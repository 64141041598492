import React, { Component } from "react";
import { Card, CardBody, Spinner, Button } from "reactstrap";
import ToastList from "components/Toast/ToastList";
import { showError, displayPrice } from "../../constants/utils";
import ApiService from "constants/ApiService";

class LateFeeRemoveModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toast: false,
      toastType: "",
      response: "",
      btnLoad: false,
      invoiceData: this.props.data ?? "",
    };
  }

  removeLateFee = async () => {
    this.setState({ btnLoad: true });
    let { id } = this.props;
    if (id) {
      let response = await ApiService.patchAPI(`host/invoice/waive_fee/${id}`);
      if (response.success) {
        this.setState({ btnLoad: false });
        this.props.close(response.message);
      } else {
        this.setState({ btnLoad: false });
        showError(this, response.message);

      }
    }
  };

  render() {
    let { toast, response, toastType, btnLoad, invoiceData } = this.state;
    let lateFee = invoiceData.amount - invoiceData.base_price;

    return (
      <div>
        {toast && <ToastList message={response} type={toastType} />}

        <Card>
          <CardBody className="pad25">
            <div className="mb-30 flex">
              <div className=" cl262626 fw-600 f16">Remove Late Fee</div>
              <div
                className="clpaid closeModal fw-500 point"
                onClick={() => this.props.close()}
              >
                x
              </div>
            </div>
            <label className="mb-10 cl555555 f14 fw-400" htmlFor="reason">
              Are you sure you want to remove Late Fee {displayPrice(lateFee)} ?
            </label>

            <div className="flexEnd">
              <Button
                color="primary"
                size="lg"
                className="button-wrapping search-wrapper  button-secondary ml-4 cancelbtn"
                onClick={() => this.props.close()}
              >
                No
              </Button>
              <Button
                color="primary"
                size="lg"
                className="button-wrapping search-wrapper checkoutSubmit"
                onClick={() => this.removeLateFee()}
              >
                {btnLoad ? (
                  <div className="spin-loading-save w100 h100">
                    <Spinner type="border" color="#012A4D" />
                  </div>
                ) : (
                  "Yes"
                )}
              </Button>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}
export default LateFeeRemoveModal;
