import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Card, CardHeader, Container, Spinner } from "reactstrap";
// core components
import CountUp from "react-countup";
import ApiService from "constants/ApiService";
import { showUsFormat } from "constants/utils";
import NoData from "../../../src/assets/Lotties/noData.json";
import Lottie from "react-lottie";
import paidInvoice from "../../assets/img/brand/paidInvoiceIcon.svg";
import pendingInvoice from "../../assets/img/brand/pendingInvoiceIcon.svg";
import revenue from "../../assets/img/brand/revenue.svg";
import lateFee from "../../assets/img/brand/lateFee.svg";
import arrow from "../../assets/img/brand/Vector.svg";
import { RoleBasedCRUDAccess } from "views/Roles/RolesUtils";
import {
  Chart,
  Series,
  CommonSeriesSettings,
  CommonAxisSettings,
} from "devextreme-react/chart";
import PieChart, {
  Legend,
  Tooltip,
  Label,
  Connector,
  Font,
  Size,
} from "devextreme-react/pie-chart";

import moment from "moment";
import { returnSearchObject } from "constants/utils";

// import Chart from "react-apexcharts";

class PropertyDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalRevenueAmt: "",
      paidInvoiceAmt: "",
      paidInvoiceCount: "",
      pendingInvoiceCount: "",
      pendingInvoiceAmt: "",
      lateFeeAmt: "",

      dataSource: [],
      doughnutData: [
        {
          region: "Open",
          val: 0,
        },
        {
          region: "In-progress",
          val: 0,
        },
        {
          region: "Closed",
          val: 0,
        },
      ],
      bookings: [],
      request: [],
      page: 1,
      bookPage: 1,
      revenuePeriod: "tm",
      graphLoad: false,
      booking_name: "",
    };
  }

  componentDidMount() {
    this.getPropertyDetails();
    let queryParams = returnSearchObject(this.props.location.search);
    this.setState({ booking_name: queryParams?.name });
  }

  getPropertyDetails = async () => {
    let { doughnutData, revenuePeriod, dataSource } = this.state;
    let propertyId = this.props.match.params.property_id;

    let queryParams = "";
    if (revenuePeriod) queryParams += `key=${revenuePeriod}`;

    let response = await ApiService.getAPI(
      `host/properties/summary/${propertyId}?${queryParams}`
    );
    if (response.success) {
      let data = response.data;
      let requestgraph = [...doughnutData];

      requestgraph.map((req) =>
        req.region === "Open"
          ? (req.val = data?.open_req ?? 0)
          : req.region === "In-progress"
            ? (req.val = data?.inprogress_req ?? 0)
            : req.region === "Closed" && (req.val = data?.closed_req ?? 0)
      );

      let revenueGraph = [...dataSource];
      const revenueData = data?.graph?.map((data) => {
        return {
          ...revenueGraph,
          x:
            revenuePeriod === "year"
              ? moment().month(data.x).add(-1, "month").format("MMM")
              : moment(data.x).format("MM-DD-YYYY"),
          y: data.y,
        };
      });

      this.setState({
        pendingInvoiceAmt: data?.pending_inv?.amount ?? 0,
        pendingInvoiceCount: data?.pending_inv?.count ?? 0,
        totalRevenueAmt: data?.total_inv?.amount ?? 0,
        paidInvoiceAmt: data?.paid_inv?.amount ?? 0,
        paidInvoiceCount: data?.paid_inv?.count ?? 0,
        lateFeeAmt: data?.late_fee ?? 0,
        bookings: data.booking ?? [],
        request: data.requests ?? [],
        doughnutData: requestgraph,
        dataSource: revenueData ?? [],
        graphLoad: false,
      });
    }
  };

  handleRevenueChange = (e) => {
    this.setState({ revenuePeriod: e.target.value, graphLoad: true }, () => {
      this.getPropertyDetails();
    });
  };

  customizeText = (arg) => {
    if (arg.value === 0) {
      return "";
    } else {
      return arg.value;
    }
  };

  customizePoint = (arg) => {
    if (arg.argument === "Open") {
      return { color: "#9669FB" };
    }
    if (arg.argument === "In-progress") {
      return { color: "#FECF6D" };
    }
    if (arg.argument === "Closed") {
      return { color: "#FC935E" };
    }
  };

  allZero = () => {
    let { doughnutData } = this.state;
    let dataCheck = doughnutData.every((d) => d.val === "" || d.val === 0);
    return dataCheck;
  };

  render() {
    const NodataFound = {
      loop: true,
      autoplay: true,
      animationData: NoData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    let {
      totalRevenueAmt,
      pendingInvoiceAmt,
      paidInvoiceAmt,
      lateFeeAmt,
      revenuePeriod,
      paidInvoiceCount,
      pendingInvoiceCount,
      doughnutData,
      bookings,
      dataSource,
      graphLoad,
    } = this.state;
    return (
      <Container className="card-pad-wrapper" fluid>
        <div className="detailFlex mb-20 padt30">
          <div
            style={{ gridTemplateColumns: "repeat(4,200px)" }}
            className="grid grGap20 mr-20"
          >
            <div className="flexy-content jus-center  cardStyle borbotRevenue" style={{ backgroundColor: "#FFFFFF" }}>
              <div className="flex cardTitle clrevenue bkRevenue mb-26">
                <img src={revenue} className="mr-10" alt="" />
                <div className="f13 fw-500">Revenue</div>
              </div>
              <div className="card_amount mb-18">
                <CountUp
                  start={0}
                  end={totalRevenueAmt}
                  separator=","
                  prefix="$"
                />
              </div>
              <p className=" f13 cl6F6F6F fw-400">Total Revenue</p>
              {totalRevenueAmt > 0 && RoleBasedCRUDAccess("invoice", "view") ? (
                <Link
                  to={{
                    pathname: `/admin/invoices`,
                    search: `?status=paid&propname=${bookings[0]?.property?.name}`,
                  }}
                >
                  <p className="viewMore f13 fw-400 cl6A6A6A">View More</p>
                </Link>
              ) : (
                <p className="viewMore f13 fw-400 clf2f2f2 cursor-disable">
                  View More
                </p>
              )}
            </div>
            <div className="flexy-content jus-center  cardStyle borbotPaidIn" style={{ backgroundColor: "#FFFFFF" }}>
              <div className="flex cardTitle clInvoice bkInvoice mb-26">
                <img src={paidInvoice} className="mr-10" alt="" />
                <div className="f13 fw-500">Invoices</div>
              </div>
              <div className="card_amount mb-18">
                <CountUp
                  start={0}
                  end={paidInvoiceAmt}
                  separator=","
                  prefix="$"
                />
              </div>
              <div className=" flex">
                <p className="ma-r f13 cl6F6F6F fw-400">Paid Invoice</p>
                <p className="clInvoice number_of">{paidInvoiceCount}</p>
              </div>
              {paidInvoiceAmt > 0 && RoleBasedCRUDAccess("invoice", "view") ? (
                <Link
                  to={{
                    pathname: `/admin/invoices`,
                    search: `?status=paid&propname=${bookings[0]?.property?.name}`,
                  }}
                >
                  <p className="viewMore f13 fw-400 cl6A6A6A">View More</p>
                </Link>
              ) : (
                <p className="viewMore f13 fw-400 clf2f2f2 cursor-disable">
                  View More
                </p>
              )}
            </div>{" "}
            <div className="flexy-content jus-center  cardStyle borbotLatefee" style={{ backgroundColor: "#FFFFFF" }}>
              <div className="flex cardTitle clLateFee bkLateFee mb-26">
                <img src={lateFee} style={{ marginRight: "10px" }} alt="" />
                <div className="f13 fw-500">Late Fees</div>
              </div>
              <p className="card_amount mb-18">
                <CountUp start={0} end={lateFeeAmt} separator="," prefix="$" />
              </p>
              <p className="f13 cl6F6F6F fw-400">Total Late Fees</p>
              {lateFeeAmt > 0 && RoleBasedCRUDAccess("invoice", "view") ? (
                <Link
                  to={{
                    pathname: `/admin/invoices`,
                    search: `?status=created&propname=${bookings[0]?.property?.name}`,
                  }}
                >
                  <p className="viewMore f13 fw-400 cl6A6A6A">View More</p>
                </Link>
              ) : (
                <p className="viewMore f13 fw-400 clf2f2f2 cursor-disable">
                  View More
                </p>
              )}
            </div>{" "}
            <div className="flexy-content jus-center  cardStyle borbotPendIn" style={{ backgroundColor: "#FFFFFF" }}>
              <div className="flex cardTitle clPendingInvoice bkPendingInvoice mb-26">
                <img src={pendingInvoice} className="mr-10" alt="" />
                <div className="f13 fw-500">Invoices</div>
              </div>
              <div className="card_amount mb-18">
                <CountUp
                  start={0}
                  end={pendingInvoiceAmt}
                  separator=","
                  prefix="$"
                />
              </div>
              <div className="flex">
                <p className="ma-r f13 cl6F6F6F fw-400">Pending Invoices</p>
                <p className="clPendingInvoice number_of">
                  {pendingInvoiceCount}
                </p>
              </div>
              {pendingInvoiceAmt > 0 &&
                RoleBasedCRUDAccess("invoice", "view") ? (
                <Link
                  to={{
                    pathname: `/admin/invoices`,
                    search: `?status=created&propname=${bookings[0]?.property?.name}`,
                  }}
                >
                  <p className="viewMore f13 fw-400 cl6A6A6A">View More</p>
                </Link>
              ) : (
                <p className="viewMore f13 fw-400 clf2f2f2 cursor-disable">
                  View More
                </p>
              )}
            </div>
          </div>
          <div className="chart">
            <Card className="h100">
              <CardHeader className="card-white disblock pad0">
                <div className="header_with_button flex">
                  <div className="card_header_title">Revenue</div>

                  <select
                    className="graph_select_component"
                    value={revenuePeriod}
                    onChange={this.handleRevenueChange}
                  >
                    <option value="tm">This Month</option>
                    <option value="lm">Last Month</option>
                    <option value="year">This Year</option>{" "}
                  </select>
                </div>
              </CardHeader>
              <div
                className={
                  dataSource.length > 0 ? "pad30 h100" : "piechart h100"
                }
              >
                {graphLoad ? (
                  <div className="spin-loading3">
                    <Spinner type="border" color="#012A4D" />
                  </div>
                ) : (
                  <div className="h100">
                    {dataSource.length > 0 ? (
                      <Chart id="chart" dataSource={this.state.dataSource}>
                        <CommonSeriesSettings type="bar" />
                        <CommonAxisSettings grid={{ visible: false }} />
                        <Size height={100} width={600} />
                        <Series
                          valueField="y"
                          argumentField="x"
                          name=""
                          type="bar"
                          color="#6780C0CC"
                          cornerRadius={7}
                          barWidth={14}
                        />
                        <Legend visible={false} />
                        <Tooltip enabled={true} location="edge" />
                      </Chart>
                    ) : (
                      <div className="no_data_found">
                        <Lottie
                          options={NodataFound}
                          height={100}
                          width={100}
                        />
                        <div className="mt_20">
                          <p>No Revenue Available</p>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Card>
          </div>
        </div>
        <div className="detailFlex mb-20 minh-300">
          {RoleBasedCRUDAccess("request", "view") && (
            <>
              {" "}
              <div style={{ width: "25%" }} className="bshad mr-20">
                <Card className="h100">
                  <CardHeader className="white_header disblock pad2520">
                    <div className="header_with_button flex">
                      <div className="card_header_title">Requests</div>
                      {!this.allZero() && (
                        <Link
                          to={{
                            pathname: "/admin/requests",
                            search: `?property_id=${bookings[0]?.property?.id}`,
                          }}
                        >
                          <div
                            style={{
                              color: "#012A4D",
                              cursor: "pointer",
                            }}
                            className="f13 fw-500"
                          >
                            View Details
                          </div>
                        </Link>
                      )}
                    </div>
                  </CardHeader>
                  <div className="pieChart h100">
                    {this.allZero() ? (
                      <div className="no_data_found">
                        <Lottie
                          options={NodataFound}
                          height={100}
                          width={100}
                        />
                        <div className="mt_20">
                          <p>No Request Available</p>
                        </div>
                      </div>
                    ) : (
                      <PieChart
                        id="pie"
                        type="doughnut"
                        dataSource={doughnutData}
                        customizePoint={this.customizePoint}
                        style={{ width: "250px" }}
                      >
                        <Series argumentField="region">
                          <Label
                            position="inside"
                            visible={true}
                            customizeText={this.customizeText}
                          >
                            <Connector visible={true} />
                            <Font size={18} weight={600} />
                          </Label>
                        </Series>
                        <Legend
                          horizontalAlignment="center"
                          orientation="horizontal"
                          verticalAlignment="bottom"
                          font={{ weight: "600", size: "14px" }}
                        />
                      </PieChart>
                    )}
                  </div>
                </Card>
              </div>
              <div style={{ width: "45%" }} className="bshad mr-20">
                <Card className="h100">
                  <CardHeader className="white_header disblock pad0">
                    <div className="header_with_button">
                      <div className="card_header_title pad50">Requests</div>
                      {this.state.request.length > 0 && (
                        <Link
                          to={{
                            pathname: "/admin/requests",
                            search: `?property_id=${bookings[0]?.property?.id}`,
                          }}
                        >
                          <div className="viewAll" style={{ color: "#000" }}>
                            View All
                          </div>
                        </Link>
                      )}
                    </div>
                  </CardHeader>
                  {this.state.request.length > 0 ? (
                    <table className="table_container">
                      <thead className="grey_header">
                        <tr>
                          <th>Property</th>
                          <th>Issue</th>
                          <th>Issue From</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      {this.state.request.map((item, i) => (
                        <tbody
                          className="grey_table_body"
                          key={item.request_id}
                        >
                          <tr className="grey_data">
                            <td
                              className="first_td"
                            // onClick={() =>
                            //   this.redirectDetailPage(item.request_id)
                            // }
                            >
                              <Link
                                to={{
                                  pathname: `/admin/requests/detail/${item.request_id}`,
                                }}
                                style={{ color: "#000" }}
                                className={
                                  !RoleBasedCRUDAccess("request", "view")
                                    ? "disabled-link"
                                    : ""
                                }
                              >
                                {item?.property?.name}
                              </Link>
                            </td>
                            <td>{item?.type}</td>
                            <td>{showUsFormat(item?.createdAt)}</td>

                            <td className="capitalize ">
                              {" "}
                              <div
                                className="general_status_style"
                                style={{
                                  color:
                                    item.status === "accepted"
                                      ? "#0AB39C"
                                      : item.status === "pending"
                                        ? "#F7B84A"
                                        : item.status === "closed"
                                          ? "#012A4D"
                                          : item.status === "cancelled"
                                            ? "#F25138"
                                            : item.status === "rejected"
                                              ? "#f25138"
                                              : item.status === "in_progress"
                                                ? "#ffc043"
                                                : "F06548",
                                }}
                              >
                                {item?.status}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  ) : (
                    <div className="no_data_found">
                      <Lottie options={NodataFound} height={100} width={100} />
                      <div className="mt_20">
                        <p>No Request Available</p>
                      </div>
                    </div>
                  )}
                </Card>
              </div>
            </>
          )}
          {RoleBasedCRUDAccess("booking", "view") && (
            <>
              <div style={{ width: "30%" }} className="bshad">
                <Card className="h100">
                  <CardHeader className="white_header disblock pad2520">
                    <div className="header_with_button flex">
                      <div className="card_header_title">Recent Bookings</div>
                      {bookings.length > 0 && (
                        <Link
                          to={{
                            pathname: "/admin/bookings",
                            search: `?prop_name=${bookings[0]?.property?.name}`,
                          }}
                        >
                          <img
                            src={arrow}
                            className="point"
                            width="20px"
                            height="20px"
                            alt=""
                          />
                        </Link>
                      )}
                    </div>
                  </CardHeader>
                  {this.state.bookings.length > 0 ? (
                    <div className="pad24">
                      {this.state.bookings.map((book) => (
                        <Link
                          to={{
                            pathname: `/admin/bookings/detail/${book?.booking_id}`,
                          }}
                        >
                          <div className="flex pad18 borbotF8F8F8">
                            <div className="flex-column">
                              <div className="cl212121 mb-10 f13 fw-500">
                                {book?.user?.name?.first}
                              </div>
                              <div className="clB0ADAD f13 fw-500 ">
                                {book.property?.name}
                              </div>
                            </div>
                            <div
                              className={
                                book?.status === "in_notice"
                                  ? "clInvoice bkInvoice bookStatus f13 fw-500"
                                  : book?.status === "booked"
                                    ? "bookStatus f13 fw-500 clrevenue bkRevenue "
                                    : book?.status === "checked_out"
                                      ? "bookStatus f13 fw-500 clVacated bkVacated"
                                      : ""
                              }
                            >
                              {book && book.status && book.status === "booked"
                                ? "Active"
                                : book.status === "in_notice"
                                  ? "In Notice"
                                  : book.status === "checked_out"
                                    ? "Moved Out"
                                    : "-"}
                            </div>
                          </div>
                        </Link>
                      ))}
                    </div>
                  ) : (
                    <div className="no_data_found">
                      <Lottie options={NodataFound} height={100} width={100} />
                      <div className="mt_20">
                        <p>No Bookings Available</p>
                      </div>
                    </div>
                  )}
                </Card>
              </div>
            </>
          )}
        </div>
      </Container>
    );
  }
}

export default PropertyDetail;
