import React, { Component } from "react";
import {
  Card,
  CardBody,
  Spinner,
  Button,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import DatePicker from "react-datepicker";
import ToastList from "components/Toast/ToastList";
import { showError, getIsoString } from "../../constants/utils";
import "react-datepicker/dist/react-datepicker.css";
import ApiService from "constants/ApiService";
import dateLight from "../../assets/img/brand/datePicker.svg";

class CheckOutModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      check_out: "",
      reason: "",
      toast: false,
      toastType: "",
      response: "",
      datePickerIsOpen: false,
      isLoad: false,
    };
  }

  handleCheckoutDateChange = (e) => {
    this.setState({ check_out: e });
  };

  handleInputChange = (e) => {
    this.setState({ reason: e.target.value });
  };

  confirmCheckout = async () => {
    let { check_out, reason } = this.state;
    if (check_out === "" || check_out === null || check_out === undefined) {
      showError(this, "Please select Checkout date");
    } else if (reason === "") {
      showError(this, "Please enter reason");
    }
    //  else if (xxx !== 0) {
    //   showError(this, "Pending amount of __ is there for this tenant");
    // } 
    else {
      this.setState({ isLoad: true });
      let id = this.props.bookingId;
      let data = {
        checkout: getIsoString(check_out),
        reason: reason,
      };
      let response = await ApiService.patchAPI(
        `host/booking/checkout/${id}`,
        data
      );
      if (response.success) {
        this.setState({ isLoad: false });
        this.props.close(response.message);
      } else {
        this.setState({ isLoad: false });
        showError(this, response.message);
      }
    }
  };

  openDatePicker = () => {
    this.setState({ datePickerIsOpen: !this.state.datePickerIsOpen });
  };

  render() {
    let { check_out, reason, toast, toastType, response, isLoad } = this.state;
    return (
      <Card>
        {toast && <ToastList message={response} type={toastType} />}

        <CardBody className="pad25">
          <div className="mb-30 flex">
            <div className=" cl262626 fw-600 f16">Check-Out</div>
            <div
              className="clpaid closeModal fw-500 point"
              onClick={() => this.props.close()}
            >
              x
            </div>
          </div>
          <label className="f14 fw-400 cl555555 mb-10" htmlFor="schedule_on">
            Expiry Date
          </label>
          <InputGroup className="mb-0  flex-content notice-date point">
            <DatePicker
              selected={check_out}
              // open={this.state.datePickerIsOpen}
              minDate={this.props.minDate}
              // maxDate={
              //   this.props.checkOutDate === "" ||
              //   this.props.checkOutDate === null ||
              //   this.props.checkOutDate === undefined
              //     ? new Date().setMonth(new Date().getMonth() + 2)
              //     : new Date(this.props.checkOutDate)
              // }
              maxDate={new Date().setMonth(new Date().getMonth() + 1)}
              dateFormat="MM-dd-yyyy"
              placeholderText="Checkout Date"
              className="checkOutDatePick mb-30 point"
              onChange={this.handleCheckoutDateChange}
              // onClick={() => this.openDatePicker()}
              name="schedule_on"
              id="schedule_on"
              style={{ fontWeight: "500" }}
              showYearDropdown
              showMonthDropdown
              ariaDescribedBy="basic-addon2"
            />
            <InputGroupText id="basic-addon2" className=" back-unset1">
              <label className="mb-remove cursor-point" htmlFor="schedule_on">
                <img
                  src={dateLight}
                  alt="Date-Picker Icon"
                  className="date-svg"
                  // onClick={() => this.openDatePicker()}
                />
              </label>
            </InputGroupText>
          </InputGroup>

          <label className="mb-10 cl555555 f14 fw-400" htmlFor="reason">
            Please mention the reason for the Checkout *
          </label>
          <Input
            id="reason"
            onChange={this.handleInputChange}
            value={reason}
            placeholder="Reason"
            // style={{ height: "49px" }}
            className="mb-30"
            type="textarea"
            rows={4}
            maxLength={300}
          />
          <div className="flexEnd">
            <Button
              color="primary"
              size="lg"
              className="button-wrapping search-wrapper  button-secondary ml-4 cancelbtn"
              onClick={() => this.props.close()}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              size="lg"
              className="button-wrapping search-wrapper checkoutSubmit"
              onClick={() => this.confirmCheckout()}
            >
              {isLoad ? (
                <div className="spin-loading-save w100 h100">
                  <Spinner type="border" color="#012A4D" />
                </div>
              ) : (
                <div>Submit</div>
              )}
            </Button>
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default CheckOutModal;
