import Index from "views/Index.js";
import Profile from "views/examples/Profile.js";
import Login from "views/examples/Login.js";
import Properties from "./views/Property/Properties";
import Bookings from "./views/Bookings/Bookings";
import Transactions from "./views/Transactions/Transaction";
import AddEditProperty from "views/Property/AddEditProperty";
import Rooms from "views/Property/Rooms/Rooms";
import AddEditRoom from "views/Property/Rooms/AddEditRoom";
import Managers from "views/Managers/Managers";
import SubManagers from "views/Roles/SubManagers";
import AddEditManager from "views/Managers/AddEditManager";
import AddEditNotification from "views/Notifications/AddEditNotification";
import Notifications from "views/Notifications/Notifications";
import Invoices from "views/Invoices/Invoices";
import Notice from "views/Notice/Notices";
import Requests from "views/Request/Requests";
import SupplyRequests from "views/Request/SupplyRequests";
import ResetPassword from "views/examples/Reset_password";
import RequestDetail from "views/Request/RequestDetail";
import Terms from "views/TermsPolicy/Terms";
import Policy from "views/TermsPolicy/Policy";
import Payouts from "views/Payout/Payouts";
import NoticeDetail from "views/Notice/NoticeDetail";
import VerifyEmail from "views/examples/VerifyEmail";
import ResetVerify from "views/examples/ResetVerify";
import BookingDetail from "views/Bookings/BookingDetail";
import AddDocument from "views/Bookings/Documents/AddDocument";
import Vendors from "views/venders/venders";
import Purchase from "./views/Property/purchaseHistory/Purchase";
import AddEditPurchase from "./views/Property/purchaseHistory/AddEditPurchase";
import Maintenance from "views/Property/MaintenanceHistory/Maintenace";
import DocumentHistory from "views/Property/DocumentHistory/Documents";
import AddHistoryDocument from "views/Property/DocumentHistory/AddDocument";
import Contractors from "views/Contractors/contractors";
import AddEditContracts from "views/Contractors/AddEditContractors";
import MaintenanceHistory from "views/Property/MaintenanceHistory/History";
import MaintenanceHistoryDetail from "views/Property/MaintenanceHistoryDetail";
import DashboarInactive from "./assets/img/brand/dashboardInactiveNew.svg";
import DashboarActive from "./assets/img/brand/dashboardActiveNew.svg";
import ManagerInactive from "./assets/img/brand/ManagerInactive.svg";
import ManagerActive from "./assets/img/brand/managerActive.svg";
import PropertyInactive from "./assets/img/brand/PropertiesInactive.svg";
import PropertyActive from "./assets/img/brand/propertiesActiveNew.svg";
import RequestInactive from "./assets/img/brand/requestInactiveNew.svg";
import RequestActive from "./assets/img/brand/requestActiveNew.svg";
import BookingInactive from "./assets/img/brand/BookingInactive.svg";
import BookingActive from "./assets/img/brand/bookingactive.svg";
import TransactionsInactive from "./assets/img/brand/TransactionsInactive.svg";
import TransactionsActive from "./assets/img/brand/TransactionsActive.svg";
import InvoiceInactive from "./assets/img/brand/myAccountIconInactive.svg";
import InvoiceActive from "./assets/img/brand/myAccountIconActive.svg";
import NoticeInactive from "./assets/img/brand/NoticeInactive.svg";
import NoticeActive from "./assets/img/brand/NoticeActive.svg";
import VendorsInactive from "./assets/img/brand/VendorsInactive.svg";
import VendorsActive from "./assets/img/brand/VendorsActive.svg";
import ContractorsInactive from "./assets/img/brand/ContractorsInactive.svg";
import ContractorsActive from "./assets/img/brand/ContractorsActive.svg";
import PayoutInactive from "./assets/img/brand/PayoutInactive.svg";
import PayoutActive from "./assets/img/brand/PayoutActive.svg";
import NotifyActive from "./assets/img/brand/Notifyactive.svg";
import NotifyInactive from "./assets/img/brand/Notify.svg";
import Tenants from "views/Tenants/tenants";
import PersonalDashboard from "views/dashboardDesign/PersonalDashboard";
import PropertyList from "views/PersonalProperty/PropertyList";
import CustomerDetails from "views/customerDetails";
import AddEditPersonalProperty from "views/PersonalProperty/AddEditPersonalProperty";
import Payments from "views/Payments/payments";
import PropertyDetail from "views/Property/propertyDetail";
import PersonalMaintainence from "views/PersonalProperty/PersonalMaintainence";
import PersonalInvoice from "views/PersonalProperty/personalInvoice";
import PersonalPayment from "views/PersonalProperty/Payments/personalPayment";
import MaintainceInactive from "./assets/img/brand/maintainenceInactive.svg";
import MaintainceActive from "./assets/img/brand/maintainenceActiveNew.svg";
import MaintenanceList from "./views/Property/MaintenanceList";
import Calender from "views/Calender/Calender";
import Expenses from "views/Expenses/expensesList";
import Dashboard from "./views/Dashboard /dashboard";
import Settings from "views/Settings/setting";
import Templates from "./views/MyDocument/Templates/templates";
import Published from "./views/MyDocument/Published/published";
import Roles from "views/Roles/Roles";
import Deposits from "views/Deposits/deposits";
import Vault from "views/MyVault/VaultDocument";
import { roleManager, roleType, userPersonal } from "constants/utils";
import AddEditRoles from "views/Roles/AddEditRoles";
import AddEditVault from "views/MyVault/AddEditVault";
import { roleSubManager } from "constants/utils";
import Documents from "views/Documents/Documents";
import SupplyList from "views/Property/SupplyHistory/SupplyList"
// let user = localStorage.getItem("user");
var routes = [
  {
    path: "/index",
    name: "dashboard",
    rolename: "dashboard",
    merge: "",
    icon: DashboarActive,
    image: DashboarInactive,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: "profile",
    rolename: "profile",
    icon: null,
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/profile",
    name: "",
    rolename: "",
    icon: null,
    component: Profile,
    layout: "/admin",
  },
  !roleSubManager() && {
    path: "/settings",
    name: "",
    rolename: "",
    icon: null,
    component: Settings,
    layout: "/admin",
  },

  {
    path: "/bank_account",
    name: "",
    rolename: "host_or_manager",
    icon: null,
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/documentList",
    name: "documentsList",
    rolename: "documentList",
    merge: "",
    icon: "",
    image: "",
    component: Documents,
    layout: "/admin",
  },
  {
    path: "/documents",
    name: "",
    rolename: "",
    icon: null,
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/password",
    name: "",
    rolename: "",
    icon: null,
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/published",
    name: "",
    rolename: "",
    icon: null,
    component: Published,
    layout: "/admin",
  },
  {
    path: "/templates",
    name: "",
    rolename: "",
    icon: null,
    component: Templates,
    layout: "/admin",
  },
  {
    path: "/calender",
    name: "",
    rolename: "",
    icon: null,
    component: Calender,
    layout: "/admin",
  },

  {
    path: "/personal_dashboard",
    name: "Dashboard",
    rolename: "Dashboard",
    merge: "",
    icon: DashboarActive,
    image: DashboarInactive,
    component: PersonalDashboard,
    layout: "/admin",
  },
  roleManager() && {
    path: "/submanagers",
    name: "Managers",
    rolename: "submanager",
    merge: "",
    icon: ManagerActive,
    image: ManagerInactive,
    component: SubManagers,
    layout: "/admin",
    key: "Managers",
  },

  {
    path: "/managers",
    name: "Managers",
    rolename: "manager",
    merge: "",
    icon: ManagerActive,
    image: ManagerInactive,
    component: Managers,
    layout: "/admin",
    key: "Managers",
  },
  roleManager() && {
    path: "/roles",
    name: "roles",
    merge: "",
    // icon: ManagerActive,
    // image: ManagerInactive,
    component: Roles,
    layout: "/admin",
    // key: "Managers",
  },
  roleManager() && {
    path: "/roles/create",
    name: "roles",
    merge: "",
    // icon: ManagerActive,
    // image: ManagerInactive,
    component: AddEditRoles,
    layout: "/admin",
    // key: "Managers",
  },
  roleManager() && {
    path: "/roles/:role_id",
    name: "roles",
    merge: "",
    // icon: ManagerActive,
    // image: ManagerInactive,
    component: AddEditRoles,
    layout: "/admin",
    // key: "Managers",
  },
  !roleSubManager() && {
    path: "/vault/add",
    name: "vault",
    merge: "",
    component: AddEditVault,
    layout: "/admin",
  },
  !roleSubManager() && {
    path: "/vault/:doc_id",
    name: "vault",
    merge: "",
    component: AddEditVault,
    layout: "/admin",
  },

  {
    path: "/properties",
    name: "Properties",
    rolename: "property",
    merge: "ManagerProp",
    component: Properties,
    layout: "/admin",
  },
  {
    path: "/maintenanceList",
    name: "maintenances",
    rolename: "maintenance",
    merge: "ManagerProp",
    icon: MaintainceActive,
    image: MaintainceInactive,
    component: MaintenanceList,
    layout: "/admin",
  },
  {
    path: "/personal_properties",
    name: "Properties",
    rolename: "Properties",
    merge: "ManagerProp",
    icon: PropertyActive,
    image: PropertyInactive,
    component: PropertyList,
    layout: "/admin",
  },

  {
    path: "/personal_properties/add",
    name: "Properties",
    rolename: "Properties",
    merge: "ManagerProp",
    icon: PropertyActive,
    image: PropertyInactive,
    component: AddEditPersonalProperty,
    layout: "/admin",
  },
  {
    path: "/personal_properties/edit/:property_id",
    name: "Properties",
    rolename: "Properties",
    merge: "ManagerProp",
    icon: PropertyActive,
    image: PropertyInactive,
    component: AddEditPersonalProperty,
    layout: "/admin",
  },
  {
    path: "/personalMaintainence",
    name: "Properties",
    rolename: "Properties",
    merge: "ManagerProp",
    icon: MaintainceActive,
    image: MaintainceInactive,
    component: PersonalMaintainence,
    layout: "/admin",
  },
  {
    path: "/personalInvoice",
    name: "Invoices",
    rolename: "Invoices",
    merge: "ManagerProp",
    icon: InvoiceActive,
    image: InvoiceInactive,
    component: PersonalInvoice,
    layout: "/admin",
  },
  {
    path: "/personalPayment",
    name: "Payments",
    rolename: "Payments",
    merge: "ManagerProp",
    icon: TransactionsActive,
    image: TransactionsInactive,
    component: PersonalPayment,
    layout: "/admin",
  },

  roleType() && {
    path: "/properties/add",
    name: "Add Property",
    rolename: "Add Property",
    merge: "",
    icon: "ni ni-shop text-black",
    component: AddEditProperty,
    layout: "/admin",
  },
  {
    path: "/properties/property_detail/:property_id",
    name: "Property Details",
    rolename: "Property Details",
    merge: "",
    component: PropertyDetail,
    layout: "/admin",
  },

  {
    path: "/room/add/:property_id",
    name: "property",
    rolename: "property_create",
    merge: "",
    icon: "ni ni-shop text-black",
    component: AddEditRoom,
    layout: "/admin",
  },
  {
    path: "/room/edit/:room_id",
    name: "property",
    rolename: "property_edit",
    merge: "",
    icon: "ni ni-shop text-black",
    component: AddEditRoom,
    layout: "/admin",
  },
  {
    path: "/rooms/:property_id",
    name: "property",
    rolename: "property",
    merge: "",
    icon: "ni ni-shop text-black",
    component: Rooms,
    layout: "/admin",
  },
  {
    path: "/properties/update/:property_id",
    name: "property",
    rolename: "property_edit",
    merge: "",
    icon: "ni ni-shop text-black",
    component: AddEditProperty,
    layout: "/admin",
  },
  {
    path: "/notifications",
    name: "Notifications",
    rolename: "Notifications",
    merge: "",
    icon: NotifyActive,
    image: NotifyInactive,
    component: Notifications,
    layout: "/admin",
  },
  {
    path: "/supplyrequests",
    name: "Supply Requests",
    rolename: "request",
    merge: "",
    icon: RequestActive,
    image: RequestInactive,
    component: SupplyRequests,
    layout: "/admin",
  },
  {
    path: "/requests",
    name: "requests",
    rolename: "request",
    merge: "RequestProp",
    icon: RequestActive,
    image: RequestInactive,
    component: Requests,
    layout: "/admin",
  },
  // {
  //   path: "/manager/add",
  //   name: "Add Manager",
  //   merge: "",
  //   icon: "",
  //   image: "",
  //   component: AddEditManager,
  //   layout: "/admin",
  //   key: "Managers",
  // },
  // {
  //   path: "/manager/:manager_id",
  //   name: "Add Manager",
  //   merge: "",
  //   icon: "",
  //   image: "",
  //   component: AddEditManager,
  //   layout: "/admin",
  // },

  // {
  //   path: "/notification/add",
  //   name: "Managers",
  //   merge: "",
  //   icon: "",
  //   image: "",
  //   component: AddEditNotification,
  //   layout: "/admin",
  // },
  {
    path: "/customer/detail/:id",
    component: CustomerDetails,
    layout: "/admin",
  },
  {
    path: "/notification/add",
    name: "Managers",
    rolename: "Managers",
    merge: "",
    icon: "",
    image: "",
    component: AddEditNotification,
    layout: "/admin",
  },
  {
    path: "/bookings",
    name: "Bookings",
    rolename: "booking",
    merge: "BookingProp",
    icon: BookingActive,
    image: BookingInactive,
    component: Bookings,
    layout: "/admin",
  },
  // {
  //   path: "/transactions",
  //   name: "Transactions",
  //   merge: "TransactionProp",
  //   icon: TransactionsActive,
  //   image: TransactionsInactive,
  //   component: Transactions,
  //   layout: "/admin",
  // },
  {
    path: "/invoices",
    name: "invoices",
    rolename: "invoice",
    merge: "InvoiceProp",
    icon: InvoiceActive,
    image: InvoiceInactive,
    component: Invoices,
    layout: "/admin",
  },
  {
    path: "/deposits",
    name: "deposits",
    rolename: "deposit",
    merge: "",
    icon: InvoiceActive,
    image: InvoiceInactive,
    component: Deposits,
    layout: "/admin",
  },

  {
    path: "/payments",
    name: "payments",
    rolename: "payment",
    icon: InvoiceActive,
    image: InvoiceInactive,
    component: Payments,
    layout: "/admin",
  },
  {
    path: "/expenses",
    name: "expenses",
    rolename: "expense",
    icon: InvoiceActive,
    image: InvoiceInactive,
    component: Expenses,
    layout: "/admin",
  },
  {
    path: "/properties/:manager_id",
    name: "Properties",
    rolename: "property",
    merge: "",
    icon: "",
    image: "",
    component: Properties,
    layout: "/admin",
  },
  {
    path: "/notices",
    name: "Notice",
    rolename: "notice",
    merge: "NoticeProp",
    icon: NoticeActive,
    image: NoticeInactive,
    component: Notice,
    layout: "/admin",
  },
  // {
  //   path: "/notices",
  //   name: "Notices",

  //   merge: "NoticeProp",
  //   icon: NoticeActive,
  //   image: NoticeInactive,
  //   component: Notice,
  //   layout: "/admin",
  // },
  {
    path: "/reset_password/:token",
    name: "",
    rolename: "",
    merge: "",
    icon: "",
    component: ResetPassword,
    layout: "/auth",
  },
  {
    path: "/requests/detail/:request_id",
    name: "requests",
    rolename: "request",
    merge: "",
    icon: "",
    component: RequestDetail,
    layout: "/admin",
  },
  {
    path: "/supplyrequests/detail/:request_id",
    name: "supplyrequest",
    rolename: "request",
    merge: "",
    icon: "",
    component: RequestDetail,
    layout: "/admin",
  },
  {
    path: "/terms",
    name: "",
    rolename: "",
    merge: "",
    icon: "",
    component: Terms,
    layout: "/auth",
  },
  {
    path: "/policy",
    name: "",
    rolename: "",
    merge: "",
    icon: "",
    component: Policy,
    layout: "/auth",
  },
  {
    path: "/income/:manager_id",
    name: "income",
    rolename: "payout",
    merge: "PayoutProp",
    icon: "",
    image: "",
    component: Payouts,
    layout: "/admin",
  },
  {
    path: "/income",
    name: "income",
    rolename: "payout",
    merge: "PayoutProp",
    icon: PayoutActive,
    image: PayoutInactive,
    component: Payouts,
    layout: "/admin",
  },
  {
    path: "/notices/detail/:notice_id",
    name: "Notice Details",
    rolename: "Notice Details",
    merge: "NoticeProp",
    icon: "",
    image: "",
    component: NoticeDetail,
    layout: "/admin",
  },
  {
    path: "/verify_email",
    name: "",
    rolename: "",
    merge: "",
    icon: "",
    image: "",
    component: VerifyEmail,
    layout: "/auth",
  },
  {
    path: "/reset_verify",
    name: "",
    rolename: "",
    merge: "",
    icon: "",
    image: "",
    component: ResetVerify,
    layout: "/auth",
  },
  // {
  //   path: "/invoices?status=:status&property_name=:property_name",
  //   name: "invoices",
  //   rolename: "invoice",
  //   merge: "",
  //   icon: "",
  //   image: "",
  //   component: Invoices,
  //   layout: "/admin",
  // },
  {
    path: "/tenant",
    name: "",
    rolename: "tenant",
    merge: "",
    icon: "",
    image: "",
    component: Tenants,
    layout: "/admin",
  },
  {
    path: "/requests?status=pending",
    name: "requests",
    rolename: "request",
    merge: "RequestProp",
    icon: "",
    image: "",
    component: Requests,
    layout: "/admin",
  },
  {
    path: "/bookings/detail/:booking_id",
    name: "Booking Details",
    rolename: "booking",
    merge: "BookingDetailProp",
    icon: "",
    image: "",
    component: BookingDetail,
    layout: "/admin",
  },
  {
    path: "/document/add/:booking_id",
    name: "Add Document",
    rolename: "Add Document",
    merge: "",
    icon: "",
    image: "",
    component: AddDocument,
    layout: "/admin",
  },
  {
    path: "/vendors",
    name: "vendors",
    rolename: "vendor",
    merge: "",
    icon: VendorsActive,
    image: VendorsInactive,
    component: Vendors,
    layout: "/admin",
  },
  {
    path: "/contractors",
    name: "contractors",
    rolename: "contractors",
    merge: "",
    icon: ContractorsActive,
    image: ContractorsInactive,
    component: Contractors,
    layout: "/admin",
  },
  {
    path: "/contractor/add",
    name: "",
    rolename: "",
    merge: "",
    icon: "",
    image: "",
    component: AddEditContracts,
    layout: "/admin",
  },
  {
    path: "/contractor/edit/:contractor_id",
    name: "",
    rolename: "",
    merge: "",
    icon: "",
    image: "",
    component: AddEditContracts,
    layout: "/admin",
  },
  // purchase List
  {
    path: "/properties/purchase_history/:property_id",
    name: "",
    rolename: "property",
    merge: "",
    icon: "",
    image: "",
    component: Purchase,
    layout: "/admin",
  },
  {
    path: "/properties/furniture_history/:property_id",
    name: "",
    rolename: "property",
    merge: "",
    icon: "",
    image: "",
    component: Purchase,
    layout: "/admin",
  },
  {
    path: "/properties/appliance_history/:property_id",
    name: "",
    rolename: "property",
    merge: "",
    icon: "",
    image: "",
    component: Purchase,
    layout: "/admin",
  },
  // purchase add
  {
    path: "/properties/purchase_history/add/:property_id",
    name: "property",
    rolename: "property_create",
    merge: "",
    icon: "",
    image: "",
    component: AddEditPurchase,
    layout: "/admin",
  },
  {
    path: "/properties/furniture_history/add/:property_id",
    name: "property",
    rolename: "property_create",
    merge: "",
    icon: "",
    image: "",
    component: AddEditPurchase,
    layout: "/admin",
  },
  {
    path: "/properties/appliance_history/add/:property_id",
    name: "property",
    rolename: "property_create",
    merge: "",
    icon: "",
    image: "",
    component: AddEditPurchase,
    layout: "/admin",
  },
  //Purchase edit
  {
    path: "/purchase_history/edit/:history_id/:property_id",
    name: "property",
    rolename: "property_edit",
    merge: "",
    icon: "",
    image: "",
    component: AddEditPurchase,
    layout: "/admin",
  },
  {
    path: "/furniture_history/edit/:history_id/:property_id",
    name: "property",
    rolename: "property_edit",
    merge: "",
    icon: "",
    image: "",
    component: AddEditPurchase,
    layout: "/admin",
  },
  {
    path: "/appliance_history/edit/:history_id/:property_id",
    name: "property",
    rolename: "property_edit",
    merge: "",
    icon: "",
    image: "",
    component: AddEditPurchase,
    layout: "/admin",
  },
  {
    path: "/properties/supply_list/:property_id",
    name: "",
    rolename: "property",
    merge: "",
    icon: "",
    image: "",
    component: SupplyList,
    layout: "/admin",
  },
  {
    path: "/properties/maintenance/:property_id",
    name: "",
    rolename: "property",
    merge: "",
    icon: "",
    image: "",
    component: Maintenance,
    layout: "/admin",
  },
  {
    path: "/properties/maintenance_history/:item_id",
    name: "",
    rolename: "property",
    merge: "",
    icon: "",
    image: "",
    component: MaintenanceHistory,
    layout: "/admin",
  },
  // {
  //   path: "/properties/maintenance_history/:item_id",
  //   name: "",
  //   merge: "",
  //   icon: "",
  //   image: "",
  //   component: MaintenanceHistory,
  //   layout: "/admin",
  // },
  // {
  //   path: "/properties/maintenance_history/:item_id",
  //   name: "",
  //   merge: "",
  //   icon: "",
  //   image: "",
  //   component: MaintenanceHistory,
  //   layout: "/admin",
  // },
  {
    path: "/properties/document/:property_id",
    name: "",
    rolename: "property",
    merge: "",
    icon: "",
    image: "",
    component: DocumentHistory,
    layout: "/admin",
  },
  {
    path: "/properties/document/add/:property_id",
    name: "property",
    rolename: "property_create",
    merge: "",
    icon: "",
    image: "",
    component: AddHistoryDocument,
    layout: "/admin",
  },
  {
    path: "/maintenanceList/maintenance_historys/detail/:id",
    name: "maintenancehistory",
    rolename: "maintenance",
    merge: "",
    icon: DashboarActive,
    image: DashboarInactive,
    component: MaintenanceHistoryDetail,
    layout: "/admin",
  },
  {
    path: "/vault",
    name: "vault",
    rolename: "host_or_manager",
    merge: "",
    icon: "",
    image: "",
    component: Vault,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "",
    rolename: "",
    merge: "",
    icon: null,
    component: Login,
    layout: "/auth",
  },
];
export default routes;
