import React, { Component } from "react";
import ApiService from "constants/ApiService";
import {
  filterQuery,
  returnThirdDegreeObjValue,
  returnFirstDegreeObjValue,
  showLocaldate,
  displayPrice,
} from "constants/utils";

import {
  Card,
  CardBody,
  CardHeader,
  Table,
  Spinner,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupText,
  Button,
  Input,
} from "reactstrap";
import { displayPercentage } from "constants/utils";
import closeIcon from "../../assets/img/brand/close.svg";
import DateSvg from "../../assets/img/brand/date-pick.svg";
import Select from "react-select";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { maxLengthCheck } from "constants/utils";

class EditDeposit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      overall_deposit: "",
      paid_deposit: "",
      lateFee: "",
      btnLoading: false,
      installments: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      let data = this.props.data;
      this.setState({
        lateFee: data.late_fee_amount ? data.late_fee_amount : 0,
        overall_deposit: data.amount,
        installments: data.installments,
      });
    }
  }

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  closeModal = () => {
    this.props.close();
    this.setState({
      lateFee: "",
      overall_deposit: "",
      paid_deposit: "",
    });
  };

  edit = async () => {
    let { overall_deposit, paid_deposit, installments } = this.state;
    let { toast, id } = this.props;

    if (!overall_deposit) {
      toast("Please enter overall amount");
    } else if (overall_deposit <= 0) {
      toast("Please enter valid overall amount");
    } else if (!installments) {
      toast("Please enter installments");
    } else if (installments <= 0) {
      toast("Please enter valid installments");
    } else if (overall_deposit < this.returnPaidAmount()) {
      toast("Overall amount should be greater than paid amount");
    } else if (installments < this.returnPaidInstallments()) {
      toast("Installment should be greater than paid installment");
    } else {
      this.setState({ btnLoading: true });
      let data = {
        amount: parseInt(overall_deposit),
        installments: parseInt(installments),
      };

      let response = await ApiService.patchAPI(`host/deposits/${id}`, data);
      if (response.success) {
        this.props.close(response.message);
        this.setState({
          btnLoading: false,
        });
      } else {
        toast(response.message);
        this.setState({ btnLoading: false });
      }
    }
  };

  returnPaidAmount = () => {
    let { pending_amount, amount } = this.props.data;
    let amt = amount - pending_amount;
    return amt >= 0 ? amt : 0;
  };

  returnPaidInstallments = () => {
    let { pending_installments, installments, missed_installments } = this.props.data;
    let paid = installments - (pending_installments + missed_installments)
    return paid >= 0 ? paid : 0;
  };

  returnPending = () => {
    return this.props.data.pending_installments;
  }

  render() {
    let { overall_deposit, paid_deposit, btnLoading, installments, lateFee } = this.state;
    return (
      <div>
        {this.props.isOpen && (
          <Card className="bnone">
            <CardHeader>
              <div className="flex">
                <div className="fw-500 f16 cl000000">Edit Deposit</div>

                <img
                  src={closeIcon}
                  onClick={() => this.closeModal()}
                  className="point"
                />
              </div>
            </CardHeader>
            <CardBody className="pad30p ">
              <div>
                <div className="mb-15 cl2A1E17 f14 fw-400">
                  Base Amount *
                </div>
                <Input
                  type="number"
                  value={overall_deposit}
                  name="overall_deposit"
                  onChange={this.handleInputChange}
                  className="mb-30"
                  maxLength={5}
                  onInput={maxLengthCheck}
                  minLength={this.returnPaidAmount()}
                />
              </div>
              <div>
                <div className="mb-15 cl2A1E17 f14 fw-400">
                  Late Fee
                </div>
                <Input
                  type="number"
                  value={lateFee}
                  name="late_fee_amount"
                  className="mb-30"
                  maxLength={5}
                  readOnly
                  onInput={maxLengthCheck}
                  minLength={this.returnPaidAmount()}
                />
              </div>
              <div>
                <div className="mb-15 cl2A1E17 f14 fw-400">Total Installments *</div>
                <Input
                  type="number"
                  value={installments}
                  name="installments"
                  onChange={this.handleInputChange}
                  className="mb-30"
                  maxLength={2}
                  onInput={maxLengthCheck}
                  minLength={this.returnPaidInstallments()}
                />
              </div>
              <div>
                <div className="mb-15 cl2A1E17 f14 fw-400">Paid Amount</div>
                <Input
                  type="number"
                  value={this.returnPaidAmount()}
                  name="paid_deposit"
                  onChange={this.handleInputChange}
                  className="mb-30"
                  maxLength={5}
                  onInput={maxLengthCheck}
                  readOnly
                />
              </div>
              <div>
                <div className="mb-15 cl2A1E17 f14 fw-400">
                  Paid Installments
                </div>
                <Input
                  type="number"
                  value={this.returnPaidInstallments()}
                  name="paid_installments"
                  onChange={this.handleInputChange}
                  className="mb-30"
                  maxLength={5}
                  onInput={maxLengthCheck}
                  readOnly
                />
              </div>
              <div>
                <div className="mb-15 cl2A1E17 f14 fw-400">
                  Pending Installments
                </div>
                <Input
                  type="number"
                  value={this.returnPending()}
                  name="pending_installments"
                  className="mb-50"
                  maxLength={5}
                  onInput={maxLengthCheck}
                  readOnly />
              </div>
              <div className="flexEnd">
                <div
                  className="mr-20 point f14 button-secondary"
                  onClick={() => this.closeModal()}
                >
                  Cancel
                </div>
                <Button
                  color="primary"
                  className="button-wrapping "
                  size="sm"
                  onClick={() => this.edit()}
                >
                  {btnLoading ? (
                    <div className="spin-loading-save w100 h100">
                      <Spinner type="border" color="#012A4D" />
                    </div>
                  ) : (
                    <div>Submit</div>
                  )}
                </Button>
              </div>
            </CardBody>
          </Card>
        )}
      </div>
    );
  }
}

export default EditDeposit;
