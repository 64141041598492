import React, { Component } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Modal,
  Breadcrumb,
  Table,
  Col,
  Spinner,
} from "reactstrap";
// core components
import ApiService from "constants/ApiService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import defaultProfile from "../../assets/img/brand/defaultUser.svg";
import Lottie from "react-lottie";
import DateSvg from "../../assets/img/brand/date-pick.svg";
import Remove from "../../assets/img/brand/remove.svg";
import AnimateData from "../../assets/Lotties/successLottie.json";
import FailureData from "../../assets/Lotties/failureLottieNew.json";
import Trash from "../../assets/img/brand/Trash.svg";
import ReactTooltip from "react-tooltip";
import ToastList from "components/Toast/ToastList";
import {
  getUSFormat,
  getCloudinaryImage,
  uploadCloudinary,
  showError,
  showSuccess,
  roleSubManager,
  isChangePassword,
  showLocaldate,
  isInvalidEmail,
  replaceToNormalNumber,
} from "constants/utils";
import Edit from "../../assets/img/brand/editData.svg";
import CloudIcon from "../../assets/img/brand/cloud.svg";
import PdfImage from "../../assets/img/brand/pdf.png";
import EyeIcon from "../../assets/img/brand/EyeIcon.svg";
import ChangePassword from "./changePassword";
import moment from "moment";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { DropdownCommon } from "components/Dropdown/DropdownCommon";
class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: {},
      name: "",
      email: "",
      phone: "",
      curPassword: "",
      password: "",
      newPassword: "",
      type: "password",
      pType: "password",
      nType: "password",
      modalOpen: false,
      toggleModal: false,
      image: {},
      bank_name: "",
      commission: "",
      ref: "",
      aType: "",
      company: "",
      account_no: "",
      bank_info: false,
      profile_docs: {},
      employer_docs: {},
      isLoading: false,
      imageLoading: false,
      DocImageLoading: false,
      isBankLoading: this.props.location.search ? true : false,
      renderContent: false,
      bankModal: this.props.location.search ? true : false,
      isUpdateLoading: false,
      dummyDoc: null,
      isProfileDoc: false,
      OpenDeleteModal: false,
      passLoad: false,
      documentType: "",
      changeEmailPhoneModal: false,
      dub_email: "",
      dub_phone: "",
      update_type: "",
      btnLoad: false,
      img_value: "",
      payoutStatus: "Enabled",
    };
    this.basicInfo = React.createRef();
    this.accountInfo = React.createRef();
    this.documentInfo = React.createRef();
  }

  componentDidMount = async () => {
    let { bankModal } = this.state;
    this.getProfile();
    if (bankModal) {
      setTimeout(() => this.handleBankInfo(), 10000);
    }

    setTimeout(() => {
      this.goToElement();
    }, 1);
  };

  goToElement = () => {
    let url = window.location.href;
    if (url.includes("/profile")) {
      // this.basicInfo.current.scrollIntoView();
    } else if (url.includes("/bank_account")) {
      this.accountInfo.current.scrollIntoView();
    } else if (url.includes("/documents")) {
      this.documentInfo.current.scrollIntoView();
    } else if (url.includes("/password")) {
      this.toggleModal();
    }
  };

  getProfile = async () => {
    let self = this;
    let response = await ApiService.getAPI("host");
    if (response.success) {
      this.setState(self.returnProfileData(response), () =>
        setTimeout(() => {
          this.setState({
            bankModal: false,
          });
          this.props.location.search &&
            this.props.history.push("/admin/user-profile");
        }, 13000)
      );
    }
  };

  returnProfileData = (response) => {
    let profile = response.data.host;
    let { name, phone, email, image, commission } = profile;
    let stripe = profile?.stripe;
    let docs = profile.docs;
    return {
      profile: response.data.host,
      name,
      phone: getUSFormat(phone.national_number),
      email,
      dub_email: email,
      dub_phone: getUSFormat(phone?.national_number),
      image,
      commission,
      bank_name: stripe?.name,
      account_no: stripe?.ac_number,
      ref: stripe?.route_number,
      profile_docs: docs && docs.profile,
      employer_docs: docs && docs.employer,
      bank_info: stripe?.is_bank === true ? true : false,
      aType: profile?.company_name
        ? "company"
        : profile?.company_name === ""
          ? "individual"
          : "",
      isUpdateLoading: false,
      dummyDoc: null,
      company: profile?.company_name !== "" ? profile?.company_name : "",
      docs: profile.docs && profile.docs,
      payoutStatus: stripe?.payout_enabled ? "Enabled" : "Information Required",
    };
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleOnPhoneNumberChange = (evt) => {
    this.setState({ [evt.target.name]: getUSFormat(evt.target.value) });
  };
  handleTypeInputChange = (e) => {
    this.setState({ aType: e }, () =>
      this.setState({ company: "" })
    );
  };

  updateChanges = async () => {
    let { name, email, phone, image, aType, company, commission } = this.state;
    if (name === "") {
      showError(this, "Please enter name");
    } else if (email === "") {
      showError(this, "Please enter email");
    } else if (isInvalidEmail(email)) {
      showError(this, "Please enter valid email");
    } else if (phone === "") {
      showError(this, "Please enter phone number");
    } else if (phone.length < 16) {
      showError(this, "Please enter valid phone number");
    } else if (aType === "" || aType === undefined || aType === null) {
      showError(this, "Please select type");
    } else if (
      aType === "company" &&
      (company === "" || company === undefined || company === null)
    ) {
      showError(this, "Please enter company");
    } else {
      let data = {
        name: name,
        email: email,
        phone: {
          country_code: "1",
          national_number: replaceToNormalNumber(phone),
        },
        image,
        commission,
        company_name: aType === "individual" ? "" : company,
      };
      this.setState({ isUpdateLoading: true }, () => {
        this.updateHost(data);
      });
    }
  };

  updateHost = async (data) => {
    let response = await ApiService.patchAPI("host", data);
    if (response.success) {
      showSuccess(this, response.message);

      this.setState(this.returnProfileData(response));
      this.getProfile();
      this.props.history.push(`/admin/profile`);
      window.location.reload();
    } else {
      showError(this, response.message);
    }
  };

  handleBankInfo = () => {
    this.setState({
      isBankLoading: false,
      renderContent: true,
    });
    this.getProfile();
  };
  toggleModal = (msg) => {
    this.setState({ modalOpen: !this.state.modalOpen, link: false });
    if (msg) {
      showSuccess(this, msg);
      this.getProfile();
    }
  };
  openEmailPhoneModal = (type) => {
    this.setState({ changeEmailPhoneModal: true, update_type: type });
  };
  toggleEmailPhoneModal = () => {
    let { changeEmailPhoneModal, email, phone } = this.state;
    this.setState({
      changeEmailPhoneModal: !changeEmailPhoneModal,
      update_type: "",
      dub_email: email,
      dub_phone: phone,
    });
  };
  updateEmailorPhone = async () => {
    const { dub_email, dub_phone, update_type } = this.state;
    if (update_type === "email") {
      if (!dub_email) {
        showError(this, "Please enter email");
      } else if (isInvalidEmail(dub_email)) {
        showError(this, "Please enter valid email");
      } else {
        this.setState({ btnLoad: true });
        let data = { email: dub_email };

        let response = await ApiService.patchAPI("host/email_update", data);
        if (response.success) {
          this.setState({ btnLoad: false });
          showSuccess(this, response.message);
          this.toggleEmailPhoneModal();
          this.getProfile();
        } else {
          this.setState({ btnLoad: false });
          showError(this, response.message);
        }
      }
    } else {
      if (!dub_phone) {
        showError(this, "Please enter phone number");
      } else if (dub_phone.length < 16) {
        showError(this, "Please enter valid phone number");
      } else {
        this.setState({ btnLoad: true });

        let data = {
          phone: {
            country_code: "1",
            national_number: replaceToNormalNumber(dub_phone),
          },
        };
        let response = await ApiService.patchAPI("host/phone_update", data);
        if (response.success) {
          this.setState({ btnLoad: false });
          showSuccess(this, response.message);
          this.toggleEmailPhoneModal();
          this.getProfile();
        } else {
          this.setState({ btnLoad: false });
          showError(this, response.message);
        }
      }
    }
  };

  onImageChangeHandler = async (e) => {
    let self = this;
    // let { image } = this.state;
    let file = e.target.files[0];
    if (e.target.files.length > 0) {
      this.setState({ imageLoading: true });
    }
    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg"
    ) {
      if (file.size < 1000000) {
        let data = await uploadCloudinary(file, "hosts");

        if (data.url) {
          self.setState({
            image: {
              format: data.format,
              public_id: data.public_id,
              version: `v${data.version}`,
              type: "image",
            },
            imageLoading: false,
          });
        } else {
          showError(this, "Could not upload image");
          this.setState({ imageLoading: false });
        }
      } else {
        showError(this, "File size should not exceeds 1 MB");
        this.setState({ imageLoading: false });
      }
    } else {
      showError(this, "Invalid Format");
      this.setState({ imageLoading: false });
    }
  };

  toggleCloseModal = () => {
    this.setState({
      modalOpen: false,
      curPassword: "",
      password: "",
      newPassword: "",
    });
  };
  toggleBankModal = () => {
    this.setState({
      bankModal: true,
      isBankLoading: true,
    });
  };
  toggleBankCloseModal = () => {
    this.setState({
      bankModal: false,
    });
  };
  handleLinkAccount = async () => {
    let data = {
      refresh_url: `${window.location.href}?status=success`,
      return_url: `${window.location.href}?status=success`,
    };
    this.setState({
      isLoading: true,
    });
    setTimeout(() => this.setState({ isLoading: false }), 6000);
    let response = await ApiService.postAPI("host/link_bank", data);
    if (response.success) {
      window.open(response.data.link, "_self");
    } else {
      showError(this, response.message);
    }
  };

  uploadDocument = (isProfileDoc, actDoc) => {
    this.setState({
      toggleModal: true,
      dummyDoc: actDoc,
      isProfileDoc: isProfileDoc,
    });
  };

  closeDocumentModel = (type, isdataAvailable) => {
    this.setState({
      dummyDoc: null,
      toggleModal: false,
    });
  };

  onChangeDate = (date) => {
    let dummyDoc = this.state.dummyDoc;
    dummyDoc = dummyDoc ? dummyDoc : {};
    dummyDoc["expiry_date"] = date;
    this.setState({ dummyDoc: dummyDoc });
  };

  returnDate = (docObj) => {
    let key = "expiry_date";
    return docObj && docObj[key] ? new Date(docObj[key]) : "";
  };

  onDocumentImageHandler = async (e) => {
    let file = e.target.files[0];
    if (
      file.type === "application/pdf" ||
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg"
    ) {
      if (file.size < 1000000) {
        this.setState({ DocImageLoading: true });
        let data = await uploadCloudinary(file, "documents");
        if (data) {
          let dummyDoc = this.state.dummyDoc;
          dummyDoc = dummyDoc ? dummyDoc : {};
          dummyDoc["url"] = data.url;
          dummyDoc["docType"] = data.format;
          this.setState({ DocImageLoading: false, dummyDoc });
        } else {
          showError(this, "Could not upload image");
          this.setState({ imageLoading: false });
        }
      } else {
        showError(this, "File size should not exceeds 1 MB");
        this.setState({ imageLoading: false });
      }
    } else {
      showError(this, "Please upload Image or PDF files");
      this.setState({ imageLoading: false });
    }
  };

  deleteImage = () => {
    this.setState({
      dummyDoc: {
        ...this.state.dummyDoc,
        url: "",
        docType: "",
      },
    });
  };

  submitDocUpload = () => {
    let { isProfileDoc, dummyDoc } = this.state;

    if (!dummyDoc) showError(this, "Please complete fileds");
    else if (isProfileDoc && !dummyDoc.expiry_date) {
      showError(this, "Please select the expiry date");
    }
    // else if (dummyDoc.expiry_date < new Date()) {
    //   showError(this, "Please select expiry date");
    // }
    else if (isProfileDoc && (!dummyDoc.url || dummyDoc.url === "")) {
      showError(this, "Please upload document");
    } else {
      this.setState({ toggleModal: false, dummyDoc: null });
      let fDocs = {
        profile: this.state.profile_docs,
        employer: this.state.employer_docs,
      };
      dummyDoc["createdAt"] = new Date();
      fDocs[isProfileDoc ? "profile" : "employer"] = dummyDoc;
      this.updateHost({ docs: fDocs });
    }
  };
  openEditDocument = (type, docType) => {
    this.setState({
      toggleModal: true,
      isProfileDoc: type,
      dummyDoc: docType,
    });
  };

  deleteDocumentData = (type, key) => {
    this.setState(
      {
        [type]: null,
      },
      () => {
        let data = {
          docs: {
            profile: this.state.profile_docs,
            employer: this.state.employer_docs,
          },
        };
        this.updateHost(data);
      }
    );
  };

  openDeleteModal = (value, type) => {
    this.setState({ OpenDeleteModal: value, documentType: type });
  };
  toggleModalDelete = () => {
    this.setState({ OpenDeleteModal: !this.state.OpenDeleteModal });
  };
  onImageClick = (e) => {
    this.setState({ [e.target.name]: "" });
  };
  render() {
    let {
      name,
      email,
      phone,
      modalOpen,
      image,
      bank_name,
      commission,
      ref,
      aType,
      company,
      account_no,
      bank_info,
      bankModal,
      isBankLoading,
      toast,
      response,
      toastType,
      isLoading,
      isUpdateLoading,
      imageLoading,
      DocImageLoading,
      profile_docs,
      employer_docs,
      OpenDeleteModal,
      changeEmailPhoneModal,
      dub_email,
      dub_phone,
      update_type,
      btnLoad,
      payoutStatus,
      profile
    } = this.state;
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: AnimateData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    const defaultFailureOptions = {
      loop: true,
      autoplay: true,
      animationData: FailureData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={OpenDeleteModal}
          toggle={() => this.toggleModalDelete()}
        >
          <>
            <div className="modal-header modal-invite">
              <h5 className="modal-title" id="exampleModalLabel">
                Delete Document
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => this.openDeleteModal(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <span className="pad-invite">
              Are you sure you want to delete this document ?
            </span>
            <div className="modal-footer">
              <div className="add-filter-wrap ">
                <div
                  className="filter-wrap mr-3 edit-prop change-profile-pass "
                  onClick={() => this.openDeleteModal(false)}
                >
                  <label className="profile-cancel">Cancel</label>
                </div>
              </div>
              <Button
                color="primary"
                type="button"
                onClick={
                  this.state.documentType === "Profile"
                    ? () => this.deleteDocumentData("profile_docs", "profile")
                    : () => this.deleteDocumentData("employer_docs", "employer")
                }
                className="button-wrapping change-profile-button"
              >
                Delete
              </Button>
            </div>
          </>
        </Modal>
        {toast && <ToastList message={response} type={toastType} />}
        <CustomListingContainer>
          <div style={{ margin: "24px" }}>
            <Row className="align-items-center header-div">
              <Col xs="8" className="manage-con">
                <h3 className="mb-0 managers-crumb">My Profile</h3>
              </Col>
            </Row>
            {/* document upload modal */}
            <Modal isOpen={this.state.toggleModal} centered>
              <>
                {" "}
                <div className="modal-header modal-invite">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Upload Document
                  </h5>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.closeDocumentModel()}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>{" "}
                <div className="modal-body profile-body">
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label
                          className="form-control-label label-in mb-2"
                          htmlFor="input-username"
                        >
                          Type
                        </label>
                        <InputGroup className="input-group-alternative new-input-login">
                          <Input
                            className="form-control-alternative email-new-in invite-email-in"
                            value={
                              this.state.isProfileDoc
                                ? "Profile ID"
                                : "Employer Letter"
                            }
                            placeholder=""
                            disabled
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label
                          className="form-control-label label-in mb-2"
                          htmlFor="input-username"
                        >
                          Expiry Date
                        </label>
                        <InputGroup className="mb-0  flex-content notice-date">
                          <DatePicker
                            selected={this.returnDate(this.state.dummyDoc)}
                            dateFormat="MM-dd-yyyy"
                            placeholderText=""
                            className="filter-input-date  new-date-in new-send-invite point"
                            onChange={this.onChangeDate}
                            name={this.returnDate(this.state.dummyDoc)}
                            id="exp_date"
                            style={{ fontWeight: "500" }}
                            showYearDropdown
                            showMonthDropdown
                            ariaDescribedBy="basic-addon2"
                            minDate={new Date()}
                          />
                          <InputGroupText
                            id="basic-addon2"
                            className="group-date invite-group notice-group"
                          >
                            <label className="mb-remove" htmlFor="exp_date">
                              <img
                                src={DateSvg}
                                alt="Date-Picker Icon"
                                className="date-svg point"
                              />
                            </label>
                          </InputGroupText>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <div className="dotted_document_div">
                          {DocImageLoading && (
                            <div className=" image_spinner mr_20 ">
                              <Spinner type="border" color="#012A4D" />
                            </div>
                          )}
                          {this.state.dummyDoc && this.state.dummyDoc["url"] ? (
                            <div className="image_relative">
                              <img
                                className="uploaded_image_div"
                                src={
                                  this.state.dummyDoc.docType === "pdf"
                                    ? PdfImage
                                    : this.state.dummyDoc.url
                                }
                                alt=""
                              ></img>
                              <img
                                src={Remove}
                                className="ni ni-fat-remove pdf-remove"
                                onClick={() => this.deleteImage()}
                                alt=""
                              />
                            </div>
                          ) : (
                            <>
                              {!DocImageLoading && (
                                <>
                                  <img
                                    src={CloudIcon}
                                    className="pdf-add-img mb-1"
                                    alt=""
                                  />
                                  <p className="green_text mb_0 mt_20">
                                    Upload your Document or Picture
                                  </p>

                                  <Input
                                    className="form-control-alternative form-control opac-hide pdf-add-input"
                                    type="file"
                                    id="imageUpload"
                                    onChange={(event) =>
                                      this.onDocumentImageHandler(event)
                                    }
                                  />
                                </>
                              )}{" "}
                            </>
                          )}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="modal-footer">
                    <div className="add-filter-wrap ">
                      <div
                        className="filter-wrap mr-3 edit-prop change-profile-pass"
                        onClick={() => this.closeDocumentModel()}
                      >
                        <label className="profile-cancel">Cancel</label>
                      </div>
                    </div>
                    <Button
                      color="primary"
                      type="button"
                      onClick={() => this.submitDocUpload()}
                      className="button-wrapping change-profile-button"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </>
            </Modal>
            {/* end of document upload */}
            <Row className="align-items-center header-div mt-4">
              <Col xs="6" className="pl-0">
                <div className="profile-img-content">
                  <div className="sphere-image">
                    <Button
                      color="primary"
                      size="sm"
                      className="image-hide button-wrapping profile-btn-img"
                    >
                      <Input
                        className="form-control-alternative form-control opac-hide "
                        type="file"
                        id="imageUpload"
                        accept="image/*"
                        onChange={(event) => this.onImageChangeHandler(event)}
                        value={this.state.img_value}
                        onClick={(e) => this.onImageClick(e)}
                        placeholder="Enter Name"
                      />
                      Add
                    </Button>
                    {imageLoading ? (
                      <div className="image-loading">
                        <Spinner type="border" color="#012A4D" />
                      </div>
                    ) : !image?.public_id ? (
                      <>
                        {" "}
                        <label htmlFor="imageUpload">
                          <img src={defaultProfile} alt="profile img" style={{ width: "103px" }} />
                        </label>
                        <label className="pencil-bg" htmlFor="imageUpload">
                          <i className="fas fa-pencil-alt"></i>
                        </label>
                      </>
                    ) : (
                      <>
                        <img
                          src={getCloudinaryImage(100, 100, image, "c_fill")}
                          alt="profile img"
                        />

                        <label className="pencil-bg" htmlFor="imageUpload">
                          <i className="fas fa-pencil-alt"></i>
                        </label>
                      </>
                    )}
                  </div>
                  <div>
                    <div className="label-wrap-pro">
                      <label className="name-profile">{profile.name}</label>
                      <label className="email-profile">{email}</label>
                    </div>
                  </div>
                </div>
              </Col>
              <div className="add-filter-wrap ">
                <div
                  className="filter-wrap mr-4 edit-pro-prop change-password-btn"
                  onClick={() => this.toggleModal()}
                >
                  <label>Change Password</label>
                </div>
              </div>
              <Button
                color="primary"
                onClick={() => this.updateChanges()}
                size="sm"
                className={
                  isUpdateLoading
                    ? "button-wrapping new-profile-btn width-on-load"
                    : "button-wrapping new-profile-btn"
                }
              >
                {isUpdateLoading ? (
                  <div className="button_spinner">
                    <Spinner type="border" color="#012A4D" />
                  </div>
                ) : (
                  "Save Changes"
                )}
              </Button>
            </Row>
          </div>
          <Row ref={this.basicInfo}>
            <Col className="order-xl-1 mt-2" xl="8">
              <Card className=" shadow">
                <CardHeader className="border-0 pad-r-3">
                  <Row className="align-items-center">
                    <Col xs="7">
                      <h3 className="mb-0">Basic Information</h3>
                    </Col>

                    <Col className="text-right">
                      <Button
                        color="primary"
                        onClick={() => this.openEmailPhoneModal("email")}
                        size="sm"
                        className="button_without_border pro-wrap"
                        style={{ marginRight: "22px" }}
                      >
                        Change Email
                      </Button>
                      <Button
                        color="primary"
                        onClick={() => this.openEmailPhoneModal("phone")}
                        size="sm"
                        className="button_without_border pro-wrap"
                      >
                        Change Phone Number
                      </Button>
                    </Col>

                    {/* <Col></Col> */}
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <div className="pad-r-1-5">
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Name*
                            </label>

                            <Input
                              className="form-control-alternative"
                              value={name}
                              name="name"
                              placeholder="Name"
                              type="text"
                              maxLength="60"
                              onChange={this.handleInputChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email*
                            </label>

                            <Input
                              className="form-control-alternative"
                              value={email}
                              name="email"
                              // id="input-email"
                              placeholder="Email"
                              type="email"
                              onChange={this.handleInputChange}
                              readOnly
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Phone Number*
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={phone}
                              name="phone"
                              // id="input-first-name"
                              onChange={this.handleOnPhoneNumberChange}
                              placeholder="Phone Number"
                              type="text"
                              maxLength="16"
                              readOnly
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Payout Commission ( % )*
                            </label>
                            <InputGroup className="request-input input_with_icon">
                              <Input
                                className="form-control-alternative no_border"
                                value={commission}
                                name="commission"
                                id="input-email"
                                placeholder="Payout Percent"
                                // type="email"
                                onChange={this.handleOnNumberChange}
                                maxLength="3"
                                readOnly
                              />
                              <InputGroupAddon addonType="append">
                                <InputGroupText className="new-border-request no_border">
                                  <div
                                    data-tip="Payout commission Percent "
                                    data-iscapture="true"
                                    data-for="toolTip1"
                                    data-place="right"
                                  >
                                    <i className="fa fa-info-circle"></i>
                                  </div>
                                  <ReactTooltip
                                    place="right"
                                    type="info"
                                    effect="solid"
                                    id="toolTip1"
                                    html={true}
                                  />
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Type*
                            </label>
                            <DropdownCommon
                              innerStyle={{ marginTop: "13px" }}
                              status={aType}
                              setStatus={this.handleTypeInputChange}
                              statusList={[
                                { label: "Select", value: "" },
                                { label: "Individual", value: "individual" },
                                { label: "Company", value: "company" },
                              ]}
                            />
                            {/* <select
                              className="form-control-alternative form-control"
                              id="input-first-name"
                              name="aType"
                              value={aType}
                              onChange={this.handleTypeInputChange}
                            >
                              <option value="">Select</option>
                              <option value="individual">Individual</option>
                              <option value="company">Company</option>
                            </select> */}
                          </FormGroup>
                        </Col>
                        {aType === "company" && (
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Company*
                              </label>
                              <Input
                                className="form-control-alternative"
                                value={company}
                                name="company"
                                id="input-email"
                                placeholder="Company"
                                // type="email"
                                onChange={this.handleInputChange}
                              />
                            </FormGroup>
                          </Col>
                        )}
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <Modal
                className="modal-dialog-centered"
                isOpen={modalOpen}
                toggle={isChangePassword() ? "" : () => this.toggleModal()}
              >
                <ChangePassword close={this.toggleModal} />
              </Modal>
              <Modal
                className="modal-dialog-centered"
                isOpen={changeEmailPhoneModal}
                toggle={() => this.toggleEmailPhoneModal()}
              >
                <Card>
                  <CardBody>
                    <div className="flex mb-20">
                      <div className="fw500 fc-b">
                        {update_type === "email"
                          ? "Update Email"
                          : "Update Phone Number"}
                      </div>
                      <div
                        className="clpaid x_close flexmid point fw500 font-24"
                        onClick={() => this.toggleEmailPhoneModal()}
                      >
                        x
                      </div>
                    </div>
                    <div className="mb-30">
                      {/* <label className="f13 fw500 clA0A6AD">Email*</label> */}
                      <label className="form-control-label label-in ">
                        {update_type === "email" ? "Email *" : "Phone Number *"}
                      </label>
                      {update_type === "email" ? (
                        <Input
                          type="text"
                          name="dub_email"
                          onChange={this.handleInputChange}
                          value={dub_email}
                          placeholder="Email"
                          maxLength="50"
                          className="form-control-alternative form-input-update"
                        />
                      ) : (
                        <Input
                          name="dub_phone"
                          value={dub_phone}
                          onChange={this.handleOnPhoneNumberChange}
                          placeholder="Phone Number"
                          type="text"
                          maxLength="16"
                          className="form-control-alternative form-input-update"
                        />
                      )}
                    </div>
                    <div className="flexEnd">
                      <Button
                        color="primary"
                        size="lg"
                        className="button-wrapping search-wrapper content-flex mr-4 button-secondary ml-4 cancelbtn"
                        onClick={this.toggleEmailPhoneModal}
                      >
                        Cancel
                      </Button>
                      <Button
                        color="primary"
                        size="lg"
                        className="button-wrapping search-wrapper updateForm"
                        onClick={() => this.updateEmailorPhone()}
                      >
                        {btnLoad ? (
                          <div className="spin-loading-save w100 h100">
                            <Spinner type="border" color="#012A4D" />
                          </div>
                        ) : (
                          "Update"
                        )}
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Modal>
            </Col>
          </Row>

          <Row className="">
            {!roleSubManager() && (
              <>
                {" "}
                <div className="col new-table" ref={this.accountInfo}>
                  <Card className="shadow">
                    <CardHeader className="border-0">
                      <Row className="align-items-center">
                        <Col xs="6">
                          <h3 className="mb-0 mt_0">Account Details</h3>
                        </Col>
                        <Col className="text-right" xs="6">
                          <Button
                            color="primary"
                            onClick={() => this.handleLinkAccount()}
                            size="sm"
                            className={
                              isLoading
                                ? "button_without_border pro-wrap min-load "
                                : "button_without_border pro-wrap"
                            }
                          >
                            {this.state.bank_info && !isLoading
                              ? "Update Account"
                              : !this.state.bank_info && !isLoading
                                ? "Link Account"
                                : ""}
                            {isLoading && (
                              <div className="alternate_spin">
                                <Spinner type="border" />
                              </div>
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody className="new-cards">
                      {bank_info && (
                        <Form>
                          <div>
                            <Row>
                              <Col lg="4">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-username"
                                  >
                                    Bank Name
                                  </label>

                                  <Input
                                    className="form-control-alternative bank-text"
                                    value={bank_name}
                                    name="bank_name"
                                    placeholder="Bank Name"
                                    type="text"
                                    onChange={this.handleInputChange}
                                    readOnly
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="4">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-username"
                                  >
                                    Account No
                                  </label>

                                  <Input
                                    className="form-control-alternative bank-text"
                                    value={account_no}
                                    name="account_no"
                                    placeholder="Bank Name"
                                    type="text"
                                    onChange={this.handleInputChange}
                                    readOnly
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="4">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-email"
                                  >
                                    Route Number
                                  </label>

                                  <Input
                                    className="form-control-alternative bank-text"
                                    value={ref}
                                    name="ref"
                                    id="input-email"
                                    placeholder="Reference No"
                                    type="email"
                                    onChange={this.handleInputChange}
                                    readOnly
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="4">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-email"
                                  >
                                    Payout Status
                                  </label>

                                  <Input
                                    className={
                                      payoutStatus === "Enabled"
                                        ? "enableGreen form-control-alternative bank-text" :
                                        "enableRed form-control-alternative bank-text"
                                    }
                                    value={payoutStatus}
                                    name="payoutStatus"
                                    id="input-email"
                                    placeholder="Payout Status"
                                    type="email"
                                    onChange={this.handleInputChange}
                                    readOnly
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                        </Form>
                      )}
                    </CardBody>
                  </Card>
                </div>
                <Modal
                  className="modal-dialog-centered modal-dialog-notice modal-row"
                  isOpen={bankModal}
                  toggle={() => this.toggleBankModal()}
                >
                  {isBankLoading ? (
                    <>
                      <div className="spin-loading-profile-wait mt-4">
                        <Spinner type="border" color="#012A4D" />
                      </div>
                      <label className="info-text">
                        Please Wait. We are collecting your account information.
                      </label>
                    </>
                  ) : bank_info ? (
                    <div className="modal-body profile-body bank-body">
                      <Row>
                        <Col lg="12" className="mt-3">
                          <Lottie
                            options={defaultOptions}
                            height={150}
                            width={150}
                          />
                          <FormGroup className="label-accept">
                            <label
                              className="form-control-label label-in mb-3"
                              htmlFor="input-username"
                            >
                              Your Bank Account have been successfully linked !
                            </label>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div className="modal-body profile-body bank-body">
                      <Row>
                        <Col lg="12" className="mt-3">
                          <Lottie
                            options={defaultFailureOptions}
                            height={150}
                            width={150}
                            className="mt-2"
                          />
                          <FormGroup className="label-accept">
                            <label
                              className="form-control-label label-in mb-3"
                              htmlFor="input-username"
                            >
                              Your Bank Account linking process has failed
                              !.Please try again.
                            </label>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  )}
                </Modal>
              </>
            )}
          </Row>
        </CustomListingContainer>

        <div className="" ref={this.documentInfo} style={{ backgroundColor: "white", paddingTop: "15px", borderRadius: "5px", margin: "0px 24px 24px 24px" }}>
          <div className="mb_20 ft_14">
            <p className="ft_15 ft_500 black" style={{ marginLeft: "24px" }}>Documents</p>
          </div>
          <div className="">
            <Card className="shadow p_0">
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Type</th>
                    <th scope="col">Uploaded At</th>
                    <th scope="col">Expiry</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Profile Id</td>

                    <td>
                      {profile_docs?.createdAt
                        ? showLocaldate(profile_docs.createdAt)
                        : "-"}
                    </td>
                    <td>
                      {" "}
                      {profile_docs && profile_docs.expiry_date
                        ? moment(profile_docs.expiry_date).format(
                          "MMM DD, YYYY"
                        )
                        : "-"}
                    </td>
                    <td className="green_text ft_15 ft_500">
                      {profile_docs && profile_docs.url ? (
                        <>
                          <a
                            className="mr_15 cursor-point"
                            href={profile_docs.url}
                            target="_blank"
                          >
                            <img src={EyeIcon} alt="" />
                          </a>
                          <img
                            src={Edit}
                            className="mr_15 cursor-point"
                            onClick={() =>
                              this.openEditDocument(
                                true,
                                this.state.profile_docs
                              )
                            }
                            alt=""
                          />
                          <img
                            className="mr_15 cursor-point"
                            src={Trash}
                            onClick={() =>
                              this.openDeleteModal(true, "Profile")
                            }
                            alt=""
                          />
                        </>
                      ) : (
                        <>
                          <p
                            className="m_0 green_text ft_15 ft_500 cursor-point"
                            onClick={() =>
                              this.uploadDocument(
                                true,
                                this.state.profile_docs
                              )
                            }
                          >
                            {" "}
                            Upload
                          </p>
                        </>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Employement</td>
                    <td>
                      {" "}
                      {employer_docs && employer_docs.createdAt
                        ? showLocaldate(employer_docs.createdAt)
                        : "-"}
                    </td>
                    <td>
                      {" "}
                      {employer_docs && employer_docs.expiry_date
                        ? showLocaldate(employer_docs.expiry_date)
                        : "-"}
                    </td>
                    <td className="green_text ft_15 ft_500">
                      {employer_docs && employer_docs.url ? (
                        <>
                          <a
                            className="mr_15 cursor-point"
                            href={employer_docs.url}
                            target="_blank"
                          >
                            <img src={EyeIcon} alt="" />
                          </a>
                          <img
                            src={Edit}
                            className="mr_15 cursor-point"
                            onClick={() =>
                              this.openEditDocument(
                                false,
                                this.state.employer_docs
                              )
                            }
                            alt=""
                          />
                          <img
                            className="mr_15 cursor-point"
                            src={Trash}
                            onClick={() =>
                              this.openDeleteModal(true, "Employement")
                            }
                            alt=""
                          />
                        </>
                      ) : (
                        <>
                          <p
                            className="m_0 green_text ft_15 ft_500 cursor-point"
                            onClick={() =>
                              this.uploadDocument(
                                true,
                                this.state.employer_docs
                              )
                            }
                          >
                            {" "}
                            Upload
                          </p>
                        </>
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
              <Row className="align-items-center">
                <Col xs="12">
                  <h3 className="mb-0 mt_0">{""}</h3>
                </Col>
              </Row>
            </Card>
          </div>
        </div>
      </>
    );
  }
}

export default Profile;
