import React, { Component } from "react";
import closeIcon from "../../assets/img/brand/close.svg";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Input,
  InputGroupText,
  Spinner,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Media,
  Dropdown,
} from "reactstrap";
import DateSvg from "../../assets/img/brand/date-pick.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Add from "../../assets/img/brand/addIcon.svg";
import removeIcon from "../../assets/img/brand/removeIcon.svg";
import pdfImage from "../../assets/img/brand/pdf.png";
import { LightgalleryItem } from "react-lightgallery";
import { getCloudinaryImage, searchByName } from "constants/utils";
import { uploadCloudinary } from "constants/utils";
import ApiService from "constants/ApiService";
import { maxLengthCheck } from "constants/utils";
import ReactTooltip from "react-tooltip";
import { roleSubManager } from "constants/utils";
import { showCalendarFormat } from "constants/utils";
class ExpenseSideDragger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expenseDate: "",
      amount: "",
      image: [],
      description: "",
      imageLoading: false,
      btnLoading: false,
      pageLoading: false,
      expenseDetail: [],
      mode: "manual",
      propertySearch: "",
      proertyDetail: "",
      propertyList: [],
      defaultList: [],
      category: "",
      categorylist: [
        "",
        "Late Fees",
        "Laundry",
        "Storage",
        "Parking",
        "Internet",
        "Appliances",
        "Insurance",
        "Property Tax",
        "Water Bills",
        "Electricity Bill",
        "Others",
      ],
      categorytoggle: false,
      propertytoggle: false,
      btn_enable: true,
      propertyname: "",
      img_value: "",
    };
  }
  componentDidMount() {
    this.getPropertyLists();
  }
  getPropertyLists = async () => {
    let response = await ApiService.getAPI(`host/properties/list`);
    let new_prop_list = response?.data?.properties;
    new_prop_list.map((property) => {
      property["id"] = property["property_id"];
      delete property["property_id"];
    });
    if (response.success) {
      this.setState({
        propertyList: new_prop_list,
        defaultList: new_prop_list,
      });
    }
  };
  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      if (this.props.id) {
        this.setState({ pageLoading: true });
        this.getExpenseDetail();
      }
    }
  }
  toggleCategory = () => {
    let { categorytoggle } = this.state;
    this.setState({ categorytoggle: !categorytoggle });
  };
  propertyToggle = (e) => {
    let { propertytoggle, defaultList } = this.state;

    this.setState({
      propertytoggle: !propertytoggle,
      propertySearch: "",
      propertyList: defaultList,
    });
  };
  handlePropertySearch = (e) => {
    const { defaultList } = this.state;

    this.setState({ propertySearch: e.target.value });
    let propArr = [...defaultList];
    if (e.target.value !== "") {
      let searchedArray = searchByName(propArr, e.target.value);
      this.setState({ propertyList: searchedArray });
    } else {
      this.setState({ propertyList: defaultList });
    }
  };
  setPropertyName = (property) => {
    const { defaultList } = this.state;

    this.setState({
      propertyname: property?.name ?? "",
      propertyDetail: property ?? "",
    });
  };
  getExpenseDetail = async () => {
    let { id } = this.props;
    let response = await ApiService.getAPI(`host/expenses/${id}`);
    if (response.success) {
      let { expense } = response.data;

      this.setState(
        {
          amount: expense?.amount ?? "",
          image: expense?.bills ?? [],
          expenseDate: expense?.date ? new Date(expense?.date) : "",
          description: expense?.description ?? "",
          mode: expense?.mode ?? "manual",
          expenseDetail: expense,
          category: expense?.category ?? "",
          propertyDetail: expense?.property ?? "",
          propertyname: expense?.property?.name ?? "",
        },
        () => {
          this.setState({ pageLoading: false });
        }
      );
    }
  };
  handleDateChange = (e) => {
    this.setState({ expenseDate: e });
  };

  handleInputChange = (e) => {
    this.setState({ amount: e.target.value });
  };
  handleDescriptionChange = (e) => {
    this.setState({ description: e.target.value });
  };
  openPdf = (data) => {
    window.open(getCloudinaryImage(100, 100, data, "c_fill"));
  };

  closeModal = () => {
    this.props.close(false);
    this.setState({
      expenseDate: "",
      amount: "",
      image: [],
      description: "",
      proertyDetail: "",
      propertySearch: "",
      propertyname: "",
      mode: "",
    });
  };

  onImageChangeHandler = async (e) => {
    let { image, imageLoading } = this.state;
    let file = e.target.files[0];
    if (e.target.files.length > 0) {
      this.setState({ imageLoading: true });
    }
    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "application/pdf"
    ) {
      if (file.size < 1000000) {
        let data = await uploadCloudinary(file, "host");
        if (data.url) {
          let imgArr = [...image];
          let imageObj = {
            format: data.format,
            public_id: data.public_id,
            version: `v${data.version}`,
            type: "image",
          };
          imgArr.push(imageObj);
          this.setState({
            image: imgArr,
            imageLoading: false,
          });
        } else {
          this.props.toast("Could not upload image");
          this.setState({ imageLoading: false });
        }
      } else {
        this.props.toast("File size should not exceeds 1 MB");
        this.setState({ imageLoading: false });
      }
    } else {
      this.props.toast("Invalid Format");
      this.setState({ imageLoading: false });
    }
  };

  deleteImage = (i) => {
    let { image } = this.state;
    let imgArr = [...image];
    imgArr.splice(i, 1);
    this.setState({ image: imgArr });
  };
  setCatergory = (value) => {
    this.setState({ category: value });
  };
  addExpense = async () => {
    let {
      amount,
      expenseDate,
      image,
      description,
      propertyname,
      propertyDetail,
      category,
      btn_enable,
    } = this.state;

    if (
      expenseDate === undefined ||
      expenseDate === null ||
      expenseDate === ""
    ) {
      this.props.toast("Please select a date");
    } else if (amount === undefined || amount === null || amount === "") {
      this.props.toast("Please enter  amount");
    } else if (amount <= 0) {
      this.props.toast("Please enter a valid amount");
    } else if (roleSubManager() && !propertyname && !propertyDetail) {
      this.props.toast("Please select property");
    } else if (category === "Others" && description === "") {
      this.props.toast("Please enter a other property details");
    } else {
      this.setState({ btnLoading: true, btn_enable: false });
      let response;
      let data = {
        date: showCalendarFormat(expenseDate),
        amount: parseInt(amount),
        bills: image,
        description: description,
        category: category,
        property: propertyDetail ?? "",
      };
      if (this.props.id) {
        let id = this.props.id;
        response = await ApiService.patchAPI(`host/expenses/${id}`, data);
      } else {
        response = await ApiService.postAPI(`host/expenses`, data);
      }
      if (response.success) {
        this.setState({
          btnLoading: false,
          expenseDate: "",
          amount: "",
          image: [],
          description: "",
        });

        this.props.close(false, response.message);
        this.setState({ btn_enable: true });
      } else {
        this.setState({ btnLoading: false, btn_enable: true });

        this.props.toast(response.message);
      }
    }
  };
  propertyDropdownShow = (e) => {
    let { propertyToggle } = this.state;
    if (e.keyCode === 32) {
      this.setState({ propertytoggle: true });
    } else {
      this.setState({ propertytoggle: !propertyToggle });
    }
  };
  removeProperty = () => {
    this.setState({ propertyname: "", proertyDetail: "" });
  };

  onImageClick = (e) => {
    this.setState({ [e.target.name]: "" });
  };
  render() {
    let {
      image,
      description,
      amount,
      expenseDate,
      imageLoading,
      btnLoading,
      pageLoading,
      mode,
      propertySearch,
      propertyList,
      category,
      btn_enable,
      propertyname,
    } = this.state;

    return (
      <div>
        {this.props.isOpen && (
          <Card className="bnone">
            <CardHeader>
              <div className="flex">
                <div className="fw-500 f16 cl000000">
                  {this.props.viewMode
                    ? "Expense Details"
                    : this.props.id
                    ? "Edit Expense"
                    : "Add Expense"}
                </div>

                <img
                  src={closeIcon}
                  onClick={() => this.closeModal()}
                  className="point"
                />
              </div>
            </CardHeader>
            <CardBody className="pad30p">
              {pageLoading ? (
                <div className="spin-loading3 w100 h100">
                  <Spinner type="border" color="#012A4D" />
                </div>
              ) : (
                <>
                  <div className="cl2A1E17 mb-15 f14 fw-400">Date*</div>

                  <div className="form-relative flexStart mb-20 ">
                    <DatePicker
                      selected={expenseDate}
                      dateFormat="MM-dd-yyyy"
                      placeholderText=""
                      className="mark_payment_date  point"
                      ariaDescribedBy="basic-addon2"
                      onChange={this.handleDateChange}
                      maxDate={new Date()}
                      minDate={new Date().setMonth(new Date().getMonth() - 1)}
                      id="expenseDate"
                      readOnly={mode === "auto" || this.props.viewMode}
                    />

                    <label className="mb-remove" htmlFor="expenseDate">
                      <img
                        src={DateSvg}
                        alt="Date-Picker Icon"
                        className="dateIcon point"
                      />
                    </label>
                  </div>
                  <div className="cl2A1E17 mb-15 f14 fw-400">Amount*</div>
                  <Input
                    type="number"
                    value={amount}
                    name="Amount"
                    onChange={this.handleInputChange}
                    className="mb-20 sidedrag_input"
                    readOnly={mode === "auto" || this.props.viewMode}
                    maxLength={7}
                    onInput={maxLengthCheck}
                  />
                  <div className="cl2A1E17 mb-15 f14 fw-400">
                    Property{roleSubManager() ? "*" : ""}
                  </div>
                  <>
                    <Dropdown
                      className="status_select_div w-100"
                      toggle={(e) => this.propertyToggle(e)}
                      isOpen={
                        this.props.viewMode ? false : this.state.propertytoggle
                      }
                    >
                      <DropdownToggle className="drop_down_container category-dropdown mb-20 w-100">
                        <div>
                          <Media
                            className={
                              propertyname === ""
                                ? "cl939393 f14 fw-400"
                                : "property_name "
                            }
                            style={{
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <span>
                              {propertyname === ""
                                ? "Select Category"
                                : propertyname}
                            </span>
                            {propertyname !== "" ? (
                              <div onClick={() => this.removeProperty()}>
                                <i className="fa fa-close"></i>
                              </div>
                            ) : (
                              <i className="fas fa-angle-down"></i>
                            )}
                          </Media>
                          {/* <Input
                            value={propertySearch}
                            onChange={this.handlePropertySearch}
                            placeholder="select property"
                            className="sidedrag_input"
                            style={{ borderRadius: "4px" }}
                            readOnly={this.props.viewMode ? true : false}
                          /> */}
                        </div>
                      </DropdownToggle>
                      <DropdownMenu className="pad0p dropdown-menu-arrow w_100">
                        <div className="drpDownshad w_100">
                          <div
                            className="sidedrag_input"
                            style={{ borderRadius: "4px" }}
                          >
                            <Input
                              value={propertySearch}
                              onChange={this.handlePropertySearch}
                              placeholder="search..."
                              className="sidedrag_input mb-10"
                              style={{ borderRadius: "4px" }}
                            />
                          </div>
                          {propertyList.length > 0 ? (
                            propertyList.map((prop, i) => (
                              <DropdownItem
                                className="navBarPropName"
                                key={i}
                                onClick={() => this.setPropertyName(prop)}
                              >
                                <div className="flex ">
                                  <div
                                    data-for={prop.property_id}
                                    className="  mr-10 add_ellipse"
                                    data-tip={prop.name}
                                    data-place="right"
                                  >
                                    {prop.name}
                                  </div>
                                </div>
                                {prop.name.length > 18 && (
                                  <ReactTooltip
                                    id={prop.property_id}
                                    className="propNameTooltip"
                                    place="right"
                                    type="info"
                                    effect="solid"
                                    html={true}
                                  />
                                )}
                              </DropdownItem>
                            ))
                          ) : (
                            <DropdownItem className="flexmid cl757575 f14">
                              No Property Found
                            </DropdownItem>
                          )}
                        </div>
                      </DropdownMenu>
                    </Dropdown>
                  </>
                  <>
                    <div className="cl2A1E17 mb-15 f14 fw-400">Category</div>
                    <Dropdown
                      className="status_select_div w_100 mb-20"
                      toggle={() => this.toggleCategory()}
                      isOpen={
                        this.props.viewMode ? false : this.state.categorytoggle
                      }
                    >
                      <DropdownToggle className=" drop_down_container category-dropdown">
                        <Media
                          className="capitalize fw-200"
                          style={{
                            justifyContent: "space-between",
                            alignItems: "center",
                            color: "#374957",
                          }}
                        >
                          <span className="">{category == "" ? "Select" : category}</span>
                          <i className="fas fa-angle-down"></i>
                        </Media>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow w100" bottom>
                        <div
                          className="drpDownshad w_100 box-shadow-unset "
                          // style={{ boxShadow: "none !important" }}
                        >
                          {this.state.categorylist.map((el, index) => {
                            return (
                              <DropdownItem
                                className="dropdown_item "
                                key={index}
                                onClick={() => this.setCatergory(el)}
                              >
                                {el === "" ? "Select" : el}
                              </DropdownItem>
                            );
                          })}
                        </div>
                      </DropdownMenu>
                    </Dropdown>
                  </>

                  {mode === "manual" && (
                    <div className="cl2A1E17 mb-15 f14 fw-400">
                      {this.props.viewMode
                        ? image.length > 0 && "Bills"
                        : "Attach bills if any"}
                    </div>
                  )}

                  <div className="gridAuto65 mb-20">
                    {image.length > 0 &&
                      image.map((img, i) => (
                        <div className="requestBillImage">
                          {img.format === "pdf" ? (
                            <img
                              src={pdfImage}
                              alt="PDF"
                              onClick={() => this.openPdf(img)}
                              className="point br-5"
                              width="50px"
                              height="70px"
                            />
                          ) : (
                            <LightgalleryItem
                              className="image_upload_display request_image br-5"
                              src={getCloudinaryImage(100, 100, img, "c_fill")}
                            >
                              <img
                                src={getCloudinaryImage(70, 70, img, "c_fill")}
                                alt="profile image"
                                className="point"
                              />
                            </LightgalleryItem>
                          )}
                          {!this.props.viewMode && (
                            <img
                              src={removeIcon}
                              className="deleteIcon"
                              onClick={() => this.deleteImage(i)}
                            />
                          )}
                        </div>
                      ))}
                    {!this.props.viewMode && image.length < 5 && (
                      <div className="requestImageUploader point form-relative">
                        {imageLoading ? (
                          <div className="spin-loading3">
                            <Spinner type="border" color="#012A4D" />
                          </div>
                        ) : (
                          <div>
                            <Input
                              type="file"
                              onChange={(event) =>
                                this.onImageChangeHandler(event, false)
                              }
                              className="form-control-alternative form-control opac-hide "
                              id="ImageUpload"
                              value={this.state.img_value}
                              onClick={(e) => this.onImageClick(e)}
                            />
                            <label htmlFor="ImageUpload " className="mb-0">
                              <div className="flexmid point">
                                <img src={Add} className="point" alt="ADD" />
                              </div>
                            </label>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="cl2A1E17 mb-15 f14 fw-400">Description</div>
                  <Input
                    type="textarea"
                    value={description}
                    name="Amount"
                    onChange={this.handleDescriptionChange}
                    className="mb-30 sidedrag_input "
                    rows={4}
                    maxLength={50}
                    readOnly={this.props.viewMode || mode === "auto"}
                  />
                  {!this.props.viewMode && (
                    <div className="flexEnd mt-30">
                      <div
                        className="mr-20 point f14 button-secondary"
                        onClick={() => this.closeModal()}
                      >
                        Cancel
                      </div>
                      <Button
                        color="primary"
                        className="button-wrapping "
                        size="sm"
                        onClick={btn_enable ? () => this.addExpense() : ""}
                      >
                        {btnLoading ? (
                          <div className="spin-loading-save w100 h100">
                            <Spinner type="border" color="#012A4D" />
                          </div>
                        ) : (
                          <div>{this.props.id ? "Save" : "Add"}</div>
                        )}
                      </Button>
                    </div>
                  )}
                </>
              )}
            </CardBody>
          </Card>
        )}
      </div>
    );
  }
}

export default ExpenseSideDragger;
