import ApiService from "constants/ApiService";
import returnSecondDegreeObjValue from "constants/utils";
import { returnCustomerFirsLastName } from "constants/utils";
import { returnFirstDegreeObjValue } from "constants/utils";
import { showLocaldate } from "constants/utils";
import showMore from "../../assets/img/brand/showMore.svg";
import { returnThirdDegreeObjValue } from "constants/utils";
import { showError } from "constants/utils";
import React, { Component } from "react";
import { Breadcrumb, BreadcrumbItem, Button, Card, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, Row, Table, UncontrolledDropdown, Media, Spinner, Modal } from "reactstrap";
import { RoleBasedCRUDAccess } from "views/Roles/RolesUtils";
import { returnSearchObject } from "constants/utils";
import { pushToFilteredPage } from "constants/utils";
import { getValueArrayInArrayOfObject } from "constants/utils";
import { buildQueryString } from "constants/utils";
import { returnBookingDocumentStatus } from "views/Bookings/BookingDetail";
import { returnBookingDocumentOverflowView } from "views/Bookings/BookingDetail";
import CancelModal from "views/Bookings/CancelModal";
import { showSuccess } from "constants/utils";
import BookingMiniList from "./BookingMiniList";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { AsscendingDecending } from "components/FilterFields/Filterfields";
import { returnSortKey } from "constants/utils";
import { returnCustomerName } from "constants/utils";
import { getDefaultProperty } from "constants/utils";

export default class Documents extends Component {
  constructor(props) {
    super(props);
    this.filterOptions = [{ value: 'name', name: 'Name' }]
    this.statusFilters = [{ value: 'inprogress', name: 'In Progress' }, { value: 'completed', name: 'Completed' }, { value: 'recalled', name: 'Recalled' }, { value: 'expired', name: 'Expired' }]
    this.state = {
      isLoading: true,
      filterOption: 'name',
      status: '',
      inputSearch: '',
      addModal: false,
      query: {
        page: 1,
        limit: 10,
      },
      documents: [],
      cancelModal: false,
      requestId: '',
      keyValue: "",
      sortingList: "",
    };
  }

  componentDidMount() {
    this.setQueryStatus()
  }



  setQueryStatus = () => {
    const queryStatus = Object.entries(returnSearchObject(this.props.location.search))
    // if (!queryStatus.length) return this.getDocuments()
    const { query } = this.state
    let data = {}
    queryStatus.forEach(([key, value]) => {
      if (key === 'page') data['query'] = { ...query, page: parseInt(value) }
      else if (key === 'status') data = { ...data, status: value }
      else if (getValueArrayInArrayOfObject(this.filterOptions, 'value').includes(key)) data = { ...data, filterOption: key, inputSearch: value }
    })
    this.setState(data, () => this.handleFilterStatus())
  }

  getDocuments = () => {
    const { query, filterOption, inputSearch, status } = this.state
    const queryObj = { ...query }
    if (filterOption && inputSearch) queryObj[filterOption] = inputSearch
    if (status) queryObj['status'] = status

    ApiService.getAPI(`host/documents${buildQueryString(queryObj)}`).then(response => {
      this.setState({ isLoading: false })
      if (!response.success) return showError(response.message)
      this.setState({ documents: response.data.documents })
    })
  }

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value, input_value: true });
    clearTimeout(this.state.timeoutId);
    const timeoutId = setTimeout(() => {
      this.state.filterOption && this.handleSubmit();
    }, 1000);
    this.setState({ timeoutId });
  };
  onChangeSelectHandler = (status) => {
    this.setState({ filterOption: status, input_value: true }, () => {
      this.handleFilterStatus();
    });
  }
  changeStatusSearch = (value) => {
    this.setState({ status: value, query: { page: 1, limit: 10, input_value: true } }, () => this.handleFilterStatus())
  }

  handleReset = () => {
    this.setState(
      {
        filterOption: "name",
        inputSearch: "",
        status: "",
        sortingList: "",
        query: {
          page: 1,
          limit: 10,
        },
        input_value: false,
        keyValue: "",
      }, () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getDocuments();
      }
      //  () => this.handleFilterStatus()
    );
  };


  handleSubmit = () => {
    this.setState({ query: { ...this.state.query, page: 1 } }, () => this.handleFilterStatus())
  }

  handleDecrementPage = () => {
    const { query } = this.state
    if (query.page > 1) {
      this.setState({ query: { ...query, page: query.page - 1 } }, () => this.handleFilterStatus());
    }
  };

  handleIncrementPage = () => {
    const { documents, query } = this.state
    if (documents.length >= 10) {
      this.setState({ query: { ...query, page: query.page + 1 } }, () => this.handleFilterStatus());
    }
  };

  handleFilterStatus = () => {
    this.setState({ isLoading: true })
    let staticQuery = returnSearchObject(this.props.location.search);
    staticQuery.page = this.state.query.page;
    staticQuery.limit = this.state.query.limit;
    staticQuery.status = this.state.status
    for (let key in staticQuery) {
      if (key === "name") { delete staticQuery[key] }
      // if (getValueArrayInArrayOfObject(this.filterOptions, 'value').includes(key)) delete staticQuery[key]
    }
    this.state.inputSearch !== "" && this.state.filterOption !== "" && (staticQuery[this.state.filterOption] = this.state.inputSearch)
    staticQuery.sort = this.state.sortingList ?? "";
    this.setState({ query: staticQuery }, () => {
      pushToFilteredPage(this.props, { ...this.state.query });
      this.getDocuments()
    });
  };

  cancelSignature = async (id) => {
    this.setState({ cancelModal: true, requestId: id });
  };

  closeCancelModal = (msg) => {
    this.setState({ cancelModal: false, requestId: '' })
    if (msg) {
      this.handleFilterStatus();
      showSuccess(this, msg);
    }
  };

  closeBookingModal = () => {
    this.setState({ addModal: false })
  }
  action = (key) => {
    let skey = returnSortKey(this, key);
    this.setState({ keyValue: skey, sortingList: skey, page: 1, input_value: true }, () => { this.handleFilterStatus() })
  }
  render() {
    let { isLoading, filterOption, inputSearch, documents, query, cancelModal, requestId, status, addModal, input_value, sortingList } = this.state;
    return (
      <>
        <CustomListingContainer>
          <ListingHeaderLayout label={"Documents"}>
            <div className="align-items-center justend">
              <Button color="primary" onClick={() => this.setState({ addModal: true })} size="md" className="button-wrapping">
                Send Document
              </Button>
            </div>
          </ListingHeaderLayout>
          <div style={{ margin: "20px" }}>
            <InputGroup className="flex-content align-left">
              <div className="filters_container">
                <div className="left_filter_container">
                  <div className="select_with_input status_select_input ml-10">
                    <UncontrolledDropdown className="status_select_div ">
                      <DropdownToggle className="pr-0 drop_down_container">
                        <Media className="capitalize">
                          {filterOption === "name" && "Name"}
                          <i className="fas fa-angle-down" style={{ marginLeft: "20px" }}></i>
                        </Media>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" bottom style={{ minWidth: "130px" }}>
                        {" "}
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.onChangeSelectHandler("name")}
                        >
                          Name
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <Input
                      aria-label="Text input with dropdown button"
                      value={inputSearch}
                      name="inputSearch"
                      placeholder="search by"
                      onChange={this.onChangeHandler}
                    />
                  </div>
                  <div className="status_select">
                    <UncontrolledDropdown className="status_select_div">
                      <DropdownToggle className="pr-0 drop_down_container">
                        <Media className="capitalize">
                          {status
                            ? this.statusFilters.find((data) => {
                              return data.value === status;
                            }).name
                            : "All"}
                          <i className="fas fa-angle-down"></i>
                        </Media>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" bottom name="statusSearch">
                        <DropdownItem className="dropdown_item" onClick={() => this.changeStatusSearch("")}>
                          All
                        </DropdownItem>
                        {this.statusFilters.map((options, i) => (
                          <DropdownItem className="dropdown_item" key={i} onClick={() => this.changeStatusSearch(options.value)}>
                            {options.name}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>
                {input_value && (inputSearch || status || sortingList) &&
                  <div className="right_filter_container">
                    <Button color="primary" onClick={this.handleReset} size="sm" className="button-wrapping mr-4 button-secondary reset-btn"
                      style={{ fontSize: "15px" }}>
                      Clear filter
                    </Button>
                    {/* <Button color="primary" onClick={() => this.handleSubmit()} size="sm" className="button-wrapping search-wrapper">
                    Search
                  </Button> */}
                  </div>}
              </div>
            </InputGroup>
          </div>
          <Row>
            <div className="col new-table">
              <Card className="basic-book-info">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" className="" style={{ padding: "0px", display: "flex", borderTop: "0px" }}>
                        <th
                          scope="col"
                          className="pl_40 justLeft items-center point bnone tdColumn_23"
                          onClick={() => this.action("name")}
                        >
                          <AsscendingDecending keyValue={this.state.keyValue} value="name" name="Name" />
                        </th>
                        <th
                          scope="col"
                          className="pl_40 justLeft items-center point bnone tdColumn_23"
                          onClick={() => this.action("pname")}
                        >
                          <AsscendingDecending keyValue={this.state.keyValue} value="pname" name="Property" />
                        </th>
                        <th
                          scope="col"
                          className="pl_40 justLeft items-center point bnone tdColumn_23"
                          onClick={() => this.action("user")}
                        >
                          <AsscendingDecending keyValue={this.state.keyValue} value="user" name="Tenant" />
                        </th>
                      </th>

                      <th scope="col" style={{ width: "15%" }}>
                        Sent on
                      </th>
                      <th scope="col" style={{ width: "15%" }}>
                        Status
                      </th>
                      <th scope="col" style={{ width: "15%" }}></th>
                    </tr>
                  </thead>
                  {documents.length !== 0 && (
                    <tbody>
                      {documents.map((document, i) => (
                        <tr key={i}>
                          <td style={{ padding: "0px" }}>
                            <td className="cursor-point btopNone tdColumn tdColumn_23"   >
                              {returnFirstDegreeObjValue(document, "name")}
                            </td>
                            <td className="tdColumn tdColumn_23" >
                              {returnThirdDegreeObjValue(document, "booking", "property", "name")}
                            </td>
                            <td className="tdColumn tdColumn_23" >
                              {returnCustomerName(document)}
                            </td>
                          </td>
                          <td className="text-capitalize">{showLocaldate(returnFirstDegreeObjValue(document, "createdAt"))}</td>
                          <td className="text-capitalize">{returnBookingDocumentStatus(document, document.host.manager_id)}</td>
                          <td className="text-center">{RoleBasedCRUDAccess("booking", "edit") && returnBookingDocumentOverflowView(this, document)}</td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </Table>
                {isLoading ? (
                  <Card className="basic-book-info spin-loading1 ">
                    <Spinner type="border" color="#012A4D" />
                  </Card>
                ) : (
                  <>
                    {!documents.length && (
                      <Card className="basic-book-info">
                        <div className="no-data font-book"> No Documents Available</div>
                      </Card>
                    )}
                  </>
                )}
              </Card>
            </div>
          </Row>
          <Row className="align-items-center jus-space new-margin-page paginator_box">
            <div className="paginate-flex-box ">
              <div className="paginate-box" onClick={() => this.handleDecrementPage()}>
                <p className={query.page !== 1 ? "page_available" : "page_unavailable"}>Previous</p>
              </div>
              <div className="paginate-box" onClick={() => this.handleIncrementPage()}>
                <p className={documents.length < 10 ? "page_unavailable" : "page_available"}>Next</p>
              </div>
            </div>
          </Row>
          <Modal isOpen={cancelModal} toggle={() => this.closeCancelModal()} centered>
            {cancelModal && <CancelModal close={this.closeCancelModal} id={requestId} />}
          </Modal>
          <Card className={addModal ? "invoice-sidebar payoutSideWidth" : "toggle-sidebar"}>
            {addModal && <BookingMiniList props={this.props} close={this.closeBookingModal} />}
          </Card>
        </CustomListingContainer>
      </>
    );
  }
}
