import React, { Component } from "react";
import {
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  Input,
  FormGroup,
  Table,
  Spinner,
} from "reactstrap";
import RolesData from "./RolesData";
import Tick from "../../assets/img/brand/apptick.svg";
import { showError, showSuccess, handleGoBack } from "../../constants/utils";

import ApiService from "constants/ApiService";
import ToastList from "components/Toast/ToastList";
import "react-step-progress-bar/styles.css";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
let role_id = "";

const RoleAccess = {
  property: {
    view: false,
    create: false,
    edit: false,
    delete: false,
    import: false,
    export: false,
  },
  booking: {
    view: false,
    create: false,
    edit: false,
    delete: false,
    import: false,
    export: false,
  },
  notice: {
    view: false,
    create: false,
    edit: false,
    delete: false,
    import: false,
    export: false,
  },
  tenant: {
    view: false,
    create: false,
    edit: false,
    delete: false,
    import: false,
    export: false,
  },
  invoice: {
    view: false,
    create: false,
    edit: false,
    delete: false,
    import: false,
    export: false,
  },
  payment: {
    view: false,
    create: false,
    edit: false,
    delete: false,
    import: false,
    export: false,
  },
  payout: {
    view: false,
    create: false,
    edit: false,
    delete: false,
    import: false,
    export: false,
  },
  deposit: {
    view: false,
    create: false,
    edit: false,
    delete: false,
    import: false,
    export: false,
  },
  expense: {
    view: false,
    create: false,
    edit: false,
    delete: false,
    import: false,
    export: false,
  },
  request: {
    view: false,
    create: false,
    edit: false,
    delete: false,
    import: false,
    export: false,
  },
  maintenance: {
    view: false,
    create: false,
    edit: false,
    delete: false,
    import: false,
    export: false,
  },
  // vendor: {
  //   view: false,
  //   create: false,
  //   edit: false,
  //   delete: false,
  //   import: false,
  //   export: false,
  // },
};

class AddEditRoles extends Component {
  constructor(props) {
    super(props);
    role_id = this.props.match.params.role_id;
    this.state = {
      roleName: "",
      apps: RolesData,
      toast: false,
      response: "",
      toastType: "",
      permissions: {
        create: false,
        edit: false,
        delete: false,
        import: false,
        export: false,
        btnLoad: false,
      },
    };
  }
  componentDidMount() {
    if (role_id) {
      this.getRoleDetails();
    } else {
      this.getRefreshRole();
    }
  }
  getRoleDetails = async () => {
    const { apps } = this.state;
    let response = await ApiService.getAPI(`host/manage_role/${role_id}`);
    if (response.success) {
      let role_det = response?.data?.role;
      let RoleAccessing = response?.data?.role?.access;
      apps.map((el) => {
        el.subRoles.map((ele) => {
          if (RoleAccessing[ele.name]) {
            ele.permissions.view = RoleAccessing[ele.name]?.view;
            ele.permissions.create = RoleAccessing[ele.name]?.create;
            ele.permissions.edit = RoleAccessing[ele.name]?.edit;
            ele.permissions.delete = RoleAccessing[ele.name]?.delete;
            ele.permissions.import = RoleAccessing[ele.name]?.import;
            ele.permissions.export = RoleAccessing[ele.name]?.export;
            if (RoleAccessing[ele.name].view) {
              el.isActive = true;
              el.subrole_active = true;
            }
          }
        });
        let parentview = el.subRoles.every((el) => {
          return el.permissions.view;
        });
        let parentcreate = el.subRoles.every((el) => {
          return el.permissions.create;
        });
        let parentedit = el.subRoles.every((el) => {
          return el.permissions.edit;
        });
        let parentdelete = el.subRoles.every((el) => {
          return el.permissions.delete;
        });
        let parentimport = el.subRoles.every((el) => {
          return el.permissions.import;
        });
        let parentexport = el.subRoles.every((el) => {
          return el.permissions.export;
        });
        el.permissions.view = parentview;
        el.permissions.create = parentcreate;
        el.permissions.edit = parentedit;
        el.permissions.delete = parentdelete;
        el.permissions.import = parentimport;
        el.permissions.export = parentexport;
        return el;
      });

      this.setState({ roleName: role_det.name, apps: apps });
    }
  };
  getRefreshRole = () => {
    RolesData.map((el) => {
      el.isActive = false;
      el.subrole_active = false;
      el.subRoles.map((el) => {
        el.permissions.view = false;
        el.permissions.create = false;
        el.permissions.edit = false;
        el.permissions.delete = false;
        el.permissions.import = false;
        el.permissions.export = false;
      });
    });
    this.setState({ apps: RolesData });
  };

  historyBack = () => {
    this.props.history.goBack();
  };
  handleInputChange = (e) => {
    this.setState({ roleName: e.target.value });
  };
  handleOnSelectApps = (routes, index) => {
    const { apps } = this.state;
    apps[index].isActive = !apps[index].isActive;
    this.setState({ apps });
    if (routes.isActive) {
      apps[index].permissions.view = true;
      apps[index].subRoles.map((el) => {
        el.permissions.view = true;
      });
    } else {
      let app_index = apps.findIndex((el) => {
        return el.name === routes.name;
      });

      let roles_permisson = apps[app_index].permissions;
      apps[app_index].subrole_active = false;
      Object.keys(roles_permisson).forEach(
        (action) => (roles_permisson[action] = false)
      );
      let subroles = apps[app_index]?.subRoles;
      if (subroles) {
        subroles.forEach((subrole) => {
          let subroles_permisson = subrole.permissions;
          Object.keys(subroles_permisson).forEach(
            (action) => (subroles_permisson[action] = false)
          );
        });
      }
    }

    this.setState({ apps: apps });
  };
  toggleSubRoles = (role, index) => {
    const { apps } = this.state;

    if (role.subRoles) {
      apps[index].subrole_active = !apps[index].subrole_active;
      // apps[index].subRoles = apps[index].subRoles;
      this.setState({ apps });
    }
  };
  handleCheckClick = (e, index, check, permission_type) => {
    const { apps } = this.state;

    if (apps[index].subRoles) {
      apps[index].subRoles.map((subrole) => {
        subrole.permissions[permission_type] = !check;

        if (permission_type !== "view" && !check) {
          subrole.permissions.view = true;
        } else if (permission_type === "view" && check) {
          subrole.permissions.create = false;
          subrole.permissions.edit = false;
          subrole.permissions.delete = false;
          subrole.permissions.import = false;
          subrole.permissions.export = false;
        }
      });
    }

    apps[index].permissions[permission_type] = !check;
    if (permission_type !== "view" && !check) {
      apps[index].permissions.view = true;
    } else if (permission_type === "view" && check) {
      apps[index].permissions.create = false;
      apps[index].permissions.edit = false;
      apps[index].permissions.delete = false;
      apps[index].permissions.import = false;
      apps[index].permissions.export = false;
    }

    this.setState({ apps });
  };

  handleCheckSubRoleClick = (e, subindex, roleindex, permission_type) => {
    const { apps } = this.state;
    let subroles = apps[roleindex].subRoles;

    subroles[subindex].permissions[permission_type] =
      !subroles[subindex].permissions[permission_type];

    if (
      subroles[subindex].permissions[permission_type] &&
      permission_type !== "view"
    ) {
      subroles[subindex].permissions.view = true;
    } else if (
      !subroles[subindex].permissions[permission_type] &&
      permission_type === "view"
    ) {
      subroles[subindex].permissions.create = false;
      subroles[subindex].permissions.edit = false;
      subroles[subindex].permissions.delete = false;
      subroles[subindex].permissions.import = false;
      subroles[subindex].permissions.export = false;
      apps[roleindex].permissions.create = false;
      apps[roleindex].permissions.edit = false;
      apps[roleindex].permissions.delete = false;
      apps[roleindex].permissions.import = false;
      apps[roleindex].permissions.export = false;
    }

    let roles_view = subroles.every(
      (el) => el.permissions[permission_type] === true
    );

    if (roles_view === true) {
      apps[roleindex].permissions[permission_type] = true;
      apps[roleindex].permissions.view = true;
    } else {
      apps[roleindex].permissions[permission_type] = false;
    }
    this.setState({ apps });
  };
  AddEditRole = async () => {
    const { apps, roleName } = this.state;
    let newapp = this.state.apps.filter((el, i) => {
      return el.isActive === true;
    });

    let property_permissions = true;
    let request_permissions = true;
    let finances_permissions = true;

    newapp.map((el) => {
      if (el.name === "My Properties") {
        property_permissions = el.subRoles.some(
          (ele) => ele.permissions.view === true
        );
      }
      if (el.name === "My Finances") {
        finances_permissions = el.subRoles.some(
          (ele) => ele.permissions.view === true
        );
      }
      if (el.name === "My Requests") {
        request_permissions = el.subRoles.some(
          (ele) => ele.permissions.view === true
        );
      }
    });

    newapp.forEach((el) => {
      el.subRoles.map((ele) => {
        if (RoleAccess[ele.name]) {
          RoleAccess[ele.name] = ele.permissions;
        }
      });
    });

    if (roleName === "" || roleName === undefined || roleName === null) {
      showError(this, "Please enter role name ");
    } else if (newapp.length === 0) {
      showError(this, "Please select atleast one app");
    } else if (!property_permissions) {
      showError(this, "Please allow  view access to My Properties");
    } else if (!request_permissions) {
      showError(this, "Please allow  view access to My Requests");
    } else if (!finances_permissions) {
      showError(this, "Please allow  view access to My Finances");
    } else {
      let data = {
        name: roleName,
        access: RoleAccess,
      };

      this.setState({ btnLoad: true });
      if (role_id) {
        let response = await ApiService.patchAPI(
          `host/manage_role/${role_id}`,
          data
        );
        if (response.success) {
          this.setState({ btnLoad: false });
          showSuccess(this, response.message);
          handleGoBack(this.props);
        } else {
          this.setState({ btnLoad: false });
          showError(this, response.message);
        }
      } else {
        let response = await ApiService.postAPI(`host/manage_role`, data);
        if (response.success) {
          this.setState({ btnLoad: false });
          showSuccess(this, response.message);
          handleGoBack(this.props);
        } else {
          this.setState({ btnLoad: false });
          showError(this, response.message);
        }
      }
    }
  };

  render() {
    const { roleName, apps, toast, response, toastType, btnLoad } = this.state;

    return (
      <>
        {toast && <ToastList message={response} type={toastType} />}
        <CustomListingContainer>
          <ListingHeaderLayout label={"Role Management"}>
            <div className="flexEnd">
              <div className="add-filter-wrap mr-10">
                <Button
                  color="primary"
                  onClick={() => this.historyBack()}
                  size="sm"
                  className="button-wrapping search-wrapper mr-4 button-secondary ml-4 reset-btn"
                >
                  Back
                </Button>
              </div>
              <div className="add-filter-wrap ml-0">
                <Button
                  color="primary"
                  size="sm"
                  className="button-wrapping "
                  onClick={() => this.AddEditRole()}
                >
                  {btnLoad ? (
                    <div className="spin-loading-save w100 h100">
                      <Spinner type="border" color="#012A4D" />
                    </div>
                  ) : this.state.item_id ? (
                    " edit"
                  ) : (
                    "Save"
                  )}
                </Button>
              </div>
            </div>
          </ListingHeaderLayout>
          <div style={{ margin: "24px" }}>
            <Row>
              <Col lg="3" className="mb-3">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-username">
                    Role Name*
                  </label>

                  <Input
                    className="role_name"
                    value={roleName}
                    name="roleName"
                    placeholder="Name"
                    type="text"
                    onChange={this.handleInputChange}
                    maxLength={25}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="9" className="mb-3 ">
                <div className="form-control-label">Apps</div>
                <div className="roles_app_block ">
                  {this.state.apps.map((el, i) => {
                    return (
                      <div
                        className={
                          el.isActive
                            ? "roles_apps_name active_roles"
                            : "roles_apps_name"
                        }
                        onClick={() => this.handleOnSelectApps(el, i)}
                      >
                        {el.isActive ? (
                          <img src={Tick} className="apptick_img" alt=""></img>
                        ) : (
                          ""
                        )}
                        {el.name}
                      </div>
                    );
                  })}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="3" className="mb-3 mt-3">
                <div>Permissions</div>
              </Col>
            </Row>
            <Row>
              <Col lg="12" className="mb-3">
                <Table className="role_management_table">
                  <thead>
                    <tr>
                      <th scope="col" className="role_name_head">
                        APPS
                      </th>
                      <th scope="col">VIEW</th>
                      <th scope="col">CREATE</th>
                      <th scope="col">UPDATE</th>
                      <th scope="col">DELETE</th>
                      <th scope="col">IMPORT</th>
                      <th scope="col">EXPORT</th>
                    </tr>
                  </thead>
                  <tbody>
                    {apps.length > 0
                      ? apps.map((role, role_index) => (
                        <>
                          {role.isActive ? (
                            <tr key={role_index}>
                              <td
                                onClick={() =>
                                  this.toggleSubRoles(role, role_index)
                                }
                                className=""
                              >
                                <div className="">
                                  {role.name}

                                  {role?.subRoles ? (
                                    role.subrole_active ? (
                                      <i className=" fa fa-angle-up"></i>
                                    ) : (
                                      <i className=" fa fa-angle-down"></i>
                                    )
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </td>
                              <td>
                                <Input
                                  className="role_manage_checkbox"
                                  // id={amenity.amenity_id}
                                  onChange={(e) =>
                                    this.handleCheckClick(
                                      e,
                                      role_index,
                                      role.permissions.view,
                                      "view"
                                    )
                                  }
                                  checked={role.permissions.view}
                                  type="checkbox"
                                />
                              </td>
                              <td>
                                <Input
                                  className="role_manage_checkbox"
                                  // id={amenity.amenity_id}
                                  onChange={(e) =>
                                    this.handleCheckClick(
                                      e,
                                      role_index,
                                      role.permissions.create,
                                      "create"
                                    )
                                  }
                                  checked={role.permissions.create}
                                  type="checkbox"
                                />
                              </td>
                              <td>
                                <Input
                                  className="role_manage_checkbox"
                                  // id={amenity.amenity_id}
                                  onChange={(e) =>
                                    this.handleCheckClick(
                                      e,
                                      role_index,
                                      role.permissions.edit,
                                      "edit"
                                    )
                                  }
                                  checked={role.permissions.edit}
                                  type="checkbox"
                                />
                              </td>
                              <td>
                                {" "}
                                <Input
                                  className="role_manage_checkbox"
                                  // id={amenity.amenity_id}
                                  onChange={(e) =>
                                    this.handleCheckClick(
                                      e,
                                      role_index,
                                      role.permissions.delete,
                                      "delete"
                                    )
                                  }
                                  checked={role.permissions.delete}
                                  type="checkbox"
                                />
                              </td>
                              <td>
                                {" "}
                                <Input
                                  className="role_manage_checkbox"
                                  // id={amenity.amenity_id}
                                  onChange={(e) =>
                                    this.handleCheckClick(
                                      e,
                                      role_index,
                                      role.permissions.import,
                                      "import"
                                    )
                                  }
                                  checked={role.permissions.import}
                                  type="checkbox"
                                />
                              </td>
                              <td>
                                {" "}
                                <Input
                                  className="role_manage_checkbox"
                                  // id={amenity.amenity_id}
                                  onChange={(e) =>
                                    this.handleCheckClick(
                                      e,
                                      role_index,
                                      role.permissions.export,
                                      "export"
                                    )
                                  }
                                  checked={role.permissions.export}
                                  type="checkbox"
                                />
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}

                          {role?.subRoles?.length > 0
                            ? role?.subrole_active &&
                            role.subRoles.map((subrole, subindex) => (
                              <tr>
                                <td>
                                  <div className="subroles_name">
                                    {subrole.view_name}
                                  </div>
                                </td>
                                <td>
                                  {" "}
                                  <Input
                                    className="role_manage_checkbox"
                                    // id={amenity.amenity_id}
                                    onChange={(e) =>
                                      this.handleCheckSubRoleClick(
                                        e,
                                        subindex,
                                        role_index,
                                        "view"
                                      )
                                    }
                                    checked={subrole.permissions.view}
                                    type="checkbox"
                                  />
                                </td>
                                <td>
                                  {" "}
                                  <Input
                                    className="role_manage_checkbox"
                                    // id={amenity.amenity_id}
                                    onChange={(e) =>
                                      this.handleCheckSubRoleClick(
                                        e,
                                        subindex,
                                        role_index,
                                        "create"
                                      )
                                    }
                                    checked={subrole.permissions.create}
                                    type="checkbox"
                                  />
                                </td>
                                <td>
                                  {" "}
                                  <Input
                                    className="role_manage_checkbox"
                                    // id={amenity.amenity_id}
                                    onChange={(e) =>
                                      this.handleCheckSubRoleClick(
                                        e,
                                        subindex,
                                        role_index,
                                        "edit"
                                      )
                                    }
                                    checked={subrole.permissions.edit}
                                    type="checkbox"
                                  />
                                </td>
                                <td>
                                  {" "}
                                  <Input
                                    className="role_manage_checkbox"
                                    // id={amenity.amenity_id}
                                    onChange={(e) =>
                                      this.handleCheckSubRoleClick(
                                        e,
                                        subindex,
                                        role_index,
                                        "delete"
                                      )
                                    }
                                    checked={subrole.permissions.delete}
                                    type="checkbox"
                                  />
                                </td>
                                <td>
                                  {" "}
                                  <Input
                                    className="role_manage_checkbox"
                                    // id={amenity.amenity_id}
                                    onChange={(e) =>
                                      this.handleCheckSubRoleClick(
                                        e,
                                        subindex,
                                        role_index,
                                        "import"
                                      )
                                    }
                                    checked={subrole.permissions.import}
                                    type="checkbox"
                                  />
                                </td>
                                <td>
                                  {" "}
                                  <Input
                                    className="role_manage_checkbox"
                                    // id={amenity.amenity_id}
                                    onChange={(e) =>
                                      this.handleCheckSubRoleClick(
                                        e,
                                        subindex,
                                        role_index,
                                        "export"
                                      )
                                    }
                                    checked={subrole.permissions.export}
                                    type="checkbox"
                                  />
                                </td>
                              </tr>
                            ))
                            : ""}
                        </>
                      ))
                      : ""}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </CustomListingContainer>
      </>
    );
  }
}
export default AddEditRoles;
