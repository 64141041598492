import React, { Component } from "react";
import ApiService from "constants/ApiService";

import { Card, CardBody, CardHeader, Spinner, Button, Input } from "reactstrap";

import closeIcon from "../../assets/img/brand/close.svg";

import "react-datepicker/dist/react-datepicker.css";

import { maxLengthCheck } from "constants/utils";
import { returnPaidDeposit } from "constants/utils";

class DepositSideDragger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      overall_deposit: "",
      paid_deposit: "",
      return_amount: "",
      btnLoading: false,
    };
  }

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  closeModal = () => {
    this.props.close();
    this.setState({ overall_deposit: "", paid_deposit: "", return_amount: "" });
  };
  refund = async () => {
    let { return_amount } = this.state;
    let { toast, detail } = this.props;

    if (
      return_amount === "" ||
      return_amount === null ||
      return_amount === undefined
    ) {
      toast("Please enter return amount");
    } else if (parseInt(return_amount) <= 0) {
      toast("Please enter valid return amount");
    } else if (return_amount > returnPaidDeposit(this.props.detail.deposit)) {
      toast("Amount should be lesser than paid amount");
    } else {
      this.setState({ btnLoading: true });
      let data = {
        transfer_amount: parseInt(return_amount),
      };

      let response = await ApiService.patchAPI(
        `/host/deposits/return/${detail.booking_id}`,
        data
      );
      toast(response.message);
      this.setState({
        btnLoading: false,
      });
      if (response.success) {
        this.props.close(response.message);
      }
    }
  };

  render() {
    let { overall_deposit, paid_deposit, return_amount, btnLoading } =
      this.state;
    let { detail } = this.props;
    return (
      <div>
        {this.props.isOpen && (
          <Card className="bnone">
            <CardHeader>
              <div className="flex">
                <div className="fw-500 f16 cl000000">Deposit Refund</div>

                <img
                  src={closeIcon}
                  onClick={() => this.closeModal()}
                  className="point"
                  alt=""
                />
              </div>
            </CardHeader>
            <CardBody className="pad30p ">
              <div>
                <div className="mb-15 cl2A1E17 f14 fw-400">Overall Deposit</div>
                <Input
                  type="number"
                  value={detail?.deposit?.amount}
                  name="overall_deposit"
                  onChange={this.handleInputChange}
                  className="mb-30"
                  maxLength={5}
                  onInput={maxLengthCheck}
                  readOnly
                />
              </div>
              <div>
                <div className="mb-15 cl2A1E17 f14 fw-400">Paid Deposit</div>
                <Input
                  type="number"
                  value={returnPaidDeposit(detail?.deposit)}
                  name="paid_deposit"
                  onChange={this.handleInputChange}
                  className="mb-30"
                  maxLength={5}
                  onInput={maxLengthCheck}
                  readOnly
                />
              </div>
              <div>
                <div className="mb-15 cl2A1E17 f14 fw-400">Refund Amount</div>
                <Input
                  type="number"
                  value={return_amount}
                  name="return_amount"
                  onChange={this.handleInputChange}
                  className={!detail.check_list ? "mb-30" : "mb-50"}
                  maxLength={6}
                  onInput={maxLengthCheck}
                />
              </div>
              {!detail?.check_list && (
                <div className=" timelineReason f14 fw-400 mb-35">
                  <span className="f14 fw-500">Note : </span>
                  This Tenant doesn't complete the checkout checklist.
                </div>
              )}

              <div className="flexEnd">
                <div
                  className="mr-20 point f14 button-secondary"
                  onClick={() => this.closeModal()}
                >
                  Cancel
                </div>
                <Button
                  color="primary"
                  className="button-wrapping "
                  size="sm"
                  onClick={() => this.refund()}
                >
                  {btnLoading ? (
                    <div className="spin-loading-save w100 h100">
                      <Spinner type="border" color="#012A4D" />
                    </div>
                  ) : (
                    <div>Submit</div>
                  )}
                </Button>
              </div>
            </CardBody>
          </Card>
        )}
      </div>
    );
  }
}

export default DepositSideDragger;
