import React, { Component } from "react";
import { Card, CardBody, Input, Button, Spinner } from "reactstrap";
import { showError, showSuccess } from "constants/utils";
import amico from "../../assets/img/brand/amico.svg";
import referTenant from "../../assets/img/brand/referTenant.svg";
import referHost from "../../assets/img/brand/referHost.svg";
import referServicePro from "../../assets/img/brand/referServicePro.svg";
import linkChain from "../../assets/img/brand/linkChain.svg";
import ApiService from "constants/ApiService";
import ToastList from "components/Toast/ToastList";
class Refer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "",
      isActive: 5,
      toastType: "",
      toast: "",
      response: "",
      btnLoad: false,
      isActive: 0,
      refer: "tenant",
    };
  }
  componentDidMount = () => { };
  closeModal = (msg) => {
    this.setState({});
    this.props.close(msg);
  };
  getLink = (type, index) => {
    if (type === "tenant") {
      this.setState({ isActive: index, refer: type });
    } else if (type === "host") {
      this.setState({ isActive: index, refer: type });
    } else if (type === "contractor") {
      this.setState({ isActive: index, refer: type });
    } else {
      this.setState({ isActive: false });
    }
  };
  generateLink = async () => {
    const { refer } = this.state;
    this.setState({ btnLoad: true });
    if (!refer) {
      showError("this");
    }
    let response = await ApiService.postAPI(`host/refer/${this.state.refer}`);
    if (response.success) {
      // showSuccess(this, response.message);
      this.setState({ url: response?.data?.link ?? "", btnLoad: false });
    } else {
      this.setState({ btnLoad: false });
      showError(this, response.message);
    }
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  copyLink = () => {
    const { url } = this.state;
    if (url) {
      navigator.clipboard.writeText(this.state.url);
      showSuccess(this, "Link copied");
      setTimeout(() => this.props.close(), 1000);
    }
  };
  // render() {
  //     const { url, isActive, toast, toastType, response, btnLoad } = this.state;
  //     return (
  //         <div className="just-flex">
  //             {toast && <ToastList message={response} type={toastType} />}
  //             <div style={{ width: "36%" }}>
  //                 <Card className="rec-block-1 refer_left_poppop">
  //                     <div className="mt-30">
  //                         <img src={amico} alt=""></img>
  //                     </div>
  //                 </Card>
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     url: "",
  //     isActive: 5,
  //     toastType: "",
  //     toast: "",
  //     response: "",
  //     btnLoad: false,
  //     isActive: 0,
  //     refer: "tenant",
  //   };
  // }
  // componentDidMount = () => {};
  // closeModal = (msg) => {
  //   this.setState({});
  //   this.props.close(msg);
  // };
  // getLink = (type, index) => {
  //   if (type === "tenant") {
  //     this.setState({ isActive: index, refer: type });
  //   } else if (type === "host") {
  //     this.setState({ isActive: index, refer: type });
  //   } else if (type === "contractor") {
  //     this.setState({ isActive: index, refer: type });
  //   } else {
  //     this.setState({ isActive: false });
  //   }
  // };
  // generateLink = async () => {
  //   const { refer } = this.state;
  //   this.setState({ btnLoad: true });
  //   if (!refer) {
  //     showError("this");
  //   }
  //   let response = await ApiService.postAPI(`host/refer/${this.state.refer}`);
  //   if (response.success) {
  //     // showSuccess(this, response.message);
  //     this.setState({ url: response?.data?.link ?? "", btnLoad: false });
  //   } else {
  //     this.setState({ btnLoad: false });
  //     showError(this, response.message);
  //   }
  // };
  // handleChange = (e) => {
  //   this.setState({ [e.target.name]: e.target.value });
  // };
  // copyLink = (url) => {
  //   navigator.clipboard.writeText(url);
  //   showSuccess(this, "Link copied");
  //   setTimeout(() => this.props.close(), 1000);
  // };
  render() {
    const { url, isActive, toast, toastType, response, btnLoad } = this.state;
    return (
      <div className="just-flex refer_poppop">
        {toast && <ToastList message={response} type={toastType} />}
        <div style={{ width: "36%" }}>
          <Card className="rec-block-1 refer_left_poppop">
            <div className="mt-30">
              <img src={amico} alt=""></img>
            </div>
          </Card>
        </div>
        <div style={{ width: "64%" }}>
          <Card className="rec-block-2">
            <CardBody>
              <h1 className="cl012A4D">Refer a Friend</h1>
              <p className="label cl909899 f13">
                Just share the link with your friends and family and ask them
                <br />
                to signup and receive rewards for it.
              </p>
              <h4 className="fw-600 f16 mt-20">Select whom to refer</h4>
              <div className="flex mt-20">
                <div
                  className={
                    isActive === 0
                      ? "refer_boxes refer_boxes_active"
                      : "refer_boxes "
                  }
                  onClick={() => this.getLink("tenant", 0)}
                >
                  <img
                    src={referTenant}
                    alt=""
                    style={{ marginBottom: "3px" }}
                  />
                  <p>Tenant</p>
                </div>
                <div
                  className={
                    isActive === 1
                      ? "refer_boxes refer_boxes_active"
                      : "refer_boxes "
                  }
                  onClick={() => this.getLink("host", 1)}
                >
                  <img src={referHost} alt="" style={{ marginBottom: "3px" }} />
                  <p>Host</p>
                </div>
                <div
                  className={
                    isActive === 2
                      ? "refer_boxes refer_boxes_active"
                      : "refer_boxes"
                  }
                  onClick={() => this.getLink("contractor", 2)}
                >
                  <img
                    src={referServicePro}
                    alt=""
                    style={{ marginBottom: "3px" }}
                  />
                  <p>Service Pro</p>
                </div>
              </div>
              {/* <p className="f13 mt-20 cl909899">
                Just share the link with your friends and family and ask them
                <br />
                to signup and receive rewards for it.
              </p> */}
              {url !== "" && (
                <>
                  <div className="flex mt-30">
                    <Input
                      type="text"
                      name="url"
                      value={url}
                      className="refer_input"
                      onChange={() => this.handleChange()}
                      id="referal_url"
                    />
                    <div
                      className="refer_input_copy"
                      onClick={() => this.copyLink(url)}
                    >
                      <img
                        src={linkChain}
                        alt=""
                        style={{ width: "16px", marginRight: "6px" }}
                      />
                      <p style={{ marginTop: "11px", fontSize: "13px" }}>
                        Copy
                      </p>
                    </div>
                  </div>
                </>
              )}
              {!url && (
                <>
                  {" "}
                  <Button
                    color="primary"
                    type="button"
                    className=" button-wrapping change-profile-button w_9 mt-30 h50"
                    onClick={() => this.generateLink()}
                  >
                    {btnLoad ? (
                      <div className="spin-loading-save w100 h100">
                        <Spinner type="border" color="#012A4D" />
                      </div>
                    ) : (
                      "Generate Link"
                    )}
                  </Button>
                </>
              )}
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}
export default Refer;
