import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  DropdownMenu,
  DropdownItem,
  Button,
  Modal,
} from "reactstrap";
import ApiService from "constants/ApiService";
import RecommendContractor from "./RecommandContractor";
import RecommendImg from "../../assets/img/brand/Recommend.svg";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import BlueArrow from "../../assets/img/brand/blueArrow.svg";
import YellowArrow from "../../assets/img/brand/yellowArrow.svg";
import GreenArrow from "../../assets/img/brand/greenArrow.svg";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import TotalSales from "../../../src/assets/img/brand/TotalSales.svg";
import TotalRevenue from "../../../src/assets/img/brand/TotalRevenue.svg";
import TotalPayments from "../../../src/assets/img/brand/TotalPayments.svg";
import TotalInvoice from "../../../src/assets/img/brand/TotalInvoice.svg";
import { returnFirstDegreeObjValue } from "constants/utils";
import { returnPositiveNumber } from "constants/utils";
import Lottie from "react-lottie";
import NoData from "../../../src/assets/Lotties/noData.json";
import referFile from "../../../src/assets/Lotties/yUCF7u97lU.json";
import React, { Component } from "react";
import InvoiceIncrement from "../../../src/assets/img/brand/InvoiceIncrease.svg";
import InvoiceDecrement from "../../../src/assets/img/brand/invoiceDecrease.svg";
import { calculatePercentage } from "constants/utils";
import { roleSubManager } from "constants/utils";
import { Line } from "react-chartjs-2";
import Refer from "../../components/Navbars/refer";
import ToastList from "../../components/Toast/ToastList";
import serviceRequest from "../../assets/img/brand/serviceRequest.svg"
import supplyRequest from "../../assets/img/brand/supplyRequest.svg"
import vaultIcon from "../../assets/img/brand/vaultIcon.svg"
import paymentDue from "../../assets/img/brand/paymentDue.svg"
import invoiceDue from "../../assets/img/brand/invoiceDue.svg"
import noticeRequest from "../../assets/img/brand/noticeRequest.svg"
import depositDue from "../../assets/img/brand/depositDue.svg"
import {
  showSuccess,
  getCookie,
  returnSearchObject,
  pushToFilteredPage,
  buildQueryString,
  propType,
  getDefaultProperty,
  returnCustomerFirsLastName,
} from "constants/utils";
import { showLocaldate } from "constants/utils";
import { RoleBasedCRUDAccess } from "views/Roles/RolesUtils";

import DashboardShimmer from "./DashboardShimmer";

export const options = {
  title: "Company Performance",
  curveType: "function",
  legend: { position: "bottom" },
};

let userName = JSON.parse(localStorage.getItem("host_details"));
// let lastActivity = getCookie("lastActivity");

class DashboardDesign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      detail: {},
      request_list: [],
      invoiceStatusView: "",
      isGraphData: false,
      salesStatus: 0,
      currentMonthSalePercentage: "",
      maintance_history: [],
      graphFilter: "tm",
      RecommendContractorModal: false,
      ReferModal: false,
      toast: false,
      response: "",
      toastType: "",
      alertData: {},
      isShimmer: true,
      notifyArray: [
        { path: "/admin/invoices?status=past_due&limit=10&page=1", name: "Invoices Due", icon: invoiceDue, key: "inv_due" },
        { path: "/admin/notices?status=pending&limit=10&page=1", name: "Pending Notice Requests", icon: noticeRequest, key: "notices" },
        { path: "/admin/requests?status=pending&limit=10&page=1", name: "Pending Service Requests", icon: serviceRequest, key: "req_open" },
        { path: "/admin/supplyrequests?status=pending&limit=10&page=1", name: "Pending Supply Requests", icon: supplyRequest, key: "supply_open" },
        { path: "/admin/deposits?status=past_due&limit=10&page=1", name: "Deposits Due", icon: depositDue, key: "dep_due" },
        { path: "/admin/payments?status=created&limit=10&page=1", name: "Payments Due", icon: paymentDue, key: "pay_due" },
        { path: "/admin/vault?status=past&limit=10&page=1", name: "Vault Docs Expiry", icon: vaultIcon, key: "doc_expired" },
      ],

      todoArray: [
        { title: "Your Rent is on due today", date: "today 9: 11 am" },
        {
          title: "Tenant Akash raised a notice request for Stone park Property",
          date: "today 9: 11 am",
        },
        {
          title: "Tenant Askash rent is overdue today",
          date: "today 9: 11 am",
        },
        {
          title: "Tenant Akash raised a notice request for Stone park Property",
          date: "today 9: 11 am",
        },
        { title: "Your Rent is on due today", date: "today 9: 11 am" },
      ],
      donut_series: [0, 0],
      donut_options: {
        hover: { mode: null },
        states: {
          hover: {
            filter: {
              type: "none",
            },
          },
          active: {
            filter: {
              type: "none" /* none, lighten, darken */,
            },
          },
        },
        colors: ["#405188", "#FFBE0A"],
        events: {},
        chart: {
          width: "100%",
          type: "donut",
          height: "90%",
        },

        plotOptions: {
          pie: {
            startAngle: 0,
            endAngle: 360,
          },
        },
        dataLabels: {
          enabled: false,
        },

        legend: {
          show: false,
        },
        title: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
        showTooltips: false,
      },

      LineGraphdata: {
        labels: [],
        datasets: [],
      },
      LineGraphOptions: {
        legendCallback: function (chart) {
          return <div>Legend</div>;
        },
        maintainAspectRatio: false,
        label: {
          display: false,
        },
        interaction: {
          // mode: "index",
          // intersect: false,
          // axis: "x",
        },
        legend: {
          display: false,
          align: "end",
          labels: {
            boxWidth: 10,
          },
          title: {
            padding: 100,
          },
        },
        plugins: {
          tooltip: { enabled: false },
          // legend: false,

          legend: {
            display: true,
            labels: {
              font: {
                family: "monserrat",
                size: 16,
                weight: 600,
                lineHeight: 1.2,
              },
              color: "#214757",
              // height: 100,
            },
          },
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
              },
            },
          ],

          x: {
            type: "date",

            title: {
              display: true,

              color: "#911",
              font: {
                family: "Comic Sans MS",
                size: 20,
                weight: "bold",
                lineHeight: 1.2,
              },
              padding: { top: 10, left: 0, right: 0, bottom: 0 },
            },
            ticks: {
              font: {
                family: "monserrat",
                size: "16px",
                weight: "bold",
                lineHeight: 1.2,
              },
              color: "#214757",
              callback: (value) => showLocaldate(value),
            },
          },
          y: {
            beginAtZero: true,

            // min: 0,
            // max: 40,
            ticks: {
              // stepSize: 5,
              font: {
                family: "monserrat",
                size: "16px",
                weight: "bold",
                lineHeight: 1.2,
              },
              color: "#214757",

              callback: (value) => "₹" + value,
            },

            title: {
              display: false,
              text: "Amount",
              color: "#911",
              font: {
                family: "Comic Sans MS",
                size: 20,
                weight: "bold",
                lineHeight: 1.2,
              },
              padding: { top: 0, left: 0, right: 0, bottom: 10 },
            },
            lines: {
              show: false,
            },
            grid: {
              display: false,
              drawBorder: false,
            },
          },
        },
      },
    };
  }
  componentDidMount() {
    this.setQueryStatus();
    this.reloadPage();
    this.invoiceAlertData();
    this.lastActivity();
  }
  lastActivity = () => {
    return getCookie("lastActivity");
  }

  reloadPage = () => {
    this.props.history.listen((location, search) => {
      if (location.search === "") {
        this.setState({ query: {} }, () => {
          let query = this.props.location.search;
          query = returnSearchObject(query);
          if (Object.keys(query).length === 0) {
            this.handleReset();
          }
        });
      }
    });
  };

  setQueryStatus = () => {
    let queryStatus = this.props.location?.search;
    let default_property = getDefaultProperty();
    if (queryStatus) {
      let queryObj = returnSearchObject(queryStatus);
      if (default_property) {
        queryObj.property_id = default_property;
      }
      this.setState(
        {
          query: queryObj,
        },
        () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getDashboard();
          this.dashboardGraph();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...this.state.query,
            p_type: propType(),
            property_id: default_property,
          },
        },
        () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getDashboard();
          this.dashboardGraph();
        }
      );
    }
  };

  getDashboard = async () => {
    let queryParams = this.state.query;
    queryParams.key = "";

    // queryParams.p_type = propType();

    if (this.state.invoiceStatusView !== "") {
      queryParams.key = this.state.invoiceStatusView;
    }

    let response = await ApiService.getAPI(
      `host/dashboard${buildQueryString(queryParams)}`
    );
    if (response.success) {
      this.setState({
        detail: response.data,
        // isLoading: false,
        isShimmer: false,
        request_list: response.data.requests,
      });
      if (this.state.detail.lm_sales > 0) {
        calculatePercentage(
          this.state.detail.tm_sales && this.state.detail.tm_sales,
          this.state.detail.lm_sales && this.state.detail.lm_sales,
          "salesStatus",
          this
        );
      }

      let donutvalueArray = [];
      donutvalueArray.push(this.state.detail.bookings);
      donutvalueArray.push(
        this.state.detail.properties - this.state.detail.bookings
      );
      this.setState({
        maintance_history: response.data.history,
        donut_series: donutvalueArray,
      });
    } else {
      // this.setState({ isLoading: false });
      this.setState({ isShimmer: false });
    }
  };

  handleReset = () => {
    this.setState(
      {
        query: {
          p_type: propType(),
          property_id: getDefaultProperty(),
        },
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getDashboard();
        this.dashboardGraph();
      }
    );
  };

  redirectDetailPage = (id) => {
    let self = this;
    self.props.history.push(`/requests/detail/${id}`);
  };
  closeReferPoppop = (msg) => {
    this.setState({ ReferModal: false });
    // if (msg) {
    //   showSuccess(this, msg);
    // }
  };
  openReferPoppop = () => {
    this.setState({ ReferModal: true });
  };

  dashboardGraph = async () => {
    let { graphFilter, invoiceStatusView } = this.state;

    let queryParams = { ...this.state.query };

    if (invoiceStatusView !== "") {
      if (invoiceStatusView === "ty") {
        queryParams.key = "year";
      } else {
        queryParams.key = invoiceStatusView;
      }
    }

    let response = await ApiService.getAPI(
      `host/dashboard/graph${buildQueryString(queryParams)}`
    );
    if (response.success) {
      this.state.isGraphData = true;
      let data = response.data.values.map((val) => {
        return {
          x: val.x,
          y: val.y,
        };
      });
      let payout = response.data.payouts.map((val) => {
        return {
          x: val.x,
          y: val.y,
        };
      });
      let arr = data.concat(payout);

      let labels = arr.map((val) => {
        return val.x;
      });

      let labelArr = Array.from(new Set(labels));

      let mergedlabel = labelArr.sort(
        (a, b) =>
          new Date(...a.split("/").reverse()) -
          new Date(...b.split("/").reverse())
      );
      let formattinglabel = [];
      if (!invoiceStatusView) {
        mergedlabel.map((date) => {
          let format_date = moment(date).format("MMM-YYYY");
          formattinglabel.push(format_date);
        });
      } else {
        mergedlabel.map((date) => {
          let format_date = moment(date).format("YYYY-MMM-DD");
          formattinglabel.push(format_date);
        });
      }

      this.setState({
        LineGraphdata: {
          labels: formattinglabel,
          datasets: [
            {
              label: "Sales",
              data: data,
              borderColor: "#00CE68",
              pointBackgroundColor: "#00CE68",
              borderWidth: 5,
              pointBorderWidth: 0,
              pointHoverBorderWidth: 4,
              pointRadius: 2,
              tension: 0.2,
              fill: false,
            },
            {
              label: " Income",
              data: payout,
              borderColor: "#012A4D",
              pointBackgroundColor: "#012A4D",
              borderWidth: 5,
              pointBorderWidth: 0,
              pointHoverBorderWidth: 4,
              pointRadius: 2,
              tension: 0.2,
              fill: false,
            },
          ],
        },
      });
    } else {
      this.state.isGraphData = false;
    }
  };
  RouteFunction = () => {
    let self = this;
    self.props.history.push(`/admin/requests`);
  };
  handleTypeInputChange = (e) => {
    this.setState({ graphFilter: e.target.value, series: [] }, () => {
      this.dashboardGraph();
    });
  };

  setInvoiceStatusView = (status) => {
    this.setState({ invoiceStatusView: status }, () => {
      this.getDashboard();
      this.dashboardGraph();
    });
  };
  openRecContractor = () => {
    this.setState({ RecommendContractorModal: true });
  };
  closeRecContractor = (msg) => {
    if (msg) {
      showSuccess(this, msg);
    }
    this.setState({ RecommendContractorModal: false });
  };

  invoiceAlertData = async () => {
    let response = await ApiService.getAPI(
      `host/dashboard/alerts`
    );
    if (response.success) {
      this.setState({
        alertData: response.data
      });
    }
  }
  isArrowVisible = () => {
    let cardsContainer = document.getElementById("container");
    return cardsContainer.offsetWidth < cardsContainer.scrollWidth;
  }
  render() {
    const NodataFound = {
      loop: true,
      autoplay: true,
      animationData: NoData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    const ReferFile = {
      loop: true,
      autoplay: true,
      animationData: referFile,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    let {
      graphFilter,
      detail,
      invoiceStatusView,
      RecommendContractorModal,
      toast,
      response,
      toastType,
      ReferModal,
      notifyArray,
      todoArray,
      alertData,
      isShimmer,
    } = this.state;
    let status = "";
    if (this.state?.detail?.bookings && this.state?.detail?.properties) {
      let numbers =
        (this.state.detail.bookings / this.state.detail.properties) * 100;

      status = numbers.toFixed() + "%";
    }
    let x = 0;
    let mx = 0;

    let content = document.getElementById("scrollbar-dashboard");
    const nextButton = document.querySelector(".arrow-next");
    const prevButton = document.querySelector(".arrow-prev");
    let isDragStart = false,
      prevPageX,
      prevScrolleft;

    const mouseScroll = (e) => {
      if (!isDragStart) return;
      e.preventDefault();
      let positionDiff = e.pageX - prevPageX;
      content.scrollLeft = prevScrolleft - positionDiff;
    };
    const mouseDownHandler = (e) => {
      // content.classList.add("dragging-scrollbar");
      isDragStart = true;
      prevPageX = e.pageX;
      prevScrolleft = content.scrollLeft;
    };
    const mouseUpHandler = () => {
      isDragStart = false;
      // content.classList.remove("dragging-scrollbar");
    };

    const mouseDragRight = (e) => {
      e.preventDefault();
      x = content.clientWidth / 2 + content.scrollLeft + 0;
      content.scroll({
        left: x,
        behavior: "smooth",
      });
    };
    const mouseDragLeft = (e) => {
      e.preventDefault();
      x = content.clientWidth / 2 - content.scrollLeft + 0;
      content.scroll({
        left: -x,
        behavior: "smooth",
      });
    };
    const mouseMoveHandler = () => {
      const remainingWidth =
        content.scrollWidth - content.clientWidth
      nextButton.classList.remove("disabled-next");
      prevButton.classList.remove("disabled-prev");
      if (content.scrollLeft > remainingWidth - 10) {
        nextButton.classList.add("disabled-next");
      }
      if (content.scrollLeft < 10) {
        prevButton.classList.add("disabled-prev");
      }
    };

    return isShimmer ? (
      <div className="w100 h100" >
        <DashboardShimmer />
      </div>
    ) : (
      <>
        <div className="bgF3F3F9">
          <div className="header pt-md-6 equal_header_padding ">
            {toast && <ToastList message={response} type={toastType} />}
            <Container fluid className="genaral_div_padding">
              <div className="flex mb-20 ">
                <div className="flex w-89">
                  <div>
                    <div className="f16 fw-600  mb-7 cl012A4D">
                      Welcome {userName?.host?.name}
                    </div>
                    <div className="f13 fw-400 cl575757">
                      Last Activity: {this.lastActivity()}
                    </div>
                  </div>
                  {/* <div className="add-filter-wrap mr-30">
                    {!roleSubManager() && (
                      <Button
                        color="primary"
                        onClick={() => this.openRecContractor()}
                        size="sm"
                        className="button-wrapping "
                      >
                        Recommend a Service Pro
                      </Button>
                    )}
                  </div> */}
                </div>
                <div className=" invoiceStatusView dashMonthFilter">
                  <UncontrolledDropdown className="status_select_div ">
                    <DropdownToggle className="pr-0 drop_down_container">
                      <Media className="capitalize">
                        {invoiceStatusView === "tm"
                          ? "This Month"
                          : invoiceStatusView === "lm"
                            ? "Last Month"
                            : invoiceStatusView === "ty"
                              ? "This Year"
                              : "All"}
                        <i className="fas fa-angle-down"></i>
                      </Media>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" bottom>
                      {" "}
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setInvoiceStatusView("")}
                      >
                        All
                      </DropdownItem>
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setInvoiceStatusView("tm")}
                      >
                        This Month
                      </DropdownItem>{" "}
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setInvoiceStatusView("lm")}
                      >
                        Last Month
                      </DropdownItem>
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setInvoiceStatusView("ty")}
                      >
                        This Year
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>

              <div className="header-body">
                <Row className="mb-20">
                  <Col className="lg-12">
                    <div className="app-notify-wrapper">
                      <div
                        className="arrow-prev disabled-prev "
                        onClick={(e) => mouseDragLeft(e)}
                      >
                        <i className="scroll_arrow scroll_arrow_left"></i>
                      </div>

                      <div
                        className="apps-notify-box "
                        id="scrollbar-dashboard"
                        onMouseMove={(e) => mouseMoveHandler(e)}
                      // onMouseUp={(e) => mouseUpHandler(e)}
                      // onMouseLeave={(e) => mouseUpHandler(e)}
                      // onMouseDown={(e) => mouseDownHandler(e)}
                      // onScroll={(e) => mouseScroll(e)}
                      // onLoad={() => loadFunction()}
                      >

                        {notifyArray.map((el) => (
                          (alertData[el.key] && alertData[el.key] != 0)
                            ?

                            <div className="apps-notify"
                              onClick={() => this.props.history.push(el.path)}>
                              <div className="mr-12">
                                <img src={el.icon}></img>
                              </div>
                              <div className="mr-12 w-8 fw-500 f15 cl374957">
                                {el.name}
                              </div>
                              <div className="fw-600 f18 mr-10">
                                {alertData[el.key]}
                              </div>
                            </div>
                            : <></>

                        ))}


                      </div>
                      <div
                        className="arrow-next"
                        onClick={(e) => mouseDragRight(e)}
                      >
                        <i className="scroll_arrow scroll_arrow_right"></i>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-20 dash-row1-cards">
                  <div className="dash-card1 w-22 jus-space flex-column pr-0">
                    <div>
                      <Card className="dash-card-wrapper">
                        <CardBody className="dash-card">
                          <Link
                            to={{
                              pathname: "/admin/invoices",
                              merge: "TransactionProp",
                            }}

                          >
                            <div className="flex mb-10">
                              <div>
                                <h4 className="f12 fw-600 cl575757">
                                  TOTAL SALES
                                </h4>

                                <label className="dash-amt margin0">
                                  <CountUp
                                    start={0}
                                    end={returnFirstDegreeObjValue(
                                      detail,
                                      "sales"
                                    )}
                                    separator=","
                                    prefix="$"
                                  />
                                </label>
                              </div>
                              <div className="dash-icon-img">
                                <img
                                  src={TotalSales}
                                  className="img-dash"
                                  alt="total sales"
                                />
                              </div>
                            </div>
                            <div className="flex">

                              <div className={
                                RoleBasedCRUDAccess("invoice", "view")
                                  ? ""
                                  : "disabled-link"
                              }>
                                {" "}
                                <div
                                  className="f11 fw-400 point"
                                  style={{ color: "#00B0FF" }}
                                >
                                  View All <img src={BlueArrow}></img>
                                </div>
                              </div>

                              {this.state.detail &&
                                this.state.detail.lm_sales !== 0 &&
                                (
                                  <>
                                    {" "}
                                    <div className=""
                                      data-tip={`Comparing with last month sales $${this.state.detail.lm_sales}`}
                                      data-iscapture="true"
                                      data-for="workingInfo"
                                      data-place="bottom"
                                    >

                                      <>
                                        <div
                                          id="value"
                                          className={
                                            detail.tm_sales > detail.lm_sales
                                              ? "invoice_badge_increased "
                                              : "invoice_badge_decreased"
                                          }
                                        >
                                          <img
                                            src={
                                              detail.tm_sales > detail.lm_sales
                                                ? InvoiceIncrement
                                                : InvoiceDecrement
                                            }
                                          />
                                          <span
                                            className="f13 fw-600"
                                            style={{ paddingLeft: "5px" }}
                                          >
                                            {
                                              this.state.salesStatus &&
                                              returnPositiveNumber(
                                                this.state.salesStatus
                                              )}
                                            {" "}
                                            %
                                          </span>
                                        </div>
                                      </>
                                      <ReactTooltip
                                        place="left"
                                        type="info"
                                        effect="solid"
                                        id="workingInfo"
                                        html={true}
                                      />
                                    </div>
                                  </>
                                )}
                            </div>
                          </Link>
                        </CardBody>
                      </Card>
                    </div>

                    <div>
                      <Card className="dash-card-wrapper">
                        <CardBody className="dash-card">
                          <Link
                            to={{
                              pathname: "/admin/invoices",
                              merge: "TransactionProp",
                            }}
                          >
                            <div className="flex mb-10">
                              <div className="">
                                <h4 className="f12 fw-600 cl575757">
                                  TOTAL REVENUE
                                </h4>

                                <label className="dash-amt margin0">
                                  <CountUp
                                    start={0}
                                    end={returnFirstDegreeObjValue(
                                      detail,
                                      "sales"
                                    )}
                                    separator=","
                                    prefix="$"
                                  />
                                </label>
                              </div>
                              <div className="dash-icon-img">
                                <img
                                  src={TotalRevenue}
                                  className="img-dash"
                                  alt="total sales"
                                />
                              </div>
                            </div>
                            <div className="flex">

                              <div className={
                                RoleBasedCRUDAccess("invoice", "view")
                                  ? ""
                                  : "disabled-link"
                              }>
                                <div
                                  className="f11 fw-400 point"
                                  style={{ color: "#F7B84A" }}
                                >
                                  View All <img src={YellowArrow}></img>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </CardBody>
                      </Card>
                    </div>

                    <div className=" ">
                      <Card className="dash-card-wrapper">
                        <CardBody className="dash-card">
                          <Link
                            to={{
                              pathname: "/admin/invoices",
                              merge: "InvoiceProp",
                              search: "?status=created",
                            }}
                          >
                            <div className="flex mb-10">
                              <div className="">
                                <h4 className="f12 fw-600 cl575757">
                                  PENDING INVOICES
                                </h4>
                                <label className="dash-amt margin0">
                                  <CountUp
                                    start={0}
                                    end={returnFirstDegreeObjValue(
                                      detail,
                                      "invoice"
                                    )}
                                    separator=","
                                    prefix="$"
                                  />
                                </label>
                              </div>
                              <div className="dash-icon-img">
                                <img
                                  src={TotalInvoice}
                                  className="img-dash"
                                  alt="total sales"
                                />
                              </div>
                            </div>
                            <div className="flex">

                              <div className={
                                RoleBasedCRUDAccess("invoice", "view")
                                  ? ""
                                  : "disabled-link"
                              }>
                                <div
                                  className="f11 fw-400 point"
                                  style={{ color: "#F7B84A" }}
                                >
                                  View All <img src={YellowArrow}></img>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </CardBody>
                      </Card>
                    </div>

                    <div>
                      <Card className="dash-card-wrapper">
                        <CardBody className="dash-card">
                          <Link
                            to={{
                              pathname: "/admin/income",
                              merge: "",
                            }}
                          >
                            <div className="flex mb-10">
                              <div>
                                <h4 className="f12 fw-600 cl575757">INCOME</h4>

                                <label className="dash-amt margin0">
                                  <CountUp
                                    start={0}
                                    end={returnFirstDegreeObjValue(
                                      detail,
                                      "payout"
                                    )}
                                    separator=","
                                    prefix="$"
                                  />
                                </label>
                              </div>

                              <div className="dash-icon-img">
                                <img
                                  src={TotalPayments}
                                  className="img-dash"
                                  alt="total sales"
                                />
                              </div>
                            </div>
                            <div className="flex">

                              <div className={
                                RoleBasedCRUDAccess("invoice", "view")
                                  ? ""
                                  : "disabled-link"
                              }>
                                <div
                                  className="f11 fw-400 point"
                                  style={{ color: "#2CB67D" }}
                                >
                                  View All <img src={GreenArrow}></img>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                  <div className="plr-15" style={{ height: "533px", width: "78%" }}>
                    {" "}
                    <div className=" left_graph">
                      <div className="graph_filter_div mb-30">
                        <p>SALE</p>
                        <div className="flexStart mr-20">
                          <div className="flexStart mr-20">
                            <div className="legend incomeLeg mr-5"></div>
                            <div className="f13 cl333333">Income</div>
                          </div>
                          <div className="flexStart ">
                            <div className="legend saleLeg  mr-5"></div>
                            <div className="f13 cl333333">Sales</div>
                          </div>
                        </div>
                      </div>

                      <div style={{ height: "400px" }}>
                        <Line
                          data={this.state.LineGraphdata}
                          options={this.state.LineGraphOptions}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="dash-card1 pl-0" style={{ width: "28%" }}>
                    <div
                      className="h100 bgFFFFFF dash-card-wrapper todo-container"
                      style={{ height: "533px" }}
                    >
                      <Card className="border-none">
                        <div className="todo-title f15 fw-600 cl012A4D">
                          Todo
                        </div>
                      </Card>

                      <div className="todo-list-container">
                        {todoArray.length > 0 &&
                          todoArray.map((el) => (
                            <div className="todo-list">
                              <div>{el.title}</div>
                              <div>{el.date}</div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div> */}
                </Row>
                <Row className="mb-20">
                  <Col className="lg-6" style={{ paddingRight: "10px" }}>
                    <div className="invite-occupancy-wrapper">
                      {!roleSubManager() ? (
                        <div className="skewbox">
                          <div className="invite-image-block">
                            {/* <img src={InviteImg}></img> */}
                            <Lottie
                              options={ReferFile}
                              height={170}
                              width={170}
                            />
                          </div>
                          <div className="invite-content">
                            <div className="invoice-real-content">
                              <p className="f15 cl012A4D fw-600 text-center">
                                Invite your Friends
                              </p>
                              <p className="f12 cl757575 fw-400 text-center">
                                Just share the link with your friends <br />
                                and family and ask them to signup and receive
                                <br /> rewards for it.
                              </p>
                              <p className="d_flex jus-center">
                                <Button
                                  color="primary"
                                  size="sm"
                                  className="button-wrapping search-wrapper"
                                  onClick={this.openReferPoppop}
                                >
                                  Invite
                                </Button>
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {this.state.isGraphData && (
                        <>
                          {" "}
                          <div className="occupancy-wrapper ">
                            <div style={{ display: "grid" }}>
                              <p className="f15 fw-600 cl012A4D">
                                Property Status
                              </p>
                              <div>
                                <div className="range-block">
                                  <div
                                    className="occupancy-status"
                                    style={{ width: status }}
                                  ></div>
                                </div>
                              </div>

                              <div className="align_center">
                                <p className="align_center mr-19" style={{ cursor: "pointer" }}>
                                  <p
                                    className="occupancy-dot margin0 mr-4p "
                                    style={{
                                      background: "#DCDAE1"
                                    }}
                                  ></p>
                                  <p
                                    className="f14 fw-400 margin0"
                                    style={{ paddingLeft: "7px", color: "#525f7f" }}
                                    onClick={() => this.props.history.push("/admin/properties/?is_occupied=false&limit=10&page=1")}
                                  >
                                    Available {this.state.detail.properties &&
                                      this.state.detail.bookings
                                      ? this.state.detail?.properties -
                                      this.state.detail?.bookings
                                      : ""}
                                  </p>
                                </p>
                                <p className="align_center" style={{ cursor: "pointer" }}>
                                  <p
                                    className="occupancy-dot margin0 "
                                    style={{ background: "#012A4D", cursor: "pointer" }}
                                  ></p>

                                  <p
                                    className="f14 fw-400 margin0"
                                    style={{ paddingLeft: "7px", color: "#525f7f" }}
                                    onClick={() => this.props.history.push("/admin/properties/?is_occupied=true&limit=10&page=1")}
                                  >
                                    Booked {this.state.detail.bookings ?? ""}
                                    {" "}

                                  </p>
                                </p>
                              </div>
                            </div>
                            <Link
                              to={{ pathname: "/admin/properties" }}
                              className="text-center flex-column   jus-center mr-2-5"
                            >
                              <p className="f20 fw-600 cl012A4D margin0">
                                Total Property
                              </p>
                              <p className="f20 fw-600 cl012A4D margin0">
                                {this.state.detail.properties ?? ""}
                              </p>
                            </Link>
                          </div>
                        </>
                      )}
                    </div>
                  </Col>
                  <Col className="lg-6 scroll_bar">
                    {RoleBasedCRUDAccess("request", "view") && (
                      <div className="listing_left_container ">
                        <Card className="dashboard_card">
                          <CardHeader className="white_header disblock border-none bgf9f9f9">
                            <div className="header_with_button scroll_bar">
                              <p className="cl012A4D fw-600">Recent Requests</p>
                              <Link
                                to={{
                                  pathname: "/admin/supplyrequests"
                                }}
                              >
                                <p className="cl525f7f fw-400">View All</p></Link>
                              {/* <Link
                                to={{
                                  pathname: "/admin/requests",
                                }}
                              >
                                {" "}
                                <button className="card_view_button">
                                  View All
                                </button>
                              </Link> */}
                            </div>
                          </CardHeader>
                          {this.state.request_list.length > 0 ? (
                            <>
                              <table className="recent_request_table">
                                {/* <thead className="grey_header">
                                  <tr>
                                    <th>Property</th>
                                    <th>Tenant</th>
                                    <th>Requested On</th>
                                    <th>Status</th>
                                  </tr>
                                </thead> */}
                                <tbody className="grey_table_body">
                                  {this.state.request_list.map((item, i) => (
                                    <tr
                                      className="grey_data"
                                      key={item.request_id}
                                    >
                                      <td
                                        className="first_td "
                                      // onClick={() =>
                                      //   this.redirectDetailPage(item.request_id)
                                      // }
                                      >
                                        <Link
                                          to={{
                                            pathname: `requests/detail/${item.request_id}`,
                                          }}
                                          style={{ color: "#000" }}
                                        >
                                          <p className="f13 fw-500 lh-28 cl939393 margin0 ">
                                            Property
                                          </p>
                                          <p className="f13 fw-500 lh-28 margin0">
                                            {item?.property?.name}
                                          </p>
                                        </Link>
                                      </td>
                                      <td className="capitalize">
                                        <p className="f13 fw-500  lh-28 cl939393 margin0">
                                          Tenant
                                        </p>
                                        <p className=" f13 fw-500  lh-28 margin0">
                                          {returnCustomerFirsLastName(
                                            item,
                                            "user",
                                            "name",
                                            "first",
                                            "last"
                                          )}
                                        </p>
                                      </td>
                                      <td>
                                        <p className="f13 fw-500  lh-28 cl939393 margin0">
                                          Requested On
                                        </p>
                                        <p className=" f13 fw-500  lh-28 margin0">
                                          {showLocaldate(item?.createdAt)}
                                        </p>
                                      </td>

                                      <td className="capitalize ">
                                        {" "}
                                        <div
                                          className={
                                            item.status === "accepted" ||
                                              item.status === "assigned"
                                              ? "text-capitalize green general_status_style"
                                              : item.status === "cancelled" ||
                                                item.status === "rejected"
                                                ? "text-capitalize red general_status_style"
                                                : item.status === "in_progress" ||
                                                  item.status === "pending" ||
                                                  item.status === "scheduled" ||
                                                  item.status === "rescheduled"
                                                  ? "text-capitalize yellow general_status_style"
                                                  : item.status === "completed"
                                                    ? "text-capitalize status_completed general_status_style"
                                                    : item.status === "closed"
                                                      ? "text-capitalize primary general_status_style"
                                                      : "text-capitalize "
                                          }
                                        >
                                          <p className="f13 fw-500 lh-28 cl939393 margin0">
                                            Status
                                          </p>
                                          <p className=" f13 fw-500  lh-28 margin0">
                                            {" "}
                                            {item?.status === "in_progress"
                                              ? "In Progress"
                                              : item?.status}
                                          </p>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </>
                          ) : (
                            <div className="no_data_dashboard">
                              <Lottie
                                options={NodataFound}
                                height={100}
                                width={100}
                              />
                              <div className="mt_20">
                                <p>No Request Found</p>
                              </div>
                            </div>
                          )}
                        </Card>
                      </div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col className="lg-6 scroll_bar">
                    {RoleBasedCRUDAccess("maintenance", "view") && (
                      <div className="listing_left_container">
                        <Card className="dashboard_card">
                          <CardHeader className="white_header bgf9f9f9 disblock border-none ">
                            <div className="header_with_button flex">
                              <p className="cl012A4D fw-600">Maintenance</p>
                              <Link
                                to={{
                                  pathname: "/admin/maintenanceList",
                                }}
                              >
                                <p className="cl525f7f fw-400">View All</p></Link>
                            </div>
                          </CardHeader>
                          {this.state.maintance_history?.length > 0 ? (
                            <>
                              <table className="table_container">
                                {/* <thead className="grey_header">
                                  <tr>
                                    <th>Property</th>
                                    <th>Title</th>
                                    <th>Last Schedule</th>
                                    <th>Next Schedule</th>
                                  </tr>
                                </thead> */}

                                <tbody className="grey_table_body">
                                  {this.state.maintance_history.map(
                                    (item, i) => (
                                      <tr
                                        className="grey_data"
                                        key={item.history_id}
                                      >
                                        <td className="first_td">
                                          <Link
                                            to={{
                                              pathname: `/admin/maintenanceList/maintenance_historys/detail/${item.history_id}`,
                                            }}
                                            style={{ color: "#000" }}
                                          >
                                            <p className="f13 fw-500 lh-28 cl939393 margin0">
                                              Property
                                            </p>
                                            <p className="f13 fw-500 lh-28 margin0">
                                              {item?.property?.name}
                                            </p>
                                          </Link>
                                        </td>

                                        <td>
                                          {" "}
                                          <Link
                                            style={{ color: "black" }}
                                            to={{
                                              pathname: `/admin/properties/maintenance_history/${item.item_id}`,
                                            }}
                                          >
                                            <p className="f13 fw-500 lh-28 cl939393 margin0">
                                              Title
                                            </p>
                                            <p className="f13 fw-500 lh-28 margin0">
                                              {item.service_name}
                                            </p>
                                          </Link>
                                        </td>
                                        <td>
                                          <p className="f13 fw-500 cl939393 lh-28 margin0">
                                            Scheduled Done
                                          </p>
                                          <p className="f13 fw-500 lh-28  margin0">
                                            {showLocaldate(item.service_date)}
                                          </p>
                                        </td>
                                        <td>
                                          <p className="f13 fw-500 lh-28 cl939393 margin0"

                                          >
                                            Status
                                          </p>
                                          <p className={(item.status === "pending" ||
                                            item.status === "in_progress")
                                            ? "status_yellow header_data capitalize f13 fw-500 lh-28 margin0"
                                            : item.status === "assigned" ||
                                              item.status === "accepted" ||
                                              item.status === "completed"
                                              ? "status_completed header_data capitalize f13 fw-500 lh-28 margin0"
                                              : "status_red header_data capitalize"}
                                          >
                                            {item.status}
                                          </p>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </>
                          ) : (
                            <div className="no_data_dashboard">
                              <Lottie
                                options={NodataFound}
                                height={100}
                                width={100}
                              />
                              <div className="mt_20">
                                <p>No maintenance History Found</p>
                              </div>
                            </div>
                          )}
                        </Card>
                      </div>
                    )}
                  </Col>
                  <Col className="lg-6" style={{ paddingLeft: "10px" }}>
                    <div className="recommend-block-wrapper">
                      <div className="recommendbox">
                        <div className="recommend-img-block">
                          <div>
                            <img src={RecommendImg}></img>
                          </div>
                        </div>
                        <div className="recommend-content">
                          <div className="recommend-real-content">
                            <p className="f15 cl012A4D fw-600 text-center">
                              Recommend a Service Pro
                            </p>
                            <p
                              className="f12 cl757575 fw-400 text-center mb-20"
                              style={{ lineHeight: "23px" }}
                            >
                              Please share your recommendations
                              <br /> about the service pro and get
                              <br />
                              rewarded now
                            </p>
                            <p className="d_flex jus-center">
                              {!roleSubManager() && (
                                <Button
                                  color="primary"
                                  onClick={() => this.openRecContractor()}
                                  size="sm"
                                  className="button-wrapping"
                                >
                                  Recommend
                                </Button>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </div >

          <div className="header pt-5 pb-5 dashboard_design_padding">
            <Container fluid className="genaral_div_padding graph_div">
              {/* <div>
                {this.state.isGraphData && (
                  <div className="big_graph_container">
                    <div className="right_graph">
                      <Card className="dashboard_card justify_card">
                        <CardHeader>OCCUPANCY STATUS</CardHeader>
                        <div className="pie_chart">
                          <Chart
                            options={this.state.donut_options}
                            series={this.state.donut_series}
                            type="donut"
                            width={320 + Math.floor(Math.random() * 2) + 1}
                            height="100%"
                          />
                          {this.state.detail.bookings !== 0 &&
                          this.state.detail.properties !== 0 ? (
                            <div className="display_pie_value">
                              <h3>
                                {(
                                  (this.state.detail.bookings /
                                    this.state.detail.properties) *
                                  100
                                ).toFixed()}
                                %
                              </h3>
                              <p>Properties Booked</p>
                            </div>
                          ) : (
                            <div className="display_pie_value">
                              <h3></h3>
                              <p>No Properties Booked</p>
                            </div>
                          )}
                        </div>
                        <div className="pie_label">
                          <div className="individual_label">
                            <div className="label_div">
                              <h3>Total Units</h3>
                            </div>
                            <p>{this.state.detail.properties ?? " "}</p>
                          </div>
                          <div className="individual_label">
                            <div className="label_div">
                              <div className="booked_color_div"></div>
                              <h3>Booked</h3>
                            </div>
                            <p>{this.state.detail.bookings ?? ""}</p>
                          </div>
                          <div className="individual_label">
                            <div className="label_div">
                              <div className="vaccant_color_div"></div>
                              <h3>Available</h3>
                            </div>
                            <p>
                              {this.state.detail.properties -
                                this.state.detail.bookings ?? ""}
                            </p>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                )}
              </div> */}

              <Modal
                className="modal-dialog-centered modal-dialog-recommand-contractor"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter "
                isOpen={RecommendContractorModal}
                toggle={() => this.closeRecContractor()}
              >
                <RecommendContractor close={this.closeRecContractor} />
              </Modal>
            </Container>
            <Modal
              className="modal-dialog-centered modal-dialog-recommand-contractor"
              size="lg"
              aria-labelledby="contained-modal-title-vcenter "
              isOpen={ReferModal}
              toggle={this.closeReferPoppop}
            >
              <Refer close={this.closeReferPoppop} />
            </Modal>
          </div>
        </div >
      </>
    );
  }
}
export default DashboardDesign;
// const mapStateToProps = (state) => {
//   return {
//     notify_count: state.counter.notify_count,
//     notify_list: state.counter.notify_list,
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return {
//     updateNotifyCount: () => dispatch(getNotifyCount()),
//     updateNotifyList: () => dispatch(getNotifyList()),
//   };
// };
// export default connect(mapStateToProps, mapDispatchToProps)(DashboardDesign);
