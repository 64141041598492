import ApiService from "constants/ApiService";
import { showLocaldate } from "constants/utils";
import { returnCustomerFirsLastName } from "constants/utils";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Spinner } from "reactstrap";
import closeIcon from "../../assets/img/brand/close.svg";
import { returnCustomerName } from "constants/utils";

class propertyHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      historyData: [],
      loading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id || prevProps.roomId !== this.props.roomId) {
      if (this.props.open) {
        this.setState({ loading: true });
        this.getPropertyDetail();
      }
    }
  }

  getPropertyDetail = async () => {
    let id = this.props.id;
    let route = id ? `host/properties/history/${id}` : `host/room/history/${this.props.propertyId}/${this.props.roomId}`
    let response = await ApiService.getAPI(route);
    if (response.success) {
      let historyData = response.data.histories;
      this.setState({ historyData, loading: false });
    } else {
      this.setState({ loading: false });
    }
  };

  closeModal = () => {
    this.props.close();
  };

  render() {
    let { historyData, loading } = this.state;
    return (
      <div>
        {this.props.open && (
          <Card className="bnone">
            <CardHeader>
              <div className="flex">
                <div className="fw-500 f16 cl000000">Tenant History</div>

                <img
                  src={closeIcon}
                  onClick={() => this.closeModal()}
                  className="point"
                  alt=""
                />
              </div>
            </CardHeader>
            <CardBody className="pad24p">
              {loading ? (
                <div className="spin-loading3 mt-30">
                  <Spinner type="border" color="#012A4D" />
                </div>
              ) : historyData.length > 0 ? (
                historyData.map((history) => (
                  <div className="historyCard">
                    <Link
                      to={{
                        pathname: `/admin/bookings/detail/${history.booking_id}`,
                      }}
                    >
                      <div className="f14 fw-500 cl374957 mb-20 text-capitalize cursor">
                        {returnCustomerName(history)}
                      </div>
                    </Link>
                    <div className="flex">
                      <div className="w25">
                        <div className="fw-600 f12 cl404040 mb-8">Check-In</div>
                        <div className="cl8B8B8B fw-600 f12 ">
                          {showLocaldate(history.check_in)}
                        </div>
                      </div>
                      <div className="w25">
                        <div className="fw-600 f12 cl404040 mb-8">
                          Check-Out
                        </div>
                        <div className="cl8B8B8B fw-600 f12 ">
                          {showLocaldate(history.check_out)}
                        </div>
                      </div>
                      <div className="w25">
                        <div className="fw-600 f12 cl404040 mb-8">Status</div>
                        <div
                          className={(history.status === "booked" || history.status === "pre_booking") ? "green fw-600 f12 text-capitalize" : history.status === "in_notice"
                            ? "yellow fw-600 f12 text-capitalize" : history.status === "checked_out"
                              ? "red fw-600 f12 text-capitalize" : "fw-600 f12 text-capitalize"
                          }
                        >
                          {history.status === "checked_out" ? "Checked Out" : history?.status === "booked" ? "Booked"
                            : history.status === "pre_booking" ? "Pre Booked" : history?.status === "in_notice" ? " In Notice" : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="flexmid">No History Available</div>
              )}
            </CardBody>
          </Card>
        )}
      </div>
    );
  }
}

export default propertyHistory;
