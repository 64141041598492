import React, { Component } from "react";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Card,
  Table,
  Container,
  Row,
  Col,
  Button,
  InputGroup,
  Dropdown,
  Input,
  Spinner,
  Media,
  FormGroup,
  InputGroupText,
  Breadcrumb,
  Modal,
} from "reactstrap";
// core components
import ApiService from "constants/ApiService";
import { returnFirstDegreeObjValue } from "constants/utils";
import { filterQuery } from "constants/utils";
import { getIsoString } from "constants/utils";
import ToastList from "components/Toast/ToastList";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateSvg from "../../../assets/img/brand/date-pick.svg";
import showMore from "../../../assets/img/brand/showMore.svg";
import CompleteCancel from "components/Modal/CompleteCancelItem";
import moment from "moment";
import { RoleBasedCRUDAccess } from "views/Roles/RolesUtils";
import returnSecondDegreeObjValue from "constants/utils";
import {
  showError,
  showSuccess,
  showLocaldate,
  buildQueryString,
  pushToFilteredPage,
  returnSearchObject,
} from "constants/utils";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { CustomDatePicker } from "components/FilterFields/Filterfields";
import { makeCapitalize } from "constants/utils";

let property_id;

const initialState = {
  title: "",
  recurrence: "",
  pickDay: "",
  fromTime: "",
  toTime: "",
  item_id: "",
  pickDatePicker: "",
  toggleWidth: false,
};

const initialModal = {
  deleteModal: false,
  modalOpen: false,
  item_id: "",
  currenStatus: "",
  notes: "",
};

const MemoizedModal = React.memo(CompleteCancel);
class Maintenance extends Component {
  constructor(props) {
    super(props);
    property_id = this.props.match.params?.property_id;
    this.state = {
      maintenanceList: [],
      total: 0,
      page: 1,
      limit: 10,
      from: "",
      to: "",
      filterToggle: false,
      modalOpen: false,
      inputSearch: "",
      filterOption: "key",
      statusSearch: "",
      toast: false,
      response: "",
      toastType: "",
      initialState,
      initialModal,
      toggleWidth: false,
      history_id: "",
      deleteModal: false,
      isLoading: true,
      query: {},
      btn_enable: true,
      recurrence: "Select",
      pickDay: "Select",
      configAssign: false
    };
  }
  componentDidMount() {
    this.setQueryStatus();
    // this.getPurchaseList();
  }

  // componentDidUpdate(prevProps) {
  //   let search = this.props.location.search;
  //   if (prevProps.location.search !== search) {
  //     this.setQueryStatus();
  //   }
  // }

  resetState = () => {
    this.setState(initialState);
  };
  resetModalState = () => {
    this.setState(initialModal);
  };

  setQueryStatus = () => {
    let queryStatus = this.props.location.search;
    if (queryStatus) {
      let queryObj = returnSearchObject(queryStatus);

      this.setState(
        {
          from: queryObj.from ? new Date(queryObj.from) : "",
          to: queryObj.to ? new Date(queryObj.to) : "",
          filterOption: queryObj.key ? "key" : "",
          inputSearch: queryObj.key ?? "",
          page: parseInt(queryObj.page) ?? "",
          query: returnSearchObject(queryStatus),
        },
        () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getPurchaseList();
        }
      );
    } else {
      this.setState(
        { query: { ...this.state.query, page: 1, limit: 10 } },
        () => {
          pushToFilteredPage(this.props, this.state.query);
          this.getPurchaseList();
        }
      );
    }
  };

  getPurchaseList = async () => {
    let { page, filterOption, inputSearch, from, to } = this.state;
    let queryParams = "";
    if (filterOption !== "" && inputSearch !== "")
      queryParams += filterQuery(filterOption, inputSearch);
    if (from !== "") queryParams += filterQuery("from", getIsoString(from));
    if (to !== "") queryParams += filterQuery("to", getIsoString(to));
    if (from > to) {
      showError(this, "Please provide valid From and To date");
    } else {
      // let response = await ApiService.getAPI(
      //   `host/maintenance/${property_id}?page=${page}&limit=10${queryParams}`
      // );

      let response = await ApiService.getAPI(
        `host/maintenance/${property_id}${buildQueryString(this.state.query)}`
      );

      if (response.success) {
        let { data } = response;
        this.setState({
          maintenanceList: data,
          isLoading: false,
        });
      } else {
        this.setState({ isLoading: false });
        showError(this, response.message);
      }
    }
  };

  pageChange = (page) => {
    var self = this;
    self.setState({ page: page }, () => {
      self.getPurchaseList();
    });
  };

  updateStateValues = (key, value) => {
    const updatedValue = value == null ? "" : value;
    this.setState({ [key]: updatedValue, input_value: true }, () => {
      if (this.state.from && this.state.to) {
        this.handleFilterStatus();
      }
    });
  };

  handleOnToChange = (e) => {
    this.updateStateValues("to", e);
  };
  handleOnFromChange = (e) => {
    this.updateStateValues("from", e);
  };
  onChangeHandler = (e) => {
    e.target.value === "" && this.handleReset();
    this.setState({ [e.target.name]: e.target.value, input_value: true });
    clearTimeout(this.state.timeoutId);
    const timeoutId = setTimeout(() => {
      this.state.filterOption && this.handleSearch();
    }, 1000);
    this.setState({ timeoutId });
  };
  onChangeSelectHandler = (status) => {
    this.setState({ filterOption: status, input_value: true }, () => {
      this.handleFilterStatus();
    });
  }
  handleIncrementPage = () => {
    if (this.state.maintenanceList.length >= 10) {
      this.setState({ page: this.state.page + 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };
  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  setDaysMode = (mode) => {
    this.setState({ recurrence: mode });
  };
  setWeeksMode = (mode) => {
    this.setState({ pickDay: mode });
  };
  handleSearch = () => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  handleReset = () => {
    let self = this;
    self.setState(
      {
        from: "",
        to: "",
        filterOption: "key",
        inputSearch: "",
        query: { page: 1, limit: 10 },
        input_value: false
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getPurchaseList();
      }
    );
  };

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    staticQuery.from = getIsoString(this.state.from) ?? "";
    staticQuery.to = getIsoString(this.state.to) ?? "";
    if (this.state.inputSearch !== "" && this.state.filterOption !== "") {
      staticQuery[this.state.filterOption] = this.state.inputSearch;
    }

    staticQuery.status = this.state.statusSearch ?? "";
    staticQuery.page = this.state.page ?? "";
    this.setState({ query: staticQuery }, () => {
      pushToFilteredPage(this.props, { ...this.state.query });
      this.getPurchaseList();
    });
  };

  toggleCanvas = (maintenance) => {
    let { item_id, service_name, time, occurrences, next_service } =
      maintenance;
    this.setState({
      toggleWidth: true,
      item_id,
    });
    if (item_id) {
      this.setState({
        title: service_name,
        // fromTime: time.from,
        // toTime: time.to,
        recurrence: occurrences.frequency,
        pickDay:
          occurrences.frequency === "weekly" || occurrences.frequency === "bi_weekly"
            ? moment(getIsoString(next_service))
              .format("dddd")
              .split(" ")
              .map((word) => word[0].toLowerCase() + word.slice(1))
              .join(" ")
            : "",
        pickDatePicker:
          occurrences.frequency === "monthly" ||
            occurrences.frequency === "yearly"
            ? new Date(next_service)
            : "",
      });
    }
  };


  toggleCloseCanvas = () => {
    this.resetState();
  };

  handleAddEditMaintenance = async () => {
    let {
      fromTime,
      toTime,
      title,
      recurrence,
      pickDay,
      pickDatePicker,
      item_id, } = this.state;
    var self = this;
    if (title === "" || title === undefined || title === null) {
      showError(this, "Please enter title");
    } else if (recurrence === "" || recurrence === undefined || recurrence === null) {
      showError(this, "Please select recurrence");
    } else if (recurrence === "weekly" || recurrence === "bi_weekly" ? pickDay === "" || pickDay === null || pickDay === undefined : "") {
      showError(this, "Please select day");
    } else if ((recurrence === "monthly" || recurrence === "yearly" || recurrence === "one_time") && (pickDatePicker === "" ||
      pickDatePicker === null || pickDatePicker === undefined)) {
      showError(this, "Please select date");
    } else {
      this.setState({ btnLoad: true, btn_enable: false });
      let data = {
        service_name: title,
        occurrences: {
          value: recurrence === "one_time" ? "once" : "repeat",
          frequency: recurrence,
        },
        date: recurrence === "one_time" ? getIsoString(pickDatePicker) : recurrence === "weekly" || recurrence === "bi_weekly"
          ? pickDay : getIsoString(pickDatePicker),
      };

      let isUpdate = item_id !== "" && item_id !== undefined;
      let response = isUpdate ? await ApiService.patchAPI(`host/maintenance/${item_id}`, data)
        : await ApiService.postAPI(`host/maintenance/${property_id}`, data);
      if (response.success) {
        self.setState({ toggleWidth: false, history_id: response.data.history_id, configAssign: true });
        this.setState({ btnLoad: false, btn_enable: true });
        showSuccess(this, response.message);
      } else {
        this.setState({ btnLoad: false, btn_enable: true });
        showError(this, response.message);
      }
    }
  };

  handleOnPickDate = (e) => {
    this.updateStateValues("pickDatePicker", e);
  };

  toggleModal = (item_id, key) => {
    this.setState({
      modalOpen: true,
      item_id,
      currenStatus: key,
    });
  };

  handleCloseModal = () => {
    this.resetModalState();
  };

  handleCompleteCancel = async () => {
    let { currenStatus, notes, item_id } = this.state;
    let key = currenStatus === "complete" ? "completed" : currenStatus === "cancel" ? "cancelled" : "delete";
    if (key === "delete") {
      let response = await ApiService.deleteAPI(`host/maintenance/${item_id}`);
      if (response.success) {
        showSuccess(this, response.message);
        this.resetModalState();
        this.getPurchaseList();
      }
    } else {
      let data = { notes };
      let response = await ApiService.patchAPI(`host/maintenance/${key}/${item_id}`, data);
      if (response.success) {
        showSuccess(this, response.message);
        this.resetModalState();
        this.getPurchaseList();
      }
    }
  };

  redirectHistoryPage = (id) => {
    this.props.history.push(`/admin/properties/maintenance_history/${id}`);
  };

  toggleAddCanvas = () => {
    this.setState({
      toggleWidth: true,
    });
  };

  toggleDeleteModal = (item_id, currenStatus) => {
    this.setState({
      deleteModal: true,
      currenStatus,
      item_id,
    });
  };
  closeDeleteModal = () => {
    this.setState({
      configAssign: false,
      deleteModal: false,
      currenStatus: "",
      item_id: "",
    });
  };

  closeConfigAssign = () => {
    this.setState({ configAssign: false, });
    this.getPurchaseList();
  }

  toggleConfigAssignModal = () => {
    this.setState({
      configAssign: true,
    });
  };

  confirmConfigAssign = () => {
    this.setState({ configAssign: false });
    this.props.history.push(`/admin/maintenanceList/maintenance_historys/detail/${this.state.history_id}`);
  }

  render() {
    let {
      maintenanceList,
      page,
      inputSearch,
      filterOption,
      isLoading,
      fromTime,
      toast,
      response,
      toastType,
      toggleWidth,
      pickDay,
      pickDatePicker,
      toTime,
      title,
      notes,
      recurrence,
      modalOpen,
      currenStatus,
      deleteModal,
      btnLoad,
      btn_enable,
      from,
      to,
      input_value,
      configAssign,
    } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#012A4D" />
      </div>
    ) : (
      <>
        {toast && <ToastList message={response} type={toastType} />}
        <div>
          <Breadcrumb></Breadcrumb>
        </div>
        <CustomListingContainer>
          <div
            className={toggleWidth ? "opac-div" : "opac-div-initial"}
            onClick={() => this.toggleCloseCanvas()}
          ></div>
          <Card
            className={toggleWidth ? "toggle-sidebar-width" : "toggle-sidebar"}
          >
            <div className={toggleWidth ? "display-content" : "hide-content"}>
              <div className="modal-header maintain-canvas pad_new_col">
                <h5 className="modal-title" id="exampleModalLabel">
                  {this.state.item_id ? "Edit Maintenance Items" : "Add Maintenance Items"}
                </h5>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleCloseCanvas()}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <Col lg="12" className="mt_20 mb_20 pad_new_col">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-first-name"
                  >
                    Title*
                  </label>
                  <Input
                    className="form-control-alternative"
                    value={title}
                    name="title"
                    placeholder="Title"
                    type="text"
                    onChange={this.handleInputChange}
                  />
                </FormGroup>
              </Col>
              <div>
                <label
                  className="form-control-label"
                  htmlFor="input-first-name"
                  style={{ marginLeft: "30px" }}
                >
                  Recurrence*
                </label>
                <div className="invoiceStatusView" style={{ margin: "0px 30px", borderBottom: "1px solid #e1e1e1" }}>
                  <UncontrolledDropdown className="status_select_div border-none" >
                    <DropdownToggle className="pr-0 drop_down_container" style={{ paddingLeft: "0px" }}>
                      <Media className="capitalize" style={{ color: "black", fontSize: "14px" }}>
                        {
                          recurrence === "one_time" ? "One Time"
                            : recurrence === "weekly" ? "Weekly"
                              : recurrence === "bi_weekly" ? "Bi-Weekly"
                                : recurrence === "monthly" ? "Monthly"
                                  : recurrence === "yearly" ? "Yearly" : "Select"}
                        <i className="fas fa-angle-down"></i>
                      </Media>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow w100" bottom>
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setDaysMode("one_time")}
                      >
                        One Time
                      </DropdownItem>
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setDaysMode("weekly")}
                      >
                        Weekly
                      </DropdownItem>

                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setDaysMode("bi_weekly")}
                      >
                        Bi-Weekly
                      </DropdownItem>
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setDaysMode("monthly")}
                      >
                        Monthly
                      </DropdownItem>
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setDaysMode("yearly")}
                      >
                        Yearly
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
              {recurrence === "weekly" || recurrence === "bi_weekly" ? (

                <div>
                  <label
                    className="form-control-label mt_20"
                    htmlFor="input-first-name"
                    style={{ marginLeft: "30px" }}
                  >
                    Day*
                  </label>
                  <div className="invoiceStatusView" style={{ margin: "0px 30px", borderBottom: "1px solid #e1e1e1" }}>
                    <UncontrolledDropdown className="status_select_div border-none" >
                      <DropdownToggle className="pr-0 drop_down_container" style={{ paddingLeft: "0px" }}>
                        <Media className="capitalize" style={{ color: "black", fontSize: "14px" }}>
                          {pickDay === "sunday" ? "Sunday"
                            : pickDay === "monday" ? "Monday"
                              : pickDay === "tuesday" ? "Tuesday"
                                : pickDay === "wednesday" ? "Wednesday"
                                  : pickDay === "thursday" ? "Thursday"
                                    : pickDay === "friday" ? "Friday"
                                      : pickDay === "saturday" ? "Saturday" : "Select"}
                          <i className="fas fa-angle-down"></i>
                        </Media>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow w100" bottom>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setWeeksMode("sunday")}
                        >
                          Sunday
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setWeeksMode("monday")}
                        >
                          Monday
                        </DropdownItem>

                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setWeeksMode("tuesday")}
                        >
                          Tuesday
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setWeeksMode("wednesday")}
                        >
                          Wednesday
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setWeeksMode("thursday")}
                        >
                          Thursday
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setWeeksMode("friday")}
                        >
                          Friday
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setWeeksMode("saturday")}
                        >
                          Saturday
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>
              ) : (
                ""
              )}
              {(recurrence === "monthly" ||
                recurrence === "yearly" ||
                recurrence === "one_time") && (
                  <Col lg="12" className="mt_20 mb_20 pad_new_col">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                      >
                        Date*
                      </label>
                      <InputGroup className="mb-0  flex-content notice-date">
                        <DatePicker
                          selected={this.state.pickDatePicker}
                          dateFormat="MM-dd-yyyy"
                          placeholderText=""
                          className="filter-input-date  new-date-in document-add-date point"
                          onChange={this.handleOnPickDate}
                          name="purchase_date"
                          id="purchase_date"
                          style={{ fontWeight: "500" }}
                          showYearDropdown
                          showMonthDropdown
                          ariaDescribedBy="basic-addon2"
                        />
                        <InputGroupText
                          id="basic-addon2"
                          className="group-date doc-add-group notice-group"
                        >
                          <label
                            className="mb-remove cursor-point"
                            htmlFor="purchase_date"
                          >
                            <img
                              src={DateSvg}
                              alt="Date-Picker Icon"
                              className="date-svg point"
                            />
                          </label>
                        </InputGroupText>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                )}
              {/* <Col lg="12" className="mt_20 mb_20 pad_new_col">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-first-name"
                  >
                    From Time*
                  </label>
                  <select
                    className="form-control-alternative form-control"
                    id="input-first-name"
                    name="fromTime"
                    value={fromTime}
                    onChange={this.handleInputChange}
                  >

                    <option value="">Select</option>
                    {TimeList.map((time, i) => (
                      <option value={time.value} key={i}>
                        {time.time}
                      </option>
                    ))}
                  </select>
                </FormGroup>
              </Col> */}

              {/* <Col lg="12" className="mt_20 mb_20 pad_new_col">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-first-name"
                  >
                    To Time*
                  </label>
                  <select
                    className="form-control-alternative form-control"
                    id="input-first-name"
                    name="toTime"
                    value={toTime}
                    onChange={this.handleInputChange}
                  >
                    <option value="">Select</option>
                    {TimeList.map((time, i) => (
                      <option value={time.value} key={i}>
                        {time.time}
                      </option>
                    ))}
                  </select>
                </FormGroup>
              </Col> */}
              <Row className={toggleWidth ? "submit-side-tab" : "hide-content"}>
                {/* <Col lg="3"> */}
                <Button
                  color="primary"
                  onClick={() => this.toggleCloseCanvas()}
                  size="sm"
                  className="button-wrapping sec-btn-wrapping"
                >
                  Cancel
                </Button>
                {/* </Col> */}
                {/* <Col lg="4"> */}
                <Button
                  color="primary"
                  onClick={
                    btn_enable ? () => this.handleAddEditMaintenance() : ""
                  }
                  size="sm"
                  className="button-wrapping"
                >
                  {btnLoad ? (
                    <div className="spin-loading-save w100 h100">
                      <Spinner type="border" color="#012A4D" />
                    </div>
                  ) : this.state.item_id ? (
                    " Update"
                  ) : (
                    "Create"
                  )}
                </Button>
                {/* </Col> */}
              </Row>
            </div>
          </Card>

          <div className="align-items-center">
            <ListingHeaderLayout label={"Maintenance Item"} styleNo={true}>
              <div className="add-filter-wrap">
                {RoleBasedCRUDAccess("maintenance", "create") && (
                  <Button
                    color="primary"
                    onClick={() => this.toggleAddCanvas()}
                    size="sm"
                    className="button-wrapping"
                  >
                    Add
                  </Button>
                )}
              </div>
            </ListingHeaderLayout>
          </div>
          <div style={{ margin: "20px" }}>
            <InputGroup className="flex-content align-left">
              <div className="filters_container">
                <div className="left_filter_container">
                  <div className="w30">
                    <CustomDatePicker
                      from={from}
                      to={to}
                      fromChange={this.handleOnFromChange}
                      toChange={this.handleOnToChange}
                    />
                  </div>

                  {/* <div className="select_with_input ml-10">
                    <Dropdown
                      variant="input-group"
                      className="input-group-select select_input_drop"
                    >
                      <select
                        id="inputGroupSelect01"
                        className="form_select fixed_select"
                        name="filterOption"
                        value={filterOption}
                        onChange={this.onChangeHandler}
                      >
                        <option value="key">Title</option>
                      </select>
                    </Dropdown>

                    <Input
                      aria-label="Text input with dropdown button"
                      className="input-filter-toggle select_input"
                      value={inputSearch}
                      name="inputSearch"
                      onChange={this.onChangeHandler}
                      placeholder="Search by"
                    />
                  </div> */}
                  <div className="select_with_input status_select_input ml-10">
                    <UncontrolledDropdown className="status_select_div ">
                      <DropdownToggle className="pr-0 drop_down_container">
                        <Media className="capitalize">
                          {filterOption === "key" ? "Title" : "Title"
                          }
                          <i className="fas fa-angle-down" style={{ marginLeft: "20px" }}></i>
                        </Media>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" bottom style={{ minWidth: "130px" }}>
                        {" "}
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.onChangeSelectHandler("key")}
                        >
                          Title
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <Input
                      aria-label="Text input with dropdown button"
                      value={inputSearch}
                      name="inputSearch"
                      placeholder="search by"
                      onChange={this.onChangeHandler}
                    />
                  </div>
                </div>
                {input_value && (inputSearch || (from || to)) &&
                  <div className="right_filter_container">
                    <Button
                      color="primary"
                      onClick={this.handleReset}
                      size="sm"
                      className="button-wrapping search-wrapper content-flex mr-4 button-secondary ml-4 reset-btn"
                    >
                      Clear Filter
                    </Button>
                  </div>
                }
              </div>
            </InputGroup>
          </div>
          <div>
            <div className=" new-table">
              <Card className="shadow card_with_paginator">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">S.No</th>
                      <th scope="col">Title</th>
                      <th scope="col">Last Service</th>
                      <th scope="col">Next Service</th>
                      <th scope="col">Type</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  {maintenanceList.length !== 0 ? (
                    <tbody>
                      {maintenanceList.map((maintenance, i) => (
                        <tr key={i}>
                          <td> {page * 10 - 10 + (i + 1)}</td>
                          <td className="text-capitalize">
                            {returnFirstDegreeObjValue(maintenance, "service_name")}
                          </td>
                          <td className="text-capitalize">
                            {maintenance?.last_service ? showLocaldate(returnFirstDegreeObjValue(maintenance, "last_service")) : "-"}
                          </td>
                          <td>
                            {" "}
                            {showLocaldate(returnFirstDegreeObjValue(maintenance, "next_service"))}
                          </td>

                          <td className="text-capitalize">
                            {makeCapitalize(returnSecondDegreeObjValue(maintenance, "occurrences", "frequency"))}
                          </td>
                          <td>
                            <UncontrolledDropdown nav className="position_u">
                              <DropdownToggle className="pr-0 nav-notice" nav>
                                <Media className="align-items-center">
                                  <img src={showMore} />
                                </Media>
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow min-width-drop"
                                right
                              >
                                {RoleBasedCRUDAccess("maintenance", "edit") && (
                                  <DropdownItem
                                    onClick={() =>
                                      this.toggleCanvas(maintenance)
                                    }
                                  >
                                    <span>Edit</span>
                                  </DropdownItem>
                                )}

                                {maintenance.status === "pending" && (
                                  <>
                                    {RoleBasedCRUDAccess("maintenance", "view") && <DropdownItem divider />}
                                    <DropdownItem
                                      onClick={() =>
                                        this.redirectHistoryPage(
                                          maintenance.item_id
                                        )
                                      }
                                    >
                                      <span>History</span>
                                    </DropdownItem>
                                  </>
                                )}
                                {RoleBasedCRUDAccess("maintenance", "delete") && (
                                  <>
                                    <DropdownItem divider />
                                    <DropdownItem
                                      onClick={() =>
                                        this.toggleDeleteModal(
                                          maintenance.item_id,
                                          "delete"
                                        )
                                      }
                                    >
                                      <span>Delete</span>
                                    </DropdownItem>
                                  </>
                                )}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td className="no-data">
                          No Maintenance Item Available
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              </Card>
            </div>
          </div>
          <div className="align-items-center jus-space new-margin-page paginator_box">
            <div className="paginate-flex-box ">
              <div
                className="paginate-box"
                onClick={() => this.handleDecrementPage()}
              >
                <p
                  className={page !== 1 ? "page_available" : "page_unavailable"}
                >
                  Previous
                </p>
              </div>
              <div
                className="paginate-box"
                onClick={() => this.handleIncrementPage()}
              >
                <p
                  className={
                    maintenanceList.length < 10
                      ? "page_unavailable"
                      : "page_available"
                  }
                >
                  Next
                </p>
              </div>
            </div>
          </div>
          <MemoizedModal
            toggleModal={() => this.toggleModal()}
            closeModal={() => this.handleCloseModal()}
            modalState={modalOpen}
            status={currenStatus}
            notes={notes}
            onChange={this.handleInputChange}
            onSubmit={() => this.handleCompleteCancel()}
          />
          <Modal
            className="modal-dialog-centered"
            isOpen={deleteModal}
            toggle={() => this.toggleDeleteModal()}
          >
            <>
              <div className="modal-header modal-invite">
                <h5
                  className="modal-title text-capitalize"
                  id="exampleModalLabel"
                >
                  Delete Item
                </h5>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.closeDeleteModal()}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <span className="pad-new-invite">
                Are you sure you want to delete this item ?
              </span>

              {/* </div> */}
              <div className="modal-footer">
                <div className="add-filter-wrap ">
                  <div
                    className="filter-wrap mr-3 edit-prop change-profile-pass"
                    onClick={() => this.closeDeleteModal()}
                  >
                    <label className="profile-cancel">Cancel</label>
                  </div>
                </div>
                <Button
                  color="primary"
                  type="button"
                  onClick={() => this.handleCompleteCancel()}
                  className="button-wrapping change-profile-button text-capitalize"
                >
                  submit
                </Button>
              </div>
            </>
          </Modal>
          <Modal
            className="modal-dialog-centered"
            isOpen={configAssign}
            toggle={() => this.toggleConfigAssignModal()}
          >
            <>
              <div className="modal-header modal-invite">
                <h5
                  className="modal-title text-capitalize"
                  id="exampleModalLabel"
                >
                  Maintenance Created
                </h5>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.closeConfigAssign()}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <span className="pad-new-invite">
                Maintenance Item added successfully, Would you like to assign the person now ?
              </span>

              {/* </div> */}
              <div className="modal-footer">
                <div className="add-filter-wrap ">
                  <div
                    className="filter-wrap mr-3 edit-prop change-profile-pass"
                    onClick={() => this.closeConfigAssign()}
                  >
                    <label className="profile-cancel">No</label>
                  </div>
                </div>
                <Button
                  color="primary"
                  type="button"
                  onClick={() => this.confirmConfigAssign()}
                  className="button-wrapping change-profile-button text-capitalize"
                >
                  Yes
                </Button>
              </div>
            </>
          </Modal>
        </CustomListingContainer >
      </>
    );
  }
}

export default Maintenance;
