import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

//Utils
// import { isLoggedIn } from "../constants/Utils";
import { isLoggedIn } from "constants/utils";

//Routers
import PrivateRouter from "./PrivateRouter";
import PublicRouter from "./PublicRouter";
import downloadMobileApp from "views/infoScreen/downloadMobileApp";

class MainRouter extends Component {
  // checkType = () => {
  //   let url = window.location.href;
  //   if (url.includes("myhome")) {
  //     localStorage.setItem("user", "Personal");
  //   } else if (url.includes("host")) {
  //     localStorage.setItem("user", "Rental");
  //   }
  // };

  componentDidMount() {
    // if (process.env.NODE_ENV !== "development")  = () => {};
  }

  render = () => {
    return <>
      <div className='mobileAppSuggestion'>
        <Router>
          <Route path="*" component={downloadMobileApp} />
        </Router>
      </div>
      <div className='mainRouter'>
        {isLoggedIn() ? (
          <Router>
            <PrivateRouter />
          </Router>
        ) : (
          <Router>
            <PublicRouter />
          </Router>
        )}
      </div>
    </>
  };
}

export default MainRouter;
