import React, { Component } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Spinner,
} from "reactstrap";
import ReactTooltip from "react-tooltip";
import ApiService from "constants/ApiService";
import { uploadCloudinary } from "constants/utils";
import { numberRequired } from "constants/utils";
import Home from "../../../assets/img/brand/home.svg";
import Remove from "../../../assets/img/brand/remove.svg";
import propTick from "../../../assets/img/brand/propTick.svg";
import DefaultAmenity from "../../../assets/img/brand/default-amenity.svg";
import { getCloudinaryImage } from "constants/utils";
import { LightgalleryItem } from "react-lightgallery";
import ToastList from "components/Toast/ToastList";
import { handleGoBack } from "constants/utils";
import { showSuccess } from "constants/utils";
import { showError } from "constants/utils";
import { maxLengthCheck } from "constants/utils";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { DropdownCommon } from "components/Dropdown/DropdownCommon";
let room_id;
let property_id;
class AddEditRoom extends Component {
  constructor(props) {
    super(props);
    room_id = this.props.match.params?.room_id;
    property_id = this.props.match.params?.property_id;
    this.state = {
      amenityList: [],
      selectedAmenities: [],
      room_number: "",
      room_type: "",
      beds: "",
      bath: "",
      guests: "",
      price: "",
      imageArray: [],
      step_1: true,
      step_2: false,
      step_3: false,
      toast: false,
      response: "",
      toastType: "",
      imageLoading: false,
      isUpdateLoading: false,
      checkSelectAll: false,
      deposit_required: false,
      deposit_amount: "",
      max_installments: "",
      late_fee_amount: "",
      latefee_required: false,
      btn_enable: true,
      img_value: "",
      tenant_screening: false,
    };
  }
  componentDidMount() {
    room_id && this.getRoomDetail();
    this.getAmenity();
  }
  getRoomDetail = async () => {
    let response = await ApiService.getAPI(`host/room/detail/${room_id}`);
    if (response.success) {
      let {
        beds,
        bathrooms,
        guests,
        price,
        room_number,
        room_type,
        gallery,
        amenities,
        deposit,
        screening
      } = response.data.room;
      this.setState(
        {
          room_number,
          room_type,
          beds,
          bath: bathrooms,
          guests,
          price: price,
          tenant_screening: screening ? screening : false,
          selectedAmenities: amenities,
          imageArray: gallery,
          deposit_amount: deposit && deposit.amount ? deposit.amount : "",
          max_installments:
            deposit && deposit.installments ? deposit.installments : "",
          deposit_required: deposit ? deposit.is_enabled : false,
          late_fee_amount: deposit?.late_fee?.amount ?? "",
          latefee_required: deposit?.late_fee?.is_enabled ?? false,
        },
        () => {
          this.checkSelectAllStatus();
        }
      );
    }
  };
  handleCheckClick = (e, item) => {
    let { selectedAmenities, amenityList } = this.state;

    if (e.target.checked) {
      let amenityArray = this.state.selectedAmenities;
      amenityArray.push(item);
      this.setState({ selectedAmenities: amenityArray });
      if (selectedAmenities.sort().join(",") === amenityList.sort().join(",")) {
        this.setState({ checkSelectAll: true });
      }
    } else {
      let i = this.state.selectedAmenities.findIndex(
        (x) => x.amenity_id === item.amenity_id
      );
      if (i !== -1) {
        let { selectedAmenities } = this.state;
        let values = [...selectedAmenities];
        values.splice(i, 1);
        this.setState({ selectedAmenities: values, checkSelectAll: false });
      }
    }
  };
  handleSelectAllAmenities = (e) => {
    this.setState({ checkSelectAll: e.target.checked });
    if (e.target.checked) {
      this.setState({ selectedAmenities: this.state.amenityList });
    } else {
      this.setState({ selectedAmenities: [] });
    }
  };
  handleCheck = (id) => {
    if (id) {
      return (
        this.state.selectedAmenities.findIndex((x) => x.amenity_id === id) !==
        -1
      );
    } else {
      return false;
    }
  };
  getAmenity = async () => {
    let response = await ApiService.getAPI("host/amenities");
    if (response.success) {
      this.setState({ amenityList: response.data.amenities }, () => {
        this.checkSelectAllStatus();
      });
    }
  };

  checkSelectAllStatus = () => {
    let { amenityList, selectedAmenities } = this.state;

    if (selectedAmenities.sort().join(",") === amenityList.sort().join(",")) {
      this.setState({ checkSelectAll: true });
    }
  };

  // Toast Handler
  handleAddEdit = async () => {
    let {
      room_number,
      room_type,
      price,
      selectedAmenities,
      imageArray,
      beds,
      bath,
      guests,
      step_1,
      step_2,
      step_3,
      deposit_amount,
      deposit_required,
      max_installments,
      latefee_required,
      late_fee_amount,
      tenant_screening,
    } = this.state;
    if (step_1) {
      if (
        room_number === "" ||
        room_number <= 0 ||
        numberRequired(room_number)
      ) {
        showError(this, "Please enter valid room number");
      } else if (room_type === "") {
        showError(this, "Please select valid room type");
      } else if (beds === "" || beds <= 0 || numberRequired(beds)) {
        showError(this, "Please enter valid bed count");
      } else if (bath === "" || bath <= 0 || numberRequired(bath)) {
        showError(this, "Please enter valid bathroom count");
      } else if (guests === "" || guests <= 0 || numberRequired(guests)) {
        showError(this, "Please enter valid guest count");
      } else if (price === "" || price <= 0 || numberRequired(price)) {
        showError(this, "Please enter valid price");
      } else if (
        deposit_required &&
        (deposit_amount === "" ||
          deposit_amount === null ||
          deposit_amount === undefined)
      ) {
        showError(this, "Please enter deposit amount");
      } else if (deposit_required && deposit_amount <= 0) {
        showError(this, "Please enter valid deposit amount");
      } else if (
        deposit_required &&
        (max_installments === "" ||
          max_installments === null ||
          max_installments === undefined)
      ) {
        showError(this, "Please enter max installments");
      } else if (deposit_required && max_installments <= 0) {
        showError(this, "Please enter valid max installments");
      } else if (deposit_required && latefee_required && !late_fee_amount) {
        showError(this, "Please enter late fee amount");
      } else if (deposit_required && latefee_required && late_fee_amount <= 0) {
        showError(this, "Please enter valid late fee amount");
      } else {
        this.setState({ step_1: false, step_2: true });
      }
    }
    if (step_2) {
      if (selectedAmenities.length <= 0) {
        showError(this, "Please select amenities");
      } else {
        this.setState({ step_2: false, step_3: true });
      }
    }
    if (step_3) {
      if (imageArray.length <= 0) {
        showError(this, "Please upload images");
      } else {
        this.setState({ isUpdateLoading: true });

        let data = {
          room_number,
          room_type,
          beds,
          bathrooms: bath,
          guests,
          price,
          gallery: imageArray,
          screening: tenant_screening,
          amenities: selectedAmenities,
          deposit: {
            is_enabled: deposit_required,
            late_fee: { is_enabled: latefee_required },
          },
        };
        if (deposit_required) {
          data["deposit"].amount = parseInt(deposit_amount);
          data["deposit"].installments = parseInt(max_installments);
        }
        if (deposit_required && latefee_required) {
          data["deposit"]["late_fee"].amount = late_fee_amount;
        }
        this.setState({ btn_enable: false });

        if (room_id) {
          let response = await ApiService.patchAPI(
            `host/room/${room_id}`,
            data
          );
          if (response.success) {
            showSuccess(this, response.message);
            this.setState({ isUpdateLoading: false });
            handleGoBack(this.props);
          } else {
            this.setState({ isUpdateLoading: false, btn_enable: true });
            showError(this, response.message);
          }
        } else {
          let response = await ApiService.postAPI(
            `host/room/${property_id}`,
            data
          );
          if (response.success) {
            showSuccess(this, response.message);
            this.setState({ isUpdateLoading: false });
            handleGoBack(this.props);
          } else {
            this.setState({ isUpdateLoading: false, btn_enable: true });
            showError(this, response.message);
          }
        }
      }
    }
  };
  onImageChangeHandler = async (e) => {
    let self = this;
    let { imageArray } = this.state;
    let file = e.target.files[0];

    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "image/gif" ||
      file.type === "image/svg" ||
      file.type === "image/svg+xml"
    ) {
      if (file.size < 512000) {
        this.setState({ imageLoading: true });
        let data = await uploadCloudinary(file, "rooms");
        if (data.url) {
          let images = [...imageArray];
          images.push({
            format: data.format,
            public_id: data.public_id,
            version: `v${data.version}`,
            type: "image",
          });
          self.setState({ imageArray: images, imageLoading: false });
        } else {
          showError(this, "Could not upload image");
        }
      } else {
        showError(this, "File size should not exceeds 512 KB");
      }
    } else {
      showError(this, "Invalid Format");
    }
  };
  deleteImage = (idx) => {
    let { imageArray } = this.state;
    let images = [...imageArray];
    images.splice(idx, 1);
    this.setState({ imageArray: images });
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDepositRequired = (e) => {
    this.setState({
      deposit_amount: "",
      max_installments: "",
      latefee_required: false,
      late_fee_amount: "",
      deposit_required: e
      // JSON.parse(e.target.value),
    });
  };
  handleLateFeeRequired = (e) => {
    this.setState({
      latefee_required: e,
      late_fee_amount: "",
    });
  };
  toggleBack = () => {
    let { step_1, step_2, step_3 } = this.state;
    if (step_1) {
      this.props.history.goBack();
    } else if (step_2) {
      this.setState({ step_1: true, step_2: false });
    } else if (step_3) {
      this.setState({ step_2: true, step_3: false });
    }
  };
  onImageClick = (e) => {
    this.setState({ [e.target.name]: "" });
  };

  onScreening(value) {
    this.setState({
      tenant_screening: value,
    });
  }
  handleRoomType(e) {
    this.setState({ room_type: e, });
  }

  render() {
    let {
      amenityList,
      room_number,
      room_type,
      beds,
      bath,
      guests,
      price,
      step_1,
      step_2,
      step_3,
      toast,
      response,
      toastType,
      imageLoading,
      isUpdateLoading,
      checkSelectAll,
      deposit_amount,
      deposit_required,
      max_installments,
      late_fee_amount,
      latefee_required,
      btn_enable,
      tenant_screening
    } = this.state;
    return (
      <>
        <Container className="mt-8 card-pad-wrapper" fluid>
          {toast && <ToastList message={response} type={toastType} />}
          <div>
            <Breadcrumb></Breadcrumb>
          </div>
          <Row className="align-items-center header-div">
            <Col xs="8" className="manage-con">
              <Breadcrumb>
                <BreadcrumbItem
                  onClick={() => this.props.history.goBack()}
                  className="bread-cursor"
                >
                  Rooms
                </BreadcrumbItem>
                <BreadcrumbItem active style={{ color: "#012a4d" }}>
                  {room_id ? "Update" : "Add"}
                </BreadcrumbItem>
              </Breadcrumb>
            </Col>
            <div className="add-filter-wrap ">
              <div
                className="filter-wrap mr-4 edit-prop"
                onClick={() => this.toggleBack()}
              >
                <label>Previous</label>
              </div>
            </div>
            <div
              className={
                step_3 && room_id
                  ? "text-right btn-exceed"
                  : "text-right col-edit"
              }
              xs="4"
            >
              <Button
                color="primary"
                onClick={btn_enable ? () => this.handleAddEdit() : ""}
                size="sm"
                className="button-wrapping"
              >
                {isUpdateLoading && step_3 && room_id ? (
                  <div className="button_spinner">
                    <Spinner type="border" color="#012A4D" />
                  </div>
                ) : step_3 && !room_id ? (
                  "Create"
                ) : step_3 && room_id ? (
                  "Update"
                ) : (
                  "Next"
                )}
              </Button>
            </div>
          </Row>
          <div className="progress-all">
            <div className="progress-div">
              <div
                className={
                  step_1
                    ? "progress-back-toggle"
                    : step_2
                      ? "progress-back-toggle w_3_rooms"
                      : step_3
                        ? "progress-back-toggle w_6_rooms"
                        : "progress-back-toggle"
                }
              >
                {/* {step_1 ? <img src={Home} /> : <div className="steps-1"></div>} */}
                {/* <div className="steps-1"></div> */}
                {step_1 ? (
                  <div className="img-black image_one_rooms">
                    <img src={Home} className="img-abs" alt="" />
                  </div>
                ) : (!step_1 && step_2) ||
                  (!step_1 && !step_2 && step_3) ||
                  (!step_1 && !step_2 && !step_3) ? (
                  <div className="steps-1-rooms back">
                    <img src={propTick} className="img-abs-prop" alt="" />
                  </div>
                ) : (
                  <div className="steps-1-rooms"></div>
                )}
                {step_2 ? (
                  <div className="img-black image_two_rooms">
                    <img src={Home} className="img-abs" alt="" />
                  </div>
                ) : (!step_1 && !step_2 && step_3) ||
                  (!step_1 && !step_2 && !step_3) ? (
                  <div className="steps-2-rooms back">
                    <img src={propTick} className="img-abs-prop" alt="" />
                  </div>
                ) : (
                  <div className="steps-2-rooms"></div>
                )}
                {step_3 ? (
                  <div className="img-black image_three_rooms">
                    <img src={Home} className="img-abs" alt="" />
                  </div>
                ) : (
                  <div
                    className={
                      !step_1 && !step_2 && !step_3
                        ? "steps-3-rooms back"
                        : "steps-3-rooms"
                    }
                  ></div>
                )}
              </div>
            </div>
          </div>
          <Row className="mb-50">
            <div className="col new-table ">
              {step_1 && (
                <>
                  <Card className="shadow mb-30">
                    <ListingHeaderLayout label={"Basic Information"} />
                    {/* <CardHeader className="border-0">
                      <Row className="align-items-center">
                        <Col xs="12">
                          <h3 className="mb-0">Basic Information</h3>
                        </Col>
                      </Row>
                    </CardHeader> */}
                    <CardBody className="new-card">
                      <Form>
                        <div>
                          <Row>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-username"
                                >
                                  Room Number*
                                </label>

                                <Input
                                  className="form-control-alternative"
                                  value={room_number}
                                  name="room_number"
                                  placeholder="Room Number"
                                  type="number"
                                  onChange={this.handleInputChange}
                                  maxLength="5"
                                  onInput={maxLengthCheck}
                                />
                              </FormGroup>
                            </Col>

                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Room Type*
                                </label>
                                <DropdownCommon
                                  innerStyle={{ marginTop: "13px" }}
                                  status={room_type}
                                  setStatus={(e) => this.handleRoomType(e)}
                                  statusList={[{ label: "Select", value: "" }, { label: "King", value: "King" },
                                  { label: "Master", value: "Master" }, { label: "Private", value: "Private" }]}
                                ></DropdownCommon>
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  No of Beds*
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  value={beds}
                                  name="beds"
                                  id="input-first-name"
                                  onChange={this.handleInputChange}
                                  placeholder="No of Beds"
                                  type="number"
                                  maxLength={5}
                                  onInput={maxLengthCheck}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  No of Bathrooms*
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  value={bath}
                                  name="bath"
                                  id="input-first-name"
                                  onChange={this.handleInputChange}
                                  placeholder="No of Bathrooms"
                                  type="number"
                                  maxLength={5}
                                  onInput={maxLengthCheck}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  No of Guests*
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  value={guests}
                                  name="guests"
                                  id="input-first-name"
                                  onChange={this.handleInputChange}
                                  placeholder="No of Guests"
                                  type="number"
                                  maxLength={5}
                                  onInput={maxLengthCheck}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Rent*
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  value={price}
                                  name="price"
                                  id="input-first-name"
                                  onChange={this.handleInputChange}
                                  placeholder="Price"
                                  type="number"
                                  maxLength={5}
                                  onInput={maxLengthCheck}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <div style={{ display: "flex" }}>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-first-name"
                                  >
                                    Tenant Screening*
                                  </label>
                                  <div
                                    data-tip="Parknstays will do a background verification on behalf of you for whoever books this property"
                                    data-iscapture="true"
                                    data-for="toolTip1"
                                    data-place="top"
                                    className="request_tooltip"
                                  >
                                    <i className="fa fa-info-circle tertiary-circle"></i>
                                  </div>
                                  <ReactTooltip
                                    place="right"
                                    type="info"
                                    effect="solid"
                                    id="toolTip1"
                                    html={true}
                                  />
                                </div>
                                <DropdownCommon
                                  innerStyle={{ marginTop: "13px" }}
                                  status={tenant_screening}
                                  setStatus={(e) => this.onScreening(e)}
                                  statusList={[{ label: "Required", value: true }, { label: "Not Required", value: false }]}
                                ></DropdownCommon>
                                {/* <select
                                  className="form-control-alternative form-control point"
                                  id="input-first-name"
                                  value={tenant_screening}
                                  name="tenant_screening"
                                  onChange={
                                    (e) => this.onScreening(e.target.value)
                                  }
                                >
                                  <option value="true">Required</option>
                                  <option value="false">Not Required</option>
                                </select> */}
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                  <Card className="shadow mt-0">
                    <ListingHeaderLayout label={"Security Deposit"} />
                    <CardBody>
                      <Form>
                        <div>
                          <Row>
                            <Col lg="4" className="mb-3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Is Deposit Required *
                                </label>
                                <DropdownCommon
                                  innerStyle={{ marginTop: "13px" }}
                                  status={deposit_required}
                                  setStatus={this.handleDepositRequired}
                                  statusList={[{ label: "Yes", value: true }, { label: "No", value: false },
                                  ]}
                                />
                              </FormGroup>
                            </Col>
                            {deposit_required && (
                              <>
                                <Col lg="4">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-email"
                                    >
                                      Deposit Amount*
                                    </label>

                                    <Input
                                      className="form-control-alternative"
                                      value={deposit_amount}
                                      name="deposit_amount"
                                      placeholder="Deposit Amount"
                                      type="number"
                                      onChange={this.handleInputChange}
                                      maxLength={10}
                                      onInput={maxLengthCheck}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="4">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-first-name"
                                    >
                                      Max Installments*
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      value={max_installments}
                                      name="max_installments"
                                      onChange={this.handleInputChange}
                                      placeholder="Max Installments"
                                      type="number"
                                      maxLength={2}
                                      onInput={maxLengthCheck}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="4" className="mb-3">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-email"
                                    >
                                      Late Fee Required*
                                    </label>
                                    <DropdownCommon
                                      innerStyle={{ marginTop: "13px" }}
                                      status={latefee_required}
                                      setStatus={this.handleLateFeeRequired}
                                      statusList={[{ label: "Yes", value: true }, { label: "No", value: false },
                                      ]}
                                    />
                                    {/* <select
                                      className="form-control-alternative form-control point"
                                      id="input-first-name"
                                      value={latefee_required}
                                      name="latefee_required"
                                      onChange={this.handleLateFeeRequired}
                                    >
                                      <option value="true">Yes</option>
                                      <option value="false">No</option>
                                    </select> */}
                                  </FormGroup>
                                </Col>
                                {latefee_required && (
                                  <Col lg="4">
                                    <FormGroup>
                                      <label
                                        className="form-control-label"
                                        htmlFor="input-first-name"
                                      >
                                        Late Fee Amount*
                                      </label>
                                      <Input
                                        className="form-control-alternative"
                                        value={late_fee_amount}
                                        name="late_fee_amount"
                                        onChange={this.handleInputChange}
                                        placeholder="Late Fee Amount"
                                        type="number"
                                        maxLength={10}
                                        onInput={maxLengthCheck}
                                      />
                                    </FormGroup>
                                  </Col>
                                )}
                              </>
                            )}
                          </Row>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </>
              )}
              {step_3 && (
                <Card className="shadow mt-4">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <Col xs="6">
                        <h3 className="mb-0">Gallery*</h3>
                      </Col>
                      <Col className="text-right" xs="6">
                        <Button
                          color="primary"
                          size="sm"
                          className="image-hide  button_without_border point"
                        >
                          <Input
                            className="form-control-alternative form-control opac-hide  "
                            type="file"
                            id="imageUpload"
                            accept="image/*"
                            onChange={(event) =>
                              this.onImageChangeHandler(event)
                            }
                            value={this.state.img_value}
                            onClick={(e) => this.onImageClick(e)}
                            placeholder="Enter Name"
                          />
                          Add
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody className="new-card gallery-change-pad pb-5">
                    <Form>
                      <div>
                        <Row>
                          {this.state.imageArray.map((field, idx) => {
                            return (
                              <div key={idx} className="mt_20">
                                <>
                                  <div className="image_upload_display mr_15 image_array_holder">
                                    {" "}
                                    <LightgalleryItem
                                      className="image_upload_display"
                                      src={getCloudinaryImage(
                                        330,
                                        330,
                                        field,
                                        "c_fill"
                                      )}
                                    >
                                      <img
                                        src={getCloudinaryImage(
                                          330,
                                          330,
                                          field,
                                          "c_fill"
                                        )}
                                        className="arrayImg cursor-point "
                                        alt=""
                                      />
                                    </LightgalleryItem>{" "}
                                    <img
                                      src={Remove}
                                      className="ni ni-fat-remove remove_image_icon"
                                      onClick={() => this.deleteImage(idx)}
                                      alt=""
                                    />
                                  </div>
                                </>
                              </div>
                            );
                          })}
                          <div className="image_upload_display">
                            {imageLoading && (
                              <div className="image-loading spinner-cut">
                                <Spinner type="border" color="#012A4D" />
                              </div>
                            )}
                          </div>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              )}
              {step_2 && (
                <Card className="shadow mt-4 mb-5">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <Col xs="6">
                        <h3 className="mb-0">Amenities*</h3>
                      </Col>
                      <Col xs="6">
                        <div className="flexEnd">
                          <div>
                            <Input
                              className="selectAllAmenity"
                              id="selectAll"
                              checked={checkSelectAll}
                              onChange={this.handleSelectAllAmenities}
                              placeholder="Rules"
                              type="checkbox"
                            />
                          </div>
                          <div className="" style={{ marginLeft: "5px" }}>
                            <label
                              htmlFor="selectAll"
                              style={{ margin: "0px" }}
                              className="point"
                            >
                              Select All
                            </label>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody className="new-card">
                    <Form>
                      <div>
                        <div className="pl-lg-4">
                          <Row className="align-items-center mt-3">
                            {amenityList.map((amenity, i) => (
                              <Col lg="4" key={i}>
                                <FormGroup>
                                  <Input
                                    className="form-control-alternative mt-ameni"
                                    id={amenity.amenity_id}
                                    onChange={(e) =>
                                      this.handleCheckClick(e, amenity)
                                    }
                                    checked={this.handleCheck(
                                      amenity.amenity_id
                                    )}
                                    placeholder="Rules"
                                    type="checkbox"
                                  />
                                  <label
                                    className="form-control-label-ameni"
                                    htmlFor={amenity.amenity_id}
                                  >
                                    {amenity?.icon ? (
                                      <img
                                        src={amenity?.icon}
                                        alt=""
                                        className="default-amenity point"
                                      />
                                    ) : (
                                      <img
                                        src={DefaultAmenity}
                                        alt="Default Amenity Icon"
                                        className="default-amenity"
                                      />
                                    )}
                                  </label>
                                  <label
                                    className="form-control-label point"
                                    style={{ marginLeft: "0.5rem" }}
                                    htmlFor={amenity.amenity_id}
                                  >
                                    {amenity.name}
                                  </label>
                                </FormGroup>
                              </Col>
                            ))}
                          </Row>
                        </div>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              )}
            </div>
          </Row>
        </Container>
      </>
    );
  }
}
export default AddEditRoom;
