import React, { Component } from "react";
import ApiService from "constants/ApiService";
import {
  filterQuery,
  returnThirdDegreeObjValue,
  returnFirstDegreeObjValue,
  showLocaldate,
  displayPrice,
  maxLengthCheck,
  returnPaidDeposit,
  showCalendarFormat,
} from "constants/utils";

import {
  Card,
  CardBody,
  CardHeader,
  Table,
  Spinner,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupText,
  Button,
  Input,
} from "reactstrap";
import { displayPercentage } from "constants/utils";
import closeIcon from "../../assets/img/brand/close.svg";
import DateSvg from "../../assets/img/brand/date-pick.svg";
import Select from "react-select";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

class MarkPaymentSideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transferDate: "",
      transferMode: "online_transfer",
      notes: "",
      btnLoading: false,
      deposit_date: "",
      transferAmount: "",
    };
  }
  handleDateChange = (e) => {
    this.setState({ transferDate: e });
  };
  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  setPayMode = (mode) => {
    this.setState({ transferMode: mode });
  };

  closeModal = () => {
    this.props.close();
    this.setState({
      transferMode: "online_transfer",
      transferDate: "",
      notes: "",
    });
  };
  markTransfer = async () => {
    let { transferDate, transferMode, notes, transferAmount } = this.state;
    let { toast, id } = this.props;
    if (
      transferAmount === "" ||
      transferAmount === null ||
      transferAmount === undefined
    ) {
      toast("Please enter transfer amount");
    } else if (parseInt(transferAmount) <= 0) {
      toast("Please enter valid transfer amount");
    } else if (transferAmount > this.props.data.paid_amount) {
      toast("Amount should be lesser than paid amount");
    } else if (
      transferDate === "" ||
      transferDate === null ||
      transferDate === undefined
    ) {
      toast("Please select payment date");
    } else if (
      transferMode === "" ||
      transferMode === null ||
      transferMode === undefined
    ) {
      toast("Please select payment mode");
    } else {
      this.setState({ btnLoading: true });
      let data = {
        transfer_amount: parseInt(transferAmount),
        mode: transferMode,
        date: showCalendarFormat(transferDate),
        notes: notes,
      };
      let response = await ApiService.patchAPI(
        `host/deposits/transfer/${id}`,
        data
      );
      if (response.success) {
        this.props.close(response.message);
        this.setState({
          transferMode: "cash",
          transferDate: "",
          transferAmount: "",
          notes: "",
          btnLoading: false,
        });
      } else {
        toast(response.message);
        this.setState({ btnLoading: false });
      }
    }
  };

  render() {
    let { transferDate, transferMode, notes, btnLoading, transferAmount } =
      this.state;
    let { data } = this.props;
    return (
      <div>
        {this.props.isOpen && (
          <Card className="bnone">
            <CardHeader>
              <div className="flex">
                <div className="fw-500 f16 cl000000">Mark Transfer</div>
                <img
                  src={closeIcon}
                  onClick={() => this.closeModal()}
                  className="point"
                />
              </div>
            </CardHeader>
            <CardBody className="pad30p">
              <div>
                <div className="mb-15 cl2A1E17 f14 fw-400">Overall</div>
                <Input
                  type="number"
                  value={data?.amount ?? ""}
                  name="overall_deposit"
                  className="mb-30"
                  maxLength={5}
                  onInput={maxLengthCheck}
                  readOnly
                />
              </div>
              <div>
                <div className="mb-15 cl2A1E17 f14 fw-400">Paid</div>
                <Input
                  type="number"
                  // value={returnPaidDeposit(detail?.deposit)}
                  value={data?.paid_amount ?? ""}
                  name="paid_deposit"
                  className="mb-30"
                  maxLength={5}
                  onInput={maxLengthCheck}
                  readOnly
                />
              </div>
              <div>
                <div className="mb-15 cl2A1E17 f14 fw-400">
                  Transfer Amount *
                </div>
                <Input
                  type="number"
                  value={transferAmount}
                  name="transferAmount"
                  onChange={this.handleInputChange}
                  //   className={!detail.check_list ? "mb-30" : "mb-50"}
                  className="mb-30"
                  maxLength={6}
                  onInput={maxLengthCheck}
                />
              </div>
              <div>
                <div className="cl2A1E17 mb-15 f14 fw-400">Date *</div>
                <div className="form-relative flexStart mb-30 " style={{ border: "1px solid rgba(225, 225, 225, 0.4)", borderRadius: "5px" }}>
                  <DatePicker
                    selected={transferDate}
                    dateFormat="MM-dd-yyyy"
                    placeholderText=""
                    className="mark_payment_date bnone point"
                    ariaDescribedBy="basic-addon2"
                    onChange={this.handleDateChange}
                    maxDate={new Date()}
                    minDate={new Date().setMonth(new Date().getMonth() - 1)}
                    id="notice_date"
                  />

                  <label className="mb-remove" htmlFor="notice_date">
                    <img
                      src={DateSvg}
                      alt="Date-Picker Icon"
                      className="dateIcon point"
                    />
                  </label>
                </div>
              </div>
              <div>
                <div className="mb-15 cl2A1E17 f14 fw-400">
                  Mode of Payment *
                </div>
                <div className="invoiceStatusView">
                  <UncontrolledDropdown className="status_select_div  mb-30">
                    <DropdownToggle className="pr-0 drop_down_container">
                      <Media className="capitalize">
                        {transferMode === "cash"
                          ? "Cash"
                          : transferMode === "online_transfer"
                            ? "Online Transfer"
                            : transferMode === "cheque"
                              ? "Cheque"
                              : transferMode === "others"
                                ? "Others"
                                : transferMode === "zelle" && "Zelle"}
                        <i className="fas fa-angle-down"></i>
                      </Media>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow w100" bottom>
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setPayMode("online_transfer")}
                      >
                        Online Transfer
                      </DropdownItem>
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setPayMode("cash")}
                      >
                        Cash
                      </DropdownItem>

                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setPayMode("cheque")}
                      >
                        Cheque
                      </DropdownItem>
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setPayMode("zelle")}
                      >
                        Zelle
                      </DropdownItem>
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setPayMode("others")}
                      >
                        Others
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
              <div>
                <div className="mb-15 cl2A1E17 f14 fw-400">Notes</div>
                <Input
                  type="textarea"
                  value={notes}
                  name="notes"
                  rows={4}
                  onChange={this.handleInputChange}
                  className="mb-50"
                  maxLength={50}
                />
              </div>
              <div className="flexEnd">
                <div
                  className="mr-20 point f14 button-secondary"
                  onClick={() => this.closeModal()}
                >
                  Cancel
                </div>
                <Button
                  color="primary"
                  className="button-wrapping "
                  size="sm"
                  onClick={() => this.markTransfer()}
                >
                  {btnLoading ? (
                    <div className="spin-loading-save w100 h100">
                      <Spinner type="border" color="#012A4D" />
                    </div>
                  ) : (
                    <div>Mark Transfer</div>
                  )}
                </Button>
              </div>
            </CardBody>
          </Card>
        )}
      </div>
    );
  }
}

export default MarkPaymentSideBar;
