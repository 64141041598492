import React, { Component } from "react";
import { Button, Card, Input, Spinner, FormGroup, Col, Row } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import { replaceToNormalNumber } from "constants/utils";
import { isInvalidEmail } from "constants/utils";
import { getUSFormat } from "constants/utils";
import ApiService from "constants/ApiService";

class SubManagerSideDragger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phoneno: "",
      btnLoad: false,
      expenseDate: "",
      amount: "",
      image: [],
      description: "",
      imageLoading: false,
      btnLoading: false,
      pageLoading: false,
      expenseDetail: [],
      mode: "manual",
      propertySearch: "",
      proertyDetail: {},
      propertyList: [],
      defaultList: [],
      category: "",
      categorylist: [
        "",
        "Late Fees",
        "Laundry",
        "Storage",
        "Parking",
        "Internet",
        "Appliances",
        "Insurance",
        "Property Tax",
        "Water Bills",
        "Electricity Bill",
        "Others",
      ],
    };
  }
  componentDidMount() {}
  getPropertyLists = async () => {
    let response = await ApiService.getAPI(`host/properties/list`);

    if (response.success) {
      this.setState({
        propertyList: response?.data?.properties,
        defaultList: response?.data?.properties,
      });
    }
  };
  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      // this.setState({ pageLoading: true });
      if (this.props.id) {
        this.getSubManagerDetail();
      } else {
        this.setState({ email: "", phoneno: "", name: "", btnLoading: false });
      }
    }
  }
  // handlePropertySearch = (e) => {
  //   const { defaultList } = this.state;
  //   this.setState({ propertySearch: e.target.value });
  //   let propArr = [...defaultList];

  //   if (e.target.value !== "") {
  //     let searchedArray = searchByName(propArr, e.target.value);
  //     this.setState({ propertyList: searchedArray });
  //   } else {
  //     this.setState({ propertyList: defaultList, propertyDetail: "" });
  //   }
  // };
  // setPropertyName = (property) => {
  //   let propertyObj = property;
  //   propertyObj["id"] = propertyObj["property_id"];
  //   delete propertyObj["property_id"];

  //   this.setState({
  //     propertySearch: property?.name ?? "",
  //     propertyDetail: propertyObj,
  //   });
  // };
  getSubManagerDetail = async () => {
    let { id } = this.props;

    this.setState({ pageLoading: true });

    let response = await ApiService.getAPI(`host/submanager/${id}`);
    if (response.success) {
      let { sub_manager } = response.data;
      this.setState(
        {
          name: sub_manager?.name,
          email: sub_manager?.email,
          phoneno: sub_manager?.phone?.national_number
            ? getUSFormat(sub_manager?.phone?.national_number)
            : "",
        },
        () => {
          this.setState({ pageLoading: false });
        }
      );
    }
  };

  handleAddEditSubManagers = async () => {
    const { name, email, phoneno } = this.state;

    if (name === undefined || name === null || name === "") {
      this.props.toast("Please enter name");
    } else if (email === undefined || email === null || email === "") {
      this.props.toast("Please enter email");
    } else if (isInvalidEmail(email)) {
      this.props.toast("Please enter valid email");
    } else if (phoneno === undefined || phoneno === null || phoneno === "") {
      this.props.toast("Please enter phone number");
    } else if (phoneno.length < 16) {
      this.props.toast("Please enter valid phone number");
    } else {
      let data = {
        name: name,
        email: email,
        phone: {
          country_code: "1",
          national_number: replaceToNormalNumber(phoneno),
        },
      };
      this.setState({ btnLoad: true });
      let response;
      if (this.props.id) {
        let id = this.props.id;
        response = await ApiService.patchAPI(`host/submanager/${id}`, data);
      } else {
        response = await ApiService.postAPI(`host/submanager`, data);
      }
      if (response.success) {
        this.setState({ btnLoad: false, phoneno: "", email: "", name: "" });
        this.props.close(false, response.message);
      } else {
        this.setState({ btnLoad: false });
        this.props.toast(response.message);
      }
    }
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  clear = (msg) => {
    this.props.close(false);
    this.setState({ name: "", email: "", phoneno: "" });
  };

  handleOnPhoneNumberChange = (evt) => {
    this.setState({ phoneno: getUSFormat(evt.target.value) });
  };

  render() {
    let { name, email, phoneno, btnLoad } = this.state;

    return (
      <div>
        {this.props.isOpen && (
          <Card className="h-100vh">
            <div className="h-100">
              <div className="modal-header maintain-canvas pad_new_col">
                <h5 className="modal-title" id="exampleModalLabel">
                  {this.props?.id ? "Edit Sub Manager" : "Add Sub Manager"}
                </h5>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={this.clear}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              {this.state.pageLoading ? (
                <div className="spin-loading-none mt-30">
                  <Spinner type="border" color="#012A4D" />
                </div>
              ) : (
                <>
                  {" "}
                  <Col lg="12" className="mt_20 mb_20 pad_new_col">
                    <FormGroup>
                      <label
                        className="form-control-label submanager_label"
                        htmlFor="input-first-name"
                      >
                        Name*
                      </label>
                      <Input
                        className="form-control-alternative submanager_edit"
                        value={name}
                        name="name"
                        placeholder="Name"
                        type="text"
                        onChange={this.handleInputChange}
                        maxLength={50}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12" className="mt_20 mb_20 pad_new_col">
                    <FormGroup>
                      <label
                        className="form-control-label submanager_label"
                        htmlFor="input-first-name"
                      >
                        Email*
                      </label>
                      <Input
                        className="form-control-alternative submanager_edit"
                        value={email}
                        name="email"
                        placeholder="Email"
                        type="text"
                        onChange={this.handleInputChange}
                        maxLength={50}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12" className="mt_20 mb_20 pad_new_col">
                    <FormGroup>
                      <label
                        className="form-control-label submanager_label"
                        htmlFor="input-first-name"
                      >
                        Phone No*
                      </label>
                      <Input
                        className="form-control-alternative submanager_edit"
                        value={phoneno}
                        name="phoneno"
                        placeholder="Phone no"
                        type="text"
                        maxLength="16"
                        onChange={this.handleOnPhoneNumberChange}
                      />
                    </FormGroup>
                  </Col>
                  <Row className="submit-side-tab mt-50">
                    {/* <Col lg="3"> */}
                    <Button
                      color="primary"
                      onClick={this.clear}
                      size="sm"
                      className="button-wrapping sec-btn-wrapping "
                    >
                      Cancel
                    </Button>
                    {/* </Col> */}
                    {/* <Col lg="4"> */}
                    <Button
                      color="primary"
                      // onClick={
                      //   btn_enable ? () => this.handleAddEditMaintenance() : ""
                      // }
                      onClick={this.handleAddEditSubManagers}
                      size="sm"
                      className="button-wrapping"
                    >
                      {btnLoad ? (
                        <div className="spin-loading-save w100 h100">
                          <Spinner type="border" color="#012A4D" />
                        </div>
                      ) : this.props.id ? (
                        " Update"
                      ) : (
                        "Add"
                      )}
                    </Button>
                  </Row>
                </>
              )}
            </div>
          </Card>
        )}
      </div>
    );
  }
}

export default SubManagerSideDragger;
