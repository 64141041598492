import React, { Component } from "react";
import {
  Container,
  Spinner,
  Row,
  Col,
  Breadcrumb,
  Button,
  Card,
  Table,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Modal,
} from "reactstrap";
import MyRequest from "../../assets/img/brand/requestActiveNew.svg";
import MyProperty from "../../assets/img/brand/propertiesActiveNew.svg";
import MyFinance from "../../assets/img/brand/myAccountIconActiveNew.svg";
import ReactTooltip from "react-tooltip";
import RolesAssignUser from "./AssignUserSideDragger";
import showMore from "../../assets/img/brand/showMore.svg";
import {
  returnFirstDegreeObjValue,
  returnSearchObject,
  showSuccess,
  showError,
  showLocaldate,
  pushToFilteredPage,
  getDefaultProperty,
  buildQueryString,
} from "constants/utils";
import ApiService from "constants/ApiService";

import ToastList from "components/Toast/ToastList";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
class Roles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      roleList: "",
      AssignUserModal: false,
      page: 1,
      limit: 10,
      query: {},
      OpenDeleteModal: false,
      role: "",
      btnload: false,
    };
  }
  componentDidMount() {
    this.setQueryStatus();
    this.reloadPage();
  }
  setQueryStatus = () => {
    let queryStatus = this.props.location.search;
    let default_property = getDefaultProperty();
    if (queryStatus) {
      let queryObj = returnSearchObject(queryStatus);

      this.setState(
        {
          // inputSearch: (queryObj.prop_name || queryObj.property_id) ?? "",
          // filterOption: queryObj.prop_name
          //   ? "prop_name"
          //   : queryObj.property_id
          //   ? "property_id"
          //   : "",
          // statusSearch: queryObj.is_occupied ?? "",
          // managedBy: queryObj.managed_by ?? "",
          page: parseInt(queryObj.page) ?? "",
          query: returnSearchObject(queryStatus),
        },
        () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getRoleList();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...this.state.query,
            page: 1,
            limit: 10,
            // from: getIsoString(this.state.from),
            // to: getIsoString(this.state.to),
            // property_id: default_property,
          },
          // inputSearch: default_property ? default_property : "",
          // filterOption: default_property ? "property_id" : "",
        },
        () => {
          pushToFilteredPage(this.props, this.state.query);

          this.getRoleList();
        }
      );
    }
  };
  getRoleList = async () => {
    let response = await ApiService.getAPI(
      `host/manage_role${buildQueryString(this.state.query)}`
    );

    if (response.success) {
      let roles = response?.data?.roles;

      this.setState({ roleList: roles, isLoading: false });
    }
  };
  reloadPage = () => {
    this.props.history.listen((location, search) => {
      if (location.search === "") {
        this.setState({ query: {} }, () => {
          let query = this.props.location.search;
          query = returnSearchObject(query);
          if (Object.keys(query).length === 0) {
            this.handleReset();
          }
        });
      }
    });
  };
  toggleModalDelete = () => {
    this.setState({ OpenDeleteModal: !this.state.OpenDeleteModal });
  };
  // closeDeleteModal = (value, type) => {
  //   this.setState({ OpenDeleteModal: value });
  // };
  OpenDeleteRoleModal = (role) => {
    this.setState({ OpenDeleteModal: true, role: role });
  };
  handleReset = () => {
    let self = this;

    self.setState(
      {
        query: {
          page: 1,
          limit: 10,
        },
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getRoleList();
      }
    );
  };

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    // for (let key in staticQuery) {
    //   if (key === "prop_name" || key === "property_id") {
    //     delete staticQuery[key];
    //   }
    // }
    // if (this.state.inputSearch !== "" && this.state.filterOption !== "") {
    //   staticQuery[this.state.filterOption] = this.state.inputSearch;
    // }
    // staticQuery.from = getIsoString(this.state.from) ?? "";
    // staticQuery.to = getIsoString(this.state.to) ?? "";
    staticQuery.page = this.state.page ?? "";

    this.setState({ query: staticQuery }, () => {
      pushToFilteredPage(this.props, { ...this.state.query });
      this.getRoleList();
    });
  };
  deleteRole = async () => {
    const { role } = this.state;
    this.setState({ btnload: true });
    let data = {};
    let id = role.role_id;
    let response = await ApiService.deleteAPI(`host/manage_role/${id}`, data);
    if (response.success) {
      this.toggleModalDelete();
      this.setState({ btnload: false });
      showSuccess(this, response.message);
      this.handleFilterStatus();
    } else {
      // this.openDeleteModal(false);
      this.setState({ btnload: false });
      showError(this, response.message);
    }
  };
  handleIncrementPage = () => {
    if (this.state.roleList.length >= 10) {
      this.setState(
        {
          page: this.state.page + 1,
        },
        () => {
          this.handleFilterStatus();
        }
      );
    }
  };
  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 }, () => {
        this.handleFilterStatus();
      });
    }
  };

  redirectPage = () => {
    this.props.history.push("roles/create");
  };
  redirectEditPage = (role_id) => {
    this.props.history.push(`roles/${role_id}`);
  };

  displayMessage = (msg, type) => {
    if (type === true) {
      showSuccess(this, msg);
    } else {
      showError(this, msg);
    }
  };

  AssignUserModal = () => this.setState({ AssignUserModal: true });
  closeAssignUserModal = () => this.setState({ AssignUserModal: false });

  render() {
    const {
      isLoading,
      page,
      roleList,
      AssignUserModal,
      toast,
      response,
      toastType,
      OpenDeleteModal,
      role,
      btnload,
    } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#012A4D" />
      </div>
    ) : (
      <>
        {toast && <ToastList message={response} type={toastType} />}
        <CustomListingContainer>
          <ListingHeaderLayout label={"Role Management"}>
            <div className="flexEnd">
              <div className="add-filter-wrap mr-10">
                <Button
                  size="sm"
                  className="button-wrapping clfff"
                  onClick={() => this.AssignUserModal()}
                >
                  Assign User
                </Button>
              </div>
              <div className="add-filter-wrap ml-0">
                <Button
                  color="primary"
                  size="sm"
                  className="button-wrapping "
                  onClick={() => this.redirectPage()}
                >
                  Add
                </Button>
              </div>
            </div>
          </ListingHeaderLayout>
          <Row>
            <div className="col new-table">
              <Card className="shadow card_with_paginator">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th
                        scope="col"
                      // className={expenseList.length > 0 ? "padl1r" : ""}
                      >
                        Name
                      </th>
                      <th scope="col">Created At</th>
                      {/* <th scope="col">Users</th> */}
                      <th scope="col">Apps</th>
                      <th scope="col">Last updated</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {roleList.length > 0 ? (
                      roleList.map((role) => (
                        <tr>
                          <td style={{ width: "20%" }}>
                            {returnFirstDegreeObjValue(role, "name")}
                          </td>
                          <td>
                            {role.createdAt
                              ? showLocaldate(role.createdAt)
                              : "-"}
                          </td>
                          <td>
                            <div className="roles_app_view">
                              {role?.access["property"]?.view === true ||
                                role?.access["booking"]?.view === true ||
                                role?.access["notice"]?.view === true ||
                                role?.access["tenant"]?.view === true ? (
                                <>
                                  <div
                                    data-tip="My Properties"
                                    data-iscapture="true"
                                    data-for="toolTip1"
                                    data-place="top"
                                    className="request_tooltip"
                                  >
                                    <div className="roles-icon">
                                      <img src={MyProperty} alt=""></img>
                                    </div>
                                  </div>
                                  <ReactTooltip
                                    place="right"
                                    type="info"
                                    effect="solid"
                                    id="toolTip1"
                                    html={true}
                                  />
                                </>
                              ) : (
                                ""
                              )}
                              {role?.access["invoice"]?.view === true ||
                                role?.access["payment"]?.view === true ||
                                role?.access["payout"]?.view === true ||
                                role?.access["deposit"]?.view === true ||
                                role?.access["expense"]?.view === true ? (
                                <>
                                  <div
                                    data-tip="My Finances"
                                    data-iscapture="true"
                                    data-for="toolTip2"
                                    data-place="top"
                                    className="request_tooltip"
                                  >
                                    <div className="roles-icon">
                                      <img src={MyFinance} alt=""></img>
                                    </div>
                                  </div>
                                  <ReactTooltip
                                    place="right"
                                    type="info"
                                    effect="solid"
                                    id="toolTip2"
                                    html={true}
                                  />
                                </>
                              ) : (
                                ""
                              )}
                              {role?.access["request"]?.view === true ||
                                role?.access["maintenance"]?.view === true ? (
                                <>
                                  <div
                                    data-tip="My Requests"
                                    data-iscapture="true"
                                    data-for="toolTip3"
                                    data-place="top"
                                    className="request_tooltip"
                                  >
                                    <div className="roles-icon">
                                      <img src={MyRequest} alt=""></img>
                                    </div>
                                  </div>
                                  <ReactTooltip
                                    place="right"
                                    type="info"
                                    effect="solid"
                                    id="toolTip3"
                                    html={true}
                                  />
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </td>
                          <td>
                            {role.updatedAt
                              ? showLocaldate(role.updatedAt)
                              : "-"}
                          </td>
                          <td>
                            <UncontrolledDropdown nav className="position_u">
                              <DropdownToggle className="pr-0 nav-notice" nav>
                                <Media className="align-items-center">
                                  <img src={showMore} alt="" />
                                </Media>
                              </DropdownToggle>

                              <DropdownMenu
                                className="dropdown-menu-arrow min-width-drop"
                                right
                              >
                                <DropdownItem
                                  onClick={() =>
                                    this.redirectEditPage(role.role_id)
                                  }
                                >
                                  <span>Edit</span>
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem
                                  onClick={() => this.OpenDeleteRoleModal(role)}
                                >
                                  <span>Delete</span>
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="no-data">No Roles Found</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>
          <Row className="align-items-center jus-space new-margin-page paginator_box">
            <div className="paginate-flex-box ">
              <div
                className="paginate-box"
                onClick={() => this.handleDecrementPage()}
              >
                <p
                  className={page !== 1 ? "page_available" : "page_unavailable"}
                >
                  Previous
                </p>
              </div>
              <div
                className="paginate-box"
                onClick={() => this.handleIncrementPage()}
              >
                <p
                  className={
                    roleList.length < 10 ? "page_unavailable" : "page_available"
                  }
                >
                  Next
                </p>
              </div>
            </div>
          </Row>
          <Card
            className={AssignUserModal ? "exportSideBar" : "toggle-sidebar"}
          >
            <RolesAssignUser
              close={this.closeAssignUserModal}
              open={AssignUserModal}
              // Roles={roleList}
              toast={this.displayMessage}
            // toast={this.toastDisplay}
            // id={property_id}
            />
          </Card>
          <Modal
            className="modal-dialog-centered"
            isOpen={OpenDeleteModal}
            toggle={() => this.toggleModalDelete()}
          >
            <>
              <div className="modal-header modal-invite">
                <h5 className="modal-title" id="exampleModalLabel">
                  Delete Role
                </h5>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModalDelete(false)}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <span className="pad-invite">
                {`Are you sure you want to delete ${role?.name} ?`}
              </span>
              <div className="modal-footer">
                <div className="add-filter-wrap ">
                  <div
                    className="filter-wrap mr-3 edit-prop change-profile-pass "
                    onClick={() => this.toggleModalDelete(false)}
                  >
                    <label className="profile-cancel">Cancel</label>
                  </div>
                </div>
                { }
                <Button
                  color="primary"
                  type="button"
                  onClick={() => this.deleteRole()}
                  className="button-wrapping change-profile-button"
                >
                  {btnload ? (
                    <div className="spin-loading-save w100 h100">
                      <Spinner type="border" color="#012A4D" />
                    </div>
                  ) : (
                    <div>Delete</div>
                  )}
                </Button>
              </div>
            </>
          </Modal>
        </CustomListingContainer>

      </>
    );
  }
}
export default Roles;
