import React, { Component } from "react";
import ApiService from "constants/ApiService";
import {
  filterQuery,
  returnThirdDegreeObjValue,
  returnFirstDegreeObjValue,
  showLocaldate,
  displayPrice,
} from "constants/utils";

import {
  Card,
  CardBody,
  CardHeader,
  Table,
  Spinner,
  Row,
  Col,
} from "reactstrap";
import { getIsoString } from "constants/utils";
import { returnCustomerFirsLastName } from "constants/utils";

class InvoiceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceDetail: [],
      page: 1,
      invoiceStatus: "",
      tableLoad: false,
    };
  }

  componentDidUpdate(prevProps) {
    let { data, status } = this.props;
    if (
      data.property_id !== prevProps.data.property_id ||
      status !== prevProps.status
    ) {
      this.setState({ tableLoad: true });
      this.setStatus(status);
    }
  }

  getInvoiceDetail = async () => {
    let { data, status, fromDate, toDate } = this.props;
    let { page, invoiceStatus } = this.state;
    let id = data.property_id;

    let queryParams = "";
    if (id !== "") queryParams += filterQuery("property_id", id);
    if (invoiceStatus !== "")
      queryParams += filterQuery("status", invoiceStatus);
    if (fromDate !== "")
      queryParams += filterQuery("from", getIsoString(fromDate));
    if (toDate !== "") queryParams += filterQuery("to", getIsoString(toDate));
    let response = await ApiService.getAPI(
      `host/invoice?page=${page}&limit=10${queryParams}`
    );
    if (response.success) {
      let invoice = response.data.invoices;
      this.setState(
        {
          invoiceDetail: invoice,
        },
        () => {
          this.setState({ tableLoad: false });
        }
      );
    }
  };

  closeModal = () => {
    this.setState({ invoiceDetail: [], tableLoad: false });
    this.props.close();
  };

  setStatus = (status) => {
    this.setState({ invoiceStatus: status, tableLoad: true, page: 1 }, () => {
      this.getInvoiceDetail();
    });
  };

  handleIncrementPage = () => {
    if (this.state.invoiceDetail.length >= 10) {
      this.setState({ page: this.state.page + 1 }, () => {
        this.getInvoiceDetail();
      });
    }
  };

  handleDecrementPage = () => {
    if (this.state.page !== 1) {
      this.setState({ page: this.state.page - 1 }, () => {
        this.getInvoiceDetail();
      });
    }
  };

  render() {
    let { invoiceDetail, invoiceStatus, tableLoad, page } = this.state;
    let { data } = this.props;
    return (
      <div>
        {this.props.isOpen && (
          <>
            <Card>
              <CardHeader>
                <div className="flex">
                  <div>{data?.name}</div>
                  <button onClick={() => this.closeModal()} className="close">
                    X
                  </button>
                </div>
              </CardHeader>
              <CardBody className="pad1_7">
                <div className="flex status_box">
                  <div
                    className={
                      invoiceStatus === ""
                        ? "flexy-content individual_status_box w_3 point "
                        : "flexy-content w_3 point"
                    }
                    onClick={() => this.setStatus("")}
                  >
                    <div className="mb-4p f14 fw500 clLateFee">Overall</div>
                    <div className="f20 fw500">
                      {data.total !== ""
                        ? displayPrice(data.total)
                        : displayPrice(0)}
                    </div>
                  </div>
                  <div
                    className={
                      invoiceStatus === "paid"
                        ? "flexy-content individual_status_box w_3 point"
                        : "flexy-content w_3 point"
                    }
                    onClick={() => this.setStatus("paid")}
                  >
                    <div className="mb-4p f14 fw500 clpaid">Paid</div>
                    <div className="f20 fw500">
                      {data.paid !== ""
                        ? displayPrice(data.paid)
                        : displayPrice(0)}
                    </div>
                  </div>
                  <div
                    className={
                      invoiceStatus === "created"
                        ? "flexy-content individual_status_box w_3 point"
                        : "flexy-content w_3 point"
                    }
                    onClick={() => this.setStatus("created")}
                  >
                    <div className="mb-4p f14 fw500 clVacated">Unpaid</div>
                    <div className="f20 fw500">
                      {data.unpaid !== ""
                        ? displayPrice(data.unpaid)
                        : displayPrice(0)}
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card className="shadow card_with_paginator">
              {tableLoad ? (
                <div className="spin-loading3 mt-30">
                  <Spinner type="border" color="#012A4D" />
                </div>
              ) : (
                <div>
                  <div>
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Tenant</th>
                          <th scope="col">Created On</th>
                          <th scope="col">Due On</th>

                          <th scope="col">Amount</th>
                        </tr>
                      </thead>

                      {invoiceDetail.length !== 0 ? (
                        <tbody>
                          <>
                            {invoiceDetail.map((invoice, i) => (
                              <tr key={i}>
                                <td className="text-capitalize cursor-point">
                                  {returnCustomerFirsLastName(
                                    invoice,
                                    "user",
                                    "name",
                                    "first",
                                    "last"
                                  )}
                                </td>
                                <td>
                                  {" "}
                                  {showLocaldate(
                                    returnFirstDegreeObjValue(
                                      invoice,
                                      "createdAt"
                                    )
                                  )}
                                </td>
                                <td>
                                  {showLocaldate(
                                    returnFirstDegreeObjValue(invoice, "due")
                                  )}
                                </td>

                                <td>
                                  {" "}
                                  {returnFirstDegreeObjValue(
                                    invoice,
                                    "amount"
                                  ) !== "N/A"
                                    ? displayPrice(
                                        returnFirstDegreeObjValue(
                                          invoice,
                                          "amount"
                                        )
                                      )
                                    : 0}
                                </td>
                              </tr>
                            ))}
                          </>
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td className="no-data">No Invoices Available</td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      )}
                    </Table>
                  </div>

                  <div className="flexEnd paginator_box bbNone ">
                    <div
                      className="paginate-box"
                      onClick={() => this.handleDecrementPage()}
                    >
                      <p
                        className={
                          page !== 1 ? "page_available" : "page_unavailable"
                        }
                      >
                        Previous
                      </p>
                    </div>
                    <div
                      className="paginate-box"
                      onClick={() => this.handleIncrementPage()}
                    >
                      <p
                        className={
                          invoiceDetail.length < 10
                            ? "page_unavailable"
                            : "page_available"
                        }
                      >
                        Next
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </Card>
          </>
        )}
      </div>
    );
  }
}
// {
//   $("#myModal").modal({
//     keyboard: false,
//   });
// }
export default InvoiceModal;
