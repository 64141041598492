import React, { Component } from "react";
import { Card, CardBody, Spinner, Button } from "reactstrap";
import ToastList from "components/Toast/ToastList";
import { showError, showSuccess } from "../../constants/utils";
import ApiService from "constants/ApiService";

class DepositSendReminder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toast: false,
      toastType: "",
      response: "",
      btnLoad: false,
    };
  }
  openSendReminder = async () => {
    let { id } = this.props;

    let response = await ApiService.postAPI(
      `host/deposits/send_reminder/${id}`
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.props.close(response.message);
    } else {
      showError(this, response.message);
    }
  };
  render() {
    let { toast, response, toastType, btnLoad } = this.state;
    return (
      <div>
        {toast && <ToastList message={response} type={toastType} />}

        <Card>
          <CardBody className="pad25">
            <div className="mb-30 flex">
              <div className=" cl262626 fw-600 f16">Send Reminder</div>
              <div
                className="clpaid closeModal fw-500 point"
                onClick={() => this.props.close()}
              >
                x
              </div>
            </div>
            <label className="mb-10 cl555555 f14 fw-400" htmlFor="reason">
              Are you sure you want to send a reminder to this Tenant?
              <br />
            </label>
            <div className="flexEnd">
              <Button
                color="primary"
                size="lg"
                className="button-wrapping search-wrapper  button-secondary ml-4 cancelbtn"
                onClick={() => this.props.close()}
              >
                No
              </Button>
              <Button
                color="primary"
                size="lg"
                className="button-wrapping search-wrapper checkoutSubmit"
                onClick={() => this.openSendReminder()}
              >
                {btnLoad ? (
                  <div className="spin-loading-save w100 h100">
                    <Spinner type="border" color="#012A4D" />
                  </div>
                ) : (
                  "Yes"
                )}
              </Button>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default DepositSendReminder;
