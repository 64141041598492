import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import AuthLayout from "../layouts/Auth";

class PublicRouter extends Component {
  render = () => {
    return (
      <Switch>
        <Route path="/" render={(props) => <AuthLayout {...props} />} />
        <Redirect from="/" to="/login" />
      </Switch>
    );
  };
}

export default PublicRouter;
