import React, { Component } from "react";
import ToastList from "components/Toast/ToastList";
import {
  Card,
  CardBody,
  CardHeader,
  Input,
  Button,
  Spinner,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Media,
} from "reactstrap";
import ReactTooltip from "react-tooltip";
import {
  getUSFormat,
  isInvalidEmail,
  searchByName,
  isInvalidValue,
  replaceToNormalNumber,
  showError,
} from "constants/utils";
import closeIcon from "../../assets/img/brand/Recclose.svg";
import ActiveStarRate from "../../assets/img/brand/ActiveStar.svg";
import InactiveStarRate from "../../assets/img/brand/InactiveStar.svg";
import tickactive from "../../assets/img/brand/tickactive.svg";
import tickinactive from "../../assets/img/brand/tickinactive.png";
import Reccontractor from "../../assets/img/brand/reccontractor.svg";
import ApiService from "constants/ApiService";

class RecommendContractor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contractor_name: "",
      phone: "",
      email: "",
      btnLoading: false,
      step1: true,
      step2: false,
      step3: false,
      step4: false,
      propertyToggle: false,
      starLength: 5,
      starList: [],
      service_review: "",
      categorytoggle: false,
      serviceList: [],
      serviceDetails: "",
      servicename: "",
      propertyName: "",
      propertyDetail: "",
      service_provider_known: "",
      suggestions: [
        { name: "Expert in work", isChecked: false },
        { name: "Good Behaviour", isChecked: false },
      ],
      toast: "",
      response: "",
      toastType: "",
    };
  }
  closeModal = (msg) => {
    this.setState({
      step1: true,
      step2: false,
      step3: false,
      step4: false,
      servicename: "",
      service_review: "",
      propertyName: "",
      propertyDetail: "",
      contractor_name: "",
      phone: "",
      email: "",
      starCount: "",
      service_provider_known: "",
      suggestions: [
        { name: "Option1", isChecked: false },
        { name: "Option2", isChecked: false },
      ],
    });
    this.getStarList();
    this.props.close(msg);
  };
  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleNumberChange = (e) => {
    let number = e.target.value;
    if (number < 0 || number > 100) {
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };
  componentDidMount = () => {
    this.getServiceList();
    this.getStarList();
    this.getPropertyLists();
  };
  // componentDidUpdate = (prevProps) => {
  //   if (prevProps.isOpen !== this.props.isOpen) {
  //     if (this.props.isOpen) {
  //       this.getServiceList();
  //       this.getStarList();
  //       this.getPropertyLists();
  //     }
  //   }
  // };

  getServiceList = async () => {
    let response = await ApiService.getAPI("host/services?status=approved");
    if (response.success) {
      this.setState({
        serviceList: response?.data?.services,
        defaultserviceList: response?.data?.services,
      });
    }
  };
  getStarList = () => {
    const { starLength } = this.state;
    let filledArray = [...new Array(starLength)].fill(InactiveStarRate);
    this.setState({ starList: filledArray });
  };
  getPropertyLists = async () => {
    let response = await ApiService.getAPI(`host/properties/list`);
    let new_prop_list = response?.data?.properties;
    new_prop_list.map((property) => {
      property["id"] = property["property_id"];
      delete property["property_id"];
    });
    if (response.success) {
      this.setState({
        propertyList: new_prop_list,
        defaultList: new_prop_list,
      });
    }
  };
  propertyToggle = () => {
    let { propertyToggle, defaultList } = this.state;
    this.setState({
      propertyToggle: !propertyToggle,
      propertySearch: "",
      propertyList: defaultList,
    });
  };
  previous = () => {
    let { step2, step3, step4 } = this.state;
    if (step2) {
      this.setState({ step2: false, step1: true });
    } else if (step3) {
      this.setState({ step3: false, step2: true });
    } else if (step4) {
      this.setState({ step4: false, step3: true });
    }
  };
  setPropertyName = (property) => {
    const { defaultList } = this.state;
    let propArr = [...defaultList];
    this.setState({
      propertyName: property?.name ?? "",
      propertyDetail: property ?? "",
    });
  };
  handlePropertySearch = (e) => {
    let { defaultList } = this.state;
    let propArr = [...defaultList];
    this.setState({ propertySearch: e.target.value });
    if (e.target.value !== "") {
      let searchedArray = searchByName(propArr, e.target.value);
      this.setState({ propertyList: searchedArray });
    } else {
      this.setState({ propertyList: defaultList });
    }
  };
  handleServiceSearch = (e) => {
    const { defaultserviceList } = this.state;
    let servArr = [...defaultserviceList];
    this.setState({ serviceSearch: e.target.value });
    if (e.target.value !== "") {
      let searchedArray = searchByName(servArr, e.target.value);
      this.setState({ serviceList: searchedArray });
    } else {
      this.setState({ serviceList: defaultserviceList });
    }
  };
  removeProp = () => {
    this.setState({ propertyName: "", propertyDetail: "" });
  };
  removeCategory = () => {
    this.setState({ servicename: "" });
  };
  handleOnPhoneNumberChange = (e) => {
    this.setState({ [e.target.name]: getUSFormat(e.target.value) });
  };
  toggleCategory = () => {
    const { categorytoggle } = this.state;
    this.setState({ categorytoggle: !categorytoggle });
  };
  submit = async () => {
    const {
      contractor_name,
      email,
      phone,
      propertyName,
      propertyDetail,
      step1,
      step2,
      step3,
      step4,
      servicename,
      starCount,
      service_review,
      service_provider_known,
      suggestions,
      serviceDetails,
    } = this.state;

    if (step1) {
      if (
        (contractor_name && contractor_name.length < 3) ||
        (contractor_name && isInvalidValue(contractor_name))
      ) {
        showError(this, "Please enter valid name");
      } else if (!email && !phone) {
        showError(this, "Please enter email or phone number");
      } else if (email && isInvalidEmail(email)) {
        showError(this, "Please enter a valid email");
      } else if (phone && phone.length < 16) {
        showError(this, "Please enter a valid phone number");
      } else {
        this.setState({ step2: true, step1: false });
      }
    }

    if (step2) {
      if (!servicename) {
        showError(this, "Please select category");
      } else {
        this.setState({ step2: false, step3: true });
      }
    }
    if (step3) {
      if (!propertyName && !propertyDetail) {
        showError(this, "Please select property");
      } else if (!service_provider_known) {
        showError(
          this,
          "Please enter how long have you known this service pro "
        );
      } else if (parseInt(service_provider_known) === 0) {
        showError(this, "Please enter valid years");
      } else {
        this.setState({ step3: false, step4: true });
      }
    }
    if (step4) {
      let enable_suggestions = [];
      suggestions.forEach((el) => {
        if (el.isChecked) {
          enable_suggestions.push(el.name);
        }
      });
      if (!starCount) {
        showError(this, "Please give rating to service pro");
      }
      //  else if (!service_review) {
      //   this.props.toast("Please share your review on service pro");
      // }
      else if (service_review && service_review.length < 3) {
        showError(this, "Please enter valid review");
      }
      // else if (enable_suggestions.length === 0) {
      //   this.props.toast("Please suggest below from this options");
      // }
      else {
        this.setState({ btnLoading: true });
        let data = {
          name: contractor_name,
          phone: {
            country_code: "1",
            national_number: replaceToNormalNumber(phone),
          },
          email: email,
          property: propertyDetail,
          rating: starCount,
          review: service_review,
          category: {
            id: serviceDetails.service_id,
            name: serviceDetails.name,
          },
          suggestions: enable_suggestions,
          known: parseInt(service_provider_known),
        };
        let response = await ApiService.postAPI("host/recommend/", data);
        if (response.success) {
          this.setState({ btnLoading: false });
          this.closeModal(response.message);
        } else {
          this.setState({ btnLoading: false });
          showError(this, response.message);
        }
      }
    }
  };
  applyStarRate = (count) => {
    const { starList } = this.state;
    this.setState({ starCount: count });
    let newStarList = [];
    starList.forEach((star, index) => {
      if (index < count) {
        newStarList.push(ActiveStarRate);
      } else {
        newStarList.push(InactiveStarRate);
      }
    });
    this.setState({ starList: newStarList });
  };
  setCatergory = (service) => {
    let { defaultserviceList } = this.state;
    this.setState({
      servicename: service?.name,
      serviceDetails: service,
      serviceList: defaultserviceList,
    });
  };
  step2 = () => {
    let { step1, step2, step3, step4 } = this.state;
    let result = false;
    if (
      (!step1 && step2) ||
      (!step1 && !step2 && step3) ||
      (!step1 && !step2 && !step3 && step4)
    ) {
      result = true;
    }
    return result;
  };
  step3 = () => {
    let { step1, step2, step3, step4 } = this.state;
    let result = false;
    if ((!step1 && !step2 && step3) || (!step1 && !step2 && !step3 && step4)) {
      result = true;
    }
    return result;
  };
  step4 = () => {
    let { step1, step2, step3, step4 } = this.state;
    let result = false;
    if (!step1 && !step2 && !step3 && step4) {
      result = true;
    }
    return result;
  };
  checkSuggestion = (e, el, ind) => {
    let { suggestions } = this.state;
    suggestions[ind].isChecked = !suggestions[ind].isChecked;
    this.setState({ suggestions });
  };
  render() {
    const {
      contractor_name,
      phone,
      email,
      btnLoading,
      step1,
      step2,
      step3,
      step4,
      propertyList,
      propertySearch,
      propertyName,
      starList,
      service_review,
      serviceList,
      servicename,
      categorytoggle,
      service_provider_known,
      toast,
      response,
      toastType,
    } = this.state;
    return (
      <>
        <div className="just-flex minh-600">
          {toast && <ToastList message={response} type={toastType} />}
          <div className="w-42" style={{ position: "relative" }}>
            <Card className="rec-block-1 ">
              <p className="cl012A4D fw-600 f20 mt-115">
                Recommend a Service Pro
              </p>
              <p className="cl909899 fw-400 f13" style={{ marginTop: "-12px" }}>
                Please share your recommendations about the service pro and get
                rewarded now
              </p>
              <div className="mt-30">
                <img src={Reccontractor} alt=""></img>
              </div>
            </Card>
          </div>
          <div className="w-58">
            <Card className="rec-block-2">
              {/* <CardHeader>
                {" "}
                <div className="flex">
                  <div className="fw-500 f13 cl000000">
                    Recommend Contractor
                  </div>
                  <img
                    src={closeIcon}
                    onClick={() => this.closeModal()}
                    className="point"
                    alt=""
                  />
                </div>
              </CardHeader> */}
              <CardBody>
                <div className="sidebar-progress-all">
                  <div className="sidebar-progress-div">
                    <div
                      className={
                        step1
                          ? "sidebar-progress-back-toggle "
                          : step2
                          ? "sidebar-progress-back-toggle  w_3"
                          : step3
                          ? "sidebar-progress-back-toggle  w_6"
                          : step4
                          ? "sidebar-progress-back-toggle  w_9"
                          : "sidebar-progress-back-toggle "
                      }
                    ></div>
                    <div className="flex">
                      <div className="side-img-block">
                        <img src={tickactive} alt="" />
                      </div>
                      <div className="side-img-block">
                        {this.step2() ? (
                          <img src={tickactive} alt="" />
                        ) : (
                          <img src={tickinactive} alt="" />
                        )}
                      </div>
                      <div className="side-img-block">
                        {this.step3() ? (
                          <img src={tickactive} alt="" />
                        ) : (
                          <img src={tickinactive} alt="" />
                        )}
                      </div>
                      <div className="side-img-block">
                        {this.step4() ? (
                          <img src={tickactive} alt="" />
                        ) : (
                          <img src={tickinactive} alt="" />
                        )}
                      </div>
                    </div>
                    <div className="flex step_name">
                      <div className="f12 cl012A4D">Step 1</div>
                      <div
                        className={
                          this.step2()
                            ? "f12 cl012A4D step_name_each"
                            : "f12 cl606060 step_name_each"
                        }
                      >
                        Step 2
                      </div>
                      <div
                        className={
                          this.step3() ? "f12 cl012A4D" : "f12 cl606060 "
                        }
                      >
                        Step 3
                      </div>
                      <div
                        className={
                          this.step4() ? "f12 cl012A4D" : "f12 cl606060 "
                        }
                      >
                        Step 4
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
              <CardBody className="modal-footer-notice">
                <div
                  className={
                    step1
                      ? "mb-5p cl2A1E17 f18 fw-500"
                      : "mb-30 cl2A1E17 f18 fw-500"
                  }
                >
                  {step1
                    ? "Basic Details"
                    : step2
                    ? "Select the work Category"
                    : step3
                    ? "Select Property"
                    : step4
                    ? "Your Recommendations"
                    : ""}
                </div>

                {step1 && (
                  <>
                    <div>
                      <div className="mb-15 cl909899 f14 fw-400">
                        Enter the basic details and click the next button
                      </div>
                      <div className="mb-15 cl2A1E17 f14 fw-400">Name</div>
                      <Input
                        type="text"
                        value={contractor_name}
                        name="contractor_name"
                        onChange={this.handleInputChange}
                        className="mb-30 cl212121"
                        maxLength={100}
                        placeholder="Name"
                      />
                    </div>
                    <div>
                      <div className="mb-15 cl2A1E17 f14 fw-400">
                        Phone Number *
                      </div>

                      <Input
                        className="mb-30 cl212121"
                        value={phone}
                        name="phone"
                        onChange={this.handleOnPhoneNumberChange}
                        placeholder="Phone Number"
                        type="text"
                        maxLength="16"
                      />
                    </div>
                    <div>
                      <div className="mb-15 cl2A1E17 f14 fw-400">Email *</div>

                      <Input
                        className="mb-40 cl212121"
                        value={email}
                        name="email"
                        onChange={this.handleInputChange}
                        placeholder="Email"
                        type="text"
                        maxLength="100"
                      />
                    </div>
                  </>
                )}
                {step2 && (
                  <>
                    <div className="cl2A1E17 mb-15 f14 fw-400">
                      Please select which category the service pro worked *
                    </div>
                    <Dropdown
                      className="status_select_div w_100 mb-30"
                      toggle={() => this.toggleCategory()}
                      isOpen={categorytoggle}
                    >
                      <DropdownToggle className=" drop_down_container category-dropdown">
                        <Media
                          className={
                            servicename === ""
                              ? "cl939393 f14 fw-400"
                              : "property_name "
                          }
                          style={{
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <span>
                            {servicename === ""
                              ? "Select Category"
                              : servicename}
                          </span>
                          {servicename !== "" ? (
                            <div onClick={() => this.removeCategory()}>
                              <i className="fa fa-close"></i>
                            </div>
                          ) : (
                            <i className="fas fa-angle-down"></i>
                          )}
                        </Media>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow w100" bottom>
                        <div
                          className="drpDownshad w_100 box-shadow-unset "
                          // style={{ boxShadow: "none !important" }}
                        >
                          <div
                            className="sidedrag_input"
                            style={{ borderRadius: "4px" }}
                          >
                            <Input
                              value={propertySearch}
                              onChange={this.handleServiceSearch}
                              placeholder="search..."
                              className="sidedrag_input mb-10"
                              style={{ borderRadius: "4px" }}
                            />
                          </div>
                          {serviceList?.length > 0 ? (
                            serviceList.map((service, index) => {
                              return (
                                <DropdownItem
                                  className="dropdown_item "
                                  key={index}
                                  onClick={() => this.setCatergory(service)}
                                >
                                  {service?.name}
                                </DropdownItem>
                              );
                            })
                          ) : (
                            <DropdownItem className="flexmid cl757575 f14">
                              No Category Found
                            </DropdownItem>
                          )}
                        </div>
                      </DropdownMenu>
                    </Dropdown>
                  </>
                )}
                {step3 && (
                  <>
                    <div className="cl2A1E17 mb-15 f14 fw-400">
                      Please select which property the service pro worked *
                    </div>
                    <Dropdown
                      className="w-100"
                      toggle={(e) => this.propertyToggle(e)}
                      isOpen={this.state.propertyToggle}
                    >
                      <DropdownToggle className="navBarDropDown mb-30 w-100 rec_dropdown">
                        <Media
                          className={
                            propertyName === ""
                              ? "cl939393 f14 fw-400 property_box"
                              : "property_name property_box"
                          }
                        >
                          <span>
                            {propertyName !== ""
                              ? propertyName
                              : "Select Property"}
                          </span>
                          {propertyName !== "" ? (
                            <div onClick={() => this.removeProp()}>
                              <i className="fa fa-close"></i>
                            </div>
                          ) : (
                            <i className="fas fa-angle-down"></i>
                          )}
                        </Media>
                      </DropdownToggle>
                      <DropdownMenu className="pad0p w_100">
                        <div className="drpDownshad w_100">
                          <div
                            className="sidedrag_input"
                            style={{ borderRadius: "4px" }}
                          >
                            <Input
                              value={propertySearch}
                              onChange={this.handlePropertySearch}
                              placeholder="search..."
                              className="sidedrag_input mb-10"
                              style={{ borderRadius: "4px" }}
                            />
                          </div>

                          {propertyList?.length > 0 ? (
                            propertyList.map((prop, i) => (
                              <DropdownItem
                                className="navBarPropName"
                                key={i}
                                onClick={() => this.setPropertyName(prop)}
                              >
                                <div className="flex ">
                                  <div
                                    data-for={prop.property_id}
                                    className="  mr-10 add_ellipse"
                                    data-tip={prop.name}
                                    data-place="right"
                                  >
                                    {prop.name}
                                  </div>
                                </div>
                                {prop.name.length > 18 && (
                                  <ReactTooltip
                                    id={prop.property_id}
                                    className="propNameTooltip"
                                    place="right"
                                    type="info"
                                    effect="solid"
                                    html={true}
                                  />
                                )}
                              </DropdownItem>
                            ))
                          ) : (
                            <DropdownItem className="flexmid cl757575 f14">
                              No Property Found
                            </DropdownItem>
                          )}
                        </div>
                      </DropdownMenu>
                    </Dropdown>{" "}
                    <div className="cl2A1E17 mb-15 f14 fw-400">
                      How long have you known this service pro ? *
                    </div>
                    <Input
                      type="number"
                      className="mb-40 cl212121"
                      value={service_provider_known}
                      name="service_provider_known"
                      onChange={this.handleNumberChange}
                      placeholder="In years"
                    />
                  </>
                )}

                {step4 && (
                  <>
                    <div className="mb-15 cl2A1E17 f14 fw-400">
                      Rate Service pro *
                    </div>
                    <div className="flex-center mb-6">
                      {starList.map((star, index) => (
                        <div className="stars">
                          <img
                            src={star}
                            onClick={() => this.applyStarRate(index + 1)}
                            alt=""
                          ></img>
                        </div>
                      ))}
                    </div>

                    <div className="mb-15 cl2A1E17 f14 fw-400">
                      {/* Please share your recommendations here */}
                      Your review on Service Pro
                    </div>

                    <Input
                      type="textarea"
                      value={service_review}
                      name="service_review"
                      onChange={this.handleInputChange}
                      className="mb-30 textarea cl212121"
                      maxLength={300}
                      placeholder="Type here..."
                    />
                    <div className="mb-30 cl2A1E17 f14 fw-400">
                      Your suggestions
                    </div>
                    <div className="just-flex mb-30">
                      {this.state.suggestions.map((el, ind) => (
                        <div className="just-flex w-50 curse_point">
                          <Input
                            type="checkbox"
                            className="role_manage_checkbox"
                            checked={el.isChecked}
                            onChange={(e) => this.checkSuggestion(e, el, ind)}
                          />
                          <div
                            className="ml-10 f13 cl616161 fw-500"
                            onClick={(e) => this.checkSuggestion(e, el, ind)}
                          >
                            {el.name}
                          </div>
                        </div>
                      ))}

                      {/* <div className="just-flex w-50">
                        <Input
                          type="checkbox"
                          className="role_manage_checkbox"
                        />
                        <div className="ml-10 f13 cl616161 fw-500">item2</div>
                      </div> */}
                    </div>
                  </>
                )}
                <div className="flexEnd mt-20">
                  <div
                    className="mr-20 point f14 button-secondary"
                    onClick={
                      step1 ? () => this.closeModal() : () => this.previous()
                    }
                  >
                    {step1 ? "Cancel" : "Back"}
                  </div>
                  <Button
                    color="primary"
                    className="button-wrapping "
                    size="sm"
                    onClick={() => this.submit()}
                  >
                    {btnLoading ? (
                      <div className="spin-loading-save w100 h100">
                        <Spinner type="border" color="#012A4D" />
                      </div>
                    ) : (
                      <div>{step4 ? "Finish" : "Next"}</div>
                    )}
                  </Button>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </>
    );
  }
}
export default RecommendContractor;
