import React, { Component } from "react";
import build from "../../assets/img/icons/common/building.svg";
import { Link } from "react-router-dom";
import { Card, CardHeader, Modal, Spinner } from "reactstrap";
import time from "../../assets/img/icons/common/clock.svg";
import deleteIcon from "../../assets/img/brand/removeForm.svg";
import EditIcon from "../../assets/img/brand/edit.svg";
import closeIcon from "../../assets/img/brand/close.svg";
import moment from "moment";
import rectArrow from "../../assets/img/brand/rectArrow.svg";
import DeleteConfirmation from "./DeleteConfirmation";
class EventListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      serviceData: "",
    };
  }

  componentDidUpdate(prevProps) {}

  convertTime = (data) => {
    return data ? moment(data).format("h:mm A") : "";
  };
  closeModal = () => {
    this.props.close(false);
  };

  openConfirmation = (service) => {
    this.setState({ openModal: true, serviceData: service });
  };

  closeConfirmation = (msg) => {
    this.setState({ openModal: false, serviceData: "" });
    if (msg) {
      this.props.delete(msg);
    }
  };

  render() {
    let {
      maintainenceList,
      requestList,
      googEventsList,
      isOpen,
      loading,
      status,
    } = this.props;
    let { openModal, serviceData } = this.state;
    return (
      <>
        {isOpen && (
          <Card className="bnone">
            <CardHeader>
              <div className="flex">
                <div className="fw-500 f16 cl000000">
                  {status === "google"
                    ? " Google Events "
                    : status === "maintenance"
                    ? "Scheduled Maintenance"
                    : status === "request"
                    ? "Scheduled Requests"
                    : "Calender Events"}
                </div>

                <img
                  src={closeIcon}
                  onClick={() => this.closeModal()}
                  className="point"
                  alt=""
                />
              </div>
            </CardHeader>
            <div className="events_tab">
              {loading && (
                <div className="spin-loading3 calenderSpin">
                  <Spinner type="border" color="#012A4D" />
                </div>
              )}
              <div
                className={
                  loading ? "events_details blurback" : "events_details"
                }
              >
                <div className="event_container">
                  {" "}
                  {status === "all" && (
                    <div className="event_title">Scheduled Maintenance</div>
                  )}
                  {(status === "all" || status === "maintenance") && (
                    <div className="minh-100">
                      {maintainenceList.length > 0 ? (
                        <div>
                          {maintainenceList.map((service) => (
                            <Link
                              className="linkDiv"
                              to={`/admin/maintenances/detail/${service?.history_id}`}
                            >
                              <div className="event_description">
                                <div className="event_head fw-500">
                                  {service?.name}
                                </div>
                                <div className="event_description_details fw-500">
                                  <img
                                    src={build}
                                    alt="name"
                                    style={{ marginRight: "14px" }}
                                  />
                                  <div>{service?.property?.name}</div>
                                </div>

                                <div className="event_description_details fw-500">
                                  <img
                                    src={time}
                                    alt="time"
                                    style={{ marginRight: "14px" }}
                                  />
                                  <div>{this.convertTime(service?.date)}</div>
                                </div>
                              </div>
                            </Link>
                          ))}
                        </div>
                      ) : (
                        <div className="not_available">
                          No Maintenance Available
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="event_container">
                  {status === "all" && (
                    <div className="event_title">Scheduled Requests</div>
                  )}

                  {(status === "all" || status === "request") && (
                    <div className="minh-100">
                      {requestList.length > 0 ? (
                        <div>
                          {requestList.map((service) => (
                            <Link
                              className="linkDiv"
                              to={`/admin/requests/detail/${service?.request_id}`}
                            >
                              <div className="event_description">
                                <div className="event_head fw-500">
                                  {service?.name}
                                </div>
                                <div className="event_description_details fw-500">
                                  <img
                                    src={build}
                                    alt="name"
                                    style={{ marginRight: "14px" }}
                                  />
                                  <div>{service?.property?.name}</div>
                                </div>
                              </div>
                            </Link>
                          ))}
                        </div>
                      ) : (
                        <div className="not_available">
                          No Request Available
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="event_container">
                  {status === "all" && (
                    <div className="event_title"> Google Events</div>
                  )}

                  {(status === "all" || status === "google") && (
                    <div>
                      {googEventsList && googEventsList.length > 0 ? (
                        <div>
                          {googEventsList.map((service) => (
                            <div className=" googevent">
                              <div className="">
                                <div className=" fw-500 f16 cl000000 mb-10 flex ">
                                  <div>{service?.name}</div>
                                  <div className="flex">
                                    <img
                                      src={EditIcon}
                                      className="mr-10 point"
                                      onClick={() => this.props.edit(service)}
                                      alt=""
                                    />
                                    <img
                                      src={deleteIcon}
                                      onClick={() =>
                                        this.openConfirmation(service)
                                      }
                                      className="point"
                                      alt=""
                                    />
                                  </div>
                                </div>
                                <div className="f14 linkDiv fw-500 mb-20">
                                  {this.convertTime(service?.time?.from)} -{" "}
                                  {this.convertTime(service?.time?.to)}
                                </div>
                                {service?.description && (
                                  <>
                                    <div className="f14 fw-400 mb-10 cl212121">
                                      Description
                                    </div>
                                    <div className="f14 fw-400 cl757575 mb-20">
                                      {service?.description}
                                    </div>
                                  </>
                                )}
                                {service?.location && (
                                  <>
                                    <div className="f14 fw-400 mb-10 cl212121">
                                      Location
                                    </div>
                                    <div className="f14 fw-400 cl757575 mb-20">
                                      {service?.location}
                                    </div>
                                  </>
                                )}
                                {service?.attendees && (
                                  <div className="f14 fw-400 mb-10">
                                    <div className="f14 fw-400 mb-10 cl212121">
                                      Attendees
                                    </div>
                                    {service?.attendees?.length > 0 && (
                                      <ul className="tagInputAssinessList">
                                        {service?.attendees?.map((tag, i) => (
                                          <li>
                                            <span>{tag.email}</span>
                                            <img src={rectArrow} alt="" />
                                          </li>
                                        ))}
                                      </ul>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="not_available">No Event Available</div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Modal
              isOpen={openModal}
              toggle={() => this.closeConfirmation()}
              className="w450 modal-dialog-notice scheduleModal"
              centered
            >
              <DeleteConfirmation
                close={this.closeConfirmation}
                service={serviceData}
              />
            </Modal>
          </Card>
        )}
      </>
    );
  }
}

export default EventListing;
