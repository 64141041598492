import React, { Component, useState, useEffect } from "react";
import { getCloudinaryImage } from "constants/utils";
import ProfileIcon from "../../../src/assets/img/brand/ManagerInactive.svg";
import cookie, { load } from "react-cookies";
import { Link, useParams, useLocation, useHistory } from "react-router-dom";
import { AddClassToBody, roleType, saveSecureVault } from "constants/utils";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
  Badge,
  Input,
  Card,
  Modal,
} from "reactstrap";
import Logo from "../../assets/img/brand/logoPark.svg";
import defaultProfile from "../../assets/img/brand/defaultUser.svg";
import DropdownProfileIcon from "../../assets/img/brand/dropdownProfile.svg";
import DropdownLogoutIcon from "../../assets/img/brand/dropdownLogout.svg";
import Bell from "../../assets/img/brand/notification.svg";
import LeftArrow from "../../assets/img/brand/leftArrow.svg";
import RightArrow from "../../assets/img/brand/rightArrow.svg";
import ApiService from "constants/ApiService";
import CalenderIcon from "../../assets/img/brand/CalenderIcon.svg";
import Notify from "./notificationBox";
import { getCookie } from "constants/utils";
import expandArrow from "../../assets/img/brand/expandArrow.svg";
import hamburgerMenu from "../../assets/img/brand/li_align-center.svg";
import referIcon from "../../assets/img/brand/referIcon.svg";
import ReactTooltip from "react-tooltip";
import { searchByName } from "constants/utils";
import settingIcon from "../../assets/img/brand/maintenanceInvoice.svg";
import { useDispatch, useSelector, connect } from "react-redux";
import {
  getNotifyCount,
  getNotifyList,
  updatePropertyList,
} from "../../feature/counter/counterSlice";
import ResponsiveSidebar from "components/Sidebar/ResponsiveSideBar";
import { showSuccess, Logout } from "constants/utils";
import ChangePassword from "views/examples/changePassword";
import ToastList from "components/Toast/ToastList";
import passwordIcon from "../../assets/img/brand/passwordNavIcon.svg";
import { locale } from "moment";
import { RoleBasedCRUDAccess } from "views/Roles/RolesUtils";
import { roleSubManager } from "constants/utils";
import { SidebarSet } from "views/Roles/RolesUtils";
import Refer from "../Navbars/refer";
import { Margin } from "devextreme-react/chart";
const AdminNavbar = (props) => {
  let notifyCount = useSelector((state) => state?.counter?.notify_count);
  let notificationList = useSelector((state) => state?.counter?.notify_list);
  let callPropertyList = useSelector(
    (state) => state?.counter?.property_update
  );
  const location = useLocation();
  const history = useHistory();
  const handleLogout = () => { };

  const [switch_value, setSwitch] = useState();
  const [userName, setUserName] = useState("");
  const [lastActivity, setLastActivity] = useState("");
  // const [notifyCount, setNotifyCount] = useState(0);
  const [count, setCount] = useState(0);
  // const [notificationList, setNotificationList] = useState([]);
  const [propertyList, setPropertyList] = useState([]);
  const [propName, setPropName] = useState("");
  const [propId, setPropId] = useState("");
  const [openDropDown, setopenDropDown] = useState(false);
  const [propertySearch, setPropertySearch] = useState("");
  const [DefaultList, setList] = useState([]);
  const [profileImg, setProfileImg] = useState("");
  const [toggleWidth, setToggleWidth] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [toast, setToast] = useState(false);
  const [response, setResponse] = useState("");
  const [toastType, setToastType] = useState("");
  const [host_det, set_host_det] = useState({});
  const [ReferModal, setReferModal] = useState(false);

  const [view, setView] = useState(false);
  let screen = localStorage.getItem("screen");
  useEffect(() => {
    let user = localStorage.getItem("user");
    getHostDetail();
    getPropertyLists();
    props.updateNotifyCount();
    props.updateNotifyList();

    if (user) {
      setSwitch(user);
    } else {
      setSwitch("Rental");
    }
  }, [callPropertyList]);

  window.addEventListener("storage", (e) => {
    if (e.storageArea === localStorage) {
      let detail = localStorage.getItem('host_details')
      if (detail === null || detail === undefined || detail === "") {
        window.location.reload()
      }
    }
  })

  const getPropertyLists = async () => {
    let response = await ApiService.getAPI(`host/properties/list`);
    let PropResponse = await ApiService.getAPI("host");
    if (PropResponse.success) {
      if (PropResponse?.data?.host?.role !== "sub_manager") {
        let vault_status = PropResponse?.data?.host?.vault?.is_enabled ?? false;
        if (vault_status) {
          saveSecureVault("secured");
        } else {
          saveSecureVault("notsecure");
        }
      }
      localStorage.setItem("host_details", JSON.stringify(PropResponse.data));
      props.reload(JSON.stringify(PropResponse.data));
    }
    if (response.success && PropResponse.success) {
      setProfileImg(PropResponse?.data?.host?.image);
      let propData = response.data.properties;
      let profileData = PropResponse.data.host;
      let propList = [...propData];
      let obj = {
        name: "All Properties",
        property_id: "",
        default: false,
      };

      propList.splice(0, 0, obj);
      propList.map((prop) => {
        if (
          profileData.default_property == null ||
          profileData.default_property == undefined
        ) {
          propList[0].default = true;
          setPropName(propList[0].name);
          setPropId(propList[0].property_id);
        } else {
          if (profileData.default_property === prop.property_id) {
            prop.default = true;
            setPropName(prop.name);
            setPropId(prop.property_id);
            localStorage.setItem("default_property", prop.property_id);
          } else {
            prop.default = false;
          }
        }
      });
      setPropertyList(propList);
      setList(propList);
    }
  };

  const getHostDetail = async () => {
    let host = localStorage.getItem("host_details");
    let active = getCookie("lastActivity");
    let name = JSON.parse(host);
    setUserName(name?.host?.name);
    setLastActivity(active);
  };

  const switchChange = (value) => {
    setSwitch(value);
    localStorage.setItem("user", value);
    setTimeout(() => window.location.reload(), 0);
  };

  const changeView = (view_value) => {
    if (view_value === true) {
      setView(view_value);
      localStorage.setItem("screen", "full");
      AddClassToBody();
    } else {
      setView(view_value);
      localStorage.setItem("screen", "half");
      AddClassToBody();
    }
    setToggleWidth(true);
  };

  const toggleCloseCanvas = () => {
    setToggleWidth(false);
  };

  const setPropertyName = (prop) => {
    localStorage.setItem("default_property", prop.property_id);
    setPropName(prop.name);
    setPropId(prop.property_id);
    setDropDownCondition();

    history.push({
      pathname: location.pathname,
    });
  };

  const setDropDownCondition = () => {
    setPropertySearch("");
    setPropertyList(DefaultList);
    setopenDropDown(!openDropDown);
  };

  const makeDefault = async (e, property) => {
    e.stopPropagation();
    let propArr = [...propertyList];
    let defProp = [...DefaultList];
    propArr.map((prop) => {
      if (prop.property_id === property.property_id) {
        prop.default = true;
      } else {
        prop.default = false;
      }
    });
    defProp.map((prop) => {
      if (prop.property_id === property.property_id) {
        prop.default = true;
      } else {
        prop.default = false;
      }
    });
    setPropertyList(propArr);
    setList(defProp);

    let data = {
      default_property: property.property_id,
    };

    let response = await ApiService.patchAPI(`host`, data);

    if (response.success) {
      localStorage.setItem("default_property", property.property_id);
    }
  };

  const handlePropertySearch = async (e) => {
    setPropertySearch(e.target.value);
    let propArr = [...DefaultList];

    if (e.target.value !== "") {
      let searchedArray = await searchByName(propArr, e.target.value);
      setPropertyList(searchedArray);
    } else {
      setPropertyList(DefaultList);
    }
  };

  const toggleModal = () => {
    setPasswordModal(!passwordModal);
  };
  const openReferPoppop = () => {
    setReferModal(true);
  };
  const closeReferPoppop = (msg) => {
    setReferModal(false);
    // if (msg) {
    //   showSuccess(this, msg);
    // }
  };

  return (
    <>
      <Navbar
        // className={
        //   props?.design
        //     ? "navbar-top navbar-dark new-nav-dash"
        //     : "navbar-top navbar-dark"
        // }
        style={{ padding: '12px 24px', boxShadow: '0px 4px 5px 0px #00000003', borderBottom: '1px solid #E1E8F0', backgroundColor: 'white' }}

        expand="md"
        id="navbar-main"
      >
        {" "}
        {toast && <ToastList message={response} type={toastType} />}
        <Container fluid className="container_box">
          <div className="toogle_side_bar">
            <div
              className={toggleWidth ? "opac-div-sidebar" : "opac-div-initial"}
              onClick={() => toggleCloseCanvas()}
            ></div>
            <Card
              className={
                toggleWidth ? "responsiveSideBarToggle" : "toggle-sidebar"
              }
            >
              <ResponsiveSidebar />
            </Card>
            {/* <i
              className="fas fa-solid fa-bars hamburger_nav mr-25 navBarToggleIcon"
              onClick={() => changeView(!view)}
            ></i> */}
            {/* <div
              className="hamburger_nav mr-25 navBarToggleIcon"
              style={{ width: "27px" }}
            >
              <img
                src={hamburgerMenu}
                className=" "
                onClick={() => changeView(!view)}
              ></img>
              
            </div>
            <img
              src={expandArrow}
              onClick={() => changeView(!view)}
              className="expandArrow mr-19 navBarToggleImg"
            /> */}
            {view ?
              <img
                src={hamburgerMenu}
                onClick={() => changeView(!view)}
                className="expandArrowFull mr-19 navBarToggleImg"
              /> : <img
                src={expandArrow}
                onClick={() => changeView(!view)}
                className="expandArrow mr-19 navBarToggleImg"
              />}
            {localStorage.getItem("user") === "Personal" ? (
              ""
            ) : (
              <>
                <div className="navDropDown">
                  <UncontrolledDropdown>
                    <DropdownToggle className="navBarDropDown">
                      <Media
                        className="flex f18 fw-600 cl374957"
                        onClick={() => setDropDownCondition()}
                      >
                        {propName.length > 15
                          ? propName.slice(0, 15) + "..."
                          : propName}
                        <i className="fas fa-angle-down ml-14"></i>
                      </Media>
                    </DropdownToggle>
                    <DropdownMenu className="pad0p">
                      <div className="drpDownshad">
                        <Input
                          className="propertySearchBox"
                          value={propertySearch}
                          onChange={handlePropertySearch}
                          placeholder="Search..."
                        />
                        {propertyList.length > 0 ? (
                          propertyList.map((prop, i) => (
                            <DropdownItem className="navBarPropName" key={i}>
                              <div className="flex ">
                                <div
                                  data-for={prop.property_id}
                                  className="  mr-10 add_ellipse"
                                  onClick={() => setPropertyName(prop)}
                                  data-tip={prop.name}
                                  data-place="right"
                                >
                                  {prop.name}
                                </div>
                                {prop?.default ? (
                                  <div className="defaultText"> Default</div>
                                ) : (
                                  <div
                                    className="setDefault"
                                    onClick={(e) => makeDefault(e, prop)}
                                  >
                                    set as default
                                  </div>
                                )}
                              </div>
                              {prop.name.length > 18 && (
                                <ReactTooltip
                                  id={prop.property_id}
                                  className="propNameTooltip"
                                  place="right"
                                  type="info"
                                  effect="solid"
                                  html={true}
                                />
                              )}
                            </DropdownItem>
                          ))
                        ) : (
                          <DropdownItem className="flexmid cl757575 f14">
                            No Property Found
                          </DropdownItem>
                        )}
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </>
            )}
          </div>

          <div className="nav_bar_right_side">
            {roleType() && (
              <div className="switch_user">
                <Link to={"/admin/index"}>
                  {" "}
                  <div
                    className={
                      switch_value === "Rental"
                        ? "active_switch switch_common"
                        : "inactive_switch switch_common"
                    }
                    onClick={() => switchChange("Rental")}
                  >
                    Rental
                  </div>
                </Link>
                <Link to={"/admin/personal_properties"}>
                  {" "}
                  <div
                    className={
                      switch_value === "Personal"
                        ? "active_switch switch_common"
                        : "inactive_switch switch_common"
                    }
                    onClick={() => switchChange("Personal")}
                  >
                    Personal
                  </div>
                </Link>
              </div>
            )}
            <div className="notification_bell mr-15">
              <UncontrolledDropdown>
                <DropdownToggle className="notify-bell pad0p">
                  <Media className="notifyIcon pad0p">
                    <img className="" src={Bell} />
                    {notifyCount > 0 && (
                      <div className="notifyCount"> {notifyCount}</div>
                      &&
                      notifyCount >= 100 && (<div className="notifyCount">99+</div>)
                    )}
                  </Media>
                </DropdownToggle>
                <DropdownMenu
                  className="mt-20 pad0p notifyDropDown noti_trans"
                  style={{ width: "300px" }}

                >
                  <DropdownItem
                    className="pad0p"
                    style={{ whiteSpace: "unset" }}
                  >
                    <Notify notifyData={notificationList} />
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>

            <Nav className="align-items-center d-none d-md-flex navbar_options">
              <UncontrolledDropdown nav className="navBar padl0">
                <DropdownToggle className="pr-0" nav>
                  <Media className="disblock">
                    {!profileImg ? (
                      <label htmlFor="imageUpload " className="mb-remove">
                        <img
                          src={defaultProfile}
                          alt="profile image"
                          className="avatar avatar-sm rounded-circle point"
                        />
                      </label>
                    ) : (
                      <img
                        src={getCloudinaryImage(100, 100, profileImg, "c_fill")}
                        alt="profile image"
                        className="avatar avatar-sm rounded-circle"
                      />
                    )}
                    {/* </span> */}
                    <Media className="ml-2 d-none d-lg-block"></Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem
                    to="/admin/user-profile"
                    tag={Link}
                    className="dropdown_item"
                  >
                    <img src={DropdownProfileIcon} />
                    <span>My Profile</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  {!roleSubManager() && (
                    <>
                      {" "}
                      <DropdownItem
                        to="/admin/settings"
                        tag={Link}
                        className="dropdown_item"
                      >
                        <img src={settingIcon} />
                        <span>Preferences</span>
                      </DropdownItem>
                      <DropdownItem divider />
                    </>
                  )}
                  {!roleSubManager() && (
                    <>
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => openReferPoppop()}
                      >
                        <img src={referIcon} />
                        <span>Invite Friends</span>
                      </DropdownItem>
                      <DropdownItem divider />
                    </>
                  )}

                  <DropdownItem
                    to="/admin/password"
                    tag={Link}
                    className="dropdown_item"
                  >
                    <img src={passwordIcon} />
                    <span>Change Password</span>
                  </DropdownItem>

                  <DropdownItem divider />
                  <DropdownItem
                    // href="#"
                    onClick={() => Logout()}
                    className="dropdown_item"
                  >
                    <img src={DropdownLogoutIcon} />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </div>
          <Modal isOpen={passwordModal} centered toggle={() => toggleModal()}>
            <ChangePassword close={toggleModal} />
          </Modal>
          <Modal
            className="modal-dialog-centered modal-dialog-recommand-contractor"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter "
            isOpen={ReferModal}
            toggle={closeReferPoppop}
          >
            <Refer close={closeReferPoppop} />
          </Modal>
        </Container>
      </Navbar>
    </>
  );
};

// export default AdminNavbar;
const mapStateToProps = (state) => {
  return {
    notify_count: state.counter.notify_count,
    notify_list: state.counter.notify_list,
    updateproperty: state.counter.property_update,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateNotifyCount: () => dispatch(getNotifyCount()),
    updateNotifyList: () => dispatch(getNotifyList()),
    updateProperties: () => dispatch(updatePropertyList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbar);
