import React, { Component } from "react";
import Park from "../../assets/img/new Logo/host_new_template.svg";
import {
  returnFirstDegreeObjValue,
  displayPrice,
  showLocaldate,
} from "constants/utils";
import returnSecondDegreeObjValue from "constants/utils";
import ApiService from "constants/ApiService";
import { Row, Col, FormGroup, Spinner } from "reactstrap";

class PaymentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceDetail: "",
      loading: true,
    };
  }

  componentDidMount() {
    this.getDetails();
  }

  getDetails = async () => {
    let { invoiceId } = this.props;
    let response = await ApiService.getAPI(`host/invoice/pay/${invoiceId}`);
    if (response.success) {
      this.setState({ invoiceDetail: response.data.invoices, loading: false });
    }
  };

  render() {
    let { invoiceDetail, loading } = this.state;
    return loading ? (
      <div className="spin-loading1">
        <Spinner type="border" color="#012A4D" />
      </div>
    ) : (
      <div>
        <div className="bottom-line-invoice">
          <div className="modal-header invoice-header">
            <h5 className="modal-title invoice-title" id="exampleModalLabel">
              Payments
            </h5>
            <div>
              <img src={Park} className="invoice-park" alt="" />
            </div>
          </div>
        </div>

        <div className="modal-body profile-body invoice-body">
          <Row>
            <Col lg="12" className="pad-left-invoice">
              <FormGroup className="flex-invoice flex-secondary-invoice">
                <label
                  className="label-invoice-prop mb-0 label-payment"
                  htmlFor="input-username"
                >
                  Property Details
                </label>
                <label
                  className="label-invoice-id mb-0 label-payment"
                  htmlFor="input-username"
                >
                  {returnSecondDegreeObjValue(
                    invoiceDetail,
                    "property",
                    "name"
                  )}
                </label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12" className="pad-left-invoice">
              <FormGroup className="flex-invoice flex-secondary-invoice">
                <label
                  className="label-invoice-prop mb-0 label-payment"
                  htmlFor="input-username"
                >
                  Invoice ID
                </label>
                <label
                  className="label-invoice-id mb-0 label-payment"
                  htmlFor="input-username"
                >
                  {returnFirstDegreeObjValue(invoiceDetail, "invoice_id")}
                </label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12" className="pad-left-invoice">
              <FormGroup className="flex-invoice ">
                <label
                  className="label-invoice-prop mb-0 label-payment "
                  htmlFor="input-username"
                >
                  Created On
                </label>
                <label
                  className="label-invoice-id mb-0 label-payment"
                  htmlFor="input-username"
                >
                  {showLocaldate(
                    returnFirstDegreeObjValue(invoiceDetail, "createdAt")
                  )}
                </label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12" className="pad-left-invoice">
              <FormGroup className="flex-invoice ">
                <label
                  className="label-invoice-prop mb-0 "
                  htmlFor="input-username"
                >
                  Amount
                </label>
                <label
                  className="label-invoice-id mb-0 "
                  htmlFor="input-username"
                >
                  {displayPrice(
                    returnFirstDegreeObjValue(invoiceDetail, "amount")
                  )}
                </label>
              </FormGroup>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default PaymentDetails;
