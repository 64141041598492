import React, { Component } from "react";
import {
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  InputGroup,
  Dropdown,
  Input,
  Spinner,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
} from "reactstrap";
// core components
import ApiService from "constants/ApiService";
import returnSecondDegreeObjValue from "constants/utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getIsoString,
  displayPrice,
  showError,
  showLocaldate,
  isTodayorGreater,
  returnCustomerFirsLastName,
  returnFirstDegreeObjValue,
  pushToFilteredPage,
  returnSearchObject,
  buildQueryString,
  getDefaultProperty,
  showSuccess,
  roleType,
  returnPaidDeposit,
  displayAmount,
} from "constants/utils";
import ToastList from "components/Toast/ToastList";
import showMore from "../../assets/img/brand/showMore.svg";
import DepositSideDragger from "./DepositSideDragger";
import CheckListDragger from "./CheckListDragger";
import { RoleBasedCRUDAccess } from "views/Roles/RolesUtils";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { CustomSearch } from "components/FilterFields/Filterfields";
import { CustomDatePicker } from "components/FilterFields/Filterfields";
import { CustomDropDown } from "components/FilterFields/Filterfields";
import { Button_without_Border } from "components/Buttons/Button";
import { refundSecurityValidation } from "constants/utils";
import { bookingStatusColor } from "constants/utils";
import { bookingStatus } from "constants/utils";
import { AsscendingDecending } from "components/FilterFields/Filterfields";
import { returnSortKey } from "constants/utils";
import { returnCustomerName } from "constants/utils";
class Bookings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookingList: [],
      filterToggle: false,
      total: 0,
      page: 1,
      limit: 10,
      // from: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      // to: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      from: "",
      to: "",
      inputSearch: "",
      filterOption: "username",
      statusSearch: "",
      isLoading: true,
      query: {},
      invoiceStatusView: "",
      bookingDetail: "",
      sortingList: "",
      timeoutId: null,
      input_value: false,
      keyValue: "",
    };
  }

  reloadPage = () => {
    this.props.history.listen((location, search) => {
      if (location.search === "") {
        this.setState({ query: {} }, () => {
          let query = this.props.location.search;
          query = returnSearchObject(query);
          if (Object.keys(query).length === 0) {
            this.handleReset();
          }
        });
      } else {
        this.state.input_value = true
      }
    });
  };

  // componentDidUpdate(prevProps) {
  //   let search = this.props.location.search;
  //   if (prevProps.location.search !== search) {
  //     this.setQueryStatus();
  //   }
  // }
  componentWillUnmount = () => {
    clearTimeout(this.state.timeoutId);
  };
  componentDidMount() {
    this.reloadPage();
    this.setQueryStatus();
  }

  setBookingStatus = () => {
    const search = this.props.location.search;
    const prop_name = new URLSearchParams(search).get("prop_name");
    if (prop_name) {
      this.setState(
        { filterOption: "username", inputSearch: prop_name },
        () => {
          this.getbookingList();
        }
      );
    } else {
      this.getbookingList();
    }
  };
  createSecurityDeposit = (booking) => {
    let action = false;
    if (RoleBasedCRUDAccess("deposit", "edit") && !booking.deposit) {
      if (booking.status === "booked") {
        action = true;
      } else if (
        booking.status === "pre_booking" &&
        isTodayorGreater(booking?.check_in)
      ) {
        action = true;
      }
    }
    return action;
  };
  setQueryStatus = () => {
    let queryStatus = this.props.location.search;
    let default_property = getDefaultProperty();

    if (queryStatus) {
      let queryObj = returnSearchObject(queryStatus);
      if (default_property) {
        queryObj.property_id = default_property;
      }

      this.setState(
        {
          inputSearch:
            (queryObj.propname ||
              queryObj.property_id ||
              queryObj.username ||
              queryObj.booking_id) ??
            "",
          filterOption: queryObj.propname
            ? "propname"
            : queryObj.property_id
              ? "property_id"
              : queryObj.username
                ? "username"
                : queryObj.booking_id && "booking_id",
          from: queryObj.from ? new Date(queryObj.from) : "",
          to: queryObj.to ? new Date(queryObj.to) : "",
          statusSearch: queryObj.status ?? "",
          page: parseInt(queryObj.page) ?? "",
          query: queryObj,
        },
        () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getbookingList();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...this.state.query,
            page: 1,
            limit: 10,
            from: getIsoString(this.state.from),
            to: getIsoString(this.state.to),
            property_id: default_property,
          },
          inputSearch: "",
          // filterOption: default_property ? "property_id" : "",
        },
        () => {
          pushToFilteredPage(this.props, this.state.query);
          this.getbookingList();
        }
      );
    }
  };

  getbookingList = async () => {
    let { from, to } = this.state;
    // let queryParams = "";
    // if (filterOption !== "" && inputSearch !== "")
    //   queryParams += filterQuery(filterOption, inputSearch);
    // if (statusSearch !== "") {
    //   queryParams += filterQuery("status", statusSearch);
    // }
    // if (from !== "") queryParams += filterQuery("from", getIsoString(from));
    // if (to !== "") queryParams += filterQuery("to", getIsoString(to));

    if ((from && to) && from > to) {
      showError(this, "Please provide valid From and To date");
    } else {
      let response = await ApiService.getAPI(
        `host/booking${buildQueryString(this.state.query)}`
      );
      if (response.success) {
        let { bookings, total } = response.data;
        this.setState({
          bookingList: bookings,
          total: total,
          isLoading: false,
        });
      } else {
        this.setState({ isLoading: false });
        showError(this, response.message);
      }
    }
  };

  onChangeHandler = (e) => {
    e.target.value === "" && this.handleReset();
    this.setState({ [e.target.name]: e.target.value, input_value: true });
    clearTimeout(this.state.timeoutId);
    const timeoutId = setTimeout(() => {
      this.state.filterOption && this.handleSearch();
    }, 1000);
    this.setState({ timeoutId });
  };

  onChangeSelectHandler = (status) => {
    this.setState({ filterOption: status, input_value: true }, () => {
      this.handleFilterStatus();
    });
  }

  handleSearch = () => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
  };


  handleIncrementPage = () => {
    if (this.state.bookingList.length >= 10) {
      this.setState({ page: this.state.page + 1 }, () =>
        // this.getbookingList(this.state.page)
        this.handleFilterStatus()
      );
    }
  };
  
  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 }, () =>
        // this.getbookingList(this.state.page)
        this.handleFilterStatus()
      );
    }
  };

  handleOnFromChange = (e) => {
    this.updateStateValues("from", e);

  };
  handleOnToChange = (e) => {
    this.updateStateValues("to", e);
  };
  updateStateValues = (key, value) => {
    const updatedValue = value == null ? "" : value;
    this.setState({ [key]: updatedValue, input_value: true }, () => {
      if (this.state.from && this.state.to) {
        this.handleFilterStatus();
      }
    });
  };

  setInvoiceStatusView = (status) => {
    let from;
    let to;
    this.setState({ invoiceStatusView: status, input_value: true });
    if (status === "tm") {
      from = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      to = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
    } else if (status === "lm") {
      from = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
      to = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
    } else if (status === "ty") {
      from = new Date(new Date().getFullYear(), 0, 1);
      to = new Date(new Date().getFullYear(), 11, 31);
    } else {
      from = "";
      to = "";
    }
    this.setState({ from, to, input_value: true }, () => {
      this.handleFilterStatus();
    });
  };

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    staticQuery.from = getIsoString(this.state.from) ?? "";
    staticQuery.to = getIsoString(this.state.to) ?? "";
    if (this.state.inputSearch !== "" && this.state.filterOption !== "") {
      for (let key in staticQuery) {
        if (
          key === "propname" ||
          key === "username" 
        ) {
          delete staticQuery[key];
        }
      }
      staticQuery[this.state.filterOption] = this.state.inputSearch;
    }
    staticQuery.status = this.state.statusSearch ?? "";
    staticQuery.page = this.state.page ?? "";
    staticQuery.sort = this.state.sortingList ?? "";

    this.setState({ query: staticQuery }, () => {
      pushToFilteredPage(this.props, { ...this.state.query });
      this.getbookingList();
    });
  };

  handleSubmit = () => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
  };
  handleReset = () => {
    let default_property = getDefaultProperty();
    let self = this;
    self.setState(
      {
        from: "",
        to: "",
        statusSearch: "",
        filterOption: "username",
        inputSearch: "",
        page: 1,
        invoiceStatusView: "",
        sortingList: "",
        query: {
          page: 1,
          limit: 10,
          property_id: default_property,
        },
        input_value: false,
        keyValue: "",
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getbookingList();
      }
    );
  };
  redirectDetailPage = ({ booking_id, name }) => {
    this.props.history.push(`bookings/detail/${booking_id}?name=${name}`);
  };

  setStatus = (status) => {
    this.setState({ statusSearch: status, page: 1, input_value: true }, () => {
      this.handleFilterStatus();
    });
  };

  inputSelectChange = (value) => {
    this.setState({ filterOption: value });
  };

  openDeposit = (detail) => {
    this.setState({ depositSideBar: true, bookingDetail: detail });
  };

  closeDeposit = (msg) => {
    this.setState({ depositSideBar: false });
    if (msg) {
      showSuccess(this, msg);
      this.getbookingList();
    }
  };

  openCheckList = (detail) => {
    this.setState({ checkListSideBar: true, bookingDetail: detail });
  };

  closeCheckList = () => {
    this.setState({ checkListSideBar: false });
  };

  sendCheckList = async (id) => {
    let response = await ApiService.postAPI(
      `host/booking/resend_checklist/${id}`
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.getbookingList();
    } else {
      showError(this, response.message);
    }
  };

  createDeposit = async (book) => {
    let response = await ApiService.postAPI(`host/deposits/${book.booking_id}`);
    if (response.success) {
      showSuccess(this, response.message);
      this.getbookingList();
    } else {
      showError(this, response.message);
      if (response.data.error) {
        setTimeout(() => {
          if (response.data.error === 4001) {
            this.props.history.push(`/admin/room/edit/${book.room_id}`);
          } else {
            this.props.history.push(
              `/admin/properties/update/${book.property.id}`
            );
          }
        }, 2000);
      }
    }
  };

  displayErrorMessage = (msg) => {
    showError(this, msg);
  };

  setSortingList = (status) => {
    this.setState({ sortingList: status, page: 1, input_value: true }, () => {
      this.handleFilterStatus();
    });
  };

  handleKeypress = (e) => {
    if (e.key === "Enter") {
      this.handleSubmit();
    } else { }
  };
  asc = (asc) => {
    this.state.sortingList === asc ? this.handleReset() : this.setSortingList(asc)
  }
  dsc = (dsc) => {
    this.state.sortingList === dsc ? this.handleReset() : this.setSortingList(dsc)
  }
  action = (key) => {
    let skey = returnSortKey(this, key);
    this.setState({ keyValue: skey, sortingList: skey, page: 1, input_value: true }, () => { this.handleFilterStatus() })
  }
  render() {
    let {
      bookingList,
      page,
      toast,
      response,
      toastType,
      inputSearch,
      filterOption,
      statusSearch,
      isLoading,
      invoiceStatusView,
      depositSideBar,
      checkListSideBar,
      bookingDetail,
      sortingList,
      from,
      to,
      input_value,
    } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#012A4D" />
      </div>
    ) : (
      <>
        {toast && <ToastList message={response} type={toastType} />}
        <CustomListingContainer>
          <ListingHeaderLayout label={"Bookings"}>
            <div className="justend">
              <div
                className="invoiceStatusView"
                style={{ width: "170px", marginLeft: "15px" }}
              >
                <UncontrolledDropdown className="status_select_div ">
                  <DropdownToggle className="pr-0 drop_down_container">
                    <Media className="capitalize">
                      {invoiceStatusView === "tm"
                        ? "This Month"
                        : invoiceStatusView === "lm"
                          ? "Last Month"
                          : invoiceStatusView === "ty"
                            ? "This Year"
                            : "All"}
                      <i className="fas fa-angle-down"></i>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" bottom>
                    {" "}
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.setInvoiceStatusView("")}
                    >
                      All
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.setInvoiceStatusView("tm")}
                    >
                      This Month
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.setInvoiceStatusView("lm")}
                    >
                      Last Month
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.setInvoiceStatusView("ty")}
                    >
                      This Year
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          </ListingHeaderLayout>
          <div style={{ margin: "20px" }}>
            <InputGroup className="flex-content align-left">
              <div className="filters_container">
                <div className="left_filter_container">
                  <div className="w30">
                    <CustomDatePicker
                      from={from}
                      to={to}
                      fromChange={this.handleOnFromChange}
                      toChange={this.handleOnToChange}
                    ></CustomDatePicker>
                  </div>
                  {/* <CustomSearch
                    options={["property", "tenant"]}
                    change={this.onChangeHandler}
                    filterOption={filterOption}
                    inputSearch={inputSearch}
                    placeholder={"Search by"}
                  /> */}
                  <div className="select_with_input status_select_input ml-10">
                    <UncontrolledDropdown className="status_select_div ">
                      <DropdownToggle className="pr-0 drop_down_container">
                        <Media className="capitalize">
                          {filterOption === "username"
                            ? "Tenant"
                            : filterOption === "propname"
                              ? "Property" : "Tenant"
                          }
                          <i className="fas fa-angle-down" style={{ marginLeft: "20px" }}></i>
                        </Media>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" bottom style={{ minWidth: "130px" }}>
                        {" "}
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.onChangeSelectHandler("username")}
                        >
                          Tenant
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.onChangeSelectHandler("propname")}
                        >
                          Property
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <Input
                      aria-label="Text input with dropdown button"
                      value={inputSearch}
                      name="inputSearch"
                      placeholder="search by"
                      onChange={this.onChangeHandler}
                    />
                  </div>
                  <div className="flexEnd invoiceStatusView properties_headAlign" style={{ marginLeft: "15px" }}>
                    <UncontrolledDropdown className="status_select_div ">
                      <DropdownToggle className="drop_down_container">
                        <Media className="capitalize">
                          {statusSearch === "" ? "All"
                            : statusSearch === "booked" ? "Active"
                              : statusSearch === "in_notice" ? "In Notice"
                                : statusSearch === "pre_booking" ? "Pre Booked"
                                  : statusSearch === "checked_out" && "Checked Out"}
                          <i className="fas fa-angle-down"></i>
                        </Media>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" bottom>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("")}
                        >
                          All
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("booked")}
                        >
                          Active
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("in_notice")}
                        >
                          In Notice
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("pre_booking")}
                        >
                          Pre Booked
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("checked_out")}
                        >
                          Checked Out
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>
                {input_value && (inputSearch || statusSearch || (from || to) || sortingList || invoiceStatusView) &&
                  <div className="right_filter_container">
                    <Button
                      color="primary"
                      onClick={this.handleReset}
                      size="sm"
                      className="button-wrapping mr-4 button-secondary reset-btn"
                      style={{ fontSize: "15px" }}
                    >
                      Clear filter
                    </Button>
                    {/* <Button
                    color="primary"
                    onClick={() => this.handleSubmit()}
                    size="sm"
                    className="button-wrapping search-wrapper"
                  >
                    Search
                  </Button> */}
                  </div>
                }
              </div>
            </InputGroup>
          </div>
          <Row>
            <div className="col new-table">
              <Card className="shadow card_with_paginator">
                <Table
                  className="align-items-center table-flush"
                  style={{ overflowX: "scroll" }}
                  responsive
                >
                  <thead className="thead-light">
                    <tr>
                      {/* <th scope="col">Tenant</th> */}
                      <th scope="col" className="bnone" style={{ padding: "0px", display: "flex" }}>
                        <th
                          scope="col"
                          className="pl_40 justLeft items-center bnone point"
                          style={{ width: "180px", display: "flex" }}
                          onClick={() => this.action("user")}
                        >
                          <AsscendingDecending keyValue={this.state.keyValue} value="user" name="Tenant" />
                        </th>
                        {/* <th scope="col">Property</th> */}
                        <th
                          scope="col"
                          className="pl_40 justLeft items-center bnone point"
                          style={{ width: "180px", display: "flex" }}
                          onClick={() => this.action("pname")}
                        >
                          <AsscendingDecending keyValue={this.state.keyValue} value="pname" name="Property" />
                        </th>
                      </th>
                      <th scope="col">Room</th>
                      <th scope="col">Booked on</th>
                      <th scope="col" className="bnone" style={{ padding: "0px", display: "flex" }}>
                        <th
                          scope="col"
                          className="pl_40 justLeft items-center bnone point"
                          style={{ width: "140px", display: "flex" }}
                          onClick={() => this.action("checkin")}
                        >
                          <AsscendingDecending keyValue={this.state.keyValue} value="checkin" name="Check-In" />
                        </th>
                        <th
                          scope="col"
                          className="pl_40 justLeft items-center bnone point"
                          style={{ width: "140px", display: "flex" }}
                          onClick={() => this.action("checkout")}
                        >
                          <AsscendingDecending keyValue={this.state.keyValue} value="checkout" name="Check-Out" />
                        </th>
                      </th>
                      <th scope="col">Deposit</th>
                      <th scope="col">Price</th>
                      <th scope="col">Status</th>
                      <th scope="col">Checklist</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  {bookingList.length !== 0 ? (
                    <tbody>
                      {bookingList.map((booking, i) => (
                        <tr
                          key={i}
                          onClick={
                            RoleBasedCRUDAccess("booking", "view")
                              ? () =>
                                this.redirectDetailPage({
                                  booking_id: booking?.booking_id,
                                  name: (booking?.user?.name?.first) + (" ") + (booking?.user?.name?.last)
                                })
                              : ""
                          }
                          style={{ cursor: "pointer" }}   >
                          <td style={{ padding: "0px" }}>
                            <td className="cursor-point btopNone tdColumn tdColumn_18"
                            >
                              {returnCustomerName(booking)}
                            </td>
                            <td className="btopNone tdColumn tdColumn_18" >
                              {" "}
                              {returnSecondDegreeObjValue(booking, "property", "name")}
                            </td>
                          </td>
                          <td>{returnFirstDegreeObjValue(booking, "room_number")}</td>
                          <td> {showLocaldate(booking?.createdAt)}</td>
                          <td style={{ padding: "0px" }}>
                            <td className="cursor-point btopNone"
                              style={{ padding: "20px 0px 20px 40px", width: "140px" }}
                            >
                              {showLocaldate(booking?.check_in)}
                            </td>
                            <td className="btopNone" style={{ padding: "20px 0px 20px 40px", width: "140px" }}
                            >
                              {showLocaldate(booking?.check_out)}
                            </td>
                          </td>
                          <td>{displayAmount(returnPaidDeposit(booking?.deposit))}</td>
                          <td>
                            {" "}
                            {displayPrice(
                              returnSecondDegreeObjValue(
                                booking,
                                "pay_summary",
                                "base_price"
                              )
                            )}
                          </td>
                          <td
                            className="general_status_style"
                            style={{ color: bookingStatusColor(booking?.status) }}
                          >
                            {booking && bookingStatus(booking.status)}
                          </td>
                          <td
                            className={
                              booking.status === "checked_out" ||
                                booking.status === "in_notice"
                                ? booking.check_list
                                  ? "general_status_style green"
                                  : "general_status_style yellow"
                                : "-"
                            }
                          >
                            {booking.status === "checked_out" ||
                              booking.status === "in_notice"
                              ? booking.check_list
                                ? "Completed"
                                : "Pending"
                              : "-"}
                          </td>
                          <td onClick={(e) => e.stopPropagation()}>
                            {(booking.status === "checked_out" ||
                              booking.status === "in_notice" ||
                              !booking.deposit) && (
                                <div>
                                  {!RoleBasedCRUDAccess("booking", "edit") &&
                                    !RoleBasedCRUDAccess("deposit", "edit") ? (
                                    <></>
                                  ) : (
                                    <>
                                      <UncontrolledDropdown
                                        nav
                                        className="flexy-content "
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <DropdownToggle
                                          className="pr-0 nav-notice"
                                          nav
                                        >
                                          {booking.status === "pre_booking" ? (
                                            ""
                                          ) : (
                                            <Media className="align-items-center">
                                              <img src={showMore} alt="" />
                                            </Media>
                                          )}
                                        </DropdownToggle>
                                        <DropdownMenu
                                          className="dropdown-menu-arrow min-width-drop"
                                          right
                                        >
                                          {(booking.status === "checked_out" ||
                                            booking.status === "in_notice") &&
                                            booking?.check_list &&
                                            RoleBasedCRUDAccess(
                                              "booking",
                                              "edit"
                                            ) && (
                                              <DropdownItem
                                                onClick={() =>
                                                  this.openCheckList(booking)
                                                }
                                              >
                                                <span>
                                                  View Checkout Checklist
                                                </span>
                                              </DropdownItem>
                                            )}
                                          {refundSecurityValidation(booking) &&
                                            RoleBasedCRUDAccess(
                                              "deposit",
                                              "edit"
                                            ) && (
                                              <DropdownItem
                                                onClick={() =>
                                                  this.openDeposit(booking)
                                                }
                                              >
                                                <span> Refund Security Deposit </span>
                                              </DropdownItem>
                                            )}
                                          {(booking.status === "checked_out" ||
                                            booking.status === "in_notice") &&
                                            !booking.check_list &&
                                            RoleBasedCRUDAccess(
                                              "booking",
                                              "edit"
                                            ) && (
                                              <DropdownItem
                                                onClick={() =>
                                                  this.sendCheckList(
                                                    booking.booking_id
                                                  )
                                                }
                                              >
                                                <span>Send CheckList</span>
                                              </DropdownItem>
                                            )}
                                          {this.createSecurityDeposit(
                                            booking
                                          ) && (
                                              <DropdownItem
                                                onClick={() =>
                                                  this.createDeposit(booking)
                                                }
                                              >
                                                <span>Create Security Deposit</span>
                                              </DropdownItem>
                                            )}
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </>
                                  )}
                                </div>
                              )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : <></>}
                </Table>
                {bookingList.length === 0 && (
                  <div className="">
                    <div className="no-data no-book">
                      {roleType()
                        ? "No Direct Tenants Bookings available or This page data can be viewed only if you directly manage tenants."
                        : " No Bookings Available."}
                    </div>
                  </div>
                )}
              </Card>
            </div>
          </Row>
          <Row className="align-items-center jus-space new-margin-page paginator_box">
            <div className="paginate-flex-box ">
              <div
                className="paginate-box"
                onClick={() => this.handleDecrementPage()}
              >
                <p
                  className={page !== 1 ? "page_available" : "page_unavailable"}
                >
                  Previous
                </p>
              </div>
              <div
                className="paginate-box"
                onClick={() => this.handleIncrementPage()}
              >
                <p
                  className={
                    bookingList.length < 10
                      ? "page_unavailable"
                      : "page_available"
                  }
                >
                  Next
                </p>
              </div>
            </div>
          </Row>
        </CustomListingContainer >
        <Card className={depositSideBar ? "exportSideBar" : "toggle-sidebar"}>
          <DepositSideDragger
            isOpen={depositSideBar}
            close={this.closeDeposit}
            toast={this.displayErrorMessage}
            detail={bookingDetail}
          />
        </Card>{" "}
        <Card
          className={checkListSideBar ? "exportSideBar" : "toggle-sidebar"}
        >
          <CheckListDragger
            isOpen={checkListSideBar}
            close={this.closeCheckList}
            toast={this.displayErrorMessage}
            detail={bookingDetail}
          />
        </Card>
      </>
    );
  }
}

export default Bookings;
