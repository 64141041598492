import React, { useState, useEffect } from "react";
import Toast from "./Toastify";
import toastTick from "../../assets/img/brand/toastTick.svg";
import toastError from "../../assets/img/brand/toastError.svg";
const ToastList = (props) => {
  const { type, message } = props;
  const [list, setList] = useState([]);
  const showToast = (type, message) => {
    const id = Math.floor(Math.random() * 100 + 1);
    let toastProperties = null;
    switch (type) {
      case "success":
        toastProperties = {
          id,
          title: "",
          description: message,
          backgroundColor: "white",
          icon: toastTick,
        };
        break;
      case "danger":
        toastProperties = {
          id,
          title: "",
          description: `${message}`,
          backgroundColor: "white",
          icon: toastError,
        };
        break;
      default:
        setList([]);
    }
    setList([...list, toastProperties]);
  };
  const deleteToast = (id) => {
    const index = list.findIndex((e) => e.id === id);
    list.splice(index, 1);
    setList([...list]);
  };
  useEffect(() => {
    showToast(type, message);
  }, []);

  return (
    <div className="app">
      <div className="app-header">
        <Toast
          toastList={list}
          handleDelete={deleteToast}
          position="top-center"
        />
      </div>
    </div>
  );
};
export default ToastList;
