import React, { Component } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Modal,
  Spinner,
} from "reactstrap";
import ApiService from "constants/ApiService";
import { isInvalidName } from "constants/utils";
import "react-step-progress-bar/styles.css";
import Geo from "../../assets/img/brand/geo.svg";
import MapContainer from "components/mapPopUp/GoogleMapsTest";
import ToastList from "components/Toast/ToastList";
import { handleGoBack } from "constants/utils";
import { showError } from "constants/utils";
import { showSuccess } from "constants/utils";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { DropdownCommon } from "components/Dropdown/DropdownCommon";
let property_id;
class AddEditPersonalProperty extends Component {
  constructor(props) {
    super(props);
    property_id = this.props.match.params.property_id;
    this.state = {
      name: "",
      beds: "",
      baths: "",
      guests: "",
      rental_type: "",
      home_type: "",
      notice_period: "",
      days: "",
      cancel_days: "",
      price: "",
      due_date: "",
      late_fee: "",
      address: "",
      country: "",
      about: "",
      state: "",
      city: "",
      zip: "",
      state_id: {},
      city_id: "",
      optionArray: [],
      inputArray: [],
      counter: 0,
      inputFields: [],
      policyFields: [],
      amenityList: [],
      stateList: [],
      cityList: [],
      selectedAmenities: [],
      imageArray: [],
      check: false,
      key: 0,
      progressPrecent: 0,
      progressActive: false,
      manage_by: "",
      managerList: [],
      manager: {},
      twinManager: {},
      managerName: "",
      geo: "",
      places: [],
      isShowPlaces: false,
      step_1: true,
      step_2: false,
      step_3: false,
      step_4: false,
      isMapVisible: false, //map visibilitty
      isLoading: true,
      location: {
        loc: [],
        address: "",
      },
      arrayIndex: 0,
      city: {},
      state: {},
      toast: false,
      response: "",
      toastType: "",
      imageLoading: false,
      isUpdateLoading: false,
      commission_mode: "",
      commission_amount: "",
      placeId: "",
    };
  }
  componentDidMount() {
    // this.handleOptions();
    // this.getAmenity();
    if (property_id) {
      this.getPropertyDetail();
    } else {
      this.getStates();
      this.setState({ isLoading: false });
    }
    this.setState({
      progressActive: true,
    });
  }
  getPropertyDetail = async () => {
    let response = await ApiService.getAPI(`host/properties/${property_id}`);
    if (response.success) {
      let { name, bedrooms, bathrooms, address, loc } = response.data.property;
      this.setState(
        {
          name,
          beds: bedrooms,
          baths: bathrooms,
          geo: address?.line_1,
          location: {
            address: address?.line_1,
            loc,
          },
          locationData: {
            ...address,
            loc,
          },
          state_id: address?.state,
          state: address?.state?.id ?? "",
          // JSON.stringify(address?.state),
          city_id: address?.city,
          city: address?.city?.id ?? "",
          // JSON.stringify(address?.city),
          country: address?.country,
          zip: address?.zip,
          isLoading: false,
        },
        () => {
          this.getStates();
          this.getCityList();
        }
      );
    }
  };

  // Event handler
  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleStateInputChange = (e) => {
    let { stateList } = this.state;
    let id = e
    stateList.some((states) => {
      if (states.id === id) {
        this.setState({ state: id, state_id: states }, () => {
          this.getCityList("changecity");
        });
      }
    });
  };

  handleCityInputChange = (e) => {
    let { cityList } = this.state;
    let id = e;
    cityList.some((cities) => {
      if (cities.id === id) {
        this.setState({ city: id, city_id: cities });
      }
    });
  };
  displayPlaces = async (e) => {
    let searchTerm = e.target.value;
    this.setState({ geo: searchTerm });
    let response = await ApiService.getAPI(`common/search?input=${searchTerm}`);
    if (response.success) {
      this.setState({
        isShowPlaces: true,
        places: response.data.place.predictions,
      });
    }
  };

  getCityList = async (citychange) => {
    let { state, locationData, city, city_id } = this.state;
    let id = state;
    let response = await ApiService.getAPI(`host/cities/${id}`);
    if (response.success) {
      let city_list = response.data.cities;
      city_list.map((city) => {
        city["id"] = city["city_id"];
        delete city["city_id"];
        delete city["_id"];
      });
      if (citychange) {
        this.setState({ cityList: city_list, city: "", city_id: "" });
      } else {
        this.setState({
          cityList: city_list,
          city: locationData?.city?.id ? locationData.city.id : city,
          city_id: locationData?.city ? locationData.city : city_id,
        });
      }
    }
  };

  getPlaceData = async (place) => {
    let response = await ApiService.getAPI(`host/maps/place/${place.place_id}`);
    if (response.success) {
      if (response.success) {
        let locData = response.data;
        this.setState(
          {
            geo: place.description,
            placeId: place.place_id,
            isLoading: false,
            isShowPlaces: false,
            locationData: locData,
            state: locData?.state?.id ?? "",
            state_id: locData?.state ?? "",
            zip: locData?.zip_code ? locData.zip_code : "",
          },
          () => {
            locData?.state && this.getStates();
            locData?.state && this.getCityList();
          }
        );
      } else {
        // toast.error(response.message);
        this.setState({
          isLoading: false,
          isShowPlaces: false,
        });
      }
    }
  };
  getAmenity = async () => {
    let response = await ApiService.getAPI("host/amenities");
    if (response.success) {
      this.setState({ amenityList: response.data.amenities });
    }
  };
  getStates = async () => {
    let { state, twinstate } = this.state;
    let response = await ApiService.getAPI("host/states");
    if (response.success) {
      let state_list = response.data.states;
      state_list.map((state) => {
        state["id"] = state["state_id"];
        delete state["state_id"];
        delete state["_id"];
      });
      this.setState({ stateList: state_list });
    }
  };

  handleAddEdit = async () => {
    let {
      name,
      beds,
      baths,
      country,
      zip,
      geo,
      locationData,
      step_1,
      city,
      state,
      placeId,
      city_id,
      state_id
    } = this.state;

    if (step_1) {
      if (name === "" || isInvalidName(name) || name.length < 3) {
        showError(this, "Please enter valid name");
      } else if (geo === "") {
        showError(this, "Please enter geo location");
      }
      // else if (placeId === "") {
      //   showError(this, "Please select geo location");
      // }
      else if (Object.keys(state).length === 0) {
        showError(this, "Please select state");
      } else if (Object.keys(city).length === 0) {
        showError(this, "Please select city");
      } else if (zip === "") {
        showError(this, "Please enter zip");
      } else if (
        locationData.city.id !== city_id.id &&
        locationData.state.id !== city_id.id
      ) {
        showError(this, "Please change geo location as per the state and city");
      } else if (parseInt(beds) <= 0 || beds === "") {
        showError(this, "Please enter valid number of  bedrooms");
      } else if (parseInt(baths) <= 0 || baths === "") {
        showError(this, "Please enter valid number of bathrooms");
      } else {
        let data = {
          name,
          bedrooms: beds,
          bathrooms: baths,
          address: {
            line_1: geo,
            city: city_id,
            //  JSON.parse(city),
            state: state_id,
            // JSON.parse(state),
            country: country,
            zip: zip,
          },
          loc: locationData.loc,
          p_type: "personal",
        };

        if (property_id) {
          let response = await ApiService.patchAPI(`host/properties/update/${property_id}`, data);
          if (response.success) {
            showSuccess(this, response.message);
            this.setState({ isUpdateLoading: true });
            handleGoBack(this.props);
          } else {
            showError(this, response.message);
          }
        } else {
          let response = await ApiService.postAPI(`host/properties`, data);
          if (response.success) {
            showSuccess(this, response.message);
            this.setState({ isUpdateLoading: true });
            handleGoBack(this.props);
          } else {
            showError(this, response.message);
          }
        }
      }
    }
  };
  toggleBack = () => {
    let { step_1, step_2, step_3, step_4 } = this.state;
    if (step_1) {
      this.props.history.goBack();
    } else if (step_2) {
      this.setState({ step_1: true, step_2: false });
    } else if (step_3) {
      this.setState({ step_2: true, step_3: false });
    } else if (step_4) {
      this.setState({ step_3: true, step_4: false });
    }
  };
  onMapIconClick = () => {
    this.setState({ isMapVisible: !this.state.isMapVisible });
  };
  onSave = (formatted_address, latlng) => {
    let location = this.state.location;
    location.address = formatted_address;
    location.loc = latlng;
    this.setState({ location }, () =>
      this.setState({
        geo: formatted_address,
      })
    );
    this.onMapIconClick();
  };
  render() {
    let {
      optionArray,
      amenityList,
      stateList,
      cityList,
      name,
      beds,
      baths,
      guests,
      rental_type,
      home_type,
      days,
      due_date,
      price,
      late_fee,
      state_id,
      city_id,
      zip,
      about,
      geo,
      step_1,
      step_2,
      step_3,
      step_4,
      isMapVisible,
      location,
      manage_by,
      managerList,
      manager,
      city,
      state,
      cancel_days,
      toast,
      response,
      toastType,
      isLoading,
      imageLoading,
      isUpdateLoading,
      commission_mode,
      commission_amount,
    } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#012A4D" />
      </div>
    ) : (
      <>
        <Container className="mt-8 card-pad-wrapper" fluid>
          {toast && <ToastList message={response} type={toastType} />}
          <div className="pt-3">
            <Breadcrumb></Breadcrumb>
          </div>
          <Row className="align-items-center header-div">
            <Col xs="8" className="manage-con">
              <div>
                <Breadcrumb>
                  <BreadcrumbItem
                    onClick={() => this.props.history.goBack()}
                    className="bread-cursor"
                  >
                    Properties
                  </BreadcrumbItem>
                  <BreadcrumbItem active style={{ color: "#012a4d" }}>
                    {property_id ? "Update" : "Add"}
                  </BreadcrumbItem>
                </Breadcrumb>
              </div>
            </Col>
            <div className="add-filter-wrap ">
              {/* <div
                className="filter-wrap mr-4 edit-prop"
                onClick={() => this.toggleBack()}
              >
                <label>Previous</label>
              </div> */}
            </div>
            <div
              className={
                property_id ? "text-right btn-exceed" : "text-right col-edit"
              }
              xs="4"
            >
              <Button
                color="primary"
                onClick={() => this.handleAddEdit()}
                size="sm"
                className={
                  isUpdateLoading && property_id
                    ? "button-wrapping up-prop-btn"
                    : "button-wrapping "
                }
              >
                {property_id ? "Update" : "Add"}
              </Button>
            </div>
          </Row>

          <Row>
            <div className="col new-table ">
              <Card className="shadow">
                <ListingHeaderLayout label={"Basic Information"} />
                {/* <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="14">
                      <h3 className="mb-0">Basic Information</h3>
                    </Col>
                  </Row>
                </CardHeader> */}
                <CardBody>
                  <Form>
                    <div>
                      <Row>
                        <Col lg="4" className="mb-3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Name*
                            </label>

                            <Input
                              className="form-control-alternative"
                              value={name}
                              name="name"
                              placeholder="Name"
                              type="text"
                              onChange={this.handleInputChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup className="form-relative geoLocation">
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Geo Location*
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={geo}
                              name="geo"
                              id="input-first-name"
                              onChange={this.displayPlaces}
                              placeholder="Geo Location"
                              type="text"
                              style={{ width: "94%" }}
                            // readOnly
                            />
                            <img
                              src={Geo}
                              className="loc_abs point"
                            // onClick={() => this.onMapIconClick()}
                            />
                            {this.state.isShowPlaces && this.state.geo && (
                              <ul>
                                {this.state.places.map((place) => (
                                  <li onClick={() => this.getPlaceData(place)}>
                                    {place.description}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              State*
                            </label>
                            <DropdownCommon
                              innerStyle={{ marginTop: "13px" }}
                              status={state}
                              setStatus={this.handleStateInputChange}
                              statusList={stateList}
                              stateAndcity={true}
                            ></DropdownCommon>
                            {/* <select
                              className="form-control-alternative form-control point"
                              id="input-first-name"
                              name="state"
                              value={state}
                              onChange={this.handleStateInputChange}
                            >
                              <option value="">Select</option>
                              {stateList.map((state, i) => (
                                <option
                                  value={JSON.stringify({
                                    id: state.state_id,
                                    name: state.name,
                                  })}
                                  key={i}
                                >
                                  {state.name}
                                </option>
                              ))}
                            </select> */}
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              City*
                            </label>
                            <DropdownCommon
                              innerStyle={{ marginTop: "13px" }}
                              status={city}
                              setStatus={this.handleCityInputChange}
                              statusList={cityList}
                              stateAndcity={true}
                            ></DropdownCommon>
                            {/* <select
                              className="form-control-alternative form-control point  "
                              id="input-first-name"
                              name="city"
                              value={city}
                              onChange={this.handleCityInputChange}
                            >
                              <option value="">Select</option>
                              {cityList.map((city, i) => (
                                <option
                                  value={JSON.stringify({
                                    id: city.city_id,
                                    name: city.name,
                                  })}
                                  key={i}
                                >
                                  {city.name}
                                </option>
                              ))}
                            </select> */}
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              ZIP*
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={zip}
                              name="zip"
                              id="input-first-name"
                              onChange={this.handleInputChange}
                              placeholder="ZIP"
                              type="text"
                              maxLength="5"
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="4" className="mb-3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              No of Bedrooms*
                            </label>

                            <Input
                              className="form-control-alternative"
                              value={beds}
                              name="beds"
                              id="input-email"
                              placeholder="No of Bedrooms"
                              type="number"
                              onChange={this.handleInputChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              No of Bathrooms*
                            </label>

                            <Input
                              className="form-control-alternative"
                              value={baths}
                              name="baths"
                              id="input-email"
                              placeholder=" No of Bathrooms"
                              type="number"
                              onChange={this.handleInputChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>

              <Modal
                className="modal-dialog-centered map-marker"
                isOpen={isMapVisible}
                toggle={() => this.toggleModal()}
                onKeyDown={(event) => this.onEscPress(event)}
              >
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Select the property location on the map
                  </h5>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.onMapIconClick()}
                  >
                    ×
                  </button>
                </div>
                <div className="modal-body">
                  {" "}
                  <MapContainer
                    onMapIconClick={this.onMapIconClick}
                    onSave={this.onSave}
                    courtAddress={location}
                  />
                </div>
              </Modal>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}
export default AddEditPersonalProperty;
