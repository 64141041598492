import React, { Component } from "react";

import { Card, CardBody, CardHeader, Spinner } from "reactstrap";
import closeIcon from "../../assets/img/brand/close.svg";
import "react-datepicker/dist/react-datepicker.css";
import { showDatenTime } from "constants/utils";

class VaultTimeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeLineData: [],
      isLoading: true,
    };
  }

  componentDidUpdate(prevProps) {
    let { data } = this.props;
    if (prevProps.data !== data) {
      this.setState({ isLoading: true }, () => this.setTimeLine());
    }
  }

  setTimeLine = async () => {
    if (this.props.data) {
      this.setState({ timeLineData: this.props.data }, () => {
        this.setState({ isLoading: false });
      });
    }
  };

  closeModal = () => {
    this.setState({ timeLineData: [] }, this.props.close());
    // this.props.close();
  };

  render() {
    let { timeLineData, isLoading } = this.state;
    return (
      <div>
        {this.props.isOpen && (
          <Card className="bnone">
            <CardHeader>
              <div className="flex">
                <div className="fw-500 f16 cl000000">Timeline</div>

                <img
                  src={closeIcon}
                  onClick={() => this.closeModal()}
                  className="point"
                  alt=""
                />
              </div>
            </CardHeader>
            <CardBody className="pad30p">
              {isLoading ? (
                <div className="spin-loading3 mt-30">
                  <Spinner type="border" color="#012A4D" />
                </div>
              ) : timeLineData.length > 0 ? (
                timeLineData.map((data) => (
                  <div className="align-left date_info invoiceTimeline">
                    <div className="flexMidColumn mr-20">
                      <div className="timeLineRound"></div>
                      <div className="timeLineVerLine"></div>
                    </div>
                    <div className="flex-column">
                      <div className="f14 mb-15 cl5A5858 fw-500 lh-12">
                        {data?.msg ?? "-"}
                      </div>
                      <div
                        className={
                          data?.reason
                            ? "fw-500 f14 linkDiv lh-12 mb-20"
                            : "fw-500 f14 linkDiv lh-12 mb-40"
                        }
                      >
                        {showDatenTime(data.date)}
                      </div>
                      {data?.reason && (
                        <div className=" timelineReason f14 fw-400 mb-35">
                          <span className="f14 fw-500">Reason : </span>
                          {data?.reason}
                        </div>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div className="align_midd">No Timeline Available</div>
              )}
            </CardBody>
          </Card>
        )}
      </div>
    );
  }
}

export default VaultTimeline;
