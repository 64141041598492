import React, { Component } from "react";
import ApiService from "constants/ApiService";
import {
  returnFirstDegreeObjValue,
  showLocaldate,
  displayPrice,
} from "constants/utils";

import { Card, CardHeader, Table, Spinner, Input, Button } from "reactstrap";

class RemoveLateFee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceStatus: "",
      lateFeeList: [],
      btnLoading: false,
      tableLoad: true,
    };
  }

  componentDidUpdate(prevProps) {
    let { id, isOpen } = this.props;

    if (prevProps.id !== id) {
      if (isOpen) {
        this.getDepositDetail(id);
      }
    }
  }
  getDepositDetail = async (id) => {
    let response = await ApiService.getAPI(`host/deposits/${id}`);
    if (response.success) {
      this.setState({
        lateFeeList: response?.data?.deposit?.late_fee,
        tableLoad: false,
      });
    }
  };

  closeModal = () => {
    this.setState({ tableLoad: true });
    this.props.close();
  };

  handleLateFeeCheckClick = (e, index) => {
    let { lateFeeList } = this.state;

    lateFeeList[index].is_waived = !lateFeeList[index].is_waived;
    this.setState({ lateFeeList });
  };

  HandleRemoveLateFee = async () => {
    let { lateFeeList } = this.state;

    let { id, toast } = this.props;
    let data = { late_fee: lateFeeList };

    {
      if (lateFeeList) {
        this.setState({ btnLoading: true });
        let response = await ApiService.patchAPI(
          `host/deposits/waive_fee/${id}`,
          data
        );
        if (response.success) {
          this.setState({ btnLoading: false, tableLoad: true });
          this.props.close(response.message);
        } else {
          this.setState({ btnLoading: false });
          toast(response.message);
        }
      }
    }
  };
  render() {
    let { lateFeeList, btnLoading, tableLoad } = this.state;

    return (
      <div>
        {this.props.isOpen && (
          <>
            <Card className="br-none">
              <CardHeader className="br-none">
                <div className="flex">
                  <div>Update Late Fee</div>
                  <button onClick={() => this.closeModal()} className="close">
                    X
                  </button>
                </div>
              </CardHeader>
            </Card>

            <Card className="shadow card_with_paginator br-none">
              {tableLoad ? (
                <div className="spin-loading3 mt-30">
                  <Spinner type="border" color="#012A4D" />
                </div>
              ) : (
                <div style={{}}>
                  <div className="late-fee-table">
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Date</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Enable</th>
                        </tr>
                      </thead>

                      {lateFeeList?.length > 0 ? (
                        <tbody>
                          <>
                            {lateFeeList.map((latefee, index) => (
                              <tr key={index}>
                                <td className="text-capitalize cursor-point">
                                  {showLocaldate(
                                    returnFirstDegreeObjValue(latefee, "for")
                                  )}
                                </td>

                                <td>
                                  {displayPrice(
                                    returnFirstDegreeObjValue(latefee, "amount")
                                  )}
                                </td>
                                <td>
                                  <Input
                                    className="role_manage_checkbox"
                                    onChange={(e) =>
                                      this.handleLateFeeCheckClick(e, index)
                                    }
                                    checked={latefee?.is_waived ? false : true}
                                    type="checkbox"
                                  />
                                </td>
                              </tr>
                            ))}
                          </>
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      )}
                    </Table>
                  </div>

                  <div
                    className="flexEnd mt-30"
                    style={{ marginRight: "30px" }}
                  >
                    <div
                      className="mr-20 point f14 button-secondary"
                      onClick={() => this.closeModal()}
                    >
                      Cancel
                    </div>
                    <Button
                      color="primary"
                      className="button-wrapping "
                      size="sm"
                      onClick={() => this.HandleRemoveLateFee()}
                    >
                      {btnLoading ? (
                        <div className="spin-loading-save w100 h100">
                          <Spinner type="border" color="#012A4D" />
                        </div>
                      ) : (
                        <div>Submit</div>
                      )}
                    </Button>
                  </div>
                </div>
              )}
            </Card>
            <div></div>
          </>
        )}
      </div>
    );
  }
}

export default RemoveLateFee;
