import React, { Component } from "react";
import ApiService from "constants/ApiService";
import { showCalendarFormat } from "constants/utils";
import {
    Card,
    CardBody,
    CardHeader,
    Table,
    Spinner,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    DropdownMenu,
    DropdownItem,
    Button,
    Input,
} from "reactstrap";
import closeIcon from "../../assets/img/brand/close.svg";
import DateSvg from "../../assets/img/brand/date-pick.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class DepositMarkPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            payDate: "",
            payMode: "online_transfer",
            notes: "",
            btnLoading: false,
        };
    }
    handleDateChange = (e) => {
        this.setState({ payDate: e });
    };
    handleInputChange = (e) => {
        this.setState({ notes: e.target.value });
    };
    setPayMode = (mode) => {
        this.setState({ payMode: mode });
    };

    closeModal = () => {
        this.props.close();
        this.setState({ payMode: "online_transfer", payDate: "", notes: "" });
    };
    markPayment = async () => {
        let { payDate, payMode, notes } = this.state;
        let { toast, id } = this.props;

        if (payDate === "" || payDate === null || payDate === undefined) {
            toast("Please select Payment Date");
        } else if (payMode === "" || payMode === null || payMode === undefined) {
            toast("Please select Payment Mode");
        } else {
            this.setState({ btnLoading: true });
            let data = {
                mode: payMode,
                date: showCalendarFormat(payDate),
                notes: notes,
            };
            let response = await ApiService.patchAPI(`host/deposits/mark_paid/${id}`, data);
            if (response.success) {
                this.props.close(response.message);
                this.setState({
                    payMode: "",
                    payDate: "",
                    notes: "",
                    btnLoading: false,
                });
                // toast(response.message);
            } else {
                toast(response.message);
                this.setState({ btnLoading: false });
            }
        }
    };

    render() {
        let { payDate, payMode, notes, btnLoading } = this.state;
        return (
            <div>
                {this.props.isOpen && (
                    <Card className="bnone">
                        <CardHeader>
                            <div className="flex">
                                <div className="fw-500 f16 cl000000">Mark Payment</div>

                                <img
                                    src={closeIcon}
                                    onClick={() => this.closeModal()}
                                    className="point"
                                />
                            </div>
                        </CardHeader>
                        <CardBody className="pad30p ">
                            <div>
                                <div className="cl2A1E17 mb-15 f14 fw-400">Date*</div>
                                <div className="form-relative flexStart mb-30" style={{ border: "1px solid rgba(225, 225, 225, 0.4)", borderRadius: "5px" }}>
                                    <DatePicker
                                        selected={payDate}
                                        dateFormat="MM-dd-yyyy"
                                        placeholderText=""
                                        className="mark_payment_date bnone point"
                                        ariaDescribedBy="basic-addon2"
                                        onChange={this.handleDateChange}
                                        maxDate={new Date()}
                                        minDate={new Date().setMonth(new Date().getMonth() - 1)}
                                        id="notice_date"
                                    />

                                    <label className="mb-remove" htmlFor="notice_date">
                                        <img
                                            src={DateSvg}
                                            alt="Date-Picker Icon"
                                            className="dateIcon point"
                                        />
                                    </label>
                                </div>
                            </div>
                            <div>
                                <div className="mb-15 cl2A1E17 f14 fw-400">Mode of Payment</div>
                                <div className="invoiceStatusView">
                                    <UncontrolledDropdown className="status_select_div  mb-30">
                                        <DropdownToggle className="pr-0 drop_down_container">
                                            <Media className="capitalize">
                                                {payMode === "cash"
                                                    ? "Cash"
                                                    : payMode === "online_transfer"
                                                        ? "Online Transfer"
                                                        : payMode === "cheque"
                                                            ? "Cheque"
                                                            : payMode === "others"
                                                                ? "Others"
                                                                : payMode === "zelle" && "Zelle"}
                                                <i className="fas fa-angle-down"></i>
                                            </Media>
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-arrow w100" bottom>
                                            <DropdownItem
                                                className="dropdown_item"
                                                onClick={() => this.setPayMode("online_transfer")}
                                            >
                                                Online Transfer
                                            </DropdownItem>
                                            <DropdownItem
                                                className="dropdown_item"
                                                onClick={() => this.setPayMode("cash")}
                                            >
                                                Cash
                                            </DropdownItem>

                                            <DropdownItem
                                                className="dropdown_item"
                                                onClick={() => this.setPayMode("cheque")}
                                            >
                                                Cheque
                                            </DropdownItem>
                                            <DropdownItem
                                                className="dropdown_item"
                                                onClick={() => this.setPayMode("zelle")}
                                            >
                                                Zelle
                                            </DropdownItem>
                                            <DropdownItem
                                                className="dropdown_item"
                                                onClick={() => this.setPayMode("others")}
                                            >
                                                Others
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </div>
                            </div>
                            <div>
                                <div className="mb-15 cl2A1E17 f14 fw-400">Notes</div>
                                <Input
                                    type="textarea"
                                    value={notes}
                                    name="Notes"
                                    rows={4}
                                    onChange={this.handleInputChange}
                                    className="mb-50"
                                    maxLength={50}
                                />
                            </div>
                            <div className="flexEnd">
                                <div
                                    className="mr-20 point f14 button-secondary"
                                    onClick={() => this.closeModal()}
                                >
                                    Cancel
                                </div>
                                <Button
                                    color="primary"
                                    className="button-wrapping "
                                    size="sm"
                                    onClick={() => this.markPayment()}
                                >
                                    {btnLoading ? (
                                        <div className="spin-loading-save w100 h100">
                                            <Spinner type="border" color="#012A4D" />
                                        </div>
                                    ) : (
                                        <div>Mark as Paid</div>
                                    )}
                                </Button>
                            </div>
                        </CardBody>
                    </Card>
                )}
            </div>
        );
    }
}

export default DepositMarkPayment;
