import ApiService from "constants/ApiService";
import returnSecondDegreeObjValue from "constants/utils";
import {
  returnFirstDegreeObjValue,
  returnThirdDegreeObjValue,
} from "constants/utils";
import { getCloudinaryImage } from "constants/utils";
import React, { Component } from "react";
import { getUSFormat } from "constants/utils";
// import DefaultUser from "../../src/assets/img/brand/defaultNoticeUser.svg";
import {
  Table,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Form,
  Row,
  Col,
  Spinner,
  Container,
} from "reactstrap";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
let customer_id;
class CustomerDetails extends Component {
  constructor(props) {
    super(props);
    customer_id = this.props.match.params.id;
    this.state = {
      customer: {},
      isLoading: true,
    };
  }

  componentDidMount() {
    if (customer_id) {
      this.getCustomerDetails();
    }
  }
  getCustomerDetails = async () => {
    let response = await ApiService.getAPI(`host/customer/${customer_id}`);
    if (response.success) {
      this.setState({ customer: response.data.customer, isLoading: false });
    }
  };
  render() {
    let { customer, isLoading } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#012A4D" />
      </div>
    ) : (
      <>
        <CustomListingContainer style={{ padding: "24px" }}>

          <div className="flex-card-book" style={{ margin: "0px 27px" }}>
            <div className="">
              <div className="wrap-card-book">
                {customer?.image ? (
                  <img
                    src={getCloudinaryImage(130, 130, customer.image, "c_fill")}
                    alt="profile image"
                    className="profile-book-img mb-4"
                  />
                ) : (
                  <></>
                  //   <img
                  //     src={DefaultUser}
                  //     alt="profile image"
                  //     className="profile-book-img mb-4"
                  //   />
                )}

                <h3 className="mb-0 name-book">
                  {" "}
                  {returnSecondDegreeObjValue(customer, "name", "first")}{" "}
                  {returnSecondDegreeObjValue(customer, "name", "last")}
                </h3>
              </div>
            </div>
            <div className="info-book">
              <div className="new-table profile-book-base ">
                <Card className="basic-book-info">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <h3 className="mb-0 mt_0">Basic Information</h3>
                    </Row>
                  </CardHeader>
                  <CardBody className="new-card-book">
                    <Form>
                      <div>
                        <Row className="book-row-basic mr-0">
                          <label className="basic-data ">Name</label>
                          <label className="basic-info-name">
                            {returnSecondDegreeObjValue(
                              customer,
                              "name",
                              "first"
                            )}{" "}
                            {returnSecondDegreeObjValue(
                              customer,
                              "name",
                              "last"
                            )}
                          </label>
                        </Row>
                        <Row className="book-row-basic mr-0">
                          <label className="basic-data ">Gender</label>
                          <label className="basic-info-name">
                            {returnFirstDegreeObjValue(customer, "gender")}
                          </label>
                        </Row>
                        <Row className="book-row-basic mr-0">
                          <label className="basic-data ">Contact Number</label>
                          <label className="basic-info-name">
                            {getUSFormat(
                              returnSecondDegreeObjValue(
                                customer,
                                "phone",
                                "national_number"
                              )
                            )}
                          </label>
                        </Row>
                        <Row className="book-row-basic mr-0">
                          <label className="basic-data ">Email </label>
                          <label className="basic-info-name">
                            {returnFirstDegreeObjValue(customer, "email")}
                          </label>
                        </Row>
                        <Row className="book-row-basic mr-0">
                          <label className="basic-data ">Pets</label>
                          <label className="basic-info-name">
                            {customer?.pets?.length > 0
                              ? customer.pets.map(
                                (pet, i) => (i ? "," : "") + " " + pet
                              )
                              : "-"}
                          </label>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
          <Row className="align-items-center header-div-book mt-o pad-book-top mt_20" style={{ marginLeft: "27px" }}>
            <Col xs="8" className="manage-con">
              <h3 className="mb-0 managers-crumb">Documents</h3>
            </Col>
          </Row>
          <Row className="">
            <div className="col new-table">
              <Card className="basic-book-info">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Type</th>
                      <th scope="col">Start Date</th>
                      <th scope="col">Expiry Date</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                </Table>
                <Card className="basic-book-info">
                  <div className="no-data font-book">
                    {" "}
                    No Documents Available
                  </div>
                </Card>
              </Card>
            </div>
          </Row>
          <Card className="basic-book-info">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <Col xs="12">
                  <h3 className="mb-0 mt_0">Professional Information</h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="new-card-book content-pad-book">
              <Form>
                <div>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className=" basic-data mb-2"
                          htmlFor="input-username"
                        >
                          Current Employer
                        </label>
                        <h4 className="basic-info-name book-name-font">
                          {returnSecondDegreeObjValue(
                            customer,
                            "professional_details",
                            "current_emp"
                          )}
                        </h4>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="basic-data mb-2"
                          htmlFor="input-email"
                        >
                          Current Client
                        </label>
                        <h4 className="basic-info-name book-name-font">
                          {returnSecondDegreeObjValue(
                            customer,
                            "professional_details",
                            "current_client"
                          )}
                        </h4>
                      </FormGroup>
                    </Col>
                    <Col lg="6" className="pad-book-phone">
                      <FormGroup>
                        <label
                          className=" basic-data mb-2"
                          htmlFor="input-email"
                        >
                          Job Title
                        </label>
                        <h4 className="basic-info-name book-name-font">
                          {returnSecondDegreeObjValue(
                            customer,
                            "professional_details",
                            "job_title"
                          )}
                        </h4>
                      </FormGroup>
                    </Col>
                    <Col lg="6" className="pad-book-phone">
                      <FormGroup>
                        <label
                          className=" basic-data mb-2"
                          htmlFor="input-email"
                        >
                          Tecnology stack
                        </label>
                        <h4 className="basic-info-name book-name-font">
                          {returnSecondDegreeObjValue(
                            customer,
                            "professional_details",
                            "tech_stack"
                          )}
                        </h4>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Card className="basic-book-info pad-book-top">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <Col xs="12">
                  <h3 className="mb-0 mt_0">Additional Information</h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="new-card-book content-pad-book">
              <Form>
                <div>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className=" basic-data mb-2"
                          htmlFor="input-username"
                        >
                          Hobbies and Interest
                        </label>
                        <p className="basic-info-name book-name-font">
                          {returnSecondDegreeObjValue(
                            customer,
                            "additional_info",
                            "hobbies"
                          )}
                        </p>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label
                          className=" basic-data mb-2"
                          htmlFor="input-email"
                        >
                          Health Condition to be aware of
                        </label>
                        <p className="basic-info-name book-name-font">
                          {returnSecondDegreeObjValue(
                            customer,
                            "additional_info",
                            "health_con"
                          )}
                        </p>
                      </FormGroup>
                    </Col>
                    <Col lg="6" className="pad-book-phone">
                      <FormGroup>
                        <label
                          className=" basic-data mb-2"
                          htmlFor="input-email"
                        >
                          Language
                        </label>
                        <h4 className="basic-info-name book-name-font">
                          {returnSecondDegreeObjValue(
                            customer,
                            "additional_info",
                            "lang"
                          )}
                        </h4>
                      </FormGroup>
                    </Col>
                    <Col lg="6" className="pad-book-phone">
                      <FormGroup>
                        <label
                          className=" basic-data mb-2"
                          htmlFor="input-email"
                        >
                          Food Preferences
                        </label>
                        <h4 className="basic-info-name book-name-font">
                          {returnSecondDegreeObjValue(
                            customer,
                            "additional_info",
                            "food_preference"
                          )}
                        </h4>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Card className="basic-book-info pad-book-top">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <Col xs="12">
                  <h3 className="mb-0 mt_0">Emergency Details</h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="new-card-book content-pad-book">
              <Form>
                <div>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className=" basic-data mb-2"
                          htmlFor="input-username"
                        >
                          Full Name
                        </label>
                        <h4 className="basic-info-name book-name-font text-capitalize">
                          {returnSecondDegreeObjValue(
                            customer,
                            "emergency_contact",
                            "name"
                          )}
                        </h4>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label
                          className=" basic-data mb-2"
                          htmlFor="input-email"
                        >
                          Contact Number
                        </label>
                        <h4 className="basic-info-name book-name-font">
                          {getUSFormat(
                            returnThirdDegreeObjValue(
                              customer,
                              "emergency_contact",
                              "phone",
                              "national_number"
                            ).toString()
                          )}
                        </h4>
                      </FormGroup>
                    </Col>
                    <Col lg="6" className="pad-book-phone">
                      <FormGroup>
                        <label
                          className=" basic-data mb-2"
                          htmlFor="input-email"
                        >
                          Relationship
                        </label>
                        <h4 className="basic-info-name book-name-font">
                          {returnSecondDegreeObjValue(
                            customer,
                            "emergency_contact",
                            "relationship"
                          )}
                        </h4>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </CardBody>
          </Card>
        </CustomListingContainer >
      </>
    );
  }
}
export default CustomerDetails;
