import React, { Component } from "react";

class PaymentIframe extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <>
      <div className="flex payMethodHeader f18 padl30 padr30 inPad">
        <div className="fw-500 f16 ff-pop cl212121">{this.props.data.heading}</div>
        {/* <div
          className="clpaid closePayment fw-500 point"
          onClick={() => this.props.close({success: false})}
        >
          X
        </div> */}
      </div>
      <iframe src={this.props.data.link} style={{width: "100%", height: "80Vh"}} title={this.props.data.heading}></iframe>
    </>
  }
}

export default PaymentIframe;
