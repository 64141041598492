import {
  Card,
  CardBody,
  FormGroup,
  Row,
  Col,
  Container,
  CardFooter,
} from "reactstrap";
import React, { Component } from "react";
import Lottie from "react-lottie";
import VerifySuccess from "../../assets/Lotties/successLottie.json";
class ResetVerify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verifyCounter: 5,
    };
  }
  componentDidMount = () => {
    const { verifyCounter } = this.state;

    setInterval(() => {
      if (verifyCounter === 0 || verifyCounter < 0) return;
      else this.setState({ verifyCounter: this.state.verifyCounter - 1 });
    }, 1000);
  };
  componentWillUnmount = () => {
    clearInterval();
  };
  redirectLoginPage = () => {
    this.props.history.push("/login");
  };
  render() {
    let { verifyCounter } = this.state;

    const SuccessVerifyOptions = {
      loop: true,
      autoplay: true,
      animationData: VerifySuccess,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <>
        {" "}
        <div className="new-pro-design">
          <Container className="success-verify-max-width">
            <Row className="justify-content-center">
              <Col lg="5" md="7" className="login-flexing ">
                <Card className="bg-secondary shadow border-0 radius-log-verify">
                  <CardBody className="px-lg-5  pad-log-bot-verify pad-left-right-2">
                    <div className="profile-body">
                      <Row>
                        <Col lg="12" className="mt-5 verify-flex">
                          <Lottie
                            options={SuccessVerifyOptions}
                            height={170}
                            width={170}
                          />
                          <label className="verify-text mt-4 mb-0">
                            Well Done!
                          </label>
                          <FormGroup className="margin-level-verify">
                            <label
                              className="label-verify mb-3 mt-3"
                              htmlFor="input-username"
                            >
                              Your Password has been reset successfully
                            </label>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <div className="line-divider"></div>
                  </CardBody>
                  <CardFooter className="text-center foot-verify">
                    <label className="receive-email mb-0">
                      You will be redirecting to login page{" "}
                      {verifyCounter > -1 ? (
                        <span className="disable-span">
                          {" "}
                          in 00:
                          {verifyCounter < 10
                            ? `0${verifyCounter}`
                            : verifyCounter}
                        </span>
                      ) : (
                        this.redirectLoginPage()
                      )}
                    </label>
                  </CardFooter>{" "}
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}
export default ResetVerify;
