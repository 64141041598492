import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Form,
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Spinner,
} from "reactstrap";
import ApiService from "constants/ApiService";
import Message from "../../assets/img/brand/noticeMessage.svg";
import Call from "../../assets/img/brand/noticeCall.svg";
import DefaultProperty from "../../assets/img/brand/defaultProperty.svg";
import DefaultUser from "../../assets/img/brand/defaultNoticeUser.svg";
import { returnFirstDegreeObjValue } from "constants/utils";
import { getCloudinaryImage } from "constants/utils";
import { returnThirdDegreeObjValue } from "constants/utils";
import { getUSFormat } from "constants/utils";
import returnSecondDegreeObjValue from "constants/utils";
import { returnDateTxt } from "constants/utils";
import { showLocaldate } from "constants/utils";
import { showCalendarFormat } from "constants/utils";
import AcceptModal from "components/Modal/AcceptModal";
import RejectModal from "components/Modal/RejectModal";
import ToastList from "components/Toast/ToastList";
import { showSuccess } from "constants/utils";
import { showError } from "constants/utils";
import { returnCustomerFirsLastName } from "constants/utils";
// core components
let notice_id;
const MemoizedAccept = React.memo(AcceptModal);
const MemoizedReject = React.memo(RejectModal);
class NoticeDetail extends Component {
  constructor(props) {
    super(props);
    notice_id = this.props.match.params.notice_id;
    this.state = {
      noticeDetail: {},
      modalOpen: false,
      rejectModal: false,
      isLoading: true,
      notice_date: "",
      act_date: "",
      notice_id: "",
      key: "",
      about: "",
      reject_reason: "",
      toast: false,
      response: "",
      toastType: "",
    };
  }
  componentDidMount() {
    if (notice_id) this.getNoticeDetail();
  }
  getNoticeDetail = async () => {
    let response = await ApiService.getAPI(`host/notice/${notice_id}`);
    if (response.success) {
      let noticeDetail = response.data.notice;
      this.setState({
        noticeDetail,
        isLoading: false,
      });
    }
  };
  toggleStatus = async (key) => {
    let data = {};
    let response = await ApiService.patchAPI(
      `host/requests/${key}/${notice_id}`,
      data
    );
    if (response.success) {
      showSuccess(this, response.message);
      setTimeout(() => this.getNoticeDetail(), 1200);
    } else {
      showError(this, response.message);
    }
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  toggleModal = () => {
    this.setState({ modalOpen: true });
  };
  toggleCloseModal = () => {
    this.setState({ modalOpen: false });
  };
  handleRejectReason = async () => {
    let { reject_reason } = this.state;
    if (reject_reason === "") {
      showError(this, "Please enter reason to reject this notice");
    } else {
      let data = { reject_reason };
      let response = await ApiService.patchAPI(
        `host/notice/reject/${notice_id}`,
        data
      );
      if (response.success) {
        showSuccess(this, response.message);
        this.toggleCloseModal();
        setTimeout(() => this.getNoticeDetail(), 1200);
      } else {
        showError(this, response.message);
      }
    }
  };
  handleCloseModal = () => {
    this.setState({
      modalOpen: false,
      notice_date: "",
      act_date: "",
      notice_id: "",
      key: "",
    });
  };

  handleAcceptReject = async (key) => {
    var self = this;
    let { notice_id, notice_date } = self.state;
    if (notice_date === "") {
      showError(this, "Please select checkout date");
    } else {
      var data = { notice_date: showCalendarFormat(notice_date), };
      let response = await ApiService.patchAPI(`host/notice/${key}/${notice_id}`, data);
      if (response.success) {
        showSuccess(this, response.message);
        self.getNoticeDetail();
        self.handleCloseModal();
      } else {
        showError(this, response.message);
      }
    }
  };
  updateStateValues = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      function () {
        if (value === null) {
          this.setState({
            [key]: "",
          });
        }
      }
    );
  };

  handleOnNoticeChange = (e) => {
    this.updateStateValues("notice_date", e);
  };

  toggleModal = (date, actDate, id, key) => {
    this.setState({
      modalOpen: !this.state.modalOpen,
      notice_date: returnDateTxt(date),
      act_date: showLocaldate(actDate),
      notice_id: id,
      key,
    });
  };
  toggleRejectModal = (id, key) => {
    this.setState({
      rejectModal: !this.state.rejectModal,
      notice_id: id,
      key,
    });
  };
  toggleCloseRejectModal = () => {
    this.setState({
      rejectModal: false,
      notice_id: "",
      key: "",
      reject_reason: "",
    });
  };
  render() {
    let {
      isLoading,
      modalOpen,
      reject_reason,
      noticeDetail,
      rejectModal,
      toast,
      response,
      toastType,
    } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#012A4D" />
      </div>
    ) : (
      <>
        <Container className="mt-8 card-pad-wrapper mb_20" fluid>
          {toast && <ToastList message={response} type={toastType} />}
          <div>
            <Breadcrumb className="pad-to-8"></Breadcrumb>
          </div>
          <Row className="align-items-center header-div">
            <Col xs="8" className="manage-con">
              <Breadcrumb>
                <BreadcrumbItem
                  onClick={() => this.props.history.goBack()}
                  className="bread-cursor"
                >
                  Notices
                </BreadcrumbItem>
                <BreadcrumbItem active style={{ color: "#012a4d" }}>Details</BreadcrumbItem>
              </Breadcrumb>
            </Col>
            {noticeDetail.status === "pending" && (
              <Col className="text-right" xs="4">
                <>
                  {" "}
                  <Button
                    color="primary"
                    onClick={() =>
                      this.toggleRejectModal(noticeDetail.notice_id, "reject")
                    }
                    size="sm"
                    className="button-wrapping reject-wrapping mr-3"
                  >
                    Reject
                  </Button>
                  <Button
                    color="primary"
                    onClick={() =>
                      this.toggleModal(
                        noticeDetail.notice_date,
                        noticeDetail.act_date,
                        noticeDetail.notice_id,
                        "accept"
                      )
                    }
                    size="sm"
                    className="button-wrapping "
                  >
                    Accept
                  </Button>
                </>
              </Col>
            )}
          </Row>
          <Row>
            <div className="col new-table mb-4">
              <Card className="shadow">
                <CardHeader className="border-0 secondary-color-none">
                  <Row className="align-items-center">
                    <Col xs="12">
                      <h3 className="mb-0">Tenant Details</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="card-not-pad ">
                  <Form>
                    <div>
                      <Row>
                        {noticeDetail.user?.image ? (
                          <Col lg="2" className="notice-image-wrapper">
                            <img
                              src={getCloudinaryImage(
                                130,
                                130,
                                noticeDetail.user.image,
                                "c_fill"
                              )}
                              className="arrayImg"
                              alt="customer img"
                            />
                          </Col>
                        ) : (
                          <Col lg="2" className="notice-image-wrapper">
                            <img
                              src={DefaultUser}
                              className="arrayImg"
                              alt="customer img"
                            />
                          </Col>
                        )}

                        <Col lg="6" className="notice-det-pad">
                          <h3 className="mb-0 font-vary">
                            {returnCustomerFirsLastName(
                              noticeDetail,
                              "user",
                              "name",
                              "first",
                              "last"
                            )}
                          </h3>
                          <div className="call-message">
                            <div className="notice-flex-row">
                              <img src={Call} alt="" />
                              <label className="mb-0 ">
                                +
                                {getUSFormat(
                                  returnThirdDegreeObjValue(
                                    noticeDetail,
                                    "user",
                                    "phone",
                                    "national_number"
                                  )
                                )}
                              </label>
                            </div>
                            <div className="notice-flex-row ">
                              <img src={Message} alt="" />
                              <label className="mb-0 ">
                                {returnSecondDegreeObjValue(
                                  noticeDetail,
                                  "user",
                                  "email"
                                )}
                              </label>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Row>
          <Row>
            <div className="col new-table">
              <Card className="shadow">
                <CardHeader className="border-0 secondary-color-none">
                  <Row className="align-items-center">
                    <Col xs="12">
                      <h3 className="mb-0">Property Details</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="card-not-pad">
                  <Form>
                    <div>
                      <Row className="row-image-notice mb-20">
                        {noticeDetail?.property?.gallery?.length > 0 ? (
                          <Col lg="2" className="notice-imageprop-wrapper">
                            <img
                              src={getCloudinaryImage(
                                130,
                                130,
                                noticeDetail.property.gallery[0],
                                "c_fill"
                              )}
                              className="arrayImg proper-img"
                              alt="property img"
                            />
                          </Col>
                        ) : (
                          <Col lg="2" className="notice-imageprop-wrapper">
                            <img
                              src={DefaultProperty}
                              className="arrayImg "
                              alt="property img"
                            />
                          </Col>
                        )}

                        <Col lg="6" className="notice-det-pad-sec">
                          <h3 className="mb-0 font-vary">
                            {returnSecondDegreeObjValue(
                              noticeDetail,
                              "property",
                              "name"
                            )}
                          </h3>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                  <div className="modal-body profile-body notice-body">
                    <Row className="mb-10">
                      <Col lg="12" className="pad-left-invoice">
                        <FormGroup className="flex-invoice flex-secondary-invoice">
                          <label
                            className="label-notice-prop mb-10 label-invoice"
                            htmlFor="input-username"
                          >
                            Booking ID
                          </label>
                          <label
                            className="label-notice-id mb-0 label-invoice"
                            htmlFor="input-username"
                          >
                            {returnFirstDegreeObjValue(
                              noticeDetail,
                              "booking_id"
                            )}
                          </label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" className="pad-left-invoice">
                        <FormGroup className="flex-invoice ">
                          <label
                            className="label-notice-prop mb-0 "
                            htmlFor="input-username"
                          >
                            Created On
                          </label>
                          <label
                            className="label-notice-id mb-0 "
                            htmlFor="input-username"
                          >
                            {showLocaldate(
                              returnFirstDegreeObjValue(
                                noticeDetail,
                                "createdAt"
                              )
                            )}
                          </label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" className="pad-left-invoice">
                        <FormGroup className="flex-invoice ">
                          <label
                            className="label-notice-prop mb-0 "
                            htmlFor="input-username"
                          >
                            Check-In
                          </label>
                          <label
                            className="label-notice-id mb-0 "
                            htmlFor="input-username"
                          >
                            {showLocaldate(
                              returnFirstDegreeObjValue(
                                noticeDetail,
                                "check_in_date"
                              )
                            )}
                          </label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" className="pad-left-invoice">
                        <FormGroup className="flex-invoice ">
                          <label
                            className="label-notice-prop mb-0 "
                            htmlFor="input-username"
                          >
                            Check-Out
                          </label>
                          <label
                            className="label-notice-id mb-0 "
                            htmlFor="input-username"
                          >
                            {showLocaldate(
                              returnFirstDegreeObjValue(
                                noticeDetail,
                                "notice_date"
                              )
                            )}
                          </label>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="12" className="pad-left-invoice">
                        <FormGroup className="flex-invoice ">
                          <label
                            className="label-notice-prop mb-0 "
                            htmlFor="input-username"
                          >
                            Notice Status
                          </label>
                          <label
                            className="label-notice-id mb-0 "
                            htmlFor="input-username"
                          >
                            {/* cls_name="general_status_style" */}
                            <span
                              className={
                                noticeDetail.status === "accepted"
                                  ? "text-capitalize green"
                                  : noticeDetail.status === "cancelled" ||
                                    noticeDetail.status === "rejected"
                                    ? "text-capitalize red"
                                    : "text-capitalize yellow"
                              }
                            >
                              {returnFirstDegreeObjValue(
                                noticeDetail,
                                "status"
                              )}
                            </span>
                          </label>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <Row className="align-items-center mt-4">
                    <Col xs="12">
                      <h3 className="mb-0 ft_16">Reason</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" className="pad-left-invoice">
                      <FormGroup className="flex-invoice flex-secondary-invoice">
                        <p className="notice-detail-para mt-3 ft_15">
                          {returnFirstDegreeObjValue(noticeDetail, "reason")}
                        </p>
                      </FormGroup>
                    </Col>
                  </Row>
                  {noticeDetail.cancel_reason && (
                    <>
                      <Row className="align-items-center mt-4">
                        <Col xs="12">
                          <h3 className="mb-0 ft_16">Cancel Reason</h3>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12" className="pad-left-invoice">
                          <FormGroup className="flex-invoice flex-secondary-invoice">
                            <p className="notice-detail-para mt-3 ft_15">
                              {returnFirstDegreeObjValue(
                                noticeDetail,
                                "cancel_reason"
                              )}
                            </p>
                          </FormGroup>
                        </Col>
                      </Row>
                    </>
                  )}
                </CardBody>
              </Card>
            </div>
            <MemoizedAccept
              toggleModal={() => this.toggleModal("", "", "")}
              closeModal={() => this.handleCloseModal()}
              modalState={modalOpen}
              notice_date={this.state.notice_date}
              actDate={this.state.act_date}
              onChangeDate={this.handleOnNoticeChange}
              onSubmit={() => this.handleAcceptReject("accept")}
            />
            <MemoizedReject
              toggleModal={() => this.toggleRejectModal()}
              closeModal={() => this.toggleCloseRejectModal()}
              modalState={rejectModal}
              reject_reason={reject_reason}
              onInputChange={this.handleInputChange}
              onSubmit={() => this.handleRejectReason()}
            />
          </Row>
        </Container>
      </>
    );
  }
}
export default NoticeDetail;
