import React, { Component } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import MarkerSvg from "../../assets/img/brand/Marker.svg";
// import { toast, ToastContainer } from "react-toast";
//API Service class
import ApiService from "constants/ApiService";
const GoogleMapsAPI = "AIzaSyB-s5MrK-QcKMaYhqSb7BxWd3kIx31rFKM";
let timeOut;
export class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      center: {
        lat: 40.782864,
        lng: -73.965355,
      },
      isSearching: false,
      searchTerm: "",
      isShowPlaces: false,
      places: [],
      formatted_address: "",
      latlng: [],
      isOpenDrawer: false,
      isLoading: false,
    };
  }
  // toast = (msg, type) => {
  //   toast[type](msg);
  // };

  componentDidMount = () => {
    var { courtAddress } = this.props;
    // var { center } = this.state
    if (courtAddress?.address && courtAddress?.loc?.length !== 0) {
      this.setState({
        // center: { lat: courtAddress?.loc[1], lng: courtAddress?.loc[0] },
        formatted_address: courtAddress.address,
        searchTerm: courtAddress.address,
      });
    }
  };

  //API Calls
  getPlaces = async () => {
    let { searchTerm } = this.state;
    if (searchTerm !== "") {
      let response = await ApiService.getAPI(
        `common/search?input=${searchTerm}`
      );
      if (response.success) {
        if (response.success) {
          let { place } = response.data;
          this.setState({
            places: place.predictions,
            isShowPlaces: true,
            isSearching: false,
          });
        } else {
          // toast.error(response.message);
          this.setState({
            isSearching: false,
          });
        }
      }
    } else {
      this.setState({ places: [], isSearching: false });
    }
  };

  getPlaceID = async (latlng) => {
    let response = await ApiService.getAPI(
      `host/maps/geocode?loc=${latlng[1]},${latlng[0]}`
    );
    if (response.success) {
      if (response.success) {
        let { place } = response.data;
        this.getPlaceData(place.place_id);
      }
    }
  };

  getPlaceData = async (place_id) => {
    let response = await ApiService.getAPI(`common/place/${place_id}`);
    if (response.success) {
      if (response.success) {
        let { result } = response.data.place;
        let latlng = result.geometry.location;
        this.setState({
          formatted_address: result.formatted_address,
          latlng: [latlng.lng, latlng.lat],
          center: {
            lat: latlng.lat,
            lng: latlng.lng,
          },
          isLoading: false,
          isShowPlaces: false,
          searchTerm: "",
        });
      } else {
        // toast.error(response.message);
        this.setState({
          isLoading: false,
          isShowPlaces: false,
          searchTerm: "",
        });
      }
    }
  };

  //Input Handler
  handleOnInputChange = (event) => {
    this.setState(
      {
        searchTerm: event.target.value,
      },
      () => this.checkSearchTerm(this.state.searchTerm, false)
    );
  };

  //Map Handler
  handleOnMapDrag = (mapProps, map) => {
    this.setState(
      {
        center: { lat: map.center.lat(), lng: map.center.lng() },
      },
      () => {
        this.getPlaceID([map.center.lng(), map.center.lat()]);
      }
    );
  };

  //Search Handler
  checkSearchTerm = (value, key) => {
    if (key) {
      if (value.trim() === "") {
        this.toast("error", "Please enter locaiton");
      } else {
        this.setState({
          isShowPlaces: false,
        });
      }
    }
    if (value.trim() !== "") {
      this.setState({ isSearching: true });
      if (timeOut !== undefined) {
        clearTimeout(timeOut);
        timeOut = setTimeout(() => {
          this.getPlaces(value);
        }, 1000);
      } else {
        timeOut = setTimeout(() => {
          this.getPlaces(value);
        }, 1000);
      }
    } else {
      this.setState({
        places: [],
      });
    }
  };

  render = () => {
    let state = this.state;
    let { onSave } = this.props;
    return (
      <div className="map-modal">
        {/* <ToastContainer position="bottom-left" delay={3000} /> */}
        <>
          <div>
            <div>
              {/* <div className="map-modal-address">
                <h3>{state.formatted_address}</h3>
                <label onClick={onMapIconClick}>
                  <b>X</b>
                </label>
              </div> */}
              <div>
                <div className="sm__modal">
                  <input
                    placeholder="Search"
                    value={state.searchTerm}
                    onChange={this.handleOnInputChange}
                  />
                  <button
                    onClick={() =>
                      onSave(state.formatted_address, [
                        state.center.lng,
                        state.center.lat,
                      ])
                    }
                  >
                    Save
                  </button>
                  {state.isShowPlaces && (
                    <ul>
                      {state.places.map((place, i) => (
                        <li
                          key={place.place_id}
                          onClick={() =>
                            this.setState(
                              {
                                isLoading: true,
                              },
                              () => this.getPlaceData(place.place_id)
                            )
                          }
                        >
                          {place.description}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <div className="google-map-popup">
              <Map
                google={this.props.google}
                zoom={12}
                initialCenter={state.center}
                center={state.center}
                onDragend={this.handleOnMapDrag}
                mapTypeControl={false}
                fullscreenControl={false}
                rotateControl={false}
                panControl={false}
                scaleControl={false}
                streetViewControl={false}
              >
                <Marker
                  position={state.center}
                  icon={{
                    url: MarkerSvg,
                  }}
                ></Marker>
              </Map>
              {/* <img src={Loc_marker} alt="loc_marker" className="loc-marker"></img> */}
            </div>
          </div>
        </>
      </div>
    );
  };
}

export default GoogleApiWrapper({
  apiKey: GoogleMapsAPI,
})(MapContainer);
