import React, { Component } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Modal,
  Spinner,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Media,
} from "reactstrap";
import ApiService from "constants/ApiService";
import ReactTooltip from "react-tooltip";
import { uploadCloudinary } from "constants/utils";
import { isInvalidName } from "constants/utils";
import "react-step-progress-bar/styles.css";
import Delete from "../../assets/img/brand/delete.svg";
import Remove from "../../assets/img/brand/remove.svg";
import Home from "../../assets/img/brand/home.svg";
import propTick from "../../assets/img/brand/propTick.svg";
import Geo from "../../assets/img/brand/geo.svg";
import DefaultAmenity from "../../assets/img/brand/default-amenity.svg";
import MapContainer from "components/mapPopUp/GoogleMapsTest";
import { getCloudinaryImage } from "constants/utils";
import { roleType } from "constants/utils";
import { LightgalleryItem } from "react-lightgallery";
import ToastList from "components/Toast/ToastList";
import { handleGoBack } from "constants/utils";
import { showError } from "constants/utils";
import { showSuccess } from "constants/utils";
import { maxLengthCheck } from "constants/utils";
import { add } from "date-fns";
import { onlyNumbers } from "constants/utils";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import closeIcon from "../../assets/img/brand/close.svg";
import showMore from "../../assets/img/brand/showMore.svg";
import { showLocaldate } from "constants/utils";
import Autocomplete from "components/AutoComplete/AutoComplete";
import { roleManager } from "constants/utils";
import { formatAmountWithCommas } from "constants/utils";
import { DropdownCommon } from "components/Dropdown/DropdownCommon";
import { roleSubManager } from "constants/utils";

let property_id;
class AddEditProperty extends Component {
  constructor(props) {
    super(props);
    property_id = this.props.match.params.property_id;
    this.state = {
      name: "",
      beds: "",
      baths: "",
      guests: "",
      rental_type: "",
      home_type: "",
      notice_period: "",
      days: "",
      cancel_days: "",
      price: "",
      due_date: "",
      late_fee: "",
      address: "",
      country: "",
      about: "",
      zip: "",
      optionArray: [],
      inputArray: [],
      counter: 0,
      inputFields: [],
      checkFields: [],
      policyFields: [],
      amenityList: [],
      stateList: [],
      cityList: [],
      selectedAmenities: [],
      imageArray: [],
      check: false,
      key: 0,
      progressPrecent: 0,
      progressActive: false,
      manage_by: "",
      managerList: [],
      manager: "",
      twinManager: "",
      managerName: "",
      geo: "",
      step_1: true,
      step_2: false,
      step_3: false,
      step_4: false,
      isMapVisible: false, //map visibilitty
      isLoading: true,
      location: {
        loc: [],
        address: "",
      },
      arrayIndex: 0,
      city: "",
      twincity: "",
      state: "",
      twinstate: "",
      toast: false,
      response: "",
      toastType: "",
      imageLoading: false,
      isUpdateLoading: false,
      commission_mode: "",
      commission_amount: "",
      checkSelectAll: false,
      places: [],
      isShowPlaces: false,
      deposit_amount: "",
      max_installments: "",
      tenant_screening: false,
      deposit_required: false,
      btn_enable: true,
      late_fee_amount: "",
      latefee_required: false,
      img_value: "",
      checkedSharing: false,
      checkedEditAccess: false,
      access: "",
      sharingModal: false,
      multiHostObj: {},
      sharingPercentage: "",
      amount: "",
      sharedByList: [],
      editHost: false,
      sharingValue: "",
      availableShares: true,
      indexOfHost: null,
      host_id: "",
      host_detail: ""
    };
  }
  componentDidMount() {
    this.handleOptions();
    this.getAmenity();
    if (property_id) {
      this.getPropertyDetail();
    } else {
      this.getStates();
      this.setState({ isLoading: false });
    }
    this.setState({
      progressActive: true,
    });
    let retrievedObject = localStorage.getItem('host_details');
    let parsedObject = JSON.parse(retrievedObject);
    this.setState({ host_detail: parsedObject.host })
  }
  getPropertyDetail = async () => {
    let response = await ApiService.getAPI(`host/properties/${property_id}`);
    if (response.success) {
      let {
        name,
        bedrooms,
        bathrooms,
        guests,
        rental_type,
        home_type,
        notice_period,
        price,
        late_fee,
        address,
        about,
        house_rules,
        policy,
        gallery,
        amenities,
        loc,
        min_cancel_days,
        commission,
        type,
        check_list,
        deposit,
        screening,
        sharedBy,
        host_id
      } = response?.data?.property;
      let managerObj = response.data?.property?.manager;
      const allocatedPercent = sharedBy?.reduce((acc, obj) => acc + obj?.commission?.percentage, 0)
      allocatedPercent === 99 && this.setState({ availableShares: false })
      this.setState(
        {
          name,
          beds: bedrooms,
          baths: bathrooms,
          guests,
          rental_type: rental_type,
          home_type,
          notice_period: notice_period?.unit,
          days: notice_period?.value,
          price,
          due_date: late_fee?.due,
          manage_by: managerObj?.name ? "manager" : "self",
          manager: managerObj?.name ? managerObj.name : "",
          twinManager: managerObj ? managerObj : "",
          managerName: managerObj ? managerObj.name : "",
          commission_mode: type,
          commission_amount: commission,
          cancel_days: min_cancel_days,
          late_fee: late_fee?.amount,
          geo: address?.line_1,
          country: address?.country,
          location: {
            address: address?.line_1,
            loc,
          },
          locationData: {
            ...address,
            loc,
          },
          tenant_screening: screening ? screening : false,
          // state_id: address?.state,
          // city_id: address?.city,
          city: address?.city?.id ?? "",
          twincity: address?.city ?? "",
          state: address?.state?.id ?? "",
          twinstate: address?.state ?? "",
          zip: address?.zip,
          inputFields: house_rules,
          policyFields: policy,
          checkFields: check_list ?? [],
          imageArray: gallery,
          selectedAmenities: amenities,
          about,
          isLoading: false,
          deposit_amount: deposit && deposit.amount ? deposit.amount : "",
          max_installments:
            deposit && deposit.installments ? deposit.installments : "",
          deposit_required: deposit ? deposit.is_enabled : false,
          latefee_required: deposit?.late_fee?.is_enabled ?? false,
          late_fee_amount: deposit?.late_fee?.amount ?? "",
          sharedByList: sharedBy ? sharedBy : [],
          checkedSharing: sharedBy?.length !== 0 ? true : false,
          host_id: host_id
        },
        () => {
          this.getStates();
          this.getCityList();
          this.getManagerList();
          this.checkSelectAllStatus();
        }
      );
    }
  };
  getManagerList = async () => {
    let response = await ApiService.getAPI(`host/role`);
    if (response.success) {
      let { managers } = response.data;
      managers.map((manager) => {
        manager["id"] = manager["manager_id"];
        delete manager["manger_id"];
      });
      this.setState({ managerList: managers });
    }
  };
  // Event handler
  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleDueDate = (e) => {
    this.setState({ due_date: e })
  }
  handleHomeType = (e) => {
    this.setState({ home_type: e })
  }
  handleRentalType = (e) => {
    this.setState({ rental_type: e })
  }
  handleDepositRequired = (e) => {
    this.setState({
      deposit_amount: "",
      max_installments: "",
      latefee_required: false,
      late_fee_amount: "",
      deposit_required: e
      // JSON.parse(e.target.value),
    });
  };

  handleHostShare = (e) => {
    this.setState({
      checkedSharing: e
    });
  };
  handleLateFeeRequired = (e) => {
    const { latefee_required } = this.state;
    this.setState({
      latefee_required: e,
      late_fee_amount: "",
    });
  };
  handleManageInputChange = (e) => {
    var self = this;
    if (!property_id && e === "manager") {
      this.setState({ rental_type: "entire_home" });
    }
    self.setState(
      { manage_by: e },
      () => this.getManagerList(),
      this.setState({ manager: "", commission_mode: "", commission_amount: "" })
    );
  };
  handleManagersChange = (event) => {
    const id = event;
    this.state.managerList.some((manager) => {
      if (manager.id === id) {
        this.setState({
          manager: manager.id,
          twinManager: manager,
        });
        return true;
      } else {
        this.setState({ manager: "", twinManager: "" });
      }
    });
  };
  handleStateInputChange = (e) => {
    let { stateList } = this.state;
    let id = e
    stateList.some((states) => {
      if (states.id === id) {
        this.setState({ state: id, twinstate: states }, () => {
          this.getCityList("changecity");
        });
      }
    });
  };

  handleCityInputChange = (e) => {
    let { cityList } = this.state;
    let id = e;
    cityList.some((cities) => {
      if (cities.id === id) {
        this.setState({ city: id, twincity: cities });
      }
    });
  };
  handleOptions = () => {
    let numArray = [];
    let number = 31;
    for (var i = 1; i < number; i++) {
      var obj = i;
      numArray.push({ value: obj, label: obj });
    }
    this.setState({ optionArray: numArray });
  };
  // Toast Handler

  addInput = () => {
    let { inputFields } = this.state;
    let arrayIndex = inputFields.length - 1;
    this.setState({ arrayIndex });
    if (arrayIndex === -1) {
      const values = [...inputFields];
      values.push({ value: "" });
      this.setState({ inputFields: values });
    } else if (inputFields[arrayIndex].value === "") {
      showError(this, "Please enter the rules field");
    } else {
      const values = [...inputFields];
      values.push({ value: "" });
      this.setState({ inputFields: values });
    }
  };

  handleOnChange = (i, e) => {
    const values = [...this.state.inputFields];
    values[i].value = e.target.value;
    this.setState({ inputFields: values });
  };
  handleRemove = (i) => {
    let { inputFields } = this.state;
    let values = [...inputFields];
    values.splice(i, 1);
    this.setState({ inputFields: values });
  };

  addCheckListInput = () => {
    let { checkFields } = this.state;
    let values = [...checkFields];
    if (checkFields.length > 0) {
      let checkEmpty = checkFields.every((field) => field.value !== "");

      if (checkEmpty) {
        values.push({ value: "" });
      } else {
        showError(this, "Please enter Check List");
      }
    } else {
      values.push({ value: "" });
    }
    this.setState({ checkFields: values });
  };

  handleCheckOnChange = (i, e) => {
    let values = [...this.state.checkFields];
    values[i].value = e.target.value;
    this.setState({ checkFields: values });
  };

  handleCheckRemove = (i) => {
    let { checkFields } = this.state;
    let values = [...checkFields];
    values.splice(i, 1);
    this.setState({ checkFields: values });
  };

  onScreening(value) {
    this.setState({
      tenant_screening: value,
    });
  }

  addPolicyInput = () => {
    let { policyFields } = this.state;
    let arrayIndex = policyFields.length - 1;
    if (arrayIndex === -1) {
      const values = [...policyFields];
      values.push({ value: "" });
      this.setState({ policyFields: values });
    } else if (policyFields[arrayIndex].value === "") {
      showError(this, "Please enter the Policy field");
    } else {
      const values = [...policyFields];
      values.push({ value: "" });
      this.setState({ policyFields: values });
    }
  };
  handlePolicyOnChange = (i, e) => {
    const values = [...this.state.policyFields];
    values[i].value = e.target.value;
    this.setState({ policyFields: values });
  };

  handlePolicyRemove = (i) => {
    let { policyFields } = this.state;
    let values = [...policyFields];
    values.splice(i, 1);
    this.setState({ policyFields: values });
  };

  handleCheckClick = (e, item) => {
    let { selectedAmenities, amenityList } = this.state;
    if (e.target.checked) {
      let amenityArray = this.state.selectedAmenities;
      amenityArray.push(item);
      this.setState({ selectedAmenities: amenityArray });
      if (selectedAmenities.sort().join(",") === amenityList.sort().join(",")) {
        this.setState({ checkSelectAll: true });
      }
    } else {
      let i = this.state.selectedAmenities.findIndex(
        (x) => x.amenity_id === item.amenity_id
      );
      if (i !== -1) {
        let { selectedAmenities } = this.state;
        let values = [...selectedAmenities];
        values.splice(i, 1);
        this.setState({ selectedAmenities: values, checkSelectAll: false });
      }
    }
  };

  handleSelectAllAmenities = (e) => {
    this.setState({ checkSelectAll: e.target.checked });
    if (e.target.checked) {
      this.setState({ selectedAmenities: this.state.amenityList });
    } else {
      this.setState({ selectedAmenities: [] });
    }
  };

  handleCheck = (id) => {
    if (id) {
      return (
        this.state.selectedAmenities.findIndex((x) => x.amenity_id === id) !==
        -1
      );
    } else {
      return false;
    }
  };
  getCityList = async (citychange) => {
    let { state, locationData, city, twincity } = this.state;
    let id = state;
    let response = await ApiService.getAPI(`host/cities/${id}`);
    if (response.success) {
      let city_list = response.data.cities;
      city_list.map((city) => {
        city["id"] = city["city_id"];
        delete city["city_id"];
        delete city["_id"];
      });
      if (citychange) {
        this.setState({ cityList: city_list, city: "", twincity: "" });
      } else {
        this.setState({
          cityList: city_list,
          city: locationData?.city?.id ? locationData.city.id : city,
          twincity: locationData?.city ? locationData.city : twincity,
        });
      }
    }
  };

  getAmenity = async () => {
    let response = await ApiService.getAPI("host/amenities");
    if (response.success) {
      this.setState({ amenityList: response.data.amenities }, () =>
        this.checkSelectAllStatus()
      );
    }
  };

  displayPlaces = async (e) => {
    let searchTerm = e.target.value;
    this.setState({ geo: searchTerm });
    let response = await ApiService.getAPI(`common/search?input=${searchTerm}`);
    if (response.success) {
      this.setState({
        isShowPlaces: true,
        places: response.data.place.predictions,
      });
    }
  };

  getPlaceData = async (place) => {
    let response = await ApiService.getAPI(`host/maps/place/${place.place_id}`);
    if (response.success) {
      if (response.success) {
        let locData = response.data;

        this.setState(
          {
            geo: place.description,
            isLoading: false,
            isShowPlaces: false,
            locationData: locData,
            state: locData?.state?.id ?? "",
            twinstate: locData?.state ?? "",
            zip: locData?.zip_code ? locData.zip_code : "",
          },
          () => {
            locData?.state && this.getStates();
            locData?.state && this.getCityList();
          }
        );
      } else {
        // toast.error(response.message);
        this.setState({
          isLoading: false,
          isShowPlaces: false,
        });
      }
    }
  };

  checkSelectAllStatus = () => {
    let { amenityList, selectedAmenities } = this.state;

    if (selectedAmenities.sort().join(",") === amenityList.sort().join(",")) {
      this.setState({ checkSelectAll: true });
    }
  };

  getStates = async () => {
    let { state, twinstate } = this.state;
    let response = await ApiService.getAPI("host/states");
    if (response.success) {
      let state_list = response.data.states;
      state_list.map((state) => {
        state["id"] = state["state_id"];
        delete state["state_id"];
        delete state["_id"];
      });
      this.setState({ stateList: state_list });
    }
  };
  onImageChangeHandler = async (e) => {
    let self = this;
    let { imageArray } = this.state;

    let file = e.target.files[0];

    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg"
    ) {
      if (file.size < 1000000) {
        this.setState({ imageLoading: true });
        let data = await uploadCloudinary(file, "properties");

        if (data.url) {
          let images = [...imageArray];
          images.push({
            format: data.format,
            public_id: data.public_id,
            version: `v${data.version}`,
            type: "image",
          });
          self.setState({ imageArray: images, imageLoading: false });
        } else {
          showError(this, "Could not upload image");
          this.setState({ imageLoading: false });
        }
      } else {
        showError(this, "File size should not exceeds 1 MB");
        this.setState({ imageLoading: false });
      }
    } else {
      showError(this, "Invalid Format");
      this.setState({ imageLoading: false });
    }
  };
  deleteImage = (idx) => {
    let { imageArray } = this.state;
    let images = [...imageArray];
    images.splice(idx, 1);
    this.setState({ imageArray: images });
  };
  onEscPress = (event) => {
    if (event.key === "Escape") {
      this.setState({ isMapVisible: false });
    }
  };
  onImageClick = (e) => {
    this.setState({ [e.target.name]: "" });
  };
  commissionAmountChange = (e) => {
    this.setState({ commission_amount: e.target.value });
  };
  commissionModeChange = (e) => {
    this.setState({ commission_mode: e.target.value });
  };
  closeModal = () => {
    const allocatedPercent = this.state.sharedByList.reduce((acc, obj) => acc + obj.commission.percentage, 0)
    if (allocatedPercent === 99) {
      this.setState({ availableShares: false })
    } else if (allocatedPercent !== 99) {
      this.setState({ availableShares: true })
    }
    this.setState({
      sharingModal: false,
      sharingPercentage: "",
      sharingValue: "",
      multiHostObj: {},
      amount: "",
      checkedEditAccess: false,
      editHost: false,
      indexOfHost: null
    });
  }
  handleAddEdit = async () => {
    let {
      imageArray,
      name,
      beds,
      baths,
      notice_period,
      guests,
      rental_type,
      home_type,
      policyFields,
      days,
      due_date,
      price,
      late_fee,
      address,
      country,
      city_id,
      zip,
      about,
      selectedAmenities,
      manage_by,
      cancel_days,
      manager,
      twinManager,
      managerName,
      twincity,
      twinstate,
      geo,
      locationData,
      step_1,
      step_2,
      step_3,
      step_4,
      city,
      state,
      inputFields,
      checkFields,
      commission_amount,
      commission_mode,
      deposit_required,
      deposit_amount,
      max_installments,
      tenant_screening,
      latefee_required,
      late_fee_amount,
      sharedByList,
      checkedSharing,
    } = this.state;
    let arrayIndex = inputFields.length - 1;

    let arrayPolicyIndex = policyFields.length - 1;

    if (step_1) {
      if (!name || name.length < 3 || isInvalidName(name)) {
        showError(this, "Please enter valid name");
      } else if (!geo) {
        showError(this, "Please enter geo location");
      } else if (!state) {
        showError(this, "Please select state");
      } else if (!city) {
        showError(this, "Please select city");
      } else if (!zip) {
        showError(this, "Please enter zip");
      } else if (
        locationData.city.id !== twincity.id &&
        locationData.state.id !== twinstate.id
      ) {
        showError(this, "Please change geo location as per the state and city");
      } else if (!rental_type) {
        showError(this, "Please select rental type");
      } else if (!home_type) {
        showError(this, "Please select home type");
      } else if (
        (roleType() && manage_by === "") ||
        manage_by === null ||
        manage_by === undefined
      ) {
        showError(this, "Please select manage by");
      } else if (roleType() && manage_by === "manager" && !manager) {
        showError(this, "Please select existing manager");
      } else if (manager && !commission_mode) {
        showError(this, "Please select Commission Mode");
      } else if (manager && commission_mode && !commission_amount) {
        showError(
          this,
          `Please enter ${commission_mode === "commission" ? "percentage" : "amount"
          }`
        );
      } else if (
        manager &&
        commission_mode !== "" &&
        commission_mode === "commission" &&
        (commission_amount < 1 || commission_amount > 99)
      ) {
        showError(this, " Percentage should be between 1 to 99");
      } else if (
        manager &&
        commission_mode !== "" &&
        commission_mode === "fixed_amount" &&
        commission_amount < 1
      ) {
        showError(this, "Please enter valid amount");
      } else {
        if (roleType() && manage_by === "manager") {
          this.setState({ isUpdateLoading: true, btn_enable: false });
          let data = {
            name,
            rental_type: rental_type,
            type: commission_mode,
            commission: commission_amount,
            address: {
              line_1: geo,
              city: twincity,
              state: twinstate,
              country: country,
              zip: zip,
            },
            home_type: home_type,
            loc: locationData.loc,
            p_type: "rental",
          };

          if (roleType()) {
            if (manage_by === "manager") {
              data["manager"] = twinManager;
            } else {
              data["manager"] = {};
            }
          }

          if (property_id) {
            let response = await ApiService.patchAPI(
              `host/properties/update/${property_id}`,
              data
            );

            if (response.success) {
              showSuccess(this, response.message);
              this.setState({ isUpdateLoading: false });
              handleGoBack(this.props);
            } else {
              this.setState({ isUpdateLoading: false, btn_enable: true });
              showError(this, response.message);
            }
          } else {
            let response = await ApiService.postAPI(`host/properties`, data);
            if (response.success) {
              this.setState({ isUpdateLoading: false });
              showSuccess(this, response.message);
              handleGoBack(this.props);
            } else {
              this.setState({ isUpdateLoading: false, btn_enable: true });
              showError(this, response.message);
            }
          }
        } else {
          this.setState({ step_1: false, step_2: true });
        }
      }
    }
    if ((roleType() && manage_by === "self" && step_2) || step_2) {
      if (
        parseInt(beds) <= 0 ||
        beds === "" ||
        beds === undefined ||
        beds === null
      ) {
        showError(this, "Please enter valid number of  bedrooms");
      } else if (
        parseInt(baths) <= 0 ||
        baths === "" ||
        baths === null ||
        baths === undefined
      ) {
        showError(this, "Please enter valid number of bathrooms");
      } else if (
        parseInt(guests) <= 0 ||
        guests === "" ||
        guests === undefined ||
        guests === null
      ) {
        showError(this, "Please enter valid number of guests");
      } else if (
        rental_type !== "by_room" &&
        (parseInt(price) <= 0 ||
          price === "" ||
          price === null ||
          price === undefined)
      ) {
        showError(this, "Please enter valid price");
      } else if (
        due_date === "" ||
        due_date === null ||
        due_date === undefined
      ) {
        showError(this, "Please select due date");
      } else if (
        parseInt(late_fee) < 0 ||
        late_fee === "" ||
        late_fee === null ||
        late_fee === undefined
      ) {
        showError(this, "Please enter valid late fee");
      } else if (
        parseInt(days) <= 0 ||
        days === "" ||
        days === undefined ||
        days === null
      ) {
        showError(this, `Please enter valid number of days for notice period`);
      } else if (
        parseInt(cancel_days) <= 0 ||
        cancel_days === "" ||
        cancel_days === null ||
        cancel_days === undefined
      ) {
        showError(this, `Please enter minimum cancellation days`);
      } else if (parseInt(cancel_days) >= parseInt(days)) {
        showError(
          this,
          `Minimum cancellation days should be less than number of days for notice period`
        );
      } else if (
        deposit_required &&
        (deposit_amount === "" ||
          deposit_amount === null ||
          deposit_amount === undefined)
      ) {
        showError(this, "Please enter deposit amount");
      } else if (deposit_required && deposit_amount <= 0) {
        showError(this, "Please enter valid deposit amount");
      } else if (
        deposit_required &&
        (max_installments === "" ||
          max_installments === null ||
          max_installments === undefined)
      ) {
        showError(this, "Please enter max installments");
      } else if (deposit_required && max_installments <= 0) {
        showError(this, "Please enter valid max installments");
      } else if (deposit_required && latefee_required && !late_fee_amount) {
        showError(this, "Please enter late fee amount");
      } else if (deposit_required && latefee_required && late_fee_amount <= 0) {
        showError(this, "Please enter valid late fee amount");
      } else if (checkedSharing && sharedByList.length === 0 && !roleManager() && !roleSubManager() || sharedByList.length === undefined) {
        showError(this, "Please add sharing host");
      } else {
        this.setState({ step_2: false, step_3: true });
      }
    }
    if ((roleType() && manage_by === "self" && step_3) || step_3) {
      if (selectedAmenities.length === 0) {
        showError(this, "Please select amenities");
      } else {
        this.setState({ step_3: false, step_4: true });
      }
    }
    if ((roleType() && manage_by === "self" && step_4) || step_4) {
      let checkEmpty = checkFields.every((field) => field.value !== "");
      if (about === "" || about === undefined || about === null) {
        showError(this, "Please enter about");
      } else if (imageArray.length <= 0) {
        showError(this, "Please upload image");
      } else if (inputFields[arrayIndex]?.value === "") {
        showError(this, "Please enter the rules field");
      } else if (policyFields[arrayPolicyIndex]?.value === "") {
        showError(this, "Please enter the Policy field");
      } else if (checkFields.length === 0) {
        showError(this, "Please add atleast one check list");
      } else if (checkFields.length > 0 && !checkEmpty) {
        showError(this, "Please enter check list");
      } else {
        let data = {
          about,
          name,
          bedrooms: beds,
          bathrooms: baths,
          rental_type: rental_type,
          type: commission_mode,
          commission: commission_amount,
          address: {
            line_1: geo,
            city: twincity,
            state: twinstate,
            country: country,
            zip: zip,
          },
          home_type: home_type,
          policy: policyFields,
          notice_period: {
            unit: "Daily",
            value: days,
          },
          amenities: selectedAmenities,
          gallery: imageArray,
          house_rules: inputFields,
          check_list: checkFields,
          price: parseInt(price),
          guests: guests,
          min_cancel_days: parseInt(cancel_days),
          screening: tenant_screening,
          late_fee: {
            amount: late_fee,
            due: due_date,
            is_enabled: true,
          },
          loc: locationData.loc,
          p_type: "rental",
          deposit: {
            is_enabled: deposit_required,
            late_fee: { is_enabled: latefee_required },
          },
          sharedBy: checkedSharing ? sharedByList : [],
        };
        if (deposit_required) {
          data["deposit"].amount = parseInt(deposit_amount);
          data["deposit"].installments = parseInt(max_installments);
        }
        if (deposit_required && latefee_required) {
          data["deposit"]["late_fee"].amount = late_fee_amount;
        }
        if (roleType()) {
          if (manage_by === "manager") {
            data["manager"] = twinManager;
          } else {
            data["manager"] = {};
          }
        }

        this.setState({ isUpdateLoading: true, btn_enable: false });
        if (property_id) {
          let response = await ApiService.patchAPI(
            `host/properties/update/${property_id}`,
            data
          );
          if (response.success) {
            showSuccess(this, response.message);
            this.setState({ isUpdateLoading: false, sharedByList: [] });
            handleGoBack(this.props);
          } else {
            this.setState({ isUpdateLoading: false, btn_enable: true });
            showError(this, response.message);
          }
        } else {
          let response = await ApiService.postAPI(`host/properties`, data);
          if (response.success) {
            showSuccess(this, response.message);
            this.setState({ isUpdateLoading: false, sharedByList: [] });
            handleGoBack(this.props);
          } else {
            this.setState({ isUpdateLoading: false, btn_enable: true });
            showError(this, response.message);
          }
        }
      }
    }
  };
  toggleBack = () => {
    let { step_1, step_2, step_3, step_4 } = this.state;
    if (step_1) {
      this.props.history.goBack();
    } else if (step_2) {
      this.setState({ step_1: true, step_2: false });
    } else if (step_3) {
      this.setState({ step_2: true, step_3: false });
    } else if (step_4) {
      this.setState({ step_3: true, step_4: false });
    }
  };
  onMapIconClick = () => {
    this.setState({ isMapVisible: !this.state.isMapVisible });
  };
  onSave = (formatted_address, latlng) => {
    let location = this.state.location;
    location.address = formatted_address;
    location.loc = latlng;
    this.setState({ location }, () =>
      this.setState({
        geo: formatted_address,
      })
    );
    this.onMapIconClick();
  };
  setEditAccess = (e) => {
    this.setState({ checkedEditAccess: e.target.checked })
  }
  handleSelect = (host) => {
    this.setState({
      multiHostObj: {
        id: host.host_id,
        name: host.name,
      },
    });
  };
  isDuplicate = () => {
    let { multiHostObj, sharedByList } = this.state
    return sharedByList.some(obj => obj.id === multiHostObj.id)
  }
  updateDuplicate = (id) => {
    let { sharedByList } = this.state
    return sharedByList.some(obj => obj.id === id)
  }
  assignHost = async () => {
    let {
      multiHostObj,
      sharingPercentage,
      checkedEditAccess,
      indexOfHost,
      price,
      sharingValue,
      sharedByList,
      sharedBy,
      editHost,
      rental_type
    } = this.state;
    if (Object.keys(multiHostObj).length === 0) {
      showError(this, "Please select a host");
    } else if (!price && sharingPercentage && rental_type !== "by_room") {
      showError(this, "Please enter the rent amount");
    } else if (price && !sharingPercentage || !price && !sharingPercentage || sharingPercentage === 0) {
      showError(this, "Please enter the share percentage");
    }
    //  else if ((!editHost && this.isDuplicate()) ||
    //   (editHost && sharedByList[indexOfHost].id !== multiHostObj.id && this.updateDuplicate(sharedByList[indexOfHost].id))) {
    //   showError(this, "Dupliate host name");
    // } 
    else {
      if (indexOfHost >= 0 && editHost) {
        this.setState(prevState => {
          const updatedList = [...prevState.sharedByList]; // Create a copy of the array
          if (indexOfHost < updatedList.length) {
            updatedList[indexOfHost] = {
              id: multiHostObj.id,
              name: multiHostObj.name,
              edit_access: checkedEditAccess,
              commission: {
                percentage: +sharingPercentage,
                value: +sharingValue
              }
            };
            this.setState({ sharedByList: updatedList }, () => this.closeModal())
          }  // Update the state with the modified array
        });
      } else {
        this.setState(prevState => ({
          sharedByList: [...prevState.sharedByList,
          {
            id: multiHostObj.id,
            name: multiHostObj.name,
            edit_access: checkedEditAccess,
            commission: {
              percentage: +sharingPercentage,
              value: +sharingValue
            }
          }
          ]
        }), () => this.closeModal())
      }
    }
  }

  setSharePercentage = (e) => {
    let { price, sharedByList, indexOfHost } = this.state
    const allocatedPercent = sharedByList.reduce((acc, obj) => acc + obj.commission.percentage, 0)
    let remainingPercent = 99 - allocatedPercent
    let editcommision = remainingPercent + (sharedByList[indexOfHost]?.commission?.percentage)
    const value = e.target.value;

    if (indexOfHost === 0 || indexOfHost) {
      if (value > editcommision) showError(this, `Remaining only ${editcommision}% is available`)
      else if (value <= 99) {
        let shareValue = price * value / 100
        this.setState({ sharingPercentage: value, sharingValue: shareValue });
      }
    } else if (indexOfHost === null) {
      if (value > remainingPercent) showError(this, `Remaining only ${remainingPercent}% is available`)
      else if (value <= 99) {
        let shareValue = price * value / 100
        this.setState({ sharingPercentage: value, sharingValue: shareValue });
      }
    }
  };
  deleteSharingHost = (index) => {
    if (index || index === 0) {
      let { sharedByList } = this.state
      let tempTags = sharedByList;
      tempTags.splice(index, 1);
      this.setState([...sharedByList])
    }
    const allocatedPercent = this.state.sharedByList.reduce((acc, obj) => acc + obj.commission.percentage, 0);
    this.setState({ availableShares: allocatedPercent === 99 ? false : true })
  };

  editHost = (value) => {
    this.setState({
      editHost: true,
      sharingModal: true,
      sharingPercentage: value.commission.percentage,
      sharingValue: value.commission.value,
      checkedEditAccess: value.edit_access,
      multiHostObj: {
        id: value.id,
        name: value.name,
      }
    })
  }

  render() {
    let {
      optionArray,
      amenityList,
      stateList,
      cityList,
      name,
      beds,
      baths,
      guests,
      rental_type,
      home_type,
      days,
      due_date,
      price,
      late_fee,
      zip,
      about,
      geo,
      step_1,
      step_2,
      step_3,
      step_4,
      isMapVisible,
      location,
      manage_by,
      managerList,
      manager,
      city,
      state,
      cancel_days,
      toast,
      response,
      toastType,
      isLoading,
      imageLoading,
      isUpdateLoading,
      commission_mode,
      commission_amount,
      checkSelectAll,
      max_installments,
      tenant_screening,
      deposit_required,
      deposit_amount,
      btn_enable,
      latefee_required,
      late_fee_amount,
      checkedSharing,
      access,
      sharingModal,
      multiHostObj,
      sharingPercentage,
      checkedEditAccess,
      sharedByList,
      editHost,
      sharingValue,
      availableShares,
      host_id,
      host_detail
    } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#012A4D" />
      </div>
    ) : (
      <>
        <Container className="mt-8 card-pad-wrapper" fluid>
          {toast && <ToastList message={response} type={toastType} />}
          <div>
            <Breadcrumb></Breadcrumb>
          </div>
          <Row className="align-items-center header-div row-center">
            <Col xs="8" className="manage-con">
              <div>
                <Breadcrumb>
                  <BreadcrumbItem
                    onClick={() => this.props.history.goBack()}
                    className="bread-cursor"
                  >
                    Properties
                  </BreadcrumbItem>
                  <BreadcrumbItem active style={{ color: "#012a4d" }}>
                    {property_id ? name : "Add"}
                  </BreadcrumbItem>
                </Breadcrumb>
              </div>
            </Col>
            {!step_1 && (
              <div className="add-filter-wrap ">
                <div
                  className="filter-wrap mr-4 edit-prop"
                  onClick={() => this.toggleBack()}
                >
                  <label>Previous</label>
                </div>
              </div>
            )}
            <div
              className={
                step_4 && property_id
                  ? "text-right btn-exceed"
                  : "text-right col-edit"
              }
              xs="4"
            >
              <Button
                color="primary"
                onClick={btn_enable ? () => this.handleAddEdit() : ""}
                size="sm"
                className={
                  isUpdateLoading &&
                    ((roleType() && manage_by === "manager") || step_4) &&
                    property_id
                    ? "button-wrapping up-prop-btn"
                    : "button-wrapping "
                }
              >
                {isUpdateLoading &&
                  ((roleType() && manage_by === "manager") || step_4) &&
                  property_id ? (
                  <div className="button_spinner ">
                    <Spinner type="border" color="#012A4D" />
                  </div>
                ) : ((roleType() && manage_by === "manager") || step_4) &&
                  !property_id ? (
                  "Create"
                ) : ((roleType() && manage_by === "manager") || step_4) &&
                  property_id ? (
                  "Update"
                ) : (
                  "Next"
                )}
              </Button>
            </div>
          </Row>
          {((roleType() && manage_by !== "manager") || !roleType()) && (
            <div className="progress-all">
              <div className="progress-div">
                <div
                  className={
                    step_1
                      ? "progress-back-toggle"
                      : step_2
                        ? "progress-back-toggle w_3"
                        : step_3
                          ? "progress-back-toggle w_6"
                          : step_4
                            ? "progress-back-toggle w_9"
                            : "progress-back-toggle"
                  }
                >
                  {step_1 ? (
                    <div className="img-black image_one">
                      <img src={Home} className="img-abs" alt="" />
                    </div>
                  ) : (!step_1 && step_2) ||
                    (!step_1 && !step_2 && step_3) ||
                    (!step_1 && !step_2 && !step_3 && step_4) ? (
                    <div className="steps-1 back">
                      <img src={propTick} className="img-abs-prop" alt="" />
                    </div>
                  ) : (
                    <div className="steps-1"></div>
                  )}
                  {step_2 ? (
                    <div className="img-black image_two">
                      <img src={Home} className="img-abs" alt="" />
                    </div>
                  ) : (!step_1 && !step_2 && step_3) ||
                    (!step_1 && !step_2 && !step_3 && step_4) ? (
                    <div className="steps-2 back">
                      <img src={propTick} className="img-abs-prop" alt="" />
                    </div>
                  ) : (
                    <div className="steps-2"></div>
                  )}
                  {step_3 ? (
                    <div className="img-black image_three">
                      <img src={Home} className="img-abs" alt="" />
                    </div>
                  ) : !step_1 && !step_2 && !step_3 && step_4 ? (
                    <div className="steps-3 back">
                      <img src={propTick} className="img-abs-prop" alt="" />
                    </div>
                  ) : (
                    <div className="steps-3"></div>
                  )}
                  {step_4 ? (
                    <div className="img-black image_four">
                      <img src={Home} className="img-abs" alt="" />
                    </div>
                  ) : (
                    <div className="steps-4"></div>
                  )}
                </div>
              </div>
            </div>
          )}
          <Row className="mb-50">
            <div className="col new-table">
              {step_1 && (
                <Card className="shadow">
                  <ListingHeaderLayout label={"Basic Information"} />
                  <CardBody>
                    <Form>
                      <div>
                        <Row>
                          <Col lg="4" className="">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Name*
                              </label>

                              <Input
                                className="form-control-alternative"
                                value={name}
                                name="name"
                                placeholder="Name"
                                type="text"
                                onChange={this.handleInputChange}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup className="form-relative geoLocation">
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Geo Location*
                              </label>
                              <Input
                                className="form-control-alternative"
                                value={geo}
                                name="geo"
                                id="input-first-name"
                                onChange={this.displayPlaces}
                                placeholder="Geo Location"
                                type="text"
                                style={{ width: "94%" }}
                              // readOnly
                              />
                              <img
                                src={Geo}
                                className="loc_abs point"
                                alt=""
                              // onClick={() => this.onMapIconClick()}
                              />
                              {this.state.isShowPlaces && this.state.geo && (
                                <ul>
                                  {this.state.places.map((place) => (
                                    <li
                                      onClick={() => this.getPlaceData(place)}
                                    >
                                      {place.description}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </FormGroup>
                          </Col>

                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                State*
                              </label>
                              <DropdownCommon
                                innerStyle={{ marginTop: "13px" }}
                                status={state}
                                setStatus={this.handleStateInputChange}
                                statusList={stateList}
                                stateAndcity={true}
                              ></DropdownCommon>
                              {/* <select
                                className="form-control-alternative form-control point"
                                id="input-first-name"
                                name="state"
                                value={state}
                                onChange={this.handleStateInputChange}
                              >
                                <option value="">Select</option>
                                {stateList.map((state, i) => (
                                  <option value={state.id} key={i}>
                                    {state.name}
                                  </option>
                                ))}
                              </select> */}
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                City*
                              </label>
                              <DropdownCommon
                                innerStyle={{ marginTop: "13px" }}
                                status={city}
                                setStatus={this.handleCityInputChange}
                                statusList={cityList}
                                stateAndcity={true}
                              ></DropdownCommon>
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                ZIP*
                              </label>
                              <Input
                                className="form-control-alternative"
                                value={zip}
                                name="zip"
                                id="input-first-name"
                                onChange={this.handleInputChange}
                                placeholder="ZIP"
                                type="number"
                                maxLength={5}
                                onInput={maxLengthCheck}
                              />
                            </FormGroup>
                          </Col>

                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Home Type*
                              </label>
                              <DropdownCommon
                                innerStyle={{ marginTop: "13px" }}
                                status={home_type}
                                setStatus={this.handleHomeType}
                                statusList={[
                                  { label: "Select", value: "" },
                                  { label: "Town house", value: "Town house" },
                                  { label: "Apartment", value: "Apartment" },
                                  { label: "Condo", value: "Condo" },
                                  { label: "Single Home", value: "Single Family Home" }]}
                                placeHolder={"Select"}
                              />
                            </FormGroup>
                          </Col>
                          <>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Manage By*
                                </label>
                                <DropdownCommon
                                  innerStyle={{ marginTop: "13px" }}
                                  status={manage_by}
                                  setStatus={this.handleManageInputChange}
                                  statusList={[
                                    { label: "Select", value: "" },
                                    { label: "Myself", value: "self" },
                                    { label: "Property Manager", value: "manager" },
                                  ]}
                                  placeHolder={"Select"}
                                  disabled={roleType() ? "" : true}
                                />
                                {/* <select
                                  className="form-control-alternative form-control point"
                                  id="input-first-name"
                                  name="manage_by"
                                  value={manage_by}
                                  onChange={this.handleManageInputChange}
                                  disabled={roleType() ? "" : "disabled"}
                                >
                                  {/* <option>Select</option> */}
                                {/* <option value="">Select</option>
                                <option value="self">Myself</option>
                                <option value="manager">
                                  Property Manager
                                </option>
                              </select>  */}
                              </FormGroup>
                            </Col>
                            {manage_by === "manager" && (
                              <Col lg="4">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-first-name"
                                  >
                                    Property Manager*
                                  </label>
                                  <DropdownCommon
                                    innerStyle={{ marginTop: "13px" }}
                                    status={manager}
                                    setStatus={this.handleManagersChange}
                                    statusList={managerList}
                                    stateAndcity={true}
                                    disabled={roleType() ? "" : true}
                                  ></DropdownCommon>
                                  {/* <select
                                    className="form-control-alternative form-control"
                                    id="input-first-name"
                                    name="manager"
                                    value={manager}
                                    onChange={this.handleManagersChange}
                                    disabled={roleType() ? "" : "disabled"}
                                  >
                                    <option value="">Select</option>
                                    {managerList.map((tmpManager, i) => (
                                      <option value={tmpManager.id} key={i}>
                                        {tmpManager.name}
                                      </option>
                                    ))}
                                  </select> */}
                                </FormGroup>
                              </Col>
                            )}

                            {manage_by === "manager" && manager && (
                              <Col lg="4">
                                <FormGroup>
                                  <label className="form-control-label">
                                    Commission Mode*
                                  </label>
                                  <select
                                    className="form-control-alternative form-control"
                                    value={commission_mode}
                                    onChange={this.commissionModeChange}
                                    disabled={roleType() ? "" : "disabled"}
                                  >
                                    <option value="">select</option>
                                    <option value="commission">
                                      Percentage
                                    </option>
                                    <option value="fixed_amount">Fixed</option>
                                  </select>
                                </FormGroup>
                              </Col>
                            )}
                            {manage_by === "manager" &&
                              manager &&
                              commission_mode && (
                                <Col lg="4">
                                  <FormGroup>
                                    <label className="form-control-label">
                                      Enter{" "}
                                      {commission_mode === "commission"
                                        ? "Percentage"
                                        : "Amount"}
                                      *
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      name="commission_amount"
                                      type="number"
                                      value={commission_amount}
                                      onChange={this.commissionAmountChange}
                                      min="1"
                                      max="99"
                                      disabled={roleType() ? "" : "disabled"}
                                      maxLength={10}
                                      onInput={maxLengthCheck}
                                    />
                                  </FormGroup>
                                </Col>
                              )}
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Rental Type*
                                </label>
                                {roleType() && manage_by === "manager" ? (
                                  <Input
                                    className="form-control-alternative"
                                    name="Entire Home"
                                    value="Entire Home"
                                    min="1"
                                    max="99"
                                    disabled
                                  />
                                ) : (
                                  <DropdownCommon
                                    innerStyle={{ marginTop: "13px" }}
                                    status={rental_type}
                                    setStatus={this.handleRentalType}
                                    statusList={[
                                      { label: "Select", value: "" },
                                      { label: "Entire Home", value: "entire_home" },
                                      { label: "By Room", value: "by_room" }]}
                                  />
                                  // <select
                                  //   className="form-control-alternative form-control point"
                                  //   id="input-first-name"
                                  //   value={rental_type}
                                  //   name="rental_type"
                                  //   onChange={this.handleInputChange}
                                  // >
                                  //   <option value="">Select</option>
                                  //   <option value="entire_home">
                                  //     Entire Home
                                  //   </option>
                                  //   <option value="by_room">By Room</option>
                                  // </select>
                                )}
                              </FormGroup>
                            </Col>
                          </>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              )}
              {step_2 && (
                <>
                  <Card className="shadow mt-0 mb-30">
                    <ListingHeaderLayout label={"Rent Details"} classStyle="minh_58" />
                    <CardBody>
                      <Form>
                        <div>
                          <Row>
                            <Col lg="4" className="mb-3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  No of Bedrooms*
                                </label>

                                <Input
                                  className="form-control-alternative"
                                  value={beds}
                                  name="beds"
                                  id="input-email"
                                  placeholder="No of Bedrooms"
                                  type="number"
                                  onChange={this.handleInputChange}
                                  maxLength={5}
                                  onInput={maxLengthCheck}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  No of Bathrooms*
                                </label>

                                <Input
                                  className="form-control-alternative"
                                  value={baths}
                                  name="baths"
                                  id="input-email"
                                  placeholder=" No of Bathrooms"
                                  type="number"
                                  maxLength={5}
                                  onChange={this.handleInputChange}
                                  onInput={maxLengthCheck}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  No of Guests*
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  value={guests}
                                  name="guests"
                                  id="input-first-name"
                                  onChange={this.handleInputChange}
                                  placeholder="No of Guest"
                                  type="number"
                                  maxLength={5}
                                  onInput={maxLengthCheck}
                                />
                              </FormGroup>
                            </Col>
                            {rental_type !== "by_room" && (
                              <Col lg="4" className="mb-3">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-first-name"
                                  >
                                    Price(per month)*
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    value={price}
                                    name="price"
                                    id="input-first-name"
                                    onChange={this.handleInputChange}
                                    placeholder=" Price(per month)"
                                    type="number"
                                    maxLength={10}
                                    onInput={maxLengthCheck}
                                  />
                                </FormGroup>
                              </Col>
                            )}

                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Due Date*
                                </label>
                                <DropdownCommon
                                  innerStyle={{ marginTop: "13px" }}
                                  status={due_date}
                                  setStatus={this.handleDueDate}
                                  statusList={optionArray}
                                ></DropdownCommon>
                                {/* <select
                                  className="form-control-alternative form-control point"
                                  id="input-first-name"
                                  value={due_date}
                                  name="due_date"
                                  onChange={this.handleInputChange}
                                >
                                  <option value="">Select</option>
                                  {optionArray.map((option, i) => (
                                    <option value={option} key={i}>
                                    </option>
                                  ))}
                                </select> */}
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Late Fee(per day)*
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  value={late_fee}
                                  name="late_fee"
                                  id="input-first-name"
                                  onChange={this.handleInputChange}
                                  placeholder="Late Fee(per day)"
                                  type="number"
                                  maxLength={10}
                                  onInput={maxLengthCheck}
                                />
                              </FormGroup>
                            </Col>

                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Notice Period(In Days)*
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  value={days}
                                  name="days"
                                  id="input-first-name"
                                  onChange={this.handleInputChange}
                                  placeholder="No of Days"
                                  type="number"
                                  maxLength={5}
                                  onInput={maxLengthCheck}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Min. Cancellation(In Days)*
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  value={cancel_days}
                                  name="cancel_days"
                                  id="input-first-name"
                                  onChange={this.handleInputChange}
                                  placeholder="Min. Cancellation(In Days)"
                                  type="number"
                                  maxLength={5}
                                  onInput={maxLengthCheck}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <div style={{ display: "flex" }}>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-first-name"
                                  >
                                    Tenant Screening*
                                  </label>
                                  <div
                                    data-tip="Parknstays will do a background verification on behalf of you for whoever books this property"
                                    data-iscapture="true"
                                    data-for="toolTip1"
                                    data-place="top"
                                    className="request_tooltip"
                                  >
                                    <i className="fa fa-info-circle tertiary-circle"></i>
                                  </div>
                                  <ReactTooltip
                                    place="right"
                                    type="info"
                                    effect="solid"
                                    id="toolTip1"
                                    html={true}
                                  />
                                </div>
                                <DropdownCommon
                                  innerStyle={{ marginTop: "13px" }}
                                  status={tenant_screening}
                                  setStatus={(e) => this.onScreening(e)}
                                  statusList={[{ label: "Required", value: true }, { label: "Not Required", value: false }]}
                                ></DropdownCommon>
                                {/* <select
                                  className="form-control-alternative form-control point"
                                  id="input-first-name"
                                  value={tenant_screening}
                                  name="tenant_screening"
                                  onChange={
                                    (e) => this.onScreening(e.target.value)
                                  }
                                >
                                  <option value="true">Required</option>
                                  <option value="false">Not Required</option>
                                </select> */}
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                  <Card className="shadow mt-0 mb-30">
                    <ListingHeaderLayout label={"Security Deposit"} classStyle="minh_58" />
                    <CardBody>
                      <Form>
                        <div>
                          <Row>
                            <Col lg="4" className="mb-3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Is Deposit Required *
                                </label>
                                <DropdownCommon
                                  innerStyle={{ marginTop: "13px" }}
                                  status={deposit_required}
                                  setStatus={this.handleDepositRequired}
                                  statusList={[{ label: "Yes", value: true }, { label: "No", value: false }]}
                                ></DropdownCommon>
                                {/* <select
                                  className="form-control-alternative form-control point"
                                  id="input-first-name"
                                  value={deposit_required}
                                  name="deposit_required"
                                  onChange={this.handleDepositRequired}
                                >
                                  <option value="true">Yes</option>
                                  <option value="false">No</option>
                                </select> */}
                              </FormGroup>
                            </Col>
                            {deposit_required && (
                              <>
                                <Col lg="4">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-email"
                                    >
                                      Deposit Amount*
                                    </label>

                                    <Input
                                      className="form-control-alternative"
                                      value={deposit_amount}
                                      name="deposit_amount"
                                      placeholder="Deposit Amount"
                                      type="number"
                                      onChange={this.handleInputChange}
                                      maxLength={10}
                                      onInput={maxLengthCheck}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="4">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-first-name"
                                    >
                                      Max Installments*
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      value={max_installments}
                                      name="max_installments"
                                      onChange={this.handleInputChange}
                                      placeholder="Max Installments"
                                      type="number"
                                      maxLength={2}
                                      onInput={maxLengthCheck}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="4" className="mb-3">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-email"
                                    >
                                      Late Fee Required*
                                    </label>
                                    <DropdownCommon
                                      innerStyle={{ marginTop: "13px" }}
                                      status={latefee_required}
                                      setStatus={this.handleLateFeeRequired}
                                      statusList={[{ label: "Yes", value: true }, { label: "No", value: false }]}
                                    ></DropdownCommon>
                                    {/* <select
                                      className="form-control-alternative form-control point"
                                      id="input-first-name"
                                      value={latefee_required}
                                      name="latefee_required"
                                      onChange={this.handleLateFeeRequired}
                                    >
                                      <option value="true">Yes</option>
                                      <option value="false">No</option>
                                    </select> */}
                                  </FormGroup>
                                </Col>
                                {latefee_required && (
                                  <Col lg="4">
                                    <FormGroup>
                                      <label
                                        className="form-control-label"
                                        htmlFor="input-first-name"
                                      >
                                        Late Fee Amount*
                                      </label>
                                      <Input
                                        className="form-control-alternative"
                                        value={late_fee_amount}
                                        name="late_fee_amount"
                                        onChange={this.handleInputChange}
                                        placeholder="Late Fee Amount"
                                        type="number"
                                        maxLength={10}
                                        onInput={maxLengthCheck}
                                      />
                                    </FormGroup>
                                  </Col>
                                )}
                              </>
                            )}
                          </Row>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                  {!roleManager() && !roleSubManager() && ((host_id === host_detail?.host_id) || !property_id) &&
                    <Card className="shadow mt-0">
                      <ListingHeaderLayout label={"Property Sharing"} classStyle="minh_58">
                        {checkedSharing && availableShares &&
                          <div className="text-right" xs="6">
                            <Button
                              color="primary"
                              onClick={() => this.setState({ sharingModal: true })}
                              size="sm"
                              className="button_without_border"
                            >
                              Add
                            </Button>
                          </div>
                        }
                      </ListingHeaderLayout>
                      <CardBody>
                        {/* <Form> */}
                        <Col lg="4" className="mb-3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Share property with host*
                            </label>
                            <DropdownCommon
                              innerStyle={{ marginTop: "13px" }}
                              status={checkedSharing}
                              setStatus={this.handleHostShare}
                              statusList={[{ label: "Yes", value: true }, { label: "No", value: false }]}
                            ></DropdownCommon>
                          </FormGroup>
                        </Col>

                        {checkedSharing && sharedByList.length !== 0 ? (
                          <>
                            <div className="flexWrap pad15">
                              {sharedByList.map((file, i) => (

                                <div className="documentCard">
                                  <div className="flex mb-12" key={i}>
                                    <span style={{ fontWeight: "600" }}>{file?.name}</span>
                                    <span className="justCenter">
                                      <UncontrolledDropdown
                                        nav
                                        className="position_u"
                                      >
                                        <DropdownToggle
                                          className="pr-0 nav-notice"
                                          nav
                                        >
                                          <Media className="align-items-center">
                                            <img src={showMore} />
                                          </Media>
                                        </DropdownToggle>

                                        <DropdownMenu
                                          className="dropdown-menu-arrow min-width-drop"
                                          right
                                        >
                                          <DropdownItem
                                            onClick={() => (
                                              this.setState({ indexOfHost: i }, () => this.editHost(file)))}
                                          >
                                            <span>Edit</span>
                                          </DropdownItem>
                                          <DropdownItem
                                            onClick={() =>
                                              this.deleteSharingHost(i)
                                            }
                                          >
                                            <span>Delete</span>
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </span>
                                  </div>
                                  <div className="flex">
                                    <span className="f13">Value : {`${file.commission.percentage}% ($${formatAmountWithCommas(file.commission.value)})`}</span>
                                    <span className="capitalize" style={{ marginLeft: "24px", fontSize: "13px" }}>
                                      Edit access : {file.edit_access === true ? "Yes" : "No"}
                                      {/* {JSON.stringify(file.edit_access)} */}
                                      {/* {showLocaldate(new Date())} */}
                                    </span>
                                  </div>
                                </div>
                              )
                              )}
                            </div>
                          </>
                        ) : (checkedSharing && <div className="no-data font-book"> No Host Available </div>)}
                      </CardBody>
                    </Card>
                  }
                </>
              )}
              {step_3 && (
                <Card className="shadow mt-4 mb-5">
                  <ListingHeaderLayout label={"Amenities*"} classStyle="minh_58" >
                    {/* <CardHeader className="border-0"> */}
                    <div className="align-items-center">
                      {/* <Col xs="6">
                          <h3 className="mb-0">Amenities*</h3>
                        </Col> */}
                      <div xs="6">
                        <div className="flexEnd">
                          <div>
                            <Input
                              className="selectAllAmenity"
                              id="selectAll"
                              checked={checkSelectAll}
                              onChange={this.handleSelectAllAmenities}
                              placeholder="Rules"
                              type="checkbox"
                            />
                          </div>
                          <div className="" style={{ marginLeft: "5px" }}>
                            <label
                              htmlFor="selectAll"
                              style={{ margin: "0px" }}
                              className="point"
                            >
                              Select All
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* </CardHeader> */}
                  </ListingHeaderLayout>
                  <CardBody>
                    <Form>
                      <div>
                        <div className="pl-lg-4">
                          <Row className="align-items-center">
                            {amenityList.map((amenity, i) => (
                              <Col lg="4" key={i}>
                                <FormGroup>
                                  <Input
                                    className="form-control-alternative mt-ameni"
                                    id={amenity.amenity_id}
                                    onChange={(e) =>
                                      this.handleCheckClick(e, amenity)
                                    }
                                    checked={this.handleCheck(
                                      amenity.amenity_id
                                    )}
                                    placeholder="Rules"
                                    type="checkbox"
                                  />
                                  <label
                                    className="form-control-label-ameni point"
                                    htmlFor={amenity.amenity_id}
                                  >
                                    {amenity?.icon ? (
                                      <img
                                        src={amenity?.icon}
                                        alt=""
                                        className="default-amenity"
                                      />
                                    ) : (
                                      <img
                                        src={DefaultAmenity}
                                        alt=""
                                        className="default-amenity"
                                      />
                                    )}
                                  </label>
                                  <label
                                    className="form-control-label point"
                                    style={{ marginLeft: "0.5rem" }}
                                    htmlFor={amenity.amenity_id}
                                  >
                                    {amenity?.name}
                                  </label>
                                </FormGroup>
                              </Col>
                            ))}
                          </Row>
                        </div>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              )}
              {step_4 && (
                <>
                  <Card className="shadow mt-4">
                    <ListingHeaderLayout label={"About*"} classStyle="minh_58" />
                    <CardBody className="">
                      <Form>
                        <div>
                          <Row>
                            <Col lg="12">
                              <FormGroup className="mb_0">
                                <textarea
                                  className="form-control-alternative  form-control tex-area-prop"
                                  value={about}
                                  name="about"
                                  id="input-first-name"
                                  onChange={this.handleInputChange}
                                  placeholder="Tell us something about this Property....."
                                  type="text"
                                  maxLength="3000"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                  <Card className="shadow mt-4">
                    <div className="align-items-center">
                      <ListingHeaderLayout label={"Gallery*"} classStyle="minh_58">
                        <div className="text-right" xs="6">
                          <Button
                            color="primary"
                            size="sm"
                            className="image-hide button_without_border"
                          >
                            <Input
                              className="form-control-alternative form-control opac-hide "
                              type="file"
                              id="imageUpload"
                              accept="image/*"
                              onChange={(event) =>
                                this.onImageChangeHandler(event)
                              }
                              value={this.state.img_value}
                              onClick={(e) => this.onImageClick(e)}
                              placeholder="Enter Name"
                            />
                            Add
                          </Button>
                        </div>
                      </ListingHeaderLayout>
                    </div>
                    <CardBody className="gallery-change-pad pb-5">
                      <Form>
                        <div>
                          <Row>
                            {this.state.imageArray.map((field, idx) => {
                              return (
                                <div key={idx} className="mt_20">
                                  <>
                                    <div className="image_upload_display mr_15 image_array_holder">
                                      <LightgalleryItem
                                        className="image_upload_display"
                                        src={getCloudinaryImage(
                                          330,
                                          330,
                                          field,
                                          "c_fill"
                                        )}
                                      >
                                        <img
                                          src={getCloudinaryImage(
                                            330,
                                            330,
                                            field,
                                            "c_fill"
                                          )}
                                          className="arrayImg cursor-point"
                                          alt=""
                                        />
                                      </LightgalleryItem>
                                      <img
                                        src={Remove}
                                        className="ni ni-fat-remove remove_image_icon"
                                        onClick={() => this.deleteImage(idx)}
                                        alt=""
                                      />
                                    </div>
                                  </>
                                </div>
                              );
                            })}
                            <Col lg="2">
                              {imageLoading && (
                                <div className="image-loading spinner-cut">
                                  <Spinner type="border" color="#012A4D" />
                                </div>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>

                  <Card className="shadow mt-4">
                    <div className="align-items-center">
                      <ListingHeaderLayout label={"House Rules*"} classStyle="minh_58">
                        <div className="text-right" xs="6">
                          <Button
                            color="primary"
                            onClick={() => this.addInput()}
                            size="sm"
                            className="button_without_border"
                          >
                            Add
                          </Button>
                        </div>
                      </ListingHeaderLayout>
                    </div>
                    <CardBody>
                      <Form>
                        <div>
                          {this.state.inputFields.map((field, idx) => {
                            return (
                              <Row className="align-items-center " key={idx}>
                                <Col xs="8" lg="10">
                                  <FormGroup>
                                    <Input
                                      className="form-control-alternative"
                                      id="input-first-name"
                                      onChange={(e) =>
                                        this.handleOnChange(idx, e)
                                      }
                                      placeholder="Rules"
                                      type="text"
                                      value={field.value}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col
                                  className="text-right max-width-rules"
                                  xs="4"
                                >
                                  <img
                                    src={Delete}
                                    onClick={() => this.handleRemove(idx)}
                                    className="delete-icon"
                                    alt="Delete Icon"
                                  />
                                </Col>
                              </Row>
                            );
                          })}
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                  <Card className="shadow mt-4 mb_20">
                    <div className="align-items-center">
                      <ListingHeaderLayout label={"Policy*"} classStyle="minh_58">
                        <div className="text-right" xs="6">
                          <Button
                            color="primary"
                            onClick={() => this.addPolicyInput()}
                            size="sm"
                            className="button_without_border"
                          >
                            Add
                          </Button>
                        </div>
                      </ListingHeaderLayout>
                    </div>
                    <CardBody>
                      <Form>
                        <div>
                          {this.state.policyFields.map((field, idx) => {
                            return (
                              <Row className="align-items-center " key={idx}>
                                <Col xs="8" lg="10">
                                  <FormGroup>
                                    <Input
                                      className="form-control-alternative"
                                      id="input-first-name"
                                      onChange={(e) =>
                                        this.handlePolicyOnChange(idx, e)
                                      }
                                      placeholder="Policy"
                                      type="text"
                                      value={field.value}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col
                                  className="text-right max-width-rules"
                                  xs="4"
                                >
                                  <img
                                    src={Delete}
                                    onClick={() => this.handlePolicyRemove(idx)}
                                    className="delete-icon"
                                    alt="Delete Icon"
                                  />
                                </Col>
                              </Row>
                            );
                          })}
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                  <Card className="shadow mt-4">
                    <div className="align-items-center">
                      <ListingHeaderLayout label={"Checkout Checklist*"} classStyle="minh_58">
                        <div className="text-right" xs="6">
                          <Button
                            color="primary"
                            onClick={() => this.addCheckListInput()}
                            size="sm"
                            className="button_without_border"
                          >
                            Add
                          </Button>
                        </div>
                      </ListingHeaderLayout>
                    </div>
                    <CardBody>
                      <Form>
                        <div>
                          {this.state?.checkFields?.map((field, idx) => {
                            return (
                              <Row className="align-items-center " key={idx}>
                                <Col xs="8" lg="10">
                                  <FormGroup>
                                    <Input
                                      className="form-control-alternative"
                                      id="input-first-name"
                                      onChange={(e) =>
                                        this.handleCheckOnChange(idx, e)
                                      }
                                      placeholder="Check list"
                                      type="text"
                                      value={field.value}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col
                                  className="text-right max-width-rules"
                                  xs="4"
                                >
                                  <img
                                    src={Delete}
                                    onClick={() => this.handleCheckRemove(idx)}
                                    className="delete-icon"
                                    alt="Delete Icon"
                                  />
                                </Col>
                              </Row>
                            );
                          })}
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </>
              )}

              <Modal
                className="modal-dialog-centered map-marker"
                isOpen={isMapVisible}
                toggle={() => this.toggleModal()}
                onKeyDown={(event) => this.onEscPress(event)}
              >
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Select the property location on the map
                  </h5>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.onMapIconClick()}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  {" "}
                  <MapContainer
                    onMapIconClick={this.onMapIconClick}
                    onSave={this.onSave}
                    courtAddress={location}
                  />
                </div>
              </Modal>
            </div>
          </Row>
          {sharingModal &&
            <Card className={sharingModal ? "exportSideBar" : "toggle-sidebar"}>
              <CardHeader>
                <div className="flex">
                  <div className="fw-500 f16 cl000000">{editHost ? "Edit Host" : "Add Host"}</div>
                  <img
                    src={closeIcon}
                    onClick={() => this.closeModal()}
                    className="point"
                    alt=""
                  />
                </div>
              </CardHeader>
              <CardBody className="pad30p">
                <>
                  <div className="cl2A1E17 mb-10 f14 fw-400">
                    Host*
                  </div>
                  <Autocomplete
                    onSelect={this.handleSelect}
                    type="multiple_host"
                    value={multiHostObj}
                  />
                  {/* <div>
                    <label
                      className="form-control-label"
                      htmlFor="input-first-name"
                    >
                      Sharing mode*
                    </label>
                    <div className="invoiceStatusView mb-20" style={{ borderBottom: "1px solid #e1e1e1" }}>
                      <UncontrolledDropdown className="status_select_div border-none" >
                        <DropdownToggle className="pr-0 drop_down_container" style={{ paddingLeft: "0px" }}>
                          <Media className="capitalize" style={{ color: "black", fontSize: "14px" }}>
                            {shareMode === "fixed" ? "Fixed"
                              : shareMode === "percentage" ? "Percentage"
                                : "Select"}
                            <i className="fas fa-angle-down"></i>
                          </Media>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-arrow w100" bottom>
                          <DropdownItem
                            className="dropdown_item"
                            onClick={() => this.setShareMode("fixed")}
                          >
                            Fixed
                          </DropdownItem>
                          <DropdownItem
                            className="dropdown_item"
                            onClick={() => this.setShareMode("percentage")}
                          >
                            Percentage
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div> */}
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-first-name"
                    >
                      Share Percentage *
                    </label>
                    <Input
                      className="form-control-alternative mb-20"
                      value={sharingPercentage}
                      type="number"
                      name="shareAmount"
                      onChange={this.setSharePercentage}
                    />
                  </FormGroup>
                  {price &&
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                      >

                        Share Value *
                      </label>
                      <Input
                        className="form-control-alternative mb-20"
                        value={sharingValue}
                        type="number"
                        name="shareValue"
                        disabled
                        style={{ cursor: "not-allowed", backgroundColor: "transparent" }}
                      />
                    </FormGroup>}
                  <Form>
                    <FormGroup>
                      <div style={{ display: "flex", alignItems: "center", margin: "22px 0px 20px 0px" }}>
                        <Input style={{ height: "18px", width: "18px" }} checked={checkedEditAccess}
                          onChange={(e) => { this.setEditAccess(e) }}
                          type="checkbox" id="editAccess" name="editAccess" value="editAccess" className="_m position_u mr-10 ml-0" />
                        <label className="ml_8 mb_0 point" for="editAccess" style={{ fontSize: "14px", color: "#212121", marginLeft: "10px" }}>Edit Access</label>
                      </div>
                    </FormGroup>
                  </Form>

                  <div className="flexEnd">
                    <div
                      className="mr-20 point f14 button-secondary"
                      onClick={() => this.closeModal()}
                    >
                      Cancel
                    </div>
                    <Button
                      color="primary"
                      className="button-wrapping "
                      size="sm"
                      onClick={() => this.assignHost()}
                    >
                      {/* {btnLoading ? (
                            <div className="spin-loading-save w100 h100">
                              <Spinner type="border" color="#012A4D" />
                            </div>
                          ) : ( */}
                      <div>
                        {/* {this.props.eventDetails ? "Edit" : "Create"} */}
                        Save
                      </div>
                      {/* )} */}
                    </Button>
                  </div>
                </>
                {/* )} */}
              </CardBody>
            </Card>
          }
        </Container >
      </>
    );
  }
}
export default AddEditProperty;
