let RolesData = [
  {
    name: "My Properties",
    isActive: false,
    subrole_active: false,
    permissions: {
      view: false,
      create: false,
      edit: false,
      delete: false,
      import: false,
      export: false,
    },

    subRoles: [
      {
        name: "property",
        view_name: "properties",
        permissions: {
          view: false,
          create: false,
          edit: false,
          delete: false,
          import: false,
          export: false,
        },
      },
      {
        name: "booking",
        view_name: "bookings",
        permissions: {
          view: false,
          create: false,
          edit: false,
          delete: false,
          import: false,
          export: false,
        },
      },
      {
        name: "notice",
        view_name: "notices",
        permissions: {
          view: false,
          create: false,
          edit: false,
          delete: false,
          import: false,
          export: false,
        },
      },
      {
        name: "tenant",
        view_name: "tenants",
        permissions: {
          view: false,
          create: false,
          edit: false,
          delete: false,
          import: false,
          export: false,
        },
      },
    ],
  },

  {
    name: "My Finances",
    isActive: false,
    subrole_active: false,
    permissions: {
      view: false,
      create: false,
      edit: false,
      delete: false,
      import: false,
      export: false,
    },
    subRoles: [
      {
        name: "invoice",
        view_name: "invoices",
        permissions: {
          view: false,
          create: false,
          edit: false,
          delete: false,
          import: false,
          export: false,
        },
      },
      {
        name: "payment",
        view_name: "payments",
        permissions: {
          view: false,
          create: false,
          edit: false,
          delete: false,
          import: false,
          export: false,
        },
      },
      {
        name: "payout",
        view_name: "income",
        permissions: {
          view: true,
          create: false,
          edit: false,
          delete: false,
          import: false,
          export: false,
        },
      },
      {
        name: "deposit",
        view_name: "deposits",
        permissions: {
          view: false,
          create: false,
          edit: false,
          delete: false,
          import: false,
          export: false,
        },
      },
      {
        name: "expense",
        view_name: "expenses",
        permissions: {
          view: true,
          create: false,
          edit: false,
          delete: false,
          import: false,
          export: false,
        },
      },
    ],
  },
  {
    name: "My Requests",
    isActive: false,
    subrole_active: false,
    permissions: {
      view: true,
      create: false,
      edit: false,
      delete: false,
      import: false,
      export: false,
    },

    subRoles: [
      {
        name: "request",
        view_name: "requests",
        permissions: {
          view: true,
          create: false,
          edit: false,
          delete: false,
          import: false,
          export: false,
        },
      },
      {
        name: "maintenance",
        view_name: "maintenance",
        permissions: {
          view: true,
          create: false,
          edit: false,
          delete: false,
          import: false,
          export: false,
        },
      },
    ],
  },
];
export default RolesData;
