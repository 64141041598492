import React, { Component } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  Spinner,
  Input,
  Button,
  Modal,
} from "reactstrap";
import closeIcon from "../../assets/img/brand/close.svg";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import DateSvg from "assets/img/brand/date-pick.svg";
import ApiService from "constants/ApiService";
import VerifySecureVault from "./VerifySecureVault";
import DisableSecureVault from "./DisableSecureVault";
import {
  showCalendarFormat,
  getIsoString,
  checkVaultSecure,
  saveSecureVault,
} from "../../constants/utils";
import moment from "moment";

class MarkAsRenewed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeLineData: [],
      isLoading: true,
      renewedDate: new Date(),
      nextRenewedDate: new Date(),
      notes: "",
      btnload: false,
      verifyMarkRenewed: "",
      verifyVaultModal: false,
      disableVaultModal: false,
    };
  }

  componentDidUpdate(prevProps) {
    let { data } = this.props;
    if (prevProps.data !== data) {
      this.setState({ isLoading: true, verifyMarkRenewed: checkVaultSecure() });
      this.setData();
    }
  }

  setData = () => {
    let vault_data = this.props.data;
    const { renewedDate, nextRenewedDate, notes } = this.state;

    this.setOnChangeDate();
  };
  setOnChangeDate = () => {
    const { renewedDate, nextRenewedDate, notes, find_date } = this.state;
    let vault_data = this.props.data;
    let new_date = moment(new Date(renewedDate)).add(
      this.getDays(vault_data.frequency),
      "days"
    );
    this.setState({ nextRenewedDate: new Date(new_date) });
  };

  getDays = (frequency) => {
    let days = 365;
    if (frequency === "weekly") {
      days = 8;
    } else if (frequency === "bi_weekly") {
      days = 15;
    } else if (frequency === "monthly") {
      days = 31;
    } else if (frequency === "quarlerly") {
      days = 91;
    } else if (frequency === "halferly") {
      days = 183;
    }
    return days;
  };
  closeModal = (msg) => {
    this.props.close(msg);
    this.setState({
      renewedDate: new Date(),
      nextRenewedDate: new Date(),
      notes: "",
    });
  };
  handleRenewedDateChange = (e) => {
    this.setState({ renewedDate: e, find_date: e }, () =>
      this.setOnChangeDate()
    );
  };

  handleDateChange = (e) => {
    this.setState({ nextRenewedDate: e });
  };
  handleInputChange = (e) => {
    this.setState({ notes: e.target.value });
  };
  openDisableVaultModal = () => {
    this.setState({ verifyVaultModal: false, disableVaultModal: true });
  };
  scheduleRequest = async () => {
    const pastrenewdate = this.props.data?.next_due;
    const { renewedDate, nextRenewedDate, notes } = this.state;
    let { doc_id } = this.props.data;
    if (!renewedDate) {
      this.props.toast("Please select the renewed date");
    } else if (!nextRenewedDate) {
      this.props.toast("Please select the next renewed date");
    } else if (
      nextRenewedDate &&
      getIsoString(nextRenewedDate) < getIsoString(pastrenewdate)
    ) {
      this.props.toast("Please select valid next due date");
    } else {
      let data = {
        last_done: showCalendarFormat(renewedDate),
        next_due: showCalendarFormat(nextRenewedDate),
        notes: notes,
      };
      this.setState({ btnload: true });
      let response = await ApiService.patchAPI(
        `host/vault/renewal/${doc_id}`,
        data
      );
      if (response.success) {
        this.closeModal(response.message);
        this.setState({ btnload: false });
      } else {
        this.props.toast(response.message);
        this.setState({ btnload: false });
      }
    }
  };
  isSecureMarked = () => {
    const { verifyMarkRenewed } = this.state;
    if (verifyMarkRenewed) {
      this.setState({ verifyVaultModal: true });
    } else {
      this.scheduleRequest();
    }
  };
  toggleVerifyVaultModal = (status) => {
    if (status) {
      this.setState({ verifyMarkRenewed: false }, () => this.scheduleRequest());
    }
    this.setState({ verifyVaultModal: false });
  };
  toggleDisableVaultModal = (status) => {
    if (status) {
      this.getHostDetails();
      this.setState({ verifyMarkRenewed: false });
      setTimeout(() => this.closeModal("", true), 1000);
    }
    this.setState({ disableVaultModal: false });
  };
  getHostDetails = async () => {
    let response = await ApiService.getAPI("host");
    if (response.success) {
      localStorage.setItem("host_details", JSON.stringify(response.data));
      let vault_status = response?.data?.host?.vault?.is_enabled ?? false;
      if (vault_status) {
        saveSecureVault("secured");
      } else {
        saveSecureVault("notsecure");
      }
    }
  };
  render() {
    let {
      timeLineData,
      isLoading,
      renewedDate,
      nextRenewedDate,
      notes,
      disableVaultModal,
      verifyVaultModal,
    } = this.state;

    return (
      <div>
        {this.props.isOpen && (
          <Card className="bnone">
            <CardHeader>
              <div className="flex">
                <div className="fw-500 f16 cl000000">Mark as Renewed</div>

                <img
                  src={closeIcon}
                  onClick={() => this.closeModal()}
                  className="point"
                  alt=""
                />
              </div>
            </CardHeader>
            <CardBody>
              <div className="cl2A1E17 mb-15 f14 fw-400">
                Please select the next renewal date and proceed *
              </div>
              <br />
              <div className="cl2A1E17 mb-15 f14 fw-400">Last Renewed*</div>

              <div className="form-relative flexStart mb-20 ">
                <DatePicker
                  selected={renewedDate}
                  dateFormat="MM-dd-yyyy"
                  placeholderText=""
                  className="mark_payment_date  point"
                  ariaDescribedBy="basic-addon2"
                  onChange={this.handleRenewedDateChange}
                  maxDate={new Date()}
                  minDate={new Date().setMonth(new Date().getMonth() - 2)}
                  id="RenewedDate"
                  // readOnly={mode === "auto" || this.props.viewMode}
                />
                <label className="mb-remove" htmlFor="RenewedDate">
                  <img
                    src={DateSvg}
                    alt="Date-Picker Icon"
                    className="dateIcon point"
                  />
                </label>
              </div>
              <div className="cl2A1E17 mb-15 f14 fw-400">Next Renewal*</div>

              <div className="form-relative flexStart mb-20 ">
                <DatePicker
                  selected={nextRenewedDate}
                  dateFormat="MM-dd-yyyy"
                  placeholderText=""
                  className="mark_payment_date  point"
                  ariaDescribedBy="basic-addon2"
                  onChange={this.handleDateChange}
                  maxDate={new Date().setMonth(new Date().getMonth() + 24)}
                  minDate={new Date()}
                  id="nextRenewedDate"
                  // readOnly={mode === "auto" || this.props.viewMode}
                />
                <label className="mb-remove" htmlFor="nextRenewedDate">
                  <img
                    src={DateSvg}
                    alt="Date-Picker Icon"
                    className="dateIcon point"
                  />
                </label>
              </div>
              <div>
                <div className="mb-15 cl2A1E17 f14 fw-400">Notes</div>
                <Input
                  type="textarea"
                  value={notes}
                  name="Notes"
                  rows={4}
                  onChange={this.handleInputChange}
                  className="mb-50"
                  maxLength={200}
                />
              </div>
              <div className="flexEnd">
                <div
                  className="mr-20 point f14 button-secondary"
                  onClick={() => this.closeModal()}
                >
                  Cancel
                </div>

                <Button
                  color="primary"
                  type="button"
                  onClick={() => this.isSecureMarked()}
                  className="button-wrapping change-profile-button"
                >
                  {this.state.btnload ? (
                    <div className="spin-loading-save w100 h100">
                      <Spinner type="border" color="#012A4D" />
                    </div>
                  ) : (
                    <div>Save</div>
                  )}
                </Button>
              </div>
            </CardBody>
            <Modal
              isOpen={verifyVaultModal}
              className="vaultverifymodal"
              toggle={() => this.toggleVerifyVaultModal()}
            >
              <VerifySecureVault
                isOpen={verifyVaultModal}
                close={(msg) => this.toggleVerifyVaultModal(msg)}
                isOpenDisable={() => this.openDisableVaultModal()}
              />
            </Modal>
            <Modal
              isOpen={disableVaultModal}
              toggle={() => this.toggleDisableVaultModal()}
            >
              <DisableSecureVault
                close={(msg) => this.toggleDisableVaultModal(msg)}
              />
            </Modal>
          </Card>
        )}
      </div>
    );
  }
}

export default MarkAsRenewed;
