import React, { Component } from "react";
import { Button, Card, Spinner, FormGroup, Col, Row } from "reactstrap";
import ApiService from "constants/ApiService";
import Multiselect from "multiselect-react-dropdown";
import closeIcon from "../../assets/img/brand/close.svg";

class SubManagerProperty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      propertyList: [],
      property: [],
      pageLoading: false,
      btnLoad: false,
      rolesList: [],
      role: "",
      // role_id: "",
    };
  }

  componentDidMount() {
    // this.propertyaccessDetail();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.setState({ pageLoading: true });
      if (this.props.id) {
        this.propertyaccessDetail();
        this.getRolesList();
        this.getPropertyLists();
      }
    }
  }

  getRolesList = async () => {
    let response = await ApiService.getAPI(`host/manage_role?all`);
    if (response.success) {
      let roles = response?.data?.roles;
      let rolein = [];
      if (roles) {
        roles.map((el) => {
          rolein.push({ name: el.name, role_id: el.role_id });
        });
      }
      this.setState({ rolesList: rolein });
    }
  };

  getPropertyLists = async () => {
    let response = await ApiService.getAPI(`host/properties/list`);

    if (response.success) {
      this.setState({
        propertyList: response?.data?.properties,
      });
    }
  };

  propertyaccessDetail = async () => {
    let id = this.props.id;

    let response = await ApiService.getAPI(`host/manage_role/assign/${id}`);
    if (response.success) {
      let property = response?.data?.property;
      let role = response?.data?.role ?? [];
      this.setState({ pageLoading: false });
      delete role?._id;
      if (role.length === 0) {
        this.setState({ role: [...role], property: property });
      } else {
        this.setState({ role: [role], property: property });
      }
    }
  };

  clear = () => {
    this.setState({ propertyList: [], property: [] }, this.props.close(false));
  };

  onPropertySelect = (property, added_item) => {
    this.setState({ property });
  };

  onRoleSelect = (role) => {
    this.setState({ role });
  };

  onSubmit = async () => {
    let id = this.props.id;
    const { role, property } = this.state;
    let propertId = [];
    property.forEach((el, i) => propertId.push(el?.property_id));

    if (role.length === 0) {
      this.props.toast("Please select role");
    } else {
      let data = {
        role_id: role[0]?.role_id ?? "",
        properties: propertId,
      };

      this.setState({ btnLoad: true });

      let response = await ApiService.patchAPI(
        `host/manage_role/assign/update/${id}`,
        data
      );
      if (response.success) {
        this.setState({ btnLoad: false });
        this.props.close(false, response.message);
      } else {
        this.setState({ btnLoad: false });
        this.props.toast(response.message);
      }
    }
  };
  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  render() {
    let { propertyList, btnLoad, rolesList, role, property } = this.state;
    return (
      <div>
        {this.props.isOpen && (
          <Card className="h-100vh">
            <div className="h-100">
              <div className="modal-header maintain-canvas pad_new_col">
                <h5 className="modal-title" id="exampleModalLabel">
                  Sub Manger Access
                </h5>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.clear()}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              {this.state.pageLoading ? (
                <div className="spin-loading-none mt-30">
                  <Spinner type="border" color="#012A4D" />
                </div>
              ) : (
                <div>
                  {" "}
                  <Col lg="12" className="mt_20 mb_20 pad_new_col">
                    <FormGroup>
                      <label
                        className="form-control-label submanager_label"
                        htmlFor="input-first-name"
                      >
                        Role*
                      </label>
                      <Multiselect
                        options={rolesList}
                        selectedValues={role}
                        onSelect={this.onRoleSelect}
                        onRemove={this.onRoleSelect}
                        displayValue="name"
                        className="single-select-component"
                        placeholder={
                          this.state.role.length === 0 ? "select role" : ""
                        }
                        customCloseIcon={
                          <img
                            src={closeIcon}
                            style={{ width: "15px" }}
                            alt=""
                          ></img>
                        }
                        // closeIcon="cancel"
                        singleSelect={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12" className="mt_20 mb_20 pad_new_col">
                    <FormGroup>
                      <label
                        className="form-control-label submanager_label"
                        htmlFor="input-first-name"
                      >
                        Property
                      </label>
                      <Multiselect
                        options={propertyList}
                        selectedValues={property}
                        onSelect={this.onPropertySelect}
                        onRemove={this.onPropertySelect}
                        displayValue="name"
                        className="multi-select-component prop-remove"
                        placeholder={property.length === 0 ? "" : ""}
                        closeIcon="cancel"
                      />
                    </FormGroup>
                  </Col>
                  <Row className="submit-side-tab mt-50">
                    {/* <Col lg="3"> */}
                    <Button
                      color="primary"
                      onClick={() => this.clear()}
                      size="sm"
                      className="button-wrapping sec-btn-wrapping "
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      onClick={this.onSubmit}
                      size="sm"
                      className="button-wrapping"
                    >
                      {btnLoad ? (
                        <div className="spin-loading-save w100 h100">
                          <Spinner type="border" color="#012A4D" />
                        </div>
                      ) : (
                        "Update"
                      )}
                    </Button>
                  </Row>
                </div>
              )}
            </div>
          </Card>
        )}
      </div>
    );
  }
}
export default SubManagerProperty;
