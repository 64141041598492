import React, { Component } from "react";
import {
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  Input,
  InputGroup,
  CardBody,
  Spinner,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";
// core components
import ApiService from "constants/ApiService";
import { returnFirstDegreeObjValue } from "constants/utils";
import returnSecondDegreeObjValue from "constants/utils";
import DatePicker from "react-datepicker";
import CountUp from "react-countup";
import "react-datepicker/dist/react-datepicker.css";
import { getIsoString, filterQuery, userTypeQuery } from "constants/utils";
import ToastList from "components/Toast/ToastList";
import TotalInvoice from "../../../src/assets/img/brand/overall2.svg";
import PaidInvoice from "../../../src/assets/img/brand/open.svg"
import UnpaidInvoice from "../../../src/assets/img/brand/closed.svg"
import CancelledInvoice from "../../../src/assets/img/brand/cancelledInvoice.svg";
import { showError } from "constants/utils";
import { showLocaldate } from "constants/utils";
import { returnCustomerFirsLastName } from "constants/utils";
import { pushToFilteredPage } from "constants/utils";
import { returnSearchObject } from "constants/utils";
import { buildQueryString } from "constants/utils";
import { getDefaultProperty } from "constants/utils";
import { displayPrice, returnDownloadGet } from "constants/utils";
import { secondsInDay } from "date-fns";
import showMore from "../../assets/img/brand/showMore.svg";
import Env from "../../constants/Env"
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { CustomDatePicker } from "components/FilterFields/Filterfields";
import { AsscendingDecending } from "components/FilterFields/Filterfields";
import { returnSortKey } from "constants/utils";
import { returnCustomerName } from "constants/utils";

class Requests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestList: [],
      total: 0,
      filterToggle: false,
      from: "",
      to: "",
      page: 1,
      inputSearch: "",
      filterOption: "username",
      statusSearch: "",
      toast: false,
      response: "",
      toastType: "",
      isLoading: true,
      statsData: {},
      prop_id: "",
      query: {},
      sortingList: "",
      timeoutId: null,
      input_value: false,
      keyValue: ""
    };
  }

  reloadPage = () => {
    this.props.history.listen((location) => {
      if (location.search === "") {
        this.setState({ query: {} }, () => {
          let query = this.props.location.search;
          query = returnSearchObject(query);
          if (Object.keys(query).length === 0) {
            this.handleReset();
          }
        });
      } else {
        this.state.input_value = true
      }
    });
  };

  componentDidMount = async () => {
    this.reloadPage();
    await this.getRequestStats();
    this.setQueryStatus();
    this.setState({ filterOption: "username" })
  };

  // componentDidUpdate(prevProps) {
  //   let search = this.props.location.search;
  //   if (prevProps.location.search !== search) {
  //     this.setQueryStatus();
  //   }
  // }

  setQueryStatus = () => {
    let queryStatus = this.props.location.search;
    let default_property = getDefaultProperty();

    if (queryStatus) {
      let queryObj = returnSearchObject(queryStatus);

      this.setState(
        {
          inputSearch:
            (queryObj.username ||
              queryObj.propname) ?? "",
          filterOption: queryObj.propname
            ? "propname"
            : queryObj.username
            && "username",
          from: queryObj.from ? new Date(queryObj.from) : "",
          to: queryObj.to ? new Date(queryObj.to) : "",
          statusSearch: queryObj.status ?? "",
          page: parseInt(queryObj.page) ?? "",
          query: returnSearchObject(queryStatus),
        },
        () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getRequestsList();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...this.state.query,
            page: 1,
            limit: 10,
            property_id: default_property,
          },
          inputSearch: "",
          // filterOption: "username",
        },
        () => {
          pushToFilteredPage(this.props, this.state.query);
          this.getRequestsList();
        }
      );
    }
  };

  setRequestStatus = () => {
    const search = this.props.location.search;
    const prop_id = new URLSearchParams(search).get("prop_id");
    const status = new URLSearchParams(search).get("status");
    const prop_name = new URLSearchParams(search).get("prop_name");
    // if (prop_name) {
    //   this.setState(
    //     { filterOption: "propname", inputSearch: prop_name },
    //     () => {
    //       this.getRequestsList();
    //     }
    //   );
    // } else {
    //   this.getRequestsList();
    // }

    if (prop_id || status) {
      this.setState(
        { prop_id: prop_id ?? "", statusSearch: status ?? "" },
        // { filterOption: "propname", inputSearch: prop_name },
        () => {
          this.getRequestsList();
        }
      );
    } else {
      this.getRequestsList();
    }
  };
  getRequestsList = async () => {
    let { from, to, prop_id, sortingList } = this.state;
    // let queryParams = "";
    // if (filterOption !== "" && inputSearch !== "")
    //   queryParams += filterQuery(filterOption, inputSearch);
    // if (statusSearch !== "") {
    //   queryParams += filterQuery("status", statusSearch);
    //   if (sortingList !== "") queryParams.sort = sortingList;
    // }
    // if (prop_id !== "") queryParams += filterQuery("id", prop_id);
    // if (from !== "") queryParams += filterQuery("from", getIsoString(from));
    // if (to !== "") queryParams += filterQuery("to", getIsoString(to));
    if (from > to) {
      showError(this, "Please provide valid From and To date");
    } else {
      // let type = localStorage.getItem("req_type") ?? "";
      // let response = await ApiService.getAPI(
      //   `host/requests?page=${page}&limit=10${queryParams}`
      // );
      // let response = await ApiService.getAPI(
      //   `host/requests?page=${page}&limit=10${buildQueryString(this.state.query)}&${userTypeQuery()}`
      // );
      let response = await ApiService.getAPI(
        `host/requests${buildQueryString(
          this.state.query
        )}`);

      if (response.success) {
        let { requests, total } = response.data;
        this.setState({
          requestList: requests,
          total: total,
          isLoading: false,
        });
      } else {
        this.setState({ isLoading: false });
        showError(this, response.message);
      }
    }
  };

  getRequestStats = async () => {
    let default_property = getDefaultProperty();

    let { from, to } = this.state;
    let queryParams = {};

    if (from !== "") queryParams.from = getIsoString(from);
    if (to !== "") queryParams.to = getIsoString(to);
    if (default_property !== "") queryParams.property_id = default_property;

    let response = await ApiService.getAPI(
      `host/requests/stats${buildQueryString(queryParams)}`
    );
    if (response.success) {
      this.setState({ statsData: response.data });
    }
  };
  toggleFilterMenu = () => {
    this.setState({ filterToggle: !this.state.filterToggle });
  };
  onChangeHandler = (e) => {
    e.target.value === "" && this.handleReset();
    this.setState({ [e.target.name]: e.target.value, input_value: true });
    clearTimeout(this.state.timeoutId);
    const timeoutId = setTimeout(() => {
      this.state.filterOption && this.handleSearch();
    }, 1000);
    this.setState({ timeoutId });
  };
  onChangeSelectHandler = (status) => {
    this.setState({ filterOption: status, input_value: true }, () => {
      this.handleFilterStatus();
    });
  }

  updateStateValues = (key, value) => {
    const updatedValue = value == null ? "" : value;
    this.setState({ [key]: updatedValue, input_value: true }, () => {
      if (this.state.from && this.state.to) {
        this.handleFilterStatus();
      }
    });
  };
  handleOnToChange = (e) => {
    this.updateStateValues("to", e);
  };
  handleOnFromChange = (e) => {
    this.updateStateValues("from", e);
  };
  handleIncrementPage = () => {
    if (this.state.requestList.length >= 10) {
      this.setState({ page: this.state.page + 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };
  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };
  redirectDetailPage = (id) => {
    this.props.history.push(`requests/detail/${id}`);
  };
  redirectCustomerPage = (id) => {
    this.props.history.push(`customer/detail/${id}`);
  };
  // Toast Handler

  handleFilterStatus = (card) => {
    let staticQuery = returnSearchObject(this.props.location.search);
    staticQuery.from = getIsoString(this.state.from) ?? "";
    staticQuery.to = getIsoString(this.state.to) ?? "";
    if (this.state.inputSearch !== "" && this.state.filterOption !== "") {
      for (let key in staticQuery) {
        if (
          key === "propname" ||
          key === "booking_id" ||
          key === "username" ||
          key === "property_id"
        ) {
          delete staticQuery[key];
        }
      }
      staticQuery[this.state.filterOption] = this.state.inputSearch;
    }
    staticQuery.status = this.state.statusSearch ?? "";
    staticQuery.page = this.state.page ?? "";
    staticQuery.sort = this.state.sortingList ?? "";
    this.setState({ query: staticQuery }, () => {
      pushToFilteredPage(this.props, { ...this.state.query });
      this.getRequestsList();
      !card && this.getRequestStats();

    });
  };

  handleSearch = () => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  handleReset = () => {
    let default_property = getDefaultProperty();

    let self = this;
    self.setState(
      {
        from: "",
        to: "",
        statusSearch: "",
        filterOption: "username",
        inputSearch: default_property ? default_property : "",
        prop_id: "",
        page: 1,
        query: {
          page: 1,
          limit: 10,
          property_id: default_property,
        },
        input_value: false,
        keyValue: "",
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getRequestStats();
        this.getRequestsList();
      }
    );
  };

  setStatus = (status, card) => {
    if (card === "card") {
      this.setState({ statusSearch: status, page: 1 }, () => {
        this.handleFilterStatus(card);
      });
    } else {
      this.setState({ statusSearch: status, page: 1 }, () => {
        this.handleFilterStatus();
      });
    }
  };

  setSortingList = (status) => {
    this.setState({ sortingList: status, page: 1, input_value: true }, () => {
      this.handleSearch()
    });
  };
  downloadInvoicePdf = async (id) => {
    fetch(Env.BASE_URL + `host/requests/pdf/${id}`, returnDownloadGet()).then((response) => {
      return response.blob();
    })
      .then((data) => {
        if (data.type === "application/json") {
          showError(this, "No records found to download");
        } else {
          const downloadLink = document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(data);
          downloadLink.setAttribute("download", `${id}.pdf`);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }
      });
  }
  action = (key) => {
    let skey = returnSortKey(this, key);
    this.setState({ keyValue: skey, sortingList: skey, page: 1, input_value: true }, () => { this.handleFilterStatus() })
  }
  render() {
    let {
      requestList,
      isLoading,
      page,
      statusSearch,
      toast,
      response,
      toastType,
      statsData,
      sortingList,
      inputSearch,
      filterOption,
      from,
      to,
      input_value
    } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#012A4D" />
      </div>
    ) : (
      <>
        {toast && <ToastList message={response} type={toastType} />}
        <div style={{ margin: "24px 24px -24px 24px" }}>
          <div className="header-body">
            <Row className="row-center">
              <Col lg="6" xl="3" className="max-dash mb_mt_28 mb_mt_28 ">
                <Card
                  className="card-stats mb-4 mb-xl-0 dash-new"
                  onClick={() => this.setStatus("", "card")}
                >
                  <CardBody className="pad-dash">
                    <div className="dash_content dash_content">
                      <div className="dash_left">
                        <div className="heading_with_badge">
                          <h4>OVERALL</h4>
                        </div>
                        <label className="number-con">
                          <CountUp
                            start={0}
                            end={returnFirstDegreeObjValue(
                              statsData,
                              "total_req"
                            )}
                            separator=","
                          />
                        </label>
                      </div>
                      <div className="col dash-col dash_right">
                        <img
                          src={TotalInvoice}
                          className="img-dash"
                          alt="total sales"
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="3" className="max-dash mb_mt_28">
                <Card
                  className="card-stats mb-4 mb-xl-0 dash-new"
                  onClick={() =>
                    this.setStatus(
                      "scheduled,assigned,rescheduled,accepted,pending,in_progress",
                      "card"
                    )
                  }
                >
                  <CardBody className="pad-dash">
                    <div className="dash_content dash_content">
                      <div className="dash_left">
                        <div className="heading_with_badge">
                          <h4>OPEN </h4>
                        </div>
                        <label className="number-con">
                          <CountUp
                            start={0}
                            end={returnFirstDegreeObjValue(
                              statsData,
                              "open_req"
                            )}
                            separator=","
                          />
                        </label>
                      </div>
                      <div className="col dash-col dash_right">
                        <img
                          src={PaidInvoice}
                          className="img-dash"
                          alt="total sales"
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="3" className="max-dash mb_mt_28 ">
                <Card
                  className="card-stats mb-4 mb-xl-0 dash-new"
                  onClick={() => this.setStatus("completed,closed", "card")}
                >
                  <CardBody className="pad-dash">
                    <div className="dash_content dash_content">
                      <div className="dash_left">
                        <div className="heading_with_badge">
                          <h4>CLOSED </h4>
                        </div>
                        <label className="number-con">
                          <CountUp
                            start={0}
                            end={returnFirstDegreeObjValue(
                              statsData,
                              "close_req"
                            )}
                            separator=","
                          />{" "}
                        </label>
                      </div>
                      <div className="col dash-col dash_right">
                        <img
                          src={UnpaidInvoice}
                          className="img-dash"
                          alt="total sales"
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="3" className="max-dash mb_mt_28 ">

                <Card
                  className="card-stats mb-4 mb-xl-0 dash-new"
                  onClick={() => this.setStatus("rejected,cancelled", "card")}
                >
                  <CardBody className="pad-dash">
                    <div className="dash_content dash_content">
                      <div className="dash_left">
                        <div className="heading_with_badge">
                          <h4>CANCELLED </h4>
                        </div>
                        <label className="number-con">
                          <CountUp
                            start={0}
                            end={returnFirstDegreeObjValue(
                              statsData,
                              "cancel_req"
                            )}
                            separator=","
                          />{" "}
                        </label>
                      </div>
                      <div className="col dash-col dash_right">
                        <img
                          src={CancelledInvoice}
                          className="img-dash"
                          alt="total sales"
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
                {/* </a> */}
              </Col>
            </Row>
          </div>
        </div>
        <CustomListingContainer>
          <ListingHeaderLayout label={"Repairs & Services"} />
          <div style={{ margin: "20px" }}>
            <InputGroup className="flex-content align-left">
              <div className="filters_container">
                <div className="left_filter_container">
                  <div className="w30">
                    <CustomDatePicker
                      from={from}
                      to={to}
                      fromChange={this.handleOnFromChange}
                      toChange={this.handleOnToChange}
                    ></CustomDatePicker>
                  </div>
                  <div className="select_with_input status_select_input ml-10">
                    <UncontrolledDropdown className="status_select_div ">
                      <DropdownToggle className="pr-0 drop_down_container">
                        <Media className="capitalize">
                          {filterOption === "username" ? "Tenant"
                            : filterOption === "propname" && "Property"
                          }
                          <i className="fas fa-angle-down" style={{ marginLeft: "20px" }}></i>
                        </Media>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" bottom style={{ minWidth: "130px" }}>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.onChangeSelectHandler("username")}
                        >
                          Tenant
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.onChangeSelectHandler("propname")}
                        >
                          Property
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <Input
                      aria-label="Text input with dropdown button"
                      value={inputSearch}
                      name="inputSearch"
                      placeholder="search by"
                      onChange={this.onChangeHandler}
                    />
                  </div>
                  <div className="status_select">
                    <UncontrolledDropdown className="status_select_div">
                      <DropdownToggle className="pr-0 drop_down_container">
                        <Media className="capitalize">
                          {statusSearch === "in_progress"
                            ? "In Progress"
                            : statusSearch === ""
                              ? "All"
                              : statusSearch === "rejected,cancelled" ||
                                statusSearch === "completed,closed" ||
                                statusSearch === "completed,closed" ||
                                statusSearch ===
                                "scheduled,assigned,rescheduled,accepted,pending,in_progress"
                                ? "All"
                                : statusSearch}{" "}
                          <i className="fas fa-angle-down"></i>
                        </Media>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" bottom>
                        {" "}
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("", "card")}
                        >
                          All
                        </DropdownItem>{" "}
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("accepted", "card")}
                        >
                          Accepted
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("rejected", "card")}
                        >
                          Rejected
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("in_progress", "card")}
                        >
                          In Progress
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("completed", "card")}
                        >
                          Completed
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("closed", "card")}
                        >
                          Closed
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("assigned", "card")}
                        >
                          Assigned
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("pending", "card")}
                        >
                          Pending
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("cancelled", "card")}
                        >
                          Cancelled
                        </DropdownItem>
                       
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>
                {input_value && (inputSearch || statusSearch || (from || to) || sortingList) &&
                  <div className="right_filter_container">
                    <Button
                      color="primary"
                      onClick={this.handleReset}
                      size="sm"
                      className="button-wrapping mr-4 button-secondary reset-btn"
                      style={{ fontSize: "15px" }}                  >
                      Clear filter
                    </Button>
                  </div>}
              </div>
            </InputGroup>
          </div>
          <Row>
            <div className="col new-table">
              <Card className="shadow card_with_paginator">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col" className="bnone" style={{ padding: "0px", display: "flex" }}>
                        <th
                          scope="col"
                          className="pl_40 justLeft items-center bnone point tdColumn_20"
                          style={{ display: "flex" }}
                          onClick={() => this.action("user")}
                        >
                          <AsscendingDecending keyValue={this.state.keyValue} value="user" name="Tenant" />
                        </th>
                        {/* <th scope="col">Property</th> */}
                        <th
                          scope="col"
                          className="pl_40 justLeft items-center bnone point tdColumn_20"
                          style={{ display: "flex" }}
                          onClick={() => this.action("pname")}
                        >
                          <AsscendingDecending keyValue={this.state.keyValue} value="pname" name="Property" />
                        </th>
                      </th>
                      <th scope="col">Requested On</th>
                      <th scope="col">Issue</th>
                      <th scope="col">Issue From</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Status</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  {requestList.length !== 0 ? (
                    <tbody>
                      {requestList.map((request, i) => (
                        <tr key={i}>
                          <td
                            className="cursor-point"
                            onClick={() =>
                              this.redirectDetailPage(request.request_id)
                            }
                          >
                            {request.request_id}
                          </td>
                          <td style={{ padding: "0px" }}>
                            <td className="cursor-point btopNone tdColumn tdColumn_20"
                              onClick={() =>
                                this.redirectCustomerPage(request.user.id)
                              }>
                              {returnCustomerName(request)}
                            </td>
                            <td className="btopNone point tdColumn tdColumn_20"
                              onClick={() =>
                                this.redirectDetailPage(request.request_id)
                              }
                            >
                              {returnSecondDegreeObjValue(
                                request,
                                "property",
                                "name"
                              )}
                            </td>
                          </td>
                          <td className="text-capitalize">
                            {showLocaldate(request?.createdAt)}
                          </td>
                          <td className="text-capitalize">
                            {returnFirstDegreeObjValue(request, "type")}
                          </td>
                          <td className="text-capitalize">
                            {showLocaldate(request?.from)}
                          </td>
                          <td className="text-capitalize">
                            {displayPrice(
                              returnFirstDegreeObjValue(request, "amount")
                            )}
                          </td>
                          <td
                            className={
                              request.status === "accepted" ||
                                request.status === "assigned"
                                ? "text-capitalize green general_status_style"
                                : request.status === "cancelled" ||
                                  request.status === "rejected"
                                  ? "text-capitalize red general_status_style"
                                  : request.status === "in_progress" ||
                                    request.status === "pending" ||
                                    request.status === "scheduled" ||
                                    request.status === "rescheduled"
                                    ? "text-capitalize yellow general_status_style"
                                    : request.status === "completed"
                                      ? "text-capitalize status_completed general_status_style"
                                      : request.status === "closed"
                                        ? "text-capitalize primary general_status_style"
                                        : "text-capitalize "
                            }
                          >
                            {returnFirstDegreeObjValue(request, "status") ===
                              "in_progress"
                              ? "In Progress"
                              : returnFirstDegreeObjValue(request, "status")}
                          </td>
                          <td className="text-capitalize">
                            {request.status === "closed" ||
                              request.status === "completed" ? (
                              <UncontrolledDropdown
                                nav
                                className="flexy-content "
                              >

                                <DropdownToggle
                                  className="pr-0 nav-notice"
                                  nav
                                >
                                  <Media className="align-items-center">
                                    <img src={showMore} />
                                  </Media>
                                </DropdownToggle>

                                <DropdownMenu
                                  className="dropdown-menu-arrow min-width-drop"
                                  right
                                >
                                  <>
                                    <DropdownItem
                                      onClick={() =>
                                        this.downloadInvoicePdf(
                                          request.request_id,
                                        )
                                      }
                                    >
                                      <span>Download Invoice</span>
                                    </DropdownItem>
                                  </>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            ) : ("")}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td className="no-data" style={{ width: "150px" }}>No Requests Available</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              </Card>
            </div>
          </Row>
          <Row className="align-items-center jus-space new-margin-page paginator_box">
            <div className="paginate-flex-box ">
              <div
                className="paginate-box"
                onClick={() => this.handleDecrementPage()}
              >
                <p
                  className={page !== 1 ? "page_available" : "page_unavailable"}
                >
                  Previous
                </p>
              </div>
              <div
                className="paginate-box"
                onClick={() => this.handleIncrementPage()}
              >
                <p
                  className={
                    requestList.length < 10
                      ? "page_unavailable"
                      : "page_available"
                  }
                >
                  Next
                </p>
              </div>
            </div>
          </Row>
        </CustomListingContainer>
      </>
    );
  }
}

export default Requests;
