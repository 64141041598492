import React, { Component } from "react";
import { Card, CardBody, CardHeader, Input, Button } from "reactstrap";
import ApiService from "constants/ApiService";
import { showError, showSuccess } from "../../constants/utils";
import ToastList from "components/Toast/ToastList";

class DeleteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmText: "",
      toast: false,
      toastType: "",
      response: "",
    };
  }

  handleInputChange = (e) => {
    this.setState({ confirmText: e.target.value });
  };

  confirmDelete = async () => {
    let { confirmText } = this.state;
    if (confirmText === "") {
      showError(this, "Please enter text");
    } else if (confirmText !== "Delete") {
      showError(this, "Please enter Delete to confirm");
    } else {
      let { propId } = this.props;
      if (propId) {
        let response = await ApiService.deleteAPI(`host/properties/${propId}`);
        if (response.success) {
          this.props.delete(response.message);
        } else {
          showError(this, response.message);
        }
      }
    }
  };
  render() {
    let { confirmText, toast, toastType, response } = this.state;
    return (
      <div>
        {toast && <ToastList message={response} type={toastType} />}

        <Card>
          <CardBody>
            <div className="flex mb-20">
              <div className="fw500 fc-b">Delete Property</div>
              <div
                className="clpaid x_close flexmid point fw500 font-24"
                onClick={() => this.props.close()}
              >
                x
              </div>
            </div>
            <div className="mb-30">
              <div className="mb-20 f14 fw400 fc-b">
                Are you sure you want to completely Delete <br /> the{" "}
                {this.props.propName} Property ?
              </div>
              <label className="f13 fw500 clA0A6AD">
                Please Enter <span className="cl012A4D">Delete</span> to confirm
              </label>
              <Input
                type="text"
                onChange={this.handleInputChange}
                value={confirmText}
                placeholder="Delete"
                style={{ height: "49px" }}
              />
            </div>
            <div className="flexEnd">
              {/* <Button
                color="primary"
                size="lg"
                className="button-wrapping search-wrapper content-flex mr-4 button-secondary ml-4 cancelbtn"
                onClick={this.props.close}
              >
                Cancel
              </Button> */}
              <Button
                color="primary"
                size="lg"
                className="button-wrapping search-wrapper confirmDelete"
                onClick={() => this.confirmDelete()}
              >
                Yes
              </Button>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default DeleteModal;
