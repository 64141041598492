import React, { Component } from "react";
// import { toast, ToastContainer } from "react-toast";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  InputGroup,
  Input,
  Spinner,
  Modal,
  InputGroupText,
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Media,
} from "reactstrap";
import ApiService from "constants/ApiService";
import {
  returnFirstDegreeObjValue,
  returnCustomerFirsLastName,
} from "constants/utils";
import progressAnimate from "../../assets/Lotties/progressAnimate.json";
import { getCloudinaryImage } from "constants/utils";
import { LightgalleryItem } from "react-lightgallery";
import ToastList from "components/Toast/ToastList";
import { showSuccess } from "constants/utils";
import { showError } from "constants/utils";
import requestCall from "../../../src/assets/img/brand/reqCall.svg";
import requestEmail from "../../../src/assets/img/brand/reqEmail.svg";
import requestMap from "../../../src/assets/img/brand/req_map.svg";
import circle from "../../../src/assets/img/brand/reqLineCircle.svg";
import contractorImage from "../../assets/img/brand/contractorImage.svg";
import CustomTimeline from "views/customtimeline";
import requestFirstStage from "../../../src/assets/img/brand/requestFirstStage.svg";
import requestSecondStage from "../../../src/assets/img/brand/secondStageReq.svg";
import RatingStar from "../../../src/assets/img/brand/ratingStar.svg";
import returnSecondDegreeObjValue from "constants/utils";
import { showUtcDate } from "constants/utils";
import verticalView from "../../../src/assets/img/brand/grouping.svg";
import horizontalView from "../../../src/assets/img/brand/invoiceView.svg";
import horizontalViewSelected from "../../../src/assets/img/brand/invoiceViewSelected.svg";
import verticalViewSelected from "../../../src/assets/img/brand/groupingSelected.svg";
import dateLight from "../../assets/img/brand/datePicker.svg";
import { RoleBasedCRUDAccess } from "views/Roles/RolesUtils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { showLocaldate } from "constants/utils";
import Add from "../../assets/img/brand/addIcon.svg";
import removeIcon from "../../assets/img/brand/removeIcon.svg";
import pdfImage from "../../assets/img/brand/pdf.png";
import { uploadCloudinary } from "constants/utils";
import { displayPrice } from "constants/utils";
import { maxLengthCheck } from "constants/utils";
import { roleManager } from "constants/utils";
import { event } from "jquery";
import { isPayTenant } from "constants/utils";
import { returnRole } from "constants/utils";
import comment from "../../../src/assets/img/icons/common/comment.svg";
import Chat from "../Request/chat";
import { returnCustomerName } from "constants/utils";
import ReactTooltip from "react-tooltip";

let user_mode = localStorage.getItem("user");
// core components
let maintenance_id;
class MaintenanceHistoryDetail extends Component {
  constructor(props) {
    super(props);
    maintenance_id = this.props.match.params.id;
    this.state = {
      requestData: {},
      issues: [],
      timeLine: [],
      pendingDate: "",
      progressDate: "",
      progress: false,
      accepted: false,
      accDate: "",
      pending: false,
      completed: false,
      closed: false,
      closedDate: "",
      completedDate: "",
      cancelDate: "",
      cancelled: false,
      rejectDate: "",
      rejected: false,
      status: "",
      reject_reason: "",
      modalOpen: false,
      toast: false,
      response: "",
      toastType: "",
      isLoading: true,
      assignTo: "",
      commentOpenUp: false,
      paidBy: "",
      OpenPopUp: false,
      contractorAssignStage: "1",
      selectedContractor: {},
      contractor_id: "",
      completedAmount: "",
      completeRequestState: false,
      OpenSchedulePopUp: false,
      img_value: "",
      contractorDetails: {},
      disabled: false,
      approximateAmount: "",
      requestStatus: [
        {
          status: "pending",
          isStatus: false,
          boxText: "Request has been generated",
          date: "",
        },
        {
          status: "accepted",
          isStatus: false,
          boxText: "Contractor has Accepted the work",
          date: "",
        },
        {
          status: "in_progress",
          isStatus: false,
          boxText: "Work Is under progress",
          date: "",
        },
        {
          status: "completed",
          isStatus: false,
          boxText: "Work has been successfully completed",
          date: "",
        },
        {
          status: "closed",
          isStatus: false,
          boxText: "Request has been Closed",
          date: "",
        },
      ],
      contractor_list: [],
      contractor_search: "",
      dummyRequestStages: [],
      horizontal: false,
      scheduleOn: "",
      propId: "",
      image: [],
      imageLoading: false,
      assignLoad: false,
      completeSpin: false,
      tenants: [],
      tenantData: "",
    };
  }

  componentDidMount() {
    this.getRequestDetails();
    if (user_mode === "Personal") {
      this.setState({
        paidBy: "host",
        assignTo: "contractor",
        contractorAssignStage: "3",
      });
    }
  }

  getRequestDetails = async () => {
    this.setState({
      contractorDetails: {},
      dummyRequestStages: this.state.requestStatus.map((x) => ({
        ...x,
      })),
    });
    let response = await ApiService.getAPI(`host/maintenance/history/detail/${maintenance_id}`);
    this.setState({ isLoading: false });
    if (response.success) {
      let data = response.data.getHistory;
      let status = data.status;
      this.setState({ propId: data.property.id });
      // end splicing the object from local Array if the request is rejected or assigned to host`
      this.setState(
        {
          requestData: data,
          image: data?.bills,
        },
        () => {
          this.setTimelineData();
        }
      );
      this.getContractors(data);
      // this.getContractorList();

      if (
        status != "completed" ||
        status != "closed" ||
        status != "cancelled"
      ) {
        this.getTenantList(data.property.id);
      }
    }
  };

  getTenantList = async (propId) => {
    let response = await ApiService.getAPI(`host/tenants/${propId}`);
    if (response.success) {
      this.setState({ tenants: response.data.tenants });
    } else {
      showError(this, response.message);
    }
  };

  getBoxText = (item) => {
    let { assigned_to } = this.state.requestData;
    let txt = "";
    let status = item.status;
    if (status === "pending")
      txt = "Request has been generated";
    else if (status === "assigned") {
      if (assigned_to === "host" || assigned_to === "manager") {
        txt = assigned_to === "manager" ? "Manager has accepted the request" : "Host has accepted the request";
      } else {
        txt = "Work Assigned to a Contractor";
      }
    } else if (status === "accepted")
      txt = "Request has been accepted";
    else if (status === "rejected")
      txt = "Request has been rejected";
    else if (status === "sub_contractor_rejected")
      txt = "Sub contractor has been rejected";
    else if (status === "in_progress")
      txt = "Work is under process";
    else if (status === "completed")
      txt = "Work has been successfully completed";
    else if (status === "closed")
      txt = "Request has been Closed";
    else if (status === "cancelled")
      txt = "Request has been Cancelled";
    else if (status === "scheduled")
      txt = "Request has been scheduled";
    else if (status === "rescheduled")
      txt = "Request has been rescheduled";
    else if (status === "sub_contractor_assigned")
      txt = "Sub contractor has been assigned";
    else txt = status;

    return txt;
  };

  setTimelineData = () => {
    let { timeline } = this.state.requestData;
    const completedActions = [];
    const actions = [];
    const constantActions = [...this.state.requestStatus];
    timeline.map((item, index) => {
      if (item.status) {
        actions.push(item.status);
        completedActions.push({
          status: item?.status,
          isStatus: true,
          boxText: this.getBoxText(item),
          date: item?.date && showUtcDate(item.date),
        });
      }
    });
    if (!(actions.includes("cancelled") || actions.includes("rejected"))) {
      constantActions.map((item, index) => {
        if (!actions.includes(item.status)) {
          completedActions.push(item);
        }
      });
    }

    this.setState({ dummyRequestStages: completedActions });
  };

  mapRedirect = () => {
    window.open(`http://www.google.com/maps/place/${this.state.requestData.property.loc[1]},${this.state.requestData.property.loc[0]}`, "_black");
  };

  getContractorList = async () => {
    let { propId } = this.state;
    let queryParams = "";
    if (this.state.contractor_search) {
      queryParams += `?name=${this.state.contractor_search}`;
    }
    let response = await ApiService.getAPI(`host/contractor/loc/${propId}${queryParams}`);
    if (response.success) {
      this.setState({ contractor_list: response.data });
    } else {
      showError(this, response.message);
    }
  };

  getContractors = async (request) => {
    if (request.contractor) {
      let response = await ApiService.getAPI(`host/contractor/${request.contractor.id}`);
      if (response.success) {
        let data = response.data.contractor;
        this.setState(
          { contractorDetails: data, contractor_id: request.contractor.id },
          () => { }
        );
      } else {
        showError(this, response.message);
      }
    }
  };

  StatusSideBar = (value) => {

    this.setState({ OpenPopUp: value, approximateAmount: this.state.requestData?.approximate_cost }, () => {
      this.getContractorList();
      if (this.state.requestData.assigned_to) {
        this.setcontractorDetail();
      }
    });
  };

  setcontractorDetail = () => {
    let { requestData } = this.state;
    this.setState({
      assignTo: requestData.assigned_to === "host" || requestData.assigned_to === "manager" ? "self" : "contractor",
      paidBy: requestData.pay_by,
    });
  };

  clearAssignStages = () => {
    if (user_mode === "Personal") {
      this.setState({
        selectedContractor: {},
        OpenPopUp: false,
        completeRequestState: false,
        contractor_search: "",
      });
    } else {
      this.setState({
        assignTo: "",
        paidBy: "",
        tenantData: "",
        selectedContractor: {},
        contractorAssignStage: "1",
        OpenPopUp: false,
        completeRequestState: false,
        contractor_search: "",
      });
    }
  };

  switchStage = (value) => {
    if (
      (value === "2" && this.state.assignTo === "") ||
      (value === "2" && this.state.assignTo === undefined)
    ) {
      showError(this, "Please select any one to assign this request");
    } else if (
      (value === "3" && this.state.paidBy === "") ||
      (value === "3" && this.state.paidBy === undefined)
    ) {
      showError(this, "Please select any one payment option");
    } else if (
      value === "3" &&
      this.state.assignTo === "self" &&
      this.state.scheduleOn === ""
    ) {
      showError(this, "Please select schedule date");
    } else if (
      (value === "3" && this.state.paidBy === "") ||
      (value === "3" && this.state.paidBy === undefined)
    ) {
      showError(this, "Please select a payment option");
    } else if (
      (value === "3" && !this.state.approximateAmount)
    ) {
      showError(this, "Please enter the estimate cost");
    }
    else if (
      value === "4" &&
      (!this.state.tenantData || this.state.tenantData === "-1")
    ) {
      showError(this, "Please select tenant");
    } else {
      this.setState(
        {
          contractorAssignStage:
            this.state.paidBy != "tenant" && value === "3" ? "4" : value,
        },
        () => { }
      );
    }
  };
  handleRadioChange = (e, key) => {
    this.setState({ [key]: e?.target?.value ? e.target.value : e });
  };
  assignRequest = () => {
    let { scheduleOn } = this.state;
    let isSelf = this.state.assignTo === "self";
    if (
      this.state.contractorAssignStage === "2" &&
      isSelf &&
      (this.state.paidBy === "" || this.state.paidBy === undefined)
    ) {
      showError(this, "Please select payment type");
    } else if (isSelf && this.state.scheduleOn === "") {
      showError(this, "Please select schedule date");
    } else if (!this.state.approximateAmount) {
      showError(this, "Please enter a Estimate Cost");
    } else if (
      isSelf &&
      this.state.paidBy === "tenant" &&
      (!this.state.tenantData || this.state.tenantData === "-1")
    ) {
      showError(this, "Please select tenant");
    } else if (
      this.state.assignTo === "contractor" &&
      Object.keys(this.state.selectedContractor).length === 0
    ) {
      showError(this, "Please select a contractor");
    } else {
      this.setState({ assignLoad: true });
      let data = {
        type: this.state.assignTo,
        pay_by: this.state.paidBy,
        user_id: this.state.tenantData,
        approximate_cost: this.state.approximateAmount,
        service_date:
          scheduleOn === "" || scheduleOn === null || scheduleOn === undefined
            ? ""
            : scheduleOn,
      };
      if (this.state.assignTo === "contractor") {
        data["contractor"] = this.state.selectedContractor;
        delete data.service_date;
      }
      this.updateContractor(data);
    }
  };

  updateContractor = async (data) => {
    let response = await ApiService.patchAPI(
      `host/maintenance/assign/${maintenance_id}`,
      data
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.setState(
        {
          OpenPopUp: false,
          contractorDetails: {},
          assignLoad: false,
          dummyRequestStages: this.state.requestStatus.map((x) => ({
            ...x,
          })),
        },
        () => {
          this.clearAssignStages();
          this.getRequestDetails();
        }
      );
    } else {
      showError(this, response.message);
      this.setState({ assignLoad: false });
    }
  };

  selectContractor = (index, item) => {
    let contractorArray = document.querySelectorAll(".individual_contractor");
    contractorArray.forEach((item) => {
      item.classList.contains("contractor_active") &&
        item.classList.remove("contractor_active");
    });
    contractorArray[index].classList.add("contractor_active");
    this.state.selectedContractor["name"] = item.name;
    this.state.selectedContractor["id"] = item.contractor_id;
  };

  openCompleteSidebar = () => {
    this.setState({ completeRequestState: true });
  };
  changeRequestState = (requestType) => {
    let data = {};
    if (requestType === "complete") {
      data["amount"] = this.state.completedAmount;
      data["bills"] = this.state.image;
      this.updateRequest(data, requestType);
    } else {
      this.updateRequest(data, requestType);
    }
  };
  updateRequest = async (data, requestType) => {
    this.setState({ btnLoading: true });
    let response = await ApiService.patchAPI(
      `host/maintenance/${requestType}/${maintenance_id}`,
      data
    );
    if (response.success) {
      this.setState({ btnLoading: false, completeSpin: false });

      showSuccess(this, response.message);
      this.clearAssignStages();
      this.getRequestDetails();
    } else {
      this.setState({ btnLoading: false, completeSpin: false });
      showError(this, response.message);
    }
  };

  handleInputChange = (e) => {
    this.setState({ completedAmount: e.target.value }, () => { });
  };
  completeRequest = () => {
    let numberRegex = /^[0-9]+$/;
    if (
      this.state.completedAmount === "" ||
      this.state.completedAmount === undefined
    ) {
      showError(this, "Please enter the amount you have spent");
    } else if (!numberRegex.test(this.state.completedAmount)) {
      showError(this, "Field should contain only number");
    } else {
      this.setState({ completeSpin: true });
      this.changeRequestState("complete");
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  searchfilter = (e) => {

    this.setState({ contractor_search: e.target.value }, () => {
      this.getContractorList();
    });
  };

  handleScheduleOnChange = (e) => {
    this.setState({ scheduleOn: e });
  };

  handleCustomerOnChange = (e) => {
    this.setState({ tenantData: e.target.value });
  };

  ScheduleSideBar = (value) => {
    this.setState({ OpenSchedulePopUp: value });
  };

  scheduleRequest = async () => {
    let { scheduleOn } = this.state;
    if (scheduleOn === "" || scheduleOn === null || scheduleOn === undefined) {
      showError(this, "Please Select schedule Date");
    } else {
      let data = {
        date: scheduleOn,
      };
      let response = await ApiService.patchAPI(
        `host/maintenance/reschedule/${maintenance_id}`,
        data
      );
      if (response.success) {
        showSuccess(this, response.message);

        this.ScheduleSideBar(false);
        this.getRequestDetails();
      } else {
        showError(this, response.message);
      }
    }
  };

  updateBills = async () => {
    let { image } = this.state;
    let data = {
      bills: image,
    };
    let response = await ApiService.patchAPI(
      `host/maintenance/${maintenance_id}`,
      data
    );
    if (response.success) {
      this.setState({ imageLoading: false });
      showSuccess(this, response.message);
    } else {
      this.setState({ imageLoading: false });
      showError(this, response.message);
    }
  };

  onImageChangeHandler = async (e, subStatus) => {
    let { image } = this.state;
    let file = e.target.files[0];
    if (e.target.files.length > 0) {
      this.setState({ imageLoading: true });
    }
    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "application/pdf"
    ) {
      if (file.size < 1000000) {
        let data = await uploadCloudinary(file, "host");
        if (data.url) {
          let imgArr = [...image];
          let imageObj = {
            format: data.format,
            public_id: data.public_id,
            version: `v${data.version}`,
            type: "image",
          };
          imgArr.push(imageObj);
          this.setState(
            {
              image: imgArr,
              imageLoading: !subStatus && false,
            },
            () => {
              subStatus && this.updateBills();
            }
          );
        } else {
          showError(this, "Could not upload image");
          this.setState({ imageLoading: false });
        }
      } else {
        showError(this, "File size should not exceeds 1 MB");
        this.setState({ imageLoading: false });
      }
    } else {
      showError(this, "Invalid Format");
      this.setState({ imageLoading: false });
    }
  };

  deleteImage = (i, subStatus) => {
    let { image } = this.state;
    let imgArr = [...image];
    imgArr.splice(i, 1);
    this.setState({ image: imgArr }, () => {
      subStatus && this.updateBills();
    });
  };

  openPdf = (data) => {
    window.open(getCloudinaryImage(100, 100, data, "c_fill"));
  };

  goBack = (value) => {
    if (value) {
      this.setState({
        contractorAssignStage:
          (this.state.paidBy !== "tenant" || !this.state.tenants.length) &&
            value === "3"
            ? "2"
            : value,
      });
    } else {
      this.clearAssignStages();
    }
  };

  commentOpenUp = (value) => {
    this.setState({ commentOpenUp: value });
  };

  onImageClick = (e) => {
    this.setState({ [e.target.name]: "" });
  };

  toggleChatModal = () => {
    this.setState({ commentOpenUp: false });
  };

  render() {
    let {
      requestData,
      isLoading,
      toast,
      commentOpenUp,
      response,
      toastType,
      OpenPopUp,
      assignTo,
      contractorAssignStage,
      contractorDetails,
      paidBy,
      horizontal,
      OpenSchedulePopUp,
      image,
      imageLoading,
      assignLoad,
      completeSpin,
      setCustomer,
      tenantData,
    } = this.state;
    // const defaultOptions = {
    //   loop: true,
    //   autoplay: true,
    //   animationData: progressAnimate,
    //   rendererSettings: {
    //     preserveAspectRatio: "xMidYMid slice",
    //   },
    // };
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#012A4D" />
      </div>
    ) : (
      <>
        <Container className="mt-8 card-pad-wrapper" fluid>
          {toast && <ToastList message={response} type={toastType} />}
          <div>
            <Breadcrumb className="pad-to-8"></Breadcrumb>
          </div>
          <Row className="align-items-center header-div">
            <Col xs="6" className="manage-con">
              <Breadcrumb>
                <BreadcrumbItem onClick={() => this.props.history.goBack()} className="bread-cursor"  >
                  Maintenance
                </BreadcrumbItem>
                <BreadcrumbItem active style={{ color: "#012a4d" }}>{requestData?.service_name}</BreadcrumbItem>
              </Breadcrumb>
            </Col>
            {requestData && requestData.status === "pending" &&
              RoleBasedCRUDAccess("maintenance", "edit") ? (
              <Col xs="6" className="manage-con request_button_div">
                <Button
                  className="button-wrapping ft_white"
                  onClick={() => this.StatusSideBar(true)}
                >
                  Assign
                </Button>
              </Col>
            ) : (
              <Col
                style={{ display: "flex", justifyContent: "flex-end" }}
                className="manage-con"
              >
                <div className="flexEnd">
                  {requestData &&
                    RoleBasedCRUDAccess("maintenance", "edit") &&
                    (requestData.assigned_to === "host" ||
                      requestData.assigned_to === "manager") &&
                    (requestData.status === "pending" ||
                      requestData.status === "accepted" ||
                      requestData.status === "scheduled" ||
                      requestData.status === "rescheduled") && (
                      <Button
                        className="button_without_border  no_shadow p_0 mr-2r"
                        onClick={() => this.ScheduleSideBar(true)}
                      >
                        {/* {requestData.status === "rescheduled" ||
                        requestData.status === "scheduled"
                          ? "Reschedule"
                          : "Schedule"} */}
                        Reschedule
                      </Button>
                    )}
                  {requestData &&
                    RoleBasedCRUDAccess("maintenance", "edit") &&
                    (requestData.assigned_to === "host" ||
                      requestData.assigned_to === "manager") &&
                    (requestData.status === "accepted" ||
                      requestData.status === "scheduled" ||
                      requestData.status === "rescheduled") && (
                      <Button
                        className="button-wrapping ft_white"
                        onClick={() => this.changeRequestState("in_progress")}
                      >
                        {this.state.btnLoading ? (
                          <div className="spin-loading-save w100 h100">
                            <Spinner type="border" color="#012A4D" />
                          </div>
                        ) : (
                          "Mark as in Progress"
                        )}
                      </Button>
                    )}
                  {requestData &&
                    RoleBasedCRUDAccess("maintenance", "edit") &&
                    (requestData.assigned_to === "host" ||
                      requestData.assigned_to === "manager") &&
                    requestData.status === "in_progress" && (
                      <Button
                        className="button-wrapping ft_white"
                        onClick={() => this.openCompleteSidebar()}
                      >
                        {this.state.btnLoading ? (
                          <div className="spin-loading-save w100 h100">
                            <Spinner type="border" color="#012A4D" />
                          </div>
                        ) : (
                          "Complete Request"
                        )}
                      </Button>
                    )}
                </div>
                {(requestData.status !== "pending" &&
                  requestData.status !== "rejected" && user_mode !== 'Personal') && (
                    <div
                      style={{ marginLeft: "24px" }}
                      onClick={() => this.commentOpenUp(true)}
                      className="comment_Bodering"
                    >

                      <div
                        data-tip="Chat with Pro"
                        data-iscapture="true"
                        data-for="toolTip1"
                        data-place="top"
                      >
                        <img style={{ padding: "10px" }} src={comment} />
                      </div>
                      <ReactTooltip
                        place="right"
                        type="info"
                        effect="solid"
                        id="toolTip1"
                        html={true}
                      />

                    </div>
                  )}
              </Col>
            )}
          </Row>
          <div className="details_parent_card_holder">
            <Card className="request_left_card">
              <div className="individual_details">
                <div className="content_div_request">
                  <div className="header_and_data">
                    <p className="header">Property </p>
                    <p className="colon_seperator">:</p>
                    <p className="header_data capitalize">
                      {returnSecondDegreeObjValue(
                        requestData,
                        "property",
                        "name"
                      )}
                    </p>
                  </div>
                </div>
                <div className="content_div_request request_small_width_div">
                  <div className="header_and_data">
                    <p className="header">Status </p>
                    <p className="colon_seperator">:</p>
                    <p
                      className={
                        requestData &&
                          (requestData.status === "pending" ||
                            requestData.status === "in_progress" ||
                            requestData.status === "rescheduled" ||
                            requestData.status === "scheduled")
                          ? "status_yellow header_data capitalize"
                          : requestData.status === "assigned" ||
                            requestData.status === "accepted" ||
                            requestData.status === "completed"
                            ? "status_completed header_data capitalize"
                            : "status_red header_data capitalize"
                      }
                    >
                      {/*  */}
                      {requestData && requestData.status === "in_progress"
                        ? "In Progress"
                        : requestData.status}
                    </p>
                  </div>
                </div>
              </div>
              <div className="individual_details ">
                <div className="content_div_request ">
                  <div className="header_and_data ft_family">
                    <p className="header">Scheduled On</p>
                    <p className="colon_seperator">:</p>
                    <p className="header_data">
                      {" "}
                      {showLocaldate(requestData?.service_date)}
                    </p>
                  </div>
                </div>
                <div className="content_div_request request_small_width_div">
                  <div className="header_and_data ft_family">
                    <p className="header">Service Name </p>
                    <p className="colon_seperator">:</p>
                    <p className="header_data"> {requestData?.service_name}</p>
                  </div>
                </div>
              </div>
              <div className="individual_details ">
                <div className="content_div_request ">
                  <div className="header_and_data ft_family">
                    <p className="header">Estimate Cost</p>
                    <p className="colon_seperator">:</p>
                    <p className="header_data">
                      {" "}
                      ${requestData?.approximate_cost}
                    </p>
                  </div>
                </div>
                <div className="content_div_request request_small_width_div"> {requestData && (

                  <div className="header_and_data ft_family">
                    <p className="header">Amount</p>
                    <p className="colon_seperator">:</p>
                    <p className="header_data">
                      {" "}
                      {displayPrice(
                        returnFirstDegreeObjValue(
                          requestData,
                          "service_charge"
                        )
                      )}
                    </p>
                  </div>

                )}</div>
              </div>

              {requestData?.property?.loc && (
                <>
                  {" "}
                  <hr className="request_card_detail_divider"></hr>
                  <div className="request_image_map_div mt_30">
                    <div className="request_map w_100 relative_div">
                      <button
                        className="view_on_map ft_family"
                        onClick={() => this.mapRedirect()}
                      >
                        View on Map
                      </button>
                      <img src={requestMap} />
                    </div>
                  </div>
                </>
              )}
            </Card>
            <Card className="request_right_card">
              {/* if no contractors assigned */}
              {requestData &&
                (requestData.status === "pending" ||
                  requestData.status === "rejected") && (
                  <div className="no_contractors_assigned">
                    {" "}
                    <h2>Status</h2>
                    <div className="no_contractors_assigned_content">
                      <img src={requestFirstStage} alt="" />
                      <p className="ft_family ft_400 ft_13 mt_30">
                        Nobody has been assigned yet
                      </p>
                      {RoleBasedCRUDAccess("maintenance", "edit") && (
                        <Button
                          className="button-wrapping ft_white"
                          onClick={() => this.StatusSideBar(true)}
                        >
                          Assign
                        </Button>
                      )}
                    </div>
                  </div>
                )}

              {/* end of no contractors assigned */}

              {/* reassign contractor */}
              {requestData &&
                requestData.assigned_to &&
                (requestData.assigned_to === "host" ||
                  requestData.assigned_to === "manager") && (
                  <div className="no_contractors_assigned">
                    {" "}
                    <div className="no_contractors_header">
                      <h2>Self Assigned</h2>
                      {requestData &&
                        (requestData.status === "pending" ||
                          requestData.status === "accepted" ||
                          requestData.status === "in_progress" ||
                          requestData.status === "scheduled" ||
                          requestData.status === "rescheduled") &&
                        RoleBasedCRUDAccess("maintenance", "edit") && (
                          <Button
                            className="button_without_border  no_shadow p_0"
                            onClick={() => this.StatusSideBar(true)}
                          >
                            Reassign
                          </Button>
                        )}
                    </div>
                    <div className="no_contractors_assigned_content">
                      <img src={requestFirstStage} alt="" />
                      <p className="ft_family ft_400 ft_13 mt_30">
                        This work has been self assigned by the Host
                      </p>
                    </div>
                  </div>
                )}

              {/* end of reassign contractor */}

              {/* contractor assigned */}
              {Object.keys(contractorDetails).length > 0 && (
                <div className="no_contractors_assigned">
                  <div className="no_contractors_header">
                    <h2>Contractor Details</h2>
                    {(requestData.status === "pending" ||
                      requestData.status === "accepted" ||
                      requestData.status === "assigned" ||
                      requestData.status === "in_progress" ||
                      requestData.status === "scheduled" ||
                      requestData.status === "rescheduled") &&
                      RoleBasedCRUDAccess("maintenance", "edit") && (
                        <Button
                          className="button_without_border ft_13  no_shadow p_0"
                          onClick={() => this.StatusSideBar(true)}
                        >
                          Reassign
                        </Button>
                      )}
                    {/* {requestData && requestData.status === "in_progress" && (
                      <Button
                        className="button_without_border ft_13  no_shadow p_0"
                        onClick={() => this.changeRequestState("complete")}
                      >
                        Complete Request
                      </Button>
                    )} */}
                  </div>
                  <div className="customer_req_detail_div">
                    {contractorDetails?.image && (
                      <div className="left_customer_req_div">
                        <img
                          src={
                            contractorDetails?.image &&
                            getCloudinaryImage(
                              90,
                              90,
                              contractorDetails.image,
                              "c_fill"
                            )
                          }
                          alt=""
                        />
                      </div>
                    )}
                    <div
                      className={
                        contractorDetails?.image
                          ? "right_customer_req_div"
                          : "w_100 right_customer_req_div"
                      }
                    >
                      <h3 className="ft_family">
                        {contractorDetails && contractorDetails.name}
                      </h3>
                      <div className="d_flex_space_align">
                        {contractorDetails && contractorDetails?.rating && (
                          <div className="rating_contractor">
                            <img
                              className="detail_icon"
                              src={RatingStar}
                              alt=""
                            />
                            <p className="ft_family">
                              {contractorDetails.rating}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <hr className="request_card_detail_divider contractor_assign_divider"></hr>
                  {contractorDetails && contractorDetails?.user?.phone && (
                    <div className="contractor_text_with_icon">
                      <img
                        className="detail_icon"
                        src={requestCall}
                        alt=""
                      ></img>
                      <p className="host_customer_detail ft_family">
                        {contractorDetails &&
                          contractorDetails?.user?.phone?.national_number}
                      </p>
                    </div>
                  )}

                  <div className="contractor_text_with_icon ">
                    <img
                      className="detail_icon"
                      src={requestEmail}
                      alt=""
                    ></img>
                    <p className="host_customer_detail ft_family">
                      {contractorDetails.email}
                    </p>
                  </div>
                  {requestData && (
                    <div className="d_flex_space_align">
                      <div className=" contractor_status_payment">
                        <p className="header">Status :</p>
                        <p
                          className={
                            requestData.status === "pending" ||
                              requestData.status === "in_progress" ||
                              requestData.status === "rescheduled" ||
                              requestData.status === "scheduled"
                              ? "header_data status_yellow capitalize"
                              : requestData.status === "completed" ||
                                requestData.status === "assigned" ||
                                requestData.status === "accepted"
                                ? "status_accepted header_data capitalize"
                                : "status_red header_data capitalize"
                          }
                        >
                          {requestData && requestData.status === "in_progress"
                            ? "In Progress"
                            : requestData.status}
                        </p>
                      </div>{" "}
                      <div className=" contractor_status_payment">
                        <p className="header">Payment :</p>
                        <p
                          className="header_data"
                          style={{ textTransform: "capitalize" }}
                        >
                          {" "}
                          {requestData.pay_by}{" "}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {/* end of contractor assigned */}
            </Card>
          </div>
          {requestData && requestData.status === "completed" && (
            <Card className="mt-10 pad24p">
              <div className="f14 fw-600 cl374957 mb-20">Bills</div>
              <div className="gridAuto ">
                {image?.length > 0 &&
                  image?.map((img, i) => (
                    <div className="requestBillImage">
                      {img.format === "pdf" ? (
                        <img
                          src={pdfImage}
                          alt="PDF"
                          onClick={() => this.openPdf(img)}
                          className="point br-5"
                          width="50px"
                          hight="70px"
                        />
                      ) : (
                        <LightgalleryItem
                          className="image_upload_display request_image"
                          src={getCloudinaryImage(100, 100, img, "c_fill")}
                          alt=""
                        >
                          <img
                            src={getCloudinaryImage(70, 70, img, "c_fill")}
                            alt="profile img"
                            className="point br-5"
                          />
                        </LightgalleryItem>
                      )}
                      {requestData &&
                        requestData?.contractor &&
                        requestData?.contractor?.id ? (
                        ""
                      ) : (
                        <img
                          src={removeIcon}
                          className="deleteIcon"
                          onClick={() => this.deleteImage(i, true)}
                          alt=""
                        />
                      )}
                    </div>
                  ))}
                {requestData &&
                  requestData?.contractor &&
                  requestData?.contractor?.id
                  ? ""
                  : image?.length < 5 && (
                    <div className="requestImageUploader point form-relative">
                      {imageLoading ? (
                        <div className="spin-loading3">
                          <Spinner type="border" color="#012A4D" />
                        </div>
                      ) : (
                        <div>
                          <Input
                            type="file"
                            onChange={(event) =>
                              this.onImageChangeHandler(event, true)
                            }
                            className="form-control-alternative form-control opac-hide "
                            id="ImageUpload"
                          />
                          <label htmlFor="ImageUpload " className="mb-0">
                            <div className="flexmid point">
                              <img src={Add} className="point" alt="ADD" />
                            </div>
                          </label>
                        </div>
                      )}
                    </div>
                  )}
              </div>
            </Card>
          )}
          <Card className="timeline_card mt_10">
            <div className="flex">
              <h2 className="ft_family">Timeline</h2>
              <div className="flex">
                <img
                  src={horizontal ? horizontalView : horizontalViewSelected}
                  className="mr-10 point"
                  onClick={() => this.setState({ horizontal: false })}
                  alt=""
                />
                <img
                  src={horizontal ? verticalViewSelected : verticalView}
                  className=" point"
                  onClick={() => this.setState({ horizontal: true })}
                  alt=""
                />
              </div>
            </div>
            <div
              className={
                horizontal ? "timeline_div horiOverflow" : "hori_timeline"
              }
            >
              {horizontal && <img src={circle} alt="" />}
              <div className={horizontal ? "timeline_common_line" : ""}>
                <CustomTimeline
                  className="absolute_div"
                  data={this.state.dummyRequestStages}
                  view={horizontal}
                />
              </div>
              {horizontal && <img src={circle} alt="" />}
            </div>
          </Card>
          {/* change status side bar design */}
          <Card
            className={
              this.state.completeRequestState
                ? "toggle-sidebar-width req_side_bar"
                : "toggle-sidebar"
            }
          >
            {this.state.completeRequestState && (
              <>
                <div className="modal-header maintain-canvas border_radius_0">
                  {" "}
                  <h5 className="modal-title" id="exampleModalLabel">
                    Assign Request
                  </h5>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.clearAssignStages(false)}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <div className="request_complete_stages_div">
                  <div className="request_complete_stage_one fade_effect">
                    {requestData?.approximate_cost && <>  <h2 className="ft_family mb_20">
                      Estimate Cost
                    </h2>

                      <div className="mb_20">
                        <FormGroup className="mb_50">
                          <InputGroup className="d_flex_align">
                            <Input
                              className="search_contractor ft_15 new_pad10"
                              type="text"
                              disabled
                              name="approximateAmount"
                              value={requestData?.approximate_cost}
                            />
                          </InputGroup>
                        </FormGroup>
                      </div></>}

                    <h2 className="ft_family mb_20">
                      Please enter the amount you have spent
                    </h2>

                    <div className="mb_20">
                      <FormGroup className="mb_50">
                        <InputGroup className="d_flex_align">
                          <Input
                            className="search_contractor price_input ft_15"
                            type="number"
                            name="completedAmount"
                            placeholder="Please enter the amount"
                            value={this.state.completedAmount}
                            onChange={this.handleInputChange}
                            maxLength={5}
                            onInput={maxLengthCheck}
                          />
                        </InputGroup>
                      </FormGroup>
                    </div>
                    <div className="mb-40">
                      <div className="f14 fw-600 cl374957 mb-20">
                        Attach Bills if any
                      </div>
                      <div className="gridAuto65">
                        {image.length > 0 &&
                          image.map((img, i) => (
                            <div className="requestBillImage">
                              {img.format === "pdf" ? (
                                <img
                                  src={pdfImage}
                                  alt="PDF"
                                  onClick={() => this.openPdf(img)}
                                  className="point br-5"
                                  width="50px"
                                  height="70px"
                                />
                              ) : (
                                <LightgalleryItem
                                  className="image_upload_display request_image "
                                  src={getCloudinaryImage(
                                    100,
                                    100,
                                    img,
                                    "c_fill"
                                  )}
                                >
                                  <img
                                    src={getCloudinaryImage(
                                      70,
                                      70,
                                      img,
                                      "c_fill"
                                    )}
                                    alt="profile image"
                                    className="point br-5"
                                  />
                                </LightgalleryItem>
                              )}
                              <img
                                src={removeIcon}
                                className="deleteIcon"
                                onClick={() => this.deleteImage(i, false)}
                                alt=""
                              />
                            </div>
                          ))}
                        {image.length < 5 && (
                          <div className="requestImageUploader point form-relative">
                            {imageLoading ? (
                              <div className="spin-loading3">
                                <Spinner type="border" color="#012A4D" />
                              </div>
                            ) : (
                              <div>
                                <Input
                                  type="file"
                                  onChange={(event) =>
                                    this.onImageChangeHandler(event, false)
                                  }
                                  className="form-control-alternative form-control opac-hide "
                                  id="ImageUpload"
                                  value={this.state.img_value}
                                  onClick={(e) => this.onImageClick(e)}
                                />
                                <label htmlFor="ImageUpload " className="mb-0">
                                  <div className="flexmid point">
                                    <img
                                      src={Add}
                                      className="point"
                                      alt="ADD"
                                    />
                                  </div>
                                </label>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="flex_end">
                      <Button
                        className="button-wrapping ft_white"
                        onClick={() => this.completeRequest()}
                      >
                        {completeSpin ? (
                          <div className="spin-loading-save w100 h100">
                            <Spinner type="border" color="#012A4D" />
                          </div>
                        ) : (
                          "Complete Request"
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Card>

          <Card
            className={
              OpenPopUp ? "toggle-sidebar-width req_side_bar" : "toggle-sidebar"
            }
          >
            {OpenPopUp && (
              <>
                <div className="modal-header maintain-canvas border_radius_0">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Assign Request
                  </h5>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.clearAssignStages(false)}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <div className="request_complete_stages_div scrollDiv">
                  {contractorAssignStage === "1" && (
                    <div className="request_complete_stage_one fade_effect">
                      <h2 className="ft_family mb_40">
                        To whom do you want to assign this work??
                      </h2>
                      <div className="illustration_image_div d_flex_align_justify mb_50">
                        <img src={requestFirstStage} alt="" />
                      </div>
                      <div className="input_radio_div">
                        <FormGroup className="mb_30">
                          <InputGroup className="d_flex_align">
                            <Input
                              className="radio_button mt_0 mr_10 point"
                              placeholder=""
                              type="radio"
                              value="self"
                              id="selfAssign"
                              checked={this.state.assignTo === "self"}
                              onChange={(e) =>
                                this.handleRadioChange(e, "assignTo")
                              }
                            />
                            <label
                              className="form-control-label label-in mb_0 point"
                              htmlFor="selfAssign"
                              onClick={() =>
                                this.handleRadioChange("self", "assignTo")
                              }
                            >
                              Assign to Myself
                            </label>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup className="mb_50">
                          <InputGroup className="d_flex_align">
                            <Input
                              className="radio_button mt_0 mr_10 point"
                              placeholder=""
                              type="radio"
                              id="contractorAssign"
                              value="contractor"
                              checked={this.state.assignTo === "contractor"}
                              onChange={(e) =>
                                this.handleRadioChange(e, "assignTo")
                              }
                            />
                            <label
                              className="form-control-label label-in mb_0 point"
                              htmlFor="contractorAssign"
                              onClick={() =>
                                this.handleRadioChange("contractor", "assignTo")
                              }
                            >
                              Assign to Service Pro
                            </label>
                          </InputGroup>
                        </FormGroup>
                      </div>
                      <div className="buttons_div">
                        <div className="button_with_back">
                          <Button
                            className="button_without_border  no_shadow p_0 mr_35"
                            onClick={() => this.goBack("")}
                          >
                            Back
                          </Button>
                          <Button
                            className="button-wrapping ft_white mr_0 mb_0"
                            onClick={(e) => this.switchStage("2")}
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                  {contractorAssignStage === "2" && (
                    <div className="request_complete_stage_one fade_effect">
                      <h2 className="ft_family mb_40">
                        Who Should Pay the bill for this request?
                      </h2>
                      <div className="illustration_image_div d_flex_align_justify mb_50">
                        <img src={requestSecondStage} alt="" />
                      </div>
                      <div className="input_radio_div">
                        <FormGroup className="mb_30">
                          <InputGroup className="d_flex_align">
                            <Input
                              className="radio_button mt_0 mr_10 point"
                              placeholder=""
                              type="radio"
                              value={returnRole()}
                              id="selfPay"
                              checked={this.state.paidBy === returnRole()}
                              onChange={(e) =>
                                this.handleRadioChange(e, "paidBy")
                              }
                            />
                            <label
                              className="form-control-label label-in mb_0 point"
                              htmlFor="selfPay"
                              onClick={() =>
                                this.handleRadioChange(returnRole(), "paidBy")
                              }
                            >
                              I will pay the money
                            </label>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup className="mb_30">
                          <InputGroup className="d_flex_align">
                            {this.state.tenants.length !== 0 ? (
                              <Input
                                className="radio_button mt_0 mr_10 point"
                                placeholder=""
                                type="radio"
                                value="tenant"
                                id="customerPay"
                                checked={this.state.paidBy === "tenant"}
                                onChange={(e) =>
                                  this.handleRadioChange(e, "paidBy")
                                }
                              />
                            ) : (
                              <Input
                                className="radio_button mt_0 mr_10 point"
                                placeholder=""
                                type="radio"
                                value=""
                                id=""
                                disabled
                                style={{ border: "1px solid darkgray" }}
                              />
                            )}
                            {this.state.tenants.length !== 0 ? (
                              <label
                                className="form-control-label label-in mb_0 point"
                                htmlFor="customerPay"
                                onClick={() =>
                                  this.handleRadioChange("tenant", "paidBy")
                                }
                              >
                                Tenant should pay the money
                              </label>
                            ) : (
                              <label
                                className="form-control-label label-in mb_0 point gray"
                                htmlFor="customerPay"
                              >
                                Tenant should pay the money
                              </label>
                            )}
                            {this.state.tenants.length === 0 ? (
                              <p className="no_active_tenants">
                                (This property doesn't have active tenants.)
                              </p>
                            ) : (
                              ""
                            )}
                          </InputGroup>
                        </FormGroup>
                        {roleManager() ? (
                          <FormGroup className="mb_50">
                            <InputGroup className="d_flex_align">
                              <Input
                                className="radio_button mt_0 mr_10 point"
                                placeholder=""
                                type="radio"
                                value="host"
                                id="propertyOwner"
                                checked={this.state.paidBy === "host"}
                                onChange={(e) =>
                                  this.handleRadioChange(e, "paidBy")
                                }
                              />
                              <label
                                className="form-control-label label-in mb_0 point "
                                htmlFor="propertyOwner"
                              >
                                Property Owner should pay the money
                              </label>
                            </InputGroup>
                          </FormGroup>
                        ) : (
                          ""
                        )}
                      </div>
                      {this.state.assignTo === "self" && (
                        <div>
                          <h2 className="ft_family mb-10 ">Schedule On *</h2>
                          <DatePicker
                            selected={this.state.scheduleOn}
                            minDate={new Date()}
                            dateFormat="MM-dd-yyyy"
                            placeholderText=""
                            className="scheduleDate  point"
                            onChange={this.handleScheduleOnChange}
                            name="scheduleOn"
                            id="scheduleOn"
                            value={this.state.scheduleOn}
                            style={{ fontWeight: "500" }}
                            showYearDropdown
                            showTimeSelect
                            showMonthDropdown
                            ariaDescribedBy="basic-addon2"
                          />
                        </div>
                      )}
                      <div>
                        <h2 className="ft_family mb_20">
                          Please enter the Estimate Cost*
                        </h2>
                        <div className="mb_20">
                          <FormGroup className="mb_50">
                            <InputGroup className="d_flex_align">
                              <Input
                                className="search_contractor price_input ft_15"
                                type="number"
                                name="approximateAmount"
                                placeholder="Please enter the amount"
                                value={this.state.approximateAmount}
                                onChange={this.handleChange}
                                maxLength={5}
                                onInput={this.maxLengthCheck}
                              />
                            </InputGroup>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="buttons_with_back">
                        <div className="stage_buttons">
                          <Button
                            className="button_without_border  no_shadow p_0 mr_35"
                            onClick={() => this.goBack("1")}
                          >
                            Back
                          </Button>
                        </div>
                        {paidBy !== "tenant" &&
                          this.state.assignTo === "self" ? (
                          <div className="stage_buttons">
                            <Button
                              className="button-wrapping ft_white mr_0 mb_0"
                              onClick={() => this.assignRequest()}
                            >
                              {assignLoad ? (
                                <div className="spin-loading-save w100 h100">
                                  <Spinner type="border" color="#012A4D" />
                                </div>
                              ) : (
                                "Assign"
                              )}
                            </Button>
                          </div>
                        ) : (
                          <div className="">
                            <Button
                              className="button-wrapping ft_white mr_0 mb_0"
                              onClick={() => this.switchStage("3")}
                            >
                              Next
                            </Button>
                          </div>
                          // :""
                          // ))
                        )}
                      </div>
                    </div>
                  )}
                  {contractorAssignStage === "3" && paidBy === "tenant" && (
                    <div className="contractor_assign_listing fade_effect">
                      <h2 className="ft_family ft_15 ft_500">
                        Select the tenant{" "}
                      </h2>
                      <p className="select_cus">
                        Please select the tenant to send invoice
                      </p>
                      <div className="mb_40">
                        <select
                          className="customer_input"
                          id="tenantData"
                          name="tenantData"
                          value={tenantData}
                          onChange={this.handleCustomerOnChange}
                        >
                          <option value="-1">Select</option>
                          {this.state.tenants.map((tenant, i) => (
                            <option
                              value={tenant.user.id}
                              className="dropdown_item"
                              style={{ color: "black" }}
                            >

                              {returnCustomerName(tenant)}{" "}
                            </option>
                          ))}
                        </select>
                      </div>{" "}
                      {this.state.tenants.length === 0 ? (
                        <p>This property doesn't have active tenants.</p>
                      ) : (
                        ""
                      )}
                      <div className="flex_end mt_30">
                        <Button
                          className="button_without_border no_shadow"
                          onClick={
                            user_mode === "Personal"
                              ? () => this.clearAssignStages(false)
                              : () => this.goBack("2")
                          }
                        >
                          {user_mode === "Personal" ? "Cancel" : "Back"}
                        </Button>{" "}
                        {(this.state.assignTo === "contractor" &&
                          this.state.paidBy !== "tenant") ||
                          (this.state.assignTo === "self" &&
                            this.state.paidBy === "tenant") ? (
                          <div className="stage_buttons">
                            <Button
                              className="button-wrapping ft_white mr_0 mb_0"
                              onClick={() => this.assignRequest()}
                            >
                              {assignLoad ? (
                                <div className="spin-loading-save w100 h100">
                                  <Spinner type="border" color="#012A4D" />
                                </div>
                              ) : (
                                "Assign"
                              )}
                            </Button>
                          </div>
                        ) : (
                          <div className="">
                            <Button
                              className="button-wrapping ft_white mr_0 mb_0"
                              onClick={() => this.switchStage("4")}
                            >
                              Next
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {(contractorAssignStage === "4" || (contractorAssignStage === "3" && this.state.paidBy === "host")) && (
                    <div className="contractor_assign_listing fade_effect">
                      <div className="mb_20">
                        <FormGroup className="mb_50">
                          <InputGroup className="d_flex_align">
                            <Input
                              className="search_contractor"
                              type="text"
                              value={this.state.contractor_search}
                              name="contractor_search"
                              placeholder="Search for Contractor"
                              onChange={this.searchfilter}
                            />
                            <i className="fa fa-search"></i>
                          </InputGroup>
                        </FormGroup>
                      </div>
                      <h2 className="ft_family ft_15 ft_500">
                        Select the contractor for the work{" "}
                      </h2>
                      <div className="contractor_listing mb_20">
                        {this.state.contractor_list &&
                          this.state.contractor_list.map((item, i) => (
                            <div
                              key={i}
                              className="individual_contractor cursor_pointer"
                              onClick={() => this.selectContractor(i, item)}
                            >
                              <img
                                className="contractor_image"
                                src={
                                  item?.image
                                    ? getCloudinaryImage(
                                      90,
                                      90,
                                      item.image,
                                      "c_fill"
                                    )
                                    : contractorImage
                                }
                                alt=""
                              />
                              <div className="content_div">
                                <h2 className="ft_family ft_600 ft_14">
                                  {item.name}
                                </h2>
                                <p className="sub_text ft_12 mb_0">
                                  {item.text}
                                </p>
                              </div>
                              <div className="rating">
                                {item.rating && (
                                  <div className="rating_contractor">
                                    <img
                                      className="detail_icon"
                                      src={RatingStar}
                                      alt=""
                                    />
                                    <p className="ft_family">{item.rating}</p>
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                      </div>{" "}
                      <div className="flex_end mt_30">
                        <Button
                          className="button_without_border no_shadow"
                          onClick={
                            user_mode === "Personal"
                              ? () => this.clearAssignStages(false)
                              : () =>
                                this.goBack(
                                  contractorAssignStage === "4" ? "3" : "2"
                                )
                          }
                        >
                          {user_mode === "Personal" ? "Cancel" : "Back"}
                        </Button>{" "}
                        <Button
                          className="button-wrapping ft_white mb_0"
                          onClick={() => this.assignRequest()}
                        >
                          {assignLoad ? (
                            <div className="spin-loading-save w100 h100">
                              <Spinner type="border" color="#012A4D" />
                            </div>
                          ) : (
                            "Assign"
                          )}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>{" "}
              </>
            )}
          </Card>

          <Card
            className={
              commentOpenUp
                ? "invoice-sidebar payoutSideWidth"
                : "toggle-sidebar"
            }
          >
            {commentOpenUp && (
              <Chat
                close={this.toggleChatModal}
                props={this.props}
                data={this.state.requestData}
                status={requestData.status}
                requestId={maintenance_id}
              ></Chat>
            )}
          </Card>

          {/* end of status side bar design */}

          <Modal
            isOpen={OpenSchedulePopUp}
            toggle={() => this.ScheduleSideBar(false)}
            centered
            className="scheduleModal"
          >
            <Card>
              <CardBody className="pad25">
                <div className="mb-30 flex">
                  <div className=" cl262626 fw-600 f16">
                    {requestData.status === "rescheduled"
                      ? "Reschedule Request"
                      : "Schedule Request"}
                  </div>
                  <div
                    className="clpaid closeModal fw-500 point"
                    onClick={() => this.ScheduleSideBar(false)}
                  >
                    x
                  </div>
                </div>
                <label
                  className="f14 fw-400 cl555555 mb-10"
                  htmlFor="scheduleOn"
                >
                  {requestData.status === "rescheduled"
                    ? "Reschedule Date"
                    : "Schedule Date"}
                </label>

                <InputGroup className="mb-0  flex-content notice-date point">
                  <DatePicker
                    selected={this.state.scheduleOn}
                    minDate={new Date()}
                    maxDate={new Date().setDate(new Date().getDate() + 14)}
                    dateFormat="MM-dd-yyyy"
                    placeholderText=""
                    className="checkOutDatePick mb-40 point"
                    onChange={this.handleScheduleOnChange}
                    name="scheduleOn"
                    id="scheduleOn"
                    value={this.state.scheduleOn}
                    style={{ fontWeight: "500" }}
                    showYearDropdown
                    showTimeSelect
                    showMonthDropdown
                    ariaDescribedBy="basic-addon2"
                  />
                  <InputGroupText id="basic-addon2" className=" back-unset1">
                    <label
                      className="mb-remove cursor-point"
                      htmlFor="schedule_on"
                    >
                      <img
                        src={dateLight}
                        alt="Date-Picker Icon"
                        className="date-svg"
                      // onClick={() => this.openDatePicker()}
                      />
                    </label>
                  </InputGroupText>
                </InputGroup>
                <div className="flexEnd">
                  <Button
                    className="button-wrapping search-wrapper  button-secondary cancelbtn no_shadow button_without_border"
                    onClick={() => this.ScheduleSideBar(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="button-wrapping search-wrapper checkoutSubmit"
                    onClick={() => this.scheduleRequest()}
                  >
                    Save
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Modal>
        </Container>
      </>
    );
  }
}
export default MaintenanceHistoryDetail;
