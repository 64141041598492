import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Table,
  Spinner,
  Row,
  Col,
  Button,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import DatePicker from "react-datepicker";
import ToastList from "components/Toast/ToastList";
import { showError, showSuccess, getIsoString } from "../../constants/utils";
import "react-datepicker/dist/react-datepicker.css";
import ApiService from "constants/ApiService";
import moment from "moment";
import dateLight from "../../assets/img/brand/datePicker.svg";

class CancelDeposit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toast: false,
      toastType: "",
      response: "",
      isLoad: false,
    };
  }

  confirmCancel = async () => {
    this.setState({ isLoad: true });
    let response = await ApiService.patchAPI(
      `host/deposits/cancel/${this.props.id}`
    );
    if (response.success) {
      this.setState({ isLoad: false });
      this.props.close(response.message);
    } else {
      this.setState({ isLoad: false });
      showError(this, response.message);
    }
  };

  render() {
    let { toast, toastType, response, isLoad } = this.state;
    return (
      <Card>
        {toast && <ToastList message={response} type={toastType} />}

        <CardBody className="pad25">
          <div className="mb-20 flex">
            <div className=" cl262626 fw-600 f16">Cancel Deposit</div>
            <div
              className="clpaid closeModal fw-500 point"
              onClick={() => this.props.close()}
            >
              x
            </div>
          </div>

          <label className="mb-20 cl555555 f15 fw-400">
            Are you sure you want to cancel this deposit?
          </label>

          <div className="flexEnd">
            <Button
              color="primary"
              size="lg"
              className="button-wrapping search-wrapper  button-secondary ml-4 cancelbtn"
              onClick={() => this.props.close()}
            >
              No
            </Button>
            <Button
              color="primary"
              size="lg"
              className="btn-info"
              style={{ padding: "10px 13px" }}
              onClick={() => this.confirmCancel()}
            >
              {isLoad ? (
                <div className="spin-loading-save w100 h100">
                  <Spinner type="border" color="#012A4D" />
                </div>
              ) : (
                "Yes"
              )}
            </Button>
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default CancelDeposit;
