import React, { Component } from "react";
import {
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  Input,
  Spinner,
} from "reactstrap";
import ApiService from "constants/ApiService";
import ToastList from "../../components/Toast/ToastList";
import { isInvalidName } from "constants/utils";
import Edit from "../../../src/assets/img/brand/editData.svg";
import { showError } from "constants/utils";
import { pushToFilteredPage } from "constants/utils";
import { returnSearchObject } from "constants/utils";
import { buildQueryString } from "constants/utils";
import { showSuccess } from "constants/utils";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
class Vendors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      queryParams: "",
      page: 1,
      name: "",
      Wsite: "",
      address: "",
      number: "",
      email: "",
      emailId: [],
      phoneNumber: [],
      toast: false,
      response: "",
      toastType: "",
      vendor_list: [],
      addEditVender: false,
      vendor_id: "",
      query: {},
      btn_enable: true,
    };
  }

  reloadPage = () => {
    this.props.history.listen((location, search) => {
      if (location.search === "") {
        this.setState({ query: {} }, () => {
          let query = this.props.location.search;
          query = returnSearchObject(query);
          if (Object.keys(query).length === 0) {
            this.handleReset();
          }
        });
      }
    });
  };

  componentDidMount() {
    this.setQueryStatus();
    this.reloadPage();
  }
  displayMessage = (msg) => {
    showError(this, msg);
  };
  // componentDidUpdate(prevProps) {
  //   let search = this.props.location.search;
  //   if (prevProps.location.search !== search) {
  //     this.setQueryStatus();
  //   }
  // }

  setQueryStatus = () => {
    let queryStatus = this.props.location.search;

    if (queryStatus) {
      let queryObj = returnSearchObject(queryStatus);

      this.setState(
        {
          page: parseInt(queryObj.page) ?? "",
          query: returnSearchObject(queryStatus),
        },
        () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getVenderList();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...this.state.query,
            page: 1,
            limit: 10,
          },
        },
        () => {
          pushToFilteredPage(this.props, this.state.query);
          this.getVenderList();
        }
      );
    }
  };

  getVenderList = async (vendor_id) => {
    if (vendor_id) {
      this.setState({ addEditVender: true });
      let response = await ApiService.getAPI(`host/vendor/${vendor_id}`);

      if (response.success) {
        let data = response.data;
        this.setState({
          name: data.name,
          vendor_id: vendor_id,
          Wsite: data.website && data.website,
          phoneNumber: data.phone,
          emailId: data.email,
          address: data.address,
          isLoading: false,
        });
      }
    } else {
      // response = await ApiService.getAPI(
      //   `host/vendor?page=${page}&limit=10${queryParams}`
      // );
      let response = await ApiService.getAPI(
        `host/vendor${buildQueryString(this.state.query)}`
      );
      if (response.success) {
        this.setState({ vendor_list: response.data, isLoading: false });
      } else {
        this.setState({ isLoading: false });
        showError(this, response.message);
      }
    }
  };

  handleIncrementPage = () => {
    if (this.state.vendor_list.length >= 10) {
      this.setState({ page: this.state.page + 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };
  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);

    staticQuery.page = this.state.page ?? "";
    this.setState({ query: staticQuery }, () => {
      pushToFilteredPage(this.props, { ...this.state.query });
      this.getVenderList();
    });
  };

  addEditVendor = async (vendor_id) => {
    let { name, address, phoneNumber, emailId, Wsite } = this.state;
    let response;
    if (name === "" || name.length < 3 || isInvalidName(name)) {
      showError(this, "Please enter valid name");
    } else if (address === "" || address.length < 3) {
      showError(this, "Please enter valid address");
    } else if (phoneNumber.length === 0) {
      showError(this, "Please enter your phone number");
    } else if (emailId.length === 0) {
      showError(this, "Please enter your email address");
    } else {
      this.setState({ btnLoad: true, btn_enable: false });
      let data = {
        name: name,
        email: emailId,
        address: address,
        website: Wsite,
        phone: phoneNumber,
      };
      if (vendor_id) {
        response = await ApiService.patchAPI(`host/vendor/${vendor_id}`, data);
        if (response.success) {
          this.setState({ btnLoad: false });
          showSuccess(this, response.message);
          this.emptyContent();
          this.getVenderList();
        } else {
          this.setState({ btnLoad: false, btn_enable: true });
          showError(this, response.message);
        }
      } else {
        response = await ApiService.postAPI("host/vendor", data);
        if (response.success) {
          this.setState({ btnLoad: false });
          showSuccess(this, response.message);
          this.emptyContent();
          this.getVenderList();
        } else {
          this.setState({ btnLoad: false, btn_enable: true });
          showError(this, response.message);
        }
      }
    }
  };
  emptyContent() {
    this.setState({
      addEditVender: false,
      name: "",
      emailId: [],
      address: "",
      Wsite: "",
      phoneNumber: [],
      vendor_id: "",
      btn_enable: true,
    });
  }
  toggleVender(stage) {
    if (stage) {
      this.setState({ addEditVender: false });
      this.emptyContent();
    } else {
      this.setState({ addEditVender: true });
    }
  }
  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  multiInput(e, inputType) {
    var phoneno = /[0-9]/g;
    var emailRegex =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    let value = e.target.value;
    if (e.key === "Enter") {
      // for phonenumber multiSelect
      if (inputType === "phoneNumber") {
        if (!phoneno.test(value)) {
          showError(this, "Field should contain only numbers");
        } else if (value && (value.length < 10 || value.length > 10)) {
          showError(this, "Please enter valid Phone Number");
        } else {
          this.state.phoneNumber.push(value);
          this.setState({ number: "" });
        }
      }
      // for email id multiSelect
      else {
        if (!emailRegex.test(value)) {
          showError(this, "Please enter a valid email address");
        } else {
          this.state.emailId.push(value);
          this.setState({ email: "" });
        }
      }
    }
  }

  removeChips(value, index, inputType) {
    let { phoneNumber, emailId } = this.state;
    if (inputType === "phoneNumber") {
      if (phoneNumber[index] === value) {
        phoneNumber.splice(index, 1);
        this.setState({ phoneNumber: phoneNumber });
      }
    } else {
      if (emailId[index] === value) {
        emailId.splice(index, 1);
        this.setState({ emailId: emailId });
      }
    }
  }

  handleReset = () => {
    this.setState(
      {
        page: 1,
        query: {
          page: 1,
          limit: 10,
        },
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getVenderList();
      }
    );
  };
  render() {
    let {
      addEditVender,
      name,
      Wsite,
      phoneNumber,
      number,
      toast,
      response,
      toastType,
      emailId,
      email,
      page,
      address,
      vendor_list,
      vendor_id,
      btnLoad,
      btn_enable,
    } = this.state;

    return this.state.isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#" />
      </div>
    ) : (
      <>
        {toast && <ToastList message={response} type={toastType} />}
        <div>
          <Breadcrumb></Breadcrumb>
        </div>
        <div
          className={addEditVender ? "opac-div" : "opac-div-initial"}
          onClick={() => this.toggleVender("close")}
        ></div>
        <Card
          className={
            addEditVender
              ? "toggle-sidebar-width vender_side_bar"
              : "toggle-sidebar"
          }
        >
          <div className={addEditVender ? "display-content" : "hide-content"}>
            {/* <Breadcrumb className="pad-to-8">Vender/Add</Breadcrumb> */}
            <div className="modal-header maintain-canvas">
              <h5 className="modal-title" id="exampleModalLabel">
                {vendor_id ? "Edit" : "Add"} Vendor
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleVender("close")}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="vender_content">
              <Row className="input_holder_div mt_0 mb_20">
                <Col lg="6" className="mb-3 input_div">
                  <label
                    className="form-control-label"
                    htmlFor="input-username"
                  >
                    Name*
                  </label>

                  <Input
                    className="form-control-alternative"
                    value={name}
                    name="name"
                    placeholder="Name"
                    type="text"
                    onChange={this.handleInputChange}
                  />
                </Col>
                <Col lg="6" className="mb-3 input_div">
                  <label
                    className="form-control-label"
                    htmlFor="input-username"
                  >
                    Website
                  </label>

                  <Input
                    className="form-control-alternative"
                    value={Wsite}
                    name="Wsite"
                    placeholder="www.example.com"
                    type="text"
                    onChange={this.handleInputChange}
                  />
                </Col>
              </Row>
              <Row className="input_holder_div mb_20 mt_0">
                <Col lg="12" className="mb-3 input_div">
                  <label
                    className="form-control-label"
                    htmlFor="input-username"
                  >
                    Address*
                  </label>

                  <Input
                    className="form-control-alternative"
                    value={address}
                    name="address"
                    placeholder="address"
                    type="text"
                    onChange={this.handleInputChange}
                  />
                </Col>
              </Row>
              <div className="chip_total_div">
                <label
                  className="form-control-label mb_20 mt_0"
                  htmlFor="input-username"
                >
                  Phone Number*
                  <span className="info">
                    {" "}
                    (Type phone number and press enter)
                  </span>
                </label>
                <div className="number_input_holder">
                  <div className="container_chips_input">
                    <div className="chips_div">
                      {phoneNumber.length > 0 &&
                        phoneNumber.map((chips, i) => (
                          <div className="individual_chip" key={i}>
                            <p>{chips}</p>
                            <i
                              className="fa fa-close"
                              onClick={() =>
                                this.removeChips(chips, i, "phoneNumber")
                              }
                            ></i>
                          </div>
                        ))}
                      <input
                        className="transparent_input"
                        name="number"
                        onKeyDown={(e) => this.multiInput(e, "phoneNumber")}
                        value={number}
                        autoComplete="off"
                        onChange={this.handleInputChange}
                        maxLength="10"
                      />{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="chip_total_div mb_0">
                <label
                  className="form-control-label mb_20"
                  htmlFor="input-username"
                >
                  Email Id*
                  <span className="info">
                    {" "}
                    (Type email id and press enter)
                  </span>
                </label>
                <div className="number_input_holder">
                  <div className="container_chips_input">
                    <div className="chips_div">
                      {emailId.length > 0 &&
                        emailId.map((emailChips, i) => (
                          <div className="individual_chip" key={i}>
                            <p> {emailChips}</p>
                            <i
                              className="fa fa-close"
                              onClick={() =>
                                this.removeChips(emailChips, i, "email")
                              }
                            ></i>
                          </div>
                        ))}
                      <input
                        className="transparent_input"
                        name="email"
                        onKeyDown={(e) => this.multiInput(e, "email")}
                        value={email}
                        autoComplete="off"
                        onChange={this.handleInputChange}
                      />{" "}
                    </div>
                  </div>
                </div>
              </div>

              <Row className="align-items-center header-div">
                <div className="add-filter-wrap mt_70">
                  <Button
                    color="primary"
                    onClick={
                      btn_enable ? () => this.addEditVendor(vendor_id) : ""
                    }
                    size="sm"
                    className="button-wrapping "
                  >
                    {btnLoad ? (
                      <div className="spin-loading-save w100 h100">
                        <Spinner type="border" color="#012A4D" />
                      </div>
                    ) : vendor_id ? (
                      "Save Changes"
                    ) : (
                      "Add Vendor"
                    )}
                  </Button>
                </div>
              </Row>
            </div>
          </div>
        </Card>
        <CustomListingContainer>
          <ListingHeaderLayout label={"Vendors"}>
            <div className="justend">
              <div className="flexEnd invoiceStatusView"
                style={{ width: "170px", marginLeft: "15px" }}>

                <div className="add-filter-wrap">
                  <Button
                    color="primary"
                    onClick={() => this.toggleVender()}
                    size="sm"
                    className="button-wrapping "
                  >
                    Add
                  </Button>
                </div>
              </div>
            </div>
          </ListingHeaderLayout>
          <Row>
            <div className="col new-table">
              <Card className="shadow  card_with_paginator">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Website</th>
                      <th scope="col">Phone No</th>
                      <th scope="col">Email</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  {vendor_list.length !== 0 ? (
                    <tbody>
                      {vendor_list &&
                        vendor_list.map((item, i) => (
                          <tr key={i}>
                            <td>{item.name}</td>
                            <td>{item.website ? item.website : "-"}</td>
                            <td>{item.phone[0]}</td>
                            <td>{item.email[0]}</td>
                            <td>
                              <img
                                className="point"
                                src={Edit}
                                onClick={() =>
                                  this.getVenderList(item.vendor_id)
                                }
                                alt=""
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td className="no-data">No Vendors Available</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              </Card>
            </div>
          </Row>
          <Row className="align-items-center jus-space new-margin-page paginator_box">
            <div className="paginate-flex-box ">
              <div
                className="paginate-box"
                onClick={() => this.handleDecrementPage()}
              >
                <p
                  className={page !== 1 ? "page_available" : "page_unavailable"}
                >
                  Previous
                </p>
              </div>
              <div
                className="paginate-box"
                onClick={() => this.handleIncrementPage()}
              >
                <p
                  className={
                    vendor_list.length < 10
                      ? "page_unavailable"
                      : "page_available"
                  }
                >
                  Next
                </p>
              </div>
            </div>
          </Row>
        </CustomListingContainer>
      </>
    );
  }
}
export default Vendors;
