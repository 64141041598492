import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  InputGroup,
  Modal,
  FormGroup,
  InputGroupText,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateSvg from "../../assets/img/brand/date-pick.svg";
export default class AcceptModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let { toggleModal, closeModal, modalState, notice_date, onChangeDate, actDate, onSubmit, } = this.props;
    return (
      <Modal
        className="modal-dialog-centered"
        isOpen={modalState}
        toggle={toggleModal}
      >
        <>
          <div className="modal-header modal-invite">
            <h5 className="modal-title" id="exampleModalLabel">
              Accept Notice
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={closeModal}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <span className="pad-invite">
            {`Notice period actually ends at : ${actDate}. Are you sure you want to accept this notice?`}
          </span>
          <div className="modal-body profile-body">
            {" "}
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label
                    className="form-control-label label-in mb-2"
                    htmlFor="input-username"
                  >
                    Check-Out*
                  </label>
                  <InputGroup className="mb-0 mt-2 flex-content notice-date">
                    <DatePicker
                      selected={notice_date}
                      dateFormat="dd-MM-yyyy"
                      placeholderText=""
                      maxDate={
                        new Date(
                          new Date().getFullYear(),
                          new Date().getMonth() + 12
                        )
                      }
                      minDate={new Date()}
                      className="filter-input-date  new-date-in new-send-invite point"
                      onChange={onChangeDate}
                      name="notice_date"
                      id="notice_date"
                      style={{ fontWeight: "500" }}
                      showYearDropdown
                      showMonthDropdown
                      ariaDescribedBy="basic-addon2"
                    />
                    <InputGroupText id="basic-addon2" className="group-date invite-group notice-group"  >
                      <label className="mb-remove" htmlFor="notice_date">
                        <img
                          src={DateSvg}
                          alt="Date-Picker Icon"
                          className="date-svg"
                        />
                      </label>
                    </InputGroupText>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </div>
          {/* </div> */}
          <div className="modal-footer">
            <div className="add-filter-wrap ">
              <div
                className="filter-wrap mr-3 edit-prop change-profile-pass "
                onClick={closeModal}
              >
                <label className="profile-cancel">Cancel</label>
              </div>
            </div>
            <Button
              color="primary"
              type="button"
              onClick={onSubmit}
              className="button-wrapping change-profile-button"
            >
              Accept
            </Button>
          </div>
        </>
      </Modal>
    );
  }
}
