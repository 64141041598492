import { roleSubManager, saveRoleAccess } from "constants/utils";
let submanagerroute = {};
submanagerroute = saveRoleAccess();
let authroute = [
  "property",
  "booking",
  "notice",
  "tenant",
  "invoice",
  "payment",
  "payout",
  "deposit",
  "expense",
  "request",
  "maintenance",
];
let authupdateroute = ["property_edit"];
let authcreateroute = ["property_create"];

export const routeAuthenticate = (route) => {
  {
    return roleSubManager()
      ? authroute.includes(route.rolename)
        ? submanagerroute[route?.rolename]?.view
        : authupdateroute.includes(route?.rolename)
          ? submanagerroute[route?.name]?.edit
          : authcreateroute.includes(route?.rolename)
            ? submanagerroute[route?.name]?.create
            : true
      : true;
  }
};

export const RoleBasedCRUDAccess = (role, action_type) => {
  return roleSubManager() ? submanagerroute[role] ? submanagerroute[role][action_type] : false : true;
};

export const SidebarSet = (RentalRoutes, data) => {
  submanagerroute = data ? data : submanagerroute;
  const tmpRentalRoutes = JSON.parse(JSON.stringify(RentalRoutes));
  tmpRentalRoutes.filter((mainRoute, key) => {
    if (key === 2 || key === 3 || key === 4) {
      if (mainRoute?.subChild.length > 0) {
        mainRoute?.subChild.filter((subRoute, ind) => {
          submanagerroute[subRoute?.rolename]?.view
            ? (subRoute.view = true)
            : (subRoute.view = false);
        });
      }
    }
    if (key === 2 || key === 3 || key === 4) {
      if (mainRoute?.subChild?.length > 0) {
        let subRouteInActive = mainRoute?.subChild?.every((el) => {
          return el.view === false;
        });
        if (subRouteInActive) {
          mainRoute.view = false;
        }
      }
    }
    return mainRoute;
  });

  return tmpRentalRoutes;
};
