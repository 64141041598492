import { useState, useEffect, useRef, useContext, useMemo } from "react";
import { NavLink as NavLinkRRD, Link, useLocation } from "react-router-dom";
import {
  AddClassToBody,
  getCookie,
  roleSubManager,
} from "../../constants/utils";
import { PropTypes } from "prop-types";
import closeArrowInactive from "../../assets/img/brand/InactiveSideArrow.svg";
import closeArrowActive from "../../assets/img/brand/ActiveSideArrow.svg";
import openArrowActive from "../../assets/img/brand/activeDownArrow.svg";
import openArrowInactive from "../../assets/img/brand/InactiveDownArrow.svg";
import cookie from "react-cookies";
import logoutIcon from "../../assets/img/brand/logoutIcon.svg";
// reactstrap components
import {
  Collapse,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { supplyrequest, servicerequest } from "feature/counter/counterSlice";
import store from "../../feature/Store/store";
import { roleType } from "constants/utils";
import Logo from "../../assets/img/new Logo/host_new_text.svg";
import SmallLogo from "../../assets/img/brand/smallLogo.svg";
import showMore from "../../assets/img/brand/showMore.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { PageTitle } from "layouts/Admin";
import newLogo from "../../assets/img/new Logo/host_new_favicon.svg";

const Sidebar = (props) => {
  const params = useLocation();
  const history = useHistory();
  const { routes, logo } = props;
  const [collapseOpen, setCollapseOpen] = useState(true);
  const [tabValue, setTabValue] = useState("");
  const location = useLocation();
  const [navChildToggle, setNavChildToggle] = useState(false);
  const [screenValue, setScreen] = useState("half");
  const [userValue, setUser] = useState("Rental");
  const [localRoutes, setRoutes] = useState(routes);
  const [view, setView] = useState(false);
  const [lastActivity, setLastActivity] = useState("");
  const sideNavChildren = useRef();
  const { upDateTitle } = useContext(PageTitle);
  const [sideNavBarToggle, setSideNavBarToggle] = useState();

  useEffect(() => {
    setSideNavBarToggle(props.collapse);
  }, [props.collapse]);

  // setScreen(screen);
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };

  useEffect(() => {
  }, [localRoutes]);

  useEffect(() => {
    setRoutes(routes);
  }, []);

  useEffect(() => {
    setSidebar();
  }, [params.pathname]);

  useEffect(() => {
    let screen = localStorage.getItem("screen");
    let user = localStorage.getItem("user");
    if (user) {
      setUser(user);
    } else {
      setUser("Rental");
    }
    setScreen(screen);
    // if (location.merge) {
    //   setTabValue(location);
    // }
    let path = "/" + window.location.href.split("/")[5];
    let routeArray = [...routes];

    setRoutes(routeArray);
    getLastActivity();
  }, []);

  const getLastActivity = () => {
    let active = getCookie("lastActivity");
    setLastActivity(active);
  };

  const setAllSidebar = () => {
    let routeArray = [...routes];
    routeArray.map((route) => {
      if (route.path !== "/index") {
        route.isActive = false;
      }
    });
    setRoutes([...routeArray]);
  };

  const setSidebar = () => {
    let path = params.pathname;
    let routeArray = [...routes];
    for (let route of routeArray) {
      if (route.hasChild) {
        for (let subroute of route.subChild) {
          if (window.location.href.includes(subroute.path)) {
            subroute.isActive = true;
            route.isChildren = true;
            route.isActive = true;
          } else {
            subroute.isActive = false;
          }
        }
      } else {
        if (window.location.href.includes(route.path)) {
          route.isActive = true;
        } else {
          route.isActive = false;
        }
      }
    }
    setRoutes(routeArray);
  };

  const changeView = (view_value) => {
    if (view_value === true) {
      setView(view_value);
      localStorage.setItem("screen", "full");
      AddClassToBody();
    } else {
      setView(view_value);
      localStorage.setItem("screen", "half");
      AddClassToBody();
    }
  };

  const tabChange = (value, index, childIndex) => {
    setTabValue(localRoutes.name);
    // Initially set all routes to false including if it has child 
    const updatedRoutes = localRoutes.map((obj, i) => {
      if (i === index) {
        obj.isActive = !obj.isActive;
        obj.isChildren = !obj.isChildren;
        // If it has children, toggle isActive of all children
        if (obj.hasChild && (childIndex !== null && childIndex !== undefined)) {
          obj.subChild.forEach((ele) => {
            ele.isActive = false;
          });
          obj.subChild[childIndex].isActive = true
          obj.isActive = true
          obj.isChildren = true
        }
        if (childIndex === undefined) {
          obj.isActive = true
        }
        if (obj.hasChild && obj.isActive && !obj.subChild.some(child => child.isActive)) {
          const firstChild = obj.subChild[0];
          firstChild.isActive = true;
          history.push(`/admin${firstChild.path}`);
        }
      } else {
        // Reset isActive and isChildren of other routes
        obj.isActive = false;
        obj.isChildren = false;
      }
      return obj;
    });
    setRoutes([...updatedRoutes]);
  }

  // const tabChange = (value, index, childIndex) => {
  //   setTabValue(value.name);
  //   let routeArray = [...routes];

  //   routeArray.map((route) => {
  //     if (route) {
  //       route.isActive = false;
  //       if (route.hasChild && (childIndex !== null && childIndex !== undefined)) {
  //         route.subChild.map((item) => {
  //           item.isActive = false;
  //         });
  //       }
  //     }
  //   });
  //   routeArray[index].isActive = true;

  //   // expand collapse
  //   if (childIndex === null || childIndex === undefined) {
  //     routeArray.map((route) => {
  //       if (route && route !== value) {
  //         route.isChildren = false;
  //       }
  //     });
  //     routeArray[index].isChildren = !routeArray[index].isChildren;
  //   }
  //   // if ((childIndex === null || childIndex === undefined) && roleSubManager) {
  //   //   routeArray.map((route) => {
  //   //     if (route !== value) {
  //   //       route.isChildren = false;
  //   //       route.isActive = false;
  //   //     }
  //   //   });
  //   //   routeArray[index].isChildren = !routeArray[index].isChildren;
  //   // }

  //   if (childIndex >= 0 && childIndex !== null) {
  //     routeArray[index].subChild[childIndex].isActive = true;
  //   } else if (routeArray[index].hasChild && !routeArray[index].subChild.some(child => child.isActive)) {
  //     routeArray[index].subChild[0].isActive = true
  //     history.push(`/admin${routeArray[index]?.subChild[0].path}`)
  //   }
  //   setRoutes([...routeArray]);
  // };

  // const tabChange = (value, index, childIndex) => {
  //   const tempValue = value
  //   setTabValue(tempValue.name);
  //   let routeArray = [...localRoutes];
  //   routeArray.map((obj,i) => {
  //     //setting false to all routes
  //     routeArray[i] = {...obj}
  //     routeArray[i].isActive = false;
  //     routeArray[i].isChildren = false;
  //       //making all child isActive to false
  //       if (routeArray[i].hasChild && (childIndex !== null && childIndex !== undefined)) {
  //         routeArray[i].subChild.map((item) => {
  //           item.isActive = false;
  //         });
  //       }
  //   });

  //   routeArray[index].isActive = true;

  //   if ((childIndex === null || childIndex === undefined ) && !routeArray[index].hasChild){
  //     routeArray.map((obj,i) => {
  //       routeArray[i] = {...obj}
  //       routeArray[i].isChildren = false;
  //     });
  //   }

  //   if((childIndex === null) && routeArray[index].hasChild){
  //   }

  //   if ((childIndex === null || childIndex === undefined ) && routeArray[index].hasChild) {
  //    routeArray.map((obj,i) => {
  //     routeArray[i] = {...obj}
  //     routeArray[i].isChildren = false
  //     });
  //     routeArray[index].isChildren = !tempValue.isChildren;
  //     routeArray[index].isActive = !tempValue.isActive
  //     routeArray[index].subChild[0].isActive = true
  //     history.push(`/admin${routeArray[index]?.subChild[0].path}`)
  //   }

  //   if (childIndex >= 0 && childIndex !== null) {
  //     routeArray[index].subChild[childIndex].isActive = true;
  //   }

  //    else if (routeArray[index].hasChild && !routeArray[index].subChild.some(child => child.isActive)) {
  //     routeArray[index].subChild[0].isActive = true
  //     history.push(`/admin${routeArray[index]?.subChild[0].path}`)
  //   }


  // setRoutes([...routeArray]);
  // };


  const handleLogout = () => {
    cookie.remove("token", { path: "/" });
    cookie.remove("role");
    cookie.remove("host_id");
    localStorage.removeItem("host_details");
    localStorage.removeItem("user");
    window.location.reload();
  };

  const createLinks = (screen) => {
    // const dispatch = useDispatch;
    return (
      <>
        {localRoutes.map((routeItem, i) => {
          return (
            routeItem && (
              <>
                {screen === "half" && routeItem.view ? (
                  <NavItem key={i} className="side_bar_data">
                    {routeItem?.hasChild ? (
                      <div className="flex-column">
                        <div
                          style={{ position: "relative" }}
                          className={
                            routeItem.isActive
                              ? "flex pad092 h100 bortran point nav-SubChildActive"
                              : "nav-inactive flex pad092 bortran h100 point"
                          }
                          onClick={() => {
                            tabChange(routeItem, i, null);
                          }}
                        >
                          {/* <div className="input-document-flex ">
                          <div className="sideBar_icon">
                            <img src={routeItem.icon} />
                          </div>
                          <div className="side_name">{routeItem.name}</div>
                        </div>
                        <img
                          src={
                            routeItem.isChildren && routeItem.isActive
                              ? openArrowActive
                              : closeArrowActive
                          }
                        /> */}
                          <div className="input-document-flex ">
                            {routeItem.isActive ? (
                              <div className="sideBar_icon ">
                                <img
                                  src={routeItem.image}
                                  alt="route item icon"
                                />
                              </div>
                            ) : (
                              <div className="sideBar_icon">
                                <img
                                  src={routeItem.image}
                                  alt="route item image"
                                />
                              </div>
                            )}
                            <div className="side_name">{routeItem.name}</div>
                          </div>
                          <img
                            src={
                              routeItem.isChildren
                                ? routeItem.isActive
                                  ? openArrowActive
                                  : openArrowInactive
                                : routeItem.isActive
                                  ? closeArrowActive
                                  : closeArrowActive
                            }
                            alt="route children"
                          />
                          {/* {routeItem.isActive && (
                              <div className="active_parent"></div>
                            )} */}
                        </div>
                        {routeItem.isChildren ? (
                          <div className="h100">
                            {routeItem.subChild.map((item, index) => (
                              <NavLink
                                key={index}
                                to={item.layout + item.path}
                                tag={NavLinkRRD}
                                onClick={() => tabChange(item, i, index)}
                                className={
                                  item.isActive
                                    ? "clfff subNav subChildActive"
                                    : "clfff subNav h100 hoverColor"
                                }
                                style={{ position: "relative" }}
                              >
                                <div
                                  style={{ marginLeft: "33px" }}
                                  className={
                                    item.isActive
                                      ? "cl012A4D f14 fw-500 ff-pop"
                                      : "f14 fw-500 ff-pop side_name"
                                  }
                                >
                                  {item.name}
                                </div>
                              </NavLink>
                            ))}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      <NavLink
                        key={i}
                        to={routeItem.layout + routeItem.path}
                        tag={NavLinkRRD}
                        onClick={() => tabChange(routeItem, i)}
                        activeClassName={
                          routeItem.isActive ? "active" : "inactive"
                        }
                        className={
                          routeItem.isActive ? "nav-active" : "nav-inactive"
                        }
                      >
                        <>
                          {routeItem.isActive ? (
                            <div className="sideBar_icon">
                              <img
                                src={routeItem.icon}
                                alt="route item"
                                className="icon_side verticalUnset"
                              />
                            </div>
                          ) : (
                            <div className="sideBar_icon">
                              <img
                                src={routeItem.image}
                                alt="route item"
                                className="icon_side verticalUnset"
                              />
                            </div>
                          )}
                          <div className="side_name">{routeItem.name}</div>
                        </>
                        {/* {routeItem.isActive && (
                            <div className="active_parent"></div>
                          )} */}
                      </NavLink>
                    )}
                  </NavItem>
                ) : (
                  routeItem.view && (
                    <NavItem
                      key={i}
                      className="no_side_bar_data unsetAlign hoverColor"
                      style={{
                        position: "relative",
                        marginBottom: "0px",
                        marginLeft: "0px",
                        width: "100%",
                      }}
                    >
                      {routeItem?.hasChild ? (
                        <div
                          // className="flex-column"
                          className={
                            routeItem.hasChild
                              ? "sideNavContainer"
                              : "hoverColor"
                          }
                        >
                          <div
                            style={{ position: "relative" }}
                            className={
                              routeItem.isActive
                                ? "flexmid pad_sideBar_collapse active_collapse_sidebar h100 point"
                                : "nav-inactive flexmid  pad_sideBar_collapse bortran h100 point"
                            }
                            onClick={() => {
                              tabChange(routeItem, i, null);
                            }}
                          >
                            <img
                              src={
                                routeItem.isActive
                                  ? routeItem.icon
                                  : routeItem.image
                              }
                            />
                            {/* {routeItem.isActive && (
                                <div className="active_parent_side_sub_menu"></div>
                              )} */}
                          </div>

                          {/* {
                            routeItem.isActive && isOpen ? (
                              <>
                                <div className="side-sub-menu" onClick={() => setSidebarList(!sidebarList)}>
                                  {routeItem.subChild.map((item, index) => item.view && (
                                    <NavLink
                                      key={index}
                                      to={item.layout + item.path}
                                      tag={NavLinkRRD}
                                      onClick={() => tabChange(item, i, index)}
                                      className={
                                        item.isActive
                                          ? "clpaid subNav subChildActive margin0"
                                          : "padForSidebar clABABAB subNav "
                                      }
                                    >
                                      <div className="">
                                        <p className={item.isActive ? "sidebarWhite" : "sidebar"}>{item.name}</p>
                                      </div>
                                    </NavLink>
                                  )
                                  )}
                                </div>
                              // </>
                            ) : (
                              <></>
                            
                            )} */}
                          {
                            <div
                              className="side-sub-menu"
                              style={{
                                backgroundColor: "#012a4d",
                                minWidth: "180px",
                              }}
                            >
                              {routeItem.subChild.map((item, index) => (
                                <div>
                                  <NavLink
                                    ref={sideNavChildren}
                                    key={index}
                                    to={item.layout + item.path}
                                    tag={NavLinkRRD}
                                    onClick={() => tabChange(item, i, index)}
                                    activeClassName="sideNavBar"
                                    className="sideNavBar_active "
                                  >
                                    <p
                                      style={{ fontSize: "14px" }}
                                      className="sidebarWhite"
                                    >
                                      {item.name}
                                    </p>
                                  </NavLink>
                                </div>
                              ))}
                            </div>
                          }
                        </div>
                      ) : (
                        <NavLink
                          key={i}
                          to={routeItem.layout + routeItem.path}
                          tag={NavLinkRRD}
                          onClick={() => tabChange(routeItem.name, i)}
                          activeClassName={routeItem.isActive ? "" : ""}
                          style={{
                            backgroundColor: routeItem.isActive ? "white" : "",
                            width: "4vw",
                          }}
                        // className={
                        //   routeItem.isActive
                        //     ? "nav-active flexmid"
                        //     : "nav-inactive flexmid"
                        // }
                        >
                          {routeItem.isActive ? (
                            <>
                              <img
                                src={routeItem.icon}
                                alt="route icon"
                                className="icon_side"
                              />
                            </>
                          ) : (
                            <img
                              src={routeItem.image}
                              alt="route icon"
                              className="icon_side"
                            />
                          )}
                          {/* {routeItem.isActive && (
                              <div className="active_parent_side_sub_menu"></div>
                            )} */}
                        </NavLink>
                      )}
                    </NavItem>
                  )
                )}
              </>
            )
          );
        })}
      </>
    );
  };

  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  return (
    <>
      <Navbar
        className={"navbar-vertical fixed-left navbar-light"}
        expand="md"
        id="sidenav-main"
        style={{ width: "4vw", paddingTop: "23px" }}
      >
        <div
          // style={{ width: sideNavBarToggle ? "4vw" : "" }}
          className="container_fixed_div"
        >
          {/* Collapse */}
          <Collapse
            navbar
            isOpen={collapseOpen}
            className="collapse_navbar h100"
          >
            {/* Collapse header */}
            <div className="navbar-collapse-header d-md-none">
              <Row>
                {logo ? (
                  <Col className="collapse-brand" xs="6">
                    {logo.innerLink ? (
                      <Link to={logo.innerLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </Link>
                    ) : (
                      <a href={logo.outterLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </a>
                    )}
                  </Col>
                ) : null}
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={toggleCollapse}
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            {/* Form */}
            <div className="side_bar_data h100">
              <div className="sideBarFlex h100">
                <div>
                  <div
                    className="side_bar_logo "
                    onClick={() => setAllSidebar()}
                  >
                    <Link
                      to={{
                        pathname: "/admin/index",
                      }}
                      className="flex"
                    >
                      {" "}
                      {/* <img
                        style={{ marginRight: "12px", marginLeft: "36px" }}
                        src={SmallLogo}
                      ></img> */}
                      <img src={Logo}></img>
                    </Link>
                  </div>
                  <Nav className="hide-profile side_bar_data" navbar>
                    {createLinks("half")}
                  </Nav>
                </div>
                <div
                  className="flexStart logout point"
                  onClick={() => handleLogout()}
                >
                  <img src={logoutIcon} className="ma-r " />
                  <div className="f16 fw-500 clfff">Logout</div>
                </div>
              </div>
            </div>

            <div className="no_side_bar_data h100">
              <div className="sideBarFlex h100">
                <div>
                  <div className="side_bar_logo ">
                    <Link
                      to={{
                        pathname: "/admin/index",
                      }}
                    >
                      {" "}
                      <img
                        width={18}
                        height={18}
                        alt="logo"
                        src={newLogo}
                      ></img>
                    </Link>
                  </div>
                  <Nav className="hide-profile" navbar>
                    {createLinks("full")}
                  </Nav>
                </div>
                <div
                  style={{ padding: "1rem 0rem" }}
                  onClick={() => handleLogout()}
                  className="flexmid"
                >
                  <img src={logoutIcon} className="mb-15 point" />
                </div>
              </div>
            </div>
          </Collapse>
        </div>
      </Navbar>
    </>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    innerLink: PropTypes.string,
    outterLink: PropTypes.string,
    imgSrc: PropTypes.string.isRequired,
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
