import React, { Component } from "react";
import closeIcon from "../../../assets/img/brand/close.svg";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroupText,
  Media,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import DateSvg from "../../../assets/img/brand/date-pick.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Add from "../../../assets/img/brand/addIcon.svg";
import removeIcon from "../../../assets/img/brand/removeIcon.svg";
import pdfImage from "../../../assets/img/brand/pdf.png";
import { LightgalleryItem } from "react-lightgallery";
import { getCloudinaryImage } from "constants/utils";
import { uploadCloudinary } from "constants/utils";
import { showError } from "constants/utils";
import ApiService from "constants/ApiService";
import Select from "react-select";
import makeAnimated from "react-select/animated";

class AddPublish extends Component {
  constructor(props) {
    super(props);
    this.state = {
      btnLoading: false,
      pageLoading: false,
      templateName: "Rental Agreement",
      sento: "To all my tenants",
      notes: "",
      labelList: [],
      updateList: [],
    };
  }

  componentDidMount() {
    this.getPropertyList();
  }
  getPropertyList = async () => {
    let response = await ApiService.getAPI(`host/properties/all`);
    if (response.success) {
      let { properties } = response.data;
      let temp = [];
      properties.forEach((property, i) => {
        let sendData = {
          label: property.name,
          value: property.property_id,
        };
        temp.push(sendData);
      });
      this.setState({
        labelList: temp,
      });
    }
  };

  componentDidUpdate(prevProps) {}

  handleNotesChange = (e) => {
    this.setState({ notes: e.target.value });
  };

  handleOnChangeList = (value) => {
    this.setState({
      updateList: value,
    });
  };
  closeModal = () => {
    this.props.close(false);
    this.setState({
      templateName: "Rental Agreement",
      sento: "To all my tenants",
      notes: "",
      updateList: [],
    });
  };

  setSentTo = (status) => {
    this.setState({ sento: status });
  };

  setTemplateName = (status) => {
    this.setState({ templateName: status });
  };

  publishNow = () => {
    let { notes, templateName, sento, updateList } = this.state;
    if (templateName === "") {
      this.props.showError("Please provide a template name");
    } else if (sento === "to specific property" && updateList.length <= 0) {
      this.props.showError("Please select atlease one property");
    } else {
      this.props.close();
    }
  };

  render() {
    let {
      btnLoading,
      pageLoading,
      templateName,
      sento,
      notes,
      labelList,
      updateList,
    } = this.state;
    return (
      <div>
        {this.props.isOpen && (
          <Card className="bnone">
            <CardHeader>
              <div className="flex">
                <div className="fw-500 f16 cl000000">Publish Document</div>

                <img
                  src={closeIcon}
                  onClick={() => this.closeModal()}
                  className="point"
                />
              </div>
            </CardHeader>
            <CardBody className="pad30p">
              {pageLoading ? (
                <div className="spin-loading3 w100 h100">
                  <Spinner type="border" color="#012A4D" />
                </div>
              ) : (
                <>
                  <div>
                    <div className="mb-15 cl2A1E17 f14 fw-400">
                      Select Template*
                    </div>
                    <div className="invoiceStatusView">
                      <UncontrolledDropdown className="status_select_div  mb-30">
                        <DropdownToggle className="pr-0 drop_down_container">
                          <Media className="capitalize">
                            {templateName}
                            <i className="fas fa-angle-down"></i>
                          </Media>
                        </DropdownToggle>
                        <DropdownMenu
                          className="dropdown-menu-arrow w100"
                          bottom
                        >
                          <DropdownItem
                            className="dropdown_item"
                            onClick={() =>
                              this.setTemplateName("rental agreement")
                            }
                          >
                            Rental Agreement
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>

                  <div>
                    <div className="mb-15 cl2A1E17 f14 fw-400">Send To*</div>
                    <div className="invoiceStatusView">
                      <UncontrolledDropdown className="status_select_div  mb-30">
                        <DropdownToggle className="pr-0 drop_down_container">
                          <Media className="capitalize">
                            {sento}
                            <i className="fas fa-angle-down"></i>
                          </Media>
                        </DropdownToggle>
                        <DropdownMenu
                          className="dropdown-menu-arrow w100"
                          bottom
                        >
                          <DropdownItem
                            className="dropdown_item"
                            onClick={() => this.setSentTo("to all tenants")}
                          >
                            To My Customers
                          </DropdownItem>
                          <DropdownItem
                            className="dropdown_item"
                            onClick={() =>
                              this.setSentTo("to all active tenants ")
                            }
                          >
                            To All Active Customers
                          </DropdownItem>

                          <DropdownItem
                            className="dropdown_item"
                            onClick={() =>
                              this.setSentTo("to all previous tenants")
                            }
                          >
                            To All Previous Tenant
                          </DropdownItem>

                          <DropdownItem
                            className="dropdown_item"
                            onClick={() =>
                              this.setSentTo("to specific property")
                            }
                          >
                            To Specific Property
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                  <div className="specificPropSelect mb-30">
                    <div className="cl2A1E17 mb-15 f14 fw-400">Send to</div>
                    <Select
                      closeMenuOnSelect={true}
                      //   components={animatedComponents}
                      value={updateList}
                      name="updateList"
                      onChange={this.handleOnChangeList}
                      isMulti
                      options={labelList}
                      isSearchable={true}
                      placeholder="Select Properties"
                    />{" "}
                  </div>

                  <div className="cl2A1E17 mb-15 f14 fw-400">Notes</div>
                  <Input
                    type="textarea"
                    value={notes}
                    name="notes"
                    onChange={this.handleNotesChange}
                    className="mb-30"
                    rows={4}
                    maxLength={50}
                  />
                  <div className="flexEnd">
                    <div
                      className="mr-20 point f14 button-secondary"
                      onClick={() => this.closeModal()}
                    >
                      Cancel
                    </div>
                    <Button
                      color="primary"
                      className="button-wrapping "
                      size="sm"
                      onClick={() => this.publishNow()}
                    >
                      {btnLoading ? (
                        <div className="spin-loading-save w100 h100">
                          <Spinner type="border" color="#012A4D" />
                        </div>
                      ) : (
                        <div>Publish Now</div>
                      )}
                    </Button>
                  </div>
                </>
              )}
            </CardBody>
          </Card>
        )}
      </div>
    );
  }
}

export default AddPublish;
