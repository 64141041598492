import React, { Component } from "react";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Card,
  Table,
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  InputGroup,
  Dropdown,
  Input,
  Spinner,
  Media,
} from "reactstrap";
// core components
import ApiService from "constants/ApiService";
import { returnFirstDegreeObjValue } from "constants/utils";
import ToastList from "components/Toast/ToastList";
import showMore from "../../../assets/img/brand/showMore.svg";
import {
  buildQueryString,
  getDefaultProperty,
  getIsoString,
  pushToFilteredPage,
  returnSearchObject,
  showError,
  showLocaldate,
} from "../../../constants/utils.js";

import "react-datepicker/dist/react-datepicker.css";
import AddPublish from "./AddPublish";

class Published extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.state = {
      publishedList: [],
      page: 1,
      limit: 10,
      response: "",
      isLoading: true,
      query: {},
      from: "",
      to: "",
      inputSearch: "",
      filterOption: "",
      status: "",
      publishModal: false,
    };
  }

  reloadPage = () => {
    this.props.history.listen((location, search) => {
      if (location.search === "") {
        this.setState({ query: {} }, () => {
          let query = this.props.location.search;
          query = returnSearchObject(query);
          if (Object.keys(query).length === 0) {
            this.handleReset();
          }
        });
      }
    });
  };

  componentDidMount() {
    this.setQueryStatus();
    this.reloadPage();
  }

  // componentDidUpdate(prevProps) {
  //   let search = this.props.location.search;
  //   if (prevProps.location.search !== search) {
  //     this.setQueryStatus();
  //   }
  // }

  setQueryStatus = () => {
    let queryStatus = this.props.location.search;
    let default_property = getDefaultProperty();

    if (queryStatus) {
      let queryObj = returnSearchObject(queryStatus);

      this.setState(
        {
          inputSearch: (queryObj.prop_name || queryObj.property_id) ?? "",
          filterOption: queryObj.prop_name
            ? "prop_name"
            : queryObj.property_id
            ? "property_id"
            : "",
          statusSearch: queryObj.is_occupied ?? "",
          managedBy: queryObj.managed_by ?? "",
          page: parseInt(queryObj.page) ?? "",
          query: returnSearchObject(queryStatus),
        },
        () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getpublishedList();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...this.state.query,
            page: 1,
            limit: 10,
            from: getIsoString(this.state.from),
            to: getIsoString(this.state.to),
            property_id: default_property,
          },
          inputSearch: default_property ? default_property : "",
          filterOption: default_property ? "property_id" : "",
        },
        () => {
          pushToFilteredPage(this.props, this.state.query);
          this.getpublishedList();
        }
      );
    }
  };

  getpublishedList = async () => {
    let response = await ApiService.getAPI(
      `host/expenses${buildQueryString(this.state.query)}`
    );
    if (response.success) {
      let { expenses, total } = response.data;
      this.setState({
        publishedList: expenses,
        isLoading: false,
      });
    }
  };

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleIncrementPage = () => {
    if (this.state.publishedList.length >= 10) {
      this.setState(
        {
          page: this.state.page + 1,
        },
        () => {
          this.handleFilterStatus();
        }
      );
    }
  };
  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 }, () => {
        this.handleFilterStatus();
      });
    }
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    for (let key in staticQuery) {
      if (key === "prop_name" || key === "property_id") {
        delete staticQuery[key];
      }
    }
    if (this.state.inputSearch !== "" && this.state.filterOption !== "") {
      staticQuery[this.state.filterOption] = this.state.inputSearch;
    }
    staticQuery.from = getIsoString(this.state.from) ?? "";
    staticQuery.to = getIsoString(this.state.to) ?? "";
    staticQuery.page = this.state.page ?? "";

    this.setState({ query: staticQuery }, () => {
      pushToFilteredPage(this.props, { ...this.state.query });
      this.getpublishedList();
    });
  };

  handleSubmit = () => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  handleReset = () => {
    let default_property = getDefaultProperty();

    let self = this;
    self.setState(
      {
        from: "",
        to: "",
        page: 1,
        query: {
          page: 1,
          limit: 10,
          property_id: default_property,
        },
        filterOption: default_property ? "property_id" : "",
        inputSearch: default_property ? default_property : "",
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getpublishedList();
      }
    );
  };

  setStatus = (status) => {
    this.setState({ status: status, page: 1 });
  };

  displayErrorMessage = (msg) => {
    showError(this, msg);
  };

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  closePublishModal = () => {
    this.setState({ publishModal: false });
  };

  openAddPublish = () => {
    this.setState({ publishModal: true });
  };

  render() {
    let {
      publishedList,
      page,
      isLoading,
      toast,
      response,
      toastType,
      filterOption,
      inputSearch,
      status,
      publishModal,
    } = this.state;
    let self = this;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#012A4D" />
      </div>
    ) : (
      <>
        <Container className="mt-8 card-pad-wrapper" fluid>
          {toast && <ToastList message={response} type={toastType} />}
          <div>
            <Breadcrumb></Breadcrumb>
          </div>
          <Row className="align-items-center header-div">
            <Col xs="9" className="manage-con">
              <div>
                <Breadcrumb>Published</Breadcrumb>
              </div>
            </Col>
            <Col xs="3" className=" pad0p">
              <div className="flexEnd">
                <div className="add-filter-wrap ml-0">
                  <Button
                    color="primary"
                    size="sm"
                    className="button-wrapping"
                    onClick={() => this.openAddPublish()}
                  >
                    Add
                  </Button>
                </div>
              </div>
            </Col>
          </Row>

          <InputGroup className="mb-5 mb_mt_28 flex-content align-left">
            <div className="filters_container">
              <div className="left_filter_container">
                <div className="select_with_input mr-10">
                  <Dropdown variant="input-group" className="select_input_drop">
                    <select
                      id="inputGroupSelect01"
                      className="form_select fixed_select point"
                      name="filterOption"
                      value={filterOption}
                      onChange={this.onChangeHandler}
                    >
                      <option value="">Select</option>
                      <option value="prop_name">Property</option>
                      <option value="property_id">Property Id</option>
                    </select>
                  </Dropdown>
                  <Input
                    aria-label="Text input with dropdown button"
                    className="input-filter-toggle"
                    value={inputSearch}
                    name="inputSearch"
                    onChange={this.onChangeHandler}
                  />
                </div>
                <div className="status_select mr-10">
                  <UncontrolledDropdown className="status_select_div">
                    <DropdownToggle className="pr-0 drop_down_container">
                      <Media className="capitalize">
                        {status ? status : "All"}
                        <i className="fas fa-angle-down"></i>
                      </Media>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" bottom>
                      {" "}
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setStatus("")}
                      >
                        All
                      </DropdownItem>{" "}
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setStatus("signed")}
                      >
                        Signed
                      </DropdownItem>
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setStatus("unsigned")}
                      >
                        Unsigned
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
              <div className="right_filter_container">
                <Button
                  color="primary"
                  onClick={this.handleReset}
                  size="sm"
                  className="button-wrapping search-wrapper content-flex mr-4 button-secondary ml-4 reset-btn"
                >
                  Reset
                </Button>
                <Button
                  color="primary"
                  onClick={() => this.handleSubmit()}
                  size="sm"
                  className="button-wrapping search-wrapper"
                >
                  Search
                </Button>
              </div>
            </div>
          </InputGroup>

          <Row>
            <div className="col new-table">
              <Card className="shadow card_with_paginator">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Send To</th>
                      <th scope="col">Published On</th>
                      <th scope="col">Status</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  {publishedList.length !== 0 ? (
                    <tbody>
                      {publishedList.map((expense, i) => (
                        <tr key={i} className="point">
                          <td>{returnFirstDegreeObjValue(expense, "mode")}</td>
                          <td className="text-capitalize cursor-point">
                            {showLocaldate(
                              returnFirstDegreeObjValue(expense, "date")
                            )}
                          </td>
                          <td className="text-capitalize cursor-point">
                            {showLocaldate(
                              returnFirstDegreeObjValue(expense, "date")
                            )}
                          </td>
                          <td
                            className="text-capitalize cursor-point fw-600"
                            style={{
                              color:
                                expense.status === "signed"
                                  ? "#008000"
                                  : expense.status === "unsigned"
                                  ? "#ffc043"
                                  : expense.status === "cancelled"
                                  ? "#f83245"
                                  : "#f83245",
                            }}
                          >
                            unsigned
                          </td>

                          <td>
                            <UncontrolledDropdown nav className="position_u">
                              <DropdownToggle className="pr-0 nav-notice" nav>
                                <Media className="align-items-center">
                                  <img src={showMore} />
                                </Media>
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow min-width-drop"
                                right
                              >
                                <>
                                  <DropdownItem>
                                    <span>Edit</span>
                                  </DropdownItem>
                                </>

                                <DropdownItem divider />

                                <DropdownItem>
                                  <span>Delete</span>
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td className="no-data">No Expenses Available</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              </Card>
            </div>
          </Row>
          <Row className="align-items-center header-div jus-space new-margin-page paginator_box">
            <div className="paginate-flex-box">
              <div
                className="paginate-box"
                onClick={() => this.handleDecrementPage()}
              >
                <p
                  className={page !== 1 ? "page_available" : "page_unavailable"}
                >
                  Previous
                </p>
              </div>
              <div
                className="paginate-box"
                onClick={() => this.handleIncrementPage()}
              >
                <p
                  className={
                    publishedList.length < 10
                      ? "page_unavailable"
                      : "page_available"
                  }
                >
                  Next
                </p>
              </div>
            </div>
          </Row>

          <Card className={publishModal ? "exportSideBar" : "toggle-sidebar"}>
            <AddPublish
              isOpen={publishModal}
              close={this.closePublishModal}
              showError={this.displayErrorMessage}
            />
          </Card>
        </Container>
      </>
    );
  }
}

export default Published;
