import { Custom_Button } from "components/Buttons/Button";
import { makeCapitalize } from "constants/utils";
import React from "react";

export function CustomListingContainer({ children, style }) {
  return (
    <div className="customListContainer" style={style}>
      <div className="customListingContainer" >{children}</div>
    </div>
  );
}
export function ListingHeaderLayout({ label, click, buttonLabel, children, labeltwo, styleNo, classStyle, maxFile }) {
  return (
    <>
      <div className={classStyle ? `${classStyle} d_flex_sb_ac listingHeader` : "d_flex_sb_ac listingHeader"}>
        <div className="containerListing_name" style={{ width: "70%" }}>
          {labeltwo ? makeCapitalize(label) + " / " + (labeltwo) : makeCapitalize(label)}
          {maxFile && <span className="f14 cl757575 request_tooltip">(max 5 files only)*</span>}
        </div>
        {buttonLabel && (
          <div>
            <Custom_Button
              isPrimary
              btnLabel={`${buttonLabel}`}
              click={click}
            ></Custom_Button>
          </div>
        )}
        {(children && styleNo) ? <div> {children} </div> :
          children && <div style={{ width: '100%' }}> {children} </div>}

      </div>
    </>
  );
}
