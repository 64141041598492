import React, { Component } from "react";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Media,
  Button,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Form,
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Input,
  InputGroup,
  InputGroupText,
  Spinner,
  Modal,
  CustomInput,
} from "reactstrap";
import ApiService from "constants/ApiService";
import ReactTooltip from "react-tooltip";
import "react-datepicker/dist/react-datepicker.css";
import addDoc from "../../assets/img/brand/addDoc.svg";
import DefaultUser from "../../assets/img/brand/defaultNoticeUser.svg";
import DefaultProperty from "../../assets/img/brand/defaultProperty.svg";
import classnames from "classnames";
import returnSecondDegreeObjValue from "constants/utils";
import { returnFirstDegreeObjValue } from "constants/utils";
import { getUSFormat } from "constants/utils";
import { returnThirdDegreeObjValue } from "constants/utils";
import { getCloudinaryImage } from "constants/utils";
import { displayPrice } from "constants/utils";
import CheckOutModal from "./CheckOutModal";
import { showLocaldate } from "constants/utils";
import showMore from "../../assets/img/brand/showMore.svg";
import GreenTickRound from "../../assets/img/brand/tickGreenRound.svg";
import pendingTickRound from "../../assets/img/brand/pendingRound.svg";
import bookingLine from "../../assets/img/brand/bookingActiveLine.svg";
import { showSuccess } from "constants/utils";
import { showError } from "constants/utils";
import ToastList from "components/Toast/ToastList";
import axios from "axios";
import Constants from "../../constants/Env";
import { getAuthToken } from "constants/utils";
import CancelModal from "./CancelModal";
import { returnPaidDeposit } from "constants/utils";
import { displayAmount } from "constants/utils";
import DepositDetail from "views/Deposits/DepositDetail";
import { RoleBasedCRUDAccess } from "views/Roles/RolesUtils";
import { showUsFormat } from "constants/utils";
import InvoiceBookingDetails from "./InvoiceBookingDetails";
import { returnSearchObject } from "constants/utils";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { refundSecurityValidation } from "constants/utils";
import DepositSideDragger from "./DepositSideDragger";
import { bookingStatusColor } from "constants/utils";
import { bookingStatus } from "constants/utils";
// core components
let booking_id;
export default class BookingDetail extends Component {
  constructor(props) {
    super(props);
    booking_id = this.props.match.params.booking_id;
    this.state = {
      bookingDetail: {},
      pending: {},
      notice: {},
      customer: {},
      property: {},
      files: [],
      modalOpen: false,
      rejectModal: false,
      isLoading: true,
      notice_date: "",
      booking_id: "",
      key: "",
      about: "",
      check_in: "",
      check_out: "",
      reject_reason: "",
      currentActiveTab: "1",
      subCurrentActiveTab: "1",
      subPropertyActiveTab: "1",
      checkoutModal: false,
      room: "",
      toast: "",
      response: "",
      toastType: "",
      requestId: "",
      cancelModal: false,
      historyView: false,
      depositId: "",
      checkRequest: false,
      bank_name: "",
      account_no: "",
      ref: "",
      payoutStatus: "",
      payment_enabled: "Enabled",
      enablePopup: false,
      isLoad: false,
      titles: [
        "First name",
        "Last name",
        "Gender",
        "Mobile number",
        "Email",
        "Date of birth",
        "Nationality",
        "Current address",
        "Occupation",
        "Pets",
        "Are you a smoker?",
        "Payment enabled",
      ],
    };
  }
  componentDidMount() {
    if (booking_id) this.getBookingDetail();
    let queryParams = returnSearchObject(this.props.location.search);
    this.setState({ booking_name: queryParams?.name });
  }

  getBookingDetail = async () => {
    let response = await ApiService.getAPI(`host/booking/all/${booking_id}`);
    if (response.success) {
      let { booking, customer, property, files, room, notice, pending } = response.data;
      this.setState(this.returnProfileData(response));
      files = files.reverse();
      let { check_in, check_out } = booking;
      this.setState({
        bookingDetail: booking,
        pending,
        notice,
        property,
        isLoading: false,
        check_in: check_in ? new Date(check_in) : "",
        check_out: check_out ? new Date(check_out) : "",
        customer,
        files,
        room,
        checkRequest: booking.allow_request ?? false,
        enablePopup: false,
      });
    } else {
      this.setState({ enablePopup: false });
    }
  };
  returnProfileData = (response) => {
    let stripe = response?.data?.customer?.stripe;
    let customer = response?.data?.customer;
    return {
      bank_name: stripe?.name,
      account_no: stripe?.ac_number,
      ref: stripe?.route_number,
      payoutStatus: stripe?.payout_enabled ? "Enabled" : "Information Required",
    };
  };
  updateConfirmPoppop = () => {
    this.setState({ enablePopup: true });
  };
  updateEnableChange = async () => {
    this.setState({ isLoad: true });
    let response = await ApiService.patchAPI(
      `host/booking/pay_change/${booking_id}`
    );
    this.setState({ enablePopup: false, isLoad: false });
    if (response.success) {
      showSuccess(this, response.message);
      this.getBookingDetail();
    } else {
      showError(this, response.message);
    }
  };

  updateStateValues = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      function () {
        if (value === null) {
          this.setState({
            [key]: "",
          });
        }
      }
    );
  };
  handleOnNoticeChange = (e) => {
    this.updateStateValues("notice_date", e);
  };

  toggle = (tab, tabState) => {
    let { currentActiveTab, subCurrentActiveTab, subPropertyActiveTab } = this.state;
    if (tabState === "Tab") {
      if (currentActiveTab !== tab) this.setState({ currentActiveTab: tab });
    } else if (subCurrentActiveTab !== tab) {
      this.setState({ subCurrentActiveTab: tab });
    }
    if (tabState === "Tab") {
      if (currentActiveTab !== tab && subPropertyActiveTab === "subTab") this.setState({ currentActiveTab: tab })
    }
    else if (subPropertyActiveTab !== tab) {
      this.setState({ subPropertyActiveTab: tab });
    }
  };


  redirectAddDocumentPage = (booking_id) => {
    this.props.history.push(`/admin/document/add/${booking_id}`);
  };

  openCheckoutPopup = () => {
    this.setState({ checkoutModal: true });
  };

  toggleModal = (msg) => {
    this.setState({ checkoutModal: !this.state.checkoutModal });
    if (msg) {
      showSuccess(this, msg);
      this.getBookingDetail();
    }
  };

  closeEnableModel = () => {
    this.setState({ enablePopup: false });
  };

  displayHistory = (id) => {
    this.setState({ historyView: true, depositId: id });
  };

  closeHistory = () => {
    this.setState({ historyView: false, depositId: "" });
  };
  updateBooking = async () => {
    const { checkRequest } = this.state;
    let data = {
      allow_request: checkRequest,
    };

    let response = await ApiService.patchAPI(
      `host/booking/${booking_id}`,
      data
    );
    if (response.success) {
      showSuccess(this, response.message);
    } else {
      showError(this, "Request Failed");
    }
  };
  handleCheckRequest = (e) => {
    const { checkRequest } = this.state;

    this.setState({ checkRequest: !checkRequest }, () => this.updateBooking());
  };

  cancelSignature = async (id) => {
    this.setState({ cancelModal: true, requestId: id });
  };

  closeCancelModal = (msg) => {
    this.setState({ cancelModal: false });
    if (msg) {
      this.getBookingDetail();
      showSuccess(this, msg);
    }
  };
  openDeposit = (detail, customer) => {
    if (customer?.stripe && customer?.stripe?.is_bank) {
      this.setState({ depositSideBar: true, bookingDetail: detail });
    } else {
      showError(this, "This tenant doesn't have the active bank account")
    }
  };

  closeDeposit = (msg) => {
    this.setState({ depositSideBar: false });
    if (msg) {
      showSuccess(this, msg);
      this.getBookingDetail();
    }
  }
  displayErrorMessage = (msg) => {
    showError(this, msg);
  };
  render() {
    let {
      isLoading,
      currentActiveTab,
      bookingDetail,
      pending,
      check_in,
      check_out,
      property,
      customer,
      files,
      checkoutModal,
      room,
      toast,
      response,
      toastType,
      cancelModal,
      depositId,
      historyView,
      checkRequest,
      bank_name,
      ref,
      account_no,
      payoutStatus,
      payment_enabled,
      subCurrentActiveTab,
      enablePopup,
      isLoad,
      titles,
      booking_name,
      subPropertyActiveTab,
      notice,
      depositSideBar
    } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#012A4D" />
      </div>
    ) : (
      <>
        {/* <ToastContainer position="top-right" delay={3000} /> */}
        {toast && <ToastList message={response} type={toastType} />}
        <Container className="mt-8 card-pad-wrapper" fluid>
          <div>
            <Breadcrumb className="pad-to-8"></Breadcrumb>
          </div>
          <div>
            <Row className="align-items-center header-div">
              <Col xs="6" className="manage-con">
                <Breadcrumb className="custom_breadcrumb">
                  {" "}
                  <BreadcrumbItem
                    onClick={() => this.props.history.push(`/admin/bookings`)}
                    className="bread-cursor"
                  >
                    Bookings
                  </BreadcrumbItem>
                  <BreadcrumbItem active style={{ color: "#012a4d" }}>{(bookingDetail?.user?.name?.first) + (" ") + (bookingDetail?.user?.name?.last)}</BreadcrumbItem>
                </Breadcrumb>
              </Col>
              <Col xs="6" className="flexEnd">
                <div>
                  {bookingDetail.status === "checked_out" ||
                    bookingDetail.status === "pre_booked" ? (
                    <></>
                  ) : (
                    <>
                      {RoleBasedCRUDAccess("booking", "edit") && (
                        <Button
                          className=" button-wrapping clfff"
                          onClick={() => this.openCheckoutPopup()}
                        >
                          Check Out
                        </Button>
                      )}
                    </>
                  )}
                  <Modal
                    isOpen={checkoutModal}
                    toggle={() => this.toggleModal()}
                    centered
                    className="w450 modal-dialog-notice scheduleModal"
                  >
                    <CheckOutModal
                      close={this.toggleModal}
                      bookingId={booking_id}
                      minDate={this.state.check_in}
                      checkOutDate={this.state.check_out}
                    />
                  </Modal>
                </div>
              </Col>
            </Row>
          </div>
          <Row>
            <div className="col new-table mb-4">
              <Card className="booking_card" style={{ backgroundColor: "none !important" }}>
                <Nav tabs className="borderbtm0" style={{
                  padding: " 4px 0px",
                  border: "1px solid rgba(0, 0, 0, 0.05)", borderRadius: "5px 5px 0px 0px"
                }}>
                  <NavItem style={{ width: "8%", alignItems: "center", display: "flex", justifyContent: "center", marginLeft: "15px" }}>
                    <NavLink
                      className={classnames({ active: currentActiveTab === "1", }, "navPad")}
                      onClick={() => this.toggle("1", "Tab")}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      Booking Info
                    </NavLink>
                    {/* <img src={bookingLine} /> */}
                  </NavItem>
                  <NavItem style={{ width: "8%", alignItems: "center", display: "flex", justifyContent: "center" }}>
                    <NavLink
                      className={classnames({
                        active: currentActiveTab === "3",
                      }, "navPad")}
                      onClick={() => this.toggle("3", "Tab")}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      Invoices
                    </NavLink>
                  </NavItem>
                  <NavItem style={{ width: "8%", alignItems: "center", display: "flex", justifyContent: "center" }}>
                    <NavLink
                      className={classnames({
                        active: currentActiveTab === "2",
                      }, "navPad")}
                      onClick={() => this.toggle("2", "Tab")}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      Tenant Info
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink
                      className={classnames({
                        active: currentActiveTab === "4",
                      })}
                      onClick={() => this.toggle("4", "Tab")}
                    >
                      Basic Info
                    </NavLink>
                  </NavItem> */}
                </Nav>
                <TabContent activeTab={currentActiveTab}>
                  <TabPane tabId="1">
                    <Card
                      className="basic-book-info pad-book-top radius-0"
                    >
                      <CardBody className="subtabs-cardbody">
                        <Nav tab className="subtabs">
                          <NavItem
                            className={classnames("subtabs-navitem", {
                              activeColor: subPropertyActiveTab === "1",
                            })}
                          >
                            <NavLink
                              onClick={() => this.toggle("1", "subTab")}
                            >
                              Basic Information
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames("subtabs-navitem", {
                              activeColor: subPropertyActiveTab === "2",
                            })}
                          >
                            <NavLink
                              onClick={() => this.toggle("2", "subTab")}
                            >
                              Security Deposit
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames("subtabs-navitem", {
                              activeColor: subPropertyActiveTab === "3",
                            })}
                          >
                            <NavLink
                              onClick={() => this.toggle("3", "subTab")}
                            >
                              Documents
                            </NavLink>
                          </NavItem>
                          {bookingDetail.status === "in_notice" && notice &&
                            <NavItem
                              className={classnames("subtabs-navitem", {
                                activeColor: subPropertyActiveTab === "4",
                              })}
                            >
                              <NavLink
                                onClick={() => this.toggle("4", "subTab")}
                              >
                                Notice Info
                              </NavLink>
                            </NavItem>
                          }
                        </Nav>
                        <TabContent
                          activeTab={subPropertyActiveTab}
                          style={{ flex: "1" }}
                        >
                          <TabPane tabId="1">
                            <Col style={{ padding: "0" }}>
                              <div className="title-subtabs">
                                <span>Basic Information</span>
                              </div>
                              <div className="container-basicinfo">
                                <div style={{ width: "80%" }}>
                                  <Row className="book-row-basic mr-0 ml-0 pad30px">
                                    <label className="basic_information">
                                      Property
                                    </label>
                                    <label className="basic-data width5">
                                      :
                                    </label>
                                    <label className="basic_information1">
                                      {returnSecondDegreeObjValue(
                                        bookingDetail,
                                        "property",
                                        "name"
                                      )}
                                    </label>
                                  </Row>
                                  <Row className="book-row-basic mr-0 ml-0 pad30px">
                                    <label className="basic_information ">
                                      Booked On
                                    </label>
                                    <label className="basic-data width5 ">
                                      :
                                    </label>
                                    <label className="basic_information1">
                                      {showLocaldate(bookingDetail?.createdAt)}
                                    </label>
                                  </Row>
                                  <Row className="book-row-basic mr-0 ml-0 pad30px">
                                    <label className="basic_information ">
                                      Check-In
                                    </label>
                                    <label className="basic-data width5">
                                      :
                                    </label>
                                    <label className="basic_information1">
                                      {showLocaldate(check_in)}
                                    </label>
                                  </Row>
                                  <Row className="book-row-basic mr-0 ml-0 pad30px">
                                    <label className="basic_information ">
                                      Check-Out
                                    </label>
                                    <label className="basic-data width5">
                                      :
                                    </label>
                                    <label className="basic_information1">
                                      {showLocaldate(check_out)}
                                    </label>
                                  </Row>
                                  {
                                    room && room.room_number ?
                                      <Row className="book-row-basic mr-0 ml-0 pad30px">
                                        <label className="basic_information ">
                                          Room
                                        </label>
                                        <label className="basic-data width5">
                                          :
                                        </label>
                                        <label className="basic_information1">
                                          {returnFirstDegreeObjValue(room, "room_number")}
                                        </label>
                                      </Row> : <></>
                                  }
                                  <Row className="book-row-basic mr-0 ml-0 pad30px">
                                    <label className="basic_information ">
                                      Status
                                    </label>
                                    <label className="basic-data width5">
                                      :
                                    </label>
                                    <label className="basic_information1"
                                      style={{ color: bookingStatusColor(bookingDetail?.status) }}
                                    >
                                      {bookingDetail && bookingStatus(bookingDetail.status)}
                                    </label>
                                  </Row>
                                  <Row className="book-row-basic mr-0 ml-0 pad30px">
                                    <label className="basic_information flex">
                                      <div className="fw-500  f14 cl757575 request_div lh-14p">
                                        <span>Auto Request</span>
                                        <div
                                          data-tip="Allow Tenants directly to assign a service request to Service Pros"
                                          data-iscapture="true"
                                          data-for="toolTip1"
                                          data-place="top"
                                          className="request_tooltip"
                                        >
                                          <i className="fa fa-info-circle tertiary-circle"></i>
                                        </div>
                                        <ReactTooltip
                                          place="right"
                                          type="info"
                                          effect="solid"
                                          id="toolTip1"
                                          html={true}
                                        />
                                      </div>
                                    </label>
                                    <label className="basic-data width5">
                                      :
                                    </label>
                                    <div className="fw-500 f14 cl374957 point lh-14p">
                                      <Input
                                        className="selectAllAmenity m-0"
                                        checked={checkRequest}
                                        onChange={this.handleCheckRequest}
                                        style={{
                                          height: "15px",
                                          width: "15px",
                                        }}
                                        placeholder="Rules"
                                        type="checkbox"
                                        disabled={
                                          RoleBasedCRUDAccess("booking", "edit")
                                            ? false
                                            : true
                                        }
                                      />
                                    </div>
                                  </Row>
                                  <Row className="book-row-basic mr-0 ml-0 pad30px">
                                    <label className="basic_information ">
                                      <div className="fw-500  f14 cl757575 mb-12 request_div lh-14p">
                                        <span>Screening</span>
                                        <div
                                          data-tip="Tenant screening status"
                                          data-iscapture="true"
                                          data-for="toolTip1"
                                          data-place="top"
                                          className="request_tooltip"
                                        >
                                          <i className="fa fa-info-circle tertiary-circle"></i>
                                        </div>
                                        <ReactTooltip
                                          place="right"
                                          type="info"
                                          effect="solid"
                                          id="toolTip1"
                                          html={true}
                                        />
                                      </div>
                                    </label>
                                    <label className="basic-data width5">
                                      :
                                    </label>
                                    <label className="basic_information1">
                                      <div
                                        className="fw-500 f14  lh-14p caps"
                                        style={{
                                          color:
                                            bookingDetail?.screening?.status ===
                                              "completed"
                                              ? "green"
                                              : bookingDetail?.screening
                                                ?.status === "pending"
                                                ? "#ffc043"
                                                : "#374957",
                                        }}
                                      >
                                        {bookingDetail?.screening?.status
                                          ? bookingDetail?.screening?.status
                                          : "N/A"}
                                      </div>
                                    </label>
                                  </Row>
                                  <Row className="book-row-basic mr-0 ml-0 pad30px">
                                    <label className="basic_information ">
                                      Pending Invoice Amount
                                    </label>
                                    <label className="basic-data width5">
                                      :
                                    </label>
                                    <label className="basic_information1">
                                      {displayPrice(
                                        returnFirstDegreeObjValue(
                                          pending,
                                          "total"
                                        )
                                      )}
                                    </label>
                                  </Row>
                                </div>
                                <div className="property-tab-image mr-20 ">
                                  {property?.gallery.length > 0 ? (
                                    <img
                                      src={getCloudinaryImage(
                                        100,
                                        100,
                                        property.gallery[0],
                                        "c_fill"
                                      )}
                                      alt="porperty-image"
                                      className="tab-prop-img"
                                    />
                                  ) : (
                                    <img
                                      src={DefaultProperty}
                                      alt="porperty-image"
                                      className="tab-prop-img"
                                    />
                                  )}
                                </div>
                              </div>
                            </Col>
                          </TabPane>
                          <TabPane tabId="2">
                            <Col style={{ padding: "0" }}>
                              <div className="title-subtabs flex">
                                <span>Security Deposit</span>
                                {refundSecurityValidation(bookingDetail) &&
                                  <Button
                                    color="primary"
                                    size="sm"
                                    className="button-wrapping search-wrapper"
                                    onClick={() => this.openDeposit(bookingDetail, customer)}
                                  >
                                    Refund Deposit
                                  </Button>}
                              </div>
                              <div className="container-Kycinfo">
                                <div style={{ width: "100%" }}>
                                  <Col className="container-basicinfo">
                                    <Col className="KYC_Docs1">
                                      <Col style={{ paddingLeft: "0" }}>
                                        <Row className="book-row-basic mr-0 ml-0 pad30px">
                                          <label className="basic_information2">
                                            Overall Amount
                                          </label>
                                          <label className="basic-data width5">
                                            :
                                          </label>
                                          <label className="basic_information1">
                                            {displayPrice(
                                              returnSecondDegreeObjValue(
                                                bookingDetail,
                                                "deposit",
                                                "amount"
                                              )
                                            )}
                                          </label>
                                        </Row>
                                        <Row className="book-row-basic mr-0 ml-0 pad30px">
                                          <label className="basic_information2 ">
                                            Paid Amount
                                          </label>
                                          <label className="basic-data width5 ">
                                            :
                                          </label>
                                          <label className="basic_information1">
                                            {displayAmount(returnPaidDeposit(bookingDetail.deposit))}
                                          </label>
                                        </Row>
                                        <Row className="book-row-basic mr-0 ml-0 pad30px">
                                          <label className="basic_information2 ">
                                            Pending Amount
                                          </label>
                                          <label className="basic-data width5">
                                            :
                                          </label>
                                          <label className="basic_information1">
                                            {displayPrice(
                                              returnSecondDegreeObjValue(
                                                bookingDetail,
                                                "deposit",
                                                "pending_amount"
                                              )
                                            )}
                                          </label>
                                        </Row>
                                        <Row className="book-row-basic mr-0 ml-0 pad30px">
                                          <label className="basic_information2 ">
                                            Processing Amount
                                          </label>
                                          <label className="basic-data width5">
                                            :
                                          </label>
                                          <label className="basic_information1">
                                            {displayPrice(
                                              returnSecondDegreeObjValue(
                                                bookingDetail,
                                                "processing_amount"
                                              )
                                            )}
                                          </label>
                                        </Row>
                                        <Row className="book-row-basic mr-0 ml-0 pad30px">
                                          <label className="basic_information2 ">
                                            History
                                          </label>
                                          <label className="basic-data width5">
                                            :
                                          </label>
                                          <label className="basic_information1">
                                            <div
                                              className="fw-500 f14 cl374957 point lh-14p"
                                              onClick={
                                                RoleBasedCRUDAccess("deposit", "view")
                                                  ? () =>
                                                    this.displayHistory(
                                                      bookingDetail.deposit?.id
                                                    )
                                                  : ""
                                              }
                                            >
                                              View
                                            </div>
                                          </label>
                                        </Row>
                                      </Col>
                                    </Col>
                                  </Col>
                                </div>
                              </div>
                            </Col>
                          </TabPane>
                          <TabPane tabId="3">
                            <ListingHeaderLayout label={"Documents"}>
                              <div className="align-items-center">
                                <div className="text-right ">
                                  <>
                                    {" "}
                                    {bookingDetail.status === "pre_booked" ||
                                      bookingDetail.status === "checked_out" ? (
                                      ""
                                    ) : (
                                      <div
                                      // onClick={() =>
                                      //   this.redirectAddDocumentPage(booking_id)
                                      // }
                                      >
                                        {RoleBasedCRUDAccess("booking", "edit") && (
                                          <>
                                            <label
                                              className="mb-0 mr-3 cursor-point"
                                              onClick={() =>
                                                this.redirectAddDocumentPage(booking_id)
                                              }
                                            >
                                              <img
                                                src={addDoc}
                                                alt="Add Doc"
                                                className="add-Doc-img"
                                              />
                                            </label>
                                            <label
                                              className="add-doc mb-0 cursor-point"
                                              onClick={() =>
                                                this.redirectAddDocumentPage(booking_id)
                                              }
                                            >
                                              Add Document
                                            </label>
                                          </>
                                        )}
                                      </div>
                                    )}
                                  </>
                                </div>
                              </div>
                            </ListingHeaderLayout>
                            {files.length !== 0 ? (
                              <>
                                <div className="flexWrap">
                                  {files.map((file, i) => (
                                    <div className="documentCard" key={i}>
                                      <div className="flex mb-12">
                                        <span style={{ fontWeight: "600" }}>
                                          {returnFirstDegreeObjValue(file, "name")}
                                        </span>
                                        <span className="justCenter">{RoleBasedCRUDAccess("booking", "edit") &&
                                          returnBookingDocumentOverflowView(
                                            this,
                                            file
                                          )}
                                        </span>
                                      </div>
                                      <div className="flex">
                                        <span className="f13">{returnBookingDocumentStatus(file, bookingDetail?.manager?.id)}</span>
                                        <span style={{ marginLeft: "24px", fontSize: "13px" }}>{showLocaldate(
                                          returnFirstDegreeObjValue(file, "createdAt"))}
                                        </span>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </>
                            ) : (<div className="no-data font-book">
                              {" "}
                              No Documents Available
                            </div>)}
                          </TabPane>
                          <TabPane tabId="4">
                            <Col style={{ padding: "0" }}>
                              <div className="title-subtabs">
                                <span>Notice Info</span>
                              </div>
                              {notice && <div className="container-Kycinfo">
                                <div style={{ width: "100%" }}>
                                  <Col className="container-basicinfo">
                                    <Col className="KYC_Docs1">
                                      <Col style={{ paddingLeft: "0" }}>
                                        <Row className="book-row-basic mr-0 ml-0 pad30px">
                                          <label className="basic_information2">
                                            Created On
                                          </label>
                                          <label className="basic-data width5">
                                            :
                                          </label>
                                          <label className="basic_information1">
                                            {showLocaldate(returnFirstDegreeObjValue(notice, "createdAt"))}
                                          </label>
                                        </Row>
                                        <Row className="book-row-basic mr-0 ml-0 pad30px">
                                          <label className="basic_information2 ">
                                            Check Out Date
                                          </label>
                                          <label className="basic-data width5 ">
                                            :
                                          </label>
                                          <label className="basic_information1">
                                            {showLocaldate(returnFirstDegreeObjValue(notice, "notice_date"))}
                                          </label>
                                        </Row>
                                        <Row className="book-row-basic mr-0 ml-0 pad30px">
                                          <label className="basic_information2 ">
                                            Actual Notice Ends
                                          </label>
                                          <label className="basic-data width5 ">
                                            :
                                          </label>
                                          <label className="basic_information1">
                                            {showLocaldate(returnFirstDegreeObjValue(notice, "act_date"))}
                                          </label>
                                        </Row>
                                        <Row className="book-row-basic mr-0 ml-0 pad30px">
                                          <label className="basic_information2 ">
                                            Notice Period Payment
                                          </label>
                                          <label className="basic-data width5 ">
                                            :
                                          </label>
                                          <label className="basic_information1">
                                            {displayPrice(returnFirstDegreeObjValue(notice, "balance_amt"))}
                                          </label>
                                        </Row>
                                        <Row className="book-row-basic mr-0 ml-0 pad30px">
                                          <label className="basic_information2 ">
                                            Pending Invoice Amount
                                          </label>
                                          <label className="basic-data width5 ">
                                            :
                                          </label>
                                          <label className="basic_information1">
                                            {displayPrice(returnFirstDegreeObjValue(pending, "total"))}
                                          </label>
                                        </Row>
                                        <Row className="book-row-basic mr-0 ml-0 pad30px">
                                          <label className="basic_information2 ">
                                            Use deposit for balance payment
                                          </label>
                                          <label className="basic-data width5 ">
                                            :
                                          </label>
                                          <label className="basic_information1">
                                            {notice.use_deposit ? "Yes" : "No"}
                                          </label>
                                        </Row>
                                        <Row className="book-row-basic mr-0 ml-0 pad30px">
                                          <label className="basic_information2 ">
                                            Notice Status
                                          </label>
                                          <label className="basic-data width5">
                                            :
                                          </label>
                                          <span className={`${notice.status === "accepted"
                                            ? "text-capitalize green general_status_style"
                                            : notice.status === "cancelled" ||
                                              notice.status === "rejected"
                                              ? "text-capitalize red general_status_style"
                                              : "text-capitalize yellow general_status_style"
                                            }basic_information1`}
                                          >
                                            {returnFirstDegreeObjValue(notice, "status")}
                                          </span>
                                        </Row>
                                        <Row className="book-row-basic mr-0 ml-0 pad30px">
                                          <label className="basic_information2 ">
                                            Reason
                                          </label>
                                          <label className="basic-data width5">
                                            :
                                          </label>
                                          <label className="basic_information1">
                                            {returnFirstDegreeObjValue(notice, "reason")}
                                          </label>
                                        </Row>
                                        {/* <Row className="book-row-basic mr-0 ml-0 pad30px">
                                          <label className="basic_information2 ">
                                            History
                                          </label>
                                          <label className="basic-data width5">
                                            :
                                          </label>
                                          <label className="basic_information1">
                                            <div
                                              className="fw-500 f14 cl374957 point lh-14p"
                                              onClick={
                                                RoleBasedCRUDAccess("deposit", "view")
                                                  ? () =>
                                                    this.displayHistory(
                                                      bookingDetail.deposit?.id
                                                    )
                                                  : ""
                                              }
                                            >
                                              View
                                            </div>
                                          </label>
                                        </Row> */}
                                      </Col>
                                    </Col>
                                  </Col>
                                </div>
                              </div>
                              }
                            </Col>
                          </TabPane>
                        </TabContent>
                      </CardBody>
                    </Card>
                  </TabPane>
                  {/* <TabPane tabId="2">
                      <div className="flex-card-book">
                        <div className="image-card-book">
                          <div className="wrap-card-book">
                            {customer?.image ? (
                              <img
                                src={getCloudinaryImage(
                                  130,
                                  130,
                                  customer?.image,
                                  "c_fill"
                                )}
                                alt="profile image"
                                className="profile-book-img mb-4"
                              />
                            ) : (
                              <img
                                src={DefaultUser}
                                alt="profile image"
                                className="profile-book-img mb-4"
                              />
                            )}

                            <h3 className="mb-0 name-book">
                              {" "}
                              {returnSecondDegreeObjValue(
                                customer,
                                "name",
                                "first"
                              )}{" "}
                              {returnSecondDegreeObjValue(
                                customer,
                                "name",
                                "last"
                              )}
                            </h3>
                          </div>
                        </div>
                        <div className="info-book">
                          <div
                            className="col new-table profile-book-base"
                            style={{ paddingRight: "0px" }}
                          >
                            <Card className="basic-book-info">
                              <CardHeader className="border-0">
                                <Row className="align-items-center ml-7 ">
                                  <h3 className="mb-0">Basic Information</h3>
                                </Row>
                              </CardHeader>
                              <CardBody className="new-card-book">
                                <Form>
                                  <div>
                                    <Row className="book-row-basic mr-0">
                                      <label className="basic-data ">
                                        Name
                                      </label>
                                      <label className="basic-info-name">
                                        {returnSecondDegreeObjValue(
                                          customer,
                                          "name",
                                          "first"
                                        )}{" "}
                                        {returnSecondDegreeObjValue(
                                          customer,
                                          "name",
                                          "last"
                                        )}
                                      </label>
                                    </Row>
                                    <Row className="book-row-basic mr-0">
                                      <label className="basic-data ">
                                        Gender
                                      </label>
                                      <label className="basic-info-name">
                                        {returnFirstDegreeObjValue(
                                          customer,
                                          "gender"
                                        )}
                                      </label>
                                    </Row>
                                    <Row className="book-row-basic mr-0">
                                      <label className="basic-data ">
                                        Contact Number
                                      </label>
                                      <label className="basic-info-name">
                                        {getUSFormat(
                                          returnSecondDegreeObjValue(
                                            customer,
                                            "phone",
                                            "national_number"
                                          )
                                        )}
                                      </label>
                                    </Row>
                                    <Row className="book-row-basic mr-0">
                                      <label className="basic-data ">
                                        Email{" "}
                                      </label>
                                      <label className="basic-info-name">
                                        {returnFirstDegreeObjValue(
                                          customer,
                                          "email"
                                        )}
                                      </label>
                                    </Row>
                                    <Row className="book-row-basic mr-0">
                                      <label className="basic-data ">
                                        Pets
                                      </label>
                                      <label className="basic-info-name">
                                        {customer?.pets?.length > 0
                                          ? customer?.pets?.map(
                                              (pet, i) =>
                                                (i ? "," : "") + " " + pet
                                            )
                                          : "-"}
                                      </label>
                                    </Row>
                                    <Row className="book-row-basic mr-0">
                                      <label className="basic-data ">
                                        Payments Enabled
                                      </label>
                                      {!customer?.is_pay_disabled ||
                                      customer?.is_pay_disabled === false ? (
                                        <lable className="basic-info-name">
                                          Enabled
                                        </lable>
                                      ) : (
                                        <CustomInput
                                          type="switch"
                                          checked={
                                            customer.is_pay_disabled === true
                                              ? false
                                              : true
                                          }
                                          id={booking_id}
                                          onChange={() =>
                                            this.updateConfirmPoppop()
                                          }
                                        />
                                      )}
                                    </Row>
                                  </div>
                                </Form>
                              </CardBody>
                            </Card>
                          </div>
                        </div>
                      </div>
                      <Row className="align-items-center header-div-book mt-o pad-book-top mt_20">
                        <Col xs="8" className="manage-con">
                          <h3 className="mb-0 managers-crumb">Documents</h3>
                        </Col>
                      </Row>
                      <Row className="mb_30">
                        <div className="col new-table">
                          <Card className="basic-book-info">
                            <Table
                              className="align-items-center table-flush"
                              responsive
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col">Type</th>
                                  <th scope="col">Start Date</th>
                                  <th scope="col">Expiry Date</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>
                            </Table>
                            <Card className="basic-book-info">
                              <div className="no-data font-book">
                                {" "}
                                No Documents Available
                              </div>
                            </Card>
                          </Card>
                        </div>
                      </Row>
                      <Card className="basic-book-info mb_30">
                        <CardHeader className="border-0">
                          <Row className="align-items-center">
                            <Col xs="12">
                              <h3 className="mb-0">Professional Information</h3>
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody className="new-card-book content-pad-book">
                          <Form>
                            <div>
                              <Row>
                                <Col lg="6">
                                  <FormGroup>
                                    <label
                                      className=" basic-data mb-2"
                                      htmlFor="input-username"
                                    >
                                      Current Employer
                                    </label>
                                    <h4 className="basic-info-name book-name-font">
                                      {returnSecondDegreeObjValue(
                                        customer,
                                        "professional_details",
                                        "current_emp"
                                      )}
                                    </h4>
                                  </FormGroup>
                                </Col>

                                <Col lg="6">
                                  <FormGroup>
                                    <label
                                      className="basic-data mb-2"
                                      htmlFor="input-email"
                                    >
                                      Current Client
                                    </label>
                                    <h4 className="basic-info-name book-name-font">
                                      {returnSecondDegreeObjValue(
                                        customer,
                                        "professional_details",
                                        "current_client"
                                      )}
                                    </h4>
                                  </FormGroup>
                                </Col>
                                <Col lg="6" className="pad-book-phone">
                                  <FormGroup>
                                    <label
                                      className=" basic-data mb-2"
                                      htmlFor="input-email"
                                    >
                                      Job Title
                                    </label>
                                    <h4 className="basic-info-name book-name-font">
                                      {returnSecondDegreeObjValue(
                                        customer,
                                        "professional_details",
                                        "job_title"
                                      )}
                                    </h4>
                                  </FormGroup>
                                </Col>
                                <Col lg="6" className="pad-book-phone">
                                  <FormGroup>
                                    <label
                                      className=" basic-data mb-2"
                                      htmlFor="input-email"
                                    >
                                      Tecnology stack
                                    </label>
                                    <h4 className="basic-info-name book-name-font">
                                      {returnSecondDegreeObjValue(
                                        customer,
                                        "professional_details",
                                        "tech_stack"
                                      )}
                                    </h4>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </div>
                          </Form>
                        </CardBody>
                      </Card>
                      <Card className="basic-book-info pad-book-top mb_30">
                        <CardHeader className="border-0">
                          <Row className="align-items-center">
                            <Col xs="12">
                              <h3 className="mb-0">Additional Information</h3>
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody className="new-card-book content-pad-book">
                          <Form>
                            <div>
                              <Row>
                                <Col lg="6">
                                  <FormGroup>
                                    <label
                                      className=" basic-data mb-2"
                                      htmlFor="input-username"
                                    >
                                      Hobbies and Interest
                                    </label>
                                    <p className="basic-info-name book-name-font">
                                      {returnSecondDegreeObjValue(
                                        customer,
                                        "additional_info",
                                        "hobbies"
                                      )}
                                    </p>
                                  </FormGroup>
                                </Col>

                                <Col lg="6">
                                  <FormGroup>
                                    <label
                                      className=" basic-data mb-2"
                                      htmlFor="input-email"
                                    >
                                      Health Condition to be aware of
                                    </label>
                                    <p className="basic-info-name book-name-font">
                                      {returnSecondDegreeObjValue(
                                        customer,
                                        "additional_info",
                                        "health_con"
                                      )}
                                    </p>
                                  </FormGroup>
                                </Col>
                                <Col lg="6" className="pad-book-phone">
                                  <FormGroup>
                                    <label
                                      className=" basic-data mb-2"
                                      htmlFor="input-email"
                                    >
                                      Language
                                    </label>
                                    <h4 className="basic-info-name book-name-font">
                                      {returnSecondDegreeObjValue(
                                        customer,
                                        "additional_info",
                                        "lang"
                                      )}
                                    </h4>
                                  </FormGroup>
                                </Col>
                                <Col lg="6" className="pad-book-phone">
                                  <FormGroup>
                                    <label
                                      className=" basic-data mb-2"
                                      htmlFor="input-email"
                                    >
                                      Food Preferences
                                    </label>
                                    <h4 className="basic-info-name book-name-font">
                                      {returnSecondDegreeObjValue(
                                        customer,
                                        "additional_info",
                                        "food_preference"
                                      )}
                                    </h4>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </div>
                          </Form>
                        </CardBody>
                      </Card>
                      <Card className="basic-book-info pad-book-top mb-30">
                        <CardHeader className="border-0">
                          <Row className="align-items-center">
                            <Col xs="12">
                              <h3 className="mb-0">Emergency Details</h3>
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody className="new-card-book content-pad-book">
                          <Form>
                            <div>
                              <Row>
                                <Col lg="6">
                                  <FormGroup>
                                    <label
                                      className=" basic-data mb-2"
                                      htmlFor="input-username"
                                    >
                                      Full Name
                                    </label>
                                    <h4 className="basic-info-name book-name-font text-capitalize">
                                      {returnSecondDegreeObjValue(
                                        customer,
                                        "emergency_contact",
                                        "name"
                                      )}
                                    </h4>
                                  </FormGroup>
                                </Col>

                                <Col lg="6">
                                  <FormGroup>
                                    <label
                                      className=" basic-data mb-2"
                                      htmlFor="input-email"
                                    >
                                      Contact Number
                                    </label>
                                    <h4 className="basic-info-name book-name-font">
                                      {getUSFormat(
                                        returnThirdDegreeObjValue(
                                          customer,
                                          "emergency_contact",
                                          "phone",
                                          "national_number"
                                        ).toString()
                                      )}
                                    </h4>
                                  </FormGroup>
                                </Col>
                                <Col lg="6" className="pad-book-phone">
                                  <FormGroup>
                                    <label
                                      className=" basic-data mb-2"
                                      htmlFor="input-email"
                                    >
                                      Relationship
                                    </label>
                                    <h4 className="basic-info-name book-name-font">
                                      {returnSecondDegreeObjValue(
                                        customer,
                                        "emergency_contact",
                                        "relationship"
                                      )}
                                    </h4>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </div>
                          </Form>
                        </CardBody>
                      </Card>
                      {customer?.stripe?.is_bank === true && (
                        <Card className="basic-book-info pad-book-top mb-30">
                          <CardHeader className="border-0">
                            <Row className="align-items-center">
                              <Col xs="12">
                                <h3 className="mb-0">Account Details</h3>
                              </Col>
                            </Row>
                          </CardHeader>
                          <CardBody className="new-cards">
                            <Form>
                              <div>
                                <Row>
                                  <Col lg="4">
                                    <FormGroup>
                                      <label
                                        className="form-control-label"
                                        htmlFor="input-username"
                                      >
                                        Bank Name
                                      </label>

                                      <Input
                                        className="form-control-alternative bank-text"
                                        value={bank_name}
                                        name="bank_name"
                                        placeholder="Bank Name"
                                        type="text"
                                        readOnly
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col lg="4">
                                    <FormGroup>
                                      <label
                                        className="form-control-label"
                                        htmlFor="input-username"
                                      >
                                        Account No
                                      </label>

                                      <Input
                                        className="form-control-alternative bank-text"
                                        value={account_no}
                                        name="account_no"
                                        placeholder="Bank Name"
                                        type="text"
                                        readOnly
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col lg="4">
                                    <FormGroup>
                                      <label
                                        className="form-control-label"
                                        htmlFor="input-email"
                                      >
                                        Route Number
                                      </label>

                                      <Input
                                        className="form-control-alternative bank-text"
                                        value={ref}
                                        name="ref"
                                        id="input-email"
                                        placeholder="Reference No"
                                        type="email"
                                        readOnly
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col lg="4">
                                    <FormGroup>
                                      <label
                                        className="form-control-label"
                                        htmlFor="input-email"
                                      >
                                        Payout Status
                                      </label>

                                      <Input
                                        className={
                                          "form-control-alternative bank-text"
                                        }
                                        value={payoutStatus}
                                        name="payoutStatus"
                                        id="input-email"
                                        placeholder="Payout Status"
                                        type="email"
                                        readOnly
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </div>
                            </Form>
                          </CardBody>
                        </Card>
                      )}
                    </TabPane> */}
                  <TabPane tabId="2">
                    <Card
                      className="basic-book-info pad-book-top"
                    >
                      <CardBody className="subtabs-cardbody">
                        <Nav tab className="subtabs">
                          <NavItem
                            className={classnames("subtabs-navitem", {
                              activeColor: subCurrentActiveTab === "1",
                            })}
                          >
                            <NavLink
                              onClick={() => this.toggle("1", "subTab")}
                            >
                              Basic Information
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames("subtabs-navitem", {
                              activeColor: subCurrentActiveTab === "2",
                            })}
                          >
                            <NavLink
                              onClick={() => this.toggle("2", "subTab")}
                            >
                              KYC & Bank details
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames("subtabs-navitem", {
                              activeColor: subCurrentActiveTab === "3",
                            })}
                          >
                            <NavLink
                              onClick={() => this.toggle("3", "subTab")}
                            >
                              Additional Information
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames("subtabs-navitem", {
                              activeColor: subCurrentActiveTab === "4",
                            })}
                          >
                            <NavLink
                              onClick={() => this.toggle("4", "subTab")}
                            >
                              Emergency contact
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames("subtabs-navitem", {
                              activeColor: subCurrentActiveTab === "5",
                            })}
                          >
                            <NavLink
                              onClick={() => this.toggle("5", "subTab")}
                            >
                              Additional occupants
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames("subtabs-navitem", {
                              activeColor: subCurrentActiveTab === "6",
                            })}
                          >
                            <NavLink
                              onClick={() => this.toggle("6", "subTab")}
                            >
                              Landlord reference
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames("subtabs-navitem", {
                              activeColor: subCurrentActiveTab === "7",
                            })}
                          >
                            <NavLink
                              onClick={() => this.toggle("7", "subTab")}
                            >
                              Employer reference
                            </NavLink>
                          </NavItem>
                        </Nav>
                        <TabContent
                          activeTab={subCurrentActiveTab}
                          style={{ flex: "1" }}
                        >
                          <TabPane tabId="1">
                            <Col style={{ padding: "0" }}>
                              <div className="title-subtabs">
                                <span>Basic Information</span>
                              </div>
                              <div className="container-basicinfo">
                                <div style={{ width: "80%" }}>
                                  <Row className="book-row-basic mr-0 ml-0 pad30px">
                                    <label className="basic_information">
                                      First Name
                                    </label>
                                    <label className="basic-data width5">
                                      :
                                    </label>
                                    <label className="basic_information1">
                                      {returnSecondDegreeObjValue(
                                        customer,
                                        "name",
                                        "first"
                                      )}
                                    </label>
                                  </Row>
                                  <Row className="book-row-basic mr-0 ml-0 pad30px">
                                    <label className="basic_information ">
                                      Last Name
                                    </label>
                                    <label className="basic-data width5 ">
                                      :
                                    </label>
                                    <label className="basic_information1">
                                      {returnSecondDegreeObjValue(
                                        customer,
                                        "name",
                                        "last"
                                      )}
                                    </label>
                                  </Row>
                                  <Row className="book-row-basic mr-0 ml-0 pad30px">
                                    <label className="basic_information ">
                                      Gender
                                    </label>
                                    <label className="basic-data width5">
                                      :
                                    </label>
                                    <label className="basic_information1">
                                      {returnFirstDegreeObjValue(
                                        customer,
                                        "gender"
                                      )}
                                    </label>
                                  </Row>
                                  <Row className="book-row-basic mr-0 ml-0 pad30px">
                                    <label className="basic_information ">
                                      Mobile Number
                                    </label>
                                    <label className="basic-data width5">
                                      :
                                    </label>
                                    <label className="basic_information1">
                                      {getUSFormat(
                                        returnSecondDegreeObjValue(
                                          customer,
                                          "phone",
                                          "national_number"
                                        )
                                      )}
                                    </label>
                                  </Row>
                                  <Row className="book-row-basic mr-0 ml-0 pad30px">
                                    <label className="basic_information ">
                                      Email
                                    </label>
                                    <label className="basic-data width5">
                                      :
                                    </label>
                                    <label className="basic_information1">
                                      {returnFirstDegreeObjValue(
                                        customer,
                                        "email"
                                      )}
                                    </label>
                                  </Row>
                                  <Row className="book-row-basic mr-0 ml-0 pad30px">
                                    <label className="basic_information ">
                                      Date of birth
                                    </label>
                                    <label className="basic-data width5">
                                      :
                                    </label>
                                    <label className="basic_information1">
                                      {customer?.dob
                                        ? showUsFormat(
                                          returnFirstDegreeObjValue(
                                            customer,
                                            "dob"
                                          )
                                        )
                                        : "-"}
                                    </label>
                                  </Row>
                                  <Row className="book-row-basic mr-0 ml-0 pad30px">
                                    <label className="basic_information ">
                                      Nationality
                                    </label>
                                    <label className="basic-data width5">
                                      :
                                    </label>
                                    <label className="basic_information1">
                                      {returnSecondDegreeObjValue(
                                        customer,
                                        "extra",
                                        "nationality"
                                      )}
                                    </label>
                                  </Row>
                                  <Row className="book-row-basic mr-0 ml-0 pad30px">
                                    <label className="basic_information ">
                                      Current address
                                    </label>
                                    <label className="basic-data width5">
                                      :
                                    </label>
                                    <label className="basic_information1">
                                      {returnThirdDegreeObjValue(
                                        customer,
                                        "extra",
                                        "address",
                                        "line1"
                                      )}
                                    </label>
                                  </Row>
                                  <Row className="book-row-basic mr-0 ml-0 pad30px">
                                    <label className="basic_information ">
                                      Occupation
                                    </label>
                                    <label className="basic-data width5">
                                      :
                                    </label>
                                    <label className="basic_information1">
                                      {returnSecondDegreeObjValue(
                                        customer,
                                        "professional_details",
                                        "job_title"
                                      )}
                                    </label>
                                  </Row>
                                  <Row className="book-row-basic mr-0 ml-0 pad30px">
                                    <label className="basic_information ">
                                      Pets
                                    </label>
                                    <label className="basic-data width5">
                                      :
                                    </label>
                                    <label className="basic_information1">
                                      {customer?.pets?.length > 0
                                        ? customer?.pets?.map(
                                          (pet, i) =>
                                            (i ? "," : "") + " " + pet
                                        )
                                        : "-"}
                                    </label>
                                  </Row>
                                  <Row className="book-row-basic mr-0 ml-0 pad30px">
                                    <label className="basic_information ">
                                      Are you a smoker
                                    </label>
                                    <label className="basic-data width5">
                                      :
                                    </label>
                                    <label className="basic_information1">
                                      {customer?.extra?.is_smoker
                                        ? "Yes"
                                        : "No"}
                                    </label>
                                  </Row>
                                  <Row className="book-row-basic mr-0 ml-0 pad30px">
                                    <label className="basic_information ">
                                      Payments Enabled
                                    </label>
                                    <label className="basic-data width5">
                                      :
                                    </label>

                                    {!customer?.is_pay_disabled ||
                                      customer?.is_pay_disabled === false ? (
                                      <lable className="basic_information1">
                                        Enabled
                                      </lable>
                                    ) : (
                                      <CustomInput
                                        type="switch"
                                        checked={
                                          customer.is_pay_disabled === true
                                            ? false
                                            : true
                                        }
                                        id={booking_id}
                                        onChange={() =>
                                          this.updateConfirmPoppop()
                                        }
                                      />
                                    )}
                                  </Row>
                                </div>
                                <div>
                                  <img
                                    src={
                                      customer?.image
                                        ? getCloudinaryImage(
                                          120,
                                          110,
                                          customer?.image,
                                          "c_fill"
                                        )
                                        : DefaultUser
                                    }
                                    alt="profile image"
                                    className="customer-img-style"
                                  />
                                </div>
                              </div>
                            </Col>
                          </TabPane>
                          <TabPane tabId="2">
                            <Col style={{ padding: "0" }}>
                              <div className="title-subtabs">
                                <span>KYC Details</span>
                              </div>
                              <div className="container-Kycinfo">
                                <div style={{ width: "100%" }}>
                                  {customer?.extra && (
                                    <Col className="KYC_Docs">
                                      <Col className="KYC_Docs1">
                                        <Row className="mr-0 ml-0 Aadhar-det">
                                          <label className=" basic-data caps">
                                            {customer?.extra?.kyc?.proof_one?.p_type.replace(
                                              /_/g,
                                              " "
                                            )}
                                          </label>
                                          <label className="basic-info-name ">
                                            {`ID ${customer?.extra?.kyc?.proof_one?.number}`}
                                          </label>
                                        </Row>
                                        <div className="KyC_Doc_images">
                                          {customer?.extra?.kyc?.proof_one?.images.map(
                                            (image, index) =>
                                              image?.public_id !== null && (
                                                <img
                                                  key={index}
                                                  src={
                                                    image
                                                      ? getCloudinaryImage(
                                                        130,
                                                        80,
                                                        image,
                                                        "c_fill"
                                                      )
                                                      : DefaultUser
                                                  }
                                                  className="br-5 imgwh"
                                                  alt="proof image"
                                                  style={{
                                                    marginRight: "20px",
                                                  }}
                                                />
                                              )
                                          )}
                                        </div>
                                      </Col>
                                      <Col className="KYC_Docs1">
                                        <Row className="mr-0 ml-0 Aadhar-det">
                                          <label className=" basic-data caps">
                                            {customer?.extra?.kyc?.proof_two?.p_type.replace(/_/g, " ")}
                                          </label>
                                          <label className="basic-info-name">
                                            {`ID ${customer?.extra?.kyc?.proof_two?.number}`}
                                          </label>
                                        </Row>
                                        <div className="KyC_Doc_images">
                                          {customer?.extra?.kyc?.proof_two?.images.map(
                                            (image, index) =>
                                              image?.public_id !== null && (
                                                <img
                                                  key={index}
                                                  src={
                                                    image
                                                      ? getCloudinaryImage(
                                                        130,
                                                        80,
                                                        image,
                                                        "c_fill"
                                                      )
                                                      : DefaultUser
                                                  }
                                                  className="br-5 imgwh"
                                                  alt="proof image"
                                                  style={{
                                                    marginRight: "20px",
                                                  }}
                                                />
                                              )
                                          )}
                                        </div>
                                      </Col>
                                    </Col>
                                  )}

                                  <Col className="pad30p">
                                    <label className=" basic-data padb20p">
                                      Bank details
                                    </label>
                                    <Row className="book-row-basic mr-0 ml-0 pad30px">
                                      <label className="basic_information2">
                                        Bank name
                                      </label>
                                      <label className="basic-data width5">
                                        :
                                      </label>
                                      <label className="basic_information1">
                                        {returnSecondDegreeObjValue(
                                          customer,
                                          "stripe",
                                          "name"
                                        )}
                                      </label>
                                    </Row>
                                    <Row className="book-row-basic mr-0 ml-0 pad30px">
                                      <label className="basic_information2 ">
                                        Account number
                                      </label>
                                      <label className="basic-data width5 ">
                                        :
                                      </label>
                                      <label className="basic_information1">
                                        {returnSecondDegreeObjValue(
                                          customer,
                                          "stripe",
                                          "ac_number"
                                        )}
                                      </label>
                                    </Row>
                                    <Row className="book-row-basic mr-0 ml-0 pad30px">
                                      <label className="basic_information2 ">
                                        Payout status
                                      </label>
                                      <label className="basic-data width5">
                                        :
                                      </label>
                                      <label className="basic_information1">
                                        {customer?.stripe?.payout_enabled
                                          ? "Enabled"
                                          : "Disabled"}
                                      </label>
                                    </Row>
                                    <Row className="book-row-basic mr-0 ml-0 pad30px">
                                      <label className="basic_information2 ">
                                        Routing number
                                      </label>
                                      <label className="basic-data width5">
                                        :
                                      </label>
                                      <label className="basic_information1">
                                        {returnSecondDegreeObjValue(
                                          customer,
                                          "stripe",
                                          "route_number"
                                        )}
                                      </label>
                                    </Row>
                                  </Col>
                                </div>
                              </div>
                            </Col>
                          </TabPane>
                          <TabPane tabId="3">
                            <Col style={{ padding: "0" }}>
                              <div className="title-subtabs">
                                <span>Additional Information</span>
                              </div>
                              <div className="container-Kycinfo">
                                <div style={{ width: "100%" }}>
                                  <Col className="container-basicinfo">
                                    <Col className="KYC_Docs1">
                                      <Col style={{ paddingLeft: "0" }}>
                                        <Row className="book-row-basic mr-0 ml-0 pad30px">
                                          <label className="basic_information2">
                                            Hobbies and Interest
                                          </label>
                                          <label className="basic-data width5">
                                            :
                                          </label>
                                          <label className="basic_information1">
                                            {returnSecondDegreeObjValue(
                                              customer,
                                              "additional_info",
                                              "hobbies"
                                            )}
                                          </label>
                                        </Row>
                                        <Row className="book-row-basic mr-0 ml-0 pad30px">
                                          <label className="basic_information2 ">
                                            Health condition to be aware of
                                          </label>
                                          <label className="basic-data width5 ">
                                            :
                                          </label>
                                          <label className="basic_information1">
                                            {returnSecondDegreeObjValue(
                                              customer,
                                              "additional_info",
                                              "health_cond"
                                            )}
                                          </label>
                                        </Row>
                                        <Row className="book-row-basic mr-0 ml-0 pad30px">
                                          <label className="basic_information2 ">
                                            Language
                                          </label>
                                          <label className="basic-data width5">
                                            :
                                          </label>
                                          <label className="basic_information1">
                                            {returnSecondDegreeObjValue(
                                              customer,
                                              "additional_info",
                                              "lang"
                                            )}
                                          </label>
                                        </Row>
                                        <Row className="book-row-basic mr-0 ml-0 pad30px">
                                          <label className="basic_information2 ">
                                            Food Preferences
                                          </label>
                                          <label className="basic-data width5">
                                            :
                                          </label>
                                          <label className="basic_information1">
                                            {returnSecondDegreeObjValue(
                                              customer,
                                              "additional_info",
                                              "food_preference"
                                            )}
                                          </label>
                                        </Row>
                                      </Col>
                                    </Col>
                                  </Col>
                                </div>
                              </div>
                            </Col>
                          </TabPane>
                          <TabPane tabId="4">
                            <Col style={{ padding: "0" }}>
                              <div className="title-subtabs">
                                <span>Emergency contact</span>
                              </div>
                              <div className="container-Kycinfo">
                                <div style={{ width: "100%" }}>
                                  <Col className="container-basicinfo">
                                    <Col className="KYC_Docs1">
                                      <Col style={{ paddingLeft: "0" }}>
                                        <Row className="book-row-basic mr-0 ml-0 pad30px">
                                          <label className="basic_information2">
                                            Name
                                          </label>
                                          <label className="basic-data width5">
                                            :
                                          </label>
                                          <label className="basic_information1">
                                            {returnSecondDegreeObjValue(
                                              customer,
                                              "emergency_contact",
                                              "name"
                                            )}
                                          </label>
                                        </Row>
                                        <Row className="book-row-basic mr-0 ml-0 pad30px">
                                          <label className="basic_information2 ">
                                            Relation
                                          </label>
                                          <label className="basic-data width5 ">
                                            :
                                          </label>
                                          <label className="basic_information1">
                                            {returnSecondDegreeObjValue(
                                              customer,
                                              "emergency_contact",
                                              "relationship"
                                            )}
                                          </label>
                                        </Row>
                                        <Row className="book-row-basic mr-0 ml-0 pad30px">
                                          <label className="basic_information2 ">
                                            Mobile number
                                          </label>
                                          <label className="basic-data width5">
                                            :
                                          </label>
                                          <label className="basic_information1">
                                            {customer?.emergency_contact
                                              ?.phone?.national_number
                                              ? getUSFormat(
                                                returnThirdDegreeObjValue(
                                                  customer,
                                                  "emergency_contact",
                                                  "phone",
                                                  "national_number"
                                                )
                                              )
                                              : "-"}
                                          </label>
                                        </Row>
                                        <Row className="book-row-basic mr-0 ml-0 pad30px">
                                          <label className="basic_information2 ">
                                            Email
                                          </label>
                                          <label className="basic-data width5">
                                            :
                                          </label>
                                          <label className="basic_information1">
                                            {returnSecondDegreeObjValue(
                                              customer,
                                              "emergency_contact",
                                              "email"
                                            )}
                                          </label>
                                        </Row>
                                        <Row className="book-row-basic mr-0 ml-0 pad30px">
                                          <label className="basic_information2 ">
                                            Address
                                          </label>
                                          <label className="basic-data width5">
                                            :
                                          </label>
                                          <label className="basic_information1">
                                            {returnSecondDegreeObjValue(
                                              customer,
                                              "emergency_contact",
                                              "address"
                                            )}
                                          </label>
                                        </Row>
                                      </Col>
                                    </Col>
                                  </Col>
                                </div>
                              </div>
                            </Col>
                          </TabPane>
                          <TabPane tabId="5">
                            <Col style={{ padding: "0" }}>
                              <div className="title-subtabs">
                                <span>Additional Occupants</span>
                              </div>
                              <div className="container-Kycinfo">
                                <div style={{ width: "100%" }}>
                                  <Col className="KYC_Docs">
                                    <Col className="KYC_Docs1">
                                      <Col style={{ paddingLeft: "0" }}>
                                        <Row className="book-row-basic mr-0 ml-0 pad30px">
                                          <label className="basic_information">
                                            Are you staying alone
                                          </label>
                                          <label className="basic-data width5">
                                            :
                                          </label>
                                          <label className="basic_information1">
                                            {customer?.extra?.occupants
                                              ?.count >= 1
                                              ? "No"
                                              : "Yes"}
                                          </label>
                                        </Row>
                                        <Row className="book-row-basic mr-0 ml-0 pad30px">
                                          <label className="basic_information">
                                            Number of additional occupants
                                          </label>
                                          <label className="basic-data width5">
                                            :
                                          </label>
                                          <label className="basic_information1">
                                            {returnThirdDegreeObjValue(
                                              customer,
                                              "extra",
                                              "occupants",
                                              "count"
                                            )}
                                          </label>
                                        </Row>
                                      </Col>
                                    </Col>
                                  </Col>
                                </div>
                              </div>
                            </Col>
                            {customer?.extra?.occupants?.count >= 1 && (
                              <>
                                {customer?.extra?.occupants?.list?.map(
                                  (occList, index) => (
                                    <Col key={index} style={{ padding: "0" }}>
                                      <div className="title-subtabs">
                                        <span>Person {index + 1}</span>
                                      </div>
                                      <div className="container-Kycinfo">
                                        <div style={{ width: "100%" }}>
                                          <Col className="KYC_Docs">
                                            <Col className="KYC_Docs1">
                                              <Col
                                                style={{ paddingLeft: "0" }}
                                              >
                                                <Row className="book-row-basic mr-0 ml-0 pad30px">
                                                  <label className="basic_information2">
                                                    Name
                                                  </label>
                                                  <label className="basic-data width5">
                                                    :
                                                  </label>
                                                  <label className="basic_information1">
                                                    {returnFirstDegreeObjValue(
                                                      occList,
                                                      "name"
                                                    )}
                                                  </label>
                                                </Row>
                                                <Row className="book-row-basic mr-0 ml-0 pad30px">
                                                  <label className="basic_information2">
                                                    Contact number
                                                  </label>
                                                  <label className="basic-data width5">
                                                    :
                                                  </label>
                                                  <label className="basic_information1">
                                                    {getUSFormat(
                                                      returnSecondDegreeObjValue(
                                                        occList,
                                                        "phone",
                                                        "national_number"
                                                      )
                                                    )}
                                                  </label>
                                                </Row>
                                                <Row className="book-row-basic mr-0 ml-0 pad30px">
                                                  <label className="basic_information2">
                                                    Date of birth
                                                  </label>
                                                  <label className="basic-data width5">
                                                    :
                                                  </label>
                                                  <label className="basic_information1">
                                                    {occList?.dob
                                                      ? showUsFormat(
                                                        returnFirstDegreeObjValue(
                                                          occList,
                                                          "dob"
                                                        )
                                                      )
                                                      : "-"}
                                                  </label>
                                                </Row>
                                              </Col>
                                            </Col>
                                          </Col>
                                        </div>
                                      </div>
                                    </Col>
                                  )
                                )}
                              </>
                            )}
                          </TabPane>
                          <TabPane tabId="6">
                            <Col style={{ padding: "0" }}>
                              <div className="title-subtabs">
                                <span>Landlord Reference</span>
                              </div>
                              <div className="container-Kycinfo">
                                <div style={{ width: "100%" }}>
                                  <Col className="container-basicinfo">
                                    <Col className="KYC_Docs1">
                                      <Col style={{ paddingLeft: "0" }}>
                                        <Row className="book-row-basic mr-0 ml-0 pad30px">
                                          <label className="basic_information2">
                                            Name
                                          </label>
                                          <label className="basic-data width5">
                                            :
                                          </label>
                                          <label className="basic_information1">
                                            {returnThirdDegreeObjValue(
                                              customer,
                                              "extra",
                                              "landlord",
                                              "name"
                                            )}
                                          </label>
                                        </Row>
                                        <Row className="book-row-basic mr-0 ml-0 pad30px">
                                          <label className="basic_information2 ">
                                            Contact number
                                          </label>
                                          <label className="basic-data width5">
                                            :
                                          </label>
                                          <label className="basic_information1">
                                            {customer?.extra?.landlord?.phone
                                              ?.national_number
                                              ? getUSFormat(
                                                customer?.extra?.landlord
                                                  ?.phone?.national_number
                                              )
                                              : "-"}
                                          </label>
                                        </Row>
                                        <Row className="book-row-basic mr-0 ml-0 pad30px">
                                          <label className="basic_information2 ">
                                            Current Address
                                          </label>
                                          <label className="basic-data width5">
                                            :
                                          </label>
                                          <label className="basic_information1">
                                            {customer?.extra?.landlord
                                              ?.address?.length > 30
                                              ? returnThirdDegreeObjValue(
                                                customer,
                                                "extra",
                                                "landlord",
                                                "address"
                                              ).slice(0, 30) + "..."
                                              : returnThirdDegreeObjValue(
                                                customer,
                                                "extra",
                                                "landlord",
                                                "address"
                                              )}
                                          </label>
                                        </Row>
                                      </Col>
                                    </Col>
                                  </Col>
                                </div>
                              </div>
                            </Col>
                          </TabPane>
                          <TabPane tabId="7">
                            {!customer?.extra?.employer?.is_self_emp ? (
                              <Col style={{ padding: "0" }}>
                                <div className="title-subtabs">
                                  <span>Employer Reference</span>
                                </div>
                                <div
                                  className="container-basicinfo"
                                  style={{ flexDirection: "column" }}
                                >
                                  <div style={{ width: "80%" }}>
                                    <Row className="book-row-basic mr-0 ml-0 pad30px">
                                      <label className="basic_information">
                                        Employer type
                                      </label>
                                      <label className="basic-data width5">
                                        :
                                      </label>
                                      <label className="basic_information1">
                                        {customer?.extra?.employer
                                          ?.is_self_emp
                                          ? "Self Employed"
                                          : customer?.extra?.employer
                                            ?.is_self_emp === false
                                            ? "Salaried employee"
                                            : "-"}
                                      </label>
                                    </Row>
                                    <Row className="book-row-basic mr-0 ml-0 pad30px">
                                      <label className="basic_information ">
                                        Company name
                                      </label>
                                      <label className="basic-data width5 ">
                                        :
                                      </label>
                                      <label className="basic_information1">
                                        {returnThirdDegreeObjValue(
                                          customer,
                                          "extra",
                                          "employer",
                                          "company"
                                        )}
                                      </label>
                                    </Row>

                                    <Row className="book-row-basic mr-0 ml-0 pad30px">
                                      <label className="basic_information ">
                                        Contact number
                                      </label>
                                      <label className="basic-data width5">
                                        :
                                      </label>
                                      <label className="basic_information1">
                                        {customer?.extra?.employer?.phone
                                          ?.national_number
                                          ? getUSFormat(
                                            customer?.extra?.employer?.phone
                                              ?.national_number
                                          )
                                          : "-"}
                                      </label>
                                    </Row>

                                    <Row className="book-row-basic mr-0 ml-0 pad30px">
                                      <label className="basic_information ">
                                        Email
                                      </label>
                                      <label className="basic-data width5">
                                        :
                                      </label>
                                      <label className="basic_information1">
                                        {returnThirdDegreeObjValue(
                                          customer,
                                          "extra",
                                          "employer",
                                          "email"
                                        )}
                                      </label>
                                    </Row>
                                    <Row className="book-row-basic mr-0 ml-0 pad30px">
                                      <label className="basic_information ">
                                        Address
                                      </label>
                                      <label className="basic-data width5">
                                        :
                                      </label>
                                      <label className="basic_information1">
                                        {customer?.extra?.landlord?.address
                                          ?.length > 30
                                          ? returnThirdDegreeObjValue(
                                            customer,
                                            "extra",
                                            "employer",
                                            "address"
                                          ).slice(0, 30) + "..."
                                          : returnThirdDegreeObjValue(
                                            customer,
                                            "extra",
                                            "employer",
                                            "address"
                                          )}
                                      </label>
                                    </Row>
                                    {!customer?.extra?.is_pay_slip && (
                                      <Row className="book-row-basic mr-0 ml-0 pad30px">
                                        <label className="basic_information ">
                                          Reason for no pay slip
                                        </label>
                                        <label className="basic-data width5">
                                          :
                                        </label>
                                        <label className="basic_information1">
                                          {returnThirdDegreeObjValue(
                                            customer,
                                            "extra",
                                            "employer",
                                            "notes"
                                          )}
                                        </label>
                                      </Row>
                                    )}
                                  </div>
                                  {customer?.extra?.employer?.is_pay_slip && (
                                    <>
                                      <Row className="mr-0 ml-0 Aadhar-det">
                                        <label className=" basic_information">
                                          Pay slip
                                        </label>
                                      </Row>
                                      <div className="tax_slips   ">
                                        {customer?.extra?.employer?.images?.map(
                                          (image, index) =>
                                            image?.public_id !== null && (
                                              <img
                                                key={index}
                                                src={getCloudinaryImage(
                                                  130,
                                                  80,
                                                  image,
                                                  "c_fill"
                                                )}
                                                className="br-5 imgwh"
                                                alt="proof image"
                                                style={{
                                                  marginRight: "20px",
                                                }}
                                              />
                                            )
                                        )}
                                      </div>
                                    </>
                                  )}
                                </div>
                              </Col>
                            ) : (
                              <Col style={{ padding: "0" }}>
                                <div className="title-subtabs">
                                  <span>Employer Reference</span>
                                </div>
                                <div
                                  className="container-basicinfo"
                                  style={{ flexDirection: "column" }}
                                >
                                  <div style={{ width: "80%" }}>
                                    <Row className="book-row-basic mr-0 ml-0 pad30px">
                                      <label className="basic_information">
                                        Employer type
                                      </label>
                                      <label className="basic-data width5">
                                        :
                                      </label>
                                      <label className="basic_information1">
                                        {customer?.extra?.employer
                                          ?.is_self_emp
                                          ? "Self Employed"
                                          : customer?.extra?.employer
                                            ?.is_self_emp === false
                                            ? "Salaried employee"
                                            : "-"}
                                      </label>
                                    </Row>
                                    <Row className="book-row-basic mr-0 ml-0 pad30px">
                                      <label className="basic_information ">
                                        Monthly revenue
                                      </label>
                                      <label className="basic-data width5 ">
                                        :
                                      </label>
                                      <label className="basic_information1">
                                        {displayPrice(
                                          returnThirdDegreeObjValue(
                                            customer,
                                            "extra",
                                            "employer",
                                            "revenue"
                                          )
                                        )}
                                      </label>
                                    </Row>
                                    <Row className="book-row-basic mr-0 ml-0 pad30px">
                                      <label className="basic_information ">
                                        Details of employment
                                      </label>
                                      <label className="basic-data width5">
                                        :
                                      </label>
                                      <label className="basic_information1">
                                        {returnThirdDegreeObjValue(
                                          customer,
                                          "extra",
                                          "employer",
                                          "employeement"
                                        )}
                                      </label>
                                    </Row>
                                  </div>
                                  {customer?.extra?.employer?.images && (
                                    <>
                                      {!customer?.extra?.employer?.notes && (
                                        <Row className="mr-0 ml-0 Aadhar-det">
                                          <label className=" basic_information">
                                            Tax slip
                                          </label>
                                        </Row>
                                      )}
                                      <div className="tax_slips   ">
                                        {customer?.extra?.employer?.images?.map(
                                          (image, index) =>
                                            image?.public_id !== null && (
                                              <img
                                                key={index}
                                                src={getCloudinaryImage(
                                                  130,
                                                  80,
                                                  image,
                                                  "c_fill"
                                                )}
                                                className="br-5 imgwh"
                                                alt="proof image"
                                                style={{
                                                  marginRight: "20px",
                                                }}
                                              />
                                            )
                                        )}
                                      </div>
                                    </>
                                  )}
                                </div>
                              </Col>
                            )}
                          </TabPane>
                        </TabContent>
                      </CardBody>
                    </Card>
                  </TabPane>
                  <TabPane tabId="3">
                    {booking_id && <InvoiceBookingDetails bookingId={booking_id} />}
                  </TabPane>
                </TabContent>
              </Card>
            </div>
          </Row>
          <Card className={depositSideBar ? "exportSideBar" : "toggle-sidebar"}>
            <DepositSideDragger
              isOpen={depositSideBar}
              close={this.closeDeposit}
              toast={this.displayErrorMessage}
              detail={bookingDetail}
            />
          </Card>{" "}
          <Modal
            isOpen={cancelModal}
            toggle={() => this.closeCancelModal()}
            centered
          >
            <CancelModal
              close={this.closeCancelModal}
              id={this.state.requestId}
            />
          </Modal>
          <Modal
            isOpen={enablePopup}
            toggle={() => this.closeEnableModel()}
            centered
          >
            <CardBody className="pad25">
              <div className="mb-30 flex">
                <div className=" cl262626 fw-600 f16">Enable Payments</div>
                <div
                  className="clpaid closeModal fw-500 point"
                  onClick={() => this.closeEnableModel()}
                >
                  x
                </div>
              </div>
              <label
                className="f14 fw-400 cl555555 mb-10"
                htmlFor="schedule_on"
              >
                This Payment option is disabled due to multiple ACH transactions
                Failure. Are you sure to enable payments for this tenant?
              </label>
              <div className="flexEnd">
                <Button
                  color="primary"
                  size="lg"
                  className="button-wrapping search-wrapper  button-secondary ml-4 cancelbtn"
                  onClick={() => this.closeEnableModel()}
                >
                  No
                </Button>
                {/* <Button
                  color="primary"
                  size="lg"
                  className="button-wrapping search-wrapper checkoutSubmit"
                onClick={() => this.confirmCheckout()}
                > */}
                {/* {isLoad ? (
                <div className="spin-loading-save w100 h100">
                  <Spinner type="border" color="#012A4D" />
                </div>
              ) : ( */}
                {/* <div
                    onClick={() => this.updateEnableChange()}
                  >Yes</div> */}
                {/* )} */}
                {/* </Button> */}
                <Button
                  color="primary"
                  size="lg"
                  className="button-wrapping search-wrapper checkoutSubmit"
                  onClick={() => this.updateEnableChange()}
                >
                  {isLoad ? (
                    <div className="spin-loading-save w100 h100">
                      <Spinner type="border" color="#012A4D" />
                    </div>
                  ) : (
                    "Yes"
                  )}
                </Button>
              </div>
            </CardBody>
          </Modal>
        </Container >

        <Card className={historyView ? "invoice-sidebar" : "toggle-sidebar"}>
          <DepositDetail
            isOpen={historyView}
            id={depositId}
            close={this.closeHistory}
          />
        </Card>
      </>
    );
  }
}

export const returnBookingDocumentStatus = (file, type) => {
  type = type ? "Manager" : "Host";
  if (file?.zoho_data?.request_status === "inprogress") {
    if ((file.order === "0" || file.order === "3") && file?.actions) {
      if (
        file?.actions[0]?.action_status === "VIEWED" ||
        file?.actions[0]?.action_status === "NOACTION" ||
        file?.actions[0]?.action_status === "UNOPENED"
      ) {
        return (
          <div className="yellow f13 fw-500">
            Pending {file?.order === "3" ? type : "Tenant"} Sign
          </div>
        );
      }
    } else if (file.order === "1" && file?.actions) {
      if (
        file?.actions[0]?.action_status === "VIEWED" ||
        file?.actions[0]?.action_status === "NOACTION" ||
        file?.actions[0]?.action_status === "UNOPENED"
      ) {
        return <div className="yellow f13 fw-500">Pending {type} Sign</div>;
      } else if (
        file?.actions[1]?.action_status === "VIEWED" ||
        file?.actions[1]?.action_status === "NOACTION" ||
        file?.actions[1]?.action_status === "UNOPENED"
      ) {
        return <div className="yellow f13 fw-500">Pending Tenant Sign </div>;
      }
    } else if (file.order === "2" && file?.actions) {
      if (
        file?.actions[0]?.action_status === "VIEWED" ||
        file?.actions[0]?.action_status === "NOACTION" ||
        file?.actions[0]?.action_status === "UNOPENED"
      ) {
        return <div className="yellow f13 fw-500">Pending Tenant Sign </div>;
      } else if (
        file?.actions[1]?.action_status === "VIEWED" ||
        file?.actions[1]?.action_status === "NOACTION" ||
        file?.actions[1]?.action_status === "UNOPENED"
      ) {
        return <div className="yellow f13 fw-500">Pending {type} Sign</div>;
      }
    } else {
      return "-";
    }
  } else if (file?.zoho_data?.request_status) {
    return (
      <div
        className={
          file.zoho_data.request_status === "completed"
            ? "green f13 fw-500 capitalize"
            : "red f13 fw-500 capitalize"
        }
      >

        {file?.zoho_data?.request_status}
      </div>
      // <img src={GreenTickRound} />
    );
  } else {
    return "-";
  }
};

export const returnPropertySignStatus = (file) => {
  if (file?.zoho_data?.request_status === "inprogress") {
    if (file.order === "1" && file?.actions) {
      if (
        file?.actions[0]?.action_status === "VIEWED" ||
        file?.actions[0]?.action_status === "NOACTION" ||
        file?.actions[0]?.action_status === "UNOPENED"
      ) {
        return <div className="yellow f13 fw-500">Pending Host Sign</div>;
      } else if (
        file?.actions[1]?.action_status === "VIEWED" ||
        file?.actions[1]?.action_status === "NOACTION" ||
        file?.actions[1]?.action_status === "UNOPENED"
      ) {
        return <div className="yellow f13 fw-500">Pending Manager Sign </div>;
      }
    } else if (file.order === "2" && file?.actions) {
      if (
        file?.actions[0]?.action_status === "VIEWED" ||
        file?.actions[0]?.action_status === "NOACTION" ||
        file?.actions[0]?.action_status === "UNOPENED"
      ) {
        return <div className="yellow f13 fw-500">Pending Manager Sign </div>;
      } else if (
        file?.actions[1]?.action_status === "VIEWED" ||
        file?.actions[1]?.action_status === "NOACTION" ||
        file?.actions[1]?.action_status === "UNOPENED"
      ) {
        return <div className="yellow f13 fw-500">Pending Host Sign</div>;
      }
    } else {
      return "-";
    }
  } else if (file?.zoho_data?.request_status) {
    return (
      <div
        className={
          file.zoho_data.request_status === "completed"
            ? "green f13 fw-500 capitalize"
            : "red f13 fw-500 capitalize"
        }
      >

        {file?.zoho_data?.request_status}
      </div>
    );
  } else {
    return "-";
  }
};


export const returnBookingDocumentOverflowView = (self, file) => {
  const order = file.order;
  const request_status = file?.zoho_data?.request_status;
  const action_status0 = file?.actions[0]?.action_status;
  const action_status1 = file?.actions[1]?.action_status;
  const dropdownToggle = (
    <DropdownToggle className="pr-0 nav-notice" nav>
      <Media className="align-items-center">
        <img src={showMore} alt="" />
      </Media>
    </DropdownToggle>
  );
  const download = (
    <DropdownItem onClick={() => downloadDocument(file)}>
      <span>Download Document</span>
    </DropdownItem>
  );
  const reminderAndCancel = (
    <div>
      <UncontrolledDropdown nav>
        {dropdownToggle}
        <DropdownMenu className="dropdown-menu-arrow min-width-drop" right>
          <DropdownItem onClick={() => sendRemainder(file?.request_id)}>
            Send Reminder
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={() => self.cancelSignature(file?.request_id)}>
            Cancel Request
          </DropdownItem>
          <DropdownItem divider />
          {download}
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );

  const sendRemainder = async (id) => {
    let response = await ApiService.patchAPI(`host/documents/remind/${id}`);
    if (response.success) {
      showSuccess(self, response.message);
    } else {
      showError(self, response.message);
    }
  };

  const downloadDocument = async (file) => {
    axios({
      method: "GET",
      url: `${Constants.BASE_URL}host/documents/document/${file?.request_id}`,
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getAuthToken(),
      },
    }).then((response) => {
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(response.data);
      downloadLink.setAttribute("download", `${file?.zoho_data?.request_name}`);
      document.body.appendChild(downloadLink);
      downloadLink.click();
    });
  };

  const openLink = (link) => {
    window.open(link);
  };

  if (request_status === "inprogress") {
    if ((order === "0" || order === "3") && file?.actions) {
      if (["VIEWED", "NOACTION", "UNOPENED"].includes(action_status0)) {
        return reminderAndCancel;
      } else {
        return "";
      }
    } else if ((order === "1" || order === "2") && file?.actions) {
      if (["VIEWED", "NOACTION", "UNOPENED"].includes(action_status0)) {
        return reminderAndCancel;
      } else if (["VIEWED", "NOACTION", "UNOPENED"].includes(action_status1)) {
        return reminderAndCancel;
      } else {
        return "";
      }
    } else return "";
  } else if (file?.zoho_data?.request_status === "completed") {
    return (
      <div>
        <UncontrolledDropdown nav>
          {dropdownToggle}
          <DropdownMenu className="dropdown-menu-arrow min-width-drop" right>
            {download}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    );
  } else if (file.is_sign === false && file?.link) {
    return (
      <UncontrolledDropdown nav>
        {dropdownToggle}
        <DropdownMenu className="dropdown-menu-arrow min-width-drop" right>
          <DropdownItem onClick={() => openLink(file?.link)}>
            <span>View</span>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  } else {
    return "";
  }
};
