import React, { Component } from "react";
import { Card, CardBody, Button, Input } from "reactstrap";
import ToastList from "components/Toast/ToastList";
import { showError } from "../../constants/utils";
import ApiService from "constants/ApiService";

class CancelInvoiceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: "",
      toast: false,
      toastType: "",
      response: "",
    };
  }

  handleInputChange = (e) => {
    this.setState({ reason: e.target.value });
  };
  confirmCancel = async () => {
    let { reason } = this.state;
    if (reason === "") {
      showError(this, "Please enter reason");
    } else {
      let { id } = this.props;
      let data = {
        cancel_reason: reason,
      };
      let response = await ApiService.patchAPI(
        `host/notice/cancel/${id}`,
        data
      );
      if (response.success) {
        this.props.close(response.message);
      } else {
        showError(this, response.message);
      }
    }
  };

  render() {
    let { reason, toast, response, toastType } = this.state;
    return (
      <div>
        {toast && <ToastList message={response} type={toastType} />}

        <Card>
          <CardBody className="pad25">
            <div className="mb-30 flex">
              <div className=" cl262626 fw-600 f16">Cancel Notice</div>
              <div
                className="clpaid closeModal fw-500 point"
                onClick={() => this.props.close()}
              >
                x
              </div>
            </div>
            <label className="mb-10 cl555555 f14 fw-400" htmlFor="reason">
              Please mention the reason for Cancelling the notice*
            </label>
            <Input
              type="textarea"
              onChange={this.handleInputChange}
              value={reason}
              placeholder="Reason"
              className="mb-30"
              rows={4}
            />

            <div className="flexEnd">
              <Button
                color="primary"
                size="lg"
                className="button-wrapping search-wrapper  button-secondary ml-4 cancelbtn"
                onClick={() => this.props.close()}
              >
                No
              </Button>
              <Button
                color="primary"
                size="lg"
                className="button-wrapping search-wrapper checkoutSubmit"
                onClick={() => this.confirmCancel()}
              >
                Yes
              </Button>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default CancelInvoiceModal;
