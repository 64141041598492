import React, { Component } from "react";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";
import AddSecureVaultModal from "./AddSecureVaultModal";
import VerifySecureVault from "./VerifySecureVault";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Card,
  Table,
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  InputGroup,
  Dropdown,
  Input,
  Spinner,
  CustomInput,
  Media,
  Modal,
  CardBody,
} from "reactstrap";
// core components
import timelineIcon from "../../assets/img/brand/invoiceTimeline.svg";
import ApiService from "constants/ApiService";
import { returnFirstDegreeObjValue } from "constants/utils";
import { displayPrice, checkVaultSecure } from "constants/utils";
import ToastList from "components/Toast/ToastList";
import showMore from "../../assets/img/brand/showMore.svg";
import VaultTimeline from "./vaultTimeline";
import {
  buildQueryString,
  getIsoString,
  pushToFilteredPage,
  returnSearchObject,
  showError,
  showLocaldate,
  showSuccess,
  isSecureVault,
  saveSecureVault,
  getCookie,
} from "../../constants/utils";

import vaultUnsecureImg from "../../assets/Lotties/unsecureVaultNew.json";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import MarkAsRenewed from "./markAsRenewed";
import vaultImange from "../../assets/img/brand/vaultsecure.svg";
import DisableSecureVault from "./DisableSecureVault";
import cookie from "react-cookies";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import VaultSidebar from "./VaultSidebar";
class Vault extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      toast: false,
      toastType: "",
      response: "",
      page: 1,
      limit: 10,
      vaultList: [],
      statusSearch: "",
      deleteVaultModal: false,
      btnLoad: false,
      doc_id: "",
      filterOption: "name",
      inputSearch: "",
      timelineData: "",
      vaultTimeline: false,
      markAsTimeline: false,
      secureDocumentModal: false,
      vaultSwitch: false,
      verifyVaultModal: false,
      disableVaultModal: false,
      deleteSecure: false,
      timeoutId: null,
      input_value: false,
      vaultSideModal: false,
      vaultSidebarData: ""
    };
  }
  componentDidMount = () => {
    this.setQueryStatus();
    this.reloadPage();
    this.getMarkasRenewed();
  };
  reloadPage = () => {
    this.props.history.listen((location, search) => {
      if (location.search === "") {
        this.setState({ query: {} }, () => {
          let query = this.props.location.search;
          query = returnSearchObject(query);
          if (Object.keys(query).length === 0) {
            this.handleReset();
          }
        });
      } else {
        this.state.input_value = true
      }
    });
  };
  setStatus = (status) => {
    this.setState({ statusSearch: status, input_value: true }, () => this.handleFilterStatus());
  };
  handleSubmit = () => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
  };
  redirecAddtPage = () => {
    this.props.history.push("vault/add");
  };
  redirectEditPage = (doc_id) => {
    this.props.history.push(`vault/${doc_id}`);
  };
  onChangeHandler = (e) => {
    e.target.value === "" && this.handleReset();
    this.setState({ [e.target.name]: e.target.value, input_value: true });
    clearTimeout(this.state.timeoutId);
    const timeoutId = setTimeout(() => {
      this.state.filterOption && this.handleSubmit();
    }, 1000);
    this.setState({ timeoutId });
  };
  onChangeSelectHandler = (status) => {
    this.setState({ filterOption: status, input_value: true }, () => {
      this.handleFilterStatus();
    });
  }
  showTimeline = async (id) => {
    this.setState({ vaultTimeline: true });
    let response = await ApiService.getAPI(`/host/vault/${id}`);
    if (response.success) {
      let timeline = response?.data?.doc?.timeline;
      this.setState({ timelineData: timeline });
    }
  };
  showMarkAsRenewed = (data) => {
    this.setState({ markAsTimeline: true, vaultData: data });
  };
  handleReset = () => {
    // let default_property = getDefaultProperty();

    let self = this;
    self.setState(
      {
        // from: "",
        // to: "",
        // invoiceStatusView: "",
        page: 1,
        query: {
          page: 1,
          limit: 10,
          // property_id: default_property,
        },
        statusSearch: "",
        filterOption: "name",
        inputSearch: "",
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getVaultList();
      }
    );
  };
  setQueryStatus = () => {
    let queryStatus = this.props.location.search;
    // let default_property = getDefaultProperty();

    if (queryStatus) {
      let queryObj = returnSearchObject(queryStatus);

      this.setState(
        {
          // inputSearch: (queryObj.prop_name || queryObj.property_id) ?? "",
          // filterOption: queryObj.prop_name
          //   ? "prop_name"
          //   : queryObj.property_id
          //   ? "property_id"
          //   : "",
          statusSearch: queryObj.status ?? "",
          // managedBy: queryObj.managed_by ?? "",
          page: parseInt(queryObj.page) ?? "",
          query: returnSearchObject(queryStatus),
        },
        () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getVaultList();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...this.state.query,
            page: 1,
            limit: 10,
            // from: getIsoString(this.state.from),
            // to: getIsoString(this.state.to),
            // property_id: default_property,
          },
          // inputSearch: default_property ? default_property : "",
          // filterOption: default_property ? "property_id" : "",
        },
        () => {
          pushToFilteredPage(this.props, this.state.query);
          this.getVaultList();
        }
      );
    }
    this.setState({
      vaultSwitch: isSecureVault(),
      deleteSecure: isSecureVault(),
    });
  };
  getVaultList = async () => {
    let response = await ApiService.getAPI(
      `/host/vault${buildQueryString(this.state.query)}`
    );
    if (response.success) {
      let { docs, total } = response.data;
      this.setState({
        vaultList: docs,
        isLoading: false,
      });
    } else {
      this.setState({ isLoading: false });
      showError(this, response.message);
    }
  };
  closeTimeline = () => {
    this.setState({ vaultTimeline: false, timelineData: "" });
  };
  closeRenewal = (msg, status) => {
    this.setState({ markAsTimeline: false, vaultData: "" });
    if (msg) {
      showSuccess(this, msg);
      this.getVaultList();
    }

    this.setState({
      vaultSwitch: checkVaultSecure(),
      deleteSecure: checkVaultSecure(),
    });
  };

  openDeleteVaultModal = (id) => {
    this.setState({
      deleteVaultModal: true,
      doc_id: id,
    });
  };
  closeDeleteVaultModal = (status) => {
    if (status) {
      this.setState({ deleteSecure: checkVaultSecure() });
    }
    this.setState({ deleteVaultModal: false, doc_id: "" });
  };

  //enable modal
  toggleSecureDocumentModal = (status) => {
    if (status) {
      this.getHostDetails();
    }

    this.setState({ secureDocumentModal: false });
  };
  //verify modal
  toggleVerifyVaultModal = (status) => {
    if (status) {
      this.setState({ deleteSecure: false }, () => this.confirmDelete());
    }
    this.setState({ verifyVaultModal: false });
  };

  //disable modal
  toggleDisableVaultModal = (status) => {
    if (status) {
      this.getHostDetails();
      this.setState({ deleteVaultModal: false });
    }
    this.setState({ disableVaultModal: false });
  };
  getHostDetails = async () => {
    let response = await ApiService.getAPI("host");
    if (response.success) {
      localStorage.setItem("host_details", JSON.stringify(response.data));
      let vault_status = response?.data?.host?.vault?.is_enabled ?? false;
      if (vault_status) {
        saveSecureVault("secured");
      } else {
        saveSecureVault("notsecure");
      }
      this.setState({
        vaultSwitch: vault_status,
        deleteSecure: vault_status,
      });
    }
  };
  openSecureDocumentModal = () => {
    this.setState({ secureDocumentModal: true });
  };

  handleSecureDelete = () => {
    const { deleteSecure } = this.state;
    if (deleteSecure) {
      this.setState({ verifyVaultModal: true });
    } else {
      this.confirmDelete();
    }
  };
  confirmDelete = async () => {
    const { doc_id } = this.state;
    this.setState({ btnLoad: true });
    let response = await ApiService.deleteAPI(`host/vault/${doc_id}`);
    if (response.success) {
      this.closeDeleteVaultModal(true);
      this.setState({ btnLoad: false });
      showSuccess(this, response.message);
      this.getVaultList();
    } else {
      this.setState({ btnLoad: false });
      showError(this, response.message);
    }
  };
  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    // staticQuery.from = getIsoString(this.state.from) ?? "";
    // staticQuery.to = getIsoString(this.state.to) ?? "";
    if (this.state.inputSearch !== "" && this.state.filterOption !== "") {
      // for (let key in staticQuery) {
      //   if (
      //     key === "propname" ||
      //     key === "booking_id" ||
      //     key === "username" ||
      //     key === "property_id"
      //   ) {
      //     delete staticQuery[key];
      //   }
      // }
      staticQuery[this.state.filterOption] = this.state.inputSearch;
    }
    staticQuery.status = this.state.statusSearch ?? "";
    staticQuery.page = this.state.page ?? "";

    this.setState({ query: staticQuery }, () => {
      pushToFilteredPage(this.props, { ...this.state.query });
      this.getVaultList();
    });
  };
  handleIncrementPage = () => {
    if (this.state.vaultList.length >= 10) {
      this.setState(
        {
          page: this.state.page + 1,
        },
        () => {
          this.handleFilterStatus();
        }
      );
    }
  };
  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 }, () => {
        this.handleFilterStatus();
      });
    }
  };
  enDisableSecureVaultDocument = () => {
    const { vaultSwitch } = this.state;
    if (!vaultSwitch) {
      this.openSecureDocumentModal();
    } else {
      this.setState({ disableVaultModal: true });
    }
  };

  getStatus = (next_due) => {
    let value =
      getIsoString(next_due) < getIsoString(new Date()) ? "Past Due" : "Active";
    return value;
  };
  getMarkasRenewed = (next_due) => {
    let previous_days = moment(new Date(next_due))
      .subtract(3, "d")
      .format("YYYY-MM-DD");
    let value = getIsoString(previous_days) <= getIsoString(new Date());
    return value;
  };
  validate = (msg) => {
    showError(this, msg);
  };
  openDisableVaultModal = () => {
    this.setState({
      verifyVaultModal: false,
      disableVaultModal: true,
    });
  };
  setVaultDetails = async (doc_id) => {
    this.setState({ vaultSideModal: true })
    let response = await ApiService.getAPI(`host/vault/${doc_id}`);
    if (response.success) {
      this.setState({ vaultSidebarData: response.data.doc })
    } else {
      this.setState({ btnLoad: false });
      showError(this, response.message);
    }
  };

  openPayoutSideBar = () => {
    this.setState({ vaultSideModal: true });
  };

  closeVaultSideBar = () => {
    this.setState({ vaultSidebarData: "", vaultSideModal: false });
  };
  render() {
    const {
      isLoading,
      toast,
      toastType,
      response,
      page,
      vaultList,
      statusSearch,
      btnLoad,
      deleteVaultModal,
      filterOption,
      inputSearch,
      timelineModal,
      timelineData,
      vaultTimeline,
      markAsTimeline,
      vaultData,
      secureDocumentModal,
      vaultSwitch,
      disableVaultModal,
      verifyVaultModal,
      deleteSecure,
      input_value,
      vaultSideModal,
      vaultSidebarData
    } = this.state;
    const unsecureVault = {
      loop: true,
      autoplay: true,
      animationData: vaultUnsecureImg,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#012A4D" />
      </div>
    ) : (
      <>
        {toast && <ToastList message={response} type={toastType} />}
        <CustomListingContainer>
          <div>
            <Row className="securevault clfff">
              <Col xs="11">
                <div className="just-flex align_center">
                  <div className="mr-2r">
                    {vaultSwitch ? (
                      <img src={vaultImange} width="104px" height="104px"></img>
                    ) : (
                      <Lottie
                        options={unsecureVault}
                        height={104}
                        width={104}
                      />
                    )}
                  </div>
                  <div className="mt-20">
                    <p className="cl333333 fw-600 f17 text_initial">
                      {vaultSwitch
                        ? "Your documents are secured"
                        : "Secure your documents "}
                      {!vaultSwitch ? (
                        <span className="cl333333 fw-400 f14 text_initial">
                          (Recommended)
                        </span>
                      ) : (
                        ""
                      )}
                    </p>

                    <p
                      className="fw-400 cl333333 f14 text_initial"
                      style={{ marginTop: "-9px" }}
                    >
                      {vaultSwitch
                        ? "You have enabled the extra level security and your documents are secured now from un-authorized access"
                        : "We recommend you to enable extra security for your vault documents to prevent from unauthorised access"}
                    </p>
                  </div>
                </div>
              </Col>
              <Col xs="1" className="align_center mt-20 justend">
                <CustomInput
                  type="switch"
                  className="custom-switch-lg "
                  checked={this.state.vaultSwitch}
                  id="vaultdoc"
                  onClick={() => this.enDisableSecureVaultDocument()}
                />
              </Col>
            </Row>
          </div>
          <ListingHeaderLayout label={"My Vault"}>
            <div className="justend">
              <div className="flexEnd invoiceStatusView"
                style={{ width: "170px", marginLeft: "15px" }}>
                {/* <div className="invoiceStatusView  mr-10 w40">
                  <UncontrolledDropdown className="status_select_div ">
                    <DropdownToggle className="pr-0 drop_down_container">
                      <Media className="capitalize">
                        {invoiceStatusView === "tm"
                          ? "This Month"
                          : invoiceStatusView === "lm"
                          ? "Last Month"
                          : invoiceStatusView === "ty"
                          ? "This Year"
                          : "All"}
                        <i className="fas fa-angle-down"></i>
                      </Media>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" bottom>
                      {" "}
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setInvoiceStatusView("")}
                      >
                        All
                      </DropdownItem>
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setInvoiceStatusView("tm")}
                      >
                        This Month
                      </DropdownItem>{" "}
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setInvoiceStatusView("lm")}
                      >
                        Last Month
                      </DropdownItem>
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setInvoiceStatusView("ty")}
                      >
                        This Year
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                   </div> */}
                <div className="add-filter-wrap ml-0">
                  <Button
                    color="primary"
                    size="sm"
                    className="button-wrapping notify"
                    onClick={this.redirecAddtPage}
                  >
                    Add
                  </Button>
                </div>
              </div>
            </div>
          </ListingHeaderLayout>
          <div style={{ margin: "20px" }}>
            <InputGroup className="flex-content align-left">
              <div className="filters_container">
                <div className="left_filter_container">
                  <div className="select_with_input status_select_input ml-10">
                    <UncontrolledDropdown className="status_select_div ">
                      <DropdownToggle className="pr-0 drop_down_container">
                        <Media className="capitalize">
                          {filterOption === "name" && "Name"}
                          <i className="fas fa-angle-down" style={{ marginLeft: "20px" }}></i>
                        </Media>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" bottom style={{ minWidth: "130px" }}>
                        {" "}
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.onChangeSelectHandler("name")}
                        >
                          Name
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <Input
                      aria-label="Text input with dropdown button"
                      value={inputSearch}
                      name="inputSearch"
                      placeholder="search by"
                      onChange={this.onChangeHandler}
                    />
                  </div>
                  <div className="status_select">
                    <UncontrolledDropdown className="status_select_div">
                      <DropdownToggle className="pr-0 drop_down_container border_status">
                        <Media className="capitalize">
                          {statusSearch === ""
                            ? "All"
                            : statusSearch === "active"
                              ? "Active"
                              : statusSearch === "past"
                                ? "Past Due"
                                : ""}
                          <i className="fas fa-angle-down"></i>
                        </Media>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" bottom>
                        {" "}
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("")}
                        >
                          All
                        </DropdownItem>{" "}
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("active")}
                        >
                          Active
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("past")}
                        >
                          Past Due
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>
                {input_value && (inputSearch || statusSearch) &&
                  <div className="right_filter_container">
                    {" "}
                    <Button
                      color="primary"
                      onClick={this.handleReset}
                      size="sm"
                      className="button-wrapping mr-4 button-secondary reset-btn"
                      style={{ fontSize: "15px" }}                  >
                      Clear filter
                    </Button>
                  </div>}
              </div>
            </InputGroup>
          </div>
          <Row>
            <div className="col new-table">
              <Card className="shadow card_with_paginator">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th
                        scope="col"
                        className={vaultList.length > 0 ? "padl1r" : ""}
                      >
                        Name
                      </th>
                      <th scope="col">Purchased At</th>
                      <th scope="col">Price</th>
                      <th scope="col">Last Done</th>
                      <th scope="col">Next Due</th>
                      <th scope="col">Status</th>
                      <th scope="col" style={{ width: "5%" }}></th>
                    </tr>
                  </thead>
                  {vaultList.length !== 0 ? (
                    <tbody>
                      {vaultList.map((vault, i) => (
                        <tr key={i} className="point">
                          <td className="pad1015" onClick={() => this.setVaultDetails(vault?.doc_id
                          )}>
                            {returnFirstDegreeObjValue(vault, "name")}
                          </td>
                          <td>
                            {showLocaldate(
                              returnFirstDegreeObjValue(vault, "purchased_at")
                            )}
                          </td>
                          <td>
                            {displayPrice(
                              returnFirstDegreeObjValue(vault, "price")
                            )}
                          </td>
                          <td>
                            {showLocaldate(
                              returnFirstDegreeObjValue(vault, "last_done")
                            )}
                          </td>
                          <td>
                            {showLocaldate(
                              returnFirstDegreeObjValue(vault, "next_due")
                            )}
                          </td>
                          <td
                            className="general_status_style"
                            style={{
                              color:
                                this.getStatus(
                                  returnFirstDegreeObjValue(vault, "next_due")
                                ) === "Active"
                                  ? "#008000"
                                  : this.getStatus(
                                    returnFirstDegreeObjValue(
                                      vault,
                                      "next_due"
                                    )
                                  ) === "Past Due"
                                    ? "#f83245"
                                    : "",
                            }}
                          >
                            {this.getStatus(
                              returnFirstDegreeObjValue(vault, "next_due")
                            )}
                          </td>
                          <td>
                            <div className="flexStart">
                              <img
                                src={timelineIcon}
                                className="mr-20 point"
                                onClick={() => this.showTimeline(vault?.doc_id)}
                              />
                              <UncontrolledDropdown nav className="position_u">
                                <DropdownToggle className="pr-0 nav-notice" nav>
                                  <Media className="align-items-center">
                                    <img src={showMore} />
                                  </Media>
                                </DropdownToggle>
                                <DropdownMenu
                                  className="dropdown-menu-arrow min-width-drop"
                                  right
                                >
                                  <DropdownItem
                                    onClick={() =>
                                      this.redirectEditPage(vault?.doc_id)
                                    }
                                  >
                                    <span>Edit</span>
                                  </DropdownItem>

                                  <DropdownItem
                                    onClick={() =>
                                      this.openDeleteVaultModal(vault?.doc_id)
                                    }
                                  >
                                    <span>Delete</span>
                                  </DropdownItem>
                                  {this.getMarkasRenewed(
                                    vault?.next_due ?? ""
                                  ) && (
                                      <DropdownItem
                                        onClick={() =>
                                          this.showMarkAsRenewed(vault)
                                        }
                                      >
                                        <span>Mark As Renewed</span>
                                      </DropdownItem>
                                    )}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td className="no-data">No Data Available</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              </Card>
            </div>
          </Row>
          <Row className="align-items-center jus-space new-margin-page paginator_box">
            <div className="paginate-flex-box ">
              <div
                className="paginate-box"
                onClick={() => this.handleDecrementPage()}
              >
                <p
                  className={page !== 1 ? "page_available" : "page_unavailable"}
                >
                  Previous
                </p>
              </div>
              <div
                className="paginate-box"
                onClick={() => this.handleIncrementPage()}
              >
                <p
                  className={
                    vaultList.length < 10
                      ? "page_unavailable"
                      : "page_available"
                  }
                >
                  Next
                </p>
              </div>
            </div>
          </Row>
          <Modal
            isOpen={secureDocumentModal}
            className="vaultverifymodal"
            toggle={() => this.toggleSecureDocumentModal()}
          >
            <AddSecureVaultModal
              isOpen={secureDocumentModal}
              close={(msg) => this.toggleSecureDocumentModal(msg)}
            />
          </Modal>
          <Modal
            isOpen={verifyVaultModal}
            className="vaultverifymodal"
            toggle={() => this.toggleVerifyVaultModal()}
          >
            <VerifySecureVault
              isOpen={verifyVaultModal}
              close={(msg) => this.toggleVerifyVaultModal(msg)}
              isOpenDisable={() => this.openDisableVaultModal()}
            />
          </Modal>
          <Modal
            isOpen={disableVaultModal}
            toggle={() => this.toggleDisableVaultModal()}
          >
            <DisableSecureVault
              close={(msg) => this.toggleDisableVaultModal(msg)}
            />
          </Modal>
          <Modal
            isOpen={deleteVaultModal}
            toggle={() => this.closeDeleteVaultModal()}
            centered
          >
            <Card>
              <CardBody className="pad25">
                <div className="mb-20 flex">
                  <div className=" cl262626 fw-600 f16">Delete</div>
                  <div
                    className="clpaid closeModal fw-500 point"
                    onClick={() => this.closeDeleteVaultModal()}
                  >
                    x
                  </div>
                </div>

                <label className="mb-20 cl555555 f15 fw-400">
                  Are you sure you want to delete this document?
                </label>

                <div className="flexEnd">
                  <Button
                    color="primary"
                    size="lg"
                    className="button-wrapping search-wrapper  button-secondary ml-4 cancelbtn"
                    onClick={() => this.closeDeleteVaultModal()}
                  >
                    No
                  </Button>
                  <Button
                    color="primary"
                    size="lg"
                    className="btn-info"
                    style={{ padding: "10px 18px" }}
                    onClick={() => this.handleSecureDelete()}
                  >
                    {btnLoad ? (
                      <div className="spin-loading-save w100 h100">
                        <Spinner type="border" color="#012A4D" />
                      </div>
                    ) : (
                      "Yes"
                    )}
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Modal>
          <Card className={vaultTimeline ? "exportSideBar" : "toggle-sidebar"}>
            <VaultTimeline
              close={this.closeTimeline}
              isOpen={vaultTimeline}
              data={timelineData}
            />
          </Card>

          <Card className={markAsTimeline ? "exportSideBar" : "toggle-sidebar"}>
            <MarkAsRenewed
              close={this.closeRenewal}
              isOpen={markAsTimeline}
              toast={this.validate}
              data={vaultData}
            />
          </Card>
        </CustomListingContainer >
        <Card
          className={
            vaultSideModal ? "invoice-sidebar payoutSideWidth" : "toggle-sidebar"
          }
        >
          {vaultSideModal && (
            <VaultSidebar data={vaultSidebarData} close={this.closeVaultSideBar} />
          )}
        </Card>
      </>
    );
  }
}
export default Vault;
