import React from "react";

const DashboardShimmer = () => {

    return (
        <div className="pad30p padT10" style={{ backgroud: "#f5f5f5"}}>
            <div className="wd_100 flex_sb">
                <div className="wd_50">
                    <div className="line wd_20"></div>
                    <div className="line wd_30"></div>
                </div>
                <div className="line wd_10 h-35"></div>
            </div>
            <div className="flex_sb mb_20" >
                {Array(4).fill(null).map((x, i) => (
                    <div className={'apps-notifying shimmer1 ' + (i ? 'ml_10' : '')}>
                        <div className="flex_sb wd_100 ">
                            <div className="d_flex wd_70">
                                <div className="line wd_10"></div>
                                <div className="line ml_10" style={{ width: ((Math.random() * 50 + 50) + "%") }}></div>
                            </div>
                            <div className="line wd_5"></div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="flex_sb">
                <div>
                    {Array(4).fill(null).map((x, i) => (
                        <div className={"sales_cards shimmer1 flex_sb wd_100" + (i ? "" : '')}>
                            <div className="wd_80">
                                <div className="line wd_50"></div>
                                <div className="line wd_60"></div>
                                <div className="line wd_20"></div>
                            </div>
                            <div className="line wd_20"></div>
                        </div>
                    ))}
                </div>
                <div className="bar_chart shimmer1 ml_20">
                    <div className="flex_sb mb_60 bd_b">
                        <div className="line wd_20"></div>
                        <div className="wd_30 flex_sb">
                            <div className="line wd_40"></div>
                            <div className="line wd_50"></div>
                        </div>
                    </div>
                    {Array(6).fill(null).map(() => (
                        <div className="line wd_70 mb_50" style={{ width: ((Math.random() * 50 + 50) + "%") }}></div>
                    ))}

                </div>
            </div>
            <div className="flex_sb wd_100">
                <div className="wd_50">
                    <div className="boxes mb_20 flex_sb shimmer1">
                        <div className="wd_50">
                            <div className="circle "></div>
                        </div>
                        <div className="wd_50 al_center">
                            <div className="line wd_50 mb_10"></div>
                            <div className="line wd_30 h10 mb_0"></div>
                            <div className="line wd_60 h10"></div>
                            <div className="line wd_20 h35"></div>
                        </div>
                    </div>
                    <div className="boxes mb_20 wd_100 flex_sb shimmer1">
                        <div className="wd_70">
                            <div className="flex_sb">
                                <div className="wd_20">
                                    <div className="line wd_70"></div>
                                </div>
                            </div>
                            <div className="wd_50">
                                <div className="line wd_100"></div>
                                <div className="connection wd_60">
                                    <div className="line wd_40"></div>
                                    <div className="line wd_40"></div>
                                </div>
                            </div>
                        </div>
                        <div className="wd_30 al_center mt_110">
                            <div className="line wd_80 h10"></div>
                            <div className="line wd_60 h10"></div>
                        </div>
                    </div>
                </div>
                <div className="wd_50 ml_20 mb_20 boxes">
                    <div className="flex_sb mb_30 bd_b">
                        <div className="line wd_20"></div>
                        <div className="wd_30 flex_sb">
                            <div className="line wd_40"></div>
                            <div className="line wd_50"></div>
                        </div>
                    </div>
                    <div className="grid shimmer1">
                        {Array(16).fill(null).map((x, i) => (
                            <div >
                                <div className="line wd_100 h10" style={{ width: ((Math.random() * 50 + 40) + "%") }}></div>
                                <div className="line wd_100 h10" style={{ width: ((Math.random() * 30 + 30) + "%") }}></div>
                            </div>
                        ))}

                    </div>

                </div>
            </div>
            <div className="flex_sb wd_100">
                <div className="wd_50 boxes ">
                    <div className="flex_sb mb_30 bd_b">
                        <div className="line wd_20"></div>
                        {/* <div className="wd_30 flex_sb">
                            <div className="line wd_40"></div>
                            <div className="line wd_50"></div>
                        </div> */}
                        <div className="line wd_10"></div>

                    </div>
                    <div className="grid shimmer1">
                        {Array(12).fill(null).map((x, i) => (
                            <div >
                                <div className="line wd_100 h10" style={{ width: ((Math.random() * 50 + 40) + "%") }}></div>
                                <div className="line wd_100 h10" style={{ width: ((Math.random() * 30 + 30) + "%") }}></div>
                            </div>
                        ))}

                    </div>

                </div>

                <div className="boxes flex_sb shimmer1 wd_50 ml_20">
                    <div className="wd_50">
                        <div className="circle "></div>
                    </div>
                    <div className="wd_50 al_center">
                        <div className="line wd_50 mb_10"></div>
                        <div className="line wd_30 h10 mb_0"></div>
                        <div className="line wd_60 h10"></div>
                        <div className="line wd_20 h35"></div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default DashboardShimmer;