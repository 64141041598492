import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import ApiService from "constants/ApiService";
import Chart from "react-apexcharts";

// import { Chart } from "react-google-charts";

import { Link } from "react-router-dom";
import CountUp from "react-countup";
import TotalSales from "../../../src/assets/img/brand/TotalSales.svg";
import TotalRevenue from "../../../src/assets/img/brand/TotalRevenue.svg";
import TotalPayments from "../../../src/assets/img/brand/TotalPayments.svg";
import TotalInvoice from "../../../src/assets/img/brand/TotalInvoice.svg";
import Lottie from "react-lottie";
import NoData from "../../../src/assets/Lotties/noData.json";
import React, { Component } from "react";
import InvoiceIncrement from "../../../src/assets/img/brand/InvoiceIncrease.svg";
import InvoiceDecrement from "../../../src/assets/img/brand/invoiceDecrease.svg";
import { Line } from "react-chartjs-2";
import {
  showUsFormat,
  pushToFilteredPage,
  returnSearchObject,
  getCookie,
  showLocaldate,
  userTypeQuery,
  calculatePercentage,
  returnPositiveNumber,
  returnFirstDegreeObjValue,
} from "constants/utils";
export const options = {
  title: "Company Performance",
  curveType: "function",
  legend: { position: "bottom" },
};

// let data = localStorage.getItem("image");
// let userName;
// if (data !== undefined) {
//   userName = JSON.parse(localStorage.getItem("image"));
// }
let lastActivity = getCookie("lastActivity");

class DashboardDesign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      detail: {},
      request_list: [],
      invoiceStatusView: "",
      isGraphData: false,
      salesStatus: 0,
      currentMonthSalePercentage: "",
      maintance_history: [],
      graphFilter: "tm",
      series: [],
      options: {
        colors: ["#405188"],
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: false,
          position: "bottom",
          floating: false,
          align: "center",
        },
        dataLabels: {
          enabled: false,
        },
        // stroke: {
        //   width: [5, 7, 5],
        //   curve: "straight",
        //   dashArray: [0, 8, 5],
        // },
        title: {
          text: "",
          align: "left",
        },

        xaxis: {
          categories: [],
        },

        grid: {
          borderColor: "#f1f1f1",
        },
      },
      donut_series: [0, 0],
      donut_options: {
        hover: { mode: null },
        states: {
          hover: {
            filter: {
              type: "none",
            },
          },
          active: {
            filter: {
              type: "none" /* none, lighten, darken */,
            },
          },
        },
        colors: ["#405188", "#FFBE0A"],
        events: {},
        chart: {
          width: "100%",
          type: "donut",
          height: "90%",
        },

        plotOptions: {
          pie: {
            startAngle: 0,
            endAngle: 360,
          },
        },
        dataLabels: {
          enabled: false,
        },

        legend: {
          show: false,
        },
        title: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
        showTooltips: false,
      },

      LineGraphdata: {
        labels: [],
        datasets: [],
      },
      LineGraphOptions: {
        maintainAspectRatio: false,
        interaction: {
          // mode: "index",
          // intersect: false,
          // axis: "x",
        },
        plugins: {
          tooltip: { enabled: true },
          // legend: false,
          legend: {
            display: true,
            labels: {
              font: {
                family: "monserrat",
                size: 16,
                weight: 600,
                lineHeight: 1.2,
              },
              color: "#214757",
              // height: 100,
            },
          },
        },

        scales: {
          x: {
            // beginAtZero: true,
            // min: 0,
            // max: 10,
            type: "date",
            grid: {
              display: false,
              drawBorder: true,
              borderDash: [8],
              border: false,
            },
            title: {
              display: true,

              color: "#911",
              font: {
                family: "Comic Sans MS",
                size: 20,
                weight: "bold",
                lineHeight: 1.2,
              },
              padding: { top: 10, left: 0, right: 0, bottom: 0 },
            },
            ticks: {
              font: {
                family: "monserrat",
                size: "16px",
                weight: "bold",
                lineHeight: 1.2,
              },
              color: "#214757",
              callback: (value) => showLocaldate(value),
            },
          },
          y: {
            beginAtZero: true,

            // min: 0,
            // max: 40,
            ticks: {
              // stepSize: 5,
              font: {
                family: "monserrat",
                size: "16px",
                weight: "bold",
                lineHeight: 1.2,
              },
              color: "#214757",

              callback: (value) => "₹" + value,
            },

            title: {
              display: true,
              text: "Amount",
              color: "#911",
              font: {
                family: "Comic Sans MS",
                size: 20,
                weight: "bold",
                lineHeight: 1.2,
              },
              padding: { top: 0, left: 0, right: 0, bottom: 10 },
            },
            grid: {
              borderDash: [10],
              display: false,
              drawBorder: true,
            },
          },
        },
      },
    };
  }
  componentDidMount() {
    // this.setQueryStatus();

    this.getDashboard();
    this.dashboardGraph();
  }

  setQueryStatus = () => {
    let queryStatus = this.props.location?.search;
    let default_property = localStorage.getItem("default_property");
    if (queryStatus) {
      let queryObj = returnSearchObject(queryStatus);
      this.setState(
        {
          query: queryObj,
        },
        () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getDashboard();
          this.dashboardGraph();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...this.state.query,
            property_id: default_property,
          },
        },
        () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getDashboard();
          this.dashboardGraph();
        }
      );
    }
  };

  getDashboard = async () => {
    let queryParams = "";
    let default_property = localStorage.getItem("default_property");

    if (this.state.invoiceStatusView !== "") {
      queryParams += `&key=${this.state.invoiceStatusView}`;
    }
    if (default_property !== "") {
      queryParams += `&property_id=${default_property}`;
    }
    let response = await ApiService.getAPI(
      `host/dashboard?${userTypeQuery()}${queryParams}`
    );
    if (response.success) {
      this.setState({
        detail: response.data,
        isLoading: false,
        request_list: response.data.requests,
      });
      if (this.state.detail.lm_sales > 0) {
        calculatePercentage(
          this.state.detail.tm_sales && this.state.detail.tm_sales,
          this.state.detail.lm_sales && this.state.detail.lm_sales,
          "salesStatus",
          this
        );
      }

      let donutvalueArray = [];
      donutvalueArray.push(this.state.detail.bookings);
      donutvalueArray.push(
        this.state.detail.properties - this.state.detail.bookings
      );
      this.setState({
        maintance_history: response.data.history,
        donut_series: donutvalueArray,
      });
    }
  };

  redirectDetailPage = (id) => {
    let self = this;
    self.props.history.push(`/requests/detail/${id}`);
  };

  dashboardGraph = async () => {
    let { graphFilter } = this.state;
    let default_property = localStorage.getItem("default_property");

    let queryParams = "";
    if (graphFilter !== "") {
      queryParams += `?key=${graphFilter}`;
    }
    if (default_property !== "") {
      queryParams += `&property_id=${default_property}`;
    }
    let response = await ApiService.getAPI(
      `host/dashboard/graph${queryParams}&${userTypeQuery()}`
    );
    if (response.success) {
      this.state.isGraphData = true;
      let data = response.data.values.map((val) => {
        return {
          x: val.x,
          y: val.y,
        };
      });
      let payout = response.data.payouts.map((val) => {
        return {
          x: val.x,
          y: val.y,
        };
      });
      let arr = data.concat(payout);

      let labels = arr.map((val) => {
        return val.x;
      });

      let labelArr = Array.from(new Set(labels));

      let mergedlabel = labelArr.sort(
        (a, b) =>
          new Date(...a.split("/").reverse()) -
          new Date(...b.split("/").reverse())
      );

      // let obj = {
      //   name: "Sales",
      //   data: [0],
      // };
      // this.setState((prevState) => ({
      //   series: [
      //     ...prevState.series,
      //     { name: "Sales", data: data.map((value) => value.y) },
      //     // { name: "Payouts", data: payout.map((value) => value.y) },
      //   ],
      // }));
      // this.setState((prevState) => ({
      //   options: {
      //     ...prevState.options,
      //     xaxis: {
      //       ...prevState.options.xaxis,
      //       categories: data.map((value) => showUsFormat(value.x)),
      //     },
      //   },
      // }));

      this.setState({
        LineGraphdata: {
          labels: mergedlabel,
          datasets: [
            {
              label: " Sales",
              data: data,
              borderColor: "#405188",
              pointBackgroundColor: "#405188",
              borderWidth: 5,
              pointBorderWidth: 0,
              pointHoverBorderWidth: 4,
              pointRadius: 2,
              tension: 0.2,
              fill: true,
            },
            {
              label: " Payouts",
              data: payout,
              borderColor: "#FFBE0A",
              pointBackgroundColor: "#FFBE0A",
              borderWidth: 5,
              pointBorderWidth: 0,
              pointHoverBorderWidth: 4,
              pointRadius: 2,
              tension: 0.2,
              fill: true,
            },
          ],
        },
      });
    } else {
      this.state.isGraphData = false;
    }
  };
  RouteFunction = () => {
    let self = this;
    self.props.history.push(`/admin/requests`);
  };
  handleTypeInputChange = (e) => {
    this.setState({ graphFilter: e.target.value, series: [] }, () => {
      this.dashboardGraph();
    });
  };

  setInvoiceStatusView = (status) => {
    this.setState({ invoiceStatusView: status }, () => {
      this.getDashboard();
    });
  };
  render() {
    const NodataFound = {
      loop: true,
      autoplay: true,
      animationData: NoData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    let { graphFilter, detail, invoiceStatusView } = this.state;
    return (
      <>
        <div className="header pt-md-6 mt-8 equal_header_padding">
          <Container fluid className="genaral_div_padding">
            <div className="flex mb-20 ">
              <div className="">
                <div className="f16 fw-600  mb-7 cl555555">
                  {/* Welcome {userName?.host?.name} */}
                </div>
                <div className="f13 fw-400 clABABAB">
                  Last Activity {lastActivity}
                </div>
              </div>
              <div className=" invoiceStatusView dashMonthFilter">
                <UncontrolledDropdown className="status_select_div ">
                  <DropdownToggle className="pr-0 drop_down_container">
                    <Media className="capitalize">
                      {invoiceStatusView === "tm"
                        ? "This Month"
                        : invoiceStatusView === "lm"
                        ? "Last Month"
                        : invoiceStatusView === "ty"
                        ? "This Year"
                        : "All"}
                      <i className="fas fa-angle-down"></i>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" bottom>
                    {" "}
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.setInvoiceStatusView("")}
                    >
                      All
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.setInvoiceStatusView("tm")}
                    >
                      This Month
                    </DropdownItem>{" "}
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.setInvoiceStatusView("lm")}
                    >
                      Last Month
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.setInvoiceStatusView("ty")}
                    >
                      This Year
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>

            <div className="header-body">
              <Row className="row-center">
                <Col lg="6" xl="3" className="max-dash mb_mt_28 ">
                  <Link
                    to={{
                      pathname: "/admin/invoices",
                      merge: "TransactionProp",
                    }}
                  >
                    <Card className="card-stats mb-4 mb-xl-0 dash-new">
                      <CardBody className="pad-dash invoice_dash">
                        <div className="dash_content dash_content_invoice ">
                          <div className="dash_left">
                            <div className="heading_with_badge mb_20">
                              <h4>TOTAL SALES</h4>
                            </div>
                            <label className="number-con mb_0 point">
                              <CountUp
                                start={0}
                                end={returnFirstDegreeObjValue(detail, "sales")}
                                separator=","
                                prefix="$"
                              />
                            </label>
                          </div>
                          <div className="dash_right_container">
                            {this.state.detail &&
                              this.state.detail.lm_sales !== 0 && (
                                <>
                                  {" "}
                                  <div className="percentage_calulate mb_20">
                                    <>
                                      <div
                                        id="value"
                                        className={
                                          detail.tm_sales > detail.lm_sales
                                            ? "invoice_badge_increased"
                                            : "invoice_badge_decreased"
                                        }
                                      >
                                        <img
                                          src={
                                            detail.tm_sales > detail.lm_sales
                                              ? InvoiceIncrement
                                              : InvoiceDecrement
                                          }
                                          alt=""
                                        />
                                        <span>
                                          {this.state.salesStatus &&
                                            returnPositiveNumber(
                                              this.state.salesStatus
                                            )}{" "}
                                          %
                                        </span>
                                      </div>
                                    </>
                                  </div>
                                </>
                              )}

                            <div className="col dash-col dash_right dash_right_invoice">
                              <img
                                src={TotalSales}
                                className="img-dash"
                                alt="total sales"
                              />
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>

                <Col lg="6" xl="3" className="max-dash mb_mt_28">
                  <Link
                    to={{
                      pathname: "/admin/invoices",
                      merge: "TransactionProp",
                    }}
                  >
                    <Card className="card-stats mb-4 mb-xl-0 dash-new">
                      <CardBody className="pad-dash invoice_dash">
                        <div className="dash_content dash_content_invoice ">
                          <div className="dash_left">
                            <div className="heading_with_badge">
                              <h4>TOTAL REVENUE</h4>
                            </div>
                            <label className="number-con mb_0 point">
                              <CountUp
                                start={0}
                                end={returnFirstDegreeObjValue(detail, "sales")}
                                separator=","
                                prefix="$"
                              />
                            </label>
                          </div>
                          <div className="dash_right_container">
                            {/* <div className="percentage_calulate">
                              <div
                                id="value"
                                className={
                                  paidInvoicePercentage &&
                                  paidInvoicePercentage > 0
                                    ? "invoice_badge_increased"
                                    : "invoice_badge_decreased"
                                }
                              >
                                <img
                                  src={
                                    paidInvoicePercentage &&
                                    paidInvoicePercentage > 0
                                      ? InvoiceIncrement
                                      : InvoiceDecrement
                                  }
                                />
                                <span>
                                  {paidInvoicePercentage &&
                                    returnPositiveNumber(
                                      paidInvoicePercentage
                                    )}{" "}
                                  %
                                </span>
                              </div>
                            </div> */}
                            <div className="col dash-col dash_right dash_right_invoice">
                              <img
                                src={TotalRevenue}
                                className="img-dash"
                                alt="total sales"
                              />
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>

                <Col lg="6" xl="3" className="max-dash mb_mt_28 ">
                  <Link
                    to={{
                      pathname: "/admin/invoices",
                      merge: "InvoiceProp",
                      search: "?status=created",
                    }}
                  >
                    <Card className="card-stats mb-4 mb-xl-0 dash-new ">
                      <CardBody className="pad-dash invoice_dash">
                        <div className="dash_content dash_content_invoice ">
                          <div className="dash_left">
                            <div className="heading_with_badge">
                              <h4>PENDING INVOICES</h4>
                            </div>
                            <label className="number-con mb_0 point">
                              <CountUp
                                start={0}
                                end={returnFirstDegreeObjValue(
                                  detail,
                                  "invoice"
                                )}
                                separator=","
                                prefix="$"
                              />
                            </label>
                          </div>
                          <div className="dash_right_container">
                            <div className="percentage_calulate"></div>
                            <div className="col dash-col dash_right dash_right_invoice">
                              <img
                                src={TotalInvoice}
                                className="img-dash"
                                alt="total sales"
                              />
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>

                <Col lg="6" xl="3" className="max-dash mb_mt_28 ">
                  <Link
                    to={{
                      pathname: "/admin/income",
                      merge: "",
                    }}
                  >
                    <Card className="card-stats mb-4 mb-xl-0 dash-new point">
                      <CardBody className="pad-dash invoice_dash">
                        <div className="dash_content dash_content_invoice ">
                          <div className="dash_left">
                            <div className="heading_with_badge">
                              <h4>PAYOUTS</h4>
                            </div>
                            <label className="number-con mb_0 point">
                              <CountUp
                                start={0}
                                end={returnFirstDegreeObjValue(
                                  detail,
                                  "payout"
                                )}
                                separator=","
                                prefix="$"
                              />
                            </label>
                          </div>
                          <div className="dash_right_container">
                            <div className="percentage_calulate"></div>
                            <div className="col dash-col dash_right dash_right_invoice">
                              <img
                                src={TotalPayments}
                                className="img-dash"
                                alt="total sales"
                              />
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
              </Row>
            </div>
            <div className="header-body">
              <Row className="row-center">
                {/* <Col lg="6" xl="3" className="max-dash ">
                  <Link
                    to={{
                      pathname: "/admin/transactions",
                      merge: "TransactionProp",
                    }}
                  >
                    <Card className="card-stats mb-4 mb-xl-0 dash-new">
                      <CardBody className="pad-dash">
                        <div className="dash_content dash_content">
                          <div className="dash_left">
                            <div className="heading_with_badge mb_20">
                              <h4>TOTAL SALES</h4>
                              {isNaN(this.state.salesStatus) ? (
                                <></>
                              ) : (
                                <div
                                  className={
                                    this.state.currentMonthSalePercentage >
                                    detail.lm_sales
                                      ? "heading_badge_success"
                                      : "heading_badge"
                                  }
                                  id="value"
                                >
                                  <i
                                    className={
                                      this.state.currentMonthSalePercentage >
                                      detail.lm_sales
                                        ? "fas fa-long-arrow-alt-up"
                                        : "fas fa-long-arrow-alt-down"
                                    }
                                  ></i>
                                  <span
                                    title={
                                      this.state.currentMonthSalePercentage >
                                      detail.lm_sales
                                        ? `The sales has increased by ${this.state.salesStatus.toFixed(
                                            0
                                          )}%`
                                        : `The sales has decreased by ${this.state.salesStatus.toFixed(
                                            0
                                          )}%`
                                    }
                                  >
                                    {this.state.salesStatus &&
                                      returnPositiveNumber(
                                        this.state.salesStatus.toFixed(0)
                                      )}{" "}
                                    %
                                  </span>
                                </div>
                              )}
                            </div>
                            <label className="number-con">
                              <CountUp
                                start={0}
                                end={returnFirstDegreeObjValue(detail, "sales")}
                                separator=","
                                prefix="$"
                              />
                            </label>
                          </div>
                          <div className="col dash-col dash_right">
                            <img
                              src={TotalSales}
                              className="img-dash"
                              alt="total sales"
                            />
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Link>
                </Col> */}

                {/* <Col lg="6" xl="3" className="max-dash">
                  <Link
                    to={{
                      pathname: "/admin/transactions",
                      merge: "TransactionProp",
                    }}
                  >
                    <Card className="card-stats mb-4 mb-xl-0 dash-new">
                      <CardBody className="pad-dash">
                        <div className="dash_content">
                          <div className="dash_left">
                            <div className="heading_with_badge">
                              <h4>TOTAL REVENUE</h4>
                            </div>
                            <p className="number-con">
                              <CountUp
                                start={0}
                                end={
                                  returnFirstDegreeObjValue(detail, "sales") -
                                  returnFirstDegreeObjValue(detail, "payout")
                                }
                                separator=","
                                prefix="$"
                              />
                            </p>
                          </div>
                          <div className="col dash-col dash_right">
                            <img
                              src={TotalRevenue}
                              className="img-dash"
                              alt="transactions"
                            />
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Link>
                </Col> */}

                {/* <Col lg="6" xl="3" className="max-dash ">
                  <Link
                    to={{
                      pathname: "/admin/invoices",
                      merge: "InvoiceProp",
                      search: "?status=created",
                    }}
                  >
                    <Card className="card-stats mb-4 mb-xl-0 dash-new">
                      <CardBody className="pad-dash">
                        <div className="dash_content">
                          <div className="dash_left">
                            <div className="heading_with_badge">
                              <h4 className="mr_0">PENDING INVOICES</h4>
                            </div>
                            <p className="number-con">
                              <CountUp
                                start={0}
                                end={returnFirstDegreeObjValue(
                                  detail,
                                  "invoice"
                                )}
                                separator=","
                                prefix="$"
                              />
                            </p>
                          </div>
                          <div className="col dash-col dash_right">
                            <img
                              src={TotalInvoice}
                              className="img-dash"
                              alt="invoices"
                            />
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Link>
                </Col> */}

                {/* <Col lg="6" xl="3" className="max-dash ">
                  <Card className="card-stats mb-4 mb-xl-0 dash-new">
                    <CardBody className="pad-dash">
                      <div className="dash_content">
                        <div className="dash_left">
                          <div className="heading_with_badge">
                            <h4>PAYOUTS</h4>
                          </div>
                          <p className="number-con">
                            <CountUp
                              start={0}
                              end={returnFirstDegreeObjValue(detail, "payout")}
                              separator=","
                              prefix="$"
                            />
                          </p>
                        </div>
                        <div className="col dash-col dash_right">
                          <img
                            src={TotalPayments}
                            className="img-dash"
                            alt="host payouts"
                          />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col> */}
              </Row>
            </div>
          </Container>
        </div>

        <div className="header pt-5 pb-5 dashboard_design_padding">
          <Container fluid className="genaral_div_padding graph_div">
            {this.state.isGraphData && (
              <div className="big_graph_container">
                <div className="left_graph">
                  <div className="graph_filter_div">
                    <p>SALE</p>
                    <select
                      className="graph_select_component point"
                      value={graphFilter}
                      onChange={this.handleTypeInputChange}
                    >
                      <option value="tm">This Month</option>
                      <option value="lm">Last Month</option>
                      <option value="year">This Year</option>{" "}
                    </select>
                  </div>

                  <Line
                    data={this.state.LineGraphdata}
                    options={this.state.LineGraphOptions}
                  />
                </div>
                <div className="right_graph">
                  <Card className="dashboard_card justify_card">
                    <CardHeader>PROPERTY STATUS</CardHeader>
                    <div className="pie_chart">
                      <Chart
                        options={this.state.donut_options}
                        series={this.state.donut_series}
                        type="donut"
                        width={320 + Math.floor(Math.random() * 2) + 1}
                        height="100%"
                      />
                      {this.state.detail.bookings !== 0 &&
                      this.state.detail.properties !== 0 ? (
                        <div className="display_pie_value">
                          <h3>
                            {(
                              (this.state.detail.bookings /
                                this.state.detail.properties) *
                              100
                            ).toFixed(0)}
                            %
                          </h3>
                          <p>Properties Booked</p>
                        </div>
                      ) : (
                        <div className="display_pie_value">
                          <h3></h3>
                          <p>No Properties Booked</p>
                        </div>
                      )}
                    </div>
                    <div className="pie_label">
                      <div className="individual_label">
                        <div className="label_div">
                          <h3>Total Units</h3>
                        </div>
                        <p>{this.state.detail.properties ?? " "}</p>
                      </div>
                      <div className="individual_label">
                        <div className="label_div">
                          <div className="booked_color_div"></div>
                          <h3>Booked</h3>
                        </div>
                        <p>{this.state.detail.bookings ?? ""}</p>
                      </div>
                      <div className="individual_label">
                        <div className="label_div">
                          <div className="vaccant_color_div"></div>
                          <h3>Available</h3>
                        </div>
                        <p>
                          {this.state.detail.properties -
                            this.state.detail.bookings ?? ""}
                        </p>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            )}

            <div className="listing_big_container">
              <div className="listing_left_container dashReq">
                <Card className="dashboard_card">
                  <CardHeader className="white_header disblock">
                    <div className="header_with_button">
                      <p>Recent Requests</p>
                      <Link
                        to={{
                          pathname: "/admin/requests",
                        }}
                      >
                        {" "}
                        <button className="card_view_button">View All</button>
                      </Link>
                    </div>
                  </CardHeader>
                  {this.state.request_list.length > 0 ? (
                    <>
                      <table className="table_container">
                        <thead className="grey_header">
                          <tr>
                            <th>Property</th>
                            <th>Issue</th>
                            <th>Issue From</th>
                            <th>Status</th>
                          </tr>
                        </thead>

                        {this.state.request_list.map((item, i) => (
                          <tbody
                            className="grey_table_body"
                            key={item.request_id}
                          >
                            <tr className="grey_data">
                              <td
                                className="first_td"
                                // onClick={() =>
                                //   this.redirectDetailPage(item.request_id)
                                // }
                              >
                                <Link
                                  to={{
                                    pathname: `requests/detail/${item.request_id}`,
                                  }}
                                  style={{ color: "#000" }}
                                >
                                  {item?.property?.name}
                                </Link>
                              </td>
                              <td>{item?.type}</td>
                              <td>{showUsFormat(item?.createdAt)}</td>

                              <td className="capitalize ">
                                {" "}
                                <div
                                  className="general_status_style"
                                  style={{
                                    color:
                                      item.status === "accepted"
                                        ? "#0AB39C"
                                        : item.status === "pending"
                                        ? "#F7B84A"
                                        : "F06548",
                                  }}
                                >
                                  {item?.status}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </>
                  ) : (
                    <div className="no_data_dashboard">
                      <Lottie options={NodataFound} height={100} width={100} />
                      <div className="mt_20">
                        <p>No Request Found</p>
                      </div>
                    </div>
                  )}
                </Card>
              </div>
              <div className="listing_right_container dashReq">
                <Card className="dashboard_card">
                  <CardHeader className="white_header">
                    <div className="header_with_button">
                      <p>Maintenance</p>
                      <button className="invisible_button"></button>
                    </div>
                  </CardHeader>
                  {this.state.maintance_history.length > 0 ? (
                    <table className="table_container">
                      <thead className="grey_header">
                        <tr>
                          <th>Property</th>
                          <th>Title</th>
                          <th>Last Schedule</th>
                          <th>Next Schedule</th>
                        </tr>
                      </thead>
                      {this.state.maintance_history.map((item, i) => (
                        <tbody
                          className="grey_table_body"
                          key={item.history_id}
                        >
                          <tr className="grey_data">
                            <td className="first_td">
                              <Link
                                to={{
                                  pathname: `/admin/maintenanceList/maintenance_historys/detail/${item.history_id}`,
                                }}
                                style={{ color: "#000" }}
                              >
                                {item?.property?.name}
                              </Link>
                            </td>

                            <td>
                              {" "}
                              <Link
                                style={{ color: "black" }}
                                to={{
                                  pathname: `/admin/properties/maintenance_history/${item.item_id}`,
                                }}
                              >
                                {item.service_name}
                              </Link>
                            </td>
                            <td>{showUsFormat(item.last_service)}</td>
                            <td>{showUsFormat(item.next_service)}</td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  ) : (
                    <div className="no_data_dashboard">
                      <Lottie options={NodataFound} height={100} width={100} />
                      <div className="mt_20">
                        <p>No maintenance History Found</p>
                      </div>
                    </div>
                  )}
                </Card>
              </div>
            </div>
          </Container>
        </div>
      </>
    );
  }
}
export default DashboardDesign;
