import { Select } from "antd";
import { useEffect } from "react";

export function DropdownCommon({
    statusList,
    setStatus,
    status,
    width,
    style,
    height,
    innerStyle,
    placeHolder,
    disabled,
    stateAndcity,
    stateId,
    cityId,
    inputField = false,
}) {
    useEffect(() => { }, [status]);

    return (
        <div
            style={{ width: `${width}`, minHeight: `${height}`, ...style }}
        >
            <Select
                style={{ ...innerStyle }}
                className="indivi_customSelect"
                onChange={(e) => setStatus(e)}
                options={statusList?.map((opt) => ({
                    value: stateAndcity ? opt.id : opt.value,
                    label: stateAndcity ? opt.name : opt.label
                }))}
                placeholder={placeHolder}
                value={status}
                disabled={disabled}
            />
        </div>
    );
}