import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Button,
  FormGroup,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  Spinner,
} from "reactstrap";
import ToastList from "components/Toast/ToastList";
import pana from "../../assets/img/brand/pana.svg";

import { showError } from "constants/utils";
import ApiService from "constants/ApiService";
import { defaultLayout } from "@phuocng/react-pdf-viewer";
import { showSuccess } from "constants/utils";
class DisableSecureVault extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      pType: "password",
      toast: "",
      response: "",
      toastType: "",
      btnLoading: false,
    };
  }
  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  closeModal = (status) => {
    this.props.close(status);
  };
  handleDisableVaultSecurity = async () => {
    const { password } = this.state;
    if (!password) {
      showError(this, "Please enter user password");
    } else {
      this.setState({ btnLoading: true });
      let data = {
        password: password,
      };
      let response = await ApiService.postAPI(
        "/host/vault/disable_security",
        data
      );
      if (response.success) {
        showSuccess(this, response.message);

        setTimeout(() => this.closeModal(true), 1000);
      } else {
        this.setState({ btnLoading: false });
        showError(this, response.message);
      }
    }
  };
  toggleEyeIcon = (key) => {
    if (key === "pass") {
      this.setState({
        pType: this.state.pType === "password" ? "text" : "password",
      });
    }
  };
  handleKeypress = (e) => {
    const { btnLoading } = this.state;
    if (e.key === "Enter") {
      if (!btnLoading) {
        this.handleDisableVaultSecurity();
      }
    }
  };
  render() {
    const { pType, password, toast, response, toastType, btnLoading } =
      this.state;
    return (
      <Card className="acc_verifying_modal" style={{ borderRadius: "0px" }}>
        {toast && <ToastList message={response} type={toastType} />}
        <CardBody className="pad25">
          <div>
            <img src={pana} alt="verify icon" className="scheduleContent" />
            <h2 className="mt-35 vmodal_heading">Reverify Your Account</h2>
            <p className="vmodal_p">
              Please enter your current account password to
              <br />
              disable the extra security
            </p>
            <FormGroup className="text-align-left">
              <label className="vmodal_label" htmlFor="input-email">
                Password *
              </label>
              <InputGroup>
                <Input
                  type={pType}
                  name="password"
                  value={password}
                  // autoComplete="new-password"
                  onChange={this.handleInputChange}
                  onKeyDown={this.handleKeypress}
                  className="vmodal_input vault-secure-input"
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText
                    onClick={() => this.toggleEyeIcon("pass")}
                    className="vmodal_input cl374957"
                  >
                    <i
                      className={
                        pType === "password" ? "fa fa-eye-slash" : "fa fa-eye"
                      }
                    ></i>
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
            <Button
              className="vmodal_button"
              onClick={() => this.handleDisableVaultSecurity()}
            >
              {btnLoading ? (
                <div className="button_spinner">
                  <Spinner type="border" color="#012A4D" />
                </div>
              ) : (
                "Verify Now"
              )}
            </Button>
            <Button
              color="primary"
              size="sm"
              className="button-wrapping button-secondary mt-10 point"
              onClick={() => this.closeModal()}
            >
              Cancel
            </Button>
          </div>
        </CardBody>
      </Card>
    );
  }
}
export default DisableSecureVault;
