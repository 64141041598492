import React, { Component } from "react";
import {
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  Spinner,
} from "reactstrap";
import ApiService from "constants/ApiService";
import makeAnimated from "react-select/animated";
import ToastList from "../../components/Toast/ToastList";
import Edit from "../../../src/assets/img/brand/editData.svg";
// const animatedComponents = makeAnimated();

class Contractors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toast: false,
      response: "",
      toastType: "",
      contractors_list: [],
      isLoading: true,
      page: 1,
      queryParams: "",
    };
  }
  componentDidMount() {
    this.getContranctList();
  }
  getContranctList = async () => {
    let { page, queryParams } = this.state;
    let response = await ApiService.getAPI(
      `host/contractor?page=${page}&limit=10${queryParams}`
    );
    if (response.success) {
      this.setState({ contractors_list: response.data, isLoading: false });
    }
  };
  handleIncrementPage = () => {
    this.setState({ page: this.state.page + 1 }, () =>
      this.getContranctList("")
    );
  };
  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 }, () =>
        this.getContranctList()
      );
    }
  };
  AddContract = () => {
    this.props.history.push("/admin/contractor/add");
  };
  openAddEditPage(id) {
    this.props.history.push(`/admin/contractor/edit/${id}`);
  }
  render() {
    let { toast, response, toastType, contractors_list, page } = this.state;
    return this.state.isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#012A4D" />
      </div>
    ) : (
      <>
        <Container className="mt-8 card-pad-wrapper" fluid>
          {toast && <ToastList message={response} type={toastType} />}
          <div>
            <Breadcrumb className="pad-to-8"></Breadcrumb>
          </div>
          <Row className="align-items-center header-div">
            <Col xs="8" className="manage-con">
              <Breadcrumb>Contractors</Breadcrumb>
            </Col>
            <div className="add-filter-wrap">
              {
                <Button
                  color="primary"
                  size="sm"
                  className="button-wrapping "
                  onClick={() => this.AddContract()}
                >
                  Add
                </Button>
              }
            </div>
          </Row>
          <Row>
            <div className="col new-table">
              <Card className="shadow card_with_paginator">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>Id</th>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Website</th>
                      <th></th>
                    </tr>
                  </thead>
                  {contractors_list && contractors_list.length > 0 ? (
                    <tbody>
                      {contractors_list.map((item, i) => (
                        <tr key={i}>
                          <td>{item.contractor_id}</td>
                          <td>{item.name}</td>
                          <td>{item.type}</td>
                          <td>{item.website}</td>
                          <td>
                            {" "}
                            <img
                              className="cursor-point"
                              src={Edit}
                              onClick={() =>
                                this.openAddEditPage(item.contractor_id)
                              }
                              alt=""
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <>
                      <Card className="card_with_paginator no_data_card">
                        {" "}
                        <div className="no-data">No Contractors Available</div>
                      </Card>
                    </>
                  )}
                </Table>
              </Card>
            </div>
          </Row>
          <Row className="align-items-center header-div jus-space new-margin-page paginator_box">
            <div className="paginate-flex-box ">
              <div
                className="paginate-box"
                onClick={() => this.handleDecrementPage()}
              >
                <p
                  className={page !== 1 ? "page_available" : "page_unavailable"}
                >
                  Previous
                </p>
              </div>
              <div
                className="paginate-box"
                onClick={() => this.handleIncrementPage()}
              >
                <p
                  className={
                    contractors_list.length < 10
                      ? "page_unavailable"
                      : "page_available"
                  }
                >
                  Next
                </p>
              </div>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}
export default Contractors;
