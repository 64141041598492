import React, { Component } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  InputGroup,
  InputGroupText,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import ApiService from "constants/ApiService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateSvg from "../../assets/img/brand/date-pick.svg";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { showCalendarFormat } from "constants/utils";
import ToastList from "components/Toast/ToastList";
import { handleGoBack } from "constants/utils";
import { showError } from "constants/utils";
import { showSuccess } from "constants/utils";

// core components
const animatedComponents = makeAnimated();
class AddEditNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      send_to: "",
      title: "",
      content: "",
      schedule_on: "",
      labelList: [],
      updateList: [],
      page: 1,
      toast: false,
      response: "",
      toastType: "",
    };
  }
  componentDidMount() {
    this.getPropertyList();
  }

  getPropertyList = async () => {
    let response = await ApiService.getAPI(`host/properties/all`);
    if (response.success) {
      let { properties } = response.data;
      var data = properties;
      var temp = [];
      data.forEach((property, i) => {
        var sendData = {
          label: property.name,
          value: property.property_id,
        };
        temp.push(sendData);
      });
      this.setState({
        labelList: temp,
      });
    }
  };
  // Event handler
  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleStateInputChange = (e) => {
    var self = this;
    self.setState({ state_id: e.target.value });
  };
  handleOnDateChange = (e) => {
    this.updateStateValues("schedule_on", e);
  };
  updateStateValues = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      function () {
        if (value === null) {
          this.setState({
            [key]: "",
          });
        }
      }
    );
  };
  handleOptions = () => {
    let numArray = [];
    let number = 31;
    for (var i = 1; i < number; i++) {
      var obj = i;
      numArray.push(obj);
    }
    this.setState({ optionArray: numArray });
  };
  handleOnChangeList = (value) => {
    this.setState({
      updateList: value,
    });
  };
  handleAddNotification = async () => {
    let { title, content, send_to, updateList, schedule_on } = this.state;
    if (title === "") {
      showError(this, "Please enter title");
    } else if (send_to === "") {
      showError(this, "Please select send to");
    } else if (send_to === "properties" && updateList.length <= 0) {
      showError(this, "Please select specific properties");
    } else if (content === "") {
      showError(this, "Please enter content");
    } else {
      var temp = [];
      updateList.forEach((list, i) => {
        temp.push(list.value);
      });
      let data = {
        properties: temp ? temp : [],
        type: send_to === "properties" ? "specific" : send_to,
        title,
        message: content,
        schedule_on: showCalendarFormat(schedule_on),
      };
      let response = await ApiService.postAPI(`host/notification`, data);
      if (response.success) {
        showSuccess(this, response.message);
        handleGoBack(this.props);
      } else {
        showError(this, response.message);
      }
    }
  };
  // Toast Handler

  render() {
    let {
      send_to,
      title,
      content,
      labelList,
      updateList,
      toast,
      response,
      toastType,
    } = this.state;

    return (
      <>
        <Container className="mt-8 card-pad-wrapper" fluid>
          {toast && <ToastList message={response} type={toastType} />}
          <div className="pt-3">
            <Breadcrumb></Breadcrumb>
          </div>
          <Row className="align-items-center header-div">
            <Col xs="8" className="manage-con">
              <Breadcrumb className="">
                <BreadcrumbItem
                  onClick={() => this.props.history.goBack()}
                  className="bread-cursor"
                >
                  Notifications
                </BreadcrumbItem>
                <BreadcrumbItem>Add</BreadcrumbItem>
              </Breadcrumb>
            </Col>  

            <Col className="text-right" xs="4">
              <Button
                color="primary"
                onClick={() => this.handleAddNotification()}
                size="sm"
                className="button-wrapping"
              >
                Publish
              </Button>
            </Col>
          </Row>
          <Row>
            <div className="col new-table ">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="12">
                      <h3 className="mb-0">Title*</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="new-card">
                  <Form>
                    <div>
                      <Row>
                        <Col lg="4" className="mb-3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Title
                            </label>

                            <Input
                              className="form-control-alternative"
                              value={title}
                              name="title"
                              placeholder="Title"
                              type="text"
                              onChange={this.handleInputChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4"></Col>
                        <Col lg="4"></Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Send To*
                            </label>
                            <select
                              className="form-control-alternative form-control point"
                              id="input-first-name"
                              name="send_to"
                              value={send_to}
                              onChange={this.handleInputChange}
                            >
                              <option value="">Select</option>
                              <option value="all">To My Customers</option>
                              <option value="active">
                                To All Active Customers
                              </option>
                              <option value="in_active">
                                To All Previous Customers
                              </option>
                              <option value="properties">
                                To Specific Properties
                              </option>
                            </select>
                          </FormGroup>
                        </Col>
                        {send_to === "properties" && (
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Select Specific Properties*
                              </label>

                              <Select
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                value={updateList}
                                name="updateList"
                                onChange={this.handleOnChangeList}
                                isMulti
                                options={labelList}
                                isSearchable={true}
                                placeholder="Select Properties"
                              />
                            </FormGroup>
                          </Col>
                        )}
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Schedule On
                            </label>

                            <InputGroup className="mb-0  flex-content notice-date">
                              <DatePicker
                                selected={this.state.schedule_on}
                                dateFormat="MM-dd-yyyy"
                                placeholderText=""
                                className="filter-input-date  new-date-in document-add-date point"
                                onChange={this.handleOnDateChange}
                                name="schedule_on"
                                id="schedule_on"
                                style={{ fontWeight: "500" }}
                                showYearDropdown
                                showMonthDropdown
                                ariaDescribedBy="basic-addon2"
                              />
                              <InputGroupText
                                id="basic-addon2"
                                className="group-date doc-add-group notice-group"
                              >
                                <label
                                  className="mb-remove cursor-point"
                                  htmlFor="schedule_on"
                                >
                                  <img
                                    src={DateSvg}
                                    alt="Date-Picker Icon"
                                    className="date-svg point"
                                  />
                                </label>
                              </InputGroupText>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <Card className="shadow mt-5">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="12">
                      <h3 className="mb-0">Content*</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="new-card">
                  <Form>
                    <div>
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <textarea
                              className="form-control-alternative  form-control tex-area-prop"
                              value={content}
                              name="content"
                              id="input-first-name"
                              onChange={this.handleInputChange}
                              placeholder="Notification Message"
                              type="text"
                              maxLength="500"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}
export default AddEditNotification;
