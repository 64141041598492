import React, { Component } from "react";
import {
    returnFirstDegreeObjValue,
    showLocaldate,
    displayPrice,
} from "constants/utils";
import { Card, CardBody, CardHeader, Table, Spinner } from "reactstrap";
import { displayPercentage } from "constants/utils";
import closeIcon from "../../../assets/img/brand/close.svg";
import returnSecondDegreeObjValue from "constants/utils";
import ApiService from "constants/ApiService";
import { showError } from "constants/utils";
import ColumnGroup from "antd/es/table/ColumnGroup";
import { pushToFilteredPage } from "constants/utils";
import { returnSearchObject } from "constants/utils";
import { buildQueryString } from "constants/utils";

class SupplyHistoryDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            supplyDetail: [],
            invoiceStatus: "",
            tableLoad: false,
            supplyData: "",
            query: {
                page: 1,
                limit: 10,
            },
            total: 0,
            isLoading: false
        };
    }
    componentDidMount() {
        this.getSupplyDetail()
    }

    getSupplyDetail = async (data) => {
        this.setState({ isLoading: true });
        let response = await ApiService.getAPI(
            `host/supplyItem/history/stats?item_id=${this.props.data}${buildQueryString(this.state.query, "&")}`
        );
        if (response.success) {
            let { data } = response;
            this.setState({
                supplyData: data,
                isLoading: false,
            });
        } else {
            this.setState({ isLoading: false });
            showError(this, response.message);
        }
    };

    closeModal = () => {
        this.setState({ supplyDetail: [], tableLoad: false });
        this.props.close();
    };

    setStatus = (status) => {
        this.setState({ invoiceStatus: status, tableLoad: true, page: 1 }, () => {
            this.getSupplyDetail();
        });
    };

    handleIncrementPage = () => {
        const { supplyData, query } = this.state
        if (supplyData.histories.length >= 10) {
            this.setState({ query: { ...query, page: query.page + 1 } }, () =>
                this.getSupplyDetail()
            );
        }
    };

    handleDecrementPage = () => {
        const { query } = this.state
        if (query.page > 1) {
            this.setState({ query: { ...query, page: query.page - 1 } }, () => this.getSupplyDetail());
        }
    };

    render() {
        let { supplyDetail, invoiceStatus, tableLoad, page, supplyData, isLoading, query } = this.state;
        let { data } = this.props;
        return (
            <div>
                <Card>
                    <CardHeader>
                        <div className="flex">
                            <div className="fw-500 f16 cl000000">Supply History</div>
                            <img
                                src={closeIcon}
                                onClick={() => this.closeModal()}
                                className="point"
                            />
                        </div>
                    </CardHeader>
                    {!isLoading &&
                        <CardBody className="pad30p">
                            <div className="gridThreeColumns grGap30">
                                <div className="flex-column">
                                    <div className="f14 fw-400 cl757575 mb-15">Name</div>
                                    <div className="fw-500 cl303030 f15">
                                        {supplyData?.detail?.item_name}
                                    </div>
                                </div>
                                <div className="flex-column">
                                    <div className="f14 fw-400 cl757575 mb-15">Overall Supplied</div>
                                    <div className="fw-500 cl303030 f15">
                                        {supplyData?.detail?.totalStockSupplied ? supplyData?.detail?.totalStockSupplied : 0}
                                    </div>
                                </div>
                                <div className="flex-column">
                                    <div className="f14 fw-400 cl757575 mb-15">In Stock</div>
                                    <div className="fw-500 cl303030 f15">
                                        {supplyData?.detail?.in_stock}
                                    </div>
                                </div>
                                <div className="flex-column">
                                    <div className="f14 fw-400 cl757575 mb-15">Next Schedule</div>
                                    <div className="fw-500 cl303030 f15">
                                        {showLocaldate(supplyData?.detail?.next_schedule?.start_date)}
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    }
                </Card>
                <Card className="shadow card_with_paginator">
                    {isLoading ? (
                        <div className="spin-loading3 mt-30">
                            <Spinner type="border" color="#012A4D" style={{ marginTop: "200px" }} />
                        </div>
                    ) : (
                        <div>
                            <div>
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Date Given</th>
                                            <th scope="col">Supplied Stock</th>
                                            <th scope="col">Tenant</th>
                                        </tr>
                                    </thead>
                                    {supplyData?.histories?.length !== 0 || supplyData?.histories?.length === undefined ? (
                                        <tbody>
                                            <>
                                                {supplyData?.histories?.map((item, i) => (
                                                    <tr key={i}>
                                                        <td>
                                                            {showLocaldate(returnFirstDegreeObjValue(item, "scheduled_date"))}
                                                        </td>
                                                        <td className="text-capitalize cursor-point">
                                                            {returnFirstDegreeObjValue(item, "stockSupplied")}
                                                        </td>
                                                        <td className="text-capitalize cursor-point">
                                                            {item?.user ? (item?.user?.name?.first + " " + item?.user?.name?.last) : "-"}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </>
                                        </tbody>
                                    ) : (
                                        <tbody>
                                            <tr>
                                                <td className="no-data">No History Available</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    )}
                                </Table>
                            </div>
                            <>
                                {supplyData?.histories && (
                                    <div className="flexEnd paginator_box bbNone ">
                                        <div
                                            className="paginate-box"
                                            onClick={() => this.handleDecrementPage()}
                                        >
                                            <p
                                                className={
                                                    query.page !== 1 ? "page_available" : "page_unavailable"
                                                }
                                            >
                                                Previous
                                            </p>
                                        </div>
                                        <div
                                            className="paginate-box"
                                            onClick={() => this.handleIncrementPage()}
                                        >
                                            <p
                                                className={
                                                    supplyData?.histories?.length < 10
                                                        ? "page_unavailable"
                                                        : "page_available"
                                                }
                                            >
                                                Next
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </>
                        </div>
                    )}
                </Card>
            </div>

        )

    }
}

export default SupplyHistoryDetail;
