import React, { useState } from "react";
import { Spinner } from "reactstrap";
import {
  useLocation,
  Route,
  Switch,
  Redirect,
  HashRouter,
} from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import PersonalRoutes from "../PersonalRoutes";
import RentalRoutes from "../RentalRoutes";
import routes from "routes.js";
import RolesData from "views/Roles/RolesData";
import { userTypeQuery } from "constants/utils";
import { roleType, roleManager } from "constants/utils";
import { routeAuthenticate, SidebarSet } from "views/Roles/RolesUtils";
import { roleSubManager, saveRoleAccess } from "constants/utils";
import { isChangePassword } from "constants/utils";
export const PageTitle = React.createContext();

let screen = localStorage.getItem("screen");
const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  let user = localStorage.getItem("user");
  const [userroute, setuserroute] = useState([]);
  const [title, setTitle] = useState("");
  const [breadcrumb, setBreadcrumb] = useState()
  const [rentalRoutes, setRentalRoutes] = useState([]);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
    // getRentalRoutes();
    setUserType();
  }, [location]);

  React.useEffect(() => {
    setRentalRoutes(getRentalRoutes());
  }, []);

  const setUserType = () => {
    if (user) {
      return;
    } else {
      localStorage.setItem("user", "Rental");
    }
  };

  const getRoutes = (routes) => {
    if (roleType() || roleManager()) {
      return routes.map((prop, key) => {
        if (prop.layout === "/admin") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
              exact
            />
          );
        } else {
          return null;
        }
      });
    } else {
      return routes.map((prop, key) => {
        if (prop.layout === "/admin") {
          if (prop?.rolename !== "host_or_manager") {
            if (routeAuthenticate(prop)) {
              return (
                <Route
                  path={prop.layout + prop.path}
                  component={prop.component}
                  key={key}
                  exact
                />
              );
            }
          }
        } else {
          return null;
        }
      });
    }
  };
  const loading = (
    <div className="spin-loading">
      <Spinner type="border" color="#012A4D" />
    </div>
  );
  const getRentalRoutes = (datas) => {
    let newroute = RentalRoutes.filter((item, i) => {
      if (item?.subChild?.length > 0) {
        item.subChild.filter((subchild, key) => {
          subchild === false && item.subChild.splice(key, 1);
        });
      }
      item === false && RentalRoutes.splice(i, 1);
      return item;
    });
      return roleSubManager() ? SidebarSet(newroute,  saveRoleAccess(datas)):newroute;
  };
  
  const refreshSidebar = (data) => {
    if (roleSubManager()) {
      setRentalRoutes(getRentalRoutes(data));
    }
  };
  const upDateTitle = (value) => {
    setTitle(value);
  };
  const setIsBreadcrumb = (value) => {
    setBreadcrumb(value)
  }

  return (
    <>
      <HashRouter>
        <React.Suspense fallback={loading}>
          <PageTitle.Provider
            value={{ title, upDateTitle, breadcrumb, setIsBreadcrumb }}
          >
            <div className="big_container">
              <Sidebar
                {...props}
                routes={user === "Personal" ? PersonalRoutes : getRentalRoutes()}
                logo={{
                  innerLink: "/admin/index",
                  imgSrc: require("../assets/img/new Logo/host_new_template.svg").default,
                  imgAlt: "...",
                }}
                screenProps={screen}
              />
              <div className="main-content " ref={mainContent}>
                <AdminNavbar {...props} reload={(data) => refreshSidebar(data)} />
                <Switch>
                  <Route
                    path="/payments"
                    exact
                    render={() => <Redirect to="/payments/manager" />}
                  />
                  {isChangePassword() ? (
                    <Route
                      path="/login"
                      exact
                      render={() => <Redirect to="admin/password" />}
                    />
                  ) : (
                    <Route
                      path="/login"
                      exact
                      render={() => <Redirect to="/admin/index" />}
                    />
                  )}
                  {getRoutes(routes)}
                  {isChangePassword() ? (
                    <Route
                      path="*"
                      exact
                      render={() => <Redirect to={"/admin/password"} />}
                    />
                  ) : (
                    <Route
                      path="*"
                      exact
                      render={() => <Redirect to={"/admin/index"} />}
                    />
                  )}
                </Switch>
                <Container fluid>
                  <AdminFooter />
                </Container>
              </div>
            </div>
          </PageTitle.Provider>
        </React.Suspense>
      </HashRouter>
    </>
  );
};

export default Admin;
