import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Button,
  FormGroup,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  Spinner,
  Modal,
} from "reactstrap";
import ToastList from "components/Toast/ToastList";
import DisableSecureVault from "./DisableSecureVault";
import VaultPinVerify from "../../assets/img/brand/vaultPin.svg";
import { showError } from "constants/utils";
import ApiService from "constants/ApiService";
class VerifySecureVault extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toast: "",
      response: "",
      toastType: "",
      pType: "password",
      nType: "password",
      pin: "",
      userPassword: "",
      btnLoading: false,

      disableVaultModal: false,
    };
  }

  handlePinInputChange = (e) => {
    this.setState({ pin: e.target.value.replace(/\D/g, "") });
  };
  toggleEyeIcon = (key) => {
    if (key === "pin") {
      this.setState({
        pType: this.state.pType === "password" ? "text" : "password",
      });
    } else if (key === "userPass") {
      this.setState({
        nType: this.state.nType === "password" ? "text" : "password",
      });
    }
  };
  closeModal = (msg) => {
    this.props.close(msg);
  };
  openDisableVaultModal = () => {
    this.props.isOpenDisable();
  };
  handleVerifyVault = async () => {
    const { pin, userPassword, btnLoading } = this.state;
    if (!pin) {
      showError(this, "Please enter pin");
    } else {
      this.setState({ btnLoading: true });
      let data = {
        passcode: pin,
      };
      let response = await ApiService.postAPI(
        "/host/vault/verify_security",
        data
      );

      if (response.success) {
        this.closeModal(true);
      } else {
        this.setState({ btnLoading: false });
        showError(this, response.message);
      }
    }
  };
  toggleDisableVaultModal = () => {};
  DisableVaultSecurity = async () => {
    const { userPassword, btnLoading } = this.state;

    if (userPassword) {
      showError(this, "Please enter password");
    } else {
      //   this.setState({ btnLoading: true });
      let data = {
        password: "Test@1234",
      };
      let response = await ApiService.postAPI(
        "/host/vault/disable_security",
        data
      );
      if (response.success) {
        this.closeModal("notsecure");
      } else {
        showError(this, response.message);
      }
    }
  };

  handleKeypress = (e) => {
    const { btnLoading } = this.state;
    if (e.key === "Enter") {
      if (!btnLoading) {
        this.handleVerifyVault();
      }
    }
  };
  render() {
    const {
      toast,
      response,
      toastType,
      pType,
      nType,
      pin,
      userPassword,
      btnLoading,
      disableVaultModal,
    } = this.state;
    return (
      <>
        <div className=" minh-600">
          {toast && <ToastList message={response} type={toastType} />}
          <Card>
            <div className="flex-center pad30 bgFAFAFA">
              <img
                alt="vault verification"
                src={VaultPinVerify}
                width="275px"
                height="170px"
              />
            </div>

            <CardBody className="flex-column align_center ml-25 mr-25">
              <CardTitle className="fw-600 f17 cl333333 text-center">
                Enter Verification Code
              </CardTitle>

              <CardText className="fw-400 f13 cl575757 text-center">
                Please enter your PIN to
                <br />
                proceed the changes
              </CardText>
              <FormGroup className="mb-3 w100">
                <div className="d_flex">
                  <label
                    className="form-control-label label-in margin0"
                    htmlFor="input-pin"
                  >
                    PIN *
                  </label>
                </div>
                <InputGroup>
                  <Input
                    type={pType}
                    name="pin"
                    value={pin}
                    onChange={this.handlePinInputChange}
                    onKeyDown={this.handleKeypress}
                    maxLength={4}
                    id="input-pin"
                    className="border-none vault-secure-input  text-center let-space30"
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText
                      onClick={() => this.toggleEyeIcon("pin")}
                      className="border-none cl374957  "
                    >
                      <i
                        className={
                          pType === "password" ? "fa fa-eye-slash" : "fa fa-eye"
                        }
                      ></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <div className="pin_line"></div>
                </InputGroup>
              </FormGroup>
              <CardText className="fw-400 f13 cl575757 mt-10">
                Forgot your PIN ?
                <span
                  className="point fw-500"
                  onClick={() => this.openDisableVaultModal()}
                >
                  {" "}
                  Disable Now{" "}
                </span>{" "}
                to continue !
              </CardText>
              <Button
                color="primary"
                size="sm"
                className="button-wrapping reset-btn w100 mt-30 point"
                onClick={!btnLoading ? () => this.handleVerifyVault() : ""}
              >
                {btnLoading ? (
                  <div className="button_spinner">
                    <Spinner type="border" color="#012A4D" />
                  </div>
                ) : (
                  <span className="f13 fw-600">Verify Now</span>
                )}
              </Button>

              <Button
                color="primary"
                size="sm"
                className="button-wrapping button-secondary mt-10 point"
                onClick={() => this.closeModal()}
              >
                Cancel
              </Button>
            </CardBody>
          </Card>
        </div>
      </>
    );
  }
}
export default VerifySecureVault;
