import { makeCapitalize } from 'constants/utils'
import React from 'react'

export function Custom_Button({ isPrimary = false, btnLabel, click }) {
  return (
    <button onClick={click} style={{ marginLeft: '10px' }} className={isPrimary ? 'custom_pri_btn' : 'custom_sec_btn'}>{makeCapitalize(btnLabel)}</button>
  )
}

export function Button_without_Border({ btnLabel, click }) {
  return (
    <button onClick={click} className='custom_btn_no_border'>{btnLabel}</button>
  )
}

