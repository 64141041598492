import React, { Component } from "react";
import {
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  InputGroup,
  Dropdown,
  Input,
  Spinner,
  Modal,
  CardBody,
  FormGroup,
  InputGroupText,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Tooltip,
} from "reactstrap";
import ReactTooltip from "react-tooltip";

// core components
import ApiService from "constants/ApiService";
import { Link } from "react-router-dom";
import { returnFirstDegreeObjValue } from "constants/utils";
import returnSecondDegreeObjValue from "constants/utils";
import CountUp from "react-countup";
import { returnThirdDegreeObjValue } from "constants/utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateSvg from "../../assets/img/brand/date-pick.svg";
import Park from "../../assets/img/new Logo/host_new_template.svg";
import { filterQuery } from "constants/utils";
import { displayPrice } from "constants/utils";
import { getMonthFromDate } from "constants/utils";
import { showLocaldate } from "constants/utils";
import { getIsoString } from "constants/utils";
import { getMonthFilter } from "constants/utils";
import { getFinalMonthFilter } from "constants/utils";
import ToastList from "components/Toast/ToastList";
import { returnPositiveNumber } from "constants/utils";
import {
  buildQueryString,
  calculatePercentage,
  pushToFilteredPage,
  redirectToBookings,
  returnCustomerFirsLastName,
  returnSearchObject,
  roleType,
  showError,
  showSuccess,
  userTypeQuery,
} from "../../constants/utils";
import TotalInvoice from "../../../src/assets/img/brand/headerTotalInvoice.svg";
import PaidInvoice from "../../../src/assets/img/brand/paidInvoice.svg";
import UnpaidInvoice from "../../../src/assets/img/brand/unpaidInvoice.svg";
import CancelledInvoice from "../../../src/assets/img/brand/cancelledInvoice.svg";
import InvoiceIncrement from "../../../src/assets/img/brand/InvoiceIncrease.svg";
import InvoiceDecrement from "../../../src/assets/img/brand/invoiceDecrease.svg";
import showMore from "../../assets/img/brand/showMore.svg";
import downloadIcon from "../../assets/img/brand/download.svg";
import maintenanceInvoice from "../../assets/img/brand/maintenanceInvoice.svg";
import requestInvoice from "../../assets/img/brand/requestInvoice.svg";
import rentInvoice from "../../assets/img/brand/rentInvoice.svg";
import sourceRentInvoice from "../../assets/img/brand/sourceRentInvoice.svg";
import timelineIcon from "../../assets/img/brand/invoiceTimeline.svg";
import TimeLineSidebar from "../Invoices/timeLineSideBar";


import moment from "moment";

class Invoices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceList: [],
      total: 0,
      filterToggle: false,
      from: "",
      to: "",
      page: 1,
      inputSearch: "",
      filterOption: "",
      statusSearch: "",
      modalOpen: false,
      isLoading: true,
      invoiceDetail: {},
      lateFee: 0,
      toast: false,
      response: "",
      statsData: {},
      totalInvoicePercentage: 0,
      paidInvoicePercentage: 0,
      toastType: "",
      invoiceViewToolTip: false,
      invoiceModal: false,
      invoiceModalStatus: "",
      invoiceData: "",
      cancelInvoiceModal: false,
      invoiceId: "",
      invoiceStatusView: "",
      timeLineModal: false,
    };
  }

  reloadPage = () => {
    this.props.history.listen((location, search) => {
      if (location.search === "") {
        this.setState({ query: {} }, () => {
          let query = this.props.location.search;
          query = returnSearchObject(query);
          if (Object.keys(query).length === 0) {
            this.handleReset();
          }
        });
      }
    });
  };

  componentDidMount = () => {
    this.setQueryStatus();
    this.reloadPage();
  };

  // componentDidUpdate(prevProps) {
  //   let search = this.props.location.search;
  //   if (prevProps.location.search !== search) {
  //     this.setQueryStatus();
  //   }
  // }

  setQueryStatus = () => {
    let queryStatus = this.props.location.search;
    if (queryStatus) {
      let queryObj = returnSearchObject(queryStatus);

      this.setState(
        {
          inputSearch:
            (queryObj.propname || queryObj.property_id || queryObj.username) ??
            "",
          filterOption: queryObj.propname
            ? "propname"
            : queryObj.property_id
              ? "property_id"
              : queryObj.username && "username",

          from: queryObj.from ? new Date(queryObj.from) : "",
          to: queryObj.to ? new Date(queryObj.to) : "",
          statusSearch: queryObj.status ?? "",
          page: parseInt(queryObj.page) ?? "",
          query: returnSearchObject(queryStatus),
        },
        () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getInvoiceList();
          this.getInvoiceStats();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...this.state.query,
            page: 1,
            limit: 10,
            from: getIsoString(this.state.from),
            to: getIsoString(this.state.to),
          },
        },
        () => {
          pushToFilteredPage(this.props, this.state.query);
          this.getInvoiceList();
          this.getInvoiceStats();
        }
      );
    }
  };

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  getInvoiceList = async () => {
    let { page, filterOption, statusSearch, inputSearch, from, to } =
      this.state;
    let queryParams = "";
    if (filterOption !== "" && inputSearch !== "")
      queryParams += filterQuery(filterOption, inputSearch);
    if (statusSearch !== "") {
      let status = statusSearch === "unpaid" ? "created" : statusSearch;
      queryParams += filterQuery("status", status);
    }
    if (from !== "") queryParams += filterQuery("from", getIsoString(from));
    if (to !== "") queryParams += filterQuery("to", getIsoString(to));
    if (from > to) {
      showError(this, "Please provide valid From and To date");
    } else {
      // let response = await ApiService.getAPI(
      //   `host/invoice?page=${page}&limit=10${queryParams}&${userTypeQuery()}`
      // );
      let response = await ApiService.getAPI(
        `host/invoice${buildQueryString(this.state.query)}&${userTypeQuery()}`
      );

      if (response.success) {
        let { invoices } = response.data;
        this.setState({ invoiceList: invoices, isLoading: false });
      } else {
        this.setState({ isLoading: false });
        showError(this, response.message);
      }
    }
  };

  getInvoiceStats = async () => {
    let { statsData, from, to } = this.state;
    let queryParams = "";

    if (from !== "") queryParams += `&from=${getIsoString(from)}`;
    if (to !== "") queryParams += `&to=${getIsoString(to)}`;
    let response = await ApiService.getAPI(
      `host/invoice/stats?${userTypeQuery()}${queryParams}`
    );
    if (response.success) {
      this.setState({ statsData: response.data }, () => {
        this.PercentageFunctions();
      });
    } else {
      showError(this, response.message);
    }
  };

  PercentageFunctions = () => {
    let { statsData } = this.state;
    if (statsData && statsData.inv_lm && statsData.inv_tm) {
      calculatePercentage(
        statsData && statsData.inv_tm.total,
        statsData && statsData.inv_lm.total,
        "totalInvoicePercentage",
        this
      );
    }
    if (statsData && statsData.paid_tm && statsData.paid_lm) {
      calculatePercentage(
        statsData && statsData.paid_tm && statsData.paid_tm.total,
        statsData && statsData.paid_lm && statsData.paid_lm.total,
        "paidInvoicePercentage",
        this
      );
    }
  };

  updateStateValues = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      function () {
        if (value === null) {
          this.setState({
            [key]: "",
          });
        }
      }
    );
  };

  handleOnToChange = (e) => {
    this.updateStateValues("to", e);
  };

  handleOnFromChange = (e) => {
    this.updateStateValues("from", e);
  };

  handleIncrementPage = () => {
    if (this.state.invoiceList.length >= 10) {
      this.setState({ page: this.state.page + 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };

  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    staticQuery.from = getIsoString(this.state.from) ?? "";
    staticQuery.to = getIsoString(this.state.to) ?? "";
    for (let key in staticQuery) {
      if (key === "propname" || key === "property_id" || key === "username") {
        delete staticQuery[key];
      }
    }
    if (this.state.inputSearch !== "" && this.state.filterOption !== "") {
      staticQuery[this.state.filterOption] = this.state.inputSearch;
    }
    staticQuery.status = this.state.statusSearch ?? "";
    staticQuery.page = this.state.page ?? "";

    this.setState({ query: staticQuery }, () => {
      pushToFilteredPage(this.props, { ...this.state.query });
      this.getInvoiceList();
      this.getInvoiceStats();
    });
  };

  handleReset = () => {
    let self = this;
    self.setState(
      {
        from: "",
        to: "",
        statusSearch: "",
        filterOption: "",
        inputSearch: "",
        page: 1,
        invoiceStatusView: "",
        query: {
          page: 1,
          limit: 10,
        },
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getInvoiceList();
        this.getInvoiceStats();
      }
    );
  };

  search = () => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
  };
  toggleModal = (invoice_id) => {
    this.setState(
      {
        modalOpen: !this.state.modalOpen,
      },
      () => this.state.modalOpen && this.getInvoiceDetail(invoice_id)
    );
  };

  getInvoiceDetail = async (id) => {
    let response = await ApiService.getAPI(`host/invoice/${id}`);
    if (response.success) {
      this.setState({
        invoiceDetail: response.data.invoices,
      });
      let lateFee =
        response.data.invoices.amount - response.data.invoices.base_price;
      this.setState(
        {
          lateFee: lateFee,
        },
        () => { }
      );
    }
  };

  toggleInvoiceView = () => {
    this.setState({ invoiceViewToolTip: !this.state.invoiceViewToolTip });
  };

  handleCloseModal = () => {
    this.setState({ modalOpen: false });
  };
  handleSubLateFee = (amount, base_price) => {
    let lateFee = amount - base_price;
    this.setState({
      lateFee,
    });
  };

  displayTotalInvoice = () => {
    this.handleReset();
  };

  displayStatusInvoice = (status) => {
    this.setState(
      {
        filterOption: "",
        inputSearch: "",
        page: 1,
      },
      () => {
        this.setStatus(status);
      }
    );
  };

  setStatus = (status) => {
    this.setState({ statusSearch: status, page: 1 }, () => {
      this.search();
    });
  };

  setInvoiceStatusView = (status) => {
    let from;
    let to;
    this.setState({ invoiceStatusView: status });
    if (status === "tm") {
      from = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      to = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
    } else if (status === "lm") {
      from = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
      to = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
    } else if (status === "ty") {
      from = new Date(new Date().getFullYear(), 0, 1);
      to = new Date(new Date().getFullYear(), 11, 31);
    } else {
      from = "";
      to = "";
    }
    this.setState({ from, to }, () => {
      this.handleFilterStatus();
    });
  };

  setInvoiceDetail = (status, data) => {
    this.setInvoiceModalStatus(status);
    this.setInvoiceData(data);
  };

  setInvoiceModalStatus = (status) => {
    this.setState({ invoiceModalStatus: status }, () => {
      this.openModal();
    });
  };

  setInvoiceData = (data) => {
    this.setState({ invoiceData: data });
  };

  openModal = () => {
    this.setState({ invoiceModal: true });
  };
  closeModal = () => {
    this.setState({ invoiceModal: false, invoiceData: "" });
  };

  openCancelInvoicePopup = (id) => {
    this.setState({ cancelInvoiceModal: true, invoiceId: id });
  };

  toggleCancelInvoiceModal = (msg) => {
    this.setState({ cancelInvoiceModal: false });
    if (msg) {
      showSuccess(this, msg);
      // this.setState({ page: 1 }, () => {
      //   this.getInvoiceList();
      // });
      window.location.reload();
    }
  };

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  openTimeLineModal = (id) => {
    this.getInvoiceDetail(id);
    this.setState({ timeLineModal: true });
  };

  closeTimeLineModal = () => {
    this.setState({ timeLineModal: false });
  };

  render() {
    let {
      invoiceList,
      filterToggle,
      inputSearch,
      filterOption,
      statusSearch,
      isLoading,
      page,
      modalOpen,
      invoiceDetail,
      totalInvoicePercentage,
      paidInvoicePercentage,
      lateFee,
      toast,
      response,
      statsData,
      toastType,
      invoiceViewToolTip,
      invoiceModal,
      invoiceData,
      invoiceModalStatus,
      cancelInvoiceModal,
      invoiceId,
      invoiceStatusView,
      timeLineModal,
    } = this.state;

    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#012A4D" />
      </div>
    ) : (
      <>
        <Container className="mt-8 card-pad-wrapper" fluid>
          {toast && <ToastList message={response} type={toastType} />}
          {/* Table */}
          <div>
            <Breadcrumb className="pad-to-1"></Breadcrumb>
          </div>
          <Row className="align-items-center header-div">
            <Col xs="8" className="manage-con">
              <Breadcrumb>Invoices</Breadcrumb>
            </Col>
            <Col xs="4" className="flexEnd pad0p">
              <div
                className="flexEnd invoiceStatusView"
                style={{ width: "35%" }}
              >
                <UncontrolledDropdown className="status_select_div ">
                  <DropdownToggle className="pr-0 drop_down_container">
                    <Media className="capitalize">
                      {invoiceStatusView === "tm"
                        ? "This Month"
                        : invoiceStatusView === "lm"
                          ? "Last Month"
                          : invoiceStatusView === "ty"
                            ? "This Year"
                            : "All"}
                      <i className="fas fa-angle-down"></i>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" bottom>
                    {" "}
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.setInvoiceStatusView("")}
                    >
                      All
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.setInvoiceStatusView("tm")}
                    >
                      This Month
                    </DropdownItem>{" "}
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.setInvoiceStatusView("lm")}
                    >
                      Last Month
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.setInvoiceStatusView("ty")}
                    >
                      This Year
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </Col>

            <div className="add-filter-wrap"></div>
          </Row>
          <div className="header-body">
            <Row className="row-center">
              <Col lg="6" xl="3" className="max-dash mb_mt_28 ">
                <Card
                  className="card-stats mb-4 mb-xl-0 dash-new point"
                  onClick={() => this.displayTotalInvoice()}
                >
                  <CardBody className="pad-dash invoice_dash point">
                    <div className="dash_content dash_content_invoice point ">
                      <div className="dash_left">
                        <div className="heading_with_badge">
                          <h4>OVERALL</h4>
                        </div>
                        <label className="number-con mb_15 point">
                          <CountUp
                            start={0}
                            end={returnSecondDegreeObjValue(
                              statsData,
                              "total",
                              "total"
                            )}
                            separator=","
                            prefix="$"
                            className="point"
                          />
                        </label>
                        <div className="count_with_heading">
                          <div className="count_div">
                            <CountUp
                              start={0}
                              end={returnSecondDegreeObjValue(
                                statsData,
                                "total",
                                "count"
                              )}
                              separator=","
                              prefix=""
                            />
                          </div>
                          <p>Invoices</p>
                        </div>
                      </div>
                      <div className="dash_right_container">
                        {isNaN(totalInvoicePercentage) ? (
                          <></>
                        ) : (
                          <>
                            {this.state.statsData.inv_lm &&
                              this.state.statsData.inv_lm.total !== 0 && (
                                <>
                                  <div className="percentage_calulate">
                                    <div
                                      id="value"
                                      className={
                                        totalInvoicePercentage &&
                                          totalInvoicePercentage > 0 &&
                                          this.state.statsData.inv_lm.total !== 0
                                          ? "invoice_badge_increased"
                                          : "invoice_badge_decreased"
                                      }
                                    >
                                      <img
                                        src={
                                          totalInvoicePercentage &&
                                            totalInvoicePercentage > 0
                                            ? InvoiceIncrement
                                            : InvoiceDecrement
                                        }
                                      />
                                      <span>
                                        {totalInvoicePercentage &&
                                          returnPositiveNumber(
                                            totalInvoicePercentage
                                          )}{" "}
                                        %
                                      </span>
                                    </div>
                                  </div>
                                </>
                              )}
                          </>
                        )}

                        <div className="col dash-col dash_right dash_right_invoice">
                          <img
                            src={TotalInvoice}
                            className="img-dash"
                            alt="total sales"
                          />
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="3" className="max-dash mb_mt_28">
                <Card
                  className="card-stats mb-4 mb-xl-0 dash-new point"
                  onClick={() => this.displayStatusInvoice("paid")}
                >
                  <CardBody className="pad-dash invoice_dash">
                    <div className="dash_content dash_content_invoice ">
                      <div className="dash_left">
                        <div className="heading_with_badge">
                          <h4>PAID</h4>
                        </div>
                        <label className="number-con mb_15 point">
                          <CountUp
                            start={0}
                            end={returnSecondDegreeObjValue(
                              statsData,
                              "total_paid",
                              "total"
                            )}
                            separator=","
                            prefix="$"
                          />
                        </label>
                        <div className="count_with_heading">
                          <div className="count_div">
                            <CountUp
                              start={0}
                              end={returnSecondDegreeObjValue(
                                statsData,
                                "total_paid",
                                "count"
                              )}
                              separator=","
                              prefix=""
                            />
                          </div>
                          <p>invoices</p>
                        </div>
                      </div>
                      <div className="dash_right_container">
                        {isNaN(paidInvoicePercentage) ? (
                          <></>
                        ) : (
                          <>
                            {this.state.statsData.paid_lm &&
                              this.state.statsData.paid_lm.total !== 0 && (
                                <>
                                  <div className="percentage_calulate">
                                    <div
                                      id="value"
                                      className={
                                        paidInvoicePercentage &&
                                          paidInvoicePercentage > 0
                                          ? "invoice_badge_increased"
                                          : "invoice_badge_decreased"
                                      }
                                    >
                                      <img
                                        src={
                                          paidInvoicePercentage &&
                                            paidInvoicePercentage > 0
                                            ? InvoiceIncrement
                                            : InvoiceDecrement
                                        }
                                      />
                                      <span>
                                        {paidInvoicePercentage &&
                                          returnPositiveNumber(
                                            paidInvoicePercentage
                                          )}{" "}
                                        %
                                      </span>
                                    </div>
                                  </div>
                                </>
                              )}
                          </>
                        )}

                        <div className="col dash-col dash_right dash_right_invoice">
                          <img
                            src={PaidInvoice}
                            className="img-dash"
                            alt="total sales"
                          />
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="3" className="max-dash mb_mt_28 ">
                <Card
                  className="card-stats mb-4 mb-xl-0 dash-new point"
                  onClick={() => this.displayStatusInvoice("created")}
                >
                  <CardBody className="pad-dash invoice_dash">
                    <div className="dash_content dash_content_invoice ">
                      <div className="dash_left">
                        <div className="heading_with_badge">
                          <h4>UNPAID </h4>
                        </div>
                        <label className="number-con mb_15 point">
                          <CountUp
                            start={0}
                            end={returnSecondDegreeObjValue(
                              statsData,
                              "total_unpaid",
                              "total_unpaid"
                            )}
                            separator=","
                            prefix="$"
                          />
                        </label>
                        <div className="count_with_heading">
                          <div className="count_div">
                            <CountUp
                              start={0}
                              end={returnSecondDegreeObjValue(
                                statsData,
                                "total_unpaid",
                                "count_unpaid"
                              )}
                              separator=","
                              prefix=""
                            />
                          </div>
                          <p>invoices</p>
                        </div>
                      </div>
                      <div className="dash_right_container">
                        <div className="percentage_calulate"></div>
                        <div className="col dash-col dash_right dash_right_invoice">
                          <img
                            src={UnpaidInvoice}
                            className="img-dash"
                            alt="total sales"
                          />
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="3" className="max-dash mb_mt_28 ">
                
                <Card
                  className="card-stats mb-4 mb-xl-0 dash-new"
                  onClick={() => this.displayStatusInvoice("cancelled")}
                >
                  <CardBody className="pad-dash invoice_dash">
                    <div className="dash_content dash_content_invoice ">
                      <div className="dash_left">
                        <div className="heading_with_badge">
                          <h4>CANCELLED</h4>
                        </div>
                        <label className="number-con mb_15 point">
                          <CountUp
                            start={0}
                            end={returnSecondDegreeObjValue(
                              statsData,
                              "cancelled",
                              "total"
                            )}
                            separator=","
                            prefix="$"
                          />
                        </label>
                        <div className="count_with_heading">
                          <div className="count_div">
                            <CountUp
                              start={0}
                              end={returnSecondDegreeObjValue(
                                statsData,
                                "cancelled",
                                "count"
                              )}
                              separator=","
                              prefix=""
                            />
                          </div>
                          <p>invoices</p>
                        </div>
                      </div>
                      <div className="dash_right_container">
                        <div className="percentage_calulate"></div>
                        <div className="col dash-col dash_right dash_right_invoice">
                          <img
                            src={CancelledInvoice}
                            className="img-dash"
                            alt="total sales"
                          />
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          <InputGroup className="mb-5 mb_mt_28 flex-content align-left">
            <div className="filters_container">
              <div className="left_filter_container">
                <div className="w30">
                  {" "}
                  <div className="flexStart" style={{ width: "100%" }}>
                    <div className="date-merge w20">Date</div>
                    <div className="w40">
                      <DatePicker
                        selected={this.state.from}
                        maxDate={new Date()}
                        dateFormat="MM-dd-yyyy"
                        placeholderText=""
                        className="filter-input-date point "
                        onChange={this.handleOnFromChange}
                        name="from"
                        id="from"
                        value={this.state.value}
                        style={{ fontWeight: "500" }}
                        showYearDropdown
                        showMonthDropdown
                        ariaDescribedBy="basic-addon2"
                      />
                    </div>

                    <div className="todate-merge">to</div>
                    <div className="w40">
                      <DatePicker
                        selected={this.state.to}
                        maxDate={new Date()}
                        dateFormat="MM-dd-yyyy"
                        placeholderText=""
                        className="to_filter-input-date  point"
                        onChange={this.handleOnToChange}
                        name="to"
                        id="to"
                        value={this.state.value}
                        style={{ fontWeight: "500" }}
                        showYearDropdown
                        showMonthDropdown
                        ariaDescribedBy="basic-addon2"
                      />
                    </div>
                  </div>
                </div>
                <div className="select_with_input ml-10">
                  <Dropdown
                    variant="input-group"
                    className="select_input_drop point"
                  >
                    <select
                      id="inputGroupSelect01"
                      className="form_select fixed_select point"
                      name="filterOption"
                      value={filterOption}
                      onChange={this.onChangeHandler}
                    >
                      <option value="">Select</option>
                      <option value="username">Tenant</option>
                      <option value="propname">Property</option>
                      <option value="property_id">Property Id</option>
                    </select>
                    {/* <i className="fas fa-angle-down"></i> */}
                  </Dropdown>
                  <Input
                    aria-label="Text input with dropdown button"
                    className="input-filter-toggle"
                    value={inputSearch}
                    name="inputSearch"
                    onChange={this.onChangeHandler}
                  />
                </div>
                <div className="status_select">
                  <UncontrolledDropdown className="status_select_div">
                    <DropdownToggle className="pr-0 drop_down_container">
                      <Media className="capitalize">
                        {statusSearch === ""
                          ? "All"
                          : statusSearch === "created"
                            ? "Unpaid"
                            : statusSearch}
                        <i className="fas fa-angle-down"></i>
                      </Media>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" bottom>
                      {" "}
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setStatus("")}
                      >
                        All
                      </DropdownItem>{" "}
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setStatus("paid")}
                      >
                        Paid
                      </DropdownItem>
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setStatus("created")}
                      >
                        Unpaid
                      </DropdownItem>
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setStatus("cancelled")}
                      >
                        Cancelled
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
              <div className="right_filter_container">
                <Button
                  color="primary"
                  onClick={this.handleReset}
                  size="sm"
                  className="button-wrapping search-wrapper mr-4 button-secondary ml-4 reset-btn"
                >
                  Reset
                </Button>
                <Button
                  color="primary"
                  onClick={() => this.search()}
                  size="sm"
                  className="button-wrapping search-wrapper"
                >
                  Search
                </Button>
                {/* <div className="point">
                  <img src={downloadIcon} className="downloadIcon" />
                </div> */}
              </div>
            </div>
          </InputGroup>
          <Row>
            <div className="col new-table">
              <Card className="shadow card_with_paginator">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      {invoiceList.length > 0 && (
                        <th scope="col" className="pad1015"></th>
                      )}
                      <th
                        scope="col"
                        className={invoiceList.length > 0 ? "padl1r" : ""}
                      >
                        Invoice Id
                      </th>
                      <th scope="col">Property</th>
                      <th scope="col">Tenant</th>
                      <th scope="col">Created On</th>
                      <th scope="col">Due on</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Status</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  {invoiceList.length !== 0 ? (
                    <tbody>
                      {invoiceList.map((invoice, i) => (
                        <tr key={i}>
                          <td className="pad1015 ">
                            <div
                              data-tip={
                                invoice?.type === "source_rent"
                                  ? "Source Rent"
                                  : this.capitalizeFirstLetter(invoice?.type)
                              }
                              data-iscapture="true"
                              data-for={String(i)}
                              data-place="top"
                              className="point flexmid"
                            >
                              {roleType() && invoice?.type ? (
                                invoice?.type === "rent" ? (
                                  <img src={rentInvoice} id={invoice?.type} />
                                ) : invoice?.type === "source_rent" ? (
                                  <img
                                    src={sourceRentInvoice}
                                    id={invoice?.type}
                                  />
                                ) : invoice?.type === "request" ? (
                                  <img
                                    src={requestInvoice}
                                    id={invoice?.type}
                                  />
                                ) : (
                                  invoice?.type === "maintenance" && (
                                    <img
                                      src={maintenanceInvoice}
                                      id={invoice?.type}
                                    />
                                  )
                                )
                              ) : (
                                ""
                              )}
                              <ReactTooltip
                                place="right"
                                type="info"
                                effect="solid"
                                id={String(i)}
                                html={true}
                              />
                            </div>
                          </td>
                          <td
                            className="text-capitalize cursor-point padl1r"
                            onClick={() => this.toggleModal(invoice.invoice_id)}
                          >
                            {returnFirstDegreeObjValue(invoice, "invoice_id")}
                          </td>
                          <td>
                            {returnSecondDegreeObjValue(
                              invoice,
                              "property",
                              "name"
                            )}{" "}
                          </td>
                          <td
                            className="text-capitalize cursor-point"
                            onClick={() =>
                              redirectToBookings(this, invoice.booking_id)
                            }
                          >
                            {returnCustomerFirsLastName(
                              invoice,
                              "user",
                              "name",
                              "first",
                              "last"
                            )}
                          </td>
                          <td>
                            {" "}
                            {showLocaldate(
                              returnFirstDegreeObjValue(invoice, "createdAt")
                            )}
                          </td>
                          <td>
                            {showLocaldate(
                              returnFirstDegreeObjValue(invoice, "due")
                            )}
                          </td>

                          <td>
                            {" "}
                            {returnFirstDegreeObjValue(invoice, "amount") !==
                              "N/A"
                              ? displayPrice(
                                returnFirstDegreeObjValue(invoice, "amount")
                              )
                              : 0}
                          </td>
                          <td
                            className={
                              invoice.status === "paid"
                                ? "text-capitalize green general_status_style"
                                : invoice.status === "created"
                                  ? "text-capitalize yellow general_status_style"
                                  : invoice.status === "cancelled"
                                    ? "text-capitalize red general_status_style"
                                    : "text-capitalize"
                            }
                          >
                            {returnFirstDegreeObjValue(invoice, "status") ===
                              "created"
                              ? "Unpaid"
                              : returnFirstDegreeObjValue(invoice, "status")}
                          </td>
                          <td style={{ textAlign: "left", padding: "0px" }}>
                            <div className="flexStart">
                              <img
                                src={timelineIcon}
                                className="mr-20 point"
                                onClick={() =>
                                  this.openTimeLineModal(invoice.invoice_id)
                                }
                              />
                              <div>
                                {invoice.status === "created" ? (
                                  <UncontrolledDropdown
                                    nav
                                    className="flexy-content "
                                  >
                                    <DropdownToggle
                                      className="pr-0 nav-notice"
                                      nav
                                    >
                                      <Media className="align-items-center">
                                        <img src={showMore} />
                                      </Media>
                                    </DropdownToggle>
                                    <DropdownMenu
                                      className="dropdown-menu-arrow min-width-drop"
                                      right
                                    >
                                      <>
                                        <DropdownItem
                                          onClick={() =>
                                            this.openCancelInvoicePopup(
                                              invoice.invoice_id
                                            )
                                          }
                                        >
                                          <span>Cancel</span>
                                        </DropdownItem>
                                      </>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                ) : invoice.status === "cancelled" ? (
                                  <div>
                                    <InputGroupText
                                      className="new-border-request no_border"
                                      style={{ padding: "0px" }}
                                    >
                                      <div
                                        data-tip={invoice?.cancel_reason}
                                        data-iscapture="true"
                                        data-for="toolTip1"
                                        data-place="right"
                                      >
                                        <i className="fa fa-info-circle"></i>
                                      </div>
                                      <ReactTooltip
                                        place="right"
                                        type="info"
                                        effect="solid"
                                        id="toolTip1"
                                        html={true}
                                      />

                                      {/* </div> */}
                                    </InputGroupText>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </td>

                          {/* <td
                            className="text-capitalize cursor-point"
                            style={{ color: "black" }}
                            onClick={() => this.toggleModal(invoice.invoice_id)}
                          >
                            view
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td className="no-data">No Invoices Available</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              </Card>
            </div>
          </Row>
          <Row className="align-items-center header-div jus-space new-margin-page paginator_box">
            <div className="paginate-flex-box ">
              <div
                className="paginate-box"
                onClick={() => this.handleDecrementPage()}
              >
                <p
                  className={page !== 1 ? "page_available" : "page_unavailable"}
                >
                  Previous
                </p>
              </div>
              <div
                className="paginate-box"
                onClick={() => this.handleIncrementPage()}
              >
                <p
                  className={
                    invoiceList.length < 10
                      ? "page_unavailable"
                      : "page_available"
                  }
                >
                  Next
                </p>
              </div>
            </div>

            <Modal
              className="modal-dialog-centered modal-dialog-invoice"
              isOpen={modalOpen}
              toggle={() => this.toggleModal()}
            >
              <div className="bottom-line-invoice">
                <div className="modal-header invoice-header">
                  <h5
                    className="modal-title invoice-title"
                    id="exampleModalLabel"
                  >
                    INVOICE
                  </h5>
                  <div>
                    <img src={Park} className="invoice-park" />
                  </div>
                </div>
              </div>
              <div className="modal-body profile-body invoice-body">
                <Row>
                  <Col lg="6" className="pad-left-invoice">
                    <FormGroup>
                      <label
                        className="label-invoice-prop mb-0 label-invoice"
                        htmlFor="input-username"
                      >
                        Property Details
                      </label>
                      <p className="prop-statsData-para">
                        {returnSecondDegreeObjValue(
                          invoiceDetail,
                          "property",
                          "name"
                        )}
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <div className="modal-body profile-body invoice-body">
                <Row>
                  <Col lg="12" className="pad-left-invoice">
                    <FormGroup className="flex-invoice flex-secondary-invoice">
                      <label
                        className="label-invoice-prop mb-0 label-invoice"
                        htmlFor="input-username"
                      >
                        Invoice ID
                      </label>
                      <label
                        className="label-invoice-id mb-0 label-invoice"
                        htmlFor="input-username"
                      >
                        {returnFirstDegreeObjValue(invoiceDetail, "invoice_id")}
                      </label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" className="pad-left-invoice">
                    <FormGroup className="flex-invoice ">
                      <label
                        className="label-invoice-prop mb-0 "
                        htmlFor="input-username"
                      >
                        Created On
                      </label>
                      <label
                        className="label-invoice-id mb-0 "
                        htmlFor="input-username"
                      >
                        {showLocaldate(
                          returnFirstDegreeObjValue(invoiceDetail, "createdAt")
                        )}
                      </label>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <div className="modal-body profile-body invoice-body">
                <Row>
                  <Col lg="12" className="pad-left-invoice">
                    <FormGroup className="flex-invoice ">
                      <label
                        className="label-invoice-prop mb-0 label-invoice"
                        htmlFor="input-username"
                      >
                        Description
                      </label>
                      <label
                        className="label-invoice-prop mb-0 label-invoice"
                        htmlFor="input-username"
                      >
                        Amount
                      </label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" className="pad-left-invoice">
                    <FormGroup className="flex-invoice ">
                      {invoiceDetail.items &&
                        invoiceDetail.items.length > 0 && (
                          <label
                            className="label-invoice-name mb-0 "
                            htmlFor="input-username"
                          >
                            {invoiceDetail?.items[0]?.type === "rent" ? (
                              <div>
                                Rent for {getMonthFromDate(invoiceDetail?.due)}{" "}
                              </div>
                            ) : invoiceDetail?.items[0]?.type === "request" ? (
                              "Request"
                            ) : (
                              invoiceDetail?.items[0]?.type ===
                              "maintainence" && "MAintence"
                            )}
                          </label>
                        )}

                      {invoiceDetail?.items?.length > 0 && (
                        <label
                          className="label-invoice-name mb-0 "
                          htmlFor="input-username"
                        >
                          {invoiceDetail?.items[0]?.type === "rent"
                            ? displayPrice(
                              returnFirstDegreeObjValue(
                                invoiceDetail,
                                "base_price"
                              )
                            )
                            : (invoiceDetail?.items[0]?.type === "request" ||
                              invoiceDetail.items[0].type ===
                              "maintainence") &&
                            displayPrice(invoiceDetail.items[0].amount)}
                        </label>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                {invoiceDetail?.items?.length > 0 &&
                  invoiceDetail?.items[0]?.type === "rent" &&
                  lateFee !== 0 && (
                    <Row>
                      <Col lg="12" className="pad-left-invoice">
                        <FormGroup className="flex-invoice ">
                          <label
                            className="label-invoice-name mb-0 "
                            htmlFor="input-username"
                          >
                            Late Fee
                          </label>
                          <label
                            className="label-invoice-name mb-0 "
                            htmlFor="input-username"
                          >
                            {displayPrice(lateFee)}
                          </label>
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
              </div>
              <div className="modal-body profile-body invoice-body">
                {invoiceDetail?.items?.length > 0 &&
                  invoiceDetail?.items[0]?.type === "rent" && (
                    <Row>
                      <Col lg="12" className="pad-left-invoice">
                        <FormGroup className="flex-invoice flex-secondary-invoice ">
                          <label
                            className="label-invoice-name mb-0 label-invoice"
                            htmlFor="input-username"
                          >
                            Subtotal
                          </label>
                          <label
                            className="label-invoice-name mb-0 label-invoice"
                            htmlFor="input-username"
                          >
                            {displayPrice(
                              returnFirstDegreeObjValue(invoiceDetail, "amount")
                            )}
                          </label>
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                <Row>
                  <Col lg="12" className="pad-left-invoice">
                    <FormGroup className="flex-invoice nav-last-panel label-invoice">
                      <label
                        className="label-invoice-name mb-0 "
                        htmlFor="input-username"
                      >
                        Total
                      </label>
                      <label
                        className="label-invoice-name primary-color mb-0 "
                        htmlFor="input-username"
                      >
                        {displayPrice(
                          returnFirstDegreeObjValue(invoiceDetail, "amount")
                        )}
                      </label>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              {/* </div> */}
            </Modal>
          </Row>
          <Card className={timeLineModal ? "exportSideBar" : "toggle-sidebar"}>
            <TimeLineSidebar
              type="invoice"
              id={invoiceDetail.invoice_id}
              close={this.closeTimeLineModal}
            />
          </Card>
        </Container>
      </>
    );
  }
}

export default Invoices;
