import React, { useEffect, useState } from "react";
import { Card, Table, Row, Col } from "reactstrap";
import { returnFirstDegreeObjValue } from "constants/utils";
import { displayPrice } from "constants/utils";
import { showLocaldate, } from "../../constants/utils";
import ApiService from "constants/ApiService";

function InvoiceBookingDetails({ bookingId }) {
  const [invoiceList, setInvoiceList] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);

  useEffect(() => {
    getInvoiceData();
  }, [page]);

  async function getInvoiceData() {
    let response = await ApiService.getAPI(
      `host/invoice?page=${page}&limit=${limit}&p_type=rental&booking_id=${bookingId}`
    );
    setInvoiceList(response.data.invoices);
  }

  function handleIncrementPage() {
    if (invoiceList.length >= 10) {
      setPage((prevPage) => {
        return prevPage + 1;
      });
    }
  }

  function handleDecrementPage() {
    if (page > 1) {
      setPage((prevPage) => {
        return prevPage - 1;
      });
    }
  }

  return (
    <>
      <Row>
        <div className="col new-table">
          <Card className="shadow card_with_paginator">
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th style={{ width: '20%' }} scope="col">Invoice Id</th>
                  <th style={{ width: '20%' }} scope="col">Created On</th>
                  <th style={{ width: '20%' }} scope="col">Due On</th>
                  <th style={{ width: '20%' }} scope="col">Amount</th>
                  <th style={{ width: '20%' }} scope="col">Status</th>
                </tr>
              </thead>
              {invoiceList.length !== 0 ? (
                <tbody>
                  {invoiceList.map((invoice, i) => (
                    <tr>
                      <td>{invoice.invoice_id}</td>
                      <td>
                        {showLocaldate(returnFirstDegreeObjValue(invoice, "createdAt"))}
                      </td>
                      <td>
                        {showLocaldate(returnFirstDegreeObjValue(invoice, "due"))}
                      </td>
                      <td>
                        {displayPrice(returnFirstDegreeObjValue(invoice, "amount"))}
                      </td>
                      <td
                        className={
                          invoice.status === "paid"
                            ? "text-capitalize green general_status_style"
                            : invoice.status === "created"
                              ? "text-capitalize yellow general_status_style"
                              : invoice.status === "cancelled"
                                ? "text-capitalize red general_status_style"
                                : "text-capitalize"
                        }
                      >
                        {returnFirstDegreeObjValue(invoice, "status")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td className="no-data">No Invoices Available</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              )}
            </Table>
          </Card>
        </div>
      </Row>
      <Row className="align-items-center jus-space new-margin-page paginator_box">
        <div className="paginate-flex-box ">
          <div className="paginate-box" onClick={() => handleDecrementPage()}>
            <p className={page !== 1 ? "page_available" : "page_unavailable"}>
              Previous
            </p>
          </div>
          <div className="paginate-box" onClick={() => handleIncrementPage()}>
            <p
              className={
                invoiceList.length < limit
                  ? "page_unavailable"
                  : "page_available"
              }
            >
              Next
            </p>
          </div>
        </div>
      </Row>
    </>
  );
}

export default InvoiceBookingDetails;
