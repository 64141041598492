import React, { Component } from "react";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Card,
  Table,
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  Spinner,
  Media,
  Modal,
} from "reactstrap";
// core components
import ApiService from "constants/ApiService";
import { returnFirstDegreeObjValue, getUSFormat } from "constants/utils";
import ToastList from "components/Toast/ToastList";
import showMore from "../../assets/img/brand/showMore.svg";
import returnSecondDegreeObjValue, {
  buildQueryString,
  getDefaultProperty,
  getIsoString,
  pushToFilteredPage,
  returnSearchObject,
  showError,
  showSuccess,
} from "../../constants/utils";
import "react-datepicker/dist/react-datepicker.css";
// import ExpenseSideDragger from "./AddEditExpenseSideBar";
import SubManagerSideDragger from "./SubManagerSideDragger";
import SubManagerProperty from "./SubManagerProperty";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";

class SubManagers extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.state = {
      submanagerlist: [],
      page: 1,
      limit: 10,
      response: "",
      isLoading: true,
      query: {},
      from: "",
      to: "",
      expenseModal: false,
      OpenDeleteModal: false,
      expenseId: "",
      detailView: false,
      invoiceStatusView: "",
      inputSearch: "",
      filterOption: "",
      propertyList: [],
      SubManagerModal: false,
      submanagerId: "",
      submanagerviewId: "",
      SubManagerPropertyModal: false,
      submanager: "",
      btnload: false,
    };
  }

  reloadPage = () => {
    this.props.history.listen((location, search) => {
      if (location.search === "") {
        this.setState({ query: {} }, () => {
          let query = this.props.location.search;
          query = returnSearchObject(query);
          if (Object.keys(query).length === 0) {
            this.handleReset();
          }
        });
      }
    });
  };
  search = () => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
  };
  componentDidMount() {
    this.setQueryStatus();
    this.reloadPage();
  }

  // componentDidUpdate(prevProps) {
  //   let search = this.props.location.search;
  //   if (prevProps.location.search !== search) {
  //     this.setQueryStatus();
  //   }
  // }

  setQueryStatus = () => {
    let queryStatus = this.props.location.search;
    let default_property = getDefaultProperty();

    if (queryStatus) {
      let queryObj = returnSearchObject(queryStatus);

      this.setState(
        {
          inputSearch: (queryObj.manager_name || queryObj.manager_id) ?? "",
          filterOption: queryObj.manager_name
            ? "manager_name"
            : queryObj.manager_id
              ? "manager_id"
              : "",
          // statusSearch: queryObj.is_occupied ?? "",
          // managedBy: queryObj.managed_by ?? "",
          page: parseInt(queryObj.page) ?? "",
          query: returnSearchObject(queryStatus),
        },
        () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getSubManagerList();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...this.state.query,
            page: 1,
            limit: 10,
            // from: getIsoString(this.state.from),
            // to: getIsoString(this.state.to),
            // property_id: default_property,
          },
          // inputSearch: default_property ? default_property : "",
          // filterOption: default_property ? "property_id" : "",
        },
        () => {
          pushToFilteredPage(this.props, this.state.query);

          this.getSubManagerList();
        }
      );
    }
  };

  getSubManagerList = async () => {
    let response = await ApiService.getAPI(
      `host/submanager${buildQueryString(this.state.query)}`
    );
    if (response.success) {
      let { sub_managers, total } = response.data;
      this.setState({
        submanagerlist: sub_managers,
        isLoading: false,
      });
    } else {
      this.setState({ isLoading: false });
      showError(this, response.message);
    }
  };

  // Toast Handler

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleOnFromChange = (e) => {
    this.setState({ from: e });
  };

  handleOnToChange = (e) => {
    this.setState({ to: e });
  };

  openViewMode = (id) => {
    this.setState({ detailView: true, expenseId: id }, () => {
      this.toggleExpenseModal(true);
    });
  };

  handleIncrementPage = () => {
    if (this.state.submanagerlist.length >= 10) {
      this.setState(
        {
          page: this.state.page + 1,
        },
        () => {
          this.handleFilterStatus();
        }
      );
    }
  };
  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 }, () => {
        this.handleFilterStatus();
      });
    }
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    for (let key in staticQuery) {
      if (key === "manager_name" || key === "manager_id") {
        delete staticQuery[key];
      }
    }
    if (this.state.inputSearch !== "" && this.state.filterOption !== "") {
      staticQuery[this.state.filterOption] = this.state.inputSearch;
    }
    staticQuery.from = getIsoString(this.state.from) ?? "";
    staticQuery.to = getIsoString(this.state.to) ?? "";
    staticQuery.page = this.state.page ?? "";

    this.setState({ query: staticQuery }, () => {
      pushToFilteredPage(this.props, { ...this.state.query });
      this.getSubManagerList();
    });
  };

  handleSubmit = () => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  handleReset = () => {
    let default_property = getDefaultProperty();

    let self = this;
    self.setState(
      {
        from: "",
        to: "",
        invoiceStatusView: "",
        page: 1,
        query: {
          page: 1,
          limit: 10,
          property_id: default_property,
        },
        // filterOption: default_property ? "property_id" : "",
        // inputSearch: default_property ? default_property : "",
        filterOption: "",
        inputSearch: "",
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getSubManagerList();
      }
    );
  };

  displayMessage = (msg, type) => {
    if (type == true) {
      showSuccess(this, msg);
    } else {
      showError(this, msg);
    }
  };

  AddeditSubManagerModal = (id) => {
    this.setState({ submanagerId: id }, () => this.toggleSubManagerModal(true));
  };
  toggleSubManagerModal = (status, msg) => {
    this.setState({ SubManagerModal: status });

    if (msg) {
      showSuccess(this, msg);
      this.handleFilterStatus();
    }
    if (!status) {
      this.setState({ submanagerId: "" });
    }
  };
  toggleSubManagerPropertyModal = (status, msg) => {
    this.setState({ SubManagerPropertyModal: status });
    if (msg) {
      showSuccess(this, msg);
      this.handleFilterStatus();
    }
    if (!status) {
      this.setState({ submanagerviewId: "" });
    }
  };
  SubManagerPropertyView = (id) => {
    this.setState(
      { submanagerviewId: id },
      this.toggleSubManagerPropertyModal(true)
    );
  };
  deleteExpense = async (id) => {
    let response = await ApiService.deleteAPI(`host/expenses/${id}`);
    if (response.success) {
      showSuccess(this, response.message);
      this.handleFilterStatus();
    } else {
      showError(this, response.message);
    }
  };

  setInvoiceStatusView = (status) => {
    let from;
    let to;
    this.setState({ invoiceStatusView: status });
    if (status === "tm") {
      from = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      to = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
    } else if (status === "lm") {
      from = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
      to = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
    } else if (status === "ty") {
      from = new Date(new Date().getFullYear(), 0, 1);
      to = new Date(new Date().getFullYear(), 11, 31);
    } else {
      from = "";
      to = "";
    }
    this.setState({ from, to, page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  ActiveBlockSubManger = async (sub_manager_id, status) => {
    let data = {};

    let id = sub_manager_id;

    if (status === "block") {
      let response = await ApiService.patchAPI(
        `host/submanager/activate/${id}`,
        data
      );
      if (response.success) {
        showSuccess(this, response.message);

        this.handleFilterStatus();
      } else {
        showError(this, response.message);
      }
    } else {
      this.setState({ btnload: true });
      let response = await ApiService.patchAPI(
        `host/submanager/block/${id}`,
        data
      );
      if (response.success) {
        this.openDeleteModal(false);
        this.setState({ btnload: false });
        showSuccess(this, response.message);

        this.handleFilterStatus();
      } else {
        this.setState({ btnload: false });
        showError(this, response.message);
      }
    }
  };
  toggleModalDelete = () => {
    this.setState({ OpenDeleteModal: !this.state.OpenDeleteModal });
  };
  openDeleteModal = (value, type) => {
    this.setState({ OpenDeleteModal: value });
  };
  showDeleteModal = (submanager) => {
    this.openDeleteModal(true);

    this.setState({ submanager });
  };
  blockManager = () => {
    const { submanager } = this.state;

    this.ActiveBlockSubManger(submanager.sub_manager_id, submanager.status);
  };

  render() {
    let {
      submanagerlist,
      page,
      isLoading,
      toast,
      response,
      toastType,
      OpenDeleteModal,
      SubManagerModal,
      expenseId,
      detailView,
      invoiceStatusView,
      filterOption,
      inputSearch,
      submanagerId,
      submanagerviewId,
      SubManagerPropertyModal,
      // propertyList,
      btnload,
    } = this.state;
    let self = this;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#012A4D" />
      </div>
    ) : (
      <>
        {toast && <ToastList message={response} type={toastType} />}
        <CustomListingContainer>
          <ListingHeaderLayout label={"Sub Managers"}>
            <div className="flexEnd">
              <div className="invoiceStatusView  mr-10 w40"></div>
              <div className="add-filter-wrap ml-0">
                <Button
                  color="primary"
                  size="sm"
                  className="button-wrapping "
                  onClick={() => this.AddeditSubManagerModal("")}
                >
                  Add
                </Button>
              </div>
            </div>
          </ListingHeaderLayout>
          {/* 
          <InputGroup className="mb-5 flex-content align-left">
            <div className="filters_container">
              <div className="left_filter_container">
                <div className="w40">
                  {" "}
                  <div className="flexStart" style={{ width: "100%" }}>
                    <div className="date-merge w30 f12">Date</div>
                    <div className="w30">
                      <DatePicker
                        selected={this.state.from}
                        maxDate={new Date()}
                        dateFormat="MM-dd-yyyy"
                        placeholderText=""
                        className="filter-input-date point "
                        onChange={this.handleOnFromChange}
                        name="from"
                        id="from"
                        value={this.state.value}
                        style={{ fontWeight: "500" }}
                        showYearDropdown
                        showMonthDropdown
                        ariaDescribedBy="basic-addon2"
                      />
                    </div>

                    <div className="todate-merge">to</div>
                    <div className="w30">
                      <DatePicker
                        selected={this.state.to}
                        maxDate={new Date()}
                        dateFormat="MM-dd-yyyy"
                        placeholderText=""
                        className="to_filter-input-date  point"
                        onChange={this.handleOnToChange}
                        name="to"
                        id="to"
                        value={this.state.value}
                        style={{ fontWeight: "500" }}
                        showYearDropdown
                        showMonthDropdown
                        ariaDescribedBy="basic-addon2"
                      />
                    </div>
                  </div>
                </div>
                <div className="select_with_input ml-10">
                  <Dropdown variant="input-group" className="select_input_drop">
                    <select
                      id="inputGroupSelect01"
                      className="form_select fixed_select point"
                      name="filterOption"
                      value={filterOption}
                      onChange={this.onChangeHandler}
                    >
                      <option value="">Select</option>
                      <option value="manager_name">manager</option>
                      <option value="manager_id">manager id</option>
                    </select>
                  </Dropdown>
                  <Input
                    aria-label="Text input with dropdown button"
                    className="input-filter-toggle"
                    value={inputSearch}
                    name="inputSearch"
                    onChange={this.onChangeHandler}
                  />
                </div>
              </div>
              <div className="right_filter_container">
                <Button
                  color="primary"
                  onClick={this.handleReset}
                  size="sm"
                  className="button-wrapping search-wrapper content-flex mr-4 button-secondary ml-4 reset-btn"
                >
                  Reset
                </Button>
                <Button
                  color="primary"
                  onClick={() => this.search()}
                  size="sm"
                  className="button-wrapping search-wrapper"
                >
                  Search
                </Button>
              </div>
            </div>
          </InputGroup> */}

          <Row>
            <div className="col new-table">
              <Card className="shadow card_with_paginator">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      {/* {submanagerlist.length > 0 && (
                        <th scope="col" className="pad1015"></th>
                      )} */}

                      <th
                        scope="col"
                        className={submanagerlist.length > 0 ? "padl2r" : ""}
                      >
                        Name
                      </th>
                      <th scope="col">Email</th>
                      <th scope="col">Contact</th>
                      <th scope="col">Assigned Properties</th>
                      <th scope="col">Status</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  {submanagerlist.length !== 0 ? (
                    <tbody>
                      {submanagerlist.map((submanager, i) => (
                        <tr key={i} className="point">
                          <td
                            className="text-capitalize cursor-point padl2r"
                          // onClick={() =>
                          //   this.openViewMode(expense.expense_id)
                          // }
                          >
                            {returnFirstDegreeObjValue(submanager, "name")}
                          </td>
                          <td
                          // onClick={() =>
                          //   this.openViewMode(expense.expense_id)
                          // }
                          >
                            {returnFirstDegreeObjValue(submanager, "email")}
                          </td>
                          <td
                          // onClick={() =>
                          //   this.openViewMode(expense.expense_id)
                          // }
                          >
                            {getUSFormat(
                              returnSecondDegreeObjValue(
                                submanager,
                                "phone",
                                "national_number"
                              )
                            )}
                          </td>
                          <td>
                            {submanager?.properties?.length > 0
                              ? submanager.properties.length
                              : "-"}
                          </td>
                          <td>
                            {/* <CustomInput
                              type="switch"
                              checked={submanager?.active}
                              id={submanager?.sub_manager_id}
                              onChange={() =>
                                this.ActiveBlockSubManger(
                                  submanager?.sub_manager_id,
                                  submanager?.active
                                )
                              }
                            /> */}
                            {submanager?.status === "active" ? (
                              <span className="green general_status_style">
                                {" "}
                                Active{" "}
                              </span>
                            ) : (
                              <span className="red general_status_style">
                                Blocked
                              </span>
                            )}
                          </td>
                          <td style={{ width: "7%" }}>
                            <UncontrolledDropdown nav className="position_u">
                              <DropdownToggle className="pr-0 nav-notice" nav>
                                <Media className="align-items-center">
                                  <img src={showMore} alt="" />
                                </Media>
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow min-width-drop "
                                right
                              >
                                <DropdownItem
                                  onClick={() =>
                                    this.AddeditSubManagerModal(
                                      submanager?.sub_manager_id
                                    )
                                  }
                                >
                                  <span>Edit</span>
                                </DropdownItem>
                                {submanager?.properties?.length > 0 && submanager?.status === "active" && (
                                  <DropdownItem
                                    onClick={() =>
                                      this.SubManagerPropertyView(
                                        submanager?.sub_manager_id
                                      )
                                    }
                                  >
                                    <span>Edit Access</span>
                                  </DropdownItem>
                                )}

                                <DropdownItem
                                  onClick={
                                    submanager?.status === "block"
                                      ? () =>
                                        this.ActiveBlockSubManger(
                                          submanager?.sub_manager_id,
                                          submanager?.status
                                        )
                                      : () => this.showDeleteModal(submanager)
                                  }
                                  className="text-capitalize"
                                >
                                  <span>
                                    {submanager?.status === "block"
                                      ? "activate"
                                      : "block"}
                                  </span>
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td className="no-data">No Managers Available</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              </Card>
            </div>
          </Row>
          <Row className="align-items-center jus-space new-margin-page paginator_box">
            <div className="paginate-flex-box ">
              <div
                className="paginate-box"
                onClick={() => this.handleDecrementPage()}
              >
                <p
                  className={page !== 1 ? "page_available" : "page_unavailable"}
                >
                  Previous
                </p>
              </div>
              <div
                className="paginate-box"
                onClick={() => this.handleIncrementPage()}
              >
                <p
                  className={
                    submanagerlist.length < 10
                      ? "page_unavailable"
                      : "page_available"
                  }
                >
                  Next
                </p>
              </div>
            </div>
          </Row>

          <Card
            className={SubManagerModal ? "exportSideBar" : "toggle-sidebar"}
          >
            <SubManagerSideDragger
              close={this.toggleSubManagerModal}
              isOpen={SubManagerModal}
              toast={this.displayMessage}
              id={submanagerId}
            // viewMode={detailView}
            />
          </Card>
          <Card
            className={
              SubManagerPropertyModal ? "exportSideBar" : "toggle-sidebar"
            }
          >
            <SubManagerProperty
              close={this.toggleSubManagerPropertyModal}
              isOpen={SubManagerPropertyModal}
              toast={this.displayMessage}
              id={submanagerviewId}
            />
          </Card>
          <Modal
            className="modal-dialog-centered"
            isOpen={OpenDeleteModal}
            toggle={() => this.toggleModalDelete()}
          >
            <>
              <div className="modal-header modal-invite">
                <h5 className="modal-title" id="exampleModalLabel">
                  Block Manager
                </h5>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.openDeleteModal(false)}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <span className="pad-invite">
                Are you sure you want to block {this.state.submanager?.name} ?
              </span>
              <div className="modal-footer">
                <div className="add-filter-wrap ">
                  <div
                    className="filter-wrap mr-3 edit-prop change-profile-pass "
                    onClick={() => this.openDeleteModal(false)}
                  >
                    <label className="profile-cancel">Cancel</label>
                  </div>
                </div>
                <Button
                  color="primary"
                  type="button"
                  onClick={() => this.blockManager()}
                  className="button-wrapping change-profile-button"
                >
                  {btnload ? (
                    <div className="spin-loading-save w100 h100">
                      <Spinner type="border" color="#012A4D" />
                    </div>
                  ) : (
                    <div>Block</div>
                  )}
                </Button>
              </div>
            </>
          </Modal>
        </CustomListingContainer>
      </>
    );
  }
}

export default SubManagers;
