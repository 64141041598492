import React, { Component } from "react";
import {
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Card,
    Table,
    Container,
    Row,
    Col,
    Button,
    InputGroup,
    Dropdown,
    Input,
    Spinner,
    Media,
    FormGroup,
    InputGroupText,
    Breadcrumb,
    Modal,
    BreadcrumbItem,
} from "reactstrap";
// core components
import ApiService from "constants/ApiService";
import { returnFirstDegreeObjValue } from "constants/utils";
import { filterQuery } from "constants/utils";
import { getIsoString } from "constants/utils";
import ToastList from "components/Toast/ToastList";
import DatePicker from "react-datepicker";
import DateSvg from "../../../assets/img/brand/date-pick.svg";
import showMore from "../../../assets/img/brand/showMore.svg";
import CompleteCancel from "components/Modal/CompleteCancelItem";
import moment from "moment";
import { RoleBasedCRUDAccess } from "views/Roles/RolesUtils";
import returnSecondDegreeObjValue from "constants/utils";
import {
    showError,
    showSuccess,
    showLocaldate,
    buildQueryString,
    pushToFilteredPage,
    returnSearchObject,
} from "constants/utils";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { CustomDatePicker } from "components/FilterFields/Filterfields";
import { makeCapitalize } from "constants/utils";
import { AsscendingDecending } from "components/FilterFields/Filterfields";
import { returnSortKey } from "constants/utils";
import SupplyHistoryDetail from "./SupplyHistoryDetail";

let property_id;

const initialState = {
    name: "",
    frequency: "",
    pickDay: "",
    fromTime: "",
    toTime: "",
    item_id: "",
    pickDatePicker: "",
    toggleWidth: false,
    count: 1,
    InStockcount: 1,
    notifyCount: 0,
    checkedNotify: false
};

const initialModal = {
    deleteModal: false,
    modalOpen: false,
    item_id: "",
    currenStatus: "",
    notes: "",
};

const MemoizedModal = React.memo(CompleteCancel);
class SupplyList extends Component {
    constructor(props) {
        super(props);
        property_id = this.props.match.params?.property_id;
        this.state = {
            supplyList: [],
            total: 0,
            page: 1,
            limit: 10,
            from: "",
            to: "",
            filterToggle: false,
            modalOpen: false,
            inputSearch: "",
            filterOption: "name",
            statusSearch: "",
            toast: false,
            response: "",
            toastType: "",
            initialState,
            initialModal,
            toggleWidth: false,
            history_id: "",
            deleteModal: false,
            isLoading: true,
            query: {},
            btn_enable: true,
            frequency: "Select",
            pickDay: "Select",
            configAssign: false,
            InStockcount: "",
            keyValue: "",
            supplySideBar: false,
            supplyData: "",
            supplyId: "",
            sort: "",
            sortingList: "",
            maxDate: ""
        };
    }
    componentDidMount() {
        this.setQueryStatus();
        // this.getPurchaseList();
        var oneYearFromNow = new Date();
        oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
        this.setState({ maxDate: oneYearFromNow })
    }

    resetState = () => {
        this.setState(initialState);
    };
    resetModalState = () => {
        this.setState(initialModal);
    };

    setQueryStatus = () => {
        let queryStatus = this.props.location.search;
        if (queryStatus) {
            let queryObj = returnSearchObject(queryStatus);
            this.setState(
                {
                    from: queryObj.from ? new Date(queryObj.from) : "",
                    to: queryObj.to ? new Date(queryObj.to) : "",
                    filterOption: queryObj.key ? "name" : "",
                    inputSearch: queryObj.key ?? "",
                    page: parseInt(queryObj.page) ?? "",
                    query: returnSearchObject(queryStatus),
                },
                () => {
                    pushToFilteredPage(this.props, { ...this.state.query });
                    this.getPurchaseList();
                }
            );
        } else {
            this.setState(
                { query: { ...this.state.query, page: 1, limit: 10 } },
                () => {
                    pushToFilteredPage(this.props, this.state.query);
                    this.getPurchaseList();
                }
            );
        }
    };

    getPurchaseList = async () => {
        let { page, filterOption, inputSearch, from, to } = this.state;
        let queryParams = "";
        if (filterOption !== "" && inputSearch !== "")
            queryParams += filterQuery(filterOption, inputSearch);
        if (from !== "") queryParams += filterQuery("from", getIsoString(from));
        if (to !== "") queryParams += filterQuery("to", getIsoString(to));
        if (from > to) {
            showError(this, "Please provide valid From and To date");
        } else {
            let response = await ApiService.getAPI(
                `host/supplyItem/${property_id}${buildQueryString(this.state.query)}`
            );
            if (response.success) {
                let { data } = response;
                this.setState({
                    supplyList: data,
                    isLoading: false,
                });
            } else {
                this.setState({ isLoading: false });
                showError(this, response.message);
            }
        }
    };

    pageChange = (page) => {
        var self = this;
        self.setState({ page: page }, () => {
            self.getPurchaseList();
        });
    };

    updateStateValues = (key, value) => {
        const updatedValue = value == null ? "" : value;
        this.setState({ [key]: updatedValue, input_value: true }, () => {
            if (this.state.from && this.state.to) {
                this.handleFilterStatus();
            }
        });
    };

    handleOnToChange = (e) => {
        this.updateStateValues("to", e);
    };
    handleOnFromChange = (e) => {
        this.updateStateValues("from", e);
    };
    onChangeHandler = (e) => {
        e.target.value === "" && this.handleReset();
        this.setState({ [e.target.name]: e.target.value, input_value: true });
        clearTimeout(this.state.timeoutId);
        const timeoutId = setTimeout(() => {
            this.state.filterOption && this.handleSearch();
        }, 1000);
        this.setState({ timeoutId });
    };
    onChangeSelectHandler = (status) => {
        this.setState({ filterOption: status, input_value: true }, () => {
            this.handleFilterStatus();
        });
    }
    handleIncrementPage = () => {
        if (this.state.supplyList.length >= 10) {
            this.setState({ page: this.state.page + 1 }, () =>
                this.handleFilterStatus()
            );
        }
    };
    handleDecrementPage = () => {
        if (this.state.page > 1) {
            this.setState({ page: this.state.page - 1 }, () =>
                this.handleFilterStatus()
            );
        }
    };

    handleInputChange = (e) => {
        // const regexPattern = /^(?=[A-Za-z])[A-Za-z0-9]*[A-Za-z][A-Za-z0-9]*$/;
        const value = e.target.value;
        // if (regexPattern.test(value)) {
        this.setState({ [e.target.name]: value });
        // }
    };
    handleCountChange = (e) => {
        const value = e.target.value;
        if (value <= 1000 && /^-?\d*$/.test(value)) {
            this.setState({ count: value });
        }
    };
    handleInStockCountChange = (e) => {
        const value = e.target.value;
        if (/^-?\d*$/.test(value)) {
            this.setState({ InStockcount: value });
        }
    };
    handleNotifyCountChange = (e) => {
        const value = e.target.value;
        if (/^-?\d*$/.test(value)) {
            this.setState({ notifyCount: value });
        }
    };
    setDaysMode = (mode) => {
        this.setState({ frequency: mode });
    };
    setWeeksMode = (mode) => {
        this.setState({ pickDay: mode });
    };
    handleSearch = () => {
        this.setState({ page: 1 }, () => {
            this.handleFilterStatus();
        });
    };

    handleReset = () => {
        let self = this;
        self.setState(
            {
                from: "",
                to: "",
                filterOption: "name",
                inputSearch: "",
                query: { page: 1, limit: 10 },
                input_value: false,
                keyValue: "",
                sortingList: "",
            },
            () => {
                pushToFilteredPage(this.props, { ...this.state.query });
                this.getPurchaseList();
            }
        );
    };

    handleFilterStatus = () => {
        let staticQuery = returnSearchObject(this.props.location.search);
        if (this.state.inputSearch !== "" && this.state.filterOption !== "") {
            staticQuery[this.state.filterOption] = this.state.inputSearch;
        }
        staticQuery.sort = this.state.sortingList ?? "";
        staticQuery.page = this.state.page ?? "";
        this.setState({ query: staticQuery }, () => {
            pushToFilteredPage(this.props, { ...this.state.query });
            this.getPurchaseList();
        });
    };

    toggleCanvas = (supply) => {
        let { item_id, item_name, date, frequency, in_stock, supply_count, alert_stock, alert } = supply;
        this.setState({ toggleWidth: true, item_id });
        if (item_id) {
            this.setState({
                name: item_name,
                frequency: frequency,
                pickDatePicker: date ? new Date(date) : "",
                count: supply_count,
                InStockcount: in_stock,
                notifyCount: alert_stock,
                checkedNotify: alert_stock && true
            });
        }
    };

    toggleCloseCanvas = () => {
        this.resetState();
    };

    handleAddEditMaintenance = async () => {
        let {
            name,
            frequency,
            pickDatePicker,
            item_id,
            count,
            InStockcount,
            notifyCount,
            checkedNotify
        } = this.state;

        if (name === undefined || name === null || name.trim() === "") {
            showError(this, "Please enter name");
        } else if (name.trim().length < 3) {
            showError(this, "Please enter valid name");
        } else if (frequency === "Select" || !frequency) {
            showError(this, "Please select frequency");
        } else if ((frequency) && (pickDatePicker === "" ||
            pickDatePicker === null || pickDatePicker === undefined)) {
            showError(this, "Please select date");
        } else if (!count || count <= 0) {
            showError(this, "Please provide supply count");
        } else if (!InStockcount) {
            showError(this, "Please provide in stock count");
        } else if (count > InStockcount) {
            showError(this, "Supply count should be less than or equal to in stock count");
        } else if ((notifyCount || notifyCount >= 1) && checkedNotify === false) {
            showError(this, "Please select the tick box");
        } else if (checkedNotify === true && (!notifyCount || notifyCount < 0)) {
            showError(this, "Please enter the alert count");
        } else {
            this.setState({ btnLoad: true, btn_enable: false });
            let data = {
                item_name: name.trim(),
                frequency: frequency,
                date: getIsoString(pickDatePicker),
                supply_count: +count,
                in_stock: +InStockcount,
                alert_stock: +notifyCount
            };
            let isUpdate = item_id !== "" && item_id !== undefined;
            let response = isUpdate ? await ApiService.patchAPI(`host/supplyItem/${item_id}`, data)
                : await ApiService.postAPI(`host/supplyItem/${property_id}`, data);
            if (response.success) {
                showSuccess(this, response.message);
                this.setState({ toggleWidth: false, btnLoad: false, btn_enable: true, configAssign: true });
                this.getPurchaseList()
                // self.setState({ configAssign: true });
            } else {
                this.setState({ btnLoad: false, btn_enable: true });
                showError(this, response.message);
            }
        }
    };

    handleOnPickDate = (e) => {
        this.updateStateValues("pickDatePicker", e);
    };

    toggleModal = (item_id, key) => {
        this.setState({
            modalOpen: true,
            item_id,
            currenStatus: key,
        });
    };
    handleCloseModal = () => {
        this.resetModalState();
    };
    handleCompleteCancel = async () => {
        let { currenStatus, notes, item_id } = this.state;
        let key = currenStatus === "complete" ? "completed" : currenStatus === "cancel" ? "cancelled" : "delete";
        if (key === "delete") {
            let response = await ApiService.deleteAPI(`host/supplyItem/${item_id}`);
            if (response.success) {
                showSuccess(this, response.message);
                this.resetModalState();
                this.getPurchaseList();
            }
        } else {
            let data = { notes };
            let response = await ApiService.patchAPI(`host/supplyItem/${key}/${item_id}`, data);
            if (response.success) {
                showSuccess(this, response.message);
                this.resetModalState();
                this.getPurchaseList();
            }
        }
    };

    redirectHistoryPage = (id) => {
        this.props.history.push(`/admin/properties/maintenance_history/${id}`);
    };

    toggleAddCanvas = () => {
        this.resetState()
        this.setState({ toggleWidth: true })
    };

    toggleDeleteModal = (item_id, currenStatus) => {
        this.setState({
            deleteModal: true,
            currenStatus,
            item_id,
        });
    };
    closeDeleteModal = () => {
        this.setState({
            configAssign: false,
            deleteModal: false,
            currenStatus: "",
            item_id: "",
        });
    };

    action = (key) => {
        let skey = returnSortKey(this, key);
        this.setState({ keyValue: skey, sortingList: skey, page: 1, input_value: true }, () => { this.handleFilterStatus() })
    }

    setSupplyHistoryDetails = (data) => {
        this.setState({ supplyData: data }, () => this.setState({ supplySideBar: true }))
    };

    closeSupplySideBar = () => {
        this.setState({ supplyData: "", supplySideBar: false });
    };
    setNotify = (e) => {
        this.setState({ checkedNotify: e.target.checked })
    }

    render() {
        let {
            supplyList,
            page,
            inputSearch,
            filterOption,
            isLoading,
            fromTime,
            toast,
            response,
            toastType,
            toggleWidth,
            pickDay,
            pickDatePicker,
            sortingList,
            name,
            notes,
            frequency,
            modalOpen,
            currenStatus,
            deleteModal,
            btnLoad,
            btn_enable,
            from,
            to,
            input_value,
            configAssign,
            count,
            InStockcount,
            supplySideBar,
            supplyData,
            notifyCount,
            checkedNotify
        } = this.state;
        return isLoading ? (
            <div className="spin-loading">
                <Spinner type="border" color="#012A4D" />
            </div>
        ) : (
            <>
                {toast && <ToastList message={response} type={toastType} />}
                <div>
                    <Breadcrumb></Breadcrumb>
                </div>
                <div
                    className={toggleWidth ? "opac-div" : "opac-div-initial"}
                    onClick={() => this.toggleCloseCanvas()}
                ></div>
                <Card
                    className={toggleWidth ? "toggle-sidebar-width" : "toggle-sidebar"}
                >
                    <div className={toggleWidth ? "display-content" : "hide-content"}>
                        <div className="modal-header maintain-canvas pad_new_col">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {this.state.item_id ? "Edit Supply Item" : "Add Supply Item"}
                            </h5>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.toggleCloseCanvas()}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <Col lg="12" className="mt_20 mb_20 pad_new_col">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-first-name"
                                >
                                    Name*
                                </label>
                                <Input
                                    className="form-control-alternative"
                                    value={name}
                                    name="name"
                                    placeholder="Name"
                                    type="text"
                                    onChange={this.handleInputChange}
                                    minLength={3}
                                    maxLength={50}
                                />
                            </FormGroup>
                        </Col>
                        <div>
                            <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                                style={{ marginLeft: "30px" }}
                            >
                                Frequency*
                            </label>
                            <div className="invoiceStatusView" style={{ margin: "0px 30px", borderBottom: "1px solid #e1e1e1" }}>
                                <UncontrolledDropdown className="status_select_div border-none" >
                                    <DropdownToggle className="pr-0 drop_down_container" style={{ paddingLeft: "0px" }}>
                                        <Media className="capitalize" style={{ color: "black", fontSize: "14px" }}>
                                            {frequency === "one_month" ? "One month"
                                                : frequency === "two_months" ? "Two months"
                                                    : frequency === "three_months" ? "Three months"
                                                        : frequency === "four_months" ? "Four months"
                                                            : frequency === "five_months" ? "Five months"
                                                                : frequency === "six_months" ? "Six months"
                                                                    : frequency === "yearly" ? "Yearly" : "Select"}
                                            <i className="fas fa-angle-down"></i>
                                        </Media>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-arrow w100" bottom>
                                        <DropdownItem
                                            className="dropdown_item"
                                            onClick={() => this.setDaysMode("one_month")}
                                        >
                                            One Month
                                        </DropdownItem>

                                        <DropdownItem
                                            className="dropdown_item"
                                            onClick={() => this.setDaysMode("two_months")}
                                        >
                                            Two Months
                                        </DropdownItem>
                                        <DropdownItem
                                            className="dropdown_item"
                                            onClick={() => this.setDaysMode("three_months")}
                                        >
                                            Three Months
                                        </DropdownItem>
                                        <DropdownItem
                                            className="dropdown_item"
                                            onClick={() => this.setDaysMode("four_months")}
                                        >
                                            Four Months
                                        </DropdownItem>
                                        <DropdownItem
                                            className="dropdown_item"
                                            onClick={() => this.setDaysMode("five_months")}
                                        >
                                            Five Months
                                        </DropdownItem>
                                        <DropdownItem
                                            className="dropdown_item"
                                            onClick={() => this.setDaysMode("six_months")}
                                        >
                                            Six Months
                                        </DropdownItem>
                                        <DropdownItem
                                            className="dropdown_item"
                                            onClick={() => this.setDaysMode("yearly")}
                                        >
                                            Yearly
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>
                        </div>
                        <Col lg="12" className="mt_20 mb_20 pad_new_col">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-first-name"
                                >
                                    Date*
                                </label>
                                <InputGroup className="mb-0  flex-content notice-date">
                                    <DatePicker
                                        selected={this.state.pickDatePicker}
                                        dateFormat="MM-dd-yyyy"
                                        placeholderText=""
                                        className="filter-input-date  new-date-in document-add-date point"
                                        onChange={this.handleOnPickDate}
                                        name="purchase_date"
                                        id="purchase_date"
                                        style={{ fontWeight: "500" }}
                                        showYearDropdown
                                        showMonthDropdown
                                        ariaDescribedBy="basic-addon2"
                                        minDate={new Date()}
                                        maxDate={this.state.maxDate}
                                    />
                                    <InputGroupText
                                        id="basic-addon2"
                                        className="group-date doc-add-group notice-group"
                                    >
                                        <label
                                            className="mb-remove cursor-point"
                                            htmlFor="purchase_date"
                                        >
                                            <img
                                                src={DateSvg}
                                                alt="Date-Picker Icon"
                                                className="date-svg point"
                                            />
                                        </label>
                                    </InputGroupText>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        {/* )} */}
                        <Col lg="12" className="mt_20 mb_20 pad_new_col">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-first-name"
                                >
                                    Supply Count*
                                </label>
                                <Input
                                    className="form-control-alternative"
                                    value={count}
                                    name="count"
                                    placeholder="Supply Count"
                                    type="number"
                                    onChange={this.handleCountChange}
                                    max={100}
                                />
                            </FormGroup>
                        </Col>
                        {/* {this.state.item_id && */}
                        <Col lg="12" className="mt_20 pad_new_col">
                            <FormGroup className="mb-0">
                                <label
                                    className="form-control-label"
                                    htmlFor="input-first-name"
                                >
                                    In Stock Count*
                                </label>
                                <Input
                                    className="form-control-alternative"
                                    value={InStockcount}
                                    name="count"
                                    placeholder="In Stock Count"
                                    type="number"
                                    onChange={this.handleInStockCountChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg="12" className="mb_20 pad_new_col">
                            <FormGroup>
                                <div style={{ display: "flex", alignItems: "center", margin: "22px 0px 20px 0px" }}>
                                    <Input style={{ height: "18px", width: "18px" }} checked={checkedNotify}
                                        onChange={(e) => { this.setNotify(e) }}
                                        type="checkbox" id="stockCount" name="stockCount" value="stockCount" className="_m position_u mr-10 ml-0" />
                                    <label className="ml_8 mb_0 point" for="stockCount" style={{ fontSize: "14px", color: "#212121", marginLeft: "10px" }}>Notify me when stock goes to below count</label>
                                </div>
                                {checkedNotify ?
                                    <>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-first-name"
                                        >
                                            Enter the Count*
                                        </label>
                                        <Input
                                            className="form-control-alternative"
                                            value={notifyCount}
                                            name="notifycount"
                                            placeholder="Enter the Count"
                                            type="number"
                                            onChange={this.handleNotifyCountChange}
                                        />
                                    </>
                                    : ""}
                            </FormGroup>
                        </Col>
                        {/* } */}
                        <Row className={toggleWidth ? "submit-side-tab" : "hide-content"}>
                            {/* <Col lg="3"> */}
                            <Button
                                color="primary"
                                onClick={() => this.toggleCloseCanvas()}
                                size="sm"
                                className="button-wrapping sec-btn-wrapping"
                            >
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                onClick={
                                    btn_enable ? () => this.handleAddEditMaintenance() : ""
                                }
                                size="sm"
                                className="button-wrapping"
                            >
                                {btnLoad ? (
                                    <div className="spin-loading-save w100 h100">
                                        <Spinner type="border" color="#012A4D" />
                                    </div>
                                ) : this.state.item_id ? (
                                    " Update"
                                ) : (
                                    "Create"
                                )}
                            </Button>
                            {/* </Col> */}
                        </Row>
                    </div>
                </Card>

                <Row
                    className="align-items-center mb_0"
                    style={{ justifyContent: "space-between", margin: "24px" }}>
                    <Col xs="6" className="manage-con">
                        <Breadcrumb>
                            <BreadcrumbItem onClick={() => this.props.history.push(`/admin/properties`)} className="bread-cursor"  >
                                Properties
                            </BreadcrumbItem>
                            <BreadcrumbItem active style={{ color: "#012a4d" }}>Supply List</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                    {/* <ListingHeaderLayout label={"Supply List"} styleNo={true}> */}
                    <div className="add-filter-wrap">
                        {RoleBasedCRUDAccess("maintenance", "create") && (
                            <Button
                                color="primary"
                                onClick={() => this.toggleAddCanvas()}
                                size="sm"
                                className="button-wrapping"
                            >
                                Add
                            </Button>
                        )}
                    </div>
                    {/* </ListingHeaderLayout> */}
                </Row >
                <CustomListingContainer>

                    <div style={{ margin: "20px" }}>
                        <InputGroup className="flex-content align-left">
                            <div className="filters_container">

                                <div className="select_with_input status_select_input ml-10">
                                    <UncontrolledDropdown className="status_select_div ">
                                        <DropdownToggle className="pr-0 drop_down_container">
                                            <Media className="capitalize">
                                                {filterOption === "name" ? "Name" : "Name"
                                                }
                                                <i className="fas fa-angle-down" style={{ marginLeft: "20px" }}></i>
                                            </Media>
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-arrow" bottom style={{ minWidth: "130px" }}>
                                            {" "}
                                            <DropdownItem
                                                className="dropdown_item"
                                                onClick={() => this.onChangeSelectHandler("name")}
                                            >
                                                Name
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    <Input
                                        aria-label="Text input with dropdown button"
                                        value={inputSearch}
                                        name="inputSearch"
                                        placeholder="search by"
                                        onChange={this.onChangeHandler}
                                    />
                                </div>
                                {input_value && (inputSearch || sortingList) &&
                                    <div className="right_filter_container">
                                        <Button
                                            color="primary"
                                            onClick={this.handleReset}
                                            size="sm"
                                            className="button-wrapping search-wrapper content-flex mr-4 button-secondary ml-4 reset-btn"
                                        >
                                            Clear Filter
                                        </Button>
                                    </div>
                                }
                            </div>
                        </InputGroup>
                    </div>
                    <div>
                        <div className=" new-table">
                            <Card className="shadow card_with_paginator">
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">S.No</th>
                                            <th
                                                scope="col"
                                                className="pl_40 justLeft items-center bnone point"
                                                style={{ width: "100%", display: "flex" }}
                                                onClick={() => this.action("name")}
                                            >
                                                <AsscendingDecending keyValue={this.state.keyValue} value="name" name="Name" />
                                            </th>
                                            <th scope="col">Last Provided</th>
                                            {/* <th scope="col">Current Schedule</th> */}
                                            <th
                                                scope="col"
                                                className="pl_40 justLeft items-center bnone point"
                                                style={{ width: "100%", display: "flex" }}
                                                onClick={() => this.action("schedule")}
                                            >
                                                <AsscendingDecending keyValue={this.state.keyValue} value="schedule" name="Next Schedule" />
                                            </th>
                                            <th scope="col">Supply Count</th>
                                            <th scope="col">In Stock</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    {supplyList.length !== 0 ? (
                                        <tbody>
                                            {supplyList.map((supply, i) => (
                                                <tr key={i}>
                                                    <td> {page * 10 - 10 + (i + 1)}</td>
                                                    <td className="text-capitalize cursor-point tdColumn tdColumn_30"
                                                        onClick={() => this.setSupplyHistoryDetails(supply.item_id)}>
                                                        {returnFirstDegreeObjValue(supply, "item_name")}
                                                    </td>
                                                    <td className="text-capitalize">
                                                        {supply?.createdAt ? showLocaldate(returnFirstDegreeObjValue(supply, "last_schedule")) : "-"}
                                                    </td>
                                                    {/* <td className="text-capitalize">
                                                        {supply?.current_schedule ?
                                                            showLocaldate(supply?.current_schedule?.start_date) + " - " + showLocaldate(supply?.current_schedule?.end_date) : "-"}
                                                    </td> */}
                                                    <td className="cursor-point tdColumn tdColumn_23">
                                                        {showLocaldate(supply.next_schedule?.start_date)}
                                                    </td>
                                                    <td className="text-capitalize">
                                                        {supply.frequency.replace(/_/g, " ") + "-" + returnFirstDegreeObjValue(supply, "supply_count")}
                                                    </td>
                                                    <td className={supply.in_stock === 0 ? "red" : "black"}>
                                                        {returnFirstDegreeObjValue(supply, "in_stock")}
                                                    </td>
                                                    <td>
                                                        <UncontrolledDropdown nav className="position_u">
                                                            <DropdownToggle className="pr-0 nav-notice" nav>
                                                                <Media className="align-items-center">
                                                                    <img src={showMore} />
                                                                </Media>
                                                            </DropdownToggle>
                                                            <DropdownMenu
                                                                className="dropdown-menu-arrow min-width-drop"
                                                                right
                                                            >
                                                                {/* {RoleBasedCRUDAccess("maintenance", "edit") && ( */}
                                                                <DropdownItem
                                                                    onClick={() =>
                                                                        this.toggleCanvas(supply)
                                                                    }
                                                                >
                                                                    <span>Edit</span>
                                                                </DropdownItem>
                                                                {/* )}
                                                                {RoleBasedCRUDAccess("maintenance", "delete") && ( */}
                                                                <>
                                                                    <DropdownItem divider />
                                                                    <DropdownItem
                                                                        onClick={() =>
                                                                            this.toggleDeleteModal(
                                                                                supply.item_id,
                                                                                "delete"
                                                                            )
                                                                        }
                                                                    >
                                                                        <span>Delete</span>
                                                                    </DropdownItem>
                                                                </>
                                                                {/* )} */}
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    ) : (
                                        <tbody>
                                            <tr>
                                                <td className="no-data">
                                                    No Supply Item Available
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    )}
                                </Table>
                            </Card>
                        </div>
                    </div>
                    <div className="align-items-center jus-space new-margin-page paginator_box">
                        <div className="paginate-flex-box ">
                            <div
                                className="paginate-box"
                                onClick={() => this.handleDecrementPage()}
                            >
                                <p
                                    className={page !== 1 ? "page_available" : "page_unavailable"}
                                >
                                    Previous
                                </p>
                            </div>
                            <div
                                className="paginate-box"
                                onClick={() => this.handleIncrementPage()}
                            >
                                <p
                                    className={
                                        supplyList.length < 10
                                            ? "page_unavailable"
                                            : "page_available"
                                    }
                                >
                                    Next
                                </p>
                            </div>
                        </div>
                    </div>
                    <MemoizedModal
                        toggleModal={() => this.toggleModal()}
                        closeModal={() => this.handleCloseModal()}
                        modalState={modalOpen}
                        status={currenStatus}
                        notes={notes}
                        onChange={this.handleInputChange}
                        onSubmit={() => this.handleCompleteCancel()}
                    />
                    <Modal
                        className="modal-dialog-centered"
                        isOpen={deleteModal}
                        toggle={() => this.toggleDeleteModal()}
                    >
                        <>
                            <div className="modal-header modal-invite">
                                <h5
                                    className="modal-title text-capitalize"
                                    id="exampleModalLabel"
                                >
                                    Delete Item
                                </h5>
                                <button
                                    aria-label="Close"
                                    className="close"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() => this.closeDeleteModal()}
                                >
                                    <span aria-hidden={true}>×</span>
                                </button>
                            </div>
                            <span className="pad-new-invite">
                                Are you sure you want to delete this item ?
                            </span>

                            {/* </div> */}
                            <div className="modal-footer">
                                <div className="add-filter-wrap ">
                                    <div
                                        className="filter-wrap mr-3 edit-prop change-profile-pass"
                                        onClick={() => this.closeDeleteModal()}
                                    >
                                        <label className="profile-cancel">Cancel</label>
                                    </div>
                                </div>
                                <Button
                                    color="primary"
                                    type="button"
                                    onClick={() => this.handleCompleteCancel()}
                                    className="button-wrapping change-profile-button text-capitalize"
                                >
                                    submit
                                </Button>
                            </div>
                        </>
                    </Modal>
                    {supplyData && (
                        <Card
                            className={
                                supplySideBar ? "invoice-sidebar payoutSideWidth" : "toggle-sidebar"
                            }
                        >
                            <SupplyHistoryDetail data={supplyData} close={this.closeSupplySideBar} />
                        </Card>)
                    }
                </CustomListingContainer >
            </>
        );
    }
}

export default SupplyList;
