import React, { Component } from "react";
import {
  Input,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Spinner,
  Modal,
} from "reactstrap";
import ApiService from "constants/ApiService";
import constants from "constants/Env";
import "react-datepicker/dist/react-datepicker.css";
import pdfImage from "../../../assets/img/brand/pdf.png";
import ReactTooltip from "react-tooltip";
import Remove from "../../../assets/img/brand/remove.svg";
import RemoveForm from "../../../assets/img/brand/removeForm.svg";
import Add from "../../../assets/img/brand/add.svg";
import ToastList from "components/Toast/ToastList";
import {
  getAuthToken,
  uploadCloudinary,
  showError,
  getPDFCloudinary,
  showSuccess,
} from "constants/utils";
import { Rnd } from "react-rnd";
import PDFViewer from "mgr-pdf-viewer-react";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";

// core components
let booking_id;

class AddDocument extends Component {
  constructor(props) {
    super(props);
    booking_id = this.props.match.params.booking_id;

    this.state = {


      type: "", //"leasing_agreement",
      isPublished: null, //"true",
      pdfArray: [], //[{format:"pdf",public_id: "staging/documents/rqypqbxqpp8z5xbvl5c2",type: "pdf",version: "v1666952221",}],
      redirectZohoMail: false, // true
      mailTemplate: false, // true
      docFields: {}, // {format:"pdf",public_id: "staging/documents/rqypqbxqpp8z5xbvl5c2",type: "pdf",version: "v1666952221",},
      pdfLoading: true, // false
      signingSequence: "", //"2",

      bookingDetails: "",
      hover: false,
      toast: false,
      response: "",
      zohoResponse: null,
      toastType: "",
      checkedSign: false,
      unCheckedSign: false,
      isUpdateLoading: false,
      numPage: null,
      files: [],
      pageNumber: 1,
      imageLoading: false,
      img_value: "",
      numPages: null,

      formFields: [
        {
          id: 1,
          name: "Text",
          x_coord: 0,
          y_coord: 0,
          field_name: "Text",
          field_type: "text",
          field_type_name: "Textfield",
        },
        {
          id: 2,
          name: "Date",
          x_coord: 0,
          y_coord: 0,
          field_name: "Date",
          field_type: "date",
          field_type_name: "Date",
        },
        {
          id: 3,
          name: "Signature",
          x_coord: 0,
          y_coord: 0,
          field_name: "Signature",
          field_type: "image",
          field_type_name: "Signature",
        },
      ],
      activeFields: [],
      activeDrag: 0,
      setPosition: {
        x: 0,
        y: 0,
      },
      disabled: [false, false, false],

      x: 0,
      y: 0,
      activeFieldsLength: 0,
      dataField: {},
      previewModal: false,
      pageFound: 1,
      previewPageFound: 1,
      pdfDocs: [],
      key: 0,
      deleteFields: false,
      signBy: "",
      taskDetails: "",
      isLoading: false,
      pdfUrl: "",
      createLoad: false,
    };
  }

  async componentDidMount() {
    if (booking_id) this.getBookingdetail();

  }

  getBookingdetail = async () => {
    let response = await ApiService.getAPI(`host/booking/all/${booking_id}`);
    if (response.success) {
      const bookingDetails = response.data;

      this.setState({ bookingDetails: bookingDetails });
    }
  };

  // Event handler
  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () =>
      this.handleRedirectingState()
    );
  };

  updateStateValues = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      function () {
        if (value === null) {
          this.setState({
            [key]: "",
          });
        }
        this.handleRedirectingState();
      }
    );
  };
  handleAddEdit = async () => {
    let { type, isPublished, pdfArray, signingSequence, pdfUrl } = this.state;
    if (type === "") showError(this, "Please enter document name");
    else if (isPublished === null)
      showError(this, "Please confirm sigature required or not");
    else if (isPublished === true && signingSequence === "") {
      showError(this, "Please select sequence");
    } else if (Object.keys(pdfArray).length === 0)
      showError(this, "Please upload document");
    else if (isPublished === false) {
      this.setState({ createLoad: true });
      const token = getAuthToken();
      let formData = new FormData();
      formData.append("file", this.state.files[0]); //
      formData.append("is_sign", false);
      formData.append("name", this.state.type);
      formData.append("link", pdfUrl);

      fetch(`${constants.BASE_URL}host/documents/${booking_id}`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.success) {
            this.setState({ createLoad: false });
            showSuccess(this, "Document Created");
            setTimeout(
              () =>
                this.props.history.push(`/admin/bookings/detail/${booking_id}`),
              3000
            );
          } else {
            showError(this, response.message);
          }
        })
        .catch((error) => {
          this.setState({ createLoad: false });
          showError(this, "Document Creation Failed");
        });
    }
  };
  handleRadioInput = (e) => {
    this.setState({
      checkedSign: e.target.checked,
    });
  };
  _handleRadio = (event) => {
    this.setState(
      {
        isPublished: event.target.value === "true" ? true : false,
        signingSequence: "",
      },
      () => this.handleRedirectingState()
    );
  };
  handleSigningSequence = (event) => {
    this.setState({ signingSequence: event.target.value }, () => {
      this.handleRedirectingState();
    });
  };

  redirectMailTemplate = () => {
    let { pdfArray } = this.state;
    this.setState({
      mailTemplate: true,
      docFields: pdfArray[0],
    });
    setTimeout(
      () =>
        this.setState({
          pdfLoading: false,
        }),
      2000
    );
  };
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({
      numPage: numPages,
      pageNumber: 1,
    });
  };
  onImageChangeHandler = async (e) => {
    let self = this;
    let { pdfArray, files } = this.state;

    let file = e.target.files[0];

    if (file.type === "application/pdf") {
      if (file.size < 1000000) {
        this.setState({ imageLoading: true });
        let data = await uploadCloudinary(file, "documents");
        if (data.url) {
          let images = [...pdfArray];
          images.push({
            format: data.format,
            public_id: data.public_id,
            version: `v${data.version}`,
            type: "pdf",
          });
          files = [...files, file];
          self.setState(
            { pdfUrl: data.url, pdfArray: images, imageLoading: false, files },
            () => this.handleRedirectingState()
          );
        } else {
          showError(this, "Could not upload image");
          this.setState({ imageLoading: false });
        }
      } else {
        showError(this, "File size should not exceeds 1 MB");
        this.setState({ imageLoading: false });
      }
    } else {
      showError(this, "Please upload only PDF files");
      this.setState({ imageLoading: false });
    }
  };

  handleRedirectingState = () => {
    let {
      type,

      isPublished,
      pdfArray,
      signingSequence,
    } = this.state;
    type !== "" &&
      isPublished &&
      signingSequence !== "" &&
      Object.keys(pdfArray).length > 0
      ? this.setState({
        redirectZohoMail: true,
      })
      : this.setState({
        redirectZohoMail: false,
      });
  };

  deleteImage = (idx) => {
    let { pdfArray } = this.state;
    let images = [...pdfArray];
    images.splice(idx, 1);
    this.setState({ pdfArray: images });
  };
  onDragStart = (evt, field, i) => {
    let { activeFieldsLength, activeFields } = this.state;
    let page_no = 0;
    page_no = parseInt(document.getElementById("pageNumber").innerHTML);
    let element = evt.currentTarget;
    element.classList.add("dragged");
    evt.dataTransfer.setData("text/plain", evt.currentTarget.id);
    evt.dataTransfer.effectAllowed = "move";
    let dataField = {
      ...field,
      name: field.name,
      id: field.name.concat(activeFields.length.toString()),
      field_label: this.idSetter(field, activeFieldsLength + 1),
      is_mandatory: true,
      page_no: this.state.pageFound,
    };
    this.setState({
      dataField,
    });
  };
  idSetter = (field, i) => {
    return field.name.concat(i.toString());
  };
  onDragEnd = (evt) => {
    evt.currentTarget.classList.remove("dragged");
  };
  onDragEnter = (evt) => {
    evt.preventDefault();
    let element = evt.currentTarget;
    element.classList.add("dragged-over");
    evt.dataTransfer.dropEffect = "move";
  };
  onDragLeave = (evt) => {
    let currentTarget = evt.currentTarget;
    let newTarget = evt.relatedTarget;
    if (newTarget.parentNode === currentTarget || newTarget === currentTarget)
      return;
    evt.preventDefault();
    let element = evt.currentTarget;
    element.classList.remove("dragged-over");
  };
  onDragOver = (evt) => {
    evt.preventDefault();
    evt.dataTransfer.dropEffect = "move";
  };
  onDrop = async (evt, value) => {
    let { activeFields, dataField } = this.state;
    evt.preventDefault();
    evt.currentTarget.classList.remove("dragged-over");
    let arrayOfFields = [...activeFields];

    dataField.x_coord = evt.nativeEvent.layerX;
    dataField.y_coord = evt.nativeEvent.layerY;
    dataField.is_mandatory = true;
    arrayOfFields.push(dataField);
    this.setState({
      activeFields: arrayOfFields,
    });
  };
  handleDragStop = (e, position, i) => {
    let { activeFields } = this.state;
    let updatedPosition = activeFields[i];
    activeFields[i] = updatedPosition;
    this.setPostion(updatedPosition, position.x, position.y, i);
    this.setState({
      activeFields,
    });
  };

  setPostion = (elements, x, y, i) => {
    elements.x_coord = x;
    elements.y_coord = y;
    return elements;
  };
  toggleCloseModal = () => {
    this.setState({
      previewModal: false,
    });
  };
  customNavigation = (props) => {
    let { page, pages, handleNextClick, handlePrevClick } = props;
    return (
      <>
        <div className="navigation_design">
          {" "}
          <h3
            style={{
              cursor: "pointer",
              display: "inline-block",
              marginRight: 24,
              marginTop: 0,
            }}
            onClick={() => {
              handlePrevClick();
              if (page !== 1) {
                this.setState({
                  pageFound: this.state.pageFound - 1,
                });
              }
            }}
          >
            Previous page
          </h3>
          <h3 style={{ display: "inline-block", marginTop: 0 }}>
            Page <span id="pageNumber">{page}</span> from {pages}
          </h3>
          <h3
            style={{
              cursor: "pointer",
              display: "inline-block",
              marginLeft: 24,
              marginTop: 0,
            }}
            onClick={() => {
              handleNextClick();
              if (page !== pages) {
                this.setState({
                  pageFound: this.state.pageFound + 1,
                });
              }
            }}
          >
            Next page
          </h3>
        </div>
      </>
    );
  };
  customPreviewNavigation = (props) => {
    let { page, pages, handleNextClick, handlePrevClick } = props;
    return (
      <>
        {" "}
        <h3
          style={{
            cursor: "pointer",
            display: "inline-block",
            marginRight: 24,
            marginTop: 0,
          }}
          onClick={() => {
            handlePrevClick();
            if (page !== 1) {
              this.setState({
                previewPageFound: this.state.previewPageFound - 1,
              });
            }
          }}
        >
          Previous page
        </h3>
        <h3 style={{ display: "inline-block", marginTop: 0 }}>
          Page <span id="pageNumber">{page}</span> from {pages}
        </h3>
        <h3
          style={{
            cursor: "pointer",
            display: "inline-block",
            marginLeft: 24,
            marginTop: 0,
          }}
          onClick={() => {
            handleNextClick();
            if (page !== pages) {
              this.setState({
                previewPageFound: this.state.previewPageFound + 1,
              });
            }
          }}
        >
          Next page
        </h3>
      </>
    );
  };
  handleOnDrag = (e, position, i) => {
    let { activeFields } = this.state;
    let updatedPosition = activeFields[i];
    updatedPosition.x_coord = position.x;
    updatedPosition.y_coord = position.y;
    activeFields[i] = updatedPosition;
    this.setState({
      activeFields,
    });
  };
  setResize = (elements, i, ref) => {
    elements[i]["height"] = ref.style.height;
    elements[i]["width"] = ref.style.width;
    return elements;
  };
  onResize = (ref, pos, i, direction) => {
    let { activeFields } = this.state;
    let elements = [...activeFields];
    this.setResize(elements, i, ref);
    this.setPostion(elements[i], pos.x, pos.y, i);
    this.setState({
      activeFields: elements,
    });
  };
  previewPDF = () => {
    let { activeFields } = this.state;
    if (activeFields.length === 0) {
      showError(this, "Template should have atleast one form field");
    } else {
      this.setState({
        previewModal: !this.state.previewModal,
      });
    }
  };

  handleDeleteFields = (i, task) => {
    this.setState({
      key: i,
      task_name: task.name,
      deleteFields: true,
    });
  };
  deleteFormIndex = () => {
    let { activeFields, key } = this.state;

    let fields = [...activeFields];
    fields.splice(key, 1);
    this.setState({
      activeFields: fields,
      deleteFields: false,
      task_name: "",
      key: 0,
    });
  };

  handleSignByChange = (event) => {
    let { activeFields, key } = this.state;
    let detail = activeFields.map((field, i) => {
      if (i === key) {
        return { ...field, signBy: event.target.value };
      } else {
        return field;
      }
    });

    this.setState({ signBy: event.target.value, activeFields: detail });
  };

  fieldNameChange = (e) => {
    let { activeFields, key } = this.state;
    let detail = [...activeFields];
    detail[key].name = e.target.value;

    this.setState({ activeFields: detail, task_name: e.target.value });
  };

  createZohoDocument = async () => {
    let { signingSequence, activeFields, bookingDetails } = this.state;
    if (!signingSequence) showError(this, "Select the Signing sequence");
    else if (!activeFields.length)
      showError(this, "Add atleast one Signature field");
    else {
      let hostField = 0;
      let tenantField = 0;
      activeFields.forEach((field) => {
        if (field.field_type_name === "Signature") {
          if (field.signBy === "host" || field.signBy === "manager")
            hostField = hostField + 1;
          else tenantField = tenantField + 1;
        }
      });

      if (signingSequence === "0" && tenantField === 0)
        return showError(this, "Add Tenant Signature field");
      else if (signingSequence === "3" && hostField === 0)
        return showError(
          this,
          `Add ${bookingDetails.property?.manager?.id ? "Manager" : "Host"
          } Signature field`
        );
      else if (signingSequence !== "0" && hostField === 0) {
        return showError(
          this,
          `Add ${bookingDetails.property?.manager?.id ? "Manager" : "Host"
          } Signature field`
        );
      } else if (signingSequence !== "3" && tenantField === 0)
        return showError(this, "Add Tenant Signature field");
      this.setState({ isLoading: true });

      const token = getAuthToken();
      let formData = new FormData();
      formData.append("file", this.state.files[0]); //
      formData.append("is_sign", true);
      formData.append("name", this.state.type);
      formData.append("order", this.state.signingSequence);

      fetch(`${constants.BASE_URL}host/documents/${booking_id}`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
        },
        body: formData,
      })
        .then((response) => response.json())
        .then(async (response) => {
          if (!response.success) {
            this.setState({ isLoading: false });
            return showError(this, response.message);
          }
          const template = response.data;
          this.setState({ zohoResponse: template });

          const hostFieldList = [];
          const tenantFieldList = [];
          this.state.activeFields.forEach((field) => {
            const data = {
              document_id: template.document_id,
              field_name: field.field_name,
              field_label: field.field_label,
              field_type_name: field.field_type_name,
              abs_width:
                field.width && field.width !== "auto"
                  ? Number.isInteger(parseInt(field.width))
                    ? parseInt(field.width)
                    : 100
                  : 100,
              abs_height:
                field.height && field.height !== "auto"
                  ? Number.isInteger(parseInt(field.height))
                    ? parseInt(field.height)
                    : 100
                  : 100,
              x_coord: parseInt(field.x_coord),
              y_coord: parseInt(field.y_coord),
              page_no: field.page_no - 1,
              is_mandatory: field.is_mandatory,
            };
            field.action_id && (data.action_id = field.action_id);
            field.field_id && (data.field_id = field.field_id);
            const isHost =
              field.signBy === "host" || field.signBy === "manager";

            isHost ? hostFieldList.push(data) : tenantFieldList.push(data);
          });

          let { signingSequence } = this.state;

          const actions = [];
          let hostSigningOrder, tenantSigningOrder, hostAction, tenantAction;
          let allActions = template.action_id;

          if (signingSequence === "0") {
            tenantSigningOrder = 1;
            tenantAction = allActions[0];
          } else if (signingSequence === "2") {
            hostSigningOrder = 2;
            tenantSigningOrder = 1;
            hostAction = allActions[1];
            tenantAction = allActions[0];
          } else if (signingSequence === "1") {
            hostSigningOrder = 1;
            tenantSigningOrder = 2;
            hostAction = allActions[0];
            tenantAction = allActions[1];
          } else if (signingSequence === "3") {
            hostSigningOrder = 1;
            hostAction = allActions[0];
          }
          let obj = localStorage.getItem("host_details");

          let bookObj = this.state.bookingDetails.booking;
          let userObj = bookObj.manager ? JSON.parse(obj).host : bookObj.host;
          tenantAction &&
            actions.push(
              this.createAction(
                tenantFieldList,
                tenantSigningOrder,
                tenantAction,
                this.state.bookingDetails.customer
              )
            );
          hostAction &&
            actions.push(
              this.createAction(
                hostFieldList,
                hostSigningOrder,
                hostAction,
                userObj
              )
            );

          const data = {
            requests: {
              actions: actions,
            },
          };

          const updateResponse = await ApiService.patchAPI(
            `host/documents/${template.doc_id}`,
            data
          );
          if (updateResponse.success) {
            showSuccess(this, updateResponse.message);
            this.setState({ isLoading: false });
            setTimeout(
              () =>
                this.props.history.push(`/admin/bookings/detail/${booking_id}`),
              3000
            );
          } else {
            this.setState({ isLoading: false });
            showError(this, updateResponse.message);
          }
        })
        .catch((err) => this.setState({ isLoading: false }));
    }
  };

  createAction = (fields, signing_order, action_id, signer_details) => {
    return {
      recipient_name: this.getName(signer_details.name),
      action_id: action_id,
      recipient_email: signer_details.email,
      action_type: "SIGN",
      is_embedded: false,
      signing_order: signing_order,
      private_notes: "",
      fields: fields,
    };
  };

  getName = (name) => {
    if (typeof name === "string") return name;
    else if (typeof name === "object") return `${name.first} ${name.last}`;
  };

  print() { }
  onImageClick = (e) => {
    this.setState({ [e.target.name]: "" });
  };
  render() {
    let {
      type,
      isUpdateLoading,
      toast,
      response,
      toastType,
      isPublished,
      pdfArray,
      imageLoading,
      redirectZohoMail,
      formFields,
      activeFields,
      docFields,
      pageFound,
      pdfLoading,
      previewModal,
      previewPageFound,
      mailTemplate,
      deleteFields,

      signingSequence,
      bookingDetails,
      key,
      isLoading,
      createLoad,
    } = this.state;
    let pending = formFields;
    return mailTemplate ? (
      <>
        {toast && <ToastList message={response} type={toastType} />}
        <Container className="mt-8 card-pad-wrapper" fluid>
          <div>
            <Breadcrumb className="pad-to-8"></Breadcrumb>
          </div>
          <div className="flex header-div">
            <div>
              <Breadcrumb className="pad-to-8">
                <BreadcrumbItem
                  onClick={() =>
                    this.setState({
                      mailTemplate: false,
                    })
                  }
                  className="bread-cursor"
                >
                  Add Document
                </BreadcrumbItem>
                <BreadcrumbItem active style={{ color: "#012a4d" }}>Template</BreadcrumbItem>
              </Breadcrumb>
            </div>
            <div>
              <Button
                color="primary"
                size="sm"
                onClick={() => this.previewPDF()}
                className="button-wrapping "
              >
                {"Preview"}
              </Button>
              <Button
                color="primary"
                type="button"
                className="button-wrapping change-profile-button"
                onClick={() => this.createZohoDocument()}
              >
                {isLoading ? (
                  <div className="spin-loading-save w100 h100">
                    <Spinner type="border" color="#012A4D" />
                  </div>
                ) : (
                  <div>Submit</div>
                )}
              </Button>
              {/* <Button onClick={() => this.print()}>print</Button> */}
            </div>
          </div>

          <div className="flexing-template mt-5r">
            <div
              className="template-container backcolor jus-center align_center"
              id="App"
            >
              <div
                style={{ width: "594px" }}
                onDragOver={(e) => this.onDragOver(e)}
                onDrop={(e) => this.onDrop(e, true)}
              >
                {pdfLoading ? (
                  <div className="spin-loading">
                    <Spinner type="border" color="#012A4D" />
                  </div>
                ) : (
                  <PDFViewer
                    document={{
                      url: getPDFCloudinary(docFields),
                    }}
                    navbarOnTop={true}
                    navigation={this.customNavigation}
                    page={pageFound}
                  />
                )}
                {activeFields.map((task, i) =>
                  task.page_no === pageFound ? (
                    <Rnd
                      position={{
                        x: task.x_coord,
                        y: task.y_coord,
                      }}
                      size={{
                        width: task.width,
                        height: task.height,
                      }}
                      minWidth={100}
                      maxHeight={1000}
                      enableResizing={true}
                      bounds="parent"
                      // onDrag={(e, pos) => this.handleOnDrag(e, pos, i)}
                      onDragStop={(e, pos) => this.handleDragStop(e, pos, i)}
                      onResize={(e, direction, ref, delta, pos) => {
                        this.onResize(ref, pos, i, direction);
                      }}
                      key={`rnd_${i}`}
                    >
                      <Input
                        type="textarea"
                        value={task.name}
                        className={
                          task.signBy === "manager" || task.signBy === "host"
                            ? "new-form_host"
                            : "new-form"
                        }
                        style={{
                          width: task.width ?? "100px",
                          height: task.height ?? "16px",
                          minWidth: "100px",
                          padding: "0px 4px",
                          textAlign: "center",
                          fontSize: "10px",
                          cursor: "pointer",
                        }}
                        key={task.name}
                        id={task.id}
                        onClick={() => this.handleDeleteFields(i, task)}
                        readOnly
                      />
                    </Rnd>
                  ) : null
                )}
              </div>
            </div>
            <div className="temp-card">
              <select
                className="signingSequence point"
                id="input-first-name"
                name="signingSequence"
                value={signingSequence}
                onChange={this.handleSigningSequence}
              >
                {/* <option>Select</option> */}
                <option value="">Select</option>
                <option value="1">
                  {bookingDetails.property?.manager?.id
                    ? "Manager then Tenant"
                    : "Host then Tenant"}
                </option>
                <option value="2">
                  {bookingDetails.property?.manager?.id
                    ? "Tenant then Manager"
                    : "Tenant then Host"}
                </option>
                <option value="0">Tenant Only</option>
                <option value="3">
                  {bookingDetails.property?.manager?.id
                    ? "Manager Only"
                    : "Host Only"}
                </option>
              </select>

              <Card className="shadow ">
                <CardHeader className="border-0 flex pad1624">
                  <Row className="align-items-center">
                    <Col xs="12">
                      <h3 className="mb-0">Form Fields</h3>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody className="pad15">
                  <Form>
                    <div className="pending small-box">
                      <div className="grid grGap16">
                        {pending.map((task, i) => (
                          <div
                            className=" task"
                            key={task.name}
                            id={task.id}
                            draggable
                            onDragStart={(e) => this.onDragStart(e, task, i)}
                            onDragEnd={(e) => this.onDragEnd(e)}
                          >
                            {task.name}
                          </div>
                        ))}
                      </div>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              {deleteFields && (
                <Card className="shadow mt-4">
                  <CardHeader className="border-0 pad1624">
                    <Row className="align-items-center">
                      <Col lg="6">
                        <h3 className="mb-0 task_name">
                          {activeFields[key].field_name}
                        </h3>
                      </Col>
                      <Col className="text-right" lg="6">
                        <img
                          src={RemoveForm}
                          className="remove-form"
                          alt="Remove Field Image"
                          onClick={() => this.deleteFormIndex()}
                        />
                      </Col>
                    </Row>
                  </CardHeader>

                  <CardBody className="pad1624">
                    <Row>
                      <Col>
                        <label className="fs-12 w100">
                          Field Name
                          <Input
                            type="text"
                            className="input-field"
                            value={activeFields[key].name}
                            onChange={this.fieldNameChange}
                          />
                        </label>
                      </Col>
                    </Row>

                    {/* {activeFields[key].field_name === "Signature" && ( */}
                    <Row>
                      <Col>
                        <label className="fs-12">Sign By</label>
                        <select
                          className="form-control-alternative form-control point"
                          value={activeFields[key].signBy ?? "tenant"}
                          onChange={this.handleSignByChange}
                        >
                          <option
                            value={
                              bookingDetails.property?.manager?.id
                                ? "manager"
                                : "host"
                            }
                          >
                            {bookingDetails.property?.manager?.id
                              ? "Manager"
                              : "Host"}
                          </option>
                          <option value="tenant">Tenant</option>
                        </select>
                      </Col>
                    </Row>
                    {/* )} */}
                  </CardBody>
                </Card>
              )}
            </div>
          </div>

          <Modal
            className="modal-dialog-centered modal-preview"
            isOpen={previewModal}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Preview Template
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => this.previewPDF()}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body profile-body">
              <div className="template-container_preview jus-center align_center">
                <div
                  style={{ width: "594px" }}
                  onDragOver={(e) => this.onDragOver(e)}
                  onDrop={(e) => this.onDrop(e, true)}
                >
                  {pdfLoading ? (
                    <div className="spin-loading">
                      <Spinner type="border" color="#012A4D" />
                    </div>
                  ) : (
                    <div>
                      <PDFViewer
                        document={{
                          url: getPDFCloudinary(docFields),
                        }}
                        navigation={this.customPreviewNavigation}
                        page={previewPageFound}
                      />
                    </div>
                  )}

                  {activeFields.map((task, i) =>
                    task.page_no === previewPageFound ? (
                      <Rnd
                        bounds="parent"
                        position={{
                          x: task.x_coord,
                          y: task.y_coord,
                        }}
                        size={{
                          width: task.width,
                          height: task.height,
                        }}
                        minWidth={100}
                        disableDragging={true}
                        enableResizing={false}
                        key={`rnd_${i}`}
                      >
                        <div
                          className={
                            task.signBy === "manager" || task.signBy === "host"
                              ? "new-form_host"
                              : "new-form"
                          }
                          style={{
                            width: task.width,
                            height: task.height,
                            minWidth: "100px",
                            textAlign: "center",
                            fontSize: "10px",
                            lineHeight: "14px",
                            backgroundColor: "#c4c4c410",
                          }}
                          key={task.name}
                          id={task.id}
                        >
                          {task.name}
                        </div>
                      </Rnd>
                    ) : null
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer mt-4">
              <div className="add-filter-wrap ">
                <div
                  className="filter-wrap mr-3 edit-prop change-profile-pass"
                  onClick={() => this.toggleCloseModal()}
                >
                  <label className="profile-cancel">Cancel</label>
                </div>
              </div>
              <Button
                color="primary"
                type="button"
                className="button-wrapping change-profile-button"
                onClick={() => this.createZohoDocument()}
              >
                {isLoading ? (
                  <div className="spin-loading-save w100 h100">
                    <Spinner type="border" color="#012A4D" />
                  </div>
                ) : (
                  <div>Submit</div>
                )}
              </Button>
            </div>
          </Modal>
        </Container>
      </>
    ) : (
      <>
        {toast && <ToastList message={response} type={toastType} />}
        <Container className="mt-8 card-pad-wrapper" fluid>
          <div>
            <Breadcrumb className="pad-to-8"></Breadcrumb>
          </div>
          <div className="flex header-div">
            <div>
              <Breadcrumb className="">
                <BreadcrumbItem
                  onClick={() => this.props.history.goBack()}
                  className="bread-cursor"
                >
                  Booking Details
                </BreadcrumbItem>
                <BreadcrumbItem active style={{ color: "#012a4d" }}>Add Document</BreadcrumbItem>
              </Breadcrumb>
            </div>
            <div>
              <Button
                color="primary"
                onClick={
                  redirectZohoMail
                    ? () => this.redirectMailTemplate()
                    : () => this.handleAddEdit()
                }
                size="sm"
                className={
                  isUpdateLoading
                    ? "button-wrapping up-prop-btn"
                    : "button-wrapping "
                }
              >
                {redirectZohoMail ? (
                  "Next"
                ) : createLoad ? (
                  <div className="spin-loading-save w100 h100">
                    <Spinner type="border" color="#012A4D" />
                  </div>
                ) : (
                  "Create"
                )}
              </Button>
            </div>
          </div>

          <Row>
            <div className="col new-table">
              <Card className="shadow">
                {/* <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="12">
                      <h3 className="mb-0">Basic Information</h3>
                    </Col>
                  </Row>
                </CardHeader> */}
                <ListingHeaderLayout label={"Basic Information"} />
                <CardBody className="new-card">
                  <Form>
                    <div>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Document Name*
                            </label>

                            <Input
                              value={type}
                              onChange={(e) =>
                                this.setState({
                                  type: e.target.value,
                                })
                              }
                              className="form-control-alternative"
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label mr-3"
                              htmlFor="input-email"
                            >
                              Is Signature required?*
                            </label>
                            <label className="mb-0 mt-1">
                              <div
                                data-tip="Confirm that you need digitized signature or not"
                                data-iscapture="true"
                                data-for="toolTip1"
                                data-place="right"
                              >
                                <i className="fa fa-info-circle tertiary-circle"></i>
                              </div>
                              <ReactTooltip
                                place="right"
                                type="info"
                                effect="solid"
                                id="toolTip1"
                                html={true}
                              />
                            </label>
                            <select
                              className="form-control-alternative form-control point"
                              id="input-first-name"
                              name="isPublished"
                              value={isPublished}
                              onChange={this._handleRadio}
                            >
                              {/* <option>Select</option> */}
                              <option value="">Select</option>
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          {isPublished && (
                            <FormGroup>
                              <label
                                className="form-control-label mr-3"
                                htmlFor="input-email"
                              >
                                Signing Sequence
                              </label>
                              <label className="mb-0 mt-1">
                                <div
                                  data-tip="Mention the Signing Order "
                                  data-iscapture="true"
                                  data-for="toolTip1"
                                  data-place="right"
                                >
                                  <i className="fa fa-info-circle tertiary-circle"></i>
                                </div>
                                <ReactTooltip
                                  place="right"
                                  type="info"
                                  effect="solid"
                                  id="toolTip1"
                                  html={true}
                                />
                              </label>

                              <select
                                className="form-control-alternative form-control point"
                                id="input-first-name"
                                name="signingSequence"
                                value={signingSequence}
                                onChange={this.handleSigningSequence}
                              >
                                <option value="">Select</option>
                                <option value="1">
                                  {bookingDetails.property?.manager?.id
                                    ? "Manager then Tenant"
                                    : "Host then Tenant"}
                                </option>
                                <option value="2">
                                  {bookingDetails.property?.manager?.id
                                    ? "Tenant then Manager"
                                    : "Tenant then Host"}
                                </option>
                                <option value="0">Tenant Only</option>
                                <option value="3">
                                  {bookingDetails.property?.manager?.id
                                    ? "Manager Only"
                                    : "Host Only"}
                                </option>
                              </select>
                            </FormGroup>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <Card className="shadow mt-4">
                {/* <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="12">
                      <h3 className="mb-0">Document*</h3>
                    </Col>
                  </Row>
                </CardHeader> */}
                <ListingHeaderLayout label={"Document*"} />
                <CardBody className="new-card pb-4 pt-4">
                  <Form>
                    <div>
                      <Row>
                        <Col lg="2">
                          {!imageLoading && Object.keys(pdfArray).length > 0 ? (
                            <>
                              <img src={pdfImage} className="pdfImage" alt="" />
                              <img
                                src={Remove}
                                className="ni ni-fat-remove pdf-remove"
                                onClick={() => this.deleteImage(0)}
                                alt=""
                              />
                            </>
                          ) : imageLoading &&
                            Object.keys(pdfArray).length === 0 ? (
                            <div className="image-loading spinner-cut">
                              <Spinner type="border" color="#012A4D" />
                            </div>
                          ) : (
                            <>
                              <div className="add-pdf">
                                <img
                                  src={Add}
                                  className="pdf-add-img mb-1"
                                  alt=""
                                />
                                <span className="c4-add">Add</span>
                                <Input
                                  className="form-control-alternative form-control opac-hide pdf-add-input"
                                  type="file"
                                  id="imageUpload"
                                  value={this.state.img_value}
                                  onChange={(event) =>
                                    this.onImageChangeHandler(event)
                                  }
                                  onClick={(e) => this.onImageClick(e)}
                                  placeholder="Enter Name"
                                  accept="application/pdf,application/vnd.ms-excel"
                                />
                              </div>
                            </>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}
export default AddDocument;
