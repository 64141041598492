import React, { Component } from "react";
import ApiService from "constants/ApiService";
import {
  filterQuery,
  returnThirdDegreeObjValue,
  returnFirstDegreeObjValue,
  showLocaldate,
  displayPrice,
} from "constants/utils";

import { Card, CardBody, CardHeader, Button, Input } from "reactstrap";
import closeIcon from "../../assets/img/brand/close.svg";
import star from "../../assets/img/brand/Star.svg";
import ellipse from "../../assets/img/brand/Ellipse.svg";

class CheckListDragger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      overall_rating: [
        { rate: false },
        { rate: false },
        { rate: false },
        { rate: false },
        { rate: false },
      ],
      btnLoading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.detail !== this.props.detail) {
      this.setDetail();
    }
  }

  setDetail = () => {
    let { detail } = this.props;
    let rating = [...this.state.overall_rating];

    for (let i = 0; i < detail?.rating?.overall_rating; i++) {
      rating[i].rate = true;
    }
    this.setState({ overall_rating: rating });
  };

  closeModal = () => {
    this.props.close();
    this.setState({ payMode: "online_transfer", payDate: "", notes: "" });
  };

  render() {
    let { overall_rating, btnLoading } = this.state;
    let { detail } = this.props;
    return (
      <div>
        {this.props.isOpen && (
          <Card className="bnone">
            <CardHeader>
              <div className="flex">
                <div className="fw-500 f16 cl000000">Checkout Checklist</div>

                <img
                  src={closeIcon}
                  onClick={() => this.closeModal()}
                  className="point"
                />
              </div>
            </CardHeader>
            <CardBody className="pad30p ">
              <div className="mb-40">
                <div className="cl1A1818 f16 fw-500 mb-20">Check List</div>
                <div className="">
                  {detail?.check_list?.items.map((item) => (
                    <div className="base-align mb-20">
                      <input
                        type="checkbox"
                        checked={item?.is_enabled}
                        className="settingCheckbox mr-15"
                      />
                      <p className="point fw-400 cl000000 mb-0 f14 auto-flow">
                        {item?.content}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <div className="cl1A1818 f16 fw-500 mb-30">Review Rated</div>
                <div className="flexmid mb-30">
                  {overall_rating.map((rating) => (
                    <img src={rating.rate ? star : ellipse} className="mr-35" />
                  ))}
                </div>
              </div>
              {detail?.rating?.comment && (
                <div className="mb-30">
                  <div className="cl1A1818 f16 fw-500 mb-20">Comments</div>
                  <div className="cl404040 fw-400 f13">
                    {detail?.rating?.comment}
                  </div>
                </div>
              )}
              <div className="mb-80">
                <div className="cl1A1818 f16 fw-500 mb-20">Other Reviews</div>

                {detail?.rating?.ratings &&
                  Object.entries(detail?.rating?.ratings).map(
                    ([key, value]) => (
                      <div className="flex cl22212F f14 fw-400 capitalize mb-20">
                        <div>{key}</div>
                        <div>{value}</div>
                      </div>
                    )
                  )}
              </div>

              <div className="flexEnd">
                <Button
                  color="primary"
                  className="button-wrapping "
                  size="sm"
                  onClick={() => this.closeModal()}
                >
                  <div>Close</div>
                </Button>
              </div>
            </CardBody>
          </Card>
        )}
      </div>
    );
  }
}

export default CheckListDragger;
