import ToastList from "components/Toast/ToastList";
import ApiService from "constants/ApiService";
import { showError } from "constants/utils";
import removeImg from "../../assets/img/brand/removeIcon.svg";
import showMore from "../../assets/img/brand/showMore.svg";
import closeIcon from "../../assets/img/brand/close.svg";
import ReactTooltip from "react-tooltip";
import {
  showSuccess,
  getUSFormat,
  getCountryCode,
  replaceToNormalNumber,
} from "constants/utils";
import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Spinner,
  FormGroup,
  Input,
  Col,
  Row,
  Form,
  Modal,
  CardFooter,
  Button,
  DropdownItem,
  UncontrolledDropdown,
  Media,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import { isInvalidValue } from "constants/utils";
import { CustomListingContainer } from "components/Container/CustomListingContainer";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      check: false,
      sms: false,
      email: false,
      toast: "",
      response: "",
      toastType: "",
      isLoading: false,
      cname: "",
      phone: "",
      contactList: [],
      contactModal: false,
      contactAction: "",
      btn_loading: false,
      contactId: "",
      country_code: "",
      delLoad: false,
      deleteContactModal: false,
    };
  }

  componentDidMount() {
    this.getDetail();
  }

  getDetail = async () => {
    let response = await ApiService.getAPI(`host`);

    if (response.success) {
      let { host } = response.data;
      this.setState({
        sms: host.preferences.sms === 1 ? true : false,
        email: host.preferences.email === 1 ? true : false,
        contactList: host.add_contacts ?? [],
      });
    }
  };

  onCheckChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  updateDetail = async () => {
    let { email, sms, contactList, phone } = this.state;

    this.setState({ isLoading: true });
    let data = {
      preferences: {
        email: email ? 1 : 0,
        sms: sms ? 1 : 0,
      },
      // add_contacts: contactList,
    };

    let response = await ApiService.patchAPI("host", data);
    if (response.success) {
      showSuccess(this, response.message);
      this.setState({ isLoading: false });
      this.getDetail();
    } else {
      showError(this, response.message);
      this.setState({ isLoading: false });
    }
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleOnPhoneNumberChange = (evt) => {
    this.setState({
      [evt.target.name]: getUSFormat(evt.target.value),
    });
  };
  handleOnCountryCondeChange = (evt) => {
    this.setState({
      [evt.target.name]: getCountryCode(evt.target.value),
    });
  };
  isDublicateName = (cname) => {
    const { contactList, contactId } = this.state;
    let hasDuplicate = false;
    let newContactList = [];
    contactList.forEach((el, ind) => {
      if (ind !== contactId) {
        newContactList.push(el);
      }
    });
    hasDuplicate = newContactList.some(
      (el) => el.name.toLowerCase() === cname.toLowerCase()
    );

    return hasDuplicate;
  };
  isDublicatePhone = (phone) => {
    const { contactList, contactId } = this.state;
    let hasDuplicate = false;
    let newContactList = [];
    let normalformat_number = replaceToNormalNumber(phone);
    contactList.forEach((el, ind) => {
      if (ind !== contactId) {
        newContactList.push(el);
      }
    });
    hasDuplicate = newContactList.some(
      (el) => el.phone.national_number === normalformat_number
    );
    return hasDuplicate;
  };
  handleAddEditContacts = async () => {
    const { contactList, cname, phone, contactId, country_code } = this.state;
    let editcheck = contactId === "";
    if (!cname) {
      showError(this, "Please enter valid name");
    } else if (cname && isInvalidValue(cname)) {
      showError(this, "Please enter valid name");
    } else if (this.isDublicateName(cname)) {
      showError(this, "This name is already exists , please enter unique name");
    } else if (!country_code) {
      showError(this, "Please enter country code");
    } else if (!phone || phone.length < 16) {
      showError(this, "Please enter valid phone number");
    } else if (this.isDublicatePhone(phone)) {
      showError(
        this,
        "This phone number is already exists , please enter different phone number"
      );
    } else {
      this.setState({ btn_loading: true });
      this.staticCrudContacts();
    }
  };
  updateContacts = async (data) => {
    let response = await ApiService.patchAPI("host", data);
    if (response.success) {
      showSuccess(this, response.message);
      this.setState({
        cname: "",
        phone: "",
        country_code: "",
        btn_loading: false,
        contactModal: false,
        deleteContactModal: false,
        delLoad: false,
      });
      this.getDetail();
    } else {
      showError(this, response.message);
      this.setState({
        isLoading: false,
        btn_loading: false,
        delLoad: false,
        contactId: "",
      });
    }
  };
  staticCrudContacts = () => {
    const { contactList, cname, phone, contactId, country_code } = this.state;
    let data;
    let newContactList = [...contactList];
    if (contactId === "") {
      let contact_obj = {
        name: cname,
        phone: {
          country_code: replaceToNormalNumber(country_code),
          national_number: replaceToNormalNumber(phone),
        },
      };

      newContactList.push(contact_obj);
      data = {
        add_contacts: newContactList,
      };

      this.updateContacts(data);
    } else if (contactId >= 0) {
      newContactList[contactId].name = cname;
      newContactList[contactId].phone.national_number =
        replaceToNormalNumber(phone);
      newContactList[contactId].phone.country_code =
        replaceToNormalNumber(country_code);
      data = {
        add_contacts: newContactList,
      };
      this.updateContacts(data);
    }
  };

  openContactModal = (index) => {
    const { contactList } = this.state;
    this.setState({ contactModal: true, contactId: index });
    if (index !== "") {
      this.setState({
        cname: contactList[index]?.name ?? "",
        country_code: contactList[index]?.phone?.country_code
          ? getCountryCode(contactList[index].phone.country_code)
          : "",
        phone: contactList[index]?.phone?.national_number
          ? getUSFormat(contactList[index].phone.national_number)
          : "",
      });
    }
  };
  showErrorModal = () => {
    showError(this, "maximum contacts already exists");
  };
  closeContactModal = () => {
    this.setState({
      contactModal: false,
      cname: "",
      phone: "",
      country_code: "",
      contactId: "",
    });
  };
  openDeleteContactModal = (index) => {
    this.setState({ deleteContactModal: true, contactId: index });
  };
  closeDeleteContactModal = () => {
    this.setState({ deleteContactModal: false, contactId: "" });
  };
  handleContactDelete = () => {
    const { contactList, contactId } = this.state;
    let newContactList = [...contactList];
    newContactList.splice(contactId, 1);
    this.setState({ delLoad: true });
    let data = {
      add_contacts: newContactList,
    };
    this.updateContacts(data);
  };
  render() {
    let {
      sms,
      email,
      toast,
      toastType,
      response,
      isLoading,
      cname,
      phone,
      contactList,
      contactModal,
      btn_loading,
      contactId,
      country_code,
      delLoad,
      deleteContactModal,
    } = this.state;

    return (
      <Container className=" mb_20 profile_body" fluid>
        {toast && <ToastList message={response} type={toastType} />}
        <div className="" style={{ padding: "24px 8px" }}>
          <Card>
            <CardHeader className="bgwhite">
              <div className="flex f15">
                <div className="cl000000 fw-500 f16">Notification</div>
                {isLoading ? (
                  <div className="spin-loading3">
                    <Spinner type="border" color="#012A4D" />
                  </div>
                ) : (
                  <div
                    className="cl525f7f fw-600 point"
                    onClick={() => this.updateDetail()}
                  >
                    Save
                  </div>
                )}
              </div>
            </CardHeader>
            <CardBody>
              <div className="cl2A1E17 f14 fw-400 mb-20">
                Notification to be sent via
              </div>
              <div>
                <div className="flexStart">
                  <div className="flex mr-30">
                    <input
                      type="checkbox"
                      name="sms"
                      checked={sms}
                      autoComplete="new-phone"
                      onChange={this.onCheckChange}
                      maxLength="10"
                      className=" settingCheckbox mr-10"
                      id="sms"
                    />
                    <label
                      className="  point fw-400 cl000000 mb-0 f14 mr-10"
                      htmlFor="sms"
                    >
                      <span> SMS </span>
                    </label>
                  </div>
                  <div className="flex">
                    <input
                      type="checkbox"
                      name="email"
                      checked={email}
                      autoComplete="new-phone"
                      onChange={this.onCheckChange}
                      maxLength="10"
                      className="settingCheckbox mr-10"
                      id="email"
                    />
                    <label
                      className="point fw-400 cl000000 mb-0 f14 mr-10"
                      htmlFor="email"
                    >
                      <span> Email </span>
                    </label>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card className="mt-50">
            <CardHeader className="bgwhite">
              <div className="flex">
                <p className="margin0 f15 fw500 cl000000">
                  Additional Contacts{" "}
                  <span className="cl2A1E17">
                    (Share your notifications with the following persons)
                  </span>
                </p>
                <div
                  className="cl525f7f fw-600 point"
                  onClick={
                    this.state.contactList.length >= 5
                      ? () => this.showErrorModal()
                      : () => this.openContactModal("")
                  }
                >
                  Add
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <div>
                <div className="preference-contact">
                  {contactList.length > 0 &&
                    contactList.map((el, ind) => (
                      <>
                        <Card className="d_flex  each-contacts-wrapper">
                          <div className="contact-action">
                            <UncontrolledDropdown nav className="position_u">
                              <DropdownToggle className="pr-0 nav-notice" nav>
                                <Media className="align-items-center">
                                  <img src={showMore} alt="" />
                                </Media>
                              </DropdownToggle>

                              <DropdownMenu
                                className="dropdown-menu-arrow min-width-drop"
                                style={{ zIndex: 1 }}
                                left
                              >
                                <DropdownItem
                                  onClick={() => this.openContactModal(ind)}
                                >
                                  Edit
                                </DropdownItem>

                                <DropdownItem
                                  onClick={() =>
                                    this.openDeleteContactModal(ind)
                                  }
                                >
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                          <CardBody className="pad15">
                            <div className="each-contacts">
                              <p
                                className="fw-500 f15 cl000000 margin0 pref-name"
                                data-tip={el?.name}
                                data-iscapture="true"
                                data-for="prefname"
                                data-place="top"
                              >
                                {el?.name?.length > 20 ? (
                                  <>
                                    <ReactTooltip
                                      place="right"
                                      type="info"
                                      effect="solid"
                                      id="prefname"
                                      html={true}
                                    />
                                    {el.name.slice(0, 18) + "..."}
                                  </>
                                ) : (
                                  el.name
                                )}
                              </p>
                              <p className="fw-400 f14  margin0 pref-phone">
                                Contact : {""}
                                {el?.phone?.country_code
                                  ? getCountryCode(el.phone.country_code)
                                  : "-"}
                                {"  "}
                                {el?.phone?.national_number
                                  ? getUSFormat(el.phone.national_number)
                                  : "-"}
                              </p>
                            </div>
                          </CardBody>
                        </Card>
                      </>
                    ))}
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        <Modal
          isOpen={deleteContactModal}
          toggle={() => this.closeDeleteContactModal()}
          centered
        >
          <Card>
            <CardBody className="pad25">
              <div className="mb-20 flex">
                <div className=" cl262626 fw-600 f16">Delete</div>
                <div
                  className="clpaid closeModal fw-500 point"
                  onClick={() => this.closeDeleteContactModal()}
                >
                  x
                </div>
              </div>

              <label className="mb-20 cl555555 f15 fw-400">
                Are you sure you want to delete this contact?
              </label>

              <div className="flexEnd">
                <Button
                  color="primary"
                  size="lg"
                  className="button-wrapping search-wrapper  button-secondary ml-4 cancelbtn"
                  onClick={() => this.closeDeleteContactModal()}
                >
                  No
                </Button>
                <Button
                  color="primary"
                  size="lg"
                  className="btn-info"
                  style={{ padding: "10px 18px" }}
                  onClick={() => this.handleContactDelete()}
                >
                  {delLoad ? (
                    <div className="spin-loading-save w100 h100">
                      <Spinner type="border" color="#012A4D" />
                    </div>
                  ) : (
                    "Yes"
                  )}
                </Button>
              </div>
            </CardBody>
          </Card>
        </Modal>
        <Card className={contactModal ? "exportSideBar" : "toggle-sidebar"}>
          {contactModal && (
            <>
              {" "}
              <CardHeader>
                <div className="flex">
                  <div className="fw-500 f16 cl000000">
                    {contactId === "" ? "Add Contact" : "Edit Contact"}
                  </div>

                  <img
                    src={closeIcon}
                    onClick={() => this.closeContactModal()}
                    className="point"
                  />
                </div>
              </CardHeader>
              <CardBody className="pad30p">
                <Form>
                  <div className="">
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Name*
                          </label>

                          <Input
                            className="form-control-alternative"
                            value={cname}
                            name="cname"
                            placeholder="Name"
                            type="text"
                            maxLength="60"
                            onChange={this.handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="12" className="mt-20">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Country Code*
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={country_code}
                            name="country_code"
                            onChange={this.handleOnCountryCondeChange}
                            placeholder="Country Code"
                            type="text"
                            maxLength="5"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="12" className="mt-20">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Phone Number*
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={phone}
                            name="phone"
                            onChange={this.handleOnPhoneNumberChange}
                            placeholder="Phone Number"
                            type="text"
                            maxLength="16"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>

                <div className="flexEnd  mt-30">
                  <div
                    className="mr-20 point f14 button-secondary"
                    onClick={() => this.closeContactModal()}
                  >
                    Cancel
                  </div>
                  <Button
                    color="primary"
                    className="button-wrapping "
                    size="sm"
                    onClick={() => this.handleAddEditContacts()}
                  >
                    {btn_loading ? (
                      <div className="spin-loading-save w100 h100">
                        <Spinner type="border" color="#012A4D" />
                      </div>
                    ) : (
                      <div>{contactId === "" ? "Add" : "Update"}</div>
                    )}
                  </Button>
                </div>
              </CardBody>
            </>
          )}
        </Card>
      </Container>
    );
  }
}

export default Settings;
