import React, { Component } from "react";
import {
  Card,
  Table,
  Container,
  Row,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  CustomInput,
  Spinner,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
// core components
// import { toast, ToastContainer } from "react-toast";
import ApiService from "constants/ApiService";
import { returnFirstDegreeObjValue } from "constants/utils";
import { isInvalidEmail } from "constants/utils";
import { displayPrice } from "constants/utils";
import SendInvite from "components/Modal/SendInvite";
import ToastList from "components/Toast/ToastList";
import { showError } from "constants/utils";
import { showSuccess } from "constants/utils";
import moment from "moment";
import ExportPropertySideBar from "../ExportPropertySideBar";
import { returnCustomerFirsLastName } from "constants/utils";
import { showLocaldate } from "constants/utils";
import showMore from "../../../assets/img/brand/showMore.svg";
import PropertyHistory from "../PropertyHistorySidebar";
import { returnSearchObject } from "constants/utils";
import { RoleBasedCRUDAccess } from "views/Roles/RolesUtils";
import ReactTooltip from "react-tooltip";
import { pushToFilteredPage, buildQueryString, replaceToNormalNumber, getUSFormat } from "../../../constants/utils";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
let property_id;

class Rooms extends Component {
  constructor(props) {
    super(props);
    property_id = this.props.match.params.property_id;
    this.state = {
      roomList: [],
      total: 0,
      page: 1,
      modalOpen: false,
      check: false,
      email: "",
      actual_start: "",
      room_id: "",
      invite_id: "",
      link: false,
      url: "",
      toast: false,
      response: "",
      toastType: "",
      filterToggle: false,
      isLoading: true,
      inviteStatus: "",
      imageLoading: false,
      exportModal: false,
      inviteData: "",
      property_name: "",
      sortingList: "",
      query: {},
      selectedScreening: "",
      screeninglist: [
        { name: "Required", value: true },
        { name: "Not Required", value: false },
      ],
      input_value: false,
      phoneNumber: "",
    };
  }
  componentDidMount() {
    this.setState({ selectedScreening: "Not Required" });
    this.getRoomList();
    let queryParams = returnSearchObject(this.props.location.search);
    this.setState({ property_name: queryParams?.name });
  }
  getRoomList = async () => {
    let { page, property_name } = this.state;
    let queryParams = `?page=${page}&limit=10`;

    if (property_name) {
      queryParams += `&name=${property_name}`;
    }
    let response = await ApiService.getAPI(
      `host/room/${property_id}${queryParams}${buildQueryString(
        this.state.query
      )}`
    );

    if (response.success) {
      let { rooms, total } = response.data;
      this.setState({ roomList: rooms, total: total, isLoading: false });
    }
  };
  pageChange = (page) => {
    var self = this;
    self.setState({ page: page }, () => {
      self.getRoomList();
    });
  };

  redirectAddPage = () => {
    this.props.history.push(`/admin/room/add/${property_id}`);
  };
  redirectEditPage = (room_id) => {
    this.props.history.push(`/admin/room/edit/${room_id}`);
  };

  deleteRoom = async (id) => {
    if (id) {
      let response = await ApiService.deleteAPI(`host/room/${id}`);
      if (response.success) {
        showSuccess(this, response.message);
        this.getRoomList();
      } else {
        showError(this, response.message);
      }
    }
  };

  handleIncrementPage = () => {
    this.setState({ page: this.state.page + 1 }, () =>
      this.getRoomList(this.state.page)
    );
  };
  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 }, () =>
        this.getRoomList(this.state.page)
      );
    }
  };

  handleState = (value) => {
    this.setState({ selectedScreening: value });
  };

  toggleModal = async (id, invite) => {
    if (invite) {
      let response = await ApiService.getAPI(`host/invite/${invite}`);
      if (response.success) {
        let data = response.data.invite;
        let phone = data.phone ? getUSFormat(data?.phone?.national_number) : "";
        this.setState({
          modalOpen: !this.state.modalOpen,
          room_id: id,
          invite_id: invite,
          email: data.email,
          phoneNumber: phone,
          inviteStatus: data.status,
          actual_start: moment(data.actual_start).toDate(),
          check: data.is_paid,
          des: data.description,
          selectedScreening: data.screening,
          inviteData: data,
        });
      }
    } else {
      let response = await ApiService.getAPI(`host/room/detail/${id}`)
      if (response.success) {
        this.setState({
          selectedScreening: response.data.room?.screening,
        })
      }
      this.setState({
        modalOpen: !this.state.modalOpen,
        room_id: id,
        inviteStatus: "",
        invite_id: invite,
      });
    }
  };
  
  toggleCloseModal = () => {
    this.setState({
      modalOpen: false,
      link: false,
      url: "",
      email: "",
      phoneNumber: "",
      actual_start: "",
      des: "",
      check: false,
    });
    this.getRoomList();
  };
  handleOnCheckChange = () => {
    this.setState({ check: !this.state.check });
  };
  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleOnNoticeChange = (e) => {
    this.updateStateValues("actual_start", e);
  };
  updateStateValues = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      function () {
        if (value === null) {
          this.setState({
            [key]: "",
          });
        }
      }
    );
  };
  handleInvite = async () => {
    let { email, actual_start, des, room_id, check, selectedScreening, invite_id, phoneNumber } = this.state;
    if (!email && !phoneNumber) {
      showError(this, "Please enter email or phone number");
    } else if (email && isInvalidEmail(email)) {
      showError(this, "Please enter valid email");
    } else if (phoneNumber && phoneNumber.length < 16) {
      showError(this, "Please enter valid phone number");
    } else if (actual_start === "") {
      showError(this, "Please select checkin date");
    } else {
      let data = {
        is_paid: check,
        actual_start: new Date(moment(actual_start).format("YYYY-MM-DD")),
        email,
        description: des,
        property_id,
        room_id,
        screening: selectedScreening,
        phone: {
          country_code: "1",
          national_number: replaceToNormalNumber(phoneNumber),
        },
      };
      if (invite_id) {
        let response = await ApiService.patchAPI(`host/invite/${invite_id}`, data);
        if (response.success) {
          let { link } = response.data;
          showSuccess(this, response.message);
          this.setState({ link: true, url: link });
          this.getRoomList();
        } else {
          showError(this, response.message);
        }
      } else {
        let response = await ApiService.postAPI(`host/invite`, data);
        if (response.success) {
          let { link } = response.data;
          showSuccess(this, response.message);
          this.setState({ link: true, url: link });
          this.getRoomList();
        } else {
          showError(this, response.message);
        }
      }
    }
  };
  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    // staticQuery.from = getIsoString(this.state.from) ?? "";
    // staticQuery.to = getIsoString(this.state.to) ?? "";
    // staticQuery.status = this.state.statusSearch ?? "";
    // staticQuery.page = this.state.page ?? "";
    staticQuery.sort = this.state.sortingList ?? "";
    this.setState({ query: staticQuery }, () => {
      pushToFilteredPage(this.props, { ...this.state.query });
      this.getRoomList();
    });
  };

  handleOnPhoneNumberChange = (evt) => {
    this.setState({ phoneNumber: getUSFormat(evt?.target.value) });
  };

  copyLink() {
    let { url } = this.state;
    if (url) {
      navigator.clipboard.writeText(url);
      showSuccess(this, "Invite Link Copied");
    }
  }
  updateEnable = async (type, checkValue, id) => {
    var self = this;
    var data = {};
    checkValue ? (data[type] = false) : (data[type] = true);
    let response = await ApiService.patchAPI(`host/room/${id}`, data);
    if (response.success) {
      showSuccess(this, response.message);
      self.getRoomList();
    } else {
      showError(this, response.message);
    }
  };



  openExportSideBar = () => {
    this.setState({ exportModal: true });
  };

  toastDisplay = (msg) => {
    showError(this, msg);
  };

  closeExportSideBar = (response) => {
    !response && this.setState({ exportModal: false });
    if (response) {
      if (response.success) {
        showSuccess(this, response.message);
        this.setState({ exportModal: false });
        this.getRoomList();
      } else {
        showError(this, response?.message ?? response);
      }
    }
  };

  openHistoryModal = (id) => {
    this.setState({ historyModal: true, room_id: id });
  };

  closeHistoryModal = () => {
    this.setState({ historyModal: false });
  };

  redirectBookingPage = async (id) => {
    let response = await ApiService.getAPI(`host/booking/booking_id/${id}`);
    if (response.success) {
      let { booking_id } = response.data;
      if (booking_id) {
        this.props.history.push(`/admin/bookings/detail/${booking_id}`);
      }
    }
  };
  setSortingList = (status) => {
    this.setState({ sortingList: status, page: 1, input_value: true }, () => {
      this.handleFilterStatus();
    });
  };
  handleReset = () => {
    let self = this;
    self.setState(
      {
        sortingList: "",
        input_value: false
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getRoomList();
      }
    );
  };
  render() {
    let {
      roomList,
      page,
      check,
      email,
      des,
      link,
      url,
      modalOpen,
      toast,
      response,
      toastType,
      isLoading,
      inviteStatus,
      invite_id,
      exportModal,
      phoneNumber,
      inviteData,
      room_id,
      historyModal,
      property_name,
      sortingList,
      screeninglist,
      selectedScreening,
      input_value
    } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#012A4D" />
      </div>
    ) : (
      <>
        {toast && <ToastList message={response} type={toastType} />}

        {/* <div xs="6" className="manage-con" style={{ width: "50%" }}>
          <Breadcrumb>
            <BreadcrumbItem
              onClick={() => this.props.history.goBack()}
              className="bread-cursor"
            >
              Properties
            </BreadcrumbItem>
          </Breadcrumb>
        </div> */}

        <div className="align-items-center" style={{ margin: "20px 20px 0px 20px" }}>
          <div className="containerListing_name flex">
            <Breadcrumb>
              <BreadcrumbItem
                onClick={() => this.props.history.goBack()}
                className="bread-cursor"
              >
                Properties
              </BreadcrumbItem>
              <BreadcrumbItem active style={{ color: "#012a4d" }}>{property_name}</BreadcrumbItem>
            </Breadcrumb>
          </div>
        </div>
        <CustomListingContainer>
          <ListingHeaderLayout label="Room">
            <div className="flexEnd">
              <div className="flexEnd invoiceStatusView"
                style={{ width: "170px", marginLeft: "15px" }}>
                <UncontrolledDropdown className="status_select_div ">
                  <DropdownToggle className="pr-0 drop_down_container">
                    <Media className="capitalize">
                      {sortingList === "user_asc"
                        ? "Tenant Asc"
                        : sortingList === "user_dsc"
                          ? "Tenant Dsc"
                          : sortingList === "check_in_asc"
                            ? "Check in Asc"
                            : sortingList === "check_in_dsc"
                              ? "Check in Dsc"
                              : sortingList === "check_out_asc"
                                ? "Check out Asc"
                                : sortingList === "check_out_dsc"
                                  ? "Check out Dsc"
                                  : "Select"}
                      <i className="fas fa-angle-down"></i>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" bottom>
                    {" "}
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.setSortingList("user_asc")}
                    >
                      Tenant Asc
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.setSortingList("user_dsc")}
                    >
                      Tenant Dsc
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.setSortingList("check_in_asc")}
                    >
                      Check in Asc
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.setSortingList("check_in_dsc")}
                    >
                      Check in Dsc
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.setSortingList("check_out_asc")}
                    >
                      Check out Asc
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.setSortingList("check_out_dsc")}
                    >
                      Check out Dsc
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              <div style={{ marginLeft: "15px" }}>
                {RoleBasedCRUDAccess("property", "import") && (
                  <Button
                    color="secondary"
                    size="sm"
                    onClick={() => this.openExportSideBar()}
                    className="secoundary-btn-wrapping button-wrapping"

                  >
                    Import
                  </Button>
                )}
                {RoleBasedCRUDAccess("property", "create") && (
                  <Button
                    color="primary"
                    onClick={() => this.redirectAddPage()}
                    size="sm"
                    className="button-wrapping"
                  >
                    Add
                  </Button>
                )}

              </div>
              {input_value &&
                <div className="" style={{ marginLeft: "15px" }}>
                  <Button
                    color="primary"
                    onClick={this.handleReset}
                    size="sm"
                    className="button-wrapping mr-4 button-secondary reset-btn"
                    style={{ fontSize: "15px" }}                  >
                    Clear filter
                  </Button>
                </div>
              }
            </div>
          </ListingHeaderLayout>
          <div>
            <Card className="shadow">
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Room</th>
                    <th scope="col">Tenant</th>
                    <th scope="col">Check-In</th>
                    <th scope="col">Check-Out</th>
                    <th scope="col">Occupancy</th>
                    <th scope="col">Rent</th>
                    <th scope="col">Invite</th>
                    <th scope="col">Enable</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                {roomList.length !== 0 ? (
                  <tbody>
                    {roomList.map((room, i) => (
                      <tr key={i}>
                        <td>
                          {" "}
                          {returnFirstDegreeObjValue(
                            room,
                            "room_number"
                          )} / {returnFirstDegreeObjValue(room, "room_type")}
                        </td>
                        <td
                          className="text-capitalize point"
                          onClick={() =>
                            room?.user &&
                            this.redirectBookingPage(room.room_id)
                          }
                        >
                          {returnCustomerFirsLastName(
                            room,
                            "user",
                            "name",
                            "first",
                            "last"
                          )}
                        </td>
                        <td>
                          {showLocaldate(
                            returnFirstDegreeObjValue(room, "check_in")
                          )}
                        </td>
                        <td>
                          {showLocaldate(
                            returnFirstDegreeObjValue(room, "check_out")
                          )}
                        </td>
                        <td
                          className={
                            room?.is_occupied
                              ? "text-capitalize red"
                              : "text-capitalize green"
                          }
                        >
                          {room.is_occupied === true ? "Active" : room.is_occupied && room.check_in && room.check_out ? "In Notices" : "Available"}
                          {/* {returnFirstDegreeObjValue(room, "is_occupied") ===
                              true
                              ? "Active"
                              :  "Available" :""} */}
                        </td>
                        <td>
                          {" "}
                          {displayPrice(
                            returnFirstDegreeObjValue(room, "price")
                          )}
                        </td>

                        <td
                          className="cursor-point"
                          onClick={
                            RoleBasedCRUDAccess("property", "edit")
                              ? () =>
                                room.is_enabled &&
                                (!room?.is_occupied ||
                                  (!room?.pre_booking &&
                                    room?.check_out !== null)) &&
                                this.toggleModal(
                                  room?.room_id,
                                  room?.invite_id
                                )
                              : ""
                          }
                        >
                          {RoleBasedCRUDAccess("property", "edit") ? (
                            <>
                              {room?.is_enabled &&
                                (!room?.is_occupied ||
                                  (!room?.pre_booking &&
                                    room?.check_out !== null))
                                ? room?.invite_id
                                  ? "View"
                                  : "Send"
                                : "-"}
                            </>
                          ) : (
                            "-"
                          )}

                          {/* {room.invite_id &&
                            ((!room.is_occupied && room.is_enabled) ||
                              (!room?.pre_booked && room?.check_out !== null))
                              ? "View"
                              : !room.invite_id &&
                                ((!room.is_occupied && room.is_enabled) ||
                                  (!room?.pre_booked &&
                                    room?.check_out !== null))
                              ? "Send"
                              : "-"} */}
                        </td>
                        <td>
                          <span
                            data-tip="you dont have access to edit property"
                            data-for="custom-switch"
                            data-place="top"
                            data-iscapture="true"
                            className="point property_flexmid"
                          >
                            <CustomInput
                              type="switch"
                              checked={room.is_enabled}
                              id={room.room_id}
                              disabled={
                                !RoleBasedCRUDAccess("property", "edit")
                              }
                              onChange={() =>
                                this.updateEnable(
                                  "is_enabled",
                                  room.is_enabled,
                                  room.room_id
                                )
                              }
                            />
                            {!RoleBasedCRUDAccess("property", "edit") && (
                              <ReactTooltip
                                place="top"
                                type="info"
                                effect="solid"
                                id="custom-switch"
                                html={true}
                              />
                            )}
                          </span>
                        </td>
                        <td>
                          <UncontrolledDropdown nav className=" position_u">
                            <DropdownToggle nav className="pr-0 nav-notice">
                              <Media className="align-items-center">
                                <img src={showMore} />
                              </Media>
                            </DropdownToggle>
                            <DropdownMenu
                              className="dropdown-menu-arrow min-width-drop"
                              right
                            >
                              {RoleBasedCRUDAccess("property", "edit") && (
                                <>
                                  <DropdownItem
                                    onClick={() =>
                                      this.redirectEditPage(room.room_id)
                                    }
                                  >
                                    Edit
                                  </DropdownItem>
                                  <DropdownItem divider />
                                </>
                              )}
                              {RoleBasedCRUDAccess("property", "delete") && (
                                <>
                                  {" "}
                                  <DropdownItem
                                    onClick={() =>
                                      this.deleteRoom(room.room_id)
                                    }
                                  >
                                    Delete
                                  </DropdownItem>
                                  <DropdownItem divider />
                                </>
                              )}

                              <DropdownItem
                                onClick={() =>
                                  this.openHistoryModal(room.room_id)
                                }
                              >
                                History
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td className="no-data">No Rooms Available</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                )}
              </Table>
            </Card>
            <SendInvite
              toggleModal={() => this.toggleModal()}
              modalState={modalOpen}
              closeModal={() => this.toggleCloseModal()}
              updatePropertyList={() => this.getRoomList()}
              onSubmit={() => this.handleInvite()}
              onInputChange={this.handleInputChange}
              onChangeDate={this.handleOnNoticeChange}
              actual_start={this.state.actual_start}
              check={check}
              status={inviteStatus}
              email={email}
              phoneNumber={phoneNumber}
              handleOnPhoneNumberChange={this.handleOnPhoneNumberChange}
              onCheckChange={this.handleOnCheckChange}
              description={des}
              invite_id={invite_id}
              link={link}
              url={url}
              copyLink={() => this.copyLink(url)}
              data={inviteData}
              property_id={property_id}
              room_id={room_id}
              screeninglist={screeninglist}
              selectedScreening={selectedScreening}
              change={this.handleState}
            />
          </div>
          <Row className="align-items-center jus-space new-margin-page paginator_box">
            <div className="paginate-flex-box ">
              <div
                className="paginate-box"
                onClick={() => this.handleDecrementPage()}
              >
                <p
                  className={page !== 1 ? "page_available" : "page_unavailable"}
                >
                  Previous
                </p>
              </div>
              <div
                className="paginate-box"
                onClick={() => this.handleIncrementPage()}
              >
                <p
                  className={
                    roomList.length < 10 ? "page_unavailable" : "page_available"
                  }
                >
                  Next
                </p>
              </div>
            </div>
          </Row>
          <Card className={exportModal ? "exportSideBar" : "toggle-sidebar"}>
            <ExportPropertySideBar
              close={this.closeExportSideBar}
              open={exportModal}
              propertyId={property_id}
              toast={this.toastDisplay}
            />
          </Card>
          <Card className={historyModal ? "exportSideBar" : "toggle-sidebar"}>
            <PropertyHistory
              close={this.closeHistoryModal}
              open={historyModal}
              roomId={room_id}
              toast={this.toastDisplay}
              propertyId={property_id}
            />
          </Card>
        </CustomListingContainer >
      </>
    );
  }
}

export default Rooms;
