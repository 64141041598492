import React, { Component } from "react";
import ApiService from "constants/ApiService";
import { Card, CardBody, CardHeader, Spinner, Button, Input } from "reactstrap";
import closeIcon from "../../assets/img/brand/close.svg";
import downloadImg from "../../assets/img/brand/UploadDocumentImage.svg";

class ExportPropertySideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: [],

      imageLoading: false,
      btnLoading: false,
    };
  }

  importExcel = async (e) => {
    let { image } = this.state;
    let file = e.target.files[0];
    if (e.target.files.length > 0) {
      this.setState({ imageLoading: true });
    }
    if (
      file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      if (file.size < 5 * 1000000) {
        let imgArr = [...image];
        imgArr.push(file);
        this.setState({ image: imgArr, imageLoading: false });
      } else {
        this.props.toast("File size should not exceeds 5 MB");
        this.setState({ imageLoading: false });
      }
    } else {
      this.props.toast("Invalid Format");
      this.setState({ imageLoading: false });
    }
  };

  deleteFile = () => {
    let { image } = this.state;
    let imgArr = [...image];
    imgArr.pop();
    this.setState({ image: imgArr });
  };

  importApi = async () => {
    let { image } = this.state;
    if (image.length <= 0) {
      this.props.close("Please upload file");
    } else {
      this.setState({ btnLoading: true });
      let formData = new FormData();
      formData.append("file", image[0]);
      if (this.props.propertyId) {
        let response = await ApiService.uploadDoc(
          `host/room/import/${this.props.propertyId}`,
          formData
        );
        if (response.success) {
          this.props.close(response);
          this.setState({ image: [], btnLoading: false });
        } else {
          this.props.close(response);
          this.setState({ btnLoading: false });
        }
      } else {
        let response = await ApiService.uploadDoc(
          "host/properties/import",
          formData
        );
        if (response.success) {
          this.props.close(response);
          this.setState({ image: [], btnLoading: false });
        } else {
          this.props.close(response);
          this.setState({ btnLoading: false });
        }
      }
    }
  };

  closeModal = () => {
    this.props.close();
    this.setState({ image: [] });
  };

  render() {
    let { image, imageLoading, btnLoading } = this.state;
    return (
      <div>
        {this.props.open ? (
          <Card className="bnone">
            <CardHeader>
              <div className="flex">
                <div className="fw-500 f16 cl000000">
                  Bulk Import {this.props.propertyId ? "Rooms" : "Properties"}
                </div>
                {/* <button
                onClick={() => this.closeModal()}
                className="close fw-500 cl000000"
              >
                X
              </button> */}
                <img
                  src={closeIcon}
                  onClick={() => this.closeModal()}
                  className="point"
                  alt=""
                />
              </div>
            </CardHeader>
            <CardBody className="pad30p">
              <div className="mb-20 f14 fw-500 downloadLink">
                Click
                <a
                  href={
                    this.props.propertyId
                      ? "https://res.cloudinary.com/parknstays/raw/upload/v1669120763/Parknstays_room_import_p4ugfk.xlsx"
                      : "https://res.cloudinary.com/parknstays/raw/upload/v1669120763/Parknstays_import_properties_af0kp6.xlsx"
                  }
                  className=" here"
                >
                  {" "}
                  here{" "}
                </a>
                to download the Sample File for your Reference
              </div>
              <div className=" excelImport mb-30">
                {imageLoading ? (
                  <div className="spin-loading3">
                    <Spinner type="border" color="#012A4D" />
                  </div>
                ) : image.length > 0 ? (
                  <div className="flex">
                    <div className="mr-5">{image[0].name}</div>
                    <img
                      src={closeIcon}
                      onClick={() => this.deleteFile()}
                      className="point"
                      alt=""
                    />
                  </div>
                ) : (
                  <>
                    <label
                      htmlFor="xlxsUpload"
                      className="form-relative point flexStart"
                    >
                      <Input
                        type="file"
                        className="form-control-alternative form-control opac-hide point"
                        id="xlxsUpload"
                        onChange={(e) => this.importExcel(e)}
                        accept=".xlsx, .xls"
                      />
                      <img src={downloadImg} className="mr-20 point" alt="" />
                      <div className="flex-column point">
                        <div className="f15 mb-5p fw-500 cl212121">
                          Click to upload or Drag and Drop
                        </div>
                        <div className="cl606060 f14 fw-400">
                          Maximum file size 5 MB
                        </div>
                      </div>
                    </label>
                  </>
                )}
              </div>
              <div className="flexEnd">
                <div
                  className="mr-20 point f14 button-secondary"
                  onClick={() => this.closeModal()}
                >
                  Cancel
                </div>
                <Button
                  className="button-wrapping flexStart"
                  onClick={() => this.importApi()}
                >
                  {btnLoading ? (
                    <div className="spin-loading-save w100 h100">
                      <Spinner type="border" color="#" />
                    </div>
                  ) : (
                    <div className="f13 clfff">Upload</div>
                  )}
                </Button>
              </div>
            </CardBody>
          </Card>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default ExportPropertySideBar;
