import React, { Component } from "react";
import {
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  InputGroup,
  Spinner,
  CardBody,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
} from "reactstrap";
import CountUp from "react-countup";
// core components
import ApiService from "constants/ApiService";
import { Link } from "react-router-dom";
import { returnFirstDegreeObjValue } from "constants/utils";
import returnSecondDegreeObjValue from "constants/utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateSvg from "../../assets/img/brand/date-pick.svg";
import { filterQuery } from "constants/utils";
import { displayPrice } from "constants/utils";
import { showLocaldate } from "constants/utils";
import { getIsoString } from "constants/utils";
import { DateRangePicker } from "react-date-range";
import { showError } from "constants/utils";
import PayoutSideBar from "./PayoutSideBar";
import { displayPercentage } from "constants/utils";
import { buildQueryString } from "constants/utils";
import { pushToFilteredPage } from "constants/utils";
import {
  returnSearchObject,
  getDefaultProperty,
  getAuthToken
} from "constants/utils";
import moment from "moment";
import TotalInvoice from "../../../src/assets/img/brand/overall2.svg";
import CancelledInvoice from "../../../src/assets/img/brand/invoice.svg";
import UnpaidInvoice from "../../../src/assets/img/brand/commission.svg";
import PaidInvoice from "../../../src/assets/img/brand/earnings.svg";
import downloadIcon from "../../assets/img/brand/downloadIcon.svg";
import Constants from "../../constants/Env";
import ReactTooltip from "react-tooltip";
import { returnDownloadGet } from "constants/utils";
import { removeKeys } from "constants/utils";
import ToastList from "components/Toast/ToastList";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { CustomDatePicker } from "components/FilterFields/Filterfields";


let manager_id;

class Payouts extends Component {
  constructor(props) {
    super(props);
    manager_id = this.props.location?.manager_id;
    this.state = {
      payoutList: [],
      total: 0,
      filterToggle: false,
      from: "",
      to: "",
      page: 1,
      inputSearch: "",
      filterOption: "",
      statusSearch: "",
      modalOpen: false,
      isLoading: true,
      invoiceDetail: {},
      lateFee: 0,
      invoiceStatusView: "",
      payoutModal: false,
      payoutData: "",
      query: {},
      statsData: {},
      response: "",
      toast: false,
      toastType: "",
      timeoutId: null,
      input_value: false
    };
  }

  reloadPage = () => {
    this.props.history.listen((location, search) => {
      if (location.search === "") {
        this.setState({ query: {} }, () => {
          let query = this.props.location.search;
          query = returnSearchObject(query);
          if (Object.keys(query).length === 0) {
            this.handleReset();
          }
        });
      } else {
        this.state.input_value = true
      }
    });
  };

  componentDidMount() {
    this.reloadPage();
    this.setQueryStatus();
    // this.getpayoutList();
  }

  // componentDidUpdate(prevProps) {
  //   let search = this.props.location.search;
  //   if (prevProps.location.search !== search) {
  //     this.setQueryStatus();
  //   }
  // }

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  setQueryStatus = () => {
    let queryStatus = this.props.location.search;

    if (queryStatus) {
      let queryObj = returnSearchObject(queryStatus);

      this.setState(
        {
          from: queryObj.from ? new Date(queryObj.from) : "",
          to: queryObj.to ? new Date(queryObj.to) : "",
          page: parseInt(queryObj.page) ?? "",
          query: returnSearchObject(queryStatus),
        },
        () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getpayoutList();
          this.getRequestStats();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...this.state.query,
            page: 1,
            limit: 10,
            from: getIsoString(this.state.from),
            to: getIsoString(this.state.to),
          },
        },
        () => {
          pushToFilteredPage(this.props, this.state.query);
          this.getpayoutList();
          this.getRequestStats();
        }
      );
    }
  };

  getpayoutList = async () => {
    let { page, filterOption, statusSearch, inputSearch, from, to } = this.state;
    let queryParams = manager_id ? `&manager_id=${manager_id}` : "";
    if (filterOption !== "" && inputSearch !== "")
      queryParams += filterQuery(filterOption, inputSearch);
    if (statusSearch !== "") {
      queryParams += filterQuery("status", statusSearch);
    }
    if (from !== "")
      queryParams += filterQuery("from", getIsoString(from));
    if (to !== "")
      queryParams += filterQuery("to", getIsoString(to));
    if (from > to) {
      showError(this, "Please provide valid From and To date");
    } else {
      let response = await ApiService.getAPI(`host/payouts${buildQueryString(this.state.query)}`);
      if (response.success) {
        let { payouts } = response.data;
        this.setState({ payoutList: payouts, isLoading: false });
      } else {
        this.setState({ isLoading: false });
        showError(this, response.message);
      }
    }
  };

  getRequestStats = async () => {
    let default_property = getDefaultProperty();

    let { from, to } = this.state;
    let queryParams = {};

    if (from !== "") queryParams.from = getIsoString(from);
    if (to !== "") queryParams.to = getIsoString(to);
    if (default_property !== "") queryParams.property_id = default_property;

    let response = await ApiService.getAPI(
      `host/payouts/stats${buildQueryString(queryParams)}`
    );
    if (response.success) {
      this.setState({ statsData: response.data });
    } else {
      this.setState({ isLoading: false });
      showError(this, response.message);
    }
  };

  updateStateValues = (key, value) => {
    const updatedValue = value == null ? "" : value;
    this.setState({ [key]: updatedValue, input_value: true }, () => {
      if (this.state.from && this.state.to) {
        this.handleFilterStatus();
      }
    });
  };
  handleOnFromChange = (e) => {
    this.updateStateValues("from", e);
  };
  handleOnToChange = (e) => {
    this.updateStateValues("to", e);
  };
  handleIncrementPage = () => {
    if (this.state.payoutList.length >= 10) {
      this.setState({ page: this.state.page + 1 }, () => {
        this.handleFilterStatus();
      });
    }
  };
  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };
  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    staticQuery.from = getIsoString(this.state.from) ?? "";
    staticQuery.to = getIsoString(this.state.to) ?? "";

    staticQuery.page = this.state.page ?? "";

    this.setState({ query: staticQuery }, () => {
      pushToFilteredPage(this.props, { ...this.state.query });
      this.getpayoutList();
      this.getRequestStats();
    });
  };

  handleSearch = () => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
  };
  handleReset = () => {
    let self = this;
    self.setState(
      {
        statusSearch: "",
        filterOption: "",
        inputSearch: "",
        invoiceStatusView: "",
        from: "",
        to: "",
        page: 1,
        query: {
          page: 1,
          limit: 10,
        },
        input_value: false,
        keyValue: "",
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });

        this.getpayoutList();
      }
    );
  };
  toggleModal = (invoice_id) => {
    this.setState(
      {
        modalOpen: !this.state.modalOpen,
      },
      () => this.state.modalOpen && this.getInvoiceDetail(invoice_id)
    );
  };
  getInvoiceDetail = async (id) => {
    let response = await ApiService.getAPI(`host/invoice/${id}`);
    if (response.success) {
      this.setState({
        invoiceDetail: response.data.invoices,
      });
    }
  };
  handleCloseModal = () => {
    this.setState({ modalOpen: false });
  };
  handleSubLateFee = (amount, base_price) => {
    let lateFee = amount - base_price;
    this.setState({
      lateFee,
    });
  };

  setStatus = (status) => {
    this.setState({ statusSearch: status, page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  setInvoiceStatusView = (status) => {
    let from;
    let to;
    this.setState({ invoiceStatusView: status, input_value: true });
    if (status === "tm") {
      from = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      to = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
    } else if (status === "lm") {
      from = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
      to = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
    } else if (status === "ty") {
      from = new Date(new Date().getFullYear(), 0, 1);
      to = new Date(new Date().getFullYear(), 11, 31);
    } else {
      from = "";
      to = "";
    }
    this.setState({ from, to, page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  setPayoutDetails = (data) => {
    this.setState({ payoutData: data }, () => {
      this.openPayoutSideBar();
    });
  };

  openPayoutSideBar = () => {
    this.setState({ payoutModal: true });
  };

  closePayoutSideBar = () => {
    this.setState({ payoutData: "", payoutModal: false });
  };

  downloadInvoice = async () => {
    let apival = removeKeys(this.state);
    fetch(Constants.BASE_URL + `host/payouts/download${apival}`, returnDownloadGet())
      .then((response) => {
        return response.blob();
      }).then((data) => {
        if (data.type === "application/json") {
          showError(this, "No records found to download");
        } else {
          const downloadLink = document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(data);
          downloadLink.download = "test.xlsx";
          downloadLink.setAttribute("download", `Income.xlsx`);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }
      });
  };

  render() {
    const selectionRange = {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    };
    let {
      payoutList,
      statusSearch,
      isLoading,
      page,
      invoiceStatusView,
      payoutModal,
      payoutData,
      statsData,
      toast,
      response,
      toastType,
      from,
      to,
      input_value
    } = this.state;

    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#012A4D" />
      </div>
    ) : (
      <>
        {toast && <ToastList message={response} type={toastType} />}
        <div style={{ margin: "24px 24px -24px 24px" }}>
          <div className="header-body">
            <Row className="row-center">
              <Col lg="6" xl="3" className="max-dash mb_mt_28 mb_mt_28 ">
                <Card
                  className="card-stats mb-4 mb-xl-0 dash-new"
                  onClick={() => this.setStatus("", "card")}
                >
                  <CardBody className="pad-dash">
                    <div className="dash_content dash_content">
                      <div className="dash_left">
                        <div className="heading_with_badge">
                          <h4>OVERALL</h4>
                        </div>
                        <label className="number-con">
                          <CountUp
                            start={0}
                            end={returnSecondDegreeObjValue(
                              statsData,
                              "overall",
                              "total"
                            )}
                            separator=","
                            prefix="$"
                          />
                        </label>
                      </div>
                      <div className="col dash-col dash_right">
                        <img
                          src={TotalInvoice}
                          className="img-dash"
                          alt="total sales"
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="3" className="max-dash mb_mt_28">
                <Card
                  className="card-stats mb-4 mb-xl-0 dash-new"
                  onClick={() => this.setStatus("", "card")}
                >
                  <CardBody className="pad-dash">
                    <div className="dash_content dash_content">
                      <div className="dash_left">
                        <div className="heading_with_badge">
                          <h4>EARNINGS</h4>
                        </div>
                        <label className="number-con">
                          <CountUp
                            start={0}
                            end={returnSecondDegreeObjValue(
                              statsData,
                              "earnings",
                              "total"
                            )}
                            separator=","
                            prefix="$"
                          />
                        </label>
                      </div>
                      <div className="col dash-col dash_right">
                        <img
                          src={PaidInvoice}
                          className="img-dash"
                          alt="total sales"
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="3" className="max-dash mb_mt_28 ">
                <Card
                  className="card-stats mb-4 mb-xl-0 dash-new"
                  onClick={() => this.setStatus("completed,closed", "card")}
                >
                  <CardBody className="pad-dash">
                    <div className="dash_content dash_content">
                      <div className="dash_left">
                        <div className="heading_with_badge">
                          <h4>COMMISSION </h4>
                        </div>
                        <label className="number-con">
                          <CountUp
                            start={0}
                            end={returnSecondDegreeObjValue(
                              statsData,
                              "commission",
                              "total"
                            )}
                            separator=","
                            prefix="$"
                          />{" "}
                        </label>
                      </div>
                      <div className="col dash-col dash_right">
                        <img
                          src={UnpaidInvoice}
                          className="img-dash"
                          alt="total sales"
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="3" className="max-dash mb_mt_28 ">

                <Card
                  className="card-stats mb-4 mb-xl-0 dash-new"
                  onClick={() => this.setStatus("rejected,cancelled", "card")}
                >
                  <CardBody className="pad-dash">
                    <div className="dash_content dash_content">
                      <div className="dash_left">
                        <div className="heading_with_badge">
                          <h4>INVOICES </h4>
                        </div>
                        <label className="number-con">
                          <CountUp
                            start={0}
                            end={returnFirstDegreeObjValue(
                              statsData,
                              "invoices",
                              "total"
                            )}
                            separator=","
                          />{" "}
                        </label>
                      </div>
                      <div className="col dash-col dash_right">
                        <img
                          src={CancelledInvoice}
                          className="img-dash"
                          alt="total sales"
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
                {/* </a> */}
              </Col>
            </Row>
          </div>
        </div>
        <CustomListingContainer>
          <ListingHeaderLayout label={"Income"}>
            <div className="justend">
              <div className="flexEnd invoiceStatusView"
                style={{ width: "170px", marginLeft: "15px" }}>
                <UncontrolledDropdown className="status_select_div ">
                  <DropdownToggle className="pr-0 drop_down_container">
                    <Media className="capitalize">
                      {invoiceStatusView === "tm"
                        ? "This Month"
                        : invoiceStatusView === "lm"
                          ? "Last Month"
                          : invoiceStatusView === "ty"
                            ? "This Year"
                            : "All"}
                      <i className="fas fa-angle-down"></i>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" bottom>
                    {" "}
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.setInvoiceStatusView("")}
                    >
                      All
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.setInvoiceStatusView("tm")}
                    >
                      This Month
                    </DropdownItem>{" "}
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.setInvoiceStatusView("lm")}
                    >
                      Last Month
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.setInvoiceStatusView("ty")}
                    >
                      This Year
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          </ListingHeaderLayout>
          <div style={{ margin: "20px" }}>
            <InputGroup className="flex-content align-left">
              <div className="filters_container">
                <div className="left_filter_container">
                  {" "}
                  <div className="w30">
                    <CustomDatePicker
                      from={from}
                      to={to}
                      fromChange={this.handleOnFromChange}
                      toChange={this.handleOnToChange}
                    ></CustomDatePicker>
                  </div>
                </div>
                <div className="right_filter_container">
                  {input_value && ((from || to) || invoiceStatusView) &&
                    < Button
                      color="primary"
                      onClick={this.handleReset}
                      size="sm"
                      className="button-wrapping mr-4 button-secondary reset-btn"
                      style={{ fontSize: "15px" }} >
                      Clear filter
                    </Button>}
                  <>
                    <div
                      onClick={() => this.downloadInvoice()}
                      className="download-btn point"
                      id="groupViewToolTip"
                      data-tip="Download"
                      data-iscapture="true"
                      data-for="downloadInvoice"
                      data-place="bottom"
                    >
                      <img src={downloadIcon} style={{ width: "17px" }} />
                    </div>
                    <ReactTooltip
                      place="bottom"
                      type="info"
                      effect="solid"
                      id="downloadInvoice"
                      html={true}
                    />
                  </>
                </div>
              </div>
            </InputGroup>
          </div >
          <Row>
            <div className="col new-table">
              <Card className="shadow card_with_paginator">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      {/* <th scope="col">Income ID</th> */}
                      <th scope="col">Created On</th>
                      <th scope="col">Invoices</th>
                      <th scope="col">Overall</th>
                      <th scope="col">Commission</th>
                      <th scope="col">Settlement</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  {payoutList.length !== 0 ? (
                    <tbody>
                      {payoutList.map((payout, i) => (
                        <tr
                          key={i}
                          onClick={() =>
                            payout.items.length > 1 &&
                            this.setPayoutDetails(payout)
                          }
                          className="point"
                        >
                          <td>
                            {showLocaldate(
                              returnFirstDegreeObjValue(payout, "createdAt")
                            )}
                          </td>
                          <td> {payout?.items?.length}</td>

                          <td>
                            {displayPrice(
                              returnFirstDegreeObjValue(
                                payout,
                                "overall_amount"
                              )
                            )}
                          </td>

                          <td>
                            {payout?.commission?.type === "percentage" ? (
                              <>
                                {displayPrice(
                                  returnSecondDegreeObjValue(
                                    payout,
                                    "commission",
                                    "amount"
                                  )
                                )}{" "}
                                (
                                {displayPercentage(
                                  returnSecondDegreeObjValue(
                                    payout,
                                    "commission",
                                    "value"
                                  )
                                )}
                                )
                              </>
                            ) : (
                              displayPrice(
                                returnSecondDegreeObjValue(
                                  payout,
                                  "commission",
                                  "amount"
                                )
                              )
                            )}
                          </td>
                          <td>
                            {displayPrice(
                              returnFirstDegreeObjValue(payout, "amount")
                            )}
                          </td>
                          <td className={payout.status === "pending"
                            ? "yellow ft_500 ft_14 mb_0 text-capitalize"
                            : payout.status === "processed"
                              ? "green ft_500 ft_14 mb_0 text-capitalize"
                              : payout.status === "rejected"
                                ? "red ft_500 ft_14 mb_0 text-capitalize"
                                : "yellow ft_500 ft_14 mb_0 text-capitalize"}
                          >
                            {payout.status}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td className="no-data">No Income Available</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              </Card>
            </div>
          </Row>
          <Row className="align-items-center jus-space new-margin-page paginator_box">
            <div className="paginate-flex-box ">
              <div
                className="paginate-box"
                onClick={() => this.handleDecrementPage()}
              >
                <p
                  className={page !== 1 ? "page_available" : "page_unavailable"}
                >
                  Previous
                </p>
              </div>
              <div
                className="paginate-box"
                onClick={() => this.handleIncrementPage()}
              >
                <p
                  className={
                    payoutList.length < 10
                      ? "page_unavailable"
                      : "page_available"
                  }
                >
                  Next
                </p>
              </div>
            </div>
          </Row>
        </CustomListingContainer >
        <Card
          className={
            payoutModal ? "invoice-sidebar payoutSideWidth" : "toggle-sidebar"
          }
        >
          <PayoutSideBar data={payoutData} close={this.closePayoutSideBar} />
        </Card>
      </>
    );
  }
}

export default Payouts;
