import React, { Component } from "react";
import { CardHeader, Spinner } from "reactstrap";
import closeIcon from "../../assets/img/brand/close.svg";
import { returnCustomerFirsLastName, returnRole } from "constants/utils";
import moment from "moment";
import { Input } from "reactstrap";
import Lottie from "react-lottie";
import NoData from "../../../src/assets/Lotties/noData.json";
import ApiService from "constants/ApiService";
import sendChat from "../../assets/img/brand/send_chat.svg";
let reqId;
let role;
let chatWidth = document.querySelector(":root");

export default class Chat extends Component {
  date = [];
  constructor(props) {
    super(props);
    reqId = props.requestId;
    this.textareaRef = React.createRef();
    this.state = {
      messageDate: new Date().toISOString(),
      chat: null,
      maxHeight: 200,
      inputMessage: null,
      messageSendloader: false,
      initPageLoading: false,
      textAreaHeight: "28px",
    };
  }

  componentDidMount() {
    chatWidth.style.setProperty("--width", "500px");
    role = returnRole();
    this.getChats();
  }

  componentWillUnmount() {
    chatWidth.style.setProperty("--width", "700px");
  }

  getChats = async () => {
    this.setState({ initPageLoading: true });
    let chat = await ApiService.getAPI(`host/chats/${reqId}`);
    if (chat.success) {
      let data = chat.data.chats;
      data.reverse();
      this.setState({ chat: data, initPageLoading: false }, () => {});
      this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }
  };

  closeModal = () => {
    this.props.close();
  };

  update = (e) => {
    const textarea = this.textareaRef.current;
    textarea.style.height = "28px";
    textarea.style.height = `${textarea.scrollHeight}px`;
    this.setState({
      inputMessage: e.target.value,
    });
  };

  sendMessage = async () => {
    const textarea = this.textareaRef.current;
    let tempChat = this.state.chat;
    if (this.state.inputMessage?.trim().length) {
      this.setState({ messageSendloader: true });
      const data = {
        content: this.state.inputMessage.trimStart(),
        source: reqId,
      };
      let response = await ApiService.postAPI("host/chats", data);
      this.setState({ messageSendloader: false });
      this.date = [];
      if (response.success) {
        let resData = response.data.chat;
        delete resData._id;
        delete resData.updatedAt;
        delete resData.__v;
        tempChat.push(resData);
        this.setState({ chat: tempChat, inputMessage: "" });
        this.messagesEnd.scrollIntoView({ behavior: "smooth" });
      }
    }
    textarea.style.height = "28px";
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey && !e.ctrlKey) {
      e.preventDefault();
    } else if (e.key === "Enter" && (e.shiftKey || e.ctrlKey) && !e.repeat) {
      this.insertLineBreak();
      e.preventDefault();
    }
    e.key === "Enter" &&
      !e.repeat &&
      !e.shiftKey &&
      !e.ctrlKey &&
      this.sendMessage();
    this.date = [];
  };

  insertLineBreak() {
    const textarea = this.textareaRef.current;
    const { selectionStart, selectionEnd, value } = textarea;
    const newValue =
      value.substring(0, selectionStart) + "\n" + value.substring(selectionEnd);
    textarea.value = newValue;
    textarea.setSelectionRange(selectionStart + 1, selectionStart + 1);
    // if(textarea.style.height < this.state.maxHeight)  textarea.style.height = `${textarea.scrollHeight}px`;
    textarea.style.height = `${textarea.scrollHeight}px`
  }

  dateCollection = (obj) => {
    let returnValue = false;
    let startDay = moment(obj.createdAt).startOf("day").format("DD MMMM");
    if (!this.date.includes(startDay)) {
      this.date.push(moment(obj.createdAt).format("DD MMMM"));
      returnValue = true;
    }
    return returnValue;
  };

  isToday = (obj) => {
    let getDate = moment(obj.createdAt).startOf("day").format();
    let today = moment().startOf("day").format();
    return today === getDate ? "Today" : moment(getDate).format("DD MMM YYYY");
  };

  isRepeatName = (obj, i) => {
    return (moment(obj.createdAt).startOf('day').format() === moment(this.state.chat[i-1]?.createdAt).startOf('day').format() ) && (obj?.contractor?.id ?? obj?.manager?.id ?? obj?.user?.id) ===
      (this.state.chat[i - 1]?.contractor?.id ??
        this.state.chat[i - 1]?.manager?.id ??
        this.state.chat[i - 1]?.user?.id)
      ? true
      : false;
  };

  render() {
    const NodataFound = {
      loop: true,
      autoplay: true,
      animationData: NoData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <>
        <CardHeader>
          <div className="flex">
            <div className="fw-500 f16 cl000000"></div>
            <img
              src={closeIcon}
              onClick={() => this.closeModal()}
              className="point"
            />
          </div>
        </CardHeader>
        {this.state.initPageLoading ? (
          <div className="spin-loading">
            <Spinner type="border" color="#012A4D" />
          </div>
        ) : this.state.chat?.length ? (
          <>
            <div className="chat_hero">
              <div>
                {this.state?.chat?.length > 0 &&
                  this.state.chat.map((obj, i) => (
                    <div className="chatContainer">
                      {(!i || moment(obj?.createdAt).startOf("day").format() !== moment(this.state.chat[i - 1]?.createdAt).startOf("day").format()) && (
                        <div className="timeContainer">
                          <div>{this.isToday(obj)}</div>
                        </div>
                      )}
                      <>
                        {Object.keys(obj).includes(role) ? (
                          <>
                            <div className="answer_container">
                              <div className="myMessage">
                                <div className="wordBreakAll">
                                  {obj.content.trimStart()}
                                </div>
                                <div
                                  style={{ color: "#ffffff" }}
                                  className="time"
                                >
                                  {moment(obj.createdAt).format("LT")}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="serviceProvider_cotainer chat_container">
                              <div className="serviceMessage">
                                <div>
                                  {!this.isRepeatName(obj, i) && (
                                    <div className="replyName">
                                      {obj?.contractor?.name ??
                                        obj?.manager?.name ??
                                        (obj?.user?.name?.first + "" +
                                          obj?.user?.name?.last)}
                                    </div>
                                  )}
                                </div>
                                <div className="wordBreakAll replyContent">
                                  {obj.content}
                                </div>
                                <div
                                  style={{ color: "#0D082C66" }}
                                  className="time"
                                >
                                  {moment(obj.createdAt).format("LT")}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        <div
                          style={{ float: "left", clear: "both" }}
                          ref={(el) => {
                            this.messagesEnd = el;
                          }}
                        ></div>
                      </>
                    </div>
                  ))}
              </div>
            </div>
          </>
        ) : (
          // <div className="chat_hero noChats">No chats found</div>
          <div className="no_data_dashboard">
            <Lottie options={NodataFound} height={100} width={100} />
            <div className="mt_20">
              <p>No chats found</p>
            </div>
          </div>
        )}
        {!this.state.initPageLoading &&
          this.props.status !== "completed" &&
          this.props.status !== "closed" &&
          this.props.status !== "cancelled" && (
            <div className="chat_footer">
              <div className="ft_1">
                <textarea
                  ref={this.textareaRef}
                  style={{
                    height: this.state.textAreaHeight,
                    // maxHeight: "200px",
                    // overflowY: "auto",
                  }}
                  onInput={this.update}
                  value={this.state.inputMessage}
                  onKeyDown={this.handleKeyDown}
                  placeholder="Type here..."
                />
                <div className="ft_img">
                  {this.state.messageSendloader ? (
                    // <Spinner color="#012a4d">Loading...</Spinner>
                    <div className="image-loading spinner-cut">
                      <Spinner
                        style={{ width: "30px", height: "30px" }}
                        type="border"
                        color="#012A4D"
                      />
                    </div>
                  ) : (
                    <img onClick={this.sendMessage} src={sendChat}></img>
                  )}
                </div>
              </div>
            </div>
          )}
      </>
    );
  }
}
