import React, { Component } from "react";

import { Card, CardHeader, CardBody, Button, Spinner } from "reactstrap";
import closeIcon from "../../assets/img/brand/close.svg";
import ApiService from "constants/ApiService";
// import { Select } from "@mui/material";
import Multiselect from "multiselect-react-dropdown";
// import $ from "jquery";
// import { color } from "html2canvas/dist/types/css/types/color";
class RolesAssignUser extends Component {
  constructor(props) {
    // this.multiselectRef = React.createRef();
    super(props);
    this.state = {
      role: "",
      employeelist: "",
      btnLoading: false,
      propertyList: [],
      property: [],
      dummy: "",
      rolesList: [],
      roles: "",
      submanagerList: [],
      submanager: "",
    };
  }
  componentDidMount() {}
  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open === true) {
        this.getPropertyLists();
        this.getSubManagersList();
        this.getRolesList();
      }
    }
  }

  getRolesList = async () => {
    let response = await ApiService.getAPI(`host/manage_role?all`);
    if (response.success) {
      let roles = response?.data?.roles;
      let rolein = [];
      if (roles) {
        roles.map((el) => {
          rolein.push({ name: el.name, id: el.role_id });
        });
      }
      this.setState({ rolesList: rolein });
    }
  };
  getSubManagersList = async () => {
    let response = await ApiService.getAPI(`host/submanager/all`);

    if (response.success) {
      let managerin = [];
      let smanager = response?.data?.sub_managers;
      smanager.map((el) => {
        managerin.push({ name: el.name, id: el.sub_manager_id });
      });
      this.setState({ submanagerList: managerin });
    }
  };
  closeModal = () => {
    this.setState(
      { property: "", roles: "", submanager: "", btnLoading: false },
      () => this.props.close()
    );
  };
  handleSelectRole = (e) => {
    this.setState({ role: e });
  };
  handleSelectEmployees = (e) => {
    this.setState({ employeelist: e });
  };
  AssignUser = async () => {
    const { roles, property, submanager } = this.state;

    if (
      roles[0]?.id === "" ||
      roles[0]?.id === undefined ||
      roles[0]?.id === null
    ) {
      this.props.toast("Please select role");
    } else if (
      property[0]?.property_id === "" ||
      property[0]?.property_id === undefined ||
      property[0]?.property_id === null
    ) {
      this.props.toast("Please select properties");
    } else if (
      submanager[0]?.id === "" ||
      submanager[0]?.id === undefined ||
      submanager[0]?.id === null
    ) {
      this.props.toast("Please select sub manager");
    } else {
      let proplistId = [];
      property.map((el) => {
        proplistId.push(el.property_id);
      });

      let data = {
        sub_manager_id: submanager[0].id,
        properties: proplistId,
      };

      this.setState({ btnLoading: true });
      let response = await ApiService.patchAPI(
        `host/manage_role/assign/${roles[0].id}`,
        data
      );
      if (response.success) {
        this.setState({ btnLoading: false });
        this.closeModal();
        this.props.toast(response.message, true);
      } else {
        this.props.toast(response.message);
        this.setState({ btnLoading: false });
      }
    }
  };
  onPropertySelect = (selectlist) => {
    this.setState({ property: selectlist });
  };
  onRoleSelect = (role) => {
    this.setState({ roles: role });
  };

  onManagerSelect = (manager) => {
    this.setState({ submanager: manager });
  };

  getPropertyLists = async () => {
    let response = await ApiService.getAPI(`host/properties/list`);

    if (response.success) {
      this.setState({
        propertyList: response?.data?.properties,
      });
    }
  };
  render() {
    const {
      rolesList,
      roles,
      btnLoading,
      submanagerList,
      submanager,
      propertyList,
      property,
    } = this.state;
    return (
      <div>
        {this.props.open && (
          <Card className="bnone">
            <CardHeader>
              <div className="flex">
                <div className="fw-500 f16 cl000000">Assign User</div>

                <img
                  src={closeIcon}
                  onClick={() => this.closeModal()}
                  className="point"
                  alt=""
                />
              </div>
            </CardHeader>
            <CardBody className="pad30p">
              <div class="cl2A1E17 mb-15 f14 fw-400 assign_title">Role*</div>
              <Multiselect
                options={rolesList}
                selectedValues={roles}
                onSelect={this.onRoleSelect}
                onRemove={this.onRoleSelect}
                displayValue="name"
                className="single-select-component"
                placeholder={this.state.roles.length === 0 ? "select role" : ""}
                // closeIcon="cancel"
                customCloseIcon={
                  <img src={closeIcon} style={{ width: "15px" }} alt=""></img>
                }
                singleSelect={true}
              />
              <div class="cl2A1E17 mb-15 f14 fw-400 assign_title">
                Property*
              </div>
              <Multiselect
                options={propertyList}
                selectedValues={property}
                onSelect={(e) => this.onPropertySelect(e)}
                onRemove={(e) => this.onPropertySelect(e)}
                displayValue="name"
                className="multi-select-component"
                placeholder={property.length === 0 ? "select property" : ""}
                closeIcon="cancel"
                // singleSelect={true}
              />
              <div class="cl2A1E17 mb-15 f14 fw-400 assign_title">Manager*</div>
              <Multiselect
                options={submanagerList}
                selectedValues={submanager}
                onSelect={this.onManagerSelect}
                onRemove={this.onManagerSelect}
                displayValue="name"
                className="single-select-component"
                placeholder={
                  this.state.submanager.length === 0 ? "select manager" : ""
                }
                singleSelect={true}
                customCloseIcon={
                  <img src={closeIcon} style={{ width: "15px" }} alt=""></img>
                }
                // closeIcon="cancel"
              />
              <div className="flexEnd mt-40">
                <div
                  className="mr-20 point f14 button-secondary"
                  onClick={() => this.closeModal()}
                >
                  Cancel
                </div>
                <Button
                  color="primary"
                  className="button-wrapping "
                  size="sm"
                  onClick={() => this.AssignUser()}
                >
                  {btnLoading ? (
                    <div className="spin-loading-save w100 h100">
                      <Spinner type="border" color="#012A4D" />
                    </div>
                  ) : (
                    <div>Assign</div>
                  )}
                </Button>
              </div>
            </CardBody>
          </Card>
        )}
      </div>
    );
  }
}

export default RolesAssignUser;
