import React, { Component } from "react";
import {
  Card,
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  CardHeader,
  Input,
  CardBody,
  BreadcrumbItem,
  Spinner,
  Form,
  FormGroup,
} from "reactstrap";
import ApiService from "constants/ApiService";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import ToastList from "../../components/Toast/ToastList";

import {
  isInvalidName,
  getUSFormat,
  replaceToNormalNumber,
} from "constants/utils";
import { isInvalidEmail } from "constants/utils";
import { handleGoBack } from "constants/utils";
import { showError } from "constants/utils";
import { showSuccess } from "constants/utils";
const animatedComponents = makeAnimated();
const options = [
  {
    value: "Electrical",
    label: "Electrical",
  },
  {
    value: " House Exterior",
    label: " House Exterior",
  },
  {
    value: "Service",
    label: "Service",
  },
  {
    value: "Plumbing",
    label: "Plumbing",
  },
  {
    value: "Appliances",
    label: "Appliances",
  },
];
let contractor_id;
class AddEditContracts extends Component {
  constructor(props) {
    super(props);
    contractor_id = this.props.match.params.contractor_id;
    this.state = {
      toast: false,
      response: "",
      toastType: "",
      type: "",
      Cname: "",
      phoneNumber: "",
      email: "",
      stateList: [],
      cityList: [],
      isLoading: false,
      name: "",
      state_id: {},
      services: "",
      state: "",
      city: "",
      zip: "",
      street_address: "",
    };
  }
  componentDidMount() {
    if (contractor_id) {
      this.getContractDetail(contractor_id);
    }
    this.getStates();
  }
  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleSelect = (e) => {
    this.setState({ type: e.target.value });
  };
  handleOnChangeList = (selectedOption) => {
    this.setState({ services: selectedOption });
  };
  handleOnPhoneNumberChange = (evt) => {
    this.setState({ phoneNumber: getUSFormat(evt.target.value) });
  };

  handleStateInputChange = (e) => {
    var self = this;
    self.setState(
      { state: e.target.value, state_id: JSON.parse(e.target.value) },
      () => self.state.state_id && self.getCityList()
    );
  };
  handleCityInputChange = (e) => {
    var self = this;
    self.setState({ city: e.target.value });
  };
  // api functions
  AddEdit = async (contractor_id) => {
    let {
      name,
      email,
      phoneNumber,
      services,
      type,
      street_address,
      state,
      city,
      zip,
    } = this.state;
    let response;
    if (type === "") {
      showError(this, "Please select contract type");
    } else if (name === "" || isInvalidName(name)) {
      showError(
        this,
        `Please enter valid  ${type === "company" ? "company name" : "name"}`
      );
    } else if (email === "") {
      showError(this, "Please enter email");
    } else if (isInvalidEmail(email)) {
      showError(this, "Please enter valid email");
    } else if (phoneNumber === "" || phoneNumber.length < 16) {
      showError(this, "Please enter valid phone number");
    } else if (services.length === 0) {
      showError(this, "Please select atleast one services");
    } else if ( street_address === "" ||
      isInvalidName(street_address) ||
      street_address.length < 3  ) {
      showError(this, "Please enter valid street name");
    } else if (Object.keys(state).length === 0) {
      showError(this, "Please select state");
    } else if (Object.keys(city).length === 0) {
      showError(this, "Please select city");
    } else if (zip === "") {
      showError(this, "Please enter zip");
    } else {
      let newService = [];
      services.forEach(function (service) {
        let obj = {};
        obj.service = service.value;
        newService.push(obj);
      });
      let data = {
        name: name,
        email: email,
        phone: replaceToNormalNumber(phoneNumber),
        type: type,
        services: newService,
        address: {
          line_1: street_address,
          state: JSON.parse(state),
          city: JSON.parse(city),
          zip: zip,
        },
      };
      if (contractor_id) {
        response = await ApiService.patchAPI(
          `host/contractor/${contractor_id}`,
          data
        );
        if (response.success) {
          showSuccess(this, response.message);
          handleGoBack(this.props);
        } else {
          showError(this, response.message);
        }
      } else {
        response = await ApiService.postAPI("host/contractor", data);
        if (response.success) {
          showSuccess(this, response.message);
          handleGoBack(this.props);
        } else {
          showError(this, response.message);
        }
      }
    }
  };

  getContractDetail = async (id) => {
    let response = await ApiService.getAPI(`host/contractor/${id}`);
    if (response.success) {
      let data = response.data;
      let serviceArray = [];
      options.filter((obj) => {
        response.data.services.forEach((responseObject) => {
          if (responseObject.service === obj.value) {
            serviceArray.push(obj);
          }
        });
      });

      this.setState({
        name: data.name,
        email: data.email,
        phoneNumber: getUSFormat(data.phone && data.phone.toString()),
        services: serviceArray,
        type: data.type,
        street_address: data.address.line_1,
        state: JSON.stringify(data.address.state),
        city: JSON.stringify(data.address.city),
        zip: data.address.zip,
      });
    } else {
    }
  };

  getStates = async () => {
    let { state_id } = this.state;
    let response = await ApiService.getAPI("host/states");
    if (response.success) {
      this.setState(
        { stateList: response.data.states },
        () => Object.keys(state_id).length > 0 && this.getCityList()
      );
    } else {
    }
  };
  getCityList = async () => {
    let { state_id } = this.state;
    let id = state_id.state_id;
    let response = await ApiService.getAPI(`host/cities/${id}`);
    if (response.success) {
      this.setState({ cityList: response.data.cities });
    } else {
    }
  };
  // end of api functions
  render() {
    let {
      toast,
      response,
      toastType,
      name,
      type,
      phoneNumber,
      email,
      services,
      cityList,
      stateList,
      state,
      zip,
      city,
      street_address,
    } = this.state;

    return this.state.isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#012A4D" />
      </div>
    ) : (
      <Container className="mt-8" fluid>
        {toast && <ToastList message={response} type={toastType} />}
        <div>
          <Breadcrumb className="pad-to-8"></Breadcrumb>
        </div>
        <Row className="align_center header-div">
          <Breadcrumb>
            <BreadcrumbItem
              onClick={() => this.props.history.goBack()}
              className="bread-cursor"
            >
              Contractors
            </BreadcrumbItem>
            <BreadcrumbItem active style={{ color: "#012a4d" }}>
              {contractor_id ? "Update" : "Add"}
            </BreadcrumbItem>
          </Breadcrumb>
          <div className="add-filter-wrap ">
            <Button
              className=" button-wrapping "
              color="primary"
              size="sm"
              onClick={() => this.AddEdit(contractor_id && contractor_id)}
            >
              {contractor_id ? "Save Changes" : "Add"}
            </Button>
          </div>
        </Row>
        <Row className="align-items-center header-div"></Row>
        <Card className="shadow">
          <CardHeader className="border-0">
            <Row className="align-items-center">
              <Col xs="12">
                <h3 className="mb-0">Add Contractors</h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form>
              <div>
                <Row>
                  <Col lg="4" className="mb-3">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Type*
                      </label>

                      <select
                        className="form-control-alternative form-control"
                        value={type}
                        name="type"
                        placeholder="Company Name"
                        type="text"
                        onChange={this.handleSelect}
                      >
                        <option value=""></option>
                        <option value="individual">Individual</option>
                        <option value="company">Company</option>
                      </select>
                    </FormGroup>
                  </Col>
                  <Col lg="4" className="mb-3">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        {type === "company" ? "Company Name*" : "Name*"}
                      </label>

                      <Input
                        className="form-control-alternative"
                        value={name}
                        name="name"
                        placeholder="Name"
                        type="text"
                        onChange={this.handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4" className="mb-3">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Email*
                      </label>
                      <Input
                        className="form-control-alternative"
                        value={email}
                        name="email"
                        placeholder="example@gmail.com"
                        type="text"
                        onChange={this.handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="4" className="mb-3">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Phone Number*
                      </label>
                      <Input
                        type="text"
                        name="phoneNumber"
                        value={phoneNumber}
                        autoComplete="new-phone"
                        onChange={this.handleOnPhoneNumberChange}
                        maxLength="16"
                        className="form-control-alternative"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="8">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Services*
                      </label>
                      <Select
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        value={services}
                        name="updateList"
                        onChange={this.handleOnChangeList}
                        isMulti
                        options={options}
                        isSearchable={true}
                        placeholder="Select Properties"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="4" className="mb-3">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Street Name*
                      </label>

                      <Input
                        className="form-control-alternative"
                        value={street_address}
                        name="street_address"
                        placeholder="Street Name"
                        type="text"
                        onChange={this.handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                      >
                        State*
                      </label>
                      <select
                        className="form-control-alternative form-control"
                        id="input-first-name"
                        name="state"
                        value={state}
                        onChange={this.handleStateInputChange}
                      >
                        {/* <option>Select</option> */}
                        <option value="">Select</option>
                        {stateList.map((state, i) => (
                          <option
                            value={JSON.stringify({
                              state_id: state.state_id,
                              name: state.name,
                            })}
                            key={i}
                          >
                            {state.name}
                          </option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                      >
                        City*
                      </label>
                      <select
                        className="form-control-alternative form-control"
                        id="input-first-name"
                        name="city"
                        value={city}
                        onChange={this.handleCityInputChange}
                      >
                        {/* <option>Select</option> */}
                        <option value="">Select</option>
                        {cityList.map((city, i) => (
                          <option
                            value={JSON.stringify({
                              city_id: city.city_id,
                              name: city.name,
                            })}
                            key={i}
                          >
                            {city.name}
                          </option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="4">
                    <FormGroup className="mb_0">
                      <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                      >
                        ZIP*
                      </label>
                      <Input
                        className="form-control-alternative"
                        value={zip}
                        name="zip"
                        id="input-first-name"
                        onChange={this.handleInputChange}
                        placeholder="ZIP"
                        type="text"
                        maxLength="5"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Container>
    );
  }
}
export default AddEditContracts;
