import React, { Component } from "react";
import ApiService from "constants/ApiService";
import {
  filterQuery,
  returnThirdDegreeObjValue,
  returnFirstDegreeObjValue,
  showLocaldate,
  displayPrice,
} from "constants/utils";

import {
  Card,
  CardBody,
  CardHeader,
  Table,
  Spinner,
  Row,
  Col,
} from "reactstrap";
import { getIsoString } from "constants/utils";

class DepositDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      invoiceStatus: "",
      tableLoad: false,
      depositDetail: [],
    };
  }

  componentDidUpdate(prevProps) {
    let { id } = this.props;
    if (prevProps.id !== id) {
      if (this.props.isOpen) {
        this.getDetail();
      }
    }
  }

  getDetail = async () => {
    this.setState({ tableLoad: true });
    let { id } = this.props;

    let response = await ApiService.getAPI(
      `host/deposits/invoice/${id}?page=${this.state.page}&limit=10`
    );
    if (response.success) {
      let { deposit_invoices } = response.data;
      this.setState({ depositDetail: deposit_invoices, tableLoad: false });
    } else {
      this.setState({ tableLoad: false });
    }
  };

  getInvoiceDetail = async () => {
    let { data, status, fromDate, toDate } = this.props;
    let { page, invoiceStatus } = this.state;
    let id = data.property_id;

    let queryParams = "";
    if (id !== "") queryParams += filterQuery("property_id", id);
    if (invoiceStatus !== "")
      queryParams += filterQuery("status", invoiceStatus);
    if (fromDate !== "")
      queryParams += filterQuery("from", getIsoString(fromDate));
    if (toDate !== "") queryParams += filterQuery("to", getIsoString(toDate));
    let response = await ApiService.getAPI(
      `host/invoice?page=${page}&limit=10${queryParams}`
    );
    if (response.success) {
      let invoice = response.data.invoices;
      this.setState(
        {
          invoiceDetail: invoice,
        },
        () => {
          this.setState({ tableLoad: false });
        }
      );
    }
  };

  closeModal = () => {
    this.setState({ depositDetail: [], tableLoad: false });
    this.props.close();
  };

  handleIncrementPage = () => {
    if (this.state.depositDetail.length >= 10) {
      this.setState({ page: this.state.page + 1 }, () => {
        this.getDetail();
      });
    }
  };

  handleDecrementPage = () => {
    if (this.state.page !== 1) {
      this.setState({ page: this.state.page - 1 }, () => {
        this.getDetail();
      });
    }
  };

  render() {
    let { depositDetail, tableLoad, page } = this.state;
    return (
      <div>
        {this.props.isOpen && (
          <>
            <Card className="br-none">
              <CardHeader className="br-none">
                <div className="flex">
                  <div>Details</div>
                  <button onClick={() => this.closeModal()} className="close">
                    X
                  </button>
                </div>
              </CardHeader>
            </Card>
            <Card className="shadow card_with_paginator br-none">
              {tableLoad ? (
                <div className="spin-loading3 mt-30">
                  <Spinner type="border" color="#012A4D" />
                </div>
              ) : (
                <div>
                  <div>
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Date</th>
                          <th scope="col">Invoice ID</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>

                      {depositDetail.length !== 0 ? (
                        <tbody>
                          <>
                            {depositDetail.map((deposit, i) => (
                              <tr key={i}>
                                <td className="text-capitalize cursor-point">
                                  {showLocaldate(
                                    returnFirstDegreeObjValue(
                                      deposit,
                                      "createdAt"
                                    )
                                  )}
                                </td>
                                <td>
                                  {returnFirstDegreeObjValue(
                                    deposit,
                                    "deposit_invoice_id"
                                  )}
                                </td>
                                <td>
                                  {displayPrice(
                                    returnFirstDegreeObjValue(deposit, "amount")
                                  )}
                                </td>

                                <td
                                  className={
                                    deposit.status === "paid"
                                      ? "text-capitalize green general_status_style"
                                      : deposit.status === "created"
                                      ? "text-capitalize yellow general_status_style"
                                      : deposit.status === "cancelled"
                                      ? "text-capitalize red general_status_style"
                                      : "text-capitalize"
                                  }
                                >
                                  {returnFirstDegreeObjValue(deposit, "status")}
                                </td>
                              </tr>
                            ))}
                          </>
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td className="no-data">No Deposits Available</td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      )}
                    </Table>
                  </div>
                </div>
              )}
            </Card>
          </>
        )}
      </div>
    );
  }
}

export default DepositDetail;
