import React, { Component, useState } from "react";
import {
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  InputGroup,
  Dropdown,
  Input,
  Spinner,
  Modal,
  CardBody,
  FormGroup,
  InputGroupText,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Tooltip,
} from "reactstrap";
import ReactTooltip from "react-tooltip";

// core components
import ApiService from "constants/ApiService";
import { Link } from "react-router-dom";
import { returnFirstDegreeObjValue } from "constants/utils";
import returnSecondDegreeObjValue from "constants/utils";
import CountUp from "react-countup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Park from "../../assets/img/new Logo/host_new_template.svg";
import { filterQuery } from "constants/utils";
import { displayPrice } from "constants/utils";
import { getMonthFromDate } from "constants/utils";
import { showLocaldate } from "constants/utils";
import { getIsoString } from "constants/utils";
import ToastList from "components/Toast/ToastList";
import { returnPositiveNumber } from "constants/utils";
import { RoleBasedCRUDAccess } from "views/Roles/RolesUtils";
import Constants from "../../constants/Env";
import {
  buildQueryString,
  calculatePercentage,
  getDefaultProperty,
  pushToFilteredPage,
  redirectToBookings,
  returnCustomerFirsLastName,
  returnSearchObject,
  showError,
  showSuccess,
  userTypeQuery,
  returnDownloadGet,
  removeKeys,
  returnSortKey,
} from "../../constants/utils";
import TotalInvoice from "../../../src/assets/img/brand/headerTotalInvoice.svg";
import PaidInvoice from "../../../src/assets/img/brand/paidInvoice.svg";
import UnpaidInvoice from "../../../src/assets/img/brand/unpaidInvoice.svg";
import InvoiceIncrement from "../../../src/assets/img/brand/InvoiceIncrease.svg";
import InvoiceDecrement from "../../../src/assets/img/brand/invoiceDecrease.svg";
import grouping from "../../../src/assets/img/brand/grouping.svg";
import invoiceView from "../../../src/assets/img/brand/invoiceView.svg";
import invoiceViewSelected from "../../../src/assets/img/brand/invoiceViewSelected.svg";
import groupingSelected from "../../../src/assets/img/brand/groupingSelected.svg";
import InvoiceModal from "./InvoiceModal";
import CancelInvoiceModal from "./CancelInvoiceModal";
import LateFeeRemoveModal from "./LateFeeRemoveModal";
import showMore from "../../assets/img/brand/showMore.svg";
import downloadIcon from "../../assets/img/brand/downloadIcon.svg";
import maintenanceInvoice from "../../assets/img/brand/maintenanceInvoice.svg";
import requestInvoice from "../../assets/img/brand/requestInvoice.svg";
import rentInvoice from "../../assets/img/brand/rentInvoice.svg";
import sourceRentInvoice from "../../assets/img/brand/sourceRentInvoice.svg";
import timelineIcon from "../../assets/img/brand/invoiceTimeline.svg";
import TimeLineSidebar from "./timeLineSideBar";
import MarkPaymentSideBar from "./MarkPaymentSideDragger";
import moment from "moment";
import DepositStatsIcon from "../../assets/img/brand/depositStatsIcon.svg";
import InvoiceSendReminder from "./InvoiceSendReminder";
import Env from "../../constants/Env";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { CustomDatePicker } from "components/FilterFields/Filterfields";
import { AsscendingDecending } from "components/FilterFields/Filterfields";

class Invoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceList: [],
      total: 0,
      filterToggle: false,
      from: "",
      to: "",
      page: 1,
      inputSearch: "",
      groupView: true,
      filterOption: "propname",
      statusSearch: "",
      modalOpen: false,
      isLoading: true,
      invoiceDetail: {},
      lateFee: 0,
      toast: false,
      response: "",
      statsData: {},
      totalInvoicePercentage: 0,
      paidInvoicePercentage: 0,
      toastType: "",
      groupCurrentPage: 1,
      groupViewData: [],
      groupedData: [],
      contentLoading: false,
      groupViewToolTip: false,
      invoiceViewToolTip: false,
      invoiceModal: false,
      invoiceModalStatus: "",
      invoiceData: "",
      cancelInvoiceModal: false,
      invoiceId: "",
      invoiceAmt: 0,
      invoiceStatusView: "",
      timeLineModal: false,
      markPaymentModal: false,
      query: {},
      reminderModal: false,
      lateFeeRemoveModal: false,
      sortingList: "",
      removeFeeModal: false,
      isLoad: false,
      timeoutId: null,
      input_value: false,
      keyValue: ""
    };
  }

  reloadPage = () => {
    this.props.history.listen((location, search) => {
      if (location.search === "") {
        this.setState({ query: {} }, () => {
          let query = this.props.location.search;
          query = returnSearchObject(query);
          if (Object.keys(query).length === 0) {
            this.handleReset();
          }
        });
      } else {
        this.state.input_value = true
      }
    });
  };
  componentDidMount = () => {
    this.reloadPage();
    this.setQueryStatus();
  };

  setQueryStatus = () => {
    let default_property = getDefaultProperty();
    let queryStatus = this.props.location.search;
    if (queryStatus) {
      let queryObj = returnSearchObject(queryStatus);

      this.setState(
        {
          inputSearch:
            (queryObj.propname || queryObj.property_id || queryObj.username) ??
            "",
          filterOption: queryObj.propname
            ? "propname"
            : queryObj.property_id
              ? "property_id"
              : queryObj.username && "username",

          from: queryObj.from ? new Date(queryObj.from) : "",
          to: queryObj.to ? new Date(queryObj.to) : "",
          statusSearch: queryObj.status ?? "",
          page: parseInt(queryObj.page) ?? "",
          query: returnSearchObject(queryStatus),
          groupView: queryObj.status ? false : true,
        },
        () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getInvoiceList();
          this.getGroupItem();
          this.getInvoiceStats();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...this.state.query,
            page: 1,
            limit: 10,
            from: getIsoString(this.state.from),
            to: getIsoString(this.state.to),
            property_id: default_property,
          },
          inputSearch: "",
          // filterOption: default_property ? "property_id" : "",
        },
        () => {
          pushToFilteredPage(this.props, this.state.query);
          this.getInvoiceList();
          this.getGroupItem();
          this.getInvoiceStats();
        }
      );
    }
  };

  onChangeHandler = (e) => {
    e.target.value === "" && this.handleReset();
    this.setState({ [e.target.name]: e.target.value, input_value: true });
    clearTimeout(this.state.timeoutId);
    const timeoutId = setTimeout(() => {
      this.state.filterOption && this.handleSearch();
    }, 1000);
    this.setState({ timeoutId });
  };

  onChangeSelectHandler = (status) => {
    this.setState({ filterOption: status, input_value: true }, () => {
      this.handleFilterStatus();
    });
  }
  getInvoiceList = async () => {
    this.setState({ contentLoading: true });
    let { page, filterOption, statusSearch, inputSearch, from, to } =
      this.state;
    let queryParams = "";
    if (filterOption !== "" && inputSearch !== "")
      queryParams += filterQuery(filterOption, inputSearch);
    if (statusSearch !== "") {
      let status = statusSearch === "unpaid" ? "created" : statusSearch;
      queryParams += filterQuery("status", status);
    }
    if (from !== "") queryParams += filterQuery("from", getIsoString(from));
    if (to !== "") queryParams += filterQuery("to", getIsoString(to));
    if (from > to) {
      showError(this, "Please provide valid From and To date");
    } else {
      let response = await ApiService.getAPI(
        `host/invoice${buildQueryString(this.state.query)}&${userTypeQuery()}`
      );
      this.setState({ contentLoading: false });
      if (response.success) {
        let { invoices } = response.data;
        // let {InvoiceAmount} = response.data.amount;
        this.setState({ invoiceList: invoices, isLoading: false });
      } else {
        this.setState({ isLoading: false });
        showError(this, response.message);
      }
    }
  };

  getGroupItem = async () => {
    let { groupCurrentPage, filterOption, inputSearch, from, to } = this.state;
    let queryParams = "";
    if (filterOption !== "" && inputSearch !== "") {
      let option =
        filterOption === "propname"
          ? "name"
          : filterOption === "property_id" && "id";

      queryParams += filterQuery(option, inputSearch);
    }

    if (from !== "") queryParams += filterQuery("from", getIsoString(from));
    if (to !== "") queryParams += filterQuery("to", getIsoString(to));
    if (from > to) {
      showError(this, "Please provide valid From and To date");
    } else {
      let response = await ApiService.getAPI(
        `host/invoice/group${buildQueryString(
          this.state.query
        )}&${userTypeQuery()}`
      );

      if (response.success) {
        this.setState({ groupViewData: response.data }, () => {
          this.setGroupDate();
        });
      } else {
        this.setState({ isLoading: false });
        showError(this, response.message);
      }
    }
  };

  setGroupDate = () => {
    let { groupViewData } = this.state;
    let arr = groupViewData.property_list;
    let total = groupViewData.total;
    let unpaid = groupViewData.unpaid;
    let paid = groupViewData.paid;
    let cancell = groupViewData.cancelled;
    arr.map(
      (data) => (
        (data["total"] = total[data.property_id] ?? ""),
        (data["unpaid"] = unpaid[data.property_id] ?? ""),
        (data["paid"] = paid[data.property_id] ?? ""),
        (data["cancelled"] = cancell[data.property_id] ?? "")
      )
    );
    this.setState({ groupedData: arr });
  };

  getInvoiceStats = async () => {
    let default_property = getDefaultProperty();

    let { statsData, from, to, invoiceStatusView } = this.state;
    let queryParams = "";

    if (from !== "") {
      queryParams += `&from=${getIsoString(from)}`;
    }
    if (to !== "") queryParams += `&to=${getIsoString(to)}`;
    if (default_property !== "")
      queryParams += `&property_id=${default_property}`;

    let response = await ApiService.getAPI(
      `host/invoice/stats?${userTypeQuery()}${queryParams}`
    );
    if (response.success) {
      this.setState({ statsData: response.data }, () => {
        this.PercentageFunctions();
      });
    }
  };

  PercentageFunctions = () => {
    let { statsData } = this.state;
    if (statsData && statsData.inv_lm && statsData.inv_tm) {
      calculatePercentage(
        statsData && statsData.inv_tm.total,
        statsData && statsData.inv_lm.total,
        "totalInvoicePercentage",
        this
      );
    }
    if (statsData && statsData.paid_tm && statsData.paid_lm) {
      calculatePercentage(
        statsData && statsData.paid_tm && statsData.paid_tm.total,
        statsData && statsData.paid_lm && statsData.paid_lm.total,
        "paidInvoicePercentage",
        this
      );
    }
  };

  updateStateValues = (key, value) => {
    const updatedValue = value == null ? "" : value;
    this.setState({ [key]: updatedValue, input_value: true }, () => {
      if (this.state.from && this.state.to) {
        this.handleFilterStatus();
      }
    });
  };

  handleOnToChange = (e) => {
    this.updateStateValues("to", e);
  };

  handleOnFromChange = (e) => {
    this.updateStateValues("from", e);
  };

  handleIncrementPage = () => {
    if (
      this.state.invoiceList.length >= 10 ||
      this.state.groupedData.length >= 10
    ) {
      this.setState({ page: this.state.page + 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };

  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };

  handleReset = () => {
    let default_property = getDefaultProperty();
    const {
      from,
      to,
      statusSearch,
      filterOption,
      inputSearch,
      invoiceStatusView,
    } = this.state;
    let self = this;
    self.setState(
      {
        from: "",
        to: "",
        statusSearch: "",
        filterOption: this.state.groupView ? "propname" : "username",
        inputSearch: "",
        invoiceStatusView: "",
        page: 1,
        sortingList: "",
        query: {
          page: 1,
          limit: 10,
          property_id: default_property,
        },
        input_value: false,
        keyValue: ""
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });

        if (
          from ||
          to ||
          statusSearch ||
          filterOption ||
          inputSearch ||
          invoiceStatusView ||
          default_property
        ) {
          this.getGroupItem();
          this.getInvoiceList();
          this.getInvoiceStats();
        }
      }
    );
  };

  handleFilterStatus = (mode) => {
    let { inputSearch, filterOption } = this.state;
    let staticQuery = returnSearchObject(this.props.location.search);
    staticQuery.from = getIsoString(this.state.from) ?? "";
    staticQuery.to = getIsoString(this.state.to) ?? "";
    for (let key in staticQuery) {
      if (key === "propname" || key === "property_id" || key === "username") {
        delete staticQuery[key];
      }
    }
    if (this.state.inputSearch !== "" && this.state.filterOption !== "") {
      staticQuery[this.state.filterOption] = this.state.inputSearch;
    }
    staticQuery.status = this.state.statusSearch ?? "";
    staticQuery.page = this.state.page ?? "";
    staticQuery.sort = this.state.sortingList ?? "";

    this.setState({ query: staticQuery }, () => {
      pushToFilteredPage(this.props, { ...this.state.query });
      this.getGroupItem();
      this.getInvoiceList();
      this.getInvoiceStats();
    });
  };

  search = (card) => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus(card);
    });
  };
  handleSearch = () => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
  };
  toggleModal = (invoice_id) => {
    this.setState(
      {
        modalOpen: !this.state.modalOpen,
        invoiceDetail: {},
      },
      () => this.state.modalOpen && this.getInvoiceDetail(invoice_id)
    );
  };

  getInvoiceDetail = async (id) => {
    let response = await ApiService.getAPI(`host/invoice/${id}`);
    if (response.success) {
      this.setState({
        invoiceDetail: response.data.invoices,
      });
      let lateFee =
        response.data.invoices.amount - response.data.invoices.base_price;
      this.setState(
        {
          lateFee: lateFee,
        },
        () => { }
      );
    }
  };
  setGroupView = () => {
    this.setState(
      { page: 1, groupView: true, inputSearch: "", filterOption: "propname" },
      () => {
        this.search();
      }
    );
  };

  toggleGroupView = () => {
    this.setState({ groupViewToolTip: !this.state.groupViewToolTip });
  };

  setInvoiceView = () => {
    this.setState({ page: 1, groupView: false }, () => { this.setState({ filterOption: "username" }) });
  };

  listByData = (data) => {
    this.setState(
      {
        page: 1,
        groupView: false,
        filterOption: this.state.groupView ? "propname" : "username",
        inputSearch: data.name,
      },
      () => {
        this.search();
      }
    );
  };

  toggleInvoiceView = () => {
    this.setState({ invoiceViewToolTip: !this.state.invoiceViewToolTip });
  };

  handleCloseModal = () => {
    this.setState({ modalOpen: false });
  };
  handleSubLateFee = (amount, base_price) => {
    let lateFee = amount - base_price;
    this.setState({
      lateFee,
    });
  };

  displayTotalInvoice = () => {
    this.handleReset();
    this.setState({ groupView: false });
  };

  displayStatusInvoice = (status) => {
    this.setState(
      {
        page: 1,
        groupView: false,
        filterOption: "",
        inputSearch: "",
      },
      () => {
        this.setStatus(status);
      }
    );
  };
  // displayUnpaidInvoice = () => {
  //   this.setState(
  //     {
  //       page: 1,
  //       groupView: false,
  //       filterOption: "",
  //       inputSearch: "",
  //     },
  //     () => {
  //       this.setStatus("created");
  //     }
  //   );
  // };

  setStatus = (status) => {
    this.setState({ statusSearch: status, page: 1, input_value: true }, () => {
      this.handleFilterStatus();
    });
  };

  setInvoiceStatusView = (status) => {
    let from;
    let to;
    this.setState({ invoiceStatusView: status, input_value: true });
    if (status === "tm") {
      from = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      to = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
    } else if (status === "lm") {
      from = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
      to = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
    } else if (status === "ty") {
      from = new Date(new Date().getFullYear(), 0, 1);
      to = new Date(new Date().getFullYear(), 11, 31);
    } else {
      from = "";
      to = "";
    }
    this.setState({ from, to, page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  setInvoiceDetail = (status, data) => {
    this.setInvoiceModalStatus(status);
    this.setInvoiceData(data);
  };

  setInvoiceModalStatus = (status) => {
    this.setState({ invoiceModalStatus: status }, () => {
      this.openModal();
    });
  };

  setInvoiceData = (data) => {
    this.setState({ invoiceData: data });
  };

  openModal = () => {
    this.setState({ invoiceModal: true });
  };
  closeModal = () => {
    this.setState({ invoiceModal: false, invoiceData: "" });
    this.setState({ removeFeeModal: false, invoiceId: "", invoiceAmt: 0 });
  };

  openCancelInvoicePopup = (id) => {
    this.setState({ cancelInvoiceModal: true, invoiceId: id });
  };

  toggleCancelReminderModal = (msg) => {
    this.setState({ reminderModal: false, invoiceId: "" });
    if (msg) {
      showSuccess(this, msg);
      this.handleFilterStatus();
    }
  };

  toggleCancelInvoiceModal = (msg) => {
    this.setState({ cancelInvoiceModal: false, invoiceId: "" });
    if (msg) {
      showSuccess(this, msg);
      this.getInvoiceList();
      this.getInvoiceStats();
    }
  };
  toggleLateFeeRemoveModal = (msg) => {
    const { lateFeeRemoveModal } = this.state;
    this.setState({
      lateFeeRemoveModal: !lateFeeRemoveModal,
      invoiceId: "",
      invoiceData: "",
    });
    if (msg) {
      showSuccess(this, msg);
      this.getInvoiceList();
      this.getInvoiceStats();
    }
  };
  openTimeLineModal = (id) => {
    this.setState({ invoiceId: id, timeLineModal: true });
  };

  closeTimeLineModal = () => {
    this.setState({ timeLineModal: false, invoiceId: "" });
  };

  openMarkPaymentSideDragger = (id) => {
    this.setState({ markPaymentModal: true, invoiceId: id });
  };
  openLateFeeRemoveModal = (id, invoice) => {
    this.setState({
      lateFeeRemoveModal: true,
      invoiceId: id,
      invoiceData: invoice,
    });
  };

  closeMarkPaymentSideDraggger = (msg) => {
    if (msg) {
      showSuccess(this, msg);
      this.getInvoiceList();
      this.getInvoiceStats();
    }
    this.setState({ markPaymentModal: false, invoiceId: "" });
  };
  displayErrorMessage = (msg) => {
    if (msg) {
      showError(this, msg);
    }
  };

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  setSortingList = (status) => {
    this.setState({ sortingList: status, page: 1, input_value: true }, () => {
      this.handleFilterStatus();
    });
  };

  downloadInvoice = async () => {
    let downloadType = "";
    if (this.state.groupView) {
      downloadType = "_group";
    }
    let apival = removeKeys(this.state);
    fetch(
      Constants.BASE_URL + `host/invoice/download${downloadType}${apival}`,
      returnDownloadGet()
    )
      .then((response) => {
        return response.blob();
      })
      .then((data) => {
        if (data.type === "application/json") {
          showError(this, "No records found to download");
        } else {
          const downloadLink = document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(data);
          downloadLink.download = "test.xlsx";
          downloadLink.setAttribute("download", `Invoice.xlsx`);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }
      });
  };

  downloadInvoicePdf = async (id) => {
    fetch(Env.BASE_URL + `host/invoice/pdf/${id}`, returnDownloadGet())
      .then((response) => {
        return response.blob();
      })
      .then((data) => {
        if (data.type === "application/json") {
          showError(this, "No records found to download");
        } else {
          const downloadLink = document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(data);
          downloadLink.setAttribute("download", `${id}.pdf`);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }
      });
  };
  removeAchFee = async (invoice) => {
    this.setState({
      removeFeeModal: true,
      invoiceId: invoice.invoice_id,
      invoiceAmt: invoice.ach.amount,
    });
  };
  callAchFee = async () => {
    this.setState({ isLoad: true });
    let response = await ApiService.patchAPI(
      `host/invoice/waive_ach_fee/${this.state.invoiceId}`
    );
    this.setState({
      removeFeeModal: false,
      invoiceId: "",
      invoiceAmt: 0,
      isLoad: false,
    });
    if (response.success) {
      showSuccess(this, response.message);
      this.getInvoiceList();
      this.getInvoiceStats();
    } else {
      showError(this, response.message);
    }
  };
  action = (key) => {
    let skey = returnSortKey(this, key);
    this.setState({ keyValue: skey, sortingList: skey, page: 1, input_value: true }, () => { this.handleFilterStatus() })
  }
  render() {
    let {
      invoiceList,
      inputSearch,
      filterOption,
      statusSearch,
      isLoading,
      page,
      modalOpen,
      invoiceDetail,
      totalInvoicePercentage,
      paidInvoicePercentage,
      lateFee,
      toast,
      response,
      statsData,
      toastType,
      groupView,
      groupedData,
      invoiceModal,
      invoiceData,
      invoiceModalStatus,
      cancelInvoiceModal,
      invoiceId,
      invoiceStatusView,
      from,
      to,
      timeLineModal,
      markPaymentModal,
      lateFeeRemoveModal,
      sortingList,
      removeFeeModal,
      isLoad,
      input_value
    } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#012A4D" />
      </div>
    ) : (
      <>
        {toast && <ToastList message={response} type={toastType} />}
        <div style={{ margin: "24px 24px -24px 24px" }}>
          <div className="header-body">
            <Row className="row-center">
              <Col lg="6" xl="3" className="max-dash mb_mt_28 ">
                <Card
                  className="card-stats mb-4 mb-xl-0 dash-new point"
                  onClick={() => this.displayTotalInvoice()}
                >
                  <CardBody className="pad-dash invoice_dash point">
                    <div className="dash_content dash_content_invoice point ">
                      <div className="dash_left">
                        <div className="heading_with_badge">
                          <h4>OVERALL</h4>
                        </div>
                        <label className="number-con mb_15 point">
                          <CountUp
                            start={0}
                            end={returnSecondDegreeObjValue(
                              statsData,
                              "total",
                              "total"
                            )}
                            separator=","
                            prefix="$"
                            className="point"
                          />
                        </label>
                        <div className="count_with_heading">
                          <div className="count_div">
                            <CountUp
                              start={0}
                              end={returnSecondDegreeObjValue(
                                statsData,
                                "total",
                                "count"
                              )}
                              separator=","
                              prefix=""
                            />
                          </div>
                          <p>Invoices</p>
                        </div>
                      </div>
                      <div className="dash_right_container">
                        {isNaN(totalInvoicePercentage) ? (
                          <></>
                        ) : (
                          <>
                            {this.state.statsData.inv_lm &&
                              this.state.statsData.inv_lm.total !== 0 && (
                                <>
                                  <div className="percentage_calulate">
                                    <div
                                      id="value"
                                      className={
                                        totalInvoicePercentage &&
                                          totalInvoicePercentage > 0 &&
                                          this.state.statsData.inv_lm.total !== 0
                                          ? "invoice_badge_increased"
                                          : "invoice_badge_decreased"
                                      }
                                    >
                                      <img
                                        src={
                                          totalInvoicePercentage &&
                                            totalInvoicePercentage > 0
                                            ? InvoiceIncrement
                                            : InvoiceDecrement
                                        }
                                      />
                                      <span>
                                        {totalInvoicePercentage &&
                                          returnPositiveNumber(
                                            totalInvoicePercentage
                                          )}{" "}
                                        %
                                      </span>
                                    </div>
                                  </div>
                                </>
                              )}
                          </>
                        )}

                        <div className="col dash-col dash_right dash_right_invoice">
                          <img
                            src={TotalInvoice}
                            className="img-dash"
                            alt="total sales"
                          />
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="3" className="max-dash mb_mt_28">
                <Card
                  className="card-stats mb-4 mb-xl-0 dash-new point"
                  onClick={() => this.displayStatusInvoice("paid")}
                >
                  <CardBody className="pad-dash invoice_dash">
                    <div className="dash_content dash_content_invoice ">
                      <div className="dash_left">
                        <div className="heading_with_badge">
                          <h4>PAID</h4>
                        </div>
                        <label className="number-con mb_15 point">
                          <CountUp
                            start={0}
                            end={returnSecondDegreeObjValue(
                              statsData,
                              "total_paid",
                              "total"
                            )}
                            separator=","
                            prefix="$"
                          />
                        </label>
                        <div className="count_with_heading">
                          <div className="count_div">
                            <CountUp
                              start={0}
                              end={returnSecondDegreeObjValue(
                                statsData,
                                "total_paid",
                                "count"
                              )}
                              separator=","
                              prefix=""
                            />
                          </div>
                          <p>invoices</p>
                        </div>
                      </div>
                      <div className="dash_right_container">
                        {isNaN(paidInvoicePercentage) ? (
                          <></>
                        ) : (
                          <>
                            {this.state.statsData.paid_lm &&
                              this.state.statsData.paid_lm.total !== 0 && (
                                <>
                                  <div className="percentage_calulate">
                                    <div
                                      id="value"
                                      className={
                                        paidInvoicePercentage &&
                                          paidInvoicePercentage > 0
                                          ? "invoice_badge_increased"
                                          : "invoice_badge_decreased"
                                      }
                                    >
                                      <img
                                        src={
                                          paidInvoicePercentage &&
                                            paidInvoicePercentage > 0
                                            ? InvoiceIncrement
                                            : InvoiceDecrement
                                        }
                                      />
                                      <span>
                                        {paidInvoicePercentage &&
                                          returnPositiveNumber(
                                            paidInvoicePercentage
                                          )}{" "}
                                        %
                                      </span>
                                    </div>
                                  </div>
                                </>
                              )}
                          </>
                        )}

                        <div className="col dash-col dash_right dash_right_invoice">
                          <img
                            src={PaidInvoice}
                            className="img-dash"
                            alt="total sales"
                          />
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="3" className="max-dash mb_mt_28 ">
                <Card
                  className="card-stats mb-4 mb-xl-0 dash-new point"
                  onClick={() => this.displayStatusInvoice("created")}
                >
                  <CardBody className="pad-dash invoice_dash">
                    <div className="dash_content dash_content_invoice ">
                      <div className="dash_left">
                        <div className="heading_with_badge">
                          <h4>UNPAID</h4>
                        </div>
                        <label className="number-con mb_15 point">
                          <CountUp
                            start={0}
                            end={returnSecondDegreeObjValue(
                              statsData,
                              "total_unpaid",
                              "total_unpaid"
                            )}
                            separator=","
                            prefix="$"
                          />
                        </label>
                        <div className="count_with_heading">
                          <div className="count_div">
                            <CountUp
                              start={0}
                              end={returnSecondDegreeObjValue(
                                statsData,
                                "total_unpaid",
                                "count_unpaid"
                              )}
                              separator=","
                              prefix=""
                            />
                          </div>
                          <p>invoices</p>
                        </div>
                      </div>
                      <div className="dash_right_container">
                        <div className="percentage_calulate"></div>
                        <div className="col dash-col dash_right dash_right_invoice">
                          <img
                            src={UnpaidInvoice}
                            className="img-dash"
                            alt="total sales"
                          />
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="3" className="max-dash mb_mt_28 ">

                <Card
                  className="card-stats mb-4 mb-xl-0 dash-new"
                  onClick={
                    RoleBasedCRUDAccess("deposit", "view")
                      ? () =>
                        this.props.history.push(
                          `/admin/deposits?page=1&limit=10&status=paid`
                        )
                      : ""
                  }
                >
                  <CardBody className="pad-dash invoice_dash">
                    <div className="dash_content dash_content_invoice ">
                      <div className="dash_left">
                        <div className="heading_with_badge">
                          <h4>DEPOSITS</h4>
                        </div>
                        <label className="number-con mb_15 point">
                          <CountUp
                            start={0}
                            end={returnSecondDegreeObjValue(
                              statsData,
                              "deposit",
                              "total"
                            )}
                            separator=","
                            prefix="$"
                          />
                        </label>
                        <div className="count_with_heading">
                          <div className="count_div">
                            <CountUp
                              start={0}
                              end={returnSecondDegreeObjValue(
                                statsData,
                                "deposit",
                                "count"
                              )}
                              separator=","
                              prefix=""
                            />
                          </div>
                          <p>Deposits</p>
                        </div>
                      </div>
                      <div className="dash_right_container">
                        <div className="percentage_calulate"></div>
                        <div className="col dash-col dash_right dash_right_invoice">
                          <img
                            src={DepositStatsIcon}
                            className="img-dash"
                            alt="total sales"
                          />
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <CustomListingContainer>
          <ListingHeaderLayout label={"Invoices"}>
            <div className="justend">
              <div className="flexEnd invoiceStatusView" style={{ width: "170px", marginLeft: "15px" }}>
                <UncontrolledDropdown className="status_select_div ">
                  <DropdownToggle className="pr-0 drop_down_container">
                    <Media className="capitalize">
                      {invoiceStatusView === "tm"
                        ? "This Month"
                        : invoiceStatusView === "lm"
                          ? "Last Month"
                          : invoiceStatusView === "ty"
                            ? "This Year"
                            : "All"}
                      <i className="fas fa-angle-down"></i>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" bottom>
                    {" "}
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.setInvoiceStatusView("")}
                    >
                      All
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.setInvoiceStatusView("tm")}
                    >
                      This Month
                    </DropdownItem>{" "}
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.setInvoiceStatusView("lm")}
                    >
                      Last Month
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.setInvoiceStatusView("ty")}
                    >
                      This Year
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
            <div className="add-filter-wrap"></div>
          </ListingHeaderLayout>
          <div style={{ margin: "20px" }}>
            <InputGroup className="flex-content align-left">
              <div className="filters_container">
                <div className="invoice_left_filter_container">
                  <div className="w40">
                    <CustomDatePicker
                      from={from}
                      to={to}
                      fromChange={this.handleOnFromChange}
                      toChange={this.handleOnToChange}
                    ></CustomDatePicker>
                  </div>
                  {/* <div className="select_with_input mr-10 w40">
                    <Dropdown
                      variant="input-group"
                      className="select_input_drop point"
                    >
                      <select
                        id="inputGroupSelect01"
                        className="form_select fixed_select point"
                        name="filterOption"
                        value={filterOption}
                        onChange={this.onChangeHandler}
                      >
                        {!groupView && <option value="username">Tenant</option>}
                        <option value="propname">Property</option>
                        <option value="property_id">Property Id</option>
                      </select>
                    </Dropdown>
                    <Input
                      aria-label="Text input with dropdown button"
                      className="input-filter-toggle"
                      value={inputSearch}
                      name="inputSearch"
                      onChange={this.onChangeHandler}
                      placeholder="Search by"
                    />
                  </div> */}
                  <div className="select_with_input status_select_input ml-10 mr-10" style={{ marginRight: "20px" }}>
                    <UncontrolledDropdown className="status_select_div" >
                      <DropdownToggle className="pr-0 drop_down_container">
                        <Media className="capitalize">
                          {filterOption === "username" ? "Tenant"
                            : filterOption === "propname" ? "Property"
                              : filterOption === "property_id" && "Property Id"
                          }
                          <i className="fas fa-angle-down" style={{ marginLeft: "20px" }}></i>
                        </Media>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" bottom style={{ minWidth: "130px" }}>
                        {" "}
                        {!groupView && <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.onChangeSelectHandler("username")}
                        >
                          Tenant
                        </DropdownItem>}
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.onChangeSelectHandler("propname")}
                        >
                          Property
                        </DropdownItem>
                        {/* {!groupView && <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.onChangeSelectHandler("property_id")}
                        >
                          Property Id
                        </DropdownItem>} */}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <Input
                      aria-label="Text input with dropdown button"
                      value={inputSearch}
                      name="inputSearch"
                      placeholder="search by"
                      onChange={this.onChangeHandler}
                    />
                  </div>
                  {!groupView && (
                    <div className="status_select ml-0">
                      <UncontrolledDropdown className="status_select_div">
                        <DropdownToggle className="pr-0 drop_down_container">
                          <Media className="capitalize">
                            {statusSearch === ""
                              ? "All"
                              : statusSearch === "created"
                                ? "Unpaid"
                                : statusSearch === "past_due"
                                  ? "Past Due"
                                  : statusSearch}
                            <i className="fas fa-angle-down"></i>
                          </Media>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-arrow" bottom>
                          {" "}
                          <DropdownItem
                            className="dropdown_item"
                            onClick={() => this.setStatus("")}
                          >
                            All
                          </DropdownItem>{" "}
                          <DropdownItem
                            className="dropdown_item"
                            onClick={() => this.setStatus("paid")}
                          >
                            Paid
                          </DropdownItem>
                          <DropdownItem
                            className="dropdown_item"
                            onClick={() => this.setStatus("created")}
                          >
                            Unpaid
                          </DropdownItem>
                          <DropdownItem
                            className="dropdown_item"
                            onClick={() => this.setStatus("past_due")}
                          >
                            Past Due
                          </DropdownItem>
                          <DropdownItem
                            className="dropdown_item"
                            onClick={() => this.setStatus("cancelled")}
                          >
                            Cancelled
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  )}
                </div>
                <div className="invoice_right_filter_container">
                  {input_value && (inputSearch || (from || to) || sortingList) &&
                    <Button
                      color="primary"
                      onClick={this.handleReset}
                      size="sm"
                      className="button-wrapping mr-4 button-secondary reset-btn"
                      style={{ fontSize: "15px", marginRight: "17px !important" }}
                    >
                      Clear filter
                    </Button>}
                  <img
                    src={groupView ? groupingSelected : grouping}
                    alt="group"
                    className="point mr-12"
                    onClick={() => this.setGroupView()}
                    id="groupViewToolTip"
                    data-tip="Grouping"
                    data-iscapture="true"
                    data-for="groupViewToolTip"
                    data-place="bottom"
                  />
                  <ReactTooltip
                    place="bottom"
                    type="info"
                    effect="solid"
                    id="groupViewToolTip"
                    html={true}
                  />

                  <img
                    src={groupView ? invoiceView : invoiceViewSelected}
                    alt="invoice"
                    className="point mr-10"
                    onClick={() => this.setInvoiceView()}
                    id="invoiceViewToolTip"
                    data-tip="Listing"
                    data-iscapture="true"
                    data-for="invoiceViewToolTip"
                    data-place="bottom"
                  />
                  <ReactTooltip
                    place="bottom"
                    type="info"
                    effect="solid"
                    id="invoiceViewToolTip"
                    html={true}
                  />

                  {/* <Button
                    color="primary"
                    onClick={() => this.search("search")}
                    size="sm"
                    className="button-wrapping search-wrapper "
                  >
                    Search
                  </Button> */}
                  <>
                    {" "}
                    <div
                      onClick={() => this.downloadInvoice()}
                      className="download-btn point"
                      id="groupViewToolTip"
                      data-tip="Download"
                      data-iscapture="true"
                      data-for="downloadInvoice"
                      data-place="bottom"
                    >
                      <img src={downloadIcon} style={{ width: "17px" }} />
                    </div>
                    <ReactTooltip
                      place="bottom"
                      type="info"
                      effect="solid"
                      id="downloadInvoice"
                      html={true}
                    />
                  </>
                </div>
              </div>
            </InputGroup>
          </div>
          {this.state.contentLoading ? (
            <div className="spin-loading">
              <Spinner type="border" color="#012A4D" />
            </div>
          ) : (
            <>
              <Row>
                {groupView ? (
                  <div className="col new-table">
                    <Card className="shadow card_with_paginator">
                      <Table
                        className="align-items-center table-flush"
                        responsive
                        style={{ overflowX: "scroll" }}
                      >
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="pl_40 justLeft items-center point bnone padLeft30"
                              style={{ display: "flex" }}
                              onClick={() => this.action("pname")}
                            >
                              <AsscendingDecending keyValue={this.state.keyValue} value="pname" name="Property" />
                            </th>
                            <th scope="col">Overall</th>
                            <th scope="col">Paid</th>
                            <th scope="col">Unpaid</th>
                            <th scope="col">Cancelled</th>
                          </tr>
                        </thead>

                        {groupedData.length !== 0 ? (
                          <tbody>
                            {groupedData.map((data, i) => (
                              <tr>
                                <td
                                  className="point tdColumn tdColumn_35 padLeft30"
                                  onClick={() => this.listByData(data)}
                                >
                                  {returnFirstDegreeObjValue(data, "name")}
                                </td>
                                <td
                                  onClick={() => this.setInvoiceDetail("", data)}
                                  className="point"
                                >
                                  {displayPrice(
                                    returnFirstDegreeObjValue(data, "total")
                                  )}
                                </td>
                                <td
                                  className="point"
                                  onClick={() =>
                                    this.setInvoiceDetail("paid", data)
                                  }
                                >
                                  {displayPrice(
                                    returnFirstDegreeObjValue(data, "paid")
                                  )}
                                </td>
                                <td
                                  className="point"
                                  onClick={() =>
                                    this.setInvoiceDetail("created", data)
                                  }
                                >
                                  {displayPrice(
                                    returnFirstDegreeObjValue(data, "unpaid")
                                  )}
                                </td>
                                <td>
                                  {displayPrice(
                                    returnFirstDegreeObjValue(data, "cancelled")
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td className="no-data">No Invoices Available</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        )}
                      </Table>
                    </Card>
                  </div>
                ) : (
                  <div className="col new-table">
                    <Card className="shadow card_with_paginator">
                      <Table
                        className="align-items-center table-flush"
                        responsive
                        style={{ overflowX: "scroll" }}
                      >
                        <thead className="thead-light">
                          <tr>
                            {invoiceList.length > 0 && (<th scope="col" className="pad1015"></th>)}
                            <th scope="col" className={invoiceList.length > 0 ? "padl1r" : "padLeft30"}  >
                              Invoice Id
                            </th>
                            <th scope="col" className="bnone" style={{ padding: "0px", display: "flex" }}>
                              <th
                                scope="col"
                                className="pl_40 justLeft items-center bnone point tdColumn_20"
                                style={{ display: "flex" }}
                                onClick={() => this.action("user")}
                              >
                                <AsscendingDecending keyValue={this.state.keyValue} value="user" name="Tenant" />
                              </th>
                              <th
                                scope="col"
                                className="pl_40 justLeft items-center bnone point tdColumn_20"
                                style={{ display: "flex" }}
                                onClick={() => this.action("pname")}
                              >
                                <AsscendingDecending keyValue={this.state.keyValue} value="pname" name="Property" />
                              </th>
                            </th>
                            <th scope="col">Created On</th>
                            <th scope="col">Due on</th>
                            <th
                              scope="col"
                              className="pl_40 justLeft items-center bnone point"
                              style={{ display: "flex" }}
                              onClick={() => this.action("amount")}  >
                              <AsscendingDecending keyValue={this.state.keyValue} value="amount" name="Amount" />
                            </th>
                            <th scope="col">Status</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        {invoiceList.length !== 0 ? (
                          <tbody>
                            {invoiceList.map((invoice, i) => (
                              <tr key={i}>
                                <td className="pad1015">
                                  <div
                                    data-tip={
                                      invoice?.type === "source_rent"
                                        ? "Source Rent"
                                        : this.capitalizeFirstLetter(invoice?.type)
                                    }
                                    data-iscapture="true"
                                    data-for={String(i)}
                                    data-place="top"
                                    className="point flexmid"
                                  >
                                    {invoice?.type ? (
                                      invoice?.type === "rent" ? (
                                        <img src={rentInvoice} id={invoice?.type} />
                                      ) : invoice?.type === "source_rent" ? (
                                        <img
                                          src={sourceRentInvoice}
                                          id={invoice?.type}
                                        />
                                      ) : invoice?.type === "request" ? (
                                        <img
                                          src={requestInvoice}
                                          id={invoice?.type}
                                        />
                                      ) : (
                                        invoice?.type === "maintenance" && (
                                          <img
                                            src={maintenanceInvoice}
                                            id={invoice?.type}
                                          />
                                        )
                                      )
                                    ) : (
                                      ""
                                    )}
                                    <ReactTooltip
                                      place="right"
                                      type="info"
                                      effect="solid"
                                      id={String(i)}
                                      html={true}
                                    />
                                  </div>
                                </td>
                                <td
                                  className="text-capitalize cursor-point padl1r"
                                  onClick={() =>
                                    this.toggleModal(invoice.invoice_id)
                                  }
                                  style={{ width: "170px" }}
                                >
                                  {returnFirstDegreeObjValue(invoice, "invoice_id")}
                                </td>
                                <td style={{ padding: "0px", width: "400px" }}>
                                  <td
                                    className="text-capitalize cursor-point btopNone tdColumn tdColumn_20"
                                    onClick={() =>
                                      redirectToBookings(this, invoice.booking_id)
                                    }
                                  >
                                    {returnCustomerFirsLastName(
                                      invoice,
                                      "user",
                                      "name",
                                      "first",
                                      "last"
                                    )}
                                  </td>
                                  <td className="btopNone tdColumn tdColumn_20"
                                  >
                                    {returnSecondDegreeObjValue(
                                      invoice,
                                      "property",
                                      "name"
                                    )}{" "}
                                  </td>
                                </td>
                                <td>
                                  {" "}
                                  {showLocaldate(
                                    returnFirstDegreeObjValue(invoice, "createdAt")
                                  )}
                                </td>
                                <td style={{ width: "170px" }}>
                                  {showLocaldate(
                                    returnFirstDegreeObjValue(invoice, "due")
                                  )}
                                </td>
                                <td className="tdColumn tdColumn_18">
                                  {displayPrice(
                                    returnFirstDegreeObjValue(invoice, "amount")
                                  )}
                                </td>
                                <td
                                  className={
                                    invoice.status === "paid"
                                      ? "text-capitalize green general_status_style"
                                      : invoice.status === "created"
                                        ? "text-capitalize yellow general_status_style"
                                        : invoice.status === "cancelled"
                                          ? "text-capitalize red general_status_style"
                                          : "text-capitalize"
                                  }
                                >
                                  {returnFirstDegreeObjValue(invoice, "status") ===
                                    "created"
                                    ? "Unpaid"
                                    : returnFirstDegreeObjValue(invoice, "status")}
                                </td>
                                <td style={{ textAlign: "left", padding: "0px" }}>
                                  <div className="flexStart">
                                    <img
                                      src={timelineIcon}
                                      className="mr-20 point"
                                      onClick={() =>
                                        this.openTimeLineModal(invoice.invoice_id)
                                      }
                                    />
                                    <div>
                                      {invoice.status === "created" &&
                                        RoleBasedCRUDAccess("invoice", "edit") ? (
                                        <UncontrolledDropdown
                                          nav
                                          className="position_u"
                                        >
                                          <DropdownToggle
                                            className="pr-0 nav-notice"
                                            nav
                                          >
                                            <Media className="align-items-center">
                                              <img src={showMore} />
                                            </Media>
                                          </DropdownToggle>

                                          <DropdownMenu
                                            className="dropdown-menu-arrow min-width-drop"
                                            right
                                          >
                                            <>
                                              <DropdownItem
                                                onClick={() =>
                                                  this.openCancelInvoicePopup(
                                                    invoice.invoice_id
                                                  )
                                                }
                                              >
                                                <span>Cancel</span>
                                              </DropdownItem>
                                              <DropdownItem
                                                onClick={() =>
                                                  this.openMarkPaymentSideDragger(
                                                    invoice.invoice_id
                                                  )
                                                }
                                              >
                                                <span>Mark Payment</span>
                                              </DropdownItem>
                                              {invoice?.type === "rent" &&
                                                invoice?.amount >
                                                invoice?.base_price && (
                                                  <DropdownItem
                                                    onClick={() =>
                                                      this.openLateFeeRemoveModal(
                                                        invoice.invoice_id,
                                                        invoice
                                                      )
                                                    }
                                                  >
                                                    <span>Remove Late Fee</span>
                                                  </DropdownItem>
                                                )}

                                              {invoice?.invoice_to === "tenant" && (
                                                <DropdownItem
                                                  onClick={() =>
                                                    this.setState({
                                                      reminderModal: true,
                                                      invoiceId: invoice.invoice_id,
                                                    })
                                                  }
                                                >
                                                  <span>Send Reminder</span>
                                                </DropdownItem>
                                              )}
                                              {invoice.ach &&
                                                invoice.ach.count > 0 && (
                                                  <DropdownItem
                                                    onClick={() =>
                                                      this.removeAchFee(invoice)
                                                    }
                                                  >
                                                    <span>Remove ACH Fee</span>
                                                  </DropdownItem>
                                                )}
                                              <DropdownItem
                                                onClick={() =>
                                                  this.downloadInvoicePdf(
                                                    invoice.invoice_id
                                                  )
                                                }
                                              >
                                                <span>Download Invoice</span>
                                              </DropdownItem>
                                            </>
                                          </DropdownMenu>
                                        </UncontrolledDropdown>
                                      ) : invoice.status === "cancelled" ? (
                                        <div
                                          data-tip={invoice?.cancel_reason}
                                          data-iscapture="true"
                                          data-for="toolTip1"
                                          data-place="top"
                                          className="new-border-request no_border mr-10 cladb5bd"
                                        >
                                          <i className="fa fa-info-circle verticalMidAlign"></i>
                                          <ReactTooltip
                                            place="top"
                                            type="info"
                                            effect="solid"
                                            id="toolTip1"
                                            html={true}
                                          />
                                        </div>
                                      ) : invoice.status != "created" &&
                                        RoleBasedCRUDAccess("invoice", "edit") ? (
                                        <UncontrolledDropdown>
                                          <DropdownToggle
                                            className="pr-0 nav-notice"
                                            nav
                                          >
                                            <Media className="align-items-center">
                                              <img
                                                src={showMore}
                                                style={{ marginTop: "5px" }}
                                              />
                                            </Media>
                                          </DropdownToggle>
                                          <DropdownMenu
                                            className="dropdown-menu-arrow min-width-drop"
                                            right
                                          >
                                            <DropdownItem
                                              onClick={() =>
                                                this.downloadInvoicePdf(
                                                  invoice.invoice_id
                                                )
                                              }
                                            >
                                              <span>Download Invoice</span>
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </UncontrolledDropdown>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </td>

                              </tr>
                            ))}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td className="no-data">No Invoices Available</td>
                              <td style={{ width: "200px" }}></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        )}
                      </Table>
                    </Card>
                  </div>
                )}
              </Row>
              <Row className="align-items-center jus-space new-margin-page paginator_box">
                {groupView ? (
                  <div className="paginate-flex-box ">
                    <div
                      className="paginate-box"
                      onClick={() => this.handleDecrementPage()}
                    >
                      <p
                        className={
                          page !== 1 ? "page_available" : "page_unavailable"
                        }
                      >
                        Previous
                      </p>
                    </div>
                    <div
                      className="paginate-box"
                      onClick={() => this.handleIncrementPage()}
                    >
                      <p
                        className={
                          groupedData.length < 10
                            ? "page_unavailable"
                            : "page_available"
                        }
                      >
                        Next
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="paginate-flex-box ">
                    <div
                      className="paginate-box"
                      onClick={() => this.handleDecrementPage()}
                    >
                      <p
                        className={
                          page !== 1 ? "page_available" : "page_unavailable"
                        }
                      >
                        Previous
                      </p>
                    </div>
                    <div
                      className="paginate-box"
                      onClick={() => this.handleIncrementPage()}
                    >
                      <p
                        className={
                          invoiceList.length < 10
                            ? "page_unavailable"
                            : "page_available"
                        }
                      >
                        Next
                      </p>
                    </div>
                  </div>
                )}
                <Modal
                  isOpen={cancelInvoiceModal}
                  toggle={() => this.toggleCancelInvoiceModal()}
                  centered
                >
                  <CancelInvoiceModal
                    close={this.toggleCancelInvoiceModal}
                    id={invoiceId}
                  />
                </Modal>
                <Modal
                  isOpen={lateFeeRemoveModal}
                  toggle={() => this.toggleLateFeeRemoveModal()}
                  centered
                >
                  <LateFeeRemoveModal
                    close={this.toggleLateFeeRemoveModal}
                    id={invoiceId}
                    data={invoiceData}
                  />
                </Modal>
                <Modal
                  isOpen={this.state.reminderModal}
                  toggle={() => this.toggleCancelReminderModal()}
                  centered
                >
                  <InvoiceSendReminder
                    close={this.toggleCancelReminderModal}
                    id={invoiceId}
                  />
                </Modal>
                <Modal
                  className="modal-dialog-centered modal-dialog-invoice"
                  isOpen={modalOpen}
                  toggle={() => this.toggleModal()}
                >
                  <div className="bottom-line-invoice">
                    <div className="modal-header invoice-header">
                      <h5
                        className="modal-title invoice-title"
                        id="exampleModalLabel"
                      >
                        INVOICE
                      </h5>
                      <div>
                        <img src={Park} className="invoice-park" />
                      </div>
                    </div>
                  </div>
                  <div className="modal-body profile-body invoice-body">
                    <Row>
                      <Col lg="6" className="pad-left-invoice">
                        <FormGroup>
                          <label
                            className="label-invoice-prop mb-0 label-invoice"
                            htmlFor="input-username"
                          >
                            Property Details
                          </label>
                          <p className="prop-statsData-para">
                            {returnSecondDegreeObjValue(
                              invoiceDetail,
                              "property",
                              "name"
                            )}
                          </p>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className="modal-body profile-body invoice-body">
                    <Row>
                      <Col lg="12" className="pad-left-invoice">
                        <FormGroup className="flex-invoice flex-secondary-invoice mb-10">
                          <label
                            className="label-invoice-prop mb-0 label-invoice"
                            htmlFor="input-username"
                          >
                            Invoice ID
                          </label>
                          <label
                            className="label-invoice-id mb-0 label-invoice"
                            htmlFor="input-username"
                          >
                            {returnFirstDegreeObjValue(invoiceDetail, "invoice_id")}
                          </label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" className="pad-left-invoice">
                        <FormGroup className="flex-invoice ">
                          <label
                            className="label-invoice-prop mb-0 "
                            htmlFor="input-username"
                          >
                            Created On
                          </label>
                          <label
                            className="label-invoice-id mb-0 "
                            htmlFor="input-username"
                          >
                            {showLocaldate(
                              returnFirstDegreeObjValue(invoiceDetail, "createdAt")
                            )}
                          </label>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className="modal-body profile-body invoice-body">
                    <Row>
                      <Col lg="12" className="pad-left-invoice">
                        <FormGroup className="flex-invoice ">
                          <label
                            className="label-invoice-prop mb-0 label-invoice"
                            htmlFor="input-username"
                          >
                            Description
                          </label>
                          <label
                            className="label-invoice-prop mb-0 label-invoice"
                            htmlFor="input-username"
                          >
                            Amount
                          </label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" className="pad-left-invoice">
                        <FormGroup className="flex-invoice ">
                          {invoiceDetail && (
                            <label
                              className="label-invoice-name mb-0 "
                              htmlFor="input-username"
                            >
                              {invoiceDetail?.type === "rent" ? (
                                <div>
                                  Rent for {getMonthFromDate(invoiceDetail?.due)}{" "}
                                </div>
                              ) : invoiceDetail?.type === "request" ? (
                                "Request"
                              ) : invoiceDetail?.type === "maintenance" ? (
                                "Maintenance"
                              ) : (
                                invoiceDetail?.type === "source_rent" &&
                                "Source Rent"
                              )}
                            </label>
                          )}

                          <label
                            className="label-invoice-name mb-0 "
                            htmlFor="input-username"
                          >
                            {invoiceDetail?.type === "rent"
                              ? displayPrice(
                                returnFirstDegreeObjValue(
                                  invoiceDetail,
                                  "base_price"
                                )
                              )
                              : (invoiceDetail?.type === "request" ||
                                invoiceDetail?.type === "maintenance" ||
                                invoiceDetail?.type === "source_rent") &&
                              displayPrice(
                                returnFirstDegreeObjValue(invoiceDetail, "amount")
                              )}
                          </label>
                        </FormGroup>
                      </Col>
                    </Row>
                    {invoiceDetail?.items?.length > 0 &&
                      invoiceDetail?.items[0]?.type === "rent" &&
                      lateFee !== 0 && (
                        <Row>
                          <Col lg="12" className="pad-left-invoice">
                            <FormGroup className="flex-invoice ">
                              <label
                                className="label-invoice-name mb-0 "
                                htmlFor="input-username"
                              >
                                Late Fee
                              </label>
                              <label
                                className="label-invoice-name mb-0 "
                                htmlFor="input-username"
                              >
                                {displayPrice(lateFee)}
                              </label>
                            </FormGroup>
                          </Col>
                        </Row>
                      )}
                  </div>
                  <div className="modal-body profile-body invoice-body">
                    {/* {invoiceDetail?.items?.length > 0 &&
                  invoiceDetail?.items[0]?.type === "rent" && (
                    <Row>
                      <Col lg="12" className="pad-left-invoice">
                        <FormGroup className="flex-invoice flex-secondary-invoice ">
                          <label
                            className="label-invoice-name mb-0 label-invoice"
                            htmlFor="input-username"
                          >
                            Subtotal
                          </label>
                          <label
                            className="label-invoice-name mb-0 label-invoice"
                            htmlFor="input-username"
                          >
                            {displayPrice(
                              returnFirstDegreeObjValue(invoiceDetail, "amount")
                            )}
                          </label>
                        </FormGroup>
                      </Col>
                    </Row>
                  )} */}
                    <Row>
                      <Col lg="12" className="pad-left-invoice">
                        <FormGroup className="flex-invoice nav-last-panel label-invoice">
                          <label
                            className="label-invoice-name mb-0 "
                            htmlFor="input-username"
                          >
                            Total
                          </label>
                          <label
                            className="label-invoice-name primary-color mb-0 "
                            htmlFor="input-username"
                          >
                            {displayPrice(
                              returnFirstDegreeObjValue(invoiceDetail, "amount")
                            )}
                          </label>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  {/* </div> */}
                </Modal>
              </Row>
            </>
          )}
        </CustomListingContainer>
        <Card className={invoiceModal ? "invoice-sidebar" : "toggle-sidebar"}>
          <InvoiceModal
            status={invoiceModalStatus}
            data={invoiceData}
            close={this.closeModal}
            fromDate={from}
            toDate={to}
            isOpen={invoiceModal}
          />
        </Card>

        <Card className={timeLineModal ? "exportSideBar" : "toggle-sidebar"}>
          <TimeLineSidebar
            close={this.closeTimeLineModal}
            id={invoiceId}
            type="invoice"
            isOpen={timeLineModal}
          />
        </Card>
        <Card
          className={markPaymentModal ? "exportSideBar" : "toggle-sidebar"}
        >
          <MarkPaymentSideBar
            close={this.closeMarkPaymentSideDraggger}
            isOpen={markPaymentModal}
            toast={this.displayErrorMessage}
            id={invoiceId}
          />
        </Card>
        <Modal
          isOpen={removeFeeModal}
          toggle={() => this.closeModal()}
          centered
        >
          <CardBody className="pad25" id={this.state.invoiceId}>
            <div className="mb-30 flex">
              <div className=" cl262626 fw-600 f16">Remove ACH Fee</div>
              <div
                className="clpaid closeModal fw-500 point"
                onClick={() => this.closeModal(false)}
              >
                x
              </div>
            </div>
            <label
              className="f14 fw-400 cl555555 mb-10"
              htmlFor="schedule_on"
            >
              Are you sure you want to remove ACH fee of $
              {this.state.invoiceAmt} for this invoice ?
            </label>
            <div className="flexEnd">
              <Button
                color="primary"
                size="lg"
                className="button-wrapping search-wrapper  button-secondary ml-4 cancelbtn"
                onClick={() => this.closeModal(false)}
              >
                No
              </Button>
              <Button
                color="primary"
                size="lg"
                className="button-wrapping search-wrapper checkoutSubmit"
                onClick={() => this.callAchFee()}
              >
                {isLoad ? (
                  <div className="spin-loading-save w100 h100">
                    <Spinner type="border" color="#012A4D" />
                  </div>
                ) : (
                  "Yes"
                )}
              </Button>
            </div>
          </CardBody>
        </Modal>
      </>
    );
  }
}

export default Invoices;
