import React, { Component } from "react";
import SocialLogin from "react-social-login";
class SocialButton extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { children, triggerLogin, ...props } = this.props;
    return (
      <button onClick={triggerLogin} {...props}>
        Button
      </button>
    );
  }
}
export default SocialLogin(SocialButton);
