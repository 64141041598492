import moment from "moment";
import { propTypes } from "pdf-viewer-reactjs";
import cookie from "react-cookies";
import ApiService from "./ApiService";
import queryString from "query-string";
import * as QS from "query-string";
import { ConstantLineLabel } from "devextreme-react/polar-chart";
export const isInvalidEmail = (email) => {
  let eRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  // let eRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!eRegex.test(email)) return true;
  else return false;
};

export const isInvalidName = (value) => {
  return !/^[a-zA-Z 0-9]+$/.test(value);
};
export const isInvalidValue = (value) => {
  let letterregex = /^[A-Za-z_ ]+$/;
  return !letterregex.test(value);
};

export function returnDownloadGet() {
  return {
    Method: "GET",
    headers: { Authorization: "Bearer " + getAuthToken() },
  };
}

export function removeKeys(state) {
  let query = state.query;
  if (Object.keys(query).length > 0) {
    for (let key in query) {
      if (key === "page" || key === "limit") {
        delete query[key];
      }
    }
  }
  return Object.keys(query).length > 0 ? buildQueryString(query) : "";
}


export function returnPositiveNumber(number) {
  return Math.abs(number);
}

export function redirectToBookings(self, id) {
  self.props.history.push(`/admin/bookings/detail/${id}`);
}

export function returnCustomerName(nameObj) {
  return returnCustomerFirsLastName(nameObj, "user", "name", "first", "last")
}

export function returnCustomerFirsLastName(masterobj, obj, key, position1, position2) {
  let first = returnThirdDegreeObjValue(masterobj, obj, key, position1);
  let last = returnThirdDegreeObjValue(masterobj, obj, key, position2);
  return (first !== "-" && last !== "-") ? (first + " " + last) : (first !== "-") ? first : last !== "-" ? last : "-";
}

export function returnFirstDegreeObjValue(obj, key) {
  return obj && (obj[key] || obj[key] === 0) ? obj[key] : "-";
}

export default function returnSecondDegreeObjValue(obj, key, postion) {
  return (obj && obj[key] && (obj[key][postion] || obj[key][postion] === 0)) ? obj[key][postion] : "-";
}

export function returnThirdDegreeObjValue(masterobj, obj, key, postion) {
  let result =
    masterobj &&
    masterobj[obj] &&
    masterobj[obj][key] &&
    (masterobj[obj][key][postion] || masterobj[obj][key][postion] === 0);
  return result ? masterobj[obj][key][postion] : "-";
}

export const showMomentFormat = (date) => {
  return moment(date).format("DD-MM-YYYY");
};

export const showMomentMonFormat = (date) => {
  return moment(date).format("DD-MMM-YYYY");
};

export const showUsFormat = (date) => {
  return moment(date).format("MM-DD-YYYY");
};

export const showCalendarFormat = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

export const isLoggedIn = () => {
  return getAuthToken() ? true : false;
};

export const roleType = () => {
  return returnRole() === "host";
};

export const roleManager = () => {
  return returnRole() === "manager";
};

export const roleSubManager = () => {
  return returnRole() === "sub_manager";
};

export const returnRole = () => {
  return cookie.load("role");
};

export function getAuthToken() {
  return getCookie("token");
}

export const saveToken = (response) => {
  setCookie("token", response.data.token);
};

export const saveRole = (response) => {
  setCookie("role", response.data.host.role);
};
export const saveSecureVault = (status) => {
  setCookie("secure_vault", status);
};
export const checkVaultSecure = () => {
  return cookie.load("secure_vault") === "secured";
};
export const saveChangePassword = (response) => {
  let password = response?.data?.host?.is_change_password
    ? "change"
    : "nochange";
  setCookie("ischangepassword", password);
};

export const isChangePassword = () => {
  return cookie.load("ischangepassword") === "change";
};

export const saveRoleAccess = () => {
  let submanagerroute = {};
  let host_details = localStorage.getItem("host_details");
  let host_det = JSON.parse(host_details);
  if (host_det?.host) {
    if (host_det?.host?.role === "sub_manager") {
      if (host_det?.host?.role_access?.access) {
        return (submanagerroute = host_det?.host?.role_access?.access);
      }
      // else {
      //   showError(this, "You are not aign to any role please contact your manager")
      //   return cookie.remove("token", { path: "/" })
      // }
    }
  }
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const returnSortKey = (context, key) => {
  let { keyValue } = context.state;
  return keyValue.includes("_asc") ? key + "_dsc" : keyValue.includes("_dsc") ? "" : key + "_asc"
}

export const isSecureVault = () => {
  let host_details = localStorage.getItem("host_details");
  let host_det = JSON.parse(host_details);
  let isSecureVault = host_det?.host?.vault?.is_enabled ?? false;
  return isSecureVault;
};
export function getCookie(key) {
  return cookie.load(key);
}

export const setCookie = (key, value) => {
  cookie.save(key, value, {
    path: "/",
    maxAge: 1800,
    secure: false,
  });
};

export const replaceToNormalNumber = (number) => {
  return number.replace(/[-+ )(]/g, "");
};

export function returnSimpleFormatedDateTime(dateString) {
  return dateString === ""
    ? ""
    : moment(dateString).format("DD MMM YYYY h:mm a");
}

export const uploadCloudinary = async (file, path) => {
  const signResponse = await fetch(ApiService.signInUrl, {
    method: "POST",
    body: JSON.stringify({ path }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getAuthToken(),
    },
  });
  const signData = await signResponse.json();

  const url =
    ApiService.cloudinary_base_url + signData.data.cloudname + "/auto/upload";
  const formData = new FormData();
  formData.append("file", file);
  formData.append("api_key", signData.data.apikey);
  formData.append("timestamp", signData.data.timestamp);
  formData.append("signature", signData.data.signature);
  formData.append("eager", "c_pad,h_300,w_400|c_crop,h_200,w_260");
  formData.append("folder", signData.data.folder);
  let valley = formData.forEach((value, key) => (value, key));

  let response = await fetch(url, {
    method: "POST",
    body: formData,
  })
    .then((response) => {
      return response.text();
    })
    .then((data) => {
      return (response = JSON.parse(data));
    });
  return response;
};

export const numberRequired = (data) => {
  // only numbers
  return !/^[\+\-]?\d*\.?\d+(?:[Ee][\+\-]?\d+)?$/.test(data);
};
export const getUSFormat = (number) => {
  if (!number) return ""
  const input = number.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
  const areaCode = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);
  let newNumber = "";
  if (input.length > 6) {
    newNumber = `(${areaCode}) ${middle} - ${last}`;
  } else if (input.length > 3) {
    newNumber = `(${areaCode}) ${middle}`;
  } else if (input.length > 0) {
    newNumber = `(${areaCode}`;
  }
  return newNumber;
};
export const getCountryCode = (code) => {
  let NewCode = "";
  const input = code.replace(/\D/g, "").substring(0, 4); // First four digits of input only
  const contrycode = input.substring(0, 4);
  if (input.length > 0) {
    NewCode = `+${contrycode}`;
  }
  return NewCode;
};
export const formatAmountWithCommas = (amount) => {
  return amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export const filterQuery = (query, data) => {
  let filter = query;
  if (filter === query) {
    return `&${query}=${data}`;
  }
};

export const dropDownToggle = (event, open, close) => {
  if (event.target) {
    return event.target.classList.contains("status_select_div")
      ? open()
      : close();
  }
};

export function getIsoString(date) {
  return date !== "" && date !== isNaN ? moment(date).format("YYYY-MM-DD") : "";
}
export function getDateString(date) {
  return date !== "" && date !== isNaN ? moment(date).toISOString() : "";
}
export function getCloudinaryImage(width, height, image, c_type) {
  return (
    ApiService.response_url +
    `/fl_lossy,w_${width},h_${height},${c_type}/${image.version}/${image.public_id}.${image.format}`
  );
}
export function AddClassToBody() {
  let screen = localStorage.getItem("screen");
  let body = document.querySelector("body");
  if (screen === "full") {
    body.classList.add("small_side_bar");
  } else {
    body.classList.remove("small_side_bar");
  }
  return screen;
}

export function getMonthFilter() {
  var thirtyDaysAgo = new Date(new Date().setDate(new Date().getDate() - 30));
  let newDate = thirtyDaysAgo.toISOString();
  return newDate;
}

export function getFinalMonthFilter() {
  return new Date().toISOString();
}

export function returnDateTxt(utcDate) {
  var now = new Date(utcDate);
  var utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
  return utcDate === "" ? new Date() : new Date(utc);
}

export function showUtcDate(utcDate) {
  var now = new Date(utcDate);
  var utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
  if (utcDate !== "-" || utcDate !== null) {
    return utcDate === "" ? new Date() : moment(new Date(utc)).format("MMM DD,YYYY");
  } else {
    return "-";
  }
}

export function showLocaldate(utcDate) {
  if (utcDate === "-" || utcDate === null || utcDate === undefined || utcDate === "") {
    return "-";
  } else {
    return moment.utc(utcDate).format("MMM DD, YYYY");
  }
}

export function getStartOfDay(date) {
  return date !== "" && date !== isNaN ? moment.utc(moment(date).startOf("day")).local().toISOString() : "";
}
export function getDateFormat(dateString) {
  return moment(dateString).format("DD MMM YYYY");
}
export function getDateRequestFormat(dateString) {
  return dateString !== "" ? moment(dateString).format("MMM DD,YYYY") : null;
}

export function displayPrice(price) {
  return (price && price !== "" && price != "-") ? ("$" + price.toLocaleString()) : "-";
}

export function displayPercentage(value) {
  return value ? value + "%" : "-";
}

export function getMonthFromDate(due) {
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const d = new Date(due);
  let monthhRender = month[d.getMonth()];
  return monthhRender;
}

export function removeCookie(key) {
  cookie.remove(key);
}

export function redirectLoginPage() {
  removeCookie("token");
  removeCookie("role");
  window.location.reload();
}

export function handleGoBack(props) {
  setTimeout(() => props.history.goBack(), 1200);
}

export function getPDFCloudinary(docFields) {
  return (
    ApiService.response_url +
    `/${docFields.version}/${docFields.public_id}.${docFields.type}`
  );
}

export const showAPIMsg = (self, response) => {
  showToast(self, response.success ? "success" : "danger", response.message);
};

export const showSuccess = (self, message) => {
  showToast(self, "success", message);
};

export const showError = (self, message) => {
  showToast(self, "danger", message);
};

// Toast Handler
export const showToast = (self, type, message) => {
  let data = {
    toast: true,
    response: message,
    toastType: type,
  };
  self.setState(data, () => handleToastMessage(self, type));
};

export const handleToastMessage = (self, type) => {
  let { toast } = self.state;
  if (toast) {
    setTimeout(
      () => self.setState({ toast: false, response: "", toastType: "" }),
      type === "success" ? 3000 : 3000
    );
  }
};

export const calculatePercentage = (thisMonth, lastMonth, setValue, self) => {
  // let newDay = new Date().getDate();
  // let currentMonthValue = (thisMonth / newDay) * 30;
  let totalPercentageValue = ((thisMonth - lastMonth) / lastMonth) * 100;
  self.setState({
    [setValue]: totalPercentageValue.toFixed(0),
  });
};

export const invoicePay = (amount, commission) => {
  let amt = amount + (amount * commission) / 100;
  return amt ? amt.toFixed(2) : "-";
};

export const checkManageBy = (property) => {
  let check = roleType() && property?.manager?.id ? true : false;
  return check;
};

export const userTypeQuery = () => {
  let type = localStorage.getItem("user");
  return `p_type=${type.toLowerCase()}`;
  // return "p_type=rendal";
};
export const propType = () => {
  let type = localStorage.getItem("user");
  return type.toLowerCase();
};
export const userPersonal = () => {
  let type = localStorage.getItem("user");
  return type.toLocaleLowerCase() === "personal";
};
export const showDatenTime = (date) => {
  return date ? moment(date).format("MMM DD,YYYY , h:mm A") : "-";
};

export const parseQueryStrings = (qs) => {
  return queryString.parse(qs);
};

export const returnSearchObject = (search) => {
  return parseQueryStrings(search);
};

export const pushToFilteredPage = (props, query) => {
  if (Object.keys(query).length > 0) {
    for (let key in query) {
      if (query[key] === "") {
        delete query[key];
      }
    }
    props.history.push({
      pathname: props.location.pathname,
      search: QS.stringify(query),
    });
  }
};

export const buildQueryString = (params, type) => {
  let newParams = null;
  if (params) {
    newParams = [];
    for (let key in params) {
      if (params[key] === "") {
        delete params[key];
      } else {
        newParams.push(`${key}=${params[key]}`);
      }
    }
    if (type) {
      return (newParams = "&" + newParams.join("&"));
    }
    return (newParams = "?" + newParams.join("&"));
  }
};

export const selectField = (value) => {
  switch (value) {
    case value["property_id"] !== "":
      return "property_id";
    case value["propname"] !== "":
      return "propname";

    default:
      return "";
  }
};

export const getDefaultProperty = () => {
  let default_property = localStorage.getItem("default_property");
  return default_property ? default_property : "";
};

export const searchByName = (list, searchKey) => {
  const searchTerm = searchKey.toLowerCase();

  return list.filter((prop) => prop.name.toLowerCase().match(searchTerm));
};

export const checkNoticeCancelDate = (checkOutDate) => {
  return moment().diff(moment(checkOutDate)) < 0;
};

export const maxLengthCheck = (object) => {
  if (object.target.value.length > object.target.maxLength) {
    object.target.value = object.target.value.slice(0, object.target.maxLength);
  }
};

export const allowOnlyText = (obj) => {
  return obj.replace(/[^a-z. ]/gi, "");
};

export const displayAmount = (price) => {
  return price >= 0 && price != "-" ? "$" + price.toLocaleString() : "-";
};

export const returnPaidDeposit = (deposit) => {
  let paid_amt = deposit?.amount - deposit?.pending_amount;
  return paid_amt >= 0 ? paid_amt : "-";
};
export const isTodayorGreater = (date) => {
  if (date) {
    let result = moment(date).isSameOrAfter(showCalendarFormat(new Date()));
    return result;
  } else {
    return false;
  }
};
export const isFirstLowercaseOnly = (string) => {
  const nonLowercaseLetters = /[^a-z]/g;
  return nonLowercaseLetters.test(string.charAt(0));
};

export const resetCookitTime = () => {
  setCookie("token", getCookie("token"));
  setCookie("role", getCookie("role"));
  setCookie("host_id", getCookie("host_id"));
  setCookie("secure_vault", getCookie("secure_vault"));
  setCookie("ischangepassword", getCookie("ischangepassword"));
};

export const isPayTenant = (reqObj) => {
  return reqObj.pay_by === "tenant";
};

export const Logout = () => {
  cookie.remove("token", { path: "/" });
  cookie.remove("role");
  cookie.remove("host_id");
  cookie.remove("secure_vault");
  cookie.remove("ischangepassword");
  localStorage.removeItem("host_details");
  localStorage.removeItem("user");
  window.location.reload();
};

export const getValueArrayInArrayOfObject = (object, path) =>
  object.map((data) => {
    return data[path];
  });

export const makeCapitalize = (string) => {
  return (string.includes('_') || string.includes(' ')) ?
    string.split(string.includes('_') ? '_' : ' ').map((str) => str[0].toUpperCase()
      + str.slice(1)).join(' ') : string[0].toUpperCase() + string.slice(1)
};

export const refundSecurityValidation = (booking) => {
  return (booking.status === "checked_out" || booking.status === "in_notice") &&
    booking?.deposit && booking?.deposit.status !== "paid_out" &&
    returnPaidDeposit(booking?.deposit) > 0
}

export const bookingStatusColor = (status) => {
  switch (status) {
    case "booked":
      return "#008000";
    case "in_notice":
      return "#ffc043";
    case "checked_out":
      return "#f83245";
    case "pre_booking":
      return "#8B4000";
    default:
      return "";
  }
}

export const bookingStatus = (status) => {
  switch (status) {
    case "booked":
      return "Active";
    case "in_notice":
      return "In Notice";
    case "checked_out":
      return "Checked Out";
    case "pre_booking":
      return "Pre Booked";
    default:
      return "";
  }
}

export const noticeStatus = (status) => {
  switch (status) {
    case "pending":
      return "Pending";
    case "accepted":
      return "Accepted";
    case "checked_out":
      return "Checked Out";
    case "cancelled":
      return "Cancelled";
    case "rejected":
      return "Rejected";
    default:
      return "";
  }
}