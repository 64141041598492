import VendorsInactive from "./assets/img/brand/VendorsInactiveNew.svg";
import VendorsActive from "./assets/img/brand/VendorsActiveNew.svg";
import PropertyActive from "./assets/img/brand/propertiesActiveNew.svg";
import PropertyInactive from "./assets/img/brand/propertiesInactiveNew.svg";
// import PropertyList from "views/PersonalProperty/PropertyList";
import PropertyList from "views/PersonalProperty/PropertyList";
import Maintenance from "views/PersonalProperty/PersonalMaintainence";
import Vendors from "./views/venders/venders";
import Payments from "views/PersonalProperty/Payments/personalPayment";
import MaintainceInactive from "./assets/img/brand/maintainenceInctiveNew.svg";
import MaintainceActive from "./assets/img/brand/maintainenceActiveNew.svg";
import TransactionsInactive from "./assets/img/brand/TransactionsInactiveNew.svg";
import TransactionsActive from "./assets/img/brand/TransactionsActiveNew.svg";

var PersonalRoutes = [
  {
    path: "/personal_properties",
    name: "Properties",
    merge: "ManagerProp",
    icon: PropertyActive,
    image: PropertyInactive,
    component: PropertyList,
    layout: "/admin",
    view: true,
  },
  {
    path: "/personalPayment",
    name: "Payments",
    merge: "personalPayment",
    icon: TransactionsActive,
    image: TransactionsInactive,
    component: Payments,
    layout: "/admin",
    view: true,
  },

  {
    path: "/personalMaintainence",
    name: "Maintenance",
    merge: "personalMaintainence",
    icon: MaintainceActive,
    image: MaintainceInactive,
    component: Maintenance,
    layout: "/admin",
    view: true,
  },

  {
    path: "/vendors",
    name: "Vendors",
    merge: "",
    icon: VendorsActive,
    image: VendorsInactive,
    component: Vendors,
    layout: "/admin",
    view: true,
  },
];
export default PersonalRoutes;
