import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Button,
  FormGroup,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  Spinner,
} from "reactstrap";
import ToastList from "components/Toast/ToastList";
import VaultVerify from "../../assets/img/brand/vaultverify.svg";
import { showError } from "constants/utils";
import ApiService from "constants/ApiService";
import { showSuccess } from "constants/utils";
class AddSecureVaultModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toast: "",
      response: "",
      toastType: "",
      pType: "password",
      nType: "password",
      pin: "",
      userPassword: "",
      btnLoading: false,
    };
  }
  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handlePinInputChange = (e) => {
    this.setState({ pin: e.target.value.replace(/\D/g, "") });
  };
  toggleEyeIcon = (key) => {
    if (key === "pin") {
      this.setState({
        pType: this.state.pType === "password" ? "text" : "password",
      });
    } else if (key === "userPass") {
      this.setState({
        nType: this.state.nType === "password" ? "text" : "password",
      });
    }
  };
  closeModal = (msg) => {
    this.props.close(msg);
  };
  updateEnableVault = async () => {
    const { pin, userPassword } = this.state;
    if (!pin) {
      showError(this, "Please enter 4 digit pin");
    } else if (!userPassword) {
      showError(this, "Please enter password");
    } else {
      this.setState({ btnLoading: true });
      let data = {
        passcode: pin,
        password: userPassword,
      };
      let response = await ApiService.postAPI(
        "/host/vault/enable_security",
        data
      );

      if (response.success) {
        showSuccess(this, response.message);
        setTimeout(() => this.closeModal("secured"), 1000);
      } else {
        this.setState({ btnLoading: false });
        showError(this, response.message);
      }
    }
  };
  handleKeypress = (e) => {
    const { btnLoading } = this.state;
    if (e.key === "Enter") {
      if (!btnLoading) {
        this.updateEnableVault();
      }
    }
  };
  render() {
    const {
      toast,
      response,
      toastType,
      pType,
      nType,
      pin,
      userPassword,
      btnLoading,
    } = this.state;
    return (
      <>
        <div className=" minh-600">
          {toast && <ToastList message={response} type={toastType} />}
          <Card>
            <div className="flex-center pad30 bgFAFAFA">
              <img
                alt="vault verification"
                src={VaultVerify}
                width="198px"
                height="176px"
              />
            </div>

            <CardBody className="flex-column align_center ml-25 mr-25">
              <CardTitle className="fw-600 f17 cl333333 text-center">
                Setup Verification Code
              </CardTitle>

              <CardText className="fw-400 f13 cl575757 text-center">
                Please set four digits PIN <br />
                to enable the security
              </CardText>
              <FormGroup className="mb-3 mt-4-5 w100">
                <div className="d_flex">
                  <label
                    className="form-control-label label-in  fw-500 margin0"
                    htmlFor="vault-pin"
                  >
                    PIN *
                  </label>
                </div>
                <InputGroup>
                  <Input
                    type={pType}
                    name="pin"
                    value={pin}
                    onChange={this.handlePinInputChange}
                    onKeyDown={this.handleKeypress}
                    maxLength={4}
                    id="vault-pin"
                    className="border-none vault-secure-input  text-center fw-600 f16 let-space30"
                  />
                  <InputGroupAddon
                    addonType="append"
                    className="input-addoneye"
                  >
                    <InputGroupText
                      onClick={() => this.toggleEyeIcon("pin")}
                      className="border-none cl374957"
                    >
                      <i
                        className={
                          pType === "password" ? "fa fa-eye-slash" : "fa fa-eye"
                        }
                      ></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <div className="pin_line"></div>
                </InputGroup>
                <div className="d_flex mt-30">
                  <label
                    className="form-control-label label-in  fw-500 margin0"
                    htmlFor="vault-pin"
                  >
                    Password *
                  </label>
                </div>
                <InputGroup>
                  <Input
                    type={nType}
                    name="userPassword"
                    value={userPassword}
                    onChange={this.handleInputChange}
                    onKeyDown={this.handleKeypress}
                    className="border-none vault-secure-input  text-center f16 "
                    placeholder=""
                  />
                  <InputGroupAddon
                    addonType="append"
                    className="input-addoneye"
                  >
                    <InputGroupText
                      onClick={() => this.toggleEyeIcon("userPass")}
                      className="border-none cl374957"
                    >
                      <i
                        className={
                          nType === "password" ? "fa fa-eye-slash" : "fa fa-eye"
                        }
                      ></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <div className="pin_line"></div>
                </InputGroup>
              </FormGroup>
              <Button
                color="primary"
                size="sm"
                className="button-wrapping reset-btn w100 mt-30 point"
                onClick={() => this.updateEnableVault()}
              >
                {btnLoading ? (
                  <div className="button_spinner">
                    <Spinner type="border" color="#012A4D" />
                  </div>
                ) : (
                  <span className="fw-600 f13">Enable Now</span>
                )}
              </Button>
              <Button
                color="primary"
                size="sm"
                className="button-wrapping button-secondary mt-10 point"
                onClick={() => this.closeModal()}
              >
                Cancel
              </Button>
            </CardBody>
          </Card>
        </div>
      </>
    );
  }
}
export default AddSecureVaultModal;
