import React, { Component } from "react";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Card,
  Table,
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  InputGroup,
  Dropdown,
  Input,
  Spinner,
  CustomInput,
  Media,
} from "reactstrap";
// core components
import ApiService from "constants/ApiService";
import { returnFirstDegreeObjValue } from "constants/utils";
import { filterQuery } from "constants/utils";
import { isInvalidEmail } from "constants/utils";
import { returnThirdDegreeObjValue } from "constants/utils";
import { displayPrice } from "constants/utils";
import { roleType } from "constants/utils";
import SendInvite from "components/Modal/SendInvite";
import ToastList from "components/Toast/ToastList";
import showMore from "../../assets/img/brand/showMore.svg";
import {
  buildQueryString,
  dropDownToggle,
  getDefaultProperty,
  pushToFilteredPage,
  returnSearchObject,
  returnSortKey,
  showError,
  showLocaldate,
  showSuccess,
  showUtcDate,
  userTypeQuery,
} from "../../constants/utils";
import moment from "moment";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { AsscendingDecending } from "components/FilterFields/Filterfields";
let manager_id;
class PropertyList extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    manager_id = this.props.location?.manager_id;
    this.state = {
      propertyList: [],
      total: 0,
      page: 1,
      limit: 10,
      filterToggle: false,
      modalOpen: false,
      toggleOptionView: false,
      inputSearch: "",
      filterOption: "name",
      statusSearch: "",
      check: false,
      email: "",
      actual_start: "",
      des: "",
      property_id: "",
      invite_id: "",
      link: false,
      url: "",
      toast: false,
      response: "",
      toastType: "",
      isLoading: true,
      inviteStatus: "",
      query: {},
      timeoutId: null,
      input_value: false,
      keyValue: "",
      sortingList: "",
    };
  }

  reloadPage = () => {
    this.props.history.listen((location, search) => {
      if (location.search === "") {
        this.setState({ query: {} }, () => {
          let query = this.props.location.search;
          query = returnSearchObject(query);
          if (Object.keys(query).length === 0) {
            this.handleReset();
          }
        });
      } else {
        this.state.input_value = true
      }
    });
  };

  componentDidMount() {
    this.reloadPage();
    this.setQueryStatus();

    // this.getPropertyList();
  }

  // componentDidUpdate(prevProps) {
  //   let search = this.props.location.search;
  //   if (prevProps.location.search !== search) {
  //     this.setQueryStatus();
  //   }
  // }

  setQueryStatus = () => {
    let default_property = getDefaultProperty();
    let queryStatus = this.props.location.search;
    if (queryStatus) {
      let queryObj = returnSearchObject(queryStatus);
      this.setState(
        {
          inputSearch: queryObj.name ?? "",
          // filterOption: queryObj.name ? "name" : "",
          page: parseInt(queryObj.page) ?? "",
          query: queryObj,
        },
        () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getPropertyList();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...this.state.query,
            page: 1,
            limit: 10
          },
          inputSearch: default_property ? default_property : "",
          filterOption: default_property ? "property_id" : "name",
        },
        () => {
          pushToFilteredPage(this.props, this.state.query);
          this.getPropertyList();
        }
      );
    }
  };

  getPropertyList = async () => {
    let { page, filterOption, statusSearch, inputSearch } = this.state;
    // let queryParams = manager_id ? `&manager_id=${manager_id}` : "";
    // if (filterOption !== "" && inputSearch !== "")
    //   queryParams += filterQuery(filterOption, inputSearch);
    // if (statusSearch !== "") {
    //   queryParams += filterQuery("is_occupied", statusSearch);
    // }
    // let response = await ApiService.getAPI(
    //   `host/properties/personal?page=${page}&limit=10${queryParams}`
    // );
    let response = await ApiService.getAPI(
      `host/properties/personal${buildQueryString(this.state.query)}&${userTypeQuery()}`
    );
    if (response.success) {
      let { properties, total } = response.data;
      this.setState({
        propertyList: properties,
        total: total,
        isLoading: false,
      });
    } else {
      this.setState({ isLoading: false });
      showError(this, response.message);
    }
  };
  // Toast Handler

  pageChange = (page) => {
    var self = this;
    self.setState({ page: page }, () => {
      self.getPropertyList();
    });
  };
  redirectAddPage = () => {
    this.props.history.push("/admin/personal_properties/add");
  };
  redirectRooms = (id) => {
    this.props.history.push(`rooms/${id}`);
  };
  redirectEditProperty = (property_id) => {
    this.props.history.push(`/admin/personal_properties/edit/${property_id}`);
  };
  redirectPurchaseHistory = (property_id) => {
    this.props.history.push(
      `/admin/properties/purchase_history/${property_id}`
    );
  };
  redirectMaintenanceHistory = (property_id) => {
    this.props.history.push(`/admin/properties/maintenance/${property_id}`);
  };
  onChangeHandler = (e) => {
    e.target.value === "" && this.handleReset();
    this.setState({ [e.target.name]: e.target.value, input_value: true });
    clearTimeout(this.state.timeoutId);
    const timeoutId = setTimeout(() => {
      this.state.filterOption && this.handleSearch();
    }, 1000);
    this.setState({ timeoutId });
  };
  onChangeSelectHandler = (status) => {
    this.setState({ filterOption: status, input_value: true }, () => {
      this.handleFilterStatus();
    });
  }
  handleSearch = () => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  updateEnable = async (type, checkValue, id) => {
    var self = this;
    var data = {};
    checkValue ? (data[type] = false) : (data[type] = true);
    let response = await ApiService.patchAPI(
      `host/properties/update/${id}`,
      data
    );
    if (response.success) {
      showSuccess(this, response.message);
      self.getPropertyList();
    } else {
      showError(this, response.message);
    }
  };
  handleIncrementPage = () => {
    if (this.state.propertyList.length > 0) {
      this.setState({ page: this.state.page + 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };
  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };
  toggleModal = async (id, invite, occupy, type) => {
    if (invite && !occupy) {
      let response = await ApiService.getAPI(`host/invite/${invite}`);
      if (response.success) {
        let data = response.data.invite;

        this.setState(
          {
            email: data.email,
            inviteStatus: data.status,
            actual_start: moment(data.actual_start).toDate(),
            check: data.is_paid,
            des: data.description,
          },
          () => { }
        );
      }
    }
    if (type === "entire_home") {
      this.setState({
        modalOpen: !this.state.modalOpen,
        property_id: id,
        inviteStatus: "",
        invite_id: invite,
      });
    }
  };
  handleOnCheckChange = () => {
    this.setState({ check: !this.state.check });
  };
  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  toggleCloseModal = () => {
    this.setState({
      modalOpen: false,
      link: false,
      url: "",
      email: "",
      actual_start: "",
      des: "",
      check: false,
    });
    this.getPropertyList();
  };
  handleOnNoticeChange = (e) => {
    this.updateStateValues("actual_start", e);
  };
  updateStateValues = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      function () {
        if (value === null) {
          this.setState({
            [key]: "",
          });
        }
      }
    );
  };
  handleInvite = async () => {
    let { email, actual_start, des, property_id, check, invite_id } =
      this.state;
    if (email === "") {
      showError(this, "Please enter email");
    } else if (isInvalidEmail(email)) {
      showError(this, "Please enter valid email");
    } else if (actual_start === "") {
      showError(this, "Please select checkin date");
    } else {
      let data = {
        is_paid: check,
        actual_start: this.state.actual_start.toISOString(),
        email,
        description: des,
        property_id,
      };
      if (invite_id) {
        let response = await ApiService.patchAPI(
          `host/invite/${invite_id}`,
          data
        );
        if (response.success) {
          let { link } = response.data;

          showSuccess(this, response.message);
          this.setState({ link: true, url: link });
          this.getPropertyList();
        } else {
          showError(this, response.message);
        }
      } else {
        let response = await ApiService.postAPI(`host/invite`, data);
        if (response.success) {
          let { link } = response.data;
          showSuccess(this, response.message);
          this.setState({ link: true, url: link });
          this.getPropertyList();
        } else {
          showError(this, response.message);
        }
      }
    }
  };
  copyLink = (url) => {
    navigator.clipboard.writeText(url);
    showSuccess(this, "Invite Link Copied");
  };

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    if (this.state.inputSearch !== "" && this.state.filterOption !== "") {
      for (let key in staticQuery) {
        if (key === "name") {
          delete staticQuery[key];
        }
      }
      staticQuery[this.state.filterOption] = this.state.inputSearch;
    }
    staticQuery.page = this.state.page ?? "";
    staticQuery.sort = this.state.sortingList ?? "";

    this.setState({ query: staticQuery }, () => {
      pushToFilteredPage(this.props, { ...this.state.query });
      this.getPropertyList();
    });
  };

  handleReset = () => {
    let self = this;
    self.setState(
      {
        from: "",
        to: "",
        statusSearch: "",
        filterOption: "name",
        inputSearch: "",
        page: 1,
        query: {
          page: 1,
          limit: 10,
        },
        input_value: false,
        sortingList: "",
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getPropertyList();
      }
    );
  };
  setStatus = (status) => {
    this.setState({ statusSearch: status, page: 1 });
  };
  toggleDropdown = (value) => {
    this.setState({ toggleOptionView: value });
  };
  openDropdown = () => {
    this.setState({ toggleOptionView: true });
  };
  closeDropdown = () => {
    this.setState({ toggleOptionView: false });
  };
  action = (key) => {
    let skey = returnSortKey(this, key);
    this.setState({ keyValue: skey, sortingList: skey, page: 1, input_value: true }, () => { this.handleFilterStatus(); })
  }
  render() {
    let {
      propertyList,
      page,
      inputSearch,
      filterOption,
      statusSearch,
      modalOpen,
      isLoading,
      check,
      email,
      des,
      link,
      url,
      toast,
      response,
      inviteStatus,
      invite_id,
      toggleOptionView,
      toastType,
      input_value,
      sortingList
    } = this.state;
    let self = this;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#012A4D" />
      </div>
    ) : (
      <>
        <div onClick={(event) =>
          dropDownToggle(event, this.openDropdown, this.closeDropdown)
        }>
          {toast && <ToastList message={response} type={toastType} />}
          <CustomListingContainer>
            <div className="align-items-center">
              {/* <Col xs="8" className="manage-con">
                <div>
                  <Breadcrumb>Properties</Breadcrumb>
                </div>
              </Col> */}
              <ListingHeaderLayout label={"Properties"} styleNo={true}>
                <div className="add-filter-wrap">
                  {roleType() && (
                    <Button
                      color="primary"
                      onClick={() => this.redirectAddPage()}
                      size="sm"
                      className="button-wrapping"
                    >
                      Add
                    </Button>
                  )}
                </div>
              </ListingHeaderLayout>
            </div>
            <div style={{ margin: "20px" }}>
              <InputGroup className="flex-content align-left">
                <div className="filters_container">
                  <div className="left_filter_container">
                    <div className="select_with_input status_select_input ml-10">
                      <UncontrolledDropdown className="status_select_div ">
                        <DropdownToggle className="pr-0 drop_down_container">
                          <Media className="capitalize">
                            {filterOption === "name" && "Name"}
                            <i className="fas fa-angle-down" style={{ marginLeft: "20px" }}></i>
                          </Media>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-arrow" bottom style={{ minWidth: "130px" }}>
                          {" "}
                          <DropdownItem
                            className="dropdown_item"
                            onClick={() => this.onChangeSelectHandler("name")}
                          >
                            Name
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      <Input
                        aria-label="Text input with dropdown button"
                        value={inputSearch}
                        name="inputSearch"
                        placeholder="search by"
                        onChange={this.onChangeHandler}
                      />
                    </div>
                    {/* <div className="status_select">
                  <UncontrolledDropdown className="status_select_div">
                    <DropdownToggle className="pr-0 drop_down_container">
                      <Media className="capitalize">
                        {statusSearch === "true"
                          ? "Occupied"
                          : statusSearch === "false"
                          ? "Available"
                          : "All"}{" "}
                        <i className="fas fa-angle-down"></i>
                      </Media>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" bottom>
                      {" "}
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setStatus("")}
                      >
                        All
                      </DropdownItem>{" "}
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setStatus("true")}
                      >
                        Occupied
                      </DropdownItem>
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setStatus("false")}
                      >
                        Available
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div> */}
                  </div>
                  {(input_value && (inputSearch || sortingList)) &&
                    <div className="right_filter_container">
                      <Button
                        color="primary"
                        onClick={this.handleReset}
                        size="sm"
                        className="button-wrapping mr-4 button-secondary reset-btn"
                        style={{ fontSize: "15px" }}
                      >
                        Clear filter
                      </Button>
                    </div>}
                </div>
              </InputGroup>
            </div>
            <Row>
              <div className="col new-table">
                <Card className="shadow card_with_paginator">
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th
                          scope="col"
                          className={propertyList.length > 0 ? "pl_40 justLeft point padLeft30" : "justLeft items-center point "}
                          onClick={() => this.action("name")}
                        >
                          <AsscendingDecending keyValue={this.state.keyValue} value="name" name="Street" />
                        </th>
                        <th scope="col">City</th>
                        <th scope="col">Created On</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    {propertyList.length !== 0 ? (
                      <tbody>
                        {propertyList.map((property, i) => (
                          <tr key={i}>
                            <td className="text-capitalize tdColumn tdColumn_50">
                              {returnFirstDegreeObjValue(property, "name")}
                            </td>
                            <td>
                              {" "}
                              {returnThirdDegreeObjValue(
                                property,
                                "address",
                                "city",
                                "name"
                              )}
                            </td>
                            <td>
                              {" "}
                              {showLocaldate(
                                returnFirstDegreeObjValue(property, "createdAt")
                              )}
                            </td>

                            <td>
                              {" "}
                              <UncontrolledDropdown nav className="position_u">
                                <DropdownToggle className="pr-0 nav-notice" nav>
                                  <Media className="align-items-center">
                                    <img src={showMore} />
                                  </Media>
                                </DropdownToggle>
                                <DropdownMenu
                                  className="dropdown-menu-arrow min-width-drop"
                                  right
                                >
                                  {roleType() && (
                                    <>
                                      <DropdownItem
                                        onClick={() =>
                                          this.redirectEditProperty(
                                            property.property_id
                                          )
                                        }
                                      >
                                        <span>Edit</span>
                                      </DropdownItem>
                                      <DropdownItem divider />
                                    </>
                                  )}

                                  <DropdownItem
                                    onClick={() =>
                                      this.redirectPurchaseHistory(
                                        property.property_id
                                      )
                                    }
                                  >
                                    <span>Purchase History</span>
                                  </DropdownItem>
                                  <DropdownItem divider />
                                  <DropdownItem
                                    onClick={() =>
                                      this.redirectMaintenanceHistory(
                                        property.property_id
                                      )
                                    }
                                  >
                                    <span>Maintenance Item</span>
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                              {/* <i
                                className="fa fa-edit mr-1"
                                style={{
                                  fontSize: "16px",
                                  color: "#ADADAD",
                                  cursor: "pointer",
                                }}
                              /> */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td className="no-data" style={{ width: "135px" }}>No Properties Available</td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    )}
                  </Table>
                </Card>
                <SendInvite
                  toggleModal={() => this.toggleModal()}
                  modalState={modalOpen}
                  closeModal={() => this.toggleCloseModal()}
                  onSubmit={() => this.handleInvite()}
                  onInputChange={this.handleInputChange}
                  onChangeDate={this.handleOnNoticeChange}
                  actual_start={this.state.actual_start}
                  check={check}
                  email={email}
                  status={inviteStatus}
                  onCheckChange={this.handleOnCheckChange}
                  description={des}
                  invite_id={invite_id}
                  link={link}
                  url={url}
                  copyLink={() => this.copyLink(url)}
                />
              </div>
            </Row>
            <Row className="align-items-center jus-space new-margin-page paginator_box">
              <div className="paginate-flex-box ">
                <div
                  className="paginate-box"
                  onClick={() => this.handleDecrementPage()}
                >
                  <p
                    className={page !== 1 ? "page_available" : "page_unavailable"}
                  >
                    Previous
                  </p>
                </div>
                <div
                  className="paginate-box"
                  onClick={() => this.handleIncrementPage()}
                >
                  <p
                    className={
                      propertyList.length < 10
                        ? "page_unavailable"
                        : "page_available"
                    }
                  >
                    Next
                  </p>
                </div>
              </div>
            </Row>
          </CustomListingContainer>
        </div >
      </>
    );
  }
}

export default PropertyList;
