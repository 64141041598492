import React, { Component } from "react";
import {
  Card,
  Table,
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  InputGroup,
  Dropdown,
  Input,
  Spinner,
  BreadcrumbItem,
  Modal,
  CardBody,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
} from "reactstrap";
// core components
import ApiService from "constants/ApiService";
import { returnFirstDegreeObjValue } from "constants/utils";
import { displayPrice, getIsoString } from "constants/utils";
import ToastList from "components/Toast/ToastList";
import { RoleBasedCRUDAccess } from "views/Roles/RolesUtils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { showError } from "constants/utils";
import { showLocaldate } from "constants/utils";
import { returnSearchObject } from "constants/utils";
import { pushToFilteredPage } from "constants/utils";
import { buildQueryString } from "constants/utils";
import { showSuccess } from "constants/utils";
import showMore from "../../../assets/img/brand/showMore.svg";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { CustomDatePicker } from "components/FilterFields/Filterfields";
let manager_id;

class Purchase extends Component {
  constructor(props) {
    super(props);
    manager_id = this.props.match.params?.property_id;
    this.state = {
      purchaseList: [],
      total: 0,
      page: 1,
      limit: 10,
      filterToggle: false,
      modalOpen: false,
      inputSearch: "",
      filterOption: "name",
      statusSearch: "",
      check: false,
      email: "",
      check_in: "",
      des: "",
      property_id: "",
      invite_id: "",
      link: false,
      url: "",
      toast: false,
      response: "",
      toastType: "",
      from: "",
      to: "",
      isLoading: true,
      query: {},
      deletePurchaseHistoryModal: false,
      history_id: "",
      btnLoad: false,
      p_type: "",
      input_value: false
    };
  }
  componentDidMount() {
    let arr = this.props.location.pathname.split("/");

    if (arr[3]) {
      this.setState({ p_type: arr[3] }, () => this.setQueryStatus());
    }
  }
  closeModal = (msg) => {
    this.setState({ history_id: "", deletePurchaseHistoryModal: false });
    if (msg) {
      showSuccess(this, msg);
    }
  };
  deletePurchasehistoryModal = (id) => {
    this.setState({ deletePurchaseHistoryModal: true, history_id: id });
  };

  confirmDelete = async () => {
    const { history_id } = this.state;
    this.setState({ btnLoad: true });
    let response = await ApiService.deleteAPI(
      `host/history/${history_id}${this.purchaseDeleteType(this.state.p_type)}`
    );
    if (response.success) {
      this.setState({ btnLoad: false });
      this.closeModal(response.message);
      this.getPurchaseList();
    } else {
      this.setState({ btnLoad: false });
      showError(this, response.message);
    }
  };

  setQueryStatus = () => {
    let queryStatus = this.props.location.search;
    if (queryStatus) {
      let queryObj = returnSearchObject(queryStatus);

      this.setState(
        {
          from: queryObj.from ? new Date(queryObj.from) : "",
          to: queryObj.to ? new Date(queryObj.to) : "",
          filterOption: queryObj.key ? "key" : "",
          inputSearch: queryObj.key ?? "",
          page: parseInt(queryObj.page) ?? "",
          query: returnSearchObject(queryStatus),
        },
        () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getPurchaseList();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...this.state.query,
            page: 1,
            limit: 10,
          },
        },
        () => {
          pushToFilteredPage(this.props, this.state.query);
          this.getPurchaseList();
        }
      );
    }
  };
  purchaseType = (type) => {
    let ret_type = "purchase";
    if (type === "appliance_history") {
      ret_type = "appliance";
    } else if (type === "furniture_history") {
      ret_type = "furniture";
    }

    return `&p_type=${ret_type}`;
  };
  purchaseDeleteType = (type) => {
    let ret_type = "purchase";
    if (type === "appliance_history") {
      ret_type = "appliance";
    } else if (type === "furniture_history") {
      ret_type = "furniture";
    }
    return `?p_type=${ret_type}`;
  };
  getPurchaseList = async () => {
    let { page, filterOption, inputSearch, from, to } = this.state;
    // let queryParams = manager_id ? `&p_id=${manager_id}` : "";
    // if (filterOption !== "" && inputSearch !== "")
    //   queryParams += filterQuery(filterOption, inputSearch);
    // if (from !== "") queryParams += filterQuery("from", getIsoString(from));
    // if (to !== "") queryParams += filterQuery("to", getIsoString(to));
    if (from > to) {
      showError(this, "Please provide valid From and To date");
    } else {
      let response = await ApiService.getAPI(
        `host/history${buildQueryString(this.state.query)}${this.purchaseType(
          this.state.p_type
        )}&p_id=${manager_id}`
      );
      if (response.success) {
        let { data } = response;
        this.setState({
          purchaseList: data,
          isLoading: false,
        });
      } else {
        this.setState({ isLoading: false });
        showError(this, response.message);
      }
    }
  };

  pageChange = (page) => {
    var self = this;
    self.setState({ page: page }, () => {
      self.getPurchaseList();
    });
  };
  updateStateValues = (key, value) => {
    const updatedValue = value == null ? "" : value;
    this.setState({ [key]: updatedValue, input_value: true }, () => {
      if (this.state.from && this.state.to) {
        this.handleFilterStatus();
      }
    });
  };
  handleOnToChange = (e) => {
    this.updateStateValues("to", e);
  };
  handleOnFromChange = (e) => {
    this.updateStateValues("from", e);
  };
  onChangeHandler = (e) => {
    e.target.value === "" && this.handleReset();
    this.setState({ [e.target.name]: e.target.value, input_value: true });
    clearTimeout(this.state.timeoutId);
    const timeoutId = setTimeout(() => {
      this.state.filterOption && this.handleSearch();
    }, 1000);
    this.setState({ timeoutId });
  };
  onChangeSelectHandler = (status) => {
    this.setState({ filterOption: status, input_value: true }, () => {
      this.handleFilterStatus();
    });
  }
  handleIncrementPage = () => {
    if (this.state.purchaseList.length >= 10) {
      this.setState({ page: this.state.page + 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };
  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    staticQuery.from = getIsoString(this.state.from) ?? "";
    staticQuery.to = getIsoString(this.state.to) ?? "";
    if (this.state.inputSearch !== "" && this.state.filterOption !== "") {
      for (let key in staticQuery) {
        if (key === "name") { delete staticQuery[key] }
      }
      staticQuery[this.state.filterOption] = this.state.inputSearch;
    }

    staticQuery.page = this.state.page ?? "";
    this.setState({ query: staticQuery }, () => {
      pushToFilteredPage(this.props, { ...this.state.query });
      this.getPurchaseList();
    });
  };

  handleSearch = () => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  handleReset = () => {
    let self = this;
    self.setState(
      {
        from: "",
        to: "",
        filterOption: "name",
        inputSearch: "",
        query: { page: 1, limit: 10 },
        input_value: false,
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getPurchaseList();
      }
    );
  };

  redirectPurchaseEdit = (history_id, property_id) => {
    const { p_type } = this.state;
    this.props.history.push(`/admin/${p_type}/edit/${history_id}/${property_id}`);
  };

  redirectAddPage = () => {
    const { p_type } = this.state;
    this.props.history.push(`/admin/properties/${p_type}/add/${manager_id}`);
  };

  checkEditDelete = () => {
    let view = false;
    if (
      RoleBasedCRUDAccess("property", "edit") ||
      RoleBasedCRUDAccess("property", "delete")
    ) {
      view = true;
    }
    return view;
  };

  render() {
    let {
      purchaseList,
      page,
      inputSearch,
      filterOption,
      isLoading,
      deletePurchaseHistoryModal,
      toast,
      response,
      toastType,
      btnLoad,
      p_type,
      from,
      to,
      input_value,
    } = this.state;

    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#012A4D" />
      </div>
    ) : (
      <>
        {toast && <ToastList message={response} type={toastType} />}

        <div style={{ margin: "20px 25px 0px 30px" }}>
          <div className="align-items-center flex">
            <Col xs="8" className="manage-con">
              <Breadcrumb>
                <BreadcrumbItem
                  onClick={() => this.props.history.goBack()}
                  className="bread-cursor"
                >
                  Properties
                </BreadcrumbItem>
                <BreadcrumbItem active style={{ color: "#012a4d" }}>
                  {p_type === "appliance_history"  ? "Appliance History" : p_type === "furniture_history"     ? "Furniture History"  : "Purchase History"}
                </BreadcrumbItem>
              </Breadcrumb>
            </Col>
            <div className="add-filter-wrap">
              {RoleBasedCRUDAccess("property", "create") && (
                <Button
                  color="primary"
                  onClick={() => this.redirectAddPage()}
                  size="sm"
                  className="button-wrapping "
                >
                  Add
                </Button>
              )}
            </div>
          </div>
        </div>
        <CustomListingContainer>
          <div style={{ margin: "20px" }}>
            <InputGroup className="paymentError flex-content align-left">
              <div className="filters_container">
                <div className="left_filter_container">
                  <div className="w30">
                    <CustomDatePicker
                      from={from}
                      to={to}
                      fromChange={this.handleOnFromChange}
                      toChange={this.handleOnToChange}
                    />
                  </div>
                  <div className="select_with_input status_select_input ml-10">
                    <UncontrolledDropdown className="status_select_div ">
                      <DropdownToggle className="pr-0 drop_down_container">
                        <Media className="capitalize">
                          {filterOption === "name" && "Name"}
                          <i className="fas fa-angle-down" style={{ marginLeft: "20px" }}></i>
                        </Media>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" bottom style={{ minWidth: "130px" }}>
                        {" "}
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.onChangeSelectHandler("name")}
                        >
                          Name
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <Input
                      aria-label="Text input with dropdown button"
                      value={inputSearch}
                      name="inputSearch"
                      placeholder="search by"
                      onChange={this.onChangeHandler}
                    />
                  </div>
                </div>
                {input_value && (inputSearch || (from || to)) &&
                  <div className="right_filter_container">
                    <Button
                      color="primary"
                      onClick={this.handleReset}
                      size="sm"
                      className="button-wrapping search-wrapper content-flex mr-4 button-secondary ml-4 reset-btn"
                    >
                      Clear Filter
                    </Button>
                  </div>}
              </div>
            </InputGroup>
          </div>
          <div className="new-table">
            <Card className="shadow card_with_paginator">
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">S.no</th>
                    <th scope="col">Name</th>
                    <th scope="col">Price</th>
                    <th scope="col">Purchase Date</th>
                    <th scope="col">Warrenty End</th>
                    {this.checkEditDelete() && (
                      <th scope="col" style={{ width: "140px" }}></th>
                    )}
                  </tr>
                </thead>
                {purchaseList.length !== 0 ? (
                  <tbody>
                    {purchaseList.map((purchase, i) => (
                      <tr key={i}>
                        <td className="text-capitalize">{i + 1}</td>
                        <td className="text-capitalize">
                          {purchase?.name === "others"
                            ? returnFirstDegreeObjValue(purchase, "others")
                            : returnFirstDegreeObjValue(purchase, "name")}
                          {/* {returnFirstDegreeObjValue(purchase, "name")} */}
                        </td>
                        <td>
                          {" "}
                          {displayPrice(
                            returnFirstDegreeObjValue(purchase, "price")
                          )}
                        </td>

                        <td>
                          {purchase?.purchased_at
                            ? showLocaldate(
                              returnFirstDegreeObjValue(
                                purchase,
                                "purchased_at"
                              )
                            )
                            : "-"}
                        </td>
                        <td>
                          {purchase?.warranty_end
                            ? showLocaldate(
                              returnFirstDegreeObjValue(
                                purchase,
                                "warranty_end"
                              )
                            )
                            : "-"}
                        </td>
                        {this.checkEditDelete() && (
                          <td>
                            <div className="flex-center">
                              <UncontrolledDropdown
                                nav
                                className="position_u"
                              >
                                <DropdownToggle
                                  className="pr-0 nav-notice"
                                  nav
                                >
                                  <Media className="align-items-center">
                                    <img src={showMore} alt="" />
                                  </Media>
                                </DropdownToggle>
                                <DropdownMenu
                                  className="dropdown-menu-arrow min-width-drop"
                                  right
                                >
                                  {RoleBasedCRUDAccess(
                                    "property",
                                    "edit"
                                  ) && (
                                      <DropdownItem
                                        onClick={() =>
                                          this.redirectPurchaseEdit(
                                            purchase.history_id,
                                            manager_id
                                          )
                                        }
                                      >
                                        <span>Edit</span>
                                      </DropdownItem>
                                    )}

                                  {RoleBasedCRUDAccess(
                                    "property",
                                    "delete"
                                  ) && (
                                      <>
                                        <DropdownItem
                                          onClick={() =>
                                            this.deletePurchasehistoryModal(
                                              purchase.history_id
                                            )
                                          }
                                        >
                                          <span>Delete</span>
                                        </DropdownItem>
                                      </>
                                    )}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td className="no-data">
                        No{" "}
                        {p_type === "furniture_history"
                          ? "Furniture"
                          : p_type === "appliance_history"
                            ? "Appliance"
                            : "Purchase"}{" "}
                        History Available
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      {this.checkEditDelete() && <td></td>}
                    </tr>
                  </tbody>
                )}
              </Table>
            </Card>
          </div>
          <div className="align-items-center jus-space new-margin-page paginator_box">
            <div className="paginate-flex-box ">
              <div
                className="paginate-box"
                onClick={() => this.handleDecrementPage()}
              >
                <p
                  className={page !== 1 ? "page_available" : "page_unavailable"}
                >
                  Previous
                </p>
              </div>
              <div
                className="paginate-box"
                onClick={() => this.handleIncrementPage()}
              >
                <p
                  className={
                    purchaseList.length < 10
                      ? "page_unavailable"
                      : "page_available"
                  }
                >
                  Next
                </p>
              </div>
            </div>
          </div>
          <Modal
            isOpen={deletePurchaseHistoryModal}
            toggle={() => this.closeModal()}
            centered
          >
            <Card>
              {/* {toast && <ToastList message={response} type={toastType} />} */}

              <CardBody className="pad25">
                <div className="mb-20 flex">
                  <div className=" cl262626 fw-600 f16">
                    Delete Purchase History
                  </div>
                  <div
                    className="clpaid closeModal fw-500 point"
                    onClick={() => this.closeModal()}
                  >
                    x
                  </div>
                </div>

                <label className="mb-20 cl555555 f15 fw-400">
                  Are you sure you want to delete this{" "}
                  {p_type === "appliance_history"
                    ? "Appliance History"
                    : p_type === "furniture_history"
                      ? "Furniture History"
                      : "Purchase History"}
                  ?
                </label>

                <div className="flexEnd">
                  <Button
                    color="primary"
                    size="lg"
                    className="button-wrapping search-wrapper  button-secondary ml-4 cancelbtn"
                    onClick={() => this.closeModal()}
                  >
                    No
                  </Button>
                  <Button
                    color="primary"
                    size="lg"
                    className="btn-info"
                    style={{ padding: "10px 18px" }}
                    onClick={() => this.confirmDelete()}
                  >
                    {btnLoad ? (
                      <div className="spin-loading-save w100 h100">
                        <Spinner type="border" color="#012A4D" />
                      </div>
                    ) : (
                      "Yes"
                    )}
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Modal>
          {/* </Container> */}
        </CustomListingContainer>

      </>
    );
  }
}

export default Purchase;
