import React, { Component } from "react";
import ApiService from "constants/ApiService";
import { setCookie } from "constants/utils";
import { Button, Spinner } from "reactstrap";
import { invoicePay, returnFirstDegreeObjValue, returnSearchObject } from "../../constants/utils";
let selectType;
class PayMethod extends Component {
  constructor(props) {
    super(props);
    this.state = {
      directPay: true,
      cardPay: false,
      total: 0,
      cardAmount: 0,
      bank_amount: 0,
      btnLoading: false,
      loading: true,
      commission: "",
      achCommision: 0,
      ach: {},
    };
  }

  componentDidMount = () => {
    let queryObj = returnSearchObject(this.props.props.location.search);
    selectType = queryObj?.type
    this.getInvoiceCommission();
  };

  getInvoiceCommission = async () => {
    let response = await ApiService.getAPI(`host/commission`);
    if (response.success) {
      let totalAmt = this.props.amount;
      let { directPay } = this.state;

      let commission = response.data.commission.card.commission;

      let tot = invoicePay(totalAmt, commission);
      let cardamount = (tot - totalAmt).toFixed(2);
      let ach = response.data.commission.ach;
      this.setState({ ach: ach });
      this.setState({ achCommision: ach.commission });
      if (totalAmt < ach.amount) {
        this.setState({ bank_amount: ach.commission });
      }
      if (directPay) {
        this.setState(
          {
            total: totalAmt,
            cardAmount: cardamount ?? "-",
            commission: commission,
          },
          () => {
            this.setState({ loading: false });
          }
        );
      }
      if (this.state.bank_amount < this.state.cardAmount) {
        this.setState({
          directPay: true,
          cardPay: false,
          total: totalAmt + +this.state.bank_amount,
        });
      } else
        this.setState({
          directPay: false,
          cardPay: true,
          total: totalAmt + +this.state.cardAmount,
        });
    }
  };

  handlePaymentTypeChange = (e) => {
    let totalAmt = this.props.amount;
    let { commission, bank_amount } = this.state;
    if (e.target.value === "direct") {
      this.setState({
        directPay: true,
        cardPay: false,
        total: totalAmt + bank_amount,
      });
    } else {
      let tot = invoicePay(totalAmt, commission);
      this.setState({ directPay: false, cardPay: true, total: tot });
    }
  };

  handlePayment = async () => {
    this.setState({ btnLoading: true });
    let invoiceId = this.props.invoice_id;
    let { cardPay } = this.state;
    let url = (invoiceId && invoiceId !== "") ? invoiceId : selectType ? `bulk?for=${selectType}` : 'bulk'
    let data = {
      success_url: `${window.location.href}&pay_status=success`,
      cancel_url: `${window.location.href}&pay_status=failure`,
      type: cardPay ? "card" : "us_bank_account",
      // pg_stripe: true,
    };
    let response = await ApiService.postAPI(`host/invoice/pay/${url}`, data);
    this.props.close(response)
  };

  render() {
    let { amount } = this.props;
    let {
      directPay,
      cardPay,
      cardAmount,
      total,
      btnLoading,
      loading,
      achCommision,
      commission,
      bank_amount,
      ach,
    } = this.state;
    return loading ? (
      <div className="spin-loading1">
        <Spinner type="border" color="#012A4D" />
      </div>
    ) : (
      <div>
        <div className="flex payMethodHeader f18 padl30 padr30 inPad mb-30">
          <div className="fw-500 f16 ff-pop cl212121">Payment Method</div>
          <div
            className="clpaid closePayment fw-500 point"
            onClick={() => this.props.close({success: false})}
          >
            X
          </div>
        </div>
        <div style={{ margin: "20px 0px" }}>
          <div className="mb-30 fc-b fw-400 f16 padl30 padr30">
            Please Select the payment method to proceed
          </div>
          <div className="flex mb-30 fc-b fw-500 bckColor inPad padl30 padr30">
            <div> Invoice Amount </div>
            <div>
              <span>$</span>
              {amount}
            </div>
          </div>
          <div className="fc-b fw-500 mb-30 f16 padl30 padr30">
            Transaction Fee{" "}
          </div>
          <div className="padl30 padr30">
            <div
              className={
                directPay ? "paymentMethod mb-30" : "paymentMethod payDim mb-30"
              }
            >
              <div className="payment_type">
                <input
                  type="radio"
                  name="payMethod"
                  id="directPayment"
                  onChange={this.handlePaymentTypeChange}
                  value="direct"
                  style={{ marginRight: "10px", marginTop: "2px" }}
                  className={directPay ? "point" : "inactiveRadio point"}
                  checked={directPay}
                />
                <div className="f14 fw-400 point">
                  <label htmlFor="directPayment" className="point flex-column">
                    <div className="mb-5p">
                      <span className="fw-600 f14 point">Bank Transfer</span>{" "}
                      {this.state.bank_amount < this.state.cardAmount && (
                        <span className="f11 fw-400 point">
                          {" (Recommended)"}
                        </span>
                      )}
                    </div>

                    {amount < ach.amount ? (
                      <div>
                        You will be charged ${achCommision} as transaction fee
                      </div>
                    ) : (
                      <div>No transaction fee</div>
                    )}
                  </label>
                </div>
              </div>
              <div className="fw-500 f16">
                <span className="mr-10">$</span>
                {bank_amount}
              </div>
            </div>
            <div
              className={
                cardPay ? "paymentMethod mb-30" : "paymentMethod payDim mb-30"
              }
            >
              <div className="payment_type ">
                <input
                  type="radio"
                  name="PayMethod"
                  id="cardPayment"
                  value="card"
                  onChange={this.handlePaymentTypeChange}
                  style={{ marginRight: "10px", marginTop: "2px" }}
                  className={cardPay ? "point" : "inactiveRadio"}
                  checked={cardPay}
                />

                <div className="f14 fw-400 point">
                  <label htmlFor="cardPayment" className="point flex-column">
                    <div className="mb-5p">
                      <span className="fw-600 f14 point">Card Payment</span>
                      {this.state.bank_amount > this.state.cardAmount && (
                        <span className="f11 fw-400 point">
                          {" (Recommended)"}
                        </span>
                      )}
                    </div>
                    <div>
                      You will be charged {commission}% of invoice
                    </div>
                  </label>
                </div>
              </div>
              <div className="fw-500 f16">
                <span>$</span>
                {cardAmount}
              </div>
            </div>
          </div>
          <div className="flexEnd mb-30 inPad bckColor fw-500 f16 padl30 padr30">
            <span className="fw-600 mr-20">Total</span>{" "}
            <span>$</span> {total}
          </div>
          <div className="flexmid mb-30">
            <Button
              className="button-wrapping fc-w butWid"
              onClick={() => this.handlePayment()}
            >
              {btnLoading ? (
                <div className="spin-loading2">
                  <Spinner type="border" color="#012A4D" />
                </div>
              ) : (
                "Proceed to Payment"
              )}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default PayMethod;
