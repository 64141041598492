import React, { Component } from "react";
import Logo from "../../assets/img/new Logo/host_new_text.svg";

class ResponsiveSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <div className="flexmid">
          <img src={Logo} className="ml-10" />
        </div>
      </div>
    );
  }
}

export default ResponsiveSidebar;
