import React, { Component } from "react";
import lineCircle from "../assets/img/brand/req_status_circle..svg";
import RequestDone from "../assets/img/brand/reqdone.svg";
import RequestPending from "../assets/img/brand/reqPend.svg";
import pendingCheck from "../assets/img/brand/pendingCheck.svg";
import inprogressCheck from "../assets/img/brand/inProgressCheckmark.svg";
import checkmark from "../assets/img/brand/checkmark.svg";

class CustomTimeline extends Component {
  constructor(props) {
    super(props);
  }
  handleStyle = (index) => ({
    left: (100 / this.props.data.length) * index + "%",
    top: index % 2 !== 0 && "-50%",
    flexDirection: index % 2 !== 0 && "column-reverse",
    transform: index % 2 !== 0 && "translateY(-78%)",
  });

  handleDateStyle = (index) => ({
    marginTop: index % 2 !== 0 && "20px",
  });

  handleVerticalStyle = (index) => ({
    flexDirection: index % 2 !== 0 && "row-reverse",
  });

  handleflexstyle = (index) => ({
    marginRight: index % 2 !== 0 && "20px",
    marginLeft: index % 2 === 0 && "20px",
  });

  handleArrow = (index, item) => ({
    left: index % 2 !== 0 && "312px",
    borderTop:
      index % 2 !== 0 &&
      (item?.isStatus && item?.status !== "in_progress"
        ? "1px solid #012A4D"
        : item?.isStatus && item?.status === "in_progress"
        ? this.getArrowBox(item, index)
        : "1px solid #E8E8E8"),
    borderLeft: index % 2 !== 0 && "1px solid #fff",
    borderRight:
      index % 2 !== 0 &&
      (item?.isStatus && item?.status !== "in_progress"
        ? "1px solid #012A4D"
        : item?.isStatus && item?.status === "in_progress"
        ? this.getArrowBox(item, index)
        : "1px solid #E8E8E8"),
    borderBottom: index % 2 !== 0 && "1px solid #fff",
  });

  getImage = (item, i) => {
    if (item?.isStatus && this.props?.data[i + 1]?.isStatus) {
      return checkmark;
    } else if (item?.isStatus && !this.props?.data[i + 1]?.isStatus) {
      return inprogressCheck;
    } else {
      return pendingCheck;
    }
  };

  getBoxColor = (item, i) => {
    if (item?.isStatus && this.props?.data[i + 1]?.isStatus) {
      return "ver_completed_box_color";
    } else if (item?.isStatus && !this.props?.data[i + 1]?.isStatus) {
      return "ver_progress_box_color";
    } else {
      return "ver_status_content_box";
    }
  };

  getArrowBox = (item, i) => {
    if (item?.isStatus && this.props?.data[i + 1]?.isStatus) {
      return "1px solid #012A4D";
    } else if (item?.isStatus && !this.props?.data[i + 1]?.isStatus) {
      return " 1px solid #F7B84A";
    } else {
      return "1px solid #E8E8E8 ";
    }
  };

  getArrow = (item, i) => {
    if (item?.isStatus && this.props?.data[i + 1]?.isStatus) {
      return "arrow completed_arrow_box";
    } else if (item?.isStatus && !this.props?.data[i + 1]?.isStatus) {
      return " arrow in_progress_arrow_box";
    } else {
      return "arrow pending_arrow_box";
    }
  };

  getClassList = (item, i) => {
    if (item?.isStatus && this.props?.data[i + 1]?.isStatus) {
      return "ver_success_line";
    } else if (item?.isStatus && !this.props?.data[i + 1]?.isStatus) {
      return "ver_progress_line";
    } else {
      return "ver_circle_joining_line";
    }
  };

  getProcessLine = (item, i) => {
    if (item?.isStatus && this.props?.data[i + 1]?.isStatus) {
      return "success_line";
    } else if (item?.isStatus && !this.props?.data[i + 1]?.isStatus) {
      return "progress_line";
    } else {
      return "circle_joining_line";
    }
  };

  getBoxStyle = (item, i) => {
    if (item?.isStatus && this.props?.data[i + 1]?.isStatus) {
      return "completed_box_color";
    } else if (item?.isStatus && !this.props?.data[i + 1]?.isStatus) {
      return "progress_box_color";
    } else {
      return "status_content_box";
    }
  };
  getHImage = (item, i) => {
    if (item?.isStatus && this.props?.data[i + 1]?.isStatus) {
      return RequestDone;
    } else if (item?.isStatus && !this.props?.data[i + 1]?.isStatus) {
      return RequestPending;
    } else {
      return lineCircle;
    }
  };

  render() {
    return (
      <>
        {this.props.view ? (
          <>
            {this.props.data?.map((item, i) => (
              <div
                key={i}
                className={
                  this.props.data.length === 2 && i === 1
                    ? "req_individual_design fix_to_last"
                    : "req_individual_design"
                }
                style={this.handleStyle(i)}
              >
                <div
                  className="show_date mb_20"
                  style={this.handleDateStyle(i)}
                >
                  {item.date ? item.date : <br></br>}
                </div>
                <img
                  src={
                    item.isStatus && item.status !== "in_progress"
                      ? RequestDone
                      : item.isStatus && item.status === "in_progress"
                      ? this.getHImage(item, i)
                      : lineCircle
                  }
                  alt=""
                />
                <div
                  className={
                    item.isStatus && item.status !== "in_progress"
                      ? "success_line"
                      : item.isStatus && item.status === "in_progress"
                      ? this.getProcessLine(item, i)
                      : "circle_joining_line"
                  }
                ></div>
                <div
                  className={
                    item.isStatus && item.status !== "in_progress"
                      ? "completed_box_color"
                      : item.isStatus && item.status === "in_progress"
                      ? this.getBoxStyle(item, i)
                      : "status_content_box"
                  }
                >
                  {item.boxText}
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="mt-4r">
            {this.props.data.map((item, i) => (
              <div
                key={i}
                className="start-flex"
                style={this.handleVerticalStyle(i)}
              >
                <div className="w-300"></div>
                <div className="flexy-content">
                  <div className="flex">
                    <img
                      src={
                        item?.isStatus && item?.status !== "in_progress"
                          ? checkmark
                          : item?.isStatus && item?.status === "in_progress"
                          ? this.getImage(item, i)
                          : pendingCheck
                      }
                      alt=""
                    />
                  </div>
                  {item?.status === "closed" ||
                  item?.status === "rejected" ||
                  item?.status === "cancelled" ? (
                    ""
                  ) : (
                    <div
                      className={
                        item?.isStatus && item?.status !== "in_progress"
                          ? "ver_success_line"
                          : item?.isStatus && item?.status === "in_progress"
                          ? this.getClassList(item, i)
                          : "ver_circle_joining_line"
                      }
                    ></div>
                  )}
                </div>
                <div
                  className={
                    item?.isStatus && item?.status !== "in_progress"
                      ? "ver_completed_box_color"
                      : item?.isStatus && item?.status === "in_progress"
                      ? this.getBoxColor(item, i)
                      : "ver_status_content_box"
                  }
                  style={this.handleflexstyle(i)}
                >
                  <div className="fw-500 f13">{item?.boxText ?? ""}</div>
                  <div className="f13 fw-400">{item?.date ?? ""}</div>
                  <div className="arrow_container">
                    <div
                      className={
                        item?.isStatus && item?.status !== "in_progress"
                          ? "arrow completed_arrow_box"
                          : item?.isStatus && item?.status === "in_progress"
                          ? this.getArrow(item, i)
                          : "arrow pending_arrow_box"
                      }
                      style={this.handleArrow(i, item)}
                    ></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </>
    );
  }
}
export default CustomTimeline;
