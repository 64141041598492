import React, { Component } from "react";
import {
  Badge,
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  InputGroup,
  InputGroupText,
  CardBody,
  Spinner,
  DropdownMenu,
  DropdownItem,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";
// core components
import ApiService from "constants/ApiService";
import { returnFirstDegreeObjValue } from "constants/utils";
import returnSecondDegreeObjValue from "constants/utils";
import { returnThirdDegreeObjValue } from "constants/utils";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import "react-datepicker/dist/react-datepicker.css";
import DateSvg from "../../assets/img/brand/date-pick.svg";
import { getIsoString, filterQuery } from "constants/utils";
import { getMonthFilter } from "constants/utils";
import { getFinalMonthFilter } from "constants/utils";
import { showUtcDate } from "constants/utils";
import ToastList from "components/Toast/ToastList";
import TotalInvoice from "../../../src/assets/img/brand/headerTotalInvoice.svg";
import PaidInvoice from "../../../src/assets/img/brand/paidInvoice.svg";
import UnpaidInvoice from "../../../src/assets/img/brand/unpaidInvoice.svg";
import CancelledInvoice from "../../../src/assets/img/brand/cancelledInvoice.svg";
import { showError } from "constants/utils";
import { showLocaldate } from "constants/utils";
import { userTypeQuery } from "constants/utils";
import { pushToFilteredPage } from "constants/utils";
import { returnSearchObject } from "constants/utils";
import { buildQueryString } from "constants/utils";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { CustomDatePicker } from "components/FilterFields/Filterfields";
import { AsscendingDecending } from "components/FilterFields/Filterfields";
import { returnSortKey } from "constants/utils";
class Maintenance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maintenanceList: [],
      total: 0,
      filterToggle: false,
      from: "",
      to: "",
      page: 1,
      inputSearch: "",
      filterOption: "",
      statusSearch: "",
      toast: false,
      response: "",
      toastType: "",
      isLoading: true,
      statsData: {},
      prop_id: "",
      query: {},
      timeoutId: null,
      input_value: false,
      keyValue: "",
      sortingList: ""
    };
  }

  reloadPage = () => {
    this.props.history.listen((location, search) => {
      if (location.search === "") {
        this.setState({ query: {} }, () => {
          let query = this.props.location.search;
          query = returnSearchObject(query);
          if (Object.keys(query).length === 0) {
            this.handleReset();
          }
        });
      } else {
        this.state.input_value = true
      }
    });
  };
  componentDidMount = async () => {
    let self = this;
    this.reloadPage();
    this.setQueryStatus();
  };

  // componentDidUpdate(prevProps) {
  //   let search = this.props.location.search;
  //   if (prevProps.location.search !== search) {
  //     this.setQueryStatus();
  //   }
  // }

  setQueryStatus = () => {
    let queryStatus = this.props.location.search;
    if (queryStatus) {
      let queryObj = returnSearchObject(queryStatus);

      this.setState(
        {
          from: queryObj.from ? new Date(queryObj.from) : "",
          to: queryObj.to ? new Date(queryObj.to) : "",
          statusSearch: queryObj.status ?? "",
          page: parseInt(queryObj.page) ?? "",
          query: returnSearchObject(queryStatus),
        },
        () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getMaintenanceList();
          this.getMaintenancetats();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...this.state.query,
            page: 1,
            limit: 10,
          },
        },
        () => {
          pushToFilteredPage(this.props, this.state.query);
          this.getMaintenanceList();
          this.getMaintenancetats();
        }
      );
    }
  };

  setMaintenancetatus = () => {
    const search = this.props.location.search;
    const prop_id = new URLSearchParams(search).get("prop_id");
    const status = new URLSearchParams(search).get("status");

    if (prop_id || status) {
      this.setState(
        { prop_id: prop_id ?? "", statusSearch: status ?? "" },
        () => {
          this.getMaintenanceList();
        }
      );
    } else {
      this.getMaintenanceList();
    }
  };

  getMaintenanceList = async () => {
    let { page, filterOption, statusSearch, inputSearch, from, to, prop_id } =
      this.state;
    let queryParams = "";
    if (filterOption !== "" && inputSearch !== "")
      queryParams += filterQuery(filterOption, inputSearch);
    if (statusSearch !== "") {
      queryParams += filterQuery("status", statusSearch);
    }
    if (prop_id !== "") queryParams += filterQuery("id", prop_id);
    if (from !== "") queryParams += filterQuery("from", getIsoString(from));
    if (to !== "") queryParams += filterQuery("to", getIsoString(to));
    if (from > to) {
      showError(this, "Please provide valid From and To date");
    } else {
      // let response = await ApiService.getAPI(
      //   `host/maintenance/histories?page=${page}&limit=10${queryParams}&${userTypeQuery()}`
      // );

      let response = await ApiService.getAPI(
        `host/maintenance/histories${buildQueryString(
          this.state.query
        )}&${userTypeQuery()}`
      );

      if (response.success) {
        let { maintenance, total } = response.data;
        this.setState({
          maintenanceList: maintenance,
          total: total,
          isLoading: false,
        });
      } else {
        this.setState({ isLoading: false });
        showError(this, response.message);
      }
    }
  };
  getMaintenancetats = async () => {
    let { statsData, from, to } = this.state;
    let queryParams = "";

    if (from !== "") queryParams += `&from=${getIsoString(from)}`;
    if (to !== "") queryParams += `&to=${getIsoString(to)}`;
    let response = await ApiService.getAPI(
      `host/maintenance/stats?${userTypeQuery()}${queryParams}`
    );
    if (response.success) {
      this.setState({ statsData: response.data });
    } else {
      this.setState({ isLoading: false });
      showError(this, response.message);
    }
  };
  toggleFilterMenu = () => {
    this.setState({ filterToggle: !this.state.filterToggle });
  };
  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  updateStateValues = (key, value) => {
    const updatedValue = value == null ? "" : value;
    this.setState({ [key]: updatedValue, input_value: true }, () => {
      if (this.state.from && this.state.to) {
        this.handleFilterStatus();
      }
    });
  };
  handleOnToChange = (e) => {
    this.updateStateValues("to", e);
  };
  handleOnFromChange = (e) => {
    this.updateStateValues("from", e);
  };

  handleIncrementPage = () => {
    if (this.state.maintenanceList.length >= 10) {
      this.setState({ page: this.state.page + 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };

  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };
  redirectDetailPage = (id) => {
    this.props.history.push(
      `/admin/maintenanceList/maintenance_historys/detail/${id}`
    );
  };
  redirectCustomerPage = (id) => {
    this.props.history.push(`customer/detail/${id}`);
  };
  // Toast Handler

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    staticQuery.from = getIsoString(this.state.from) ?? "";
    staticQuery.to = getIsoString(this.state.to) ?? "";
    staticQuery.sort = this.state.sortingList ?? "";
    staticQuery.status = this.state.statusSearch ?? "";
    staticQuery.page = this.state.page ?? "";
    this.setState({ query: staticQuery }, () => {
      pushToFilteredPage(this.props, { ...this.state.query });
      this.getMaintenanceList();
      this.getMaintenancetats();
    });
  };

  handleSearch = () => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  handleReset = () => {
    let self = this;
    self.setState(
      {
        from: "",
        to: "",
        statusSearch: "",
        filterOption: "",
        inputSearch: "",
        prop_id: "",
        page: 1,
        query: { page: 1, limit: 10 },
        sortingList: ""
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getMaintenanceList();
        this.getMaintenancetats();
      }
    );
  };

  setStatus = (status) => {
    this.setState({ statusSearch: status, page: 1 }, () => {
      this.handleSearch();
    });
  };
  action = (key) => {
    let skey = returnSortKey(this, key);
    this.setState({ keyValue: skey, sortingList: skey, page: 1, input_value: true }, () => { this.handleFilterStatus() })
  }
  render() {
    let {
      maintenanceList,
      isLoading,
      page,
      statusSearch,
      toast,
      response,
      toastType,
      statsData,
      from,
      to,
      input_value,
      sortingList
    } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#012A4D" />
      </div>
    ) : (
      <>
        <Container className="mt-8 card-pad-wrapper" fluid>
          {toast && <ToastList message={response} type={toastType} />}
          <div>
            <Breadcrumb></Breadcrumb>
          </div>
          <Row className="align-items-center header-div">
            <Col xs="8" className="manage-con">
              <div>
                <Breadcrumb className="">Maintenance</Breadcrumb>
              </div>
              <h3 className="mb-0 managers-crumb"></h3>
            </Col>
          </Row>
          <div className="header-body">
            <Row className="row-center">
              <Col lg="6" xl="3" className="max-dash">
                <Card
                  className="card-stats mb-4 mb-xl-0 dash-new"
                  onClick={() => this.setStatus("")}
                >
                  <CardBody className="pad-dash">
                    <div className="dash_content dash_content">
                      <div className="dash_left">
                        <div className="heading_with_badge">
                          <h4>OVERALL</h4>
                        </div>
                        <label className="number-con point">
                          <CountUp
                            start={0}
                            end={statsData.total}
                            separator=","
                          />
                        </label>
                      </div>
                      <div className="col dash-col dash_right">
                        <img
                          src={TotalInvoice}
                          className="img-dash"
                          alt="total sales"
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="3" className="max-dash">
                <Card
                  className="card-stats mb-4 mb-xl-0 dash-new"
                  onClick={() => this.setStatus("accepted")}
                >
                  <CardBody className="pad-dash">
                    <div className="dash_content dash_content">
                      <div className="dash_left">
                        <div className="heading_with_badge">
                          <h4>OPEN </h4>
                        </div>
                        <label className="number-con point">
                          <CountUp
                            start={0}
                            end={statsData.open}
                            separator=","
                          />
                        </label>
                      </div>
                      <div className="col dash-col dash_right">
                        <img
                          src={PaidInvoice}
                          className="img-dash"
                          alt="total sales"
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="3" className="max-dash">
                <Card
                  className="card-stats mb-4 mb-xl-0 dash-new"
                  onClick={() => this.setStatus("completed")}
                >
                  <CardBody className="pad-dash">
                    <div className="dash_content dash_content">
                      <div className="dash_left">
                        <div className="heading_with_badge">
                          <h4>COMPLETED </h4>
                        </div>
                        <label className="number-con point">
                          <CountUp
                            start={0}
                            end={statsData.completed}
                            separator=","
                          />{" "}
                        </label>
                      </div>
                      <div className="col dash-col dash_right">
                        <img
                          src={UnpaidInvoice}
                          className="img-dash"
                          alt="total sales"
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="3" className="max-dash">

                <Card className="card-stats mb-4 mb-xl-0 dash-new">
                  <CardBody className="pad-dash">
                    <div className="dash_content dash_content">
                      <div className="dash_left">
                        <div className="heading_with_badge">
                          <h4>CANCELLED </h4>
                        </div>
                        <label className="number-con point">
                          <CountUp
                            start={0}
                            end={statsData.cancelled}
                            separator=","
                          />{" "}
                        </label>
                      </div>
                      <div className="col dash-col dash_right">
                        <img
                          src={CancelledInvoice}
                          className="img-dash"
                          alt="total sales"
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
                {/* </a> */}
              </Col>
            </Row>
          </div>
        </Container>
        <CustomListingContainer>
          <div style={{ margin: "20px" }}>
            <InputGroup className="flex-content align-left">
              <div className="filters_container">
                <div className="left_filter_container">
                  {" "}
                  <div className="w30">
                    {" "}
                    {/* <div className="flexStart" style={{ width: "100%" }}>
                      <div className="date-merge w20">Date</div>
                      <div className="w40">
                        <DatePicker
                          selected={this.state.from}
                          maxDate={new Date()}
                          dateFormat="MM-dd-yyyy"
                          placeholderText=""
                          className="filter-input-date point "
                          onChange={this.handleOnFromChange}
                          name="from"
                          id="from"
                          value={this.state.value}
                          style={{ fontWeight: "500" }}
                          showYearDropdown
                          showMonthDropdown
                          ariaDescribedBy="basic-addon2"
                        />
                      </div>

                      <div className="todate-merge">to</div>
                      <div className="w40">
                        <DatePicker
                          selected={this.state.to}
                          maxDate={new Date()}
                          dateFormat="MM-dd-yyyy"
                          placeholderText=""
                          className="to_filter-input-date  point"
                          onChange={this.handleOnToChange}
                          name="to"
                          id="to"
                          value={this.state.value}
                          style={{ fontWeight: "500" }}
                          showYearDropdown
                          showMonthDropdown
                          ariaDescribedBy="basic-addon2"
                        />
                      </div>
                    </div> */}
                    <CustomDatePicker
                      from={from}
                      to={to}
                      fromChange={this.handleOnFromChange}
                      toChange={this.handleOnToChange}
                    ></CustomDatePicker>
                  </div>
                  <div className="status_select">
                    <UncontrolledDropdown className="status_select_div">
                      <DropdownToggle className="pr-0 drop_down_container">
                        <Media className="capitalize">
                          {statusSearch === "in_progress"
                            ? "In Progress"
                            : statusSearch === ""
                              ? "All"
                              : statusSearch}{" "}
                          <i className="fas fa-angle-down"></i>
                        </Media>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" bottom>
                        {" "}
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("")}
                        >
                          All
                        </DropdownItem>{" "}
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("accepted")}
                        >
                          Accepted
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("rejected")}
                        >
                          Rejected
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("in_progress")}
                        >
                          In Progress
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("completed")}
                        >
                          Completed
                        </DropdownItem>
                        {/* <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("closed")}
                        >
                          Closed
                        </DropdownItem> */}
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("assigned")}
                        >
                          Assigned
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>
                {input_value && (statusSearch || (from || to) || sortingList) &&
                  <div className="right_filter_container">
                    <Button
                      color="primary"
                      onClick={this.handleReset}
                      size="sm"
                      className="button-wrapping mr-4 button-secondary reset-btn"
                      style={{ fontSize: "15px" }}
                    >
                      Clear filter
                    </Button>
                  </div>
                }
              </div>
            </InputGroup>
          </div>
          <Row>
            <div className="col new-table">
              <Card className="shadow card_with_paginator">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th
                        scope="col"
                        className="pl_40 justLeft items-center bnone point"
                        style={{ display: "flex" }}
                        onClick={() => this.action("pname")}
                      >
                        <AsscendingDecending keyValue={this.state.keyValue} value="pname" name="Property" />
                      </th>
                      <th scope="col">Contractor</th>
                      <th scope="col">Service Name</th>
                      <th scope="col">Service Date</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  {maintenanceList.length !== 0 ? (
                    <tbody>
                      {maintenanceList.map((maintenance, i) => (
                        <tr key={i}>
                          <td
                            className="cursor-point tdColumn tdColumn_30"
                            onClick={() =>
                              this.redirectDetailPage(maintenance.history_id)
                            }
                          >
                            {" "}
                            {returnSecondDegreeObjValue(
                              maintenance,
                              "property",
                              "name"
                            )}
                          </td>
                          <td className="cursor-point">
                            {returnSecondDegreeObjValue(
                              maintenance,
                              "contractor",
                              "name",
                              "first"
                            )}
                          </td>
                          <td className="text-capitalize">
                            {returnFirstDegreeObjValue(
                              maintenance,
                              "service_name"
                            )}
                          </td>
                          <td className="text-capitalize">
                            {showLocaldate(
                              returnFirstDegreeObjValue(
                                maintenance,
                                "service_date"
                              )
                            )}
                          </td>
                          <td className="text-capitalize">
                            {returnFirstDegreeObjValue(
                              maintenance,
                              "service_charge"
                            )}
                          </td>
                          <td
                            className={
                              maintenance.status === "accepted" ||
                                maintenance.status === "assigned"
                                ? "text-capitalize green general_status_style"
                                : maintenance.status === "cancelled" ||
                                  maintenance.status === "rejected"
                                  ? "text-capitalize red general_status_style"
                                  : maintenance.status === "in_progress" ||
                                    maintenance.status === "pending"
                                    ? "text-capitalize yellow general_status_style"
                                    : maintenance.status === "completed"
                                      ? "text-capitalize status_completed general_status_style"
                                      // : maintenance.status === "closed"
                                      //   ? "text-capitalize primary general_status_style"
                                        : "text-capitalize"
                            }
                          >
                            {returnFirstDegreeObjValue(
                              maintenance,
                              "status"
                            ) === "in_progress"
                              ? "In Progress"
                              : returnFirstDegreeObjValue(
                                maintenance,
                                "status"
                              )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td className="no-data">No Maintenance Available</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              </Card>
            </div>
          </Row>
          <Row className="align-items-center jus-space new-margin-page paginator_box">
            <div className="paginate-flex-box ">
              <div
                className="paginate-box"
                onClick={() => this.handleDecrementPage()}
              >
                <p
                  className={page !== 1 ? "page_available" : "page_unavailable"}
                >
                  Previous
                </p>
              </div>
              <div
                className="paginate-box"
                onClick={() => this.handleIncrementPage()}
              >
                <p
                  className={
                    maintenanceList.length < 10
                      ? "page_unavailable"
                      : "page_available"
                  }
                >
                  Next
                </p>
              </div>
            </div>
          </Row>
        </CustomListingContainer>
      </>
    );
  }
}

export default Maintenance;
