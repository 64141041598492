import { roleType, roleSubManager } from "constants/utils";
import Index from "../src/views/Index";
import Properties from "../src/views/Property/Properties";
import Bookings from "../src/views/Bookings/Bookings";
import Invoices from "../src/views/Invoices/Invoices";
import Notice from "../src/views/Notice/Notices";
import Requests from "../src/views/Request/Requests";
import SupplyRequests from "../src/views/Request/SupplyRequests";
import Payouts from "../src/views/Payout/Payouts";
import Vendors from "../src/views/venders/venders";
import DashboarActive from "./assets/img/brand/dashboardActiveNew.svg";
import DashboarInactive from "./assets/img/brand/dashboardInactiveNew.svg";
import PropertyInactive from "./assets/img/brand/propertiesInactiveNew.svg";
import PropertyActive from "./assets/img/brand/propertiesActiveNew.svg";
import RequestActive from "./assets/img/brand/requestActiveNew.svg";
import RequestInactive from "./assets/img/brand/requestInactiveNew.svg";
import BookingInactive from "./assets/img/brand/BookingInactive.svg";
import BookingActive from "./assets/img/brand/bookingactive.svg";
import TransactionsInactive from "./assets/img/brand/TransactionsInactive.svg";
import TransactionsActive from "./assets/img/brand/TransactionsActive.svg";
import InvoiceActive from "./assets/img/brand/myAccountIconActiveNew.svg";
import InvoiceInactive from "./assets/img/brand/myAccountIconInactiveNew.svg";
import NoticeInactive from "./assets/img/brand/NoticeInactive.svg";
import NoticeActive from "./assets/img/brand/NoticeActive.svg";
import VendorsActive from "./assets/img/brand/VendorsActiveNew.svg";
import VendorsInactive from "./assets/img/brand/VendorsInactiveNew.svg";
import PayoutInactive from "./assets/img/brand/PayoutInactive.svg";
import PayoutActive from "./assets/img/brand/PayoutActive.svg";
import TenantInactive from "./assets/img/brand/TenantsInactive.svg";
import TenantActive from "./assets/img/brand/tenantsActive.svg";
import Tenants from "../src/views/Tenants/tenants";
import MaintainceInactive from "./assets/img/brand/maintainenceInactive.svg";
import MaintainceActive from "./assets/img/brand/maintainenceActiveNew.svg";
import Payments from "views/Payments/payments";
import Expenses from "views/Expenses/expensesList";
import MaintenanceList from "views/Property/MaintenanceList";
import Calender from "views/Calender/Calender";
import CalenderActive from "./assets/img/brand/calenderActiveNew.svg";
import CalenderInactive from "./assets/img/brand/calenderInactiveNew.svg";
import AccountIconActive from "./assets/img/brand/myAccountActiveNew.svg";
import AccountIconInactive from "./assets/img/brand/myAccountInactiveNew.svg";
import profileActive from "./assets/img/brand/managerActive.svg";
import profileInactive from "./assets/img/brand/ManagerInactive.svg";
import documentActive from "./assets/img/brand/documentActive.svg";
import documentInactive from "./assets/img/brand/documentInactive.svg";
import Dashboard from "./views/Dashboard /dashboard";
import DepositActive from "./assets/img/brand/depositActive.svg";
import DepositInactive from "./assets/img/brand/depositInactive.svg";
import PasswordActive from "./assets/img/brand/passwordIconActive.svg";
import PasswordInactive from "./assets/img/brand/passwordIconInactive.svg";
import RoleadminActive from "./assets/img/brand/RoleadminActive.svg";
import RoleadminInActive from "./assets/img/brand/RoleadminInActive.svg";
import SubManagerActive from "./assets/img/brand/subManagersActive.svg";
import SubManagerInActive from "./assets/img/brand/subManagersInactive.svg";
import roleActive from "./assets/img/brand/RolesActive.svg";
import roleInActive from "./assets/img/brand/RolesInactive.svg";
import vaultActive from "./assets/img/brand/vaultActiveNew.svg";
import vaultInActive from "./assets/img/brand/vaultInactiveNew.svg";
import { roleManager } from "constants/utils";
import Documents from "views/Documents/Documents";

var RentalRoutes = [
  {
    path: "/index",
    name: "Dashboard",
    merge: "Dashboard",
    icon: DashboarActive,
    image: DashboarInactive,
    component: Dashboard,
    layout: "/admin",
    isChildren: false,
    isActive: false,
    hasChild: false,
    rolename: "dashboard",
    view: true,
  },
  {
    path: "/calender",
    name: "Calender",
    merge: "calender",
    icon: CalenderActive,
    image: CalenderInactive,
    component: Calender,
    layout: "/admin",
    isChildren: false,
    isActive: false,
    hasChild: false,
    rolename: "calender",
    view: true,
  },
  {
    name: "My Properties",
    layout: "/admin",
    isActive: false,
    isChildren: false,
    hasChild: true,
    icon: PropertyActive,
    image: PropertyInactive,
    view: true,
    subChild: [
      {
        path: "/properties",
        name: "Properties",
        merge: "ManagerProp",
        icon: PropertyActive,
        image: PropertyInactive,
        component: Properties,
        layout: "/admin",
        isActive: false,
        rolename: "property",
        view: true,
      },
      {
        path: "/bookings",
        name: "Bookings",
        merge: "BookingProp",
        icon: BookingActive,
        image: BookingInactive,
        component: Bookings,
        layout: "/admin",
        isActive: false,
        rolename: "booking",
        view: true,
      },
      {
        path: "/notices",
        name: "Notices",
        merge: "NoticeProp",
        icon: NoticeActive,
        image: NoticeInactive,
        component: Notice,
        layout: "/admin",
        isActive: false,
        rolename: "notice",
        view: true,
      },
      {
        path: "/tenant",
        name: "Tenants",
        merge: "",
        icon: TenantActive,
        image: TenantInactive,
        component: Tenants,
        layout: "/admin",
        isActive: false,
        rolename: "tenant",
        view: true,
      },
      {
        path: "/documentList",
        name: "Documents",
        merge: "",
        icon: "",
        image: "",
        component: Documents,
        layout: "/admin",
        isActive: false,
        rolename: "documentList",
        view: true,
      },
    ],
  },

  // {
  //   name: "My Documents",
  //   isChildren: false,
  //   layout: "/admin",
  //   isActive: false,
  //   icon: documentActive,
  //   image: documentInactive,
  //   hasChild: true,
  //   subChild: [
  //     {
  //       path: "/templates",
  //       name: "Templates",
  //       icon: documentActive,
  //       image: documentInactive,
  //       layout: "/admin",
  //       isChildren: false,
  //       isActive: false,
  //     },
  //     {
  //       path: "/published",
  //       name: "Published",
  //       icon: documentActive,
  //       image: documentInactive,
  //       layout: "/admin",
  //       isChildren: false,
  //       isActive: false,
  //     },
  //   ],
  // },

  {
    name: "My Finances",
    layout: "/admin",
    isChildren: false,
    isActive: false,
    hasChild: true,
    icon: InvoiceActive,
    image: InvoiceInactive,
    view: true,
    subChild: [
      {
        path: "/invoices",
        name: "Invoices",
        merge: "InvoiceProp",
        icon: InvoiceActive,
        image: InvoiceInactive,
        component: Invoices,
        isChildren: false,
        layout: "/admin",
        isActive: false,
        rolename: "invoice",
        view: true,
      },
      {
        name: "Payments",
        layout: "/admin",
        path: "/payments",
        component: Payments,
        isChildren: false,
        icon: TransactionsActive,
        image: TransactionsInactive,
        isActive: false,
        rolename: "payment",
        view: true,
      },
      {
        path: "/income",
        name: "Income",
        merge: "PayoutProp",
        icon: PayoutActive,
        image: PayoutInactive,
        component: Payouts,
        isChildren: false,
        layout: "/admin",
        isActive: false,
        rolename: "payout",
        view: true,
      },
      {
        path: "/deposits",
        name: "Deposits",
        merge: "",
        icon: DepositActive,
        image: DepositInactive,
        component: Payouts,
        isChildren: false,
        layout: "/admin",
        isActive: false,
        rolename: "deposit",
        view: true,
      },
      {
        name: "Expenses",
        layout: "/admin",
        path: "/expenses",
        component: Expenses,
        isChildren: false,
        icon: TransactionsActive,
        image: TransactionsInactive,
        isActive: false,
        rolename: "expense",
        view: true,
      },
    ],
  },
  {
    name: "My Requests",
    isChildren: false,
    layout: "/admin",
    isActive: false,
    icon: RequestActive,
    image: RequestInactive,
    hasChild: true,
    view: true,
    subChild: [
      {
        path: "/supplyrequests",
        name: "Supply requests",
        rolename: "request",
        merge: "",
        icon: RequestActive,
        image: RequestInactive,
        component: SupplyRequests,
        layout: "/admin",
        isChildren: false,
        isActive: false,
        view: true,
      },
      {
        path: "/requests",
        name: "Repairs & Services",
        rolename: "request",
        merge: "RequestProp",
        icon: RequestActive,
        image: RequestInactive,
        component: Requests,
        layout: "/admin",
        isChildren: false,
        isActive: false,
        rolename: "request",
        view: true,
      },
      {
        path: "/maintenanceList",
        name: "Maintenance",
        merge: "MaintenanceProp",
        icon: MaintainceActive,
        image: MaintainceInactive,
        component: MaintenanceList,
        layout: "/admin",
        isChildren: false,
        isActive: false,
        rolename: "maintenance",
        view: true,
      },
    ],
  },
  {
    name: "My Vendors",
    isChildren: false,
    layout: "/admin",
    isActive: false,
    icon: VendorsActive,
    image: VendorsInactive,
    hasChild: true,
    view: true,
    subChild: [
      {
        path: "/vendors",
        name: "Vendors",
        rolename: "vendor",
        merge: "",
        icon: VendorsActive,
        image: VendorsInactive,
        component: Vendors,
        layout: "/admin",
        isChildren: false,
        isActive: false,
        rolename: "vendor",
        view: true,
      },
    ],
  },
  
  !roleSubManager() && {
    path: "/vault",
    name: "My Vault",
    isChildren: false,
    isActive: false,
    view: true,
    hasChild: false,
    icon: vaultActive,
    image: vaultInActive,
    rolename: "vaultdocuments",
    layout: "/admin",
  },
  {
    name: "My Account",
    isChildren: false,
    layout: "/admin",
    isActive: false,
    icon: AccountIconActive,
    image: AccountIconInactive,
    hasChild: true,
    view: true,
    subChild: [
      {
        path: "/profile",
        name: "Profile",
        rolename: "profile",
        merge: "",
        icon: profileActive,
        image: profileInactive,
        // component: Venders,
        layout: "/admin",
        isChildren: false,
        isActive: false,
        view: true,
      },
      !roleSubManager() && {
        path: "/bank_account",
        name: "Bank Account",
        rolename: "bankaccount",
        merge: "",
        icon: AccountIconActive,
        image: AccountIconInactive,
        // component: Venders,
        layout: "/admin",
        isChildren: false,
        isActive: false,
        view: true,
      },
      {
        path: "/documents",
        name: "Documents",
        rolename: "documents",
        merge: "",
        icon: documentActive,
        image: documentInactive,
        // component: Venders,
        layout: "/admin",
        isChildren: false,
        isActive: false,
        view: true,
      },
      {
        path: "/password",
        name: "Change Password",
        rolename: "changepassword",
        merge: "",
        icon: PasswordActive,
        image: PasswordInactive,
        // component: Venders,
        layout: "/admin",
        isChildren: false,
        isActive: false,
        view: true,
      },
    ],
  },

  roleManager() && {
    name: "Role Admin",
    isChildren: false,
    layout: "/admin",
    isActive: false,
    icon: RoleadminActive,
    image: RoleadminInActive,
    hasChild: true,
    view: true,
    subChild: [
      {
        path: "/submanagers",
        name: "Managers",
        rolename: "submanagers",
        merge: "",
        icon: SubManagerActive,
        image: SubManagerInActive,
        layout: "/admin",
        isChildren: false,
        isActive: false,
        view: true,
      },
      {
        path: "/roles",
        name: "Roles",
        rolename: "Roles",
        merge: "",
        icon: roleActive,
        image: roleInActive,
        layout: "/admin",
        isChildren: false,
        isActive: false,
        view: true,
      },
    ],
  },
];
export default RentalRoutes;
