import React from "react";
import { Spinner } from "reactstrap";
import {
  useLocation,
  Route,
  Switch,
  Redirect,
  HashRouter,
} from "react-router-dom";
import routes from "routes.js";

const Auth = (props) => {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return <Route path={prop.path} component={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };
  const loading = (
    <div className="spin-loading">
      <Spinner type="border" color="#012A4D" />
    </div>
  );
  return (
    <>
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            {getRoutes(routes)}
            <Redirect from="*" to="/login" />
            <Redirect from="/argon-dashboard-react" to="/login" />
            {/* <Redirect from="*" to="/auth/reset" /> */}
            <Redirect from="/login" to="/reset_password" />
          </Switch>
        </React.Suspense>
      </HashRouter>
    </>
  );
};

export default Auth;
