import React, { Component } from "react";
import {
  Card,
  Table,
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  Spinner,
} from "reactstrap";
import ApiService from "constants/ApiService";
import {
  returnFirstDegreeObjValue,
  getDateRequestFormat,
  showLocaldate,
} from "constants/utils";
import { connect } from "react-redux";
import { RoleBasedCRUDAccess } from "views/Roles/RolesUtils";
import {
  getNotifyCount,
  getNotifyList,
} from "../../feature/counter/counterSlice";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationList: [],
      page: 1,
      limit: 10,
      isLoading: true,

      notifyId: [],
    };
  }

  componentDidMount() {
    this.getNotificationList();
    this.props.updateNotifyCount();
    this.props.updateNotifyList();
  }

  updateNotifyLists = async (notifiy) => {
    let { notifyId } = this.state;
    let data = {
      ids: notifyId,
    };

    let response = await ApiService.patchAPI(`host/notification`, data);
    if (response.success) {
      if (notifiy.read === 0) {
        this.props.updateNotifyCount();
        this.props.updateNotifyList();
      }

      this.getNotificationList();
    }
  };

  getNotificationList = async () => {
    let { page } = this.state;
    let response = await ApiService.getAPI(`host/notification?page=${page}`);
    if (response.success) {
      let { notifications } = response.data;
      this.setState({ notificationList: notifications, isLoading: false });
    }
  };

  redirectAddPage = () => {
    this.props.history.push("notification/add");
  };
  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 }, () =>
        this.getNotificationList(this.state.page)
      );
    }
  };
  handleIncrementPage = () => {
    if (this.state.notificationList.length >= 10) {
      this.setState({ page: this.state.page + 1 }, () =>
        this.getNotificationList(this.state.page)
      );
    }
  };
  handlSendTo = (type) => {
    switch (type) {
      case "specific":
        return "Send To Specific Properties";
      case "active":
        return "Send To All Active Customers";
      case "in_active":
        return "To All Previous Customers";
      case "all":
        return "To My Customers";
      default:
        return type;
    }
  };

  changeStatus = (notification) => {
    let { notifyId } = this.state;
    let notify_id = [...notifyId];
    notify_id.push(notification.notification_id);

    this.setState({ notifyId: notify_id }, () => {
      this.updateNotifyLists(notification);

      if (
        notification.type === "request" &&
        notification.ref &&
        RoleBasedCRUDAccess("request", "view")
      ) {
        this.props.history.push(`/admin/requests/detail/${notification.ref}`);
      }
      if (
        notification.type === "maintenance" &&
        notification.ref &&
        RoleBasedCRUDAccess("maintenance", "view")
      ) {
        this.props.history.push(
          `/admin/maintenanceList/maintenance_historys/detail/${notification.ref}`
        );
      }
      if (
        notification.type === "invoice"
        &&
        RoleBasedCRUDAccess("invoice", "view")
      ) {
        this.props.history.push(`/admin/invoices`);
      }
      // if (
      //   notification.type === "payout"
      //   &&
      //   RoleBasedCRUDAccess("payout", "view")
      // ) {
      //   this.props.history.push(`/admin/income`);
      // }
      if (
        notification.type === "vault_doc"
        &&
        RoleBasedCRUDAccess("vault_doc", "view")
      ) {
        this.props.history.push(`/admin/vault`);
      }
      if (
        notification.type === "payment"
        &&
        RoleBasedCRUDAccess("payment", "view")
      ) {
        this.props.history.push(`/admin/payments`);
      }
    });
  };

  render() {
    let { notificationList, isLoading, page } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#012A4D" />
      </div>
    ) : (
      <>
        <CustomListingContainer>
          <div className="align-items-center">
            <ListingHeaderLayout label={"Notifications"}>
              <div className="text-right pr_0" xs="4">
                <Button
                  color="primary"
                  onClick={() => this.redirectAddPage()}
                  size="sm"
                  className="button-wrapping"
                >
                  Add
                </Button>
              </div>
            </ListingHeaderLayout>
          </div>
          <Row>
            <div className="col new-table">
              <Card className="shadow card_with_paginator">
                <Table
                  className="notification_table align-items-center table-flush"
                  responsive
                >
                  <thead className="thead-light" style={{ borderBottom: "0.5px solid #e1e8f0" }}>
                    <tr style={{ height: "49px", display: "flex", alignItems: "center" }}>
                      <span style={{ marginLeft: "23px",fontWeight: "600", color: "black" }}>Title</span>
                    </tr>
                  </thead>

                  {notificationList.length !== 0 ? (
                    <tbody>
                      {notificationList.map((notification, i) => (
                        <tr
                          key={i}
                          className="point"
                          onClick={() => this.changeStatus(notification)}
                        >
                          <td className="title">
                            <p
                              className={
                                notification.read === 0
                                  ? "title-head mb-2"
                                  : "mb-2 notify_title_readed"
                              }
                            >
                              {returnFirstDegreeObjValue(notification, "title")}
                            </p>
                            <div
                              className={
                                notification.read === 0
                                  ? "line-2"
                                  : "notify_msg_readed"
                              }
                            >
                              {" "}
                              {returnFirstDegreeObjValue(
                                notification,
                                "message"
                              )}
                            </div>
                            {notification.type !== "request" &&
                              notification?.schedule_on && (
                                <div className="notification-schedule-info mt-3">
                                  {notification.type !== "request" && (
                                    <div className="additional-message">
                                      <label className="mb-0">
                                        {this.handlSendTo(notification.type)}
                                      </label>
                                    </div>
                                  )}
                                  {notification?.schedule_on && (
                                    <div className="additional-message-info">
                                      <label className="mb-0">
                                        {`Scheduled On ${showLocaldate(
                                          returnFirstDegreeObjValue(
                                            notification,
                                            "schedule_on"
                                          )
                                        )}`}
                                      </label>
                                    </div>
                                  )}
                                </div>
                              )}
                          </td>
                          <td
                            className="notification-message"
                            style={{ borderBottom: "none", marginLeft: "7rem" }}
                          >
                            <p
                              className={
                                notification.read === 0
                                  ? "notification-para"
                                  : "notify_date_readed"
                              }
                              style={{ marginLeft: "7rem" }}
                            >
                              {getDateRequestFormat(notification.updatedAt)}
                            </p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td className="no-data">No Notifications Available</td>
                        <td></td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              </Card>
            </div>
          </Row>
          <div className="align-items-center jus-space new-margin-page paginator_box">
            <div className="paginate-flex-box ">
              <div
                className="paginate-box"
                onClick={() => this.handleDecrementPage()}
              >
                <p
                  className={page !== 1 ? "page_available" : "page_unavailable"}
                >
                  Previous
                </p>
              </div>
              <div
                className="paginate-box"
                onClick={() => this.handleIncrementPage()}
              >
                <p
                  className={
                    notificationList.length < 10
                      ? "page_unavailable"
                      : "page_available"
                  }
                >
                  Next
                </p>
              </div>
            </div>
          </div>
        </CustomListingContainer >        </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    notify_count: state.counter.notify_count,
    notify_list: state.counter.notify_list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateNotifyCount: () => dispatch(getNotifyCount()),
    updateNotifyList: () => dispatch(getNotifyList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
