import React, { Component } from "react";
import {
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  InputGroup,
  Dropdown,
  Input,
  Spinner,
  Modal,
  CardBody,
  FormGroup,
  InputGroupText,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  ModalHeader,
  ModalBody,
} from "reactstrap";
// core components
import ApiService from "constants/ApiService";
import { returnFirstDegreeObjValue, userTypeQuery } from "constants/utils";
import returnSecondDegreeObjValue, { returnSortKey } from "../../../constants/utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { displayPrice } from "constants/utils";
import { getMonthFromDate, setCookie, getCookie } from "constants/utils";
import { getIsoString } from "constants/utils";
import ToastList from "components/Toast/ToastList";
import error from "assets/img/brand/error.svg";
import Lottie from "react-lottie";
import VerifySuccess from "assets/Lotties/successLottie.json";
import ProcessingLottie from "assets/Lotties/processingLottie.json";
import maintenanceInvoice from "assets/img/brand/maintenanceInvoice.svg";
import requestInvoice from "assets/img/brand/requestInvoice.svg";
import rentInvoice from "assets/img/brand/rentInvoice.svg";
import sourceRentInvoice from "assets/img/brand/sourceRentInvoice.svg";
import PayMethod from "./payMethod";
import PaymentDetails from "./paymentDetails";
import ReactTooltip from "react-tooltip";
import {
  buildQueryString,
  calculatePercentage,
  getDefaultProperty,
  pushToFilteredPage,
  redirectToBookings,
  returnSearchObject,
  showError,
  showLocaldate,
} from "../../../constants/utils";
import timelineIcon from "assets/img/brand/invoiceTimeline.svg";
import PaymentTimeline from "./paymentTimeline";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { CustomDatePicker } from "components/FilterFields/Filterfields";
import { AsscendingDecending } from "components/FilterFields/Filterfields";

class Payments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceList: [],
      total: 0,
      from: "",
      to: "",
      page: 1,
      limit: 10,
      inputSearch: "",
      filterOption: "propname",
      statusSearch: "",
      isLoading: true,
      lateFee: 0,
      toast: false,
      response: "",
      toastType: "",
      openPaymentModal: false,
      payment: "success",
      invoice_amount: "",
      transitionId: "",
      popupLoading: true,
      invoiceDetail: "",
      invoiceDetailsModal: false,
      invoiceDetailsData: "",
      payMethodModal: false,
      invoiceData: "",
      invoiceId: "",
      invoiceStatusView: "",
      timelineModal: false,
      timelineData: "",
      query: {},
      timeoutId: null,
      input_value: false,
      keyValue: "",
      sortingList: ""
    };
  }

  reloadPage = () => {
    this.props.history.listen((location, search) => {
      if (location.search === "") {
        this.setState({ query: {} }, () => {
          let query = this.props.location.search;
          query = returnSearchObject(query);
          if (Object.keys(query).length === 0) {
            this.handleReset();
          }
        });
      } else {
        this.state.input_value = true
      }
    });
  };

  componentDidMount = async () => {
    this.reloadPage();
    this.getDetails();
    this.setQueryStatus();
    // this.getInvoiceList();
  };

  // componentDidUpdate(prevProps) {
  //   let search = this.props.location.search;
  //   if (prevProps.location.search !== search) {
  //     this.setQueryStatus();
  //   }
  // }

  getDetails = async () => {
    let queryStatus = this.props.location.search;
    let queryObj = returnSearchObject(queryStatus);
    if (queryStatus) {
      if (queryObj.pay_status === "success") {
        this.setState({ openPaymentModal: true }, () => {
          setTimeout(() => this.getData(), 10000);
        });
      } else if (queryObj.pay_status === "failure") {
        this.setState({ payment: "failure", popupLoading: false }, () => {
          this.openModal();
        });
      }
    }
  };
  setQueryStatus = () => {
    let default_property = getDefaultProperty();
    let queryStatus = this.props.location.search;

    if (queryStatus) {
      let queryObj = returnSearchObject(queryStatus);
      if (queryObj.pay_status === "success" || queryObj.pay_status === "failure")
        delete queryObj["pay_status"];
      this.setState(
        {
          inputSearch: (queryObj.propname || queryObj.property_id) ?? "",
          filterOption: queryObj.propname
            ? "propname"
            : queryObj.property_id && "property_id",
          from: queryObj.from ? new Date(queryObj.from) : "",
          to: queryObj.to ? new Date(queryObj.to) : "",
          statusSearch:
            queryObj.pay_status === "success" || queryObj.pay_status === "failure"
              ? ""
              : queryObj.pay_status ?? "",
          page: parseInt(queryObj.page) ?? "",
          query: queryObj,
        },
        () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getInvoiceList();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...this.state.query,
            page: 1,
            limit: 10,
            from: getIsoString(this.state.from),
            to: getIsoString(this.state.to),
            property_id: default_property,
          },
          inputSearch: default_property ? default_property : "",
          filterOption: default_property ? "property_id" : "propname",
        },
        () => {
          pushToFilteredPage(this.props, this.state.query);
          this.getInvoiceList();
        }
      );
    }
  };

  getData = async () => {
    let id = getCookie("invoice_id");
    let response = await ApiService.getAPI(`host/transactions/${id}`);
    if (response.success) {
      if (response.data.transaction.status === "paid") {
        this.setState(
          {
            payment: "success",
            invoiceDetail: response.data.transaction,
            popupLoading: false,
          },
          () => {
            setTimeout(() => this.closePaymentModal(), 10000);
          }
        );
      } else if (response.data.transaction.status === "processing") {
        this.setState(
          {
            payment: "processing",
            invoiceDetail: response.data.transaction,
            popupLoading: false,
          },
          () => {
            setTimeout(() => this.closePaymentModal(), 10000);
          }
        );
      } else {
        this.setState({
          payment: "failure",
          popupLoading: false,
        });
      }
    }
  };

  onChangeHandler = (e) => {
    e.target.value === "" && this.handleReset();
    this.setState({ [e.target.name]: e.target.value, input_value: true });
    clearTimeout(this.state.timeoutId);
    const timeoutId = setTimeout(() => {
      this.state.filterOption && this.handleSearch();
    }, 1000);
    this.setState({ timeoutId });
  };
  onChangeSelectHandler = (status) => {
    this.setState({ filterOption: status, input_value: true }, () => {
      this.handleFilterStatus();
    });
  }
  handleSearch = () => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  getInvoiceList = async () => {
    let { from, to } = this.state;

    if (from > to) {
      showError(this, "Please provide valid From and To date");
    } else {
      let response = await ApiService.getAPI(
        `host/invoice/payment${buildQueryString(
          this.state.query
        )}&${userTypeQuery()}`
      );

      if (response.success) {
        let { invoices } = response.data;
        this.setState({ invoiceList: invoices, isLoading: false });
      } else {
        this.setState({ isLoading: false });
        showError(this, response.message);
      }
    }
  };

  updateStateValues = (key, value) => {
    const updatedValue = value == null ? "" : value;
    this.setState({ [key]: updatedValue, input_value: true }, () => {
      if (this.state.from && this.state.to) {
        this.handleFilterStatus();
      }
    });
  };

  handleOnToChange = (e) => {
    this.updateStateValues("to", e);
  };

  handleOnFromChange = (e) => {
    this.updateStateValues("from", e);
  };

  showDetailModal = (id) => {
    this.setState({ invoiceDetailsModal: true, invoiceId: id });
  };

  openPayMethodModal = (data) => {
    this.setState({ payMethodModal: true, invoiceData: data });
  };

  closeInvoiceDetailModal = () => {
    this.setState({ payMethodModal: false });
  };

  openModal = () => {
    this.setState({ openPaymentModal: true }, () => {
      setTimeout(() => this.closePaymentModal(), 10000);
    });
  };

  closePaymentModal = () => {
    this.setState({ openPaymentModal: false }, () => {
      this.handleFilterStatus();
    });
  };

  handleIncrementPage = () => {
    if (this.state.invoiceList.length >= 10) {
      this.setState({ page: this.state.page + 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };

  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };
  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  handleReset = () => {
    let default_property = getDefaultProperty();
    let self = this;
    self.setState(
      {
        from: "",
        to: "",
        statusSearch: "",
        filterOption: default_property ? "property_id" : "propname",
        inputSearch: default_property ? default_property : "",
        invoiceStatusView: "",
        page: 1,
        query: {
          page: 1,
          limit: 10,
          property_id: default_property,
        },
        sortingList: ""
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });

        this.getInvoiceList();
      }
    );
  };

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    staticQuery.from = getIsoString(this.state.from) ?? "";
    staticQuery.to = getIsoString(this.state.to) ?? "";
    if (this.state.inputSearch !== "" && this.state.filterOption !== "") {
      for (let key in staticQuery) {
        if (key === "propname" || key === "property_id") {
          delete staticQuery[key];
        }
      }
      staticQuery[this.state.filterOption] = this.state.inputSearch;
    }
    staticQuery.status = this.state.statusSearch ?? "";
    staticQuery.page = this.state.page ?? "";
    staticQuery.sort = this.state.sortingList ?? "";
    this.setState({ query: staticQuery }, () => {
      pushToFilteredPage(this.props, { ...this.state.query });
      this.getInvoiceList();
    });
  };

  onChangeValue(event) { }

  setStatus = (status) => {
    this.setState({ statusSearch: status, page: 1, input_value: true }, () => {
      this.handleFilterStatus();
    });
  };

  setInvoiceStatusView = (status) => {
    let from;
    let to;
    this.setState({ invoiceStatusView: status });
    if (status === "tm") {
      from = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      to = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
    } else if (status === "lm") {
      from = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
      to = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
    } else if (status === "ty") {
      from = new Date(new Date().getFullYear(), 0, 1);
      to = new Date(new Date().getFullYear(), 11, 31);
    } else {
      from = "";
      to = "";
    }
    this.setState({ from, to, page: 1, input_value: true }, () => {
      this.handleFilterStatus();
    });
  };

  displayTimeline = (data) => {
    this.setState({ timelineModal: true, timelineData: data });
  };

  closeTimeline = () => {
    this.setState({ timelineModal: false, timelineData: "" });
  };
  action = (key) => {
    let skey = returnSortKey(this, key);
    this.setState({ keyValue: skey, sortingList: skey, page: 1, input_value: true }, () => { this.handleFilterStatus() })
  }
  render() {
    let {
      invoiceList,
      inputSearch,
      filterOption,
      statusSearch,
      isLoading,
      page,
      toast,
      response,
      toastType,
      openPaymentModal,
      payment,
      invoice_amount,
      transitionId,
      popupLoading,
      invoiceDetail,
      directPay,
      cardPay,
      payMethodModal,
      invoiceDetailsModal,
      invoiceId,
      invoiceStatusView,
      timelineModal,
      timelineData,
      from,
      to,
      input_value,
      sortingList,
    } = this.state;

    const SuccessVerifyOptions = {
      loop: true,
      autoplay: true,
      animationData: VerifySuccess,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    const ProcessingOptions = {
      loop: true,
      autoplay: true,
      animationData: ProcessingLottie,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#012A4D" />
      </div>
    ) : (
      <>
        {toast && <ToastList message={response} type={toastType} />}
        <CustomListingContainer>
          <ListingHeaderLayout label={"Payments"}>
            <div className="align-items-center justend">
              <div className="flexEnd invoiceStatusView properties_headAlign" style={{ marginRight: "15px" }}>
                <UncontrolledDropdown className="status_select_div ">
                  <DropdownToggle className="pr-0 drop_down_container">
                    <Media className="capitalize">
                      {invoiceStatusView === "tm"
                        ? "This Month"
                        : invoiceStatusView === "lm"
                          ? "Last Month"
                          : invoiceStatusView === "ty"
                            ? "This Year"
                            : "All"}
                      <i className="fas fa-angle-down"></i>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" bottom>
                    {" "}
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.setInvoiceStatusView("")}
                    >
                      All
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.setInvoiceStatusView("tm")}
                    >
                      This Month
                    </DropdownItem>{" "}
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.setInvoiceStatusView("lm")}
                    >
                      Last Month
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.setInvoiceStatusView("ty")}
                    >
                      This Year
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          </ListingHeaderLayout>
          <div className="add-filter-wrap"></div>
          <div style={{ margin: "20px" }}>
            <InputGroup className="flex-content align-left">
              <div className="filters_container">
                <div className="left_filter_container">
                  <div className="w30">
                    <CustomDatePicker
                      from={from}
                      to={to}
                      fromChange={this.handleOnFromChange}
                      toChange={this.handleOnToChange}
                    ></CustomDatePicker>
                  </div>
                  <div className="select_with_input status_select_input ml-10">
                    <UncontrolledDropdown className="status_select_div ">
                      <DropdownToggle className="pr-0 drop_down_container">
                        <Media className="capitalize">
                          {filterOption === "propname" ? "Property"
                            : filterOption === "property_id" ? "Property Id" : "propname"
                          }
                          <i className="fas fa-angle-down" style={{ marginLeft: "20px" }}></i>
                        </Media>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" bottom style={{ minWidth: "130px" }}>
                        {" "}
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.onChangeSelectHandler("propname")}
                        >
                          Property
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.onChangeSelectHandler("property_id")}
                        >
                          Property Id
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <Input
                      aria-label="Text input with dropdown button"
                      value={inputSearch}
                      name="inputSearch"
                      placeholder="search by"
                      onChange={this.onChangeHandler}
                    />
                  </div>
                  <div className="status_select">
                    <UncontrolledDropdown className="status_select_div">
                      <DropdownToggle className="pr-0 drop_down_container">
                        <Media className="capitalize">
                          {statusSearch === "" ? "All" : statusSearch}{" "}
                          <i className="fas fa-angle-down"></i>
                        </Media>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" bottom>
                        {" "}
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("")}
                        >
                          All
                        </DropdownItem>{" "}
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("created")}
                        >
                          Created
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("paid")}
                        >
                          Paid
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("cancelled")}
                        >
                          Cancelled
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>
                {input_value && (inputSearch || statusSearch || (from || to) || sortingList) &&
                  <div className="right_filter_container">
                    <Button
                      color="primary"
                      onClick={this.handleReset}
                      size="sm"
                      className="button-wrapping mr-4 button-secondary reset-btn"
                      style={{ fontSize: "15px" }}                  >
                      Clear filter
                    </Button>
                  </div>}
              </div>
            </InputGroup>
          </div>
          <Row>
            <div className="col new-table">
              <Card className="shadow card_with_paginator">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      {invoiceList.length > 0 && (
                        <th scope="col" className="pad1015"></th>
                      )}

                      <th scope="col">Invoice Id</th>
                      <th scope="col" className="bnone" style={{ padding: "0px", display: "flex" }}>
                        <th
                          scope="col"
                          className="pl_40 justLeft items-center bnone point"
                          style={{ width: "180px", display: "flex" }}
                          onClick={() => this.action("pname")}
                        >
                          <AsscendingDecending keyValue={this.state.keyValue} value="pname" name="Property" />
                        </th>
                        <th
                          scope="col"
                          className="pl_40 justLeft items-center bnone point"
                          style={{ width: "180px", display: "flex" }}
                          onClick={() => this.action("hname")}
                        >
                          <AsscendingDecending keyValue={this.state.keyValue} value="hname" name="Host" />
                        </th>
                        {/* <th scope="col">Property</th> */}

                      </th>
                      <th scope="col">Created On</th>
                      <th
                        scope="col"
                        className="pl_40 justLeft items-center bnone point"
                        style={{ display: "flex" }}
                        onClick={() => this.action("amount")}
                      >
                        <AsscendingDecending keyValue={this.state.keyValue} value="amount" name="Amount" />
                      </th>                      <th scope="col">Status</th>
                      {invoiceList.length > 0 ? (
                        <>
                          <th scope="col" style={{ width: "10%" }}></th>
                          <th scope="col" style={{ width: "5%" }}></th>
                        </>
                      ) : (
                        ""
                      )}
                    </tr>
                  </thead>
                  {invoiceList.length !== 0 ? (
                    <tbody>
                      {invoiceList.map((invoice, i) => (
                        <tr key={i}>
                          <td className="pad1015 ">
                            <div
                              data-tip={
                                invoice?.p_type === "source_rent"
                                  ? "Source Rent"
                                  : this.capitalizeFirstLetter(invoice?.p_type)
                              }
                              data-iscapture="true"
                              data-for={String(i)}
                              data-place="top"
                              className="point flexmid"
                            >
                              {invoice?.p_type ? (
                                invoice?.p_type === "rental" ? (
                                  <img src={rentInvoice} id={invoice?.p_type} />
                                ) : invoice?.p_type === "source_rent" ? (
                                  <img
                                    src={sourceRentInvoice}
                                    id={invoice?.p_type}
                                  />
                                ) : invoice?.p_type === "request" ? (
                                  <img
                                    src={requestInvoice}
                                    id={invoice?.p_type}
                                  />
                                ) : (
                                  invoice?.p_type === "maintenance" && (
                                    <img
                                      src={maintenanceInvoice}
                                      id={invoice?.p_type}
                                    />
                                  )
                                )
                              ) : (
                                ""
                              )}
                              <ReactTooltip
                                place="right"
                                type="info"
                                effect="solid"
                                id={String(i)}
                                html={true}
                              />
                            </div>
                          </td>
                          <td
                            className="text-capitalize cursor-point"
                            onClick={() =>
                              this.showDetailModal(invoice.invoice_id)
                            }
                          >
                            {returnFirstDegreeObjValue(invoice, "invoice_id")}
                          </td>

                          <td style={{ padding: "0px", width: "360px" }}>
                            <td className="cursor-point btopNone tdColumn tdColumn_18"
                            >
                              {returnSecondDegreeObjValue(
                                invoice,
                                "property",
                                "name"
                              )}
                            </td>
                            <td className="btopNone tdColumn tdColumn_18"
                            >
                              {returnSecondDegreeObjValue(
                                invoice,
                                "host",
                                "name"
                              )}{" "}
                            </td>
                          </td>
                          <td>
                            {" "}
                            {showLocaldate(
                              returnFirstDegreeObjValue(invoice, "createdAt")
                            )}
                          </td>

                          <td style={{ paddingLeft: "40px" }} className="tdColumn tdColumn_18">
                            {" "}
                            {returnFirstDegreeObjValue(invoice, "amount") !==
                              "N/A"
                              ? displayPrice(
                                returnFirstDegreeObjValue(invoice, "amount")
                              )
                              : 0}
                          </td>
                          <td
                            className={
                              invoice.status === "paid"
                                ? "text-capitalize green general_status_style"
                                : invoice.status === "created"
                                  ? "text-capitalize yellow general_status_style"
                                  : invoice.status === "cancelled"
                                    ? "text-capitalize red general_status_style"
                                    : "text-capitalize"
                            }
                          >
                            {returnFirstDegreeObjValue(invoice, "status")}
                          </td>
                          <td>
                            {invoice.status === "created" && (
                              <Button
                                color="primary"
                                className="button-wrapping paynow-btn"
                                size="sm"
                                onClick={() => this.openPayMethodModal(invoice)}
                              >
                                Pay Now
                              </Button>
                            )}
                          </td>
                          <td>
                            <img
                              src={timelineIcon}
                              onClick={() =>
                                this.displayTimeline(invoice?.timeline)
                              }
                              className="point"
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td className="no-data" style={{ width: "150px" }}>No Payments Available</td>
                        <td style={{ width: "350px" }}></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              </Card>
            </div>
          </Row>
          <Row className="align-items-center jus-space new-margin-page paginator_box">
            <div className="paginate-flex-box ">
              <div
                className="paginate-box"
                onClick={() => this.handleDecrementPage()}
              >
                <p
                  className={page !== 1 ? "page_available" : "page_unavailable"}
                >
                  Previous
                </p>
              </div>
              <div
                className="paginate-box"
                onClick={() => this.handleIncrementPage()}
              >
                <p
                  className={
                    invoiceList.length < 10
                      ? "page_unavailable"
                      : "page_available"
                  }
                >
                  Next
                </p>
              </div>
            </div>
          </Row>
        </CustomListingContainer>
        <Modal
          isOpen={invoiceDetailsModal}
          toggle={() => this.setState({ invoiceDetailsModal: false })}
          className="modal-dialog-centered modal-dialog-invoice"
        >
          <PaymentDetails invoiceId={invoiceId} />
        </Modal>
        <Modal
          isOpen={payMethodModal}
          toggle={() => this.closeInvoiceDetailModal()}
          className="modal-dialog-centered modal-dialog-paymentMethod"
        >
          <ModalBody className="pad0p">
            <PayMethod
              item={this.state.invoiceData}
              close={this.closeInvoiceDetailModal}
            />
          </ModalBody>
        </Modal>
        <Modal
          isOpen={openPaymentModal}
          className={
            payment === "success"
              ? "modal-dialog-payment-success modal-dialog-centered"
              : "modal-dialog-payment-failure modal-dialog-centered"
          }
          toggle={this.closePaymentModal}
        >
          {popupLoading ? (
            // <div className="spin-loading1">
            // </div>
            <div className="spin-loading1">
              <div className="flex-column d_flex_align">
                <Spinner type="border" color="#012A4D" className="mb-30" />
                <div className="f14 fw-400">
                  Please wait while we are processing your payment
                </div>
              </div>
            </div>
          ) : (
            <div>
              {payment === "success" && (
                <div className="paymentSuccess">
                  <Lottie
                    options={SuccessVerifyOptions}
                    height={170}
                    width={170}
                  />
                  <div className="paymentTitle align_midd">
                    Payment Successful
                  </div>
                  <div className="align_midd amount paymentTitle">
                    ${invoiceDetail.amount}
                  </div>
                  <div className="align_midd mb-2r">
                    Your Payment has been successfully processed
                  </div>
                  <div className="flex">
                    <div className="fw-500">Transaction id</div>
                    <div>{invoiceDetail.transaction_id}</div>
                  </div>
                </div>
              )}{" "}
              {payment === "processing" && (
                <div className="paymentSuccess">
                  <Lottie
                    options={ProcessingOptions}
                    height={170}
                    width={170}
                  />
                  <div className="paymentTitle align_midd mt-20">
                    Payment Processing
                  </div>

                  <div className="align_midd mb-2r">
                    Your Payment is being processed. Will update your payment
                    automatically.
                  </div>
                </div>
              )}
              {payment === "failure" && (
                <div className="paymentFailed">
                  <img src={error} alt="error" className="paymentError" />
                  <div className="paymentTitle">Payment Failed</div>
                  <div className="align_midd">
                    Your Payment was not successfully processed. Please try
                    again after sometime.
                  </div>
                </div>
              )}
            </div>
          )}
        </Modal>

        <Card className={timelineModal ? "exportSideBar" : "toggle-sidebar"}>
          <PaymentTimeline
            close={this.closeTimeline}
            isOpen={timelineModal}
            data={timelineData}
          />
        </Card>
      </>
    );
  }
}

export default Payments;
