import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  InputGroup,
  Input,
  FormGroup,
  Modal,
  InputGroupText,
  UncontrolledDropdown,
  DropdownItem,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Media,
} from "reactstrap";
import DatePicker from "react-datepicker";
import ReactTooltip from "react-tooltip";
import "react-datepicker/dist/react-datepicker.css";
import DateSvg from "../../assets/img/brand/date-pick.svg";
import Copy from "../../assets/img/brand/copy.svg";
import { showError } from "constants/utils";
import ToastList from "components/Toast/ToastList";
import { showSuccess } from "constants/utils";
import ApiService from "constants/ApiService";
import moment from "moment";
import { isInvalidEmail } from "constants/utils";
export default class SendInvite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toast: false,
      toastType: "",
      response: "",
      dropdownOpen: false,
    };
  }

  componentDidMount(){
  }

  trueToggle(e) {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  }

  setCatergory(el,index) {
    this.props.change(el);
  }

  print = () => {
  }

  render() {
    let {
      toggleModal,
      closeModal,
      modalState,
      actual_start,
      check,
      onChangeDate,
      onSubmit,
      onInputChange,
      toast,
      response,
      toastType,
      email,
      onCheckChange,
      url,
      invite_id,
      description,
      link,
      status,
      copyLink,
      phoneNumber,
      handleOnPhoneNumberChange,
    } = this.props;



    return (
      <Modal
        className="modal-dialog-centered"
        isOpen={modalState}
        toggle={toggleModal}
      >
        {toast && <ToastList message={response} type={toastType} />}
        {!link ? (
          <>
            <div className="modal-header modal-invite">
              <h5 className="modal-title" id="exampleModalLabel">
                Invite
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={closeModal}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <span className="pad-invite">
              Please enter the details to complete the Invite
            </span>
            <div className="modal-body profile-body">
              {" "}
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label
                      className="form-control-label label-in mb-2"
                      htmlFor="input-username"
                    >
                      Email
                    </label>
                    <InputGroup className="input-group-alternative new-input-login">
                      <Input
                        className="form-control-alternative email-new-in invite-email-in"
                        value={email}
                        name="email"
                        placeholder=""
                        onChange={onInputChange}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label
                      className="form-control-label label-in mb-2"
                      htmlFor="input-phone-number"
                    >
                      Phone Number
                    </label>
                    <Input
                      className="form-control-alternative email-new-in invite-email-in"
                      value={phoneNumber}
                      name="phone"
                      id="input-phone-number"
                      onChange={handleOnPhoneNumberChange}
                      placeholder=""
                      type="text"
                      maxLength="16"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <div style={{ display: "flex", height: "30px" }}>
                      <label style={{ fontSize: "14px", color: "#22212F" }}>
                        Actual Start Date
                      </label>
                      <p className="date_para_info">
                        <span
                          class="info_span"
                          title="This is considered to be the inital check in of your tenant for this property"
                        >
                          <i class="fa fa-info"></i>
                        </span>
                      </p>
                    </div>

                    <InputGroup className="mb-0  flex-content notice-date point">
                      <DatePicker
                        selected={actual_start}
                        minDate={new Date().setMonth(new Date().getMonth() - 2)}
                        maxDate={new Date().setMonth(new Date().getMonth() + 2)}
                        dateFormat="MM-dd-yyyy"
                        placeholderText=""
                        className="filter-input-date  new-date-in new-send-invite point"
                        onChange={onChangeDate}
                        name="actual_start"
                        id="actual_start"
                        style={{ fontWeight: "500" }}
                        showYearDropdown
                        showMonthDropdown
                        ariaDescribedBy="basic-addon2"
                      />
                      <InputGroupText
                        id="basic-addon2"
                        className="group-date invite-group notice-group"
                      >
                        <label className="mb-remove" htmlFor="actual_start">
                          <img
                            src={DateSvg}
                            alt="Date-Picker Icon"
                            className="date-svg"
                          />
                        </label>
                      </InputGroupText>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <div style={{ marginBottom: "1.5rem" }} className="terms-pad">
                    <FormGroup className="mb-term mt-0" check>
                      <Input
                        type="checkbox"
                        name="check"
                        checked={check}
                        autoComplete="new-phone"
                        onChange={onCheckChange}
                        maxLength="10"
                        className="email-new-in term-in checking-in"
                        id="markaspaid"
                      />
                      <label
                        className="term-label label-invite point"
                        htmlFor="markaspaid"
                      >
                        <span onClick={this.print}> Mark as Paid </span>
                      </label>
                    </FormGroup>
                  </div>
                </Col>
              </Row>
              {this.props.screeninglist && (
                <Row>
                  <Col lg="12">
                    <div style={{ display: "flex", height: "30px" }}>
                      <label style={{ fontSize: "14px", color: "#22212F" }}>
                        Tenant Screening
                      </label>
                      <p className="date_para_info">
                        <span
                          class="info_span"
                          title="Parknstays will do background verification after tenant confirms the invite request"
                        >
                          <i class="fa fa-info"></i>
                        </span>
                      </p>
                    </div>

                    <Dropdown
                      className="status_select_div w-100"
                      toggle={(e) => this.trueToggle(e)}
                      isOpen={this.state.dropdownOpen}
                    >
                      <DropdownToggle
                        style={{ padding: "10px 10.4px" }}
                        className="drop_down_container category-dropdown mb-20 w-100"
                      >
                        <div>
                          <Media
                            style={{
                              justifyContent: "space-between",
                              alignItems: "center",
                              fontWeight: "400",
                            }}
                          >
                            <span>{this.props.selectedScreening ? 'Required' : 'Not Required' }</span>
                            <i className="fas fa-angle-down"></i>
                          </Media>
                        </div>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow w100" bottom>
                        <div
                          className="w_100 box-shadow-unset "
                          // style={{ boxShadow: "none !important" }}
                        >
                          {this.props?.screeninglist.map((el, index) => {
                            return (
                              <DropdownItem
                                className=""
                                key={index}
                                onClick={() => this.setCatergory(el.value)}
                              >
                                {el.name}
                              </DropdownItem>
                            );
                          })}
                        </div>
                      </DropdownMenu>

                      {/* <DropdownMenu className="pad0p dropdown-menu-arrow w_100">
                        <DropdownItem >Yes</DropdownItem>
                        <DropdownItem>No</DropdownItem>
                      </DropdownMenu> */}
                    </Dropdown>
                  </Col>
                </Row>
              )}
              <Row>
                <Col lg="12" className="mt-3">
                  <FormGroup>
                    <label
                      className="form-control-label label-in mb-2"
                      htmlFor="input-username"
                    >
                      Description
                    </label>
                    <InputGroup className="input-group-alternative new-input-login">
                      <Input
                        type="textarea"
                        className="form-control-alternative email-new-in invite-email-in"
                        value={description}
                        name="description"
                        placeholder=""
                        onChange={onInputChange}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              {status && (
                <Row>
                  <Col lg="12" className="mt-3">
                    <FormGroup>
                      <div className="status_div_invite">
                        <p className="mr_20 mb_0 ft_14">Status : </p>
                        <p
                          className={
                            status === "pending"
                              ? "yellow ft_500 ft_14 mb_0"
                              : status === "accepted"
                              ? "green ft_500 ft_14 mb_0"
                              : status === "rejected"
                              ? "red ft_500 ft_14 mb_0"
                              : "yellow ft_500 ft_14 mb_0"
                          }
                        >
                          {status}
                        </p>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              )}
            </div>
            <div className="modal-footer">
              <div className="add-filter-wrap ">
                <div
                  className="filter-wrap mr-3 edit-prop change-profile-pass"
                  onClick={closeModal}
                >
                  <label className="profile-cancel">Cancel</label>
                </div>
              </div>
              <Button
                color="primary"
                type="button"
                onClick={() => onSubmit()}
                className="button-wrapping change-profile-button"
              >
                {invite_id ? "Resend" : "Send"}
              </Button>
            </div>
          </>
        ) : (
          <div className="modal-header modal-invite success-invite">
            <h3 className="green text-center font-24"> Success</h3>
            <span className="text-center fade-color">
              Your invite has been sent to recipient mail. Still you can copy
              the link and share it.
            </span>
            <div className="copy-navigator mt-4">
              <p className="mb-0 linkCopyBoard"> {url}</p>
              <div className="width-invite" onClick={() => copyLink(url)}>
                <img className="cursor-point" src={Copy} alt="copy image" />
              </div>
            </div>
            <Button
              color="primary"
              type="button"
              onClick={closeModal}
              className="button-wrapping change-profile-button mt-4"
            >
              Ok
            </Button>
          </div>
        )}
      </Modal>
    );
  }
}
