import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  FormGroup,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateSvg from "../../assets/img/brand/date-pick.svg";
export default class CompleteCancel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let {
      toggleModal,
      closeModal,
      modalState,
      status,
      onChange,
      onSubmit,
      notes,
    } = this.props;
    return (
      <Modal
        className="modal-dialog-centered"
        isOpen={modalState}
        toggle={toggleModal}
      >
        <>
          <div className="modal-header modal-invite">
            <h5 className="modal-title text-capitalize" id="exampleModalLabel">
              {status} Item
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={closeModal}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <span className="pad-invite new_pad-invite">
            Are you sure you want to {status} this item ?
          </span>
          <div className="modal-body profile-body">
            {" "}
            <Row>
              <Col lg="12">
                <FormGroup>
                  <textarea
                    className="reject-text-area"
                    value={notes}
                    name="notes"
                    placeholder={`Please enter notes to ${status} this item`}
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
          {/* </div> */}
          <div className="modal-footer">
            <div className="add-filter-wrap ">
              <div
                className="filter-wrap mr-3 edit-prop change-profile-pass "
                onClick={closeModal}
              >
                <label className="profile-cancel">Cancel</label>
              </div>
            </div>
            <Button
              color="primary"
              type="button"
              onClick={onSubmit}
              className="button-wrapping change-profile-button text-capitalize"
            >
              submit
            </Button>
          </div>
        </>
      </Modal>
    );
  }
}
