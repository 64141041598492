import React, { Component } from "react";
// import { toast, ToastContainer } from "react-toast";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  InputGroup,
  Input,
  Spinner,
  Modal,
  InputGroupText,
  UncontrolledTooltip,
} from "reactstrap";
import ApiService from "constants/ApiService";
import { returnFirstDegreeObjValue } from "constants/utils";
import progressAnimate from "../../assets/Lotties/progressAnimate.json";
import { getCloudinaryImage } from "constants/utils";
import { LightgalleryItem } from "react-lightgallery";
import ToastList from "components/Toast/ToastList";
import { showSuccess } from "constants/utils";
import { showError } from "constants/utils";
import requestCall from "../../../src/assets/img/brand/reqCall.svg";
import requestEmail from "../../../src/assets/img/brand/reqEmail.svg";
import contractorImage from "../../assets/img/brand/contractorImage.svg";
// import requestMap from "../../../src/assets/img/brand/req_map.svg";
import circle from "../../../src/assets/img/brand/reqLineCircle.svg";
import CustomTimeline from "views/customtimeline";
import requestFirstStage from "../../../src/assets/img/brand/requestFirstStage.svg";
import requestSecondStage from "../../../src/assets/img/brand/secondStageReq.svg";
import RatingStar from "../../../src/assets/img/brand/ratingStar.svg";
import { returnThirdDegreeObjValue } from "constants/utils";
import returnSecondDegreeObjValue from "constants/utils";
import { showUtcDate } from "constants/utils";
import RejectModal from "./rejectRequestModal";
import verticalView from "../../../src/assets/img/brand/grouping.svg";
import horizontalView from "../../../src/assets/img/brand/invoiceView.svg";
import horizontalViewSelected from "../../../src/assets/img/brand/invoiceViewSelected.svg";
import verticalViewSelected from "../../../src/assets/img/brand/groupingSelected.svg";
import dateLight from "../../assets/img/brand/datePicker.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { showLocaldate } from "constants/utils";
import { uploadCloudinary } from "constants/utils";
import Add from "../../assets/img/brand/addIcon.svg";
import removeIcon from "../../assets/img/brand/removeIcon.svg";
import pdfImage from "../../assets/img/brand/pdf.png";
import { returnCustomerFirsLastName } from "constants/utils";
import { displayPrice } from "constants/utils";
import { maxLengthCheck } from "constants/utils";
import { RoleBasedCRUDAccess } from "views/Roles/RolesUtils";
import { roleManager } from "constants/utils";
import { returnRole } from "constants/utils";
import comment from "../../../src/assets/img/icons/common/comment.svg";
import Chat from "./chat";
import ReactTooltip from "react-tooltip";
// core components
let request_id;
let user_mode = localStorage.getItem("user");
class RequestDetail extends Component {
  constructor(props) {
    super(props);
    request_id = this.props.match.params.request_id;
    this.state = {
      requestData: {},
      issues: [],
      timeLine: [],
      pendingDate: "",
      progressDate: "",
      progress: false,
      accepted: false,
      accDate: "",
      pending: false,
      completed: false,
      closed: false,
      closedDate: "",
      completedDate: "",
      cancelDate: "",
      cancelled: false,
      rejectDate: "",
      rejected: false,
      status: "",
      reject_reason: "",
      modalOpen: false,
      rejectPopuP: false,
      toast: false,
      response: "",
      toastType: "",
      isLoading: true,
      assignTo: "",
      paidBy: "",
      commentOpenUp: false,
      OpenPopUp: false,
      OpenSchedulePopUp: false,
      contractorAssignStage: "1",
      selectedContractor: {},
      contractor_id: "",
      completedAmount: "",
      approximateAmount: "",
      completeRequestState: false,
      contractorDetails: {},
      assignLoad: false,
      img_value: "",
      bills_value: "",
      requestStatus: [
        {
          status: "pending",
          isStatus: false,
          boxText: "Request has been generated",
          date: "",
        },
        // {
        //   status: "assigned",
        //   isStatus: false,
        //   boxText: "Work Assinged to a Contractor",
        //   date: "",
        // },
        {
          status: "accepted",
          isStatus: false,
          boxText: "Contractor has Accepted the work",
          date: "",
        },

        {
          status: "in_progress",
          isStatus: false,
          boxText: "Work Is under progress",
          date: "",
        },
        {
          status: "completed",
          isStatus: false,
          boxText: "Work has been successfully completed",
          date: "",
        },
        {
          status: "closed",
          isStatus: false,
          boxText: "Request has been Closed",
          date: "",
        },
      ],
      contractor_list: [],
      contractor_search: "",
      dummyRequestStages: [],
      horizontal: false,
      scheduleOn: "",
      propId: "",
      image: [],
      imageLoading: false,
      btnLoading: false,
      completeSpin: false,
      commentModel: false,
    };
  }
  componentDidMount() {
    this.getRequestDetails();
  }

  getBoxText = (item) => {
    let { assigned_to } = this.state.requestData;
    let txt = "";
    let status = item.status;
    if (status === "pending") txt = "Request has been generated";
    else if (status === "assigned") {
      if (assigned_to === "host" || assigned_to === "manager") {
        txt =
          assigned_to === "manager"
            ? "Manager has accepted the request"
            : "Host has accepted the request";
      } else {
        txt = "Work Assigned to a Contractor";
      }
    } else if (status === "accepted") txt = "Request has been accepted";
    else if (status === "rejected") txt = "Request has been rejected";
    else if (status === "sub_contractor_rejected")
      txt = "Sub contractor has been rejected";
    else if (status === "in_progress") txt = "Work is under process";
    else if (status === "completed")
      txt = "Work has been successfully completed";
    else if (status === "closed") txt = "Request has been Closed";
    else if (status === "cancelled") txt = "Request has been Cancelled";
    else if (status === "scheduled") txt = "Request has been scheduled";
    else if (status === "rescheduled") txt = "Request has been rescheduled";
    else if (status === "sub_contractor_assigned")
      txt = "Sub contractor has been assigned";
    else txt = status;

    return txt;
  };

  getRequestDetails = async () => {
    this.setState({
      contractorDetails: {},
      dummyRequestStages: this.state.requestStatus.map((x) => ({
        ...x,
      })),
    });
    let response = await ApiService.getAPI(`host/requests/${request_id}`);
    this.setState({ isLoading: false });
    if (response.success) {
      let data = response.data.request;
      // let timeLine = response.data.request?.timeline;
      this.setState({
        propId: data.property.id,
      });

      // timeLine?.map((item) => {
      //   if (item.status === "scheduled") {
      //     let element = {
      //       status: "scheduled",
      //       isStatus: false,
      //       boxText: "Request has been scheduled",
      //       date: item.date && showLocaldate(item.date),
      //     };
      //     this.state.dummyRequestStages.splice(3, 0, element);
      //   }
      //   if (item.status === "rescheduled") {
      //     let element = {
      //       status: "rescheduled",
      //       isStatus: false,
      //       boxText: "Request has been recheduled",
      //       date: item.date && showLocaldate(item.date),
      //     };
      //     let i = this.state.dummyRequestStages.findIndex((item) => {
      //       return item.status === "rescheduled";
      //     });
      //     if (i !== -1) {
      //       this.state.dummyRequestStages.splice(i + 1, 0, element);
      //     } else {
      //       this.state.dummyRequestStages.splice(4, 0, element);
      //     }
      //   }
      // });
      // this.state.dummyRequestStages.map((localItem) => {
      //   data.timeline.map((item) => {
      //     // change the status of the local array if the request is rejected from closed to reject
      //     if (item.status === "rejected") {
      //       this.state.dummyRequestStages[5].status = "rejected";
      //       this.state.dummyRequestStages[5].boxText =
      //         "This request has been rejected";
      //     }
      //     if (item.status === "cancelled") {
      //       this.state.dummyRequestStages[5].status = "cancelled";
      //       this.state.dummyRequestStages[5].boxText =
      //         "This request has been Cancelled";
      //     }

      //     // setting the localarray to its respective data
      //     if (
      //       localItem.status === item.status &&
      //       localItem.status !== "rescheduled"
      //     ) {
      //       localItem.isStatus = true;
      //       localItem.date = item.date && showLocaldate(item.date);
      //     }
      //     if (localItem.status === item.status) {
      //       localItem.isStatus = true;
      //     }
      //   });
      // });

      // splicing the object from local Array if the request is rejected or assigned to host`
      // if (data?.assigned_to === "host" || data?.assigned_to === "manager") {
      //   this.state.dummyRequestStages.splice(1, 1);
      //   this.state.dummyRequestStages[1].boxText =
      //     data.assigned_to === "host"
      //       ? "Host has accepted the request"
      //       : "Manager has accepted the request";
      // }

      // this.state.dummyRequestStages.map((item, i) => {
      //   if (item.isStatus === false && item.status === "scheduled") {
      //     if (data.assigned_to === "host" || data.assigned_to === "manager") {
      //       this.state.dummyRequestStages.splice(2, 1);
      //     } else {
      //       this.state.dummyRequestStages.splice(3, 1);
      //     }
      //   }
      //   if (item.isStatus === false && item.status === "rescheduled") {
      //     if (data.assigned_to === "host" || data.assigned_to === "manager") {
      //       this.state.dummyRequestStages.splice(3, 1);
      //     } else {
      //       this.state.dummyRequestStages.splice(4, 1);
      //     }
      //   }
      // });

      // if (data?.timeline[1]?.status === "rejected") {
      //   this.state.dummyRequestStages.splice(1, 4);
      // }
      // end splicing the object from local Array if the request is rejected or assigned to host`

      this.setState(
        {
          // dummyRequestStages: [...arr, ...dummyArray],
          // dummyRequestStages: completedActions,
          
          requestData: data,
          image: data?.bills,
        },
        () => {
          this.setTimelineData();
        }
      );

      this.getContractors(data);
      // this.getContractorList();
    }
  };

  setTimelineData = () => {
    let { timeline } = this.state.requestData;
    const completedActions = [];
    const actions = [];
    const constantActions = [...this.state.requestStatus];
    timeline.map((item, index) => {
      if (item.status) {
        actions.push(item.status);
        completedActions.push({
          status: item?.status,
          isStatus: true,
          boxText: this.getBoxText(item),
          date: item?.date && showUtcDate(item.date),
        });
      }
    });
    if (!(actions.includes("cancelled") || actions.includes("rejected"))) {
      constantActions.map((item, index) => {
        if (!actions.includes(item.status)) {
          completedActions.push(item);
        }
      });
    }

    this.setState({ dummyRequestStages: completedActions });
  };

  getContractorList = async () => {
    let { propId } = this.state;
    let queryParams = "";
    if (this.state.contractor_search) {
      queryParams += `?name=${this.state.contractor_search}`;
    }
    let response = await ApiService.getAPI(`host/contractor/loc/${propId}${queryParams}`);
    if (response.success) {
      this.setState({ contractor_list: response.data });
    } else {
      showError(this, response.message);
    }
  };

  getContractors = async (request) => {
    if (request.contractor) {
      let response = await ApiService.getAPI(
        `host/contractor/${request.contractor.id}`
      );
      if (response.success) {
        let data = response.data.contractor;
        this.setState(
          { contractorDetails: data, contractor_id: request.contractor.id },
          () => { }
        );
      } else {
        showError(this, response.message);
      }
    }
  };

  StatusSideBar = (value) => {
    this.setState({ OpenPopUp: value,approximateAmount:this.state.requestData?.approximate_cost }, () => {
      this.getContractorList();
      if (this.state.requestData.assigned_to) {
        this.setcontractorDetail();
      }
    });
  };

  commentOpenUp = (value) => {
    this.setState({ commentOpenUp: value });
  };

  ScheduleSideBar = (value) => {
    this.setState({ OpenSchedulePopUp: value });
  };

  setcontractorDetail = () => {
    let { requestData } = this.state;
    this.setState({
      assignTo:
        requestData.assigned_to === "host" ||
          requestData.assigned_to === "manager"
          ? "self"
          : "contractor",
      paidBy: requestData.pay_by,
    });
  };

  clearAssignStages = () => {
    this.setState({
      assignTo: "",
      paidBy: "",
      selectedContractor: {},
      contractorAssignStage: "1",
      OpenPopUp: false,
      approximateAmount:"",
      completeRequestState: false,
      contractor_search: "",
    });
  };

  scheduleRequest = async () => {
    let { scheduleOn, requestData } = this.state;
    if (scheduleOn === "" || scheduleOn === null || scheduleOn === undefined) {
      showError(this, "Please Select schedule Date");
    } else {
      let data = {
        date: scheduleOn !== "" ? scheduleOn : "",
      };
      if (requestData.status === "scheduled" || requestData.status === "rescheduled") {
        let response = await ApiService.patchAPI(`host/contractor/reschedule/${request_id}`, data);
        if (response.success) {
          showSuccess(this, response.message);
          this.ScheduleSideBar(false);
          this.getRequestDetails();
        } else {
          showError(this, response.message);
        }
      } else {
        let response = await ApiService.patchAPI(`host/contractor/schedule/${request_id}`, data);
        if (response.success) {
          showSuccess(this, response.message);
          this.ScheduleSideBar(false);
          this.getRequestDetails();
        } else {
          showError(this, response.message);
        }
      }
    }
  };

  switchStage = (value) => {
    if (
      (value === "2" && this.state.assignTo === "") ||
      (value === "2" && this.state.assignTo === undefined)
    ) {
      showError(this, "Please select any one to assign this request");
    } else if (
      (value === "3" && this.state.paidBy === "") ||
      (value === "3" && this.state.paidBy === undefined)
    ) {
      showError(this, "Please select a payment option");
    } else if (
      (value === "3" && !this.state.approximateAmount) 
    ) {
      showError(this, "Please enter the estimate cost");
    }
    else {
      this.setState({ contractorAssignStage: value }, () => { });
    }
  };
  handleRadioChange = (e, key) => {
    this.setState({ [key]: e?.target?.value ? e.target.value : e });
  };

  assignRequest = () => {
    let { scheduleOn } = this.state;
    if (
      this.state.contractorAssignStage === "2" &&
      this.state.assignTo === "self" &&
      (this.state.paidBy === "" || this.state.paidBy === undefined)
    ) {
      showError(this, "Please select a payment Type");
    } else if (this.state.assignTo === "self" && this.state.scheduleOn === "") {
      showError(this, "Please select schedule date");
    } else if (
      this.state.assignTo === "contractor" &&
      Object.keys(this.state.selectedContractor).length === 0
    ) {
      showError(this, "Please select a contractor");
    }  else if (
      !this.state.approximateAmount
    ) {
      showError(this, "Please enter the estimate cost");
    }
    else {
      this.setState({ assignLoad: true });
      let data = {
        type: this.state.assignTo,
        pay_by: this.state.paidBy,
        approximate_cost: this.state.approximateAmount,
        schedule_date:
          scheduleOn === "" || scheduleOn === null || scheduleOn === undefined
            ? ""
            : scheduleOn,
      };
      if (this.state.assignTo === "contractor") {
        data["contractor"] = this.state.selectedContractor;
      }
      this.updateContractor(data);
    }
  };

  updateContractor = async (data) => {
    let response = await ApiService.patchAPI(
      `host/contractor/assign/${request_id}`,
      data
    );
    if (response.success) {
      this.setState(
        {
          OpenPopUp: false,
          assignLoad: false,
          contractorDetails: {},
          dummyRequestStages: this.state.requestStatus.map((x) => ({
            ...x,
          })),
        },
        () => {
          this.clearAssignStages();
          this.getRequestDetails();
        }
      );
    } else {
      this.setState({ assignLoad: false });
      showError(this, response.message);
    }
  };

  selectContractor = (index, item) => {
    let contractorArray = document.querySelectorAll(".individual_contractor");
    contractorArray.forEach((item) => {
      item.classList.contains("contractor_active") &&
        item.classList.remove("contractor_active");
    });
    contractorArray[index].classList.add("contractor_active");
    this.state.selectedContractor["name"] = item.name;
    this.state.selectedContractor["id"] = item.contractor_id;
  };

  openCompleteSidebar = () => {
    this.setState({ completeRequestState: true });
  };

  changeRequestState = (requestType) => {
    let data = {};
    if (requestType === "complete") {
      data["amount"] = this.state.completedAmount;
      data["bills"] = this.state.image;
      this.updateRequest(data, requestType);
    } else {
      this.updateRequest(data, requestType);
    }
  };
  updateRequest = async (data, requestType) => {
    this.setState({ btnLoading: true });
    let response = await ApiService.patchAPI(
      `host/contractor/${requestType}/${request_id}`,
      data
    );
    if (response.success) {
      this.setState({ btnLoading: false, completeSpin: false });
      showSuccess(this, response.message);
      this.clearAssignStages();
      this.getRequestDetails();
      if (requestType === "complete") {
        this.props.history.push("/admin/supplyrequests");
      }
    } else {
      showError(this, response.message);
      this.setState({ btnLoading: false, completeSpin: false });
    }
  };

  handleInputChange = (e) => {
    this.setState({ completedAmount: e.target.value }, () => { });
  };
  // this.changeRequestState("complete")
  completeRequest = () => {
    let numberRegex = /^[0-9]+$/;
    if (
      this.state.completedAmount === "" ||
      this.state.completedAmount === undefined
    ) {
      showError(this, "Please enter the amount you have spent");
    } else if (!numberRegex.test(this.state.completedAmount)) {
      showError(this, "Field should contain only number");
    } else {
      this.setState({ completeSpin: true });
      this.changeRequestState("complete");
    }
  };

  handleScheduleOnChange = (e) => {
    this.setState({ scheduleOn: e });
  };

  rejectPopUp = (value) => {
    this.setState({ rejectPopuP: value });
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleRejectReason = async () => {
    let { reject_reason } = this.state;
    if (reject_reason === "") {
      showError(this, "Please enter reason to reject this request");
    } else {
      let data = { reject_reason };
      let response = await ApiService.patchAPI(
        `host/requests/cancel/${request_id}`,
        data
      );
      if (response.success) {
        showSuccess(this, response.message);
        setTimeout(() => this.getRequestDetails(), 1200);
        this.rejectPopUp(false);
      } else {
        showError(this, response.message);
      }
    }
  };
  onImageClick = (e) => {
    this.setState({ [e.target.name]: "" });
  };
  onImageChangeHandler = async (e, subStatus) => {
    let { image } = this.state;
    let file = e.target.files[0];
    if (e.target.files.length > 0) {
      this.setState({ imageLoading: true });
    }
    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "application/pdf"
    ) {
      if (file.size < 1000000) {
        let data = await uploadCloudinary(file, "host");
        if (data.url) {
          let imgArr = [...image];
          let imageObj = {
            format: data.format,
            public_id: data.public_id,
            version: `v${data.version}`,
            type: "image",
          };
          imgArr.push(imageObj);
          this.setState(
            {
              image: imgArr,
              imageLoading: !subStatus && false,
            },
            () => {
              subStatus && this.updateBills();
            }
          );
        } else {
          showError(this, "Could not upload image");
          this.setState({ imageLoading: false });
        }
      } else {
        showError(this, "File size should not exceeds 1 MB");
        this.setState({ imageLoading: false });
      }
    } else {
      showError(this, "Invalid Format");
      this.setState({ imageLoading: false });
    }
  };

  deleteImage = (i, subStatus) => {
    let { image } = this.state;
    let imgArr = [...image];
    imgArr.splice(i, 1);
    this.setState({ image: imgArr }, () => {
      subStatus && this.updateBills();
    });
  };

  updateBills = async () => {
    let { image } = this.state;
    let data = {
      bills: image,
    };
    let response = await ApiService.patchAPI(
      `host/requests/${request_id}`,
      data
    );
    if (response.success) {
      this.setState({ imageLoading: false });
      showSuccess(this, response.message);
    } else {
      this.setState({ imageLoading: false });
      showError(this, response.message);
    }
  };

  openPdf = (data) => {
    window.open(getCloudinaryImage(100, 100, data, "c_fill"));
  };

  searchfilter = (e) => {
    this.setState({ contractor_search: e.target.value }, () => {
      this.getContractorList();
    });
  };

  goBack = (value) => {
    if (value) {
      this.setState({ contractorAssignStage: value });
    } else {
      this.clearAssignStages();
    }
  };

  toggleChatModal = () => {
    this.setState({ commentOpenUp: false });
  };
  render() {
    let {
      requestData,
      isLoading,
      toast,
      response,
      toastType,
      OpenPopUp,
      commentModel,
      OpenSchedulePopUp,
      assignTo,
      contractorAssignStage,
      contractorDetails,
      horizontal,
      image,
      imageLoading,
      assignLoad,
      completeSpin,
      commentOpenUp,
    } = this.state;

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: progressAnimate,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#012A4D" />
      </div>
    ) : (
      <>
        {/* reject Modal */}
        <RejectModal
          open={this.state.rejectPopuP}
          close={() => this.rejectPopUp(false)}
          onInputChange={this.handleChange}
          onSubmit={() => this.handleRejectReason()}
        />
        {/* end of reject Modal */}
        <Container className="mt-8 card-pad-wrapper" fluid>
          {toast && <ToastList message={response} type={toastType} />}
          <div>
            <Breadcrumb className="pad-to-8"></Breadcrumb>
          </div>
          <Row
            className="align-items-center header-div"
            style={{ justifyContent: "space-between" }}
          >
            <Col xs="6" className="manage-con">
              <Breadcrumb>
                <BreadcrumbItem
                  onClick={() => this.props.history.goBack()}
                  className="bread-cursor"
                >
                  Supply & Service Requests
                </BreadcrumbItem>
                <BreadcrumbItem active style={{ color: "#012a4d" }}>{requestData.request_id}</BreadcrumbItem>
              </Breadcrumb>
            </Col>
            <div className="top_header_requestDetail">
              <div>
                {requestData && requestData.status === "pending" ? (
                  <Col
                    xs="6"
                    className="manage-con"
                    style={{ display: "flex" }}
                  >
                    {RoleBasedCRUDAccess("request", "edit") && (
                      <>
                        <Button
                          className="button_without_border  no_shadow"
                          onClick={() => this.rejectPopUp(true)}
                        >
                          Reject
                        </Button>

                        <Button
                          className="button-wrapping ft_white"
                          onClick={() => this.StatusSideBar(true)}
                        >
                          Assign
                        </Button>
                      </>
                    )}
                  </Col>
                ) : (
                  <Col className="manage-con">
                    <div className="flexEnd">
                      {/* (requestData.assigned_to === "host" || requestData.assigned_to === "manager") */}
                      {requestData
                        && (requestData.status === "pending" || requestData.status === "accepted"
                          || requestData.status === "scheduled" || requestData.status === "rescheduled")
                        && RoleBasedCRUDAccess("request", "edit") && (
                          <Button
                            className="button_without_border  no_shadow p_0 mr-2r"
                            onClick={() => this.ScheduleSideBar(true)}
                          >
                            Reschedule
                          </Button>
                        )}
                      {requestData &&
                        (requestData.assigned_to === "host" ||
                          requestData.assigned_to === "manager") &&
                        (requestData.status === "accepted" ||
                          requestData.status === "scheduled" ||
                          requestData.status === "rescheduled") &&
                        RoleBasedCRUDAccess("request", "edit") && (
                          <Button
                            className="button-wrapping ft_white"
                            onClick={() =>
                              this.changeRequestState("in_progress")
                            }
                          >
                            {this.state.btnLoading ? (
                              <div className="spin-loading-save w100 h100">
                                <Spinner type="border" color="#012A4D" />
                              </div>
                            ) : (
                              "Mark as in Progress"
                            )}
                          </Button>
                        )}
                      {requestData &&
                        (requestData.assigned_to === "host" ||
                          requestData.assigned_to === "manager") &&
                        requestData.status === "in_progress" &&
                        RoleBasedCRUDAccess("request", "edit") && (
                          <Button
                            className="button-wrapping ft_white"
                            onClick={() => this.openCompleteSidebar()}
                          >
                            {this.state.btnLoading ? (
                              <div className="spin-loading-save w100 h100">
                                <Spinner type="border" color="#012A4D" />
                              </div>
                            ) : (
                              "Complete Request"
                            )}
                          </Button>
                        )}
                    </div>
                  </Col>
                )}
              </div>

              {(requestData.status !== "pending" &&
                requestData.status !== "rejected") && (
                  <div
                    style={{ marginLeft: "24px" }}
                    onClick={() => this.commentOpenUp(true)}
                    className="comment_Bodering"
                  >

                    <div
                      data-tip="Chat with Pro"
                      data-iscapture="true"
                      data-for="toolTip1"
                      data-place="top"
                    >
                      <img style={{ padding: "10px" }} src={comment} />
                    </div>
                    <ReactTooltip
                      place="right"
                      type="info"
                      effect="solid"
                      id="toolTip1"
                      html={true}
                    />
                  </div>
                )}
            </div>
          </Row>
          <div className="details_parent_card_holder">
            <Card className="request_left_card">
              <div className="individual_details">
                <div className="content_div_request">
                  <div className="header_and_data">
                    <p className="header">Tenant</p>
                    <p className="colon_seperator">:</p>
                    <p className="header_data">
                      {" "}
                      {returnCustomerFirsLastName(
                        requestData,
                        "user",
                        "name",
                        "first",
                        "last"
                      )}
                    </p>
                  </div>
                </div>
                <div className="content_div_request request_small_width_div">
                  <div className="header_and_data ft_family">
                    <p className="header">Priority</p>
                    <p className="colon_seperator">:</p>
                    <p
                      className={
                        requestData.priority_level === "high"
                          ? "status_red header_data capitalize"
                          : requestData.priority_level === "Medium"
                            ? "status_yellow header_data capitalize"
                            : "status_accepted header_data capitalize"
                      }
                    >
                      {" "}
                      {returnFirstDegreeObjValue(requestData, "priority_level")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="individual_details">
                <div className="content_div_request">
                  <div className="header_and_data">
                    <p className="header">Email</p>
                    <p className="colon_seperator">:</p>
                    <p className="header_data">
                      {" "}
                      {returnSecondDegreeObjValue(requestData, "user", "email")}
                    </p>
                  </div>
                </div>
                <div className="content_div_request request_small_width_div">
                  <div className="header_and_data ft_family">
                    <p className="header">Phone</p>
                    <p className="colon_seperator">:</p>
                    <p className=" header_data capitalize">
                      {returnThirdDegreeObjValue(
                        requestData,
                        "user",
                        "phone",
                        "national_number"
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="individual_details">
                <div className="content_div_request">
                  <div className="header_and_data">
                    <p className="header">Property </p>
                    <p className="colon_seperator">:</p>
                    <p className="header_data capitalize">
                      {returnSecondDegreeObjValue(
                        requestData,
                        "property",
                        "name"
                      )}
                    </p>
                  </div>
                </div>
                <div className="content_div_request request_small_width_div">
                  <div className="header_and_data ft_family">
                    <p className="header">Type </p>
                    <p className="colon_seperator">:</p>
                    <p className="header_data">
                      {" "}
                      {returnFirstDegreeObjValue(requestData, "type")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="individual_details">
                <div className="content_div_request ">
                  <div className="header_and_data">
                    <p className="header">Requested On </p>
                    <p className="colon_seperator">:</p>
                    <p className="header_data">
                      {requestData && showLocaldate(requestData?.createdAt)}
                    </p>
                  </div>
                </div>
                <div className="content_div_request request_small_width_div">
                  <div className="header_and_data ft_family">
                    <p className="header">Issues from </p>
                    <p className="colon_seperator">:</p>
                    <p className="header_data">
                      {" "}
                      {requestData?.from && showLocaldate(requestData?.from)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="individual_details">
                <div className="content_div_request ">
                  <div className="header_and_data">
                    <p className="header">Issues </p>
                    <p className="colon_seperator">:</p>
                    <p className="header_data">
                      {requestData &&
                        requestData.issues &&
                        requestData.issues.map((item, i) => (
                          <span key={i}>
                            {" "}
                            <span>{item}</span>
                            {i < requestData.issues.length - 1 && ", "}
                          </span>
                        ))}
                      {/*  */}
                    </p>
                  </div>
                </div>
                <div className="content_div_request request_small_width_div">
                  <div className="header_and_data ft_family">
                    <p className="header">Status </p>
                    <p className="colon_seperator">:</p>
                    <p
                      className={
                        requestData &&
                          (requestData.status === "pending" ||
                            requestData.status === "in_progress" ||
                            requestData.status === "rescheduled" ||
                            requestData.status === "scheduled")
                          ? "status_yellow header_data capitalize"
                          : requestData.status === "assigned" ||
                            requestData.status === "accepted" ||
                            requestData.status === "completed"
                            ? "status_completed header_data capitalize"
                            : "status_red header_data capitalize"
                      }
                    >
                      {requestData && requestData.status === "in_progress"
                        ? "In Progress"
                        : requestData.status}
                    </p>
                  </div>
                </div>
              </div>
              <div className="individual_details">
                <div className="content_div_request">
                  <div className="header_and_data">
                    <p className="header">Invoice Id </p>
                    <p className="colon_seperator">:</p>
                    <p className="header_data">
                      {/* {returnFirstDegreeObjValue(requestData, "desc")} */}
                      {returnFirstDegreeObjValue(requestData, "invoice_id")}
                    </p>
                  </div>
                </div>
                <div className="content_div_request request_small_width_div">
                  <div className="header_and_data ft_family">
                    <p className="header">Scheduled On </p>
                    <p className="colon_seperator">:</p>
                    <p className="header_data">
                      {showLocaldate(
                        returnFirstDegreeObjValue(requestData, "schedule_date")
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="individual_details">
                <div className="content_div_request">
                  <div className="header_and_data">
                    <p className="header">Description

                    </p>
                    <p className="colon_seperator">:</p>
                    <p className="header_data">
                      {returnFirstDegreeObjValue(requestData, "desc")}
                    </p>
                  </div>
                </div>
                <div className="content_div_request request_small_width_div">
                  {requestData && (
                    <div className="header_and_data ft_family">
                      <p className="header">Amount</p>
                      <p className="colon_seperator">:</p>
                      <p className="header_data">
                        {displayPrice(
                          returnFirstDegreeObjValue(requestData, "amount")
                        )}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="individual_details">
                <div className="content_div_request">
                 
                  {requestData && (
                    <div className="header_and_data ft_family">
                      <p className="header">Estimate Cost</p>
                      <p className="colon_seperator">:</p>
                      <p className="header_data">
                        {displayPrice(
                          returnFirstDegreeObjValue(requestData, "approximate_cost")
                        )}
                      </p>
                    </div>
                  )}
                </div>
                <div className="content_div_request request_small_width_div">
                <div className="header_and_data">
                    <p className="header">OTP </p>
                    <p className="colon_seperator">:</p>
                    <p className="header_data">
                      {returnFirstDegreeObjValue(requestData, "otp")}
                    </p>
                  </div>
                </div>
              </div>

              <hr className="request_card_detail_divider"></hr>
              <div className="request_image_map_div">
                <div className="request_images">
                  <p className="photo_header">Photos</p>
                  <div className="request_image_container">
                    {requestData &&
                      requestData.images &&
                      requestData.images.length > 0 ? (
                      requestData.images.map((item, i) => (
                        <>
                          <LightgalleryItem
                            className="image_upload_display request_image"
                            src={getCloudinaryImage(330, 330, item, "c_fill")}
                          >
                            <div className="mr_10">
                              {i <= 3 && (
                                <div className="request_image relative cursor_pointer ">
                                  <img
                                    key={i}
                                    className="request_detail_image "
                                    src={getCloudinaryImage(
                                      130,
                                      110,
                                      item,
                                      "c_fill"
                                    )}
                                    alt=""
                                  />
                                  {requestData?.images?.length > 4 &&
                                    i === 3 && (
                                      <>
                                        <div className="shadow_view_more cursor_pointer"></div>
                                        <p className="view_more mb_0 cursor_pointer">
                                          <p className="f13 fw-400 ">View</p>

                                          <p className="f13 fw-400">more +</p>
                                        </p>
                                      </>
                                    )}
                                </div>
                              )}
                            </div>
                          </LightgalleryItem>
                        </>
                      ))
                    ) : (
                      <p>No photos available</p>
                    )}
                  </div>
                </div>
                {/* <div className="request_map">
                  <img src={requestMap} />
                </div> */}
              </div>
            </Card>

            <Card className="request_right_card">
              {/* if no contractors assigned */}
              {requestData && requestData.status === "pending" && (
                <div className="no_contractors_assigned">
                  {" "}
                  <h2>Status</h2>
                  <div className="no_contractors_assigned_content">
                    <img src={requestFirstStage} alt="" />
                    <p className="ft_family ft_400 ft_13 mt_30">
                      Nobody has been assigned yet
                    </p>
                    {RoleBasedCRUDAccess("request", "edit") && (
                      <Button
                        className="button-wrapping ft_white"
                        onClick={() => this.StatusSideBar(true)}
                      >
                        Assign
                      </Button>
                    )}
                  </div>
                </div>
              )}

              {/* end of no contractors assigned */}

              {/* if the request is rejected */}
              {/* request rejected */}
              {requestData && requestData.status === "rejected" && (
                <div className="no_contractors_assigned">
                  {" "}
                  <div className="d_flex_space_align mb_20">
                    <h2 className="mb_0">Status</h2>
                    <p className="ft_family ft_500 ft_16 status_red mb_0">
                      Rejected
                    </p>
                  </div>
                  <div className="">
                    <div className="d_flex_align_justify">
                      {" "}
                      <img
                        className="mb_30 mt_20"
                        src={requestFirstStage}
                        alt=""
                      />
                    </div>
                    <div className="mt_30 reject_reason_div">
                      <p className="mb_0 ft_16 mr_15 status_red">
                        Reject Reason :
                      </p>
                      <p className="mb_0 ft_15 capitalize">
                        {requestData.reject_reason}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {/* end of status rejected */}
              {/* end of request rejected design */}

              {/* reassign contractor */}
              {requestData &&
                requestData.assigned_to &&
                (requestData.assigned_to === "host" ||
                  requestData.assigned_to === "manager") && (
                  <div className="no_contractors_assigned">
                    {" "}
                    <div className="no_contractors_header">
                      <h2>Self Assigned</h2>
                    </div>
                    <div className="no_contractors_assigned_content">
                      <img src={requestFirstStage} alt="" />
                      <p className="ft_family ft_400 ft_13 mt_30">
                        This work has been self assigned by the Host
                      </p>
                      <div>
                        {requestData && (
                          <div>
                            {(requestData.status === "pending" ||
                              requestData.status === "accepted" ||
                              requestData.status === "in_progress" ||
                              requestData.status === "scheduled" ||
                              requestData.status === "rescheduled") &&
                              RoleBasedCRUDAccess("request", "edit") && (
                                <Button
                                  className="button_without_border  no_shadow p_0"
                                  onClick={() => this.StatusSideBar(true)}
                                >
                                  Reassign
                                </Button>
                              )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

              {/* end of reassign contractor */}

              {/* contractor assigned */}
              {Object.keys(contractorDetails).length > 0 && (
                <div className="no_contractors_assigned">
                  <div className="no_contractors_header">
                    <h2>Contractor Details</h2>
                    {(requestData.status === "pending" ||
                      requestData.status === "accepted" ||
                      requestData.status === "in_progress" ||
                      requestData.status === "assigned" ||
                      requestData.status === "scheduled" ||
                      requestData.status === "rescheduled") && (
                        <Button
                          className="button_without_border ft_13  no_shadow p_0"
                          onClick={() => this.StatusSideBar(true)}
                        >
                          Reassign
                        </Button>
                      )}
                    {/* {requestData && requestData.status === "in_progress" && (
                      <Button
                        className="button_without_border ft_13  no_shadow p_0"
                        onClick={() => this.changeRequestState("complete")}
                      >
                        Complete Request
                      </Button>
                    )} */}
                  </div>
                  <div className="customer_req_detail_div">
                    {contractorDetails?.image && (
                      <div className="left_customer_req_div">
                        <img
                          src={
                            contractorDetails?.image &&
                            getCloudinaryImage(
                              90,
                              90,
                              contractorDetails.image,
                              "c_fill"
                            )
                          }
                          alt=""
                        />
                      </div>
                    )}
                    <div
                      className={
                        contractorDetails?.image
                          ? "right_customer_req_div"
                          : "w_100 d_flex_space_align no_img"
                      }
                    >
                      <h3 className="ft_family">{contractorDetails?.name}</h3>
                      {contractorDetails.rating && (
                        <div className="d_flex_space_align">
                          <div className="rating_contractor">
                            <img
                              className="detail_icon"
                              src={RatingStar}
                              alt=""
                            />
                            <p className="ft_family">
                              {contractorDetails.rating}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <hr className="request_card_detail_divider contractor_assign_divider"></hr>
                  <div className="contractor_text_with_icon">
                    <img className="detail_icon" src={requestCall} alt=""></img>
                    <p className="host_customer_detail ft_family">
                      {contractorDetails &&
                        contractorDetails?.phone?.national_number}
                    </p>
                  </div>
                  <div className="contractor_text_with_icon ">
                    <img
                      className="detail_icon"
                      src={requestEmail}
                      alt=""
                    ></img>
                    <p className="host_customer_detail ft_family">
                      {contractorDetails.email}
                    </p>
                  </div>
                  {requestData && (
                    <div className="d_flex_space_align">
                      <div className=" contractor_status_payment">
                        <p className="header">Status :</p>
                        <p
                          className={
                            requestData.status === "pending" ||
                              requestData.status === "in_progress" ||
                              requestData.status === "rescheduled" ||
                              requestData.status === "scheduled"
                              ? "header_data status_yellow capitalize"
                              : requestData.status === "completed" ||
                                requestData.status === "assigned" ||
                                requestData.status === "accepted"
                                ? "status_accepted header_data capitalize"
                                : "status_red header_data capitalize"
                          }
                        >
                          {requestData && requestData.status === "in_progress"
                            ? "In Progress"
                            : requestData.status}
                        </p>
                      </div>{" "}
                      <div className=" contractor_status_payment">
                        <p className="header">Payment :</p>
                        <p
                          className="header_data"
                          style={{ textTransform: "capitalize" }}
                        >
                          {requestData.pay_by}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {/* end of contractor assigned */}
            </Card>
          </div>
          {requestData && requestData.status === "completed" && (
            <Card className="mt-10 pad24p">
              <div className="f15 fw-600 cl374957 mb-20">Bills</div>
              <div className="gridAuto ">
                {image?.length > 0 &&
                  image?.map((img, i) => (
                    <div className="requestBillImage">
                      {img.format === "pdf" ? (
                        <img
                          src={pdfImage}
                          alt="PDF"
                          onClick={() => this.openPdf(img)}
                          className="point br-5"
                          width="50px"
                          hight="70px"
                        />
                      ) : (
                        <LightgalleryItem
                          className="image_upload_display request_image"
                          src={getCloudinaryImage(100, 100, img, "c_fill")}
                        >
                          <img
                            src={getCloudinaryImage(70, 70, img, "c_fill")}
                            alt="profile img"
                            className="point br-5"
                          />
                        </LightgalleryItem>
                      )}
                      {requestData &&
                        requestData?.contractor &&
                        requestData?.contractor?.id ? (
                        ""
                      ) : (
                        <img
                          src={removeIcon}
                          className="deleteIcon"
                          onClick={() => this.deleteImage(i, true)}
                          alt=""
                        />
                      )}
                    </div>
                  ))}
                {requestData &&
                  requestData?.contractor &&
                  requestData?.contractor?.id
                  ? ""
                  : image.length < 5 && (
                    <div className="requestImageUploader point form-relative">
                      {imageLoading ? (
                        <div className="spin-loading3">
                          <Spinner type="border" color="#012A4D" />
                        </div>
                      ) : (
                        <div>
                          <Input
                            type="file"
                            onChange={(event) =>
                              this.onImageChangeHandler(event, true)
                            }
                            className="form-control-alternative form-control opac-hide "
                            id="ImageUpload"
                            value={this.state.img_value}
                            onClick={(e) => this.onImageClick(e)}
                          />
                          <label htmlFor="ImageUpload " className="mb-0">
                            <div className="flexmid point">
                              <img src={Add} className="point" alt="ADD" />
                            </div>
                          </label>
                        </div>
                      )}
                    </div>
                  )}
              </div>
            </Card>
          )}
          <Card className="timeline_card mt_10">
            <div className="flex">
              <h2 className="ft_family">Timeline</h2>
              <div className="flex">
                <img
                  src={horizontal ? horizontalView : horizontalViewSelected}
                  className="mr-10 point"
                  onClick={() => this.setState({ horizontal: false })}
                  alt=""
                />
                <img
                  src={horizontal ? verticalViewSelected : verticalView}
                  className="point"
                  onClick={() => this.setState({ horizontal: true })}
                  alt=""
                />
              </div>
            </div>
            <div
              className={
                horizontal ? "timeline_div horiOverflow" : "hori_timeline"
              }
            >
              {horizontal && <img src={circle} alt="" />}
              <div className={horizontal ? "timeline_common_line" : ""}>
                <CustomTimeline
                  className="absolute_div"
                  data={this.state.dummyRequestStages}
                  view={horizontal}
                />
              </div>
              {horizontal && <img src={circle} alt="" />}
            </div>
          </Card>
          {/* change status side bar design */}
          <Card
            className={
              this.state.completeRequestState
                ? "toggle-sidebar-width req_side_bar"
                : "toggle-sidebar"
            }
          >
            {this.state.completeRequestState && (
              <>
                <div className="modal-header maintain-canvas border_radius_0">
                  {" "}
                  <h5 className="modal-title" id="exampleModalLabel">
                    Complete Request
                  </h5>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.clearAssignStages(false)}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <div className="request_complete_stages_div">
                  <div className="request_complete_stage_one fade_effect">
                  {requestData.approximate_cost &&  <><h2 className="ft_family mb_20">
                  Estimate Cost
                    </h2>

                    <div className="mb_20">
                      <FormGroup className="mb_50">
                        <InputGroup className="d_flex_align">
                          <Input
                            className="search_contractor ft_15 new_pad10"
                            type="text"
                            disabled
                            name="approximateAmount"
                            value={requestData?.approximate_cost}
                          />
                        </InputGroup>
                      </FormGroup>
                    </div></>} 
                  
                    <h2 className="ft_family mb_20">
                      Please enter the amount you have spent
                    </h2>
                    {/* <div className="d_flex_align_justify">
                      <img className="mb_20" src={requestSecondStage} />
                    </div> */}
                    <div className="mb_20">
                      <FormGroup className="mb_50">
                        <InputGroup className="d_flex_align">
                          <Input
                            className="search_contractor price_input ft_15 "
                            type="number"
                            name="completedAmount"
                            placeholder="Please enter the amount"
                            value={this.state.completedAmount}
                            onChange={this.handleInputChange}
                            maxLength={5}
                            onInput={maxLengthCheck}
                          />
                        </InputGroup>
                      </FormGroup>
                    </div>
                    <div className="mb-40">
                      <div className="f14 fw-600 cl374957 mb-20">
                        Attach Bills if any
                      </div>
                      <div className="gridAuto65">
                        {image.length > 0 &&
                          image.map((img, i) => (
                            <div className="requestBillImage">
                              {img.format === "pdf" ? (
                                <img
                                  src={pdfImage}
                                  alt="PDF"
                                  onClick={() => this.openPdf(img)}
                                  className="point br-5"
                                  width="50px"
                                  height="70px"
                                />
                              ) : (
                                <LightgalleryItem
                                  className="image_upload_display request_image br-5"
                                  src={getCloudinaryImage(
                                    100,
                                    100,
                                    img,
                                    "c_fill"
                                  )}
                                >
                                  <img
                                    src={getCloudinaryImage(
                                      70,
                                      70,
                                      img,
                                      "c_fill"
                                    )}
                                    alt="profile img"
                                    className="point"
                                  />
                                </LightgalleryItem>
                              )}
                              <img
                                src={removeIcon}
                                className="deleteIcon"
                                onClick={() => this.deleteImage(i, false)}
                                alt=""
                              />
                            </div>
                          ))}
                        {image.length < 5 && (
                          <div className="requestImageUploader point form-relative">
                            {imageLoading ? (
                              <div className="spin-loading3">
                                <Spinner type="border" color="#012A4D" />
                              </div>
                            ) : (
                              <div>
                                <Input
                                  type="file"
                                  onChange={(event) =>
                                    this.onImageChangeHandler(event, false)
                                  }
                                  className="form-control-alternative form-control opac-hide "
                                  id="ImageUpload"
                                  value={this.state.bills_value}
                                  onClick={(e) => this.onImageClick(e)}
                                />
                                <label htmlFor="ImageUpload " className="mb-0">
                                  <div className="flexmid point">
                                    <img
                                      src={Add}
                                      className="point"
                                      alt="ADD"
                                    />
                                  </div>
                                </label>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="flex_end">
                      <Button
                        className="button-wrapping ft_white"
                        onClick={() => this.completeRequest()}
                      >
                        {completeSpin ? (
                          <div className="spin-loading-save w100 h100">
                            <Spinner type="border" color="#012A4D" />
                          </div>
                        ) : (
                          "Complete Request"
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Card>

          <Card
            className={
              OpenPopUp ? "toggle-sidebar-width req_side_bar" : "toggle-sidebar"
            }
          >
            {OpenPopUp && (
              <>
                <div className="modal-header maintain-canvas border_radius_0">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Assign Request
                  </h5>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.clearAssignStages(false)}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <div className="request_complete_stages_div scrollDiv">
                  {contractorAssignStage === "1" && (
                    <div className="request_complete_stage_one fade_effect">
                      <h2 className="ft_family mb_40">
                        To whom do you want to assign this work??
                      </h2>
                      <div className="illustration_image_div d_flex_align_justify mb_50">
                        <img src={requestFirstStage} alt="" />
                      </div>
                      <div className="input_radio_div">
                        <FormGroup className="mb_30">
                          <InputGroup className="d_flex_align">
                            <Input
                              className="radio_button mt_0 mr_10 point"
                              placeholder=""
                              type="radio"
                              value="self"
                              id="selfAssign"
                              checked={this.state.assignTo === "self"}
                              onChange={(e) =>
                                this.handleRadioChange(e, "assignTo")
                              }
                            />
                            <label
                              className="form-control-label label-in mb_0 cursor_pointer"
                              htmlFor="selfAssign"
                              onClick={() =>
                                this.handleRadioChange("self", "assignTo")
                              }
                            >
                              Assign to myself
                            </label>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup className="mb_50">
                          <InputGroup className="d_flex_align">
                            <Input
                              className="radio_button mt_0 mr_10 point"
                              placeholder=""
                              type="radio"
                              value="contractor"
                              id="contractorAssign"
                              checked={this.state.assignTo === "contractor"}
                              onChange={(e) =>
                                this.handleRadioChange(e, "assignTo")
                              }
                            />
                            <label
                              className="form-control-label label-in mb_0 cursor_pointer"
                              htmlFor="contractorAssign"
                              onClick={() =>
                                this.handleRadioChange("contractor", "assignTo")
                              }
                            >
                              Assign to a contractor
                            </label>
                          </InputGroup>
                        </FormGroup>
                      </div>
                      <div className="buttons_div">
                        <div className="button_with_back">
                          <Button
                            className="button_without_border  no_shadow p_0 mr_35"
                            onClick={() => this.goBack("")}
                          >
                            Back
                          </Button>
                          <Button
                            className="button-wrapping ft_white mr_0 mb_0"
                            onClick={(e) => this.switchStage("2")}
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                  {contractorAssignStage === "2" && (
                    <div className="request_complete_stage_one fade_effect">
                      <h2 className="ft_family mb_40">
                        Who should Pay the bill for this request?
                      </h2>
                      <div className="illustration_image_div d_flex_align_justify mb_50">
                        <img src={requestSecondStage} alt="" />
                      </div>
                      <div className="input_radio_div">
                        <FormGroup className="mb_30">
                          <InputGroup className="d_flex_align">
                            <Input
                              className="radio_button mt_0 mr_10 point"
                              placeholder=""
                              type="radio"
                              value={returnRole()}
                              id="selfPay"
                              checked={this.state.paidBy === returnRole()}
                              onChange={(e) =>
                                this.handleRadioChange(e, "paidBy")
                              }
                            />
                            <label
                              className="form-control-label label-in mb_0 point"
                              htmlFor="selfPay"
                            >
                              I will pay the money
                            </label>
                          </InputGroup>
                        </FormGroup>

                        <FormGroup className="mb_30">
                          <InputGroup className="d_flex_align">
                            <Input
                              className="radio_button mt_0 mr_10 point"
                              placeholder=""
                              type="radio"
                              value="tenant"
                              id="customerPay"
                              checked={this.state.paidBy === "tenant"}
                              onChange={(e) =>
                                this.handleRadioChange(e, "paidBy")
                              }
                            />
                            <label
                              className="form-control-label label-in mb_0 point"
                              htmlFor="customerPay"
                            >
                              Tenant should pay the money
                            </label>
                          </InputGroup>
                        </FormGroup>
                        {this.state.assignTo === "self" && (
                          <>
                            <div>
                              <h2 className="ft_family mb-10 schedule">
                                Schedule On*
                              </h2>
                              <DatePicker
                                selected={this.state.scheduleOn}
                                minDate={new Date()}
                                dateFormat="MM-dd-yyyy"
                                placeholderText=""
                                className="scheduleDate point"
                                onChange={this.handleScheduleOnChange}
                                name="scheduleOn"
                                id="scheduleOn"
                                value={this.state.scheduleOn}
                                style={{ fontWeight: "500" }}
                                showYearDropdown
                                showTimeSelect
                                showMonthDropdown
                                ariaDescribedBy="basic-addon2"
                              />
                            </div>
                            {/* <div>
      <h2 className="ft_family mb_20">
        Please enter the Approximate Amount*
      </h2>
      <div className="mb_20">
        <FormGroup className="mb_50">
          <InputGroup className="d_flex_align">
            <Input
              className="search_contractor price_input ft_15"
              type="number"
              name="approximateAmount"
              placeholder="Please enter the amount"
              value={this.state.approximateAmount}
              onChange={this.handleChange}
              maxLength={5}
              onInput={this.maxLengthCheck}
            />
          </InputGroup>
        </FormGroup>
      </div>
    </div> */}
                          </>
                        )}
                        <div>
                          <h2 className="ft_family mb_20">
                            Please enter the Estimate Cost*
                          </h2>
                          <div className="mb_20">
                            <FormGroup className="mb_50">
                              <InputGroup className="d_flex_align">
                                <Input
                                  className="search_contractor price_input ft_15"
                                  type="number"
                                  name="approximateAmount"
                                  placeholder="Please enter the amount"
                                  value={this.state.approximateAmount}
                                  onChange={this.handleChange}
                                  maxLength={5}
                                  onInput={this.maxLengthCheck}
                                />
                              </InputGroup>
                            </FormGroup>
                          </div>
                        </div>
                        {roleManager() ? (
                          <FormGroup className="mb_50">
                            <InputGroup className="d_flex_align">
                              <Input
                                className="radio_button mt_0 mr_10 point"
                                placeholder=""
                                type="radio"
                                value="host"
                                id="propertyOwner"
                                checked={this.state.paidBy === "host"}
                                onChange={(e) =>
                                  this.handleRadioChange(e, "paidBy")
                                }
                              />
                              <label
                                className="form-control-label label-in mb_0 point "
                                htmlFor="propertyOwner"
                              >
                                Property Owner should pay the money
                              </label>
                            </InputGroup>
                          </FormGroup>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="buttons_with_back">
                        <div className="stage_buttons">
                          <Button
                            className="button_without_border  no_shadow p_0 mr_35"
                            onClick={() => this.goBack("1")}
                          >
                            Back
                          </Button>
                        </div>
                        {this.state.assignTo === "self" ? (
                          <div className="stage_buttons">
                            <Button
                              className="button-wrapping ft_white mr_0 mb_0"
                              onClick={() => this.assignRequest()}
                            >
                              {assignLoad ? (
                                <div className="spin-loading-save w100 h100">
                                  <Spinner type="border" color="#012A4D" />
                                </div>
                              ) : (
                                "Assign"
                              )}
                            </Button>
                          </div>
                        ) : (
                          <div className="">
                            <Button
                              className="button-wrapping ft_white mr_0 mb_0"
                              onClick={() => this.switchStage("3")}
                            >
                              Next
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {contractorAssignStage === "3" &&
                    assignTo === "contractor" && (
                      <div className="contractor_assign_listing fade_effect ">
                        <div className="mb_20">
                          <FormGroup className="mb_50">
                            <InputGroup className="d_flex_align">
                              <Input
                                className="search_contractor"
                                type="text"
                                value={this.state.contractor_search}
                                name="contractor_search"
                                placeholder="Search for Contractor"
                                onChange={this.searchfilter}
                              />
                              <i className="fa fa-search"></i>
                            </InputGroup>
                          </FormGroup>
                        </div>
                        <h2 className="ft_family ft_15 ft_500">
                          Select the contractor for the work{" "}
                        </h2>
                        <div className="contractor_listing mb_20">
                          {this.state.contractor_list &&
                            this.state.contractor_list.map((item, i) => (
                              <div
                                key={i}
                                className="individual_contractor cursor_pointer"
                                onClick={() => this.selectContractor(i, item)}
                              >
                                { }
                                {/* <img
                                className="contractor_image"
                                src={item.image ? item.image : contractorImage}
                              /> */}
                                <img
                                  className="contractor_image"
                                  src={
                                    item?.image
                                      ? getCloudinaryImage(
                                        90,
                                        90,
                                        item.image,
                                        "c_fill"
                                      )
                                      : contractorImage
                                  }
                                  alt=""
                                />
                                <div className="content_div">
                                  <h2 className="ft_family ft_600 ft_14 mb_0">
                                    {item.name}
                                  </h2>
                                  <p className="sub_text ft_12 mb_0">
                                    {item.text}
                                  </p>
                                </div>
                                <div className="rating">
                                  {item.rating && (
                                    <div className="rating_contractor">
                                      <img
                                        className="detail_icon"
                                        src={RatingStar}
                                        alt=""
                                      />
                                      <p className="ft_family">{item.rating}</p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            ))}
                        </div>{" "}
                        <div className="flex_end mt_30">
                          <Button
                            className="button_without_border no_shadow"
                            onClick={() => this.goBack("2")}
                          >
                            Back
                          </Button>{" "}
                          <Button
                            className="button-wrapping ft_white mb_0"
                            onClick={() => this.assignRequest()}
                          >
                            {assignLoad ? (
                              <div className="spin-loading-save w100 h100">
                                <Spinner type="border" color="#012A4D" />
                              </div>
                            ) : (
                              "Assign"
                            )}
                          </Button>
                        </div>
                      </div>
                    )}
                </div>{" "}
              </>
            )}
          </Card>

          {/* end of status side bar design */}
          <Modal
            isOpen={OpenSchedulePopUp}
            toggle={() => this.ScheduleSideBar(false)}
            centered
            className="scheduleModal"
          >
            <Card>
              <CardBody className="pad25">
                <div className="mb-30 flex">
                  <div className=" cl262626 fw-600 f16">
                    {requestData.status === "rescheduled" ||
                      requestData.status === "scheduled"
                      ? "Reschedule Request"
                      : "Schedule Request"}
                  </div>
                  <div
                    className="clpaid closeModal fw-500 point"
                    onClick={() => this.ScheduleSideBar(false)}
                  >
                    x
                  </div>
                </div>

                <label
                  className="f14 fw-400 cl555555 mb-10"
                  htmlFor="scheduleOn"
                >
                  {requestData.status === "rescheduled" ||
                    requestData.status === "scheduled"
                    ? "Reschedule Date"
                    : "Schedule Date"}
                </label>

                <InputGroup className="mb-0  flex-content notice-date point">
                  <DatePicker
                    selected={this.state.scheduleOn}
                    minDate={new Date()}
                    maxDate={new Date().setDate(new Date().getDate() + 14)}
                    dateFormat="MM-dd-yyyy"
                    placeholderText=""
                    // className="scheduleDate  point"
                    className="checkOutDatePick mb-30 point"
                    onChange={this.handleScheduleOnChange}
                    name="scheduleOn"
                    id="scheduleOn"
                    value={this.state.scheduleOn}
                    style={{ fontWeight: "500" }}
                    showYearDropdown
                    showTimeSelect
                    showMonthDropdown
                    ariaDescribedBy="basic-addon2"
                  />
                  <InputGroupText id="basic-addon2" className=" back-unset1">
                    <label
                      className="mb-remove cursor-point"
                      htmlFor="schedule_on"
                    >
                      <img
                        src={dateLight}
                        alt="Date-Picker Icon"
                        className="date-svg"
                      // onClick={() => this.openDatePicker()}
                      />
                    </label>
                  </InputGroupText>
                </InputGroup>
                <div className="flexEnd">
                  <Button
                    className="button-wrapping search-wrapper  button-secondary cancelbtn no_shadow button_without_border"
                    onClick={() => this.ScheduleSideBar(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="button-wrapping search-wrapper checkoutSubmit"
                    onClick={() => this.scheduleRequest()}
                  >
                    Save
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Modal>

          <Modal isOpen={commentModel}></Modal>

          <Card
            className={
              commentModel
                ? "toggle-sidebar-width req_side_bar"
                : "toggle-sidebar"
            }
          >
            {commentModel && (
              <>
                <div className="modal-header maintain-canvas border_radius_0">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Assign Request
                  </h5>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.clearAssignStages(false)}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <div className="request_complete_stages_div scrollDiv">
                  {contractorAssignStage === "1" && (
                    <div className="request_complete_stage_one fade_effect">
                      <h2 className="ft_family mb_40">
                        To whom do you want to assign this work??
                      </h2>
                      <div className="illustration_image_div d_flex_align_justify mb_50">
                        <img src={requestFirstStage} alt="" />
                      </div>
                      <div className="input_radio_div">
                        <FormGroup className="mb_30">
                          <InputGroup className="d_flex_align">
                            <Input
                              className="radio_button mt_0 mr_10 point"
                              placeholder=""
                              type="radio"
                              value="self"
                              id="selfAssign"
                              checked={this.state.assignTo === "self"}
                              onChange={(e) =>
                                this.handleRadioChange(e, "assignTo")
                              }
                            />
                            <label
                              className="form-control-label label-in mb_0 cursor_pointer"
                              htmlFor="selfAssign"
                              onClick={() =>
                                this.handleRadioChange("self", "assignTo")
                              }
                            >
                              Assign to myself
                            </label>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup className="mb_50">
                          <InputGroup className="d_flex_align">
                            <Input
                              className="radio_button mt_0 mr_10 point"
                              placeholder=""
                              type="radio"
                              value="contractor"
                              id="contractorAssign"
                              checked={this.state.assignTo === "contractor"}
                              onChange={(e) =>
                                this.handleRadioChange(e, "assignTo")
                              }
                            />
                            <label
                              className="form-control-label label-in mb_0 cursor_pointer"
                              htmlFor="contractorAssign"
                              onClick={() =>
                                this.handleRadioChange("contractor", "assignTo")
                              }
                            >
                              Assign to a contractor
                            </label>
                          </InputGroup>
                        </FormGroup>
                      </div>
                      <div className="buttons_div">
                        <div className="button_with_back">
                          <Button
                            className="button_without_border  no_shadow p_0 mr_35"
                            onClick={() => this.goBack("")}
                          >
                            Back
                          </Button>
                          <Button
                            className="button-wrapping ft_white mr_0 mb_0"
                            onClick={(e) => this.switchStage("2")}
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                  {contractorAssignStage === "2" && (
                    <div className="request_complete_stage_one fade_effect">
                      <h2 className="ft_family mb_40">
                        Who should Pay the bill for this request?
                      </h2>
                      <div className="illustration_image_div d_flex_align_justify mb_50">
                        <img src={requestSecondStage} alt="" />
                      </div>
                      <div className="input_radio_div">
                        <FormGroup className="mb_30">
                          <InputGroup className="d_flex_align">
                            <Input
                              className="radio_button mt_0 mr_10 point"
                              placeholder=""
                              type="radio"
                              value={returnRole()}
                              id="selfPay"
                              checked={this.state.paidBy === returnRole()}
                              onChange={(e) =>
                                this.handleRadioChange(e, "paidBy")
                              }
                            />
                            <label
                              className="form-control-label label-in mb_0 point"
                              htmlFor="selfPay"
                            >
                              I will pay the money
                            </label>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup className="mb_30">
                          <InputGroup className="d_flex_align">
                            <Input
                              className="radio_button mt_0 mr_10 point"
                              placeholder=""
                              type="radio"
                              value="tenant"
                              id="customerPay"
                              checked={this.state.paidBy === "tenant"}
                              onChange={(e) =>
                                this.handleRadioChange(e, "paidBy")
                              }
                            />
                            <label
                              className="form-control-label label-in mb_0 point"
                              htmlFor="customerPay"
                            >
                              Tenant should pay the money
                            </label>
                          </InputGroup>
                        </FormGroup>
                        {roleManager() ? (
                          <FormGroup className="mb_50">
                            <InputGroup className="d_flex_align">
                              <Input
                                className="radio_button mt_0 mr_10 point"
                                placeholder=""
                                type="radio"
                                value="host"
                                id="propertyOwner"
                                checked={this.state.paidBy === "host"}
                                onChange={(e) =>
                                  this.handleRadioChange(e, "paidBy")
                                }
                              />
                              <label
                                className="form-control-label label-in mb_0 point "
                                htmlFor="propertyOwner"
                              >
                                Property Owner should pay the money
                              </label>
                            </InputGroup>
                          </FormGroup>
                        ) : (
                          ""
                        )}
                      </div>
                      {this.state.assignTo === "self" && (
                        <div>
                          <h2 className="ft_family mb-10 schedule">
                            Schedule On*
                          </h2>
                          <DatePicker
                            selected={this.state.scheduleOn}
                            minDate={new Date()}
                            dateFormat="MM-dd-yyyy"
                            placeholderText=""
                            className="scheduleDate  point"
                            onChange={this.handleScheduleOnChange}
                            name="scheduleOn"
                            id="scheduleOn"
                            value={this.state.scheduleOn}
                            style={{ fontWeight: "500" }}
                            showYearDropdown
                            showTimeSelect
                            showMonthDropdown
                            ariaDescribedBy="basic-addon2"
                          />
                        </div>
                      )}
                      <div className="buttons_with_back">
                        <div className="stage_buttons">
                          <Button
                            className="button_without_border  no_shadow p_0 mr_35"
                            onClick={() => this.goBack("1")}
                          >
                            Back
                          </Button>
                        </div>
                        {this.state.assignTo === "self" ? (
                          <div className="stage_buttons">
                            <Button
                              className="button-wrapping ft_white mr_0 mb_0"
                              onClick={() => this.assignRequest()}
                            >
                              {assignLoad ? (
                                <div className="spin-loading-save w100 h100">
                                  <Spinner type="border" color="#012A4D" />
                                </div>
                              ) : (
                                "Assign"
                              )}
                            </Button>
                          </div>
                        ) : (
                          <div className="">
                            <Button
                              className="button-wrapping ft_white mr_0 mb_0"
                              onClick={() => this.switchStage("3")}
                            >
                              Next
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {contractorAssignStage === "3" &&
                    assignTo === "contractor" && (
                      <div className="contractor_assign_listing fade_effect ">
                        <div className="mb_20">
                          <FormGroup className="mb_50">
                            <InputGroup className="d_flex_align">
                              <Input
                                className="search_contractor"
                                type="text"
                                value={this.state.contractor_search}
                                name="contractor_search"
                                placeholder="Search for Contractor"
                                onChange={this.searchfilter}
                              />
                              <i className="fa fa-search"></i>
                            </InputGroup>
                          </FormGroup>
                        </div>
                        <h2 className="ft_family ft_15 ft_500">
                          Select the contractor for the work{" "}
                        </h2>
                        <div className="contractor_listing mb_20">
                          {this.state.contractor_list &&
                            this.state.contractor_list.map((item, i) => (
                              <div
                                key={i}
                                className="individual_contractor cursor_pointer"
                                onClick={() => this.selectContractor(i, item)}
                              >
                                { }
                                {/* <img
                                className="contractor_image"
                                src={item.image ? item.image : contractorImage}
                              /> */}
                                <img
                                  className="contractor_image"
                                  src={
                                    item?.image
                                      ? getCloudinaryImage(
                                        90,
                                        90,
                                        item.image,
                                        "c_fill"
                                      )
                                      : contractorImage
                                  }
                                  alt=""
                                />
                                <div className="content_div">
                                  <h2 className="ft_family ft_600 ft_14 mb_0">
                                    {item.name}
                                  </h2>
                                  <p className="sub_text ft_12 mb_0">
                                    {item.text}
                                  </p>
                                </div>
                                <div className="rating">
                                  {item.rating && (
                                    <div className="rating_contractor">
                                      <img
                                        className="detail_icon"
                                        src={RatingStar}
                                        alt=""
                                      />
                                      <p className="ft_family">{item.rating}</p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            ))}
                        </div>{" "}
                        <div className="flex_end mt_30">
                          <Button
                            className="button_without_border no_shadow"
                            onClick={() => this.goBack("2")}
                          >
                            Back
                          </Button>{" "}
                          <Button
                            className="button-wrapping ft_white mb_0"
                            onClick={() => this.assignRequest()}
                          >
                            {assignLoad ? (
                              <div className="spin-loading-save w100 h100">
                                <Spinner type="border" color="#012A4D" />
                              </div>
                            ) : (
                              "Assign"
                            )}
                          </Button>
                        </div>
                      </div>
                    )}
                </div>{" "}
              </>
            )}
          </Card>

          <Card
            className={
              commentOpenUp
                ? "invoice-sidebar payoutSideWidth"
                : "toggle-sidebar"
            }
          >
            {commentOpenUp && (
              <Chat
                close={this.toggleChatModal}
                props={this.props}
                data={this.state.requestData}
                status={requestData.status}
                requestId={request_id}
              ></Chat>
            )}
          </Card>
        </Container>
      </>
    );
  }
}
export default RequestDetail;
