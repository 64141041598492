import mobileRespPhoto from "../../assets/img/brand/mobileRespPhoto.svg"
import appleLogo from "../../assets/img/brand/apple.svg"
import playstoreLogo from "../../assets/img/brand/playstoreLogoHost.svg"
import logoPark from "../../assets/img/new Logo/host_new_template.svg"

const downloadMobileApp = () => {

    return (

        <div className="mobRespFeild">
            <div className="mobRespIcon">
                <img src={mobileRespPhoto} className="mobileRespPic" />
                <img src={logoPark} className="calenderSpin mobRespLogo" />
            </div>
            <div className="mobileRespContent">
                <h2>Download our app</h2>
                <p>For better experience, we would request you <br />
                    to use Parknstays mobile app</p>
            </div>
            <div className="MobButtons">
                <button className="iphoneBtn" onClick={() => window.open("https://apps.apple.com/in/app/parknstays-host/id6446321336")}> <img src={appleLogo} />Download Now</button>
                <button className="playstoreBtn" onClick={() => window.open("https://play.google.com/store/apps/details?id=com.parknstays.host")}><img src={playstoreLogo} />Download Now</button>
            </div>
        </div >
    )
}

export default downloadMobileApp;
