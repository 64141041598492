import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Card,
  Table,
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  InputGroup,
  Dropdown,
  Input,
  Spinner,
  CustomInput,
  Media,
  Modal,
} from "reactstrap";

// core components
import ApiService from "constants/ApiService";
import { returnFirstDegreeObjValue } from "constants/utils";
import { isInvalidEmail } from "constants/utils";
import { displayPrice } from "constants/utils";
import { roleType } from "constants/utils";
import SendInvite from "components/Modal/SendInvite";
import ToastList from "components/Toast/ToastList";
import showMore from "../../assets/img/brand/showMore.svg";
import ReactTooltip from "react-tooltip";
import returnSecondDegreeObjValue, {
  buildQueryString,
  capitalizeFirstLetter,
  checkManageBy,
  dropDownToggle,
  getCloudinaryImage,
  getDefaultProperty,
  pushToFilteredPage,
  returnSearchObject,
  returnSortKey,
  showError,
  showSuccess,
  showUtcDate,
  userTypeQuery,
} from "../../constants/utils";
import { RoleBasedCRUDAccess } from "views/Roles/RolesUtils";
import moment from "moment";
import DeleteModal from "./DeleteModal";
import hostIcon from "../../assets/img/brand/HostIcon.svg";
import managerIcon from "../../assets/img/brand/ManagerIcon.svg";
import hostManagerActive from "../../assets/img/brand/hostAndManagerActive.svg";
import managerInactive from "../../assets/img/brand/ManagerIconInactive.svg";
import hostInactive from "../../assets/img/brand/hostInactive.svg";
import hostManagerInactive from "../../assets/img/brand/hostManagerInactive.svg";
import ExportPropertySideBar from "./ExportPropertySideBar";
import PropertyHistory from "./PropertyHistorySidebar";
import { getUSFormat } from "../../constants/utils";
import { replaceToNormalNumber } from "../../constants/utils";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { AsscendingDecending } from "components/FilterFields/Filterfields";
import { getCloseIcon } from "antd/es/notification/PurePanel";
import singleHostSharing from "../../assets/img/brand/singleHostShare.svg"
import multiHostSharing from "../../assets/img/brand/multiHostShare.svg"
import AddDocument from "views/Property/DocumentHistory/AddDocument";

let manager_id;
class Properties extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    manager_id = this.props.location?.manager_id;
    this.state = {
      propertyList: [],
      total: 0,
      page: 1,
      limit: 10,
      filterToggle: false,
      modalOpen: false,
      toggleOptionView: false,
      inputSearch: "",
      filterOption: "name",
      statusSearch: "",
      check: false,
      email: "",
      actual_start: "",
      des: "",
      property_id: "",
      invite_id: "",
      link: false,
      url: "",
      toast: false,
      response: "",
      toastType: "",
      isLoading: true,
      inviteStatus: "",
      managedBy: "",
      deleteModal: false,
      property_name: "",
      exportModal: false,
      query: {},
      inviteData: "",
      historyModal: false,
      phoneNumber: "",
      sortingList: "",
      screeninglist: [
        { name: "Required", value: true },
        { name: "Not Required", value: false },
      ],
      selectedScreening: "",
      is_enabled: true,
      timeoutId: null,
      input_value: false,
      keyValue: "",
      host_detail: ""
    };
  }

  reloadPage = () => {
    this.props.history.listen((location, search) => {
      if (location.search === "") {
        this.setState({ query: {}, is_enabled: true }, () => {
          let query = this.props.location.search;
          query = returnSearchObject(query);
          if (Object.keys(query).length === 0) {
            this.handleReset();
          }
        });
      } else {
        this.state.input_value = true
      }
    });
  };

  componentDidMount() {
    this.setState({ selectedScreening: "Not Required" });
    this.setQueryStatus();
    let retrievedObject = localStorage.getItem('host_details');
    let parsedObject = JSON.parse(retrievedObject);
    this.setState({ host_detail: parsedObject.host })
  }

  handleState = (value) => {
    this.setState({ selectedScreening: value });
  };

  send() { }

  setQueryStatus = () => {
    let default_property = getDefaultProperty();
    let queryStatus = this.props.location.search;
    if (queryStatus) {
      let queryObj = returnSearchObject(queryStatus);
      this.setState(
        {
          inputSearch: queryObj.name ?? "",
          statusSearch: queryObj.is_occupied ?? "",
          managedBy: queryObj.managed_by ?? "",
          page: parseInt(queryObj.page) ?? "",
          query: queryObj,
        },
        () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getPropertyList();
        }
      );
    } else {
      this.setState(
        {
          is_enabled: true,
          query: {
            ...this.state.query,
            page: 1,
            limit: 10,
            is_enabled: true,
            property_id: default_property,
          },
        },
        () => {
          pushToFilteredPage(this.props, this.state.query);
          this.getPropertyList();
        }
      );
    }
  };

  getPropertyList = async () => {
    let response = await ApiService.getAPI(`host/properties${buildQueryString(this.state.query)}&${userTypeQuery()}`);
    if (response.success) {
      let { properties, total } = response.data;
      this.setState({
        propertyList: properties,
        total: total,
        isLoading: false,
      });
    } else {
      this.setState({ isLoading: false });
      showError(this, response.message);
    }
  };
  // Toast Handler

  pageChange = (page) => {
    var self = this;
    self.setState({ page: page }, () => {
      self.getPropertyList();
    });
  };

  redirectAddPage = () => {
    this.props.history.push("/admin/properties/add");
  };

  redirectRooms = ({ id, name }) => {
    this.props.history.push(`rooms/${id}?name=${name}`);
  };

  redirectEditProperty = (property_id) => {
    this.props.history.push(`/admin/properties/update/${property_id}`);
  };

  redirectPurchaseHistory = (property_id, history) => {
    this.props.history.push(`/admin/properties/${history}/${property_id}`);
  };

  redirectMaintenanceHistory = (property_id) => {
    this.props.history.push(`/admin/properties/maintenance/${property_id}`);
  };
  redirectDocument = (property_id) => {
    this.props.history.push(`/admin/properties/document/${property_id}`);
  };

  onChangeHandler = (e) => {
    e.target.value === "" && this.handleReset();
    this.setState({ [e.target.name]: e.target.value, input_value: true });
    clearTimeout(this.state.timeoutId);
    const timeoutId = setTimeout(() => {
      this.state.filterOption && this.handleSearch();
    }, 1000);
    this.setState({ timeoutId });
  };

  onChangeSelectHandler = (status) => {
    this.setState({ filterOption: status, input_value: true }, () => {
      this.handleFilterStatus();
    });
  }

  updateEnable = async (type, checkValue, id) => {
    var self = this;
    var data = {};
    checkValue ? (data[type] = false) : (data[type] = true);
    let response = await ApiService.patchAPI(
      `/host/properties/update/${id}`,
      data
    );
    if (response.success) {
      showSuccess(this, response.message);
      self.getPropertyList();
    } else {
      showError(this, response.message);
    }
  };

  handleIncrementPage = () => {
    if (this.state.propertyList.length >= 10) {
      this.setState(
        {
          page: this.state.page + 1,
        },
        () => {
          this.handleFilterStatus();
        }
      );
    }
  };
  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 }, () => {
        this.handleFilterStatus();
      });
    }
  };
  toggleModal = async (id, invite, type) => {
    if (invite) {
      let response = await ApiService.getAPI(`host/invite/${invite}`);
      if (response.success) {
        let data = response.data.invite;
        let phone = data.phone ? getUSFormat(data?.phone?.national_number) : "";
        this.setState(
          {
            email: data.email,
            inviteStatus: data.status,
            phoneNumber: phone,
            actual_start: moment(data.actual_start).toDate(),
            check: data.is_paid,
            des: data.description,
            inviteData: data,
            selectedScreening: data.screening,
          },
          () => { }
        );
      }
    } else {
      let response = await ApiService.getAPI(`host/properties/${id}`);
      if (response.success) {
        this.setState({
          selectedScreening: response.data.property.screening,
        });
      }
      this.setState({ inviteData: "", des: "Welcome to Parknstays" });
    }
    if (type === "entire_home") {
      this.setState({
        modalOpen: !this.state.modalOpen,
        property_id: id,
        inviteStatus: "",
        invite_id: invite,
      });
    }
  };
  handleOnCheckChange = () => {
    this.setState({ check: !this.state.check });
  };
  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleOnPhoneNumberChange = (evt) => {
    this.setState({ phoneNumber: getUSFormat(evt?.target.value) });
  };

  toggleCloseModal = () => {
    this.setState({
      modalOpen: false,
      link: false,
      url: "",
      email: "",
      actual_start: "",
      des: "",
      check: false,
      inviteData: "",
      phoneNumber: "",
    });
    this.getPropertyList();
  };

  handleOnNoticeChange = (e) => {
    this.updateStateValues("actual_start", e);
  };

  updateStateValues = (key, value) => {
    const updatedValue = value == null ? "" : value;
    this.setState({ [key]: updatedValue, input_value: true }, () => {
      if (this.state.from && this.state.to) {
        this.handleFilterStatus();
      }
    });
  };

  handleInvite = async () => {
    let {
      email,
      actual_start,
      des,
      property_id,
      check,
      invite_id,
      phoneNumber,
      selectedScreening,
    } = this.state;
    if (!email && !phoneNumber) {
      showError(this, "Please enter email or phone number");
    } else if (email && isInvalidEmail(email)) {
      showError(this, "Please enter valid email");
    } else if (phoneNumber && phoneNumber.length < 16) {
      showError(this, "Please enter valid phone number");
    } else if (actual_start === "") {
      showError(this, "Please select checkin date");
    } else {
      let data = {
        is_paid: check,
        actual_start: new Date(moment(actual_start).format("YYYY-MM-DD")),
        email,
        description: des,
        property_id,
        phone: {
          country_code: "1",
          national_number: replaceToNormalNumber(phoneNumber),
        },
        screening: selectedScreening,
      };

      let response = invite_id ? await ApiService.patchAPI(`host/invite/${invite_id}`, data)
        : await ApiService.postAPI(`host/invite`, data);
      if (response.success) {
        let { link } = response.data;
        showSuccess(this, response.message);
        this.setState({ link: true, url: link });
        this.getPropertyList();
      } else {
        showError(this, response.message);
      }
    }
  };

  copyLink = (url) => {
    navigator.clipboard.writeText(url);
    showSuccess(this, "Invite Link Copied");
  };

  toggleEnable = () => {
    this.setState({ is_enabled: !this.state.is_enabled }, () => {
      this.isEnabled();
    });
  };

  isEnabled = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    if (this.state.is_enabled) {
      staticQuery.is_enabled = this.state.is_enabled;
    } else delete staticQuery.is_enabled;
    let query = {
      page: 1,
      limit: 10,
    }
    if (staticQuery.is_enabled)
      query['is_enabled'] = staticQuery.is_enabled
    this.setState({ query: query }, () => {
      pushToFilteredPage(this.props, { ...this.state.query });
      this.getPropertyList();
    });
  };

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    if (this.state.inputSearch !== "" && this.state.filterOption !== "") {
      for (let key in staticQuery) {
        if (key === "name") { delete staticQuery[key] }
      }
      staticQuery[this.state.filterOption] = this.state.inputSearch;
    }
    if (!this.state.is_occupied) { staticQuery.is_occupied = this.state.statusSearch ?? "" }
    staticQuery.managed_by = this.state.managedBy ?? "";
    staticQuery.page = this.state.page ?? "";
    staticQuery.sort = this.state.sortingList ?? "";
    this.setState({ query: staticQuery }, () => {
      pushToFilteredPage(this.props, { ...this.state.query });
      this.getPropertyList();
    });
  };

  handleSubmit = () => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  handleReset = () => {
    let default_property = getDefaultProperty();
    let self = this;
    self.setState(
      {
        from: "",
        to: "",
        statusSearch: "",
        managedBy: "",
        filterOption: "name",
        inputSearch: default_property ? default_property : "",
        page: 1,
        is_enabled: true,
        sortingList: "",
        query: { page: 1, limit: 10, is_enabled: true, property_id: default_property },
        input_value: false,
        keyValue: "",
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getPropertyList();
      }
    );
  };

  setStatus = (status) => {
    this.setState(
      { statusSearch: status, page: 1, input_value: true }, () => { this.handleFilterStatus() }
    );
  };
  setManagedBy = (value) => {
    let staticQuery = returnSearchObject(this.props.location.search);
    staticQuery.managed_by = value ? value : "";

    this.setState(
      {
        managedBy: value,
        page: 1,
      },
      () => {
        this.handleFilterStatus();
      }
    );
  };
  toggleDropdown = (value) => {
    this.setState({ toggleOptionView: value });
  };
  openDropdown = () => {
    this.setState({ toggleOptionView: true });
  };
  closeDropdown = () => {
    this.setState({ toggleOptionView: false });
  };

  openDeleteModal = (id, name) => {
    this.setState({ deleteModal: true, property_id: id, property_name: name });
  };
  closeDeleteModal = () => {
    this.setState({ deleteModal: false });
  };

  deleteProperty = (msg) => {
    this.setState({ page: 1, deleteModal: false }, () =>
      this.getPropertyList()
    );
    showSuccess(this, msg);
  };

  openExportSideBar = () => {
    this.setState({ exportModal: true });
  };

  toastDisplay = (msg) => {
    showError(this, msg);
  };

  closeExportSideBar = (response) => {
    !response && this.setState({ exportModal: false });
    if (response) {
      if (response.success) {
        showSuccess(this, response.message);
        this.setState({ exportModal: false });
        this.handleReset();
      } else {
        showError(this, response?.message ?? response);
      }
    }
  };
  openHistorySideBar = (id) => {
    this.setState({ historyModal: true, property_id: id });
  };

  closeHistoryModal = () => {
    this.setState({ historyModal: false, property_id: "" });
  };

  checkView = (property_type) => {
    if (
      !RoleBasedCRUDAccess("property", "edit") &&
      !RoleBasedCRUDAccess("maintenance", "view") &&
      !roleType() &&
      property_type !== "entire_home"
    ) {
      return false;
    } else return true;
  };

  handleSearch = () => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  action = (key) => {
    let skey = returnSortKey(this, key);
    this.setState({ keyValue: skey, sortingList: skey, page: 1, input_value: true }, () => { this.handleFilterStatus(); })
  }

  render() {
    let {
      propertyList,
      page,
      inputSearch,
      filterOption,
      statusSearch,
      managedBy,
      modalOpen,
      isLoading,
      check,
      email,
      des,
      link,
      url,
      toast,
      response,
      inviteStatus,
      invite_id,
      toggleOptionView,
      toastType,
      deleteModal,
      property_name,
      property_id,
      exportModal,
      inviteData,
      historyModal,
      phoneNumber,
      sortingList,
      screeninglist,
      selectedScreening,
      input_value,
      host_detail
    } = this.state;
    let self = this;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#012A4D" />
      </div>
    ) : (
      <div onClick={(event) =>
        dropDownToggle(event, this.openDropdown, this.closeDropdown)
      }>
        {toast && <ToastList message={response} type={toastType} />}
        <CustomListingContainer>
          <ListingHeaderLayout label={"Properties"}>
            <div className="align-items-center justend">
              <div className="">
                {roleType() && (
                  <Button
                    // color="secondary"
                    size="sm"
                    className="secoundary-btn-wrapping button-wrapping"
                    onClick={() => this.openExportSideBar()}
                    style={{ boxShadow: "none !important" }}
                  >
                    Import
                  </Button>
                )}
                {roleType() && (
                  <Button
                    color="primary"
                    onClick={() => this.redirectAddPage()}
                    size="sm"
                    className="button-wrapping"
                  >
                    Add
                  </Button>
                )}
              </div>
            </div>
          </ListingHeaderLayout>
          <div style={{ margin: "20px" }}>
            <InputGroup className="flex-content align-left">
              <div className="filters_container">
                <div className="left_filter_container" style={{ width: "75%" }}>
                  <div className="select_with_input status_select_input ml-10">
                    <UncontrolledDropdown className="status_select_div ">
                      <DropdownToggle className="pr-0 drop_down_container">
                        <Media className="capitalize">
                          {filterOption === "name" && "Name"}
                          <i className="fas fa-angle-down" style={{ marginLeft: "20px" }}></i>
                        </Media>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" bottom style={{ minWidth: "130px" }}>
                        {" "}
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.onChangeSelectHandler("name")}
                        >
                          Name
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <Input
                      aria-label="Text input with dropdown button"
                      value={inputSearch}
                      name="inputSearch"
                      placeholder="search by"
                      onChange={this.onChangeHandler}
                    />
                  </div>
                  <div className="status_select mr-10">
                    <UncontrolledDropdown className="status_select_div">
                      <DropdownToggle className="pr-0 drop_down_container">
                        <Media className="capitalize">
                          {statusSearch === "true"
                            ? "Occupied"
                            : statusSearch === "false"
                              ? "Available"
                              : "All"}{" "}
                          <i className="fas fa-angle-down"></i>
                        </Media>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" bottom>
                        {" "}
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("")}
                        >
                          All
                        </DropdownItem>{" "}
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("false")}
                        >
                          Available
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("true")}
                        >
                          Occupied
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                  {roleType() && (
                    <div className="manageByFilter">
                      <div
                        onClick={() => this.setManagedBy("")}
                        className="point flexmid"
                        data-tip="Manage by both"
                        data-iscapture="true"
                        data-for="ManageByBoth"
                        data-place="top"
                      >
                        <img
                          src={
                            managedBy === ""
                              ? hostManagerActive
                              : hostManagerInactive
                          }
                          alt=""
                        />
                        <ReactTooltip
                          place="right"
                          type="info"
                          effect="solid"
                          id="ManageByBoth"
                          html={true}
                        />
                      </div>
                      <div className="verLine"></div>
                      <div
                        onClick={() => this.setManagedBy("myself")}
                        className="point flexmid"
                        data-tip="Manage by myself"
                        data-iscapture="true"
                        data-for="ManageBySelf"
                        data-place="top"
                      >
                        <img
                          src={managedBy === "myself" ? hostIcon : hostInactive}
                          alt=""
                        />
                        <ReactTooltip
                          place="right"
                          type="info"
                          effect="solid"
                          id="ManageBySelf"
                          html={true}
                        />
                      </div>
                      <div className="verLine"></div>

                      <div
                        onClick={() => this.setManagedBy("manager")}
                        className="point flexmid"
                        data-tip="Manage by manager"
                        data-iscapture="true"
                        data-for="ManageByManager"
                        data-place="top"
                      >
                        <img
                          src={
                            managedBy === "manager"
                              ? managerIcon
                              : managerInactive
                          }
                          alt=""
                        />
                        <ReactTooltip
                          place="right"
                          type="info"
                          effect="solid"
                          id="ManageByManager"
                          html={true}
                        />
                      </div>
                    </div>
                  )}
                </div>
                {input_value && (inputSearch || statusSearch || sortingList) &&
                  <div className="right_filter_container">
                    <Button
                      color="primary"
                      onClick={this.handleReset}
                      size="sm"
                      className="button-wrapping mr-4 button-secondary reset-btn"
                      style={{ fontSize: "15px" }}
                    >
                      Clear filter
                    </Button>
                  </div>
                }
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>Show Enabled</div>
                  <div style={{ marginLeft: "10px" }}>
                    <CustomInput
                      type="switch"
                      checked={this.state.is_enabled}
                      id="asd"
                      onChange={this.toggleEnable}
                    />
                  </div>
                </div>

              </div>
            </InputGroup>
          </div>
          <Row>
            <div className="col new-table">
              <Card className="shadow card_with_paginator">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      {/* {propertyList.length > 0 && (
                        <th scope="col" className="pad1015"></th>
                      )} */}
                      {/* <th scope="col">Street</th> */}
                      <th
                        scope="col"
                        className={propertyList.length > 0 ? "pl_40 justLeft point padLeft30" : "justLeft items-center point "}
                        onClick={() => this.action("pptname")}  >
                        <AsscendingDecending keyValue={this.state.keyValue} value="pptname" name="Name" />
                      </th>
                      {roleType() && <th scope="col">Manage by</th>}
                      <th scope="col">{roleType() ? "Manager" : "Host"}</th>
                      <th scope="col">Rent</th>
                      <th scope="col">Occupancy</th>
                      <th scope="col">Rooms</th>
                      <th scope="col">Invite</th>
                      <th scope="col">Enabled</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {propertyList.length !== 0 ? (
                      propertyList.map((property, i) => (
                        <tr key={i}>
                          {/* <td className="pad020 align_midd">
                            {property?.gallery.length > 0 ? (
                              <img
                                src={getCloudinaryImage(
                                  30,
                                  30,
                                  property?.gallery[0],
                                  "c_fill"
                                )}
                                className="propListImage"
                                alt=""
                              />
                            ) : (
                              <img
                                src={DefaultAmenity}
                                width="20px"
                                height="20px"
                                alt=""
                              />
                            )}
                          </td> */}
                          <td className="text-capitalize tdColumn tdColumn_23 padLeft30">
                            <div>
                              {property?.manager?.id ? (
                                returnFirstDegreeObjValue(property, "name")
                              ) : (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <Link
                                    to={`/admin/properties/property_detail/${property.property_id}`}
                                    style={{ color: "#000" }}
                                  >
                                    {returnFirstDegreeObjValue(property, "name")}
                                  </Link>
                                  <div
                                    data-tip=
                                    {(property?.host_id !== host_detail?.host_id && property?.sharedBy) ? "Shared with" + " " + property?.host?.name
                                      : (property?.host_id === host_detail?.host_id && property?.sharedBy) ? "Shared with" + " " + property?.sharedBy.map((host) => (host?.name)) : ""}
                                    data-iscapture="true"
                                    data-for="ManageByBoth"
                                    data-place="top"
                                  >
                                    {(property?.sharedBy && property?.sharedBy?.length > 0) &&
                                      <img
                                        src={(property?.host_id !== host_detail?.host_id && property?.sharedBy) ? singleHostSharing : multiHostSharing}
                                        alt=""
                                        style={{ marginLeft: "5px" }}
                                      />
                                    }
                                    <ReactTooltip
                                      place="right"
                                      type="info"
                                      effect="solid"
                                      id="ManageByBoth"
                                      html={true}
                                    />
                                  </div>


                                </div>
                              )}
                            </div>
                          </td>
                          {roleType() && (
                            <td>
                              {property?.manager?.id ? (
                                <div className="flexStart">
                                  <img
                                    src={managerIcon}
                                    className="mr-5"
                                    alt=""
                                  />
                                  <div>Manager</div>
                                </div>
                              ) : (
                                <div className="flexStart">
                                  <img src={hostIcon} className="mr-5" alt="" />
                                  <div>Myself</div>
                                </div>
                              )}
                            </td>
                          )}
                          <td>
                            {roleType()
                              ? returnSecondDegreeObjValue(
                                property,
                                "manager",
                                "name"
                              )
                              : returnSecondDegreeObjValue(
                                property,
                                "host",
                                "name"
                              )}
                          </td>
                          <td>
                            {roleType() &&
                              property.manager?.id &&
                              property.commission &&
                              property.type === "fixed_amount"
                              ? displayPrice(
                                returnFirstDegreeObjValue(
                                  property,
                                  "commission"
                                )
                              )
                              : property.price
                                ? displayPrice(
                                  returnFirstDegreeObjValue(property, "price")
                                )
                                : "-"}
                          </td>
                          <td
                            className={
                              checkManageBy(property)
                                ? "text-capitalize red general_status_style"
                                : property.is_occupied
                                  ? "text-capitalize red general_status_style"
                                  : "text-capitalize green general_status_style"
                            }
                          >
                            {checkManageBy(property)
                              ? "Occupied"
                              : returnFirstDegreeObjValue(property, "is_occupied") === true
                                ? "Occupied"
                                : "Available"}
                          </td>
                          <td
                            onClick={
                              RoleBasedCRUDAccess("property", "view") ? () =>
                                ((property?.manager &&
                                  property?.manager.id &&
                                  !roleType()) ||
                                  (roleType() &&
                                    !(
                                      property?.manager &&
                                      property?.manager.id
                                    ))) &&
                                this.redirectRooms({
                                  id: property?.property_id,
                                  name: property?.name,
                                })
                                : ""
                            }
                            style={{ cursor: "pointer" }}
                            className={
                              property?.rental_type === "entire_home"
                                ? "disable_button"
                                : "cursor-point"
                            }
                          >
                            {returnFirstDegreeObjValue(
                              property,
                              "rental_type"
                            ) === "by_room" ? (
                              <span>
                                {checkManageBy(property) ? "-" : "View"}
                              </span>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td
                            onClick={
                              RoleBasedCRUDAccess("property", "edit")
                                ? () =>
                                  ((roleType() &&
                                    !(
                                      property?.manager &&
                                      property?.manager.id
                                    )) ||
                                    !roleType()) &&
                                  (!property.is_occupied ||
                                    (property.check_out !== null &&
                                      !property.pre_booking)) &&
                                  property.is_enabled &&
                                  this.toggleModal(
                                    property?.property_id,
                                    property?.invite_id,
                                    property?.rental_type
                                  )
                                : ""
                            }
                            className="cursor-point"
                          >
                            {RoleBasedCRUDAccess("property", "edit") ? (
                              <>
                                {" "}
                                {checkManageBy(property)
                                  ? "-"
                                  : returnFirstDegreeObjValue(
                                    property,
                                    "rental_type"
                                  ) === "entire_home" &&
                                    property.is_enabled &&
                                    (!property.is_occupied ||
                                      (property.check_out !== null &&
                                        !property.pre_booking))
                                    ? property?.invite_id
                                      ? "View"
                                      : "Send"
                                    : "-"}
                              </>
                            ) : (
                              "-"
                            )}

                          </td>
                          <td>
                            <span
                              data-tip="you dont have access to edit property"
                              data-for="custom-switch"
                              data-place="top"
                              data-iscapture="true"
                              className="point property_flexmid"
                            >
                              <CustomInput
                                type="switch"
                                checked={property.is_enabled}
                                disabled={
                                  !RoleBasedCRUDAccess("property", "edit")
                                }
                                id={property.property_id}
                                onChange={() => {
                                  this.updateEnable("is_enabled", property.is_enabled, property.property_id);
                                }}
                              />
                              {!RoleBasedCRUDAccess("property", "edit") && (
                                <ReactTooltip
                                  place="top"
                                  type="info"
                                  effect="solid"
                                  id="custom-switch"
                                  html={true}
                                />
                              )}
                            </span>
                          </td>

                          <td>
                            <UncontrolledDropdown nav className="position_u">
                              {this.checkView(property.rental_type) && (
                                <>
                                  {" "}
                                  <DropdownToggle
                                    className="pr-0 nav-notice"
                                    nav
                                  >
                                    <Media className="align-items-center">
                                      <img src={showMore} alt="" className="" />
                                    </Media>
                                  </DropdownToggle>
                                </>
                              )}
                              <DropdownMenu
                                className="dropdown-menu-arrow min-width-drop"
                                style={{ zIndex: 1 }}
                                right
                              >

                                {((property?.host_id === host_detail?.host_id ||
                                  property?.manager?.id === host_detail?.manager_id) || (property.sharedBy && property?.sharedBy[0]?.edit_access)) &&
                                  <>
                                    {RoleBasedCRUDAccess("property", "edit") && (
                                      <>
                                        <DropdownItem
                                          onClick={() =>
                                            this.redirectEditProperty(
                                              property.property_id
                                            )
                                          }
                                        >
                                          <span>Edit</span>
                                        </DropdownItem>
                                        <DropdownItem divider />
                                      </>
                                    )}
                                  </>
                                }
                                {((roleType() && !property.manager?.id) ||
                                  (!roleType() && property.manager.id)) && (
                                    <div>
                                      {RoleBasedCRUDAccess(
                                        "maintenance",
                                        "view"
                                      ) && (
                                          <>
                                            {" "}
                                            <DropdownItem
                                              onClick={() => this.props.history.push(`/admin/properties/supply_list/${property.property_id}`)
                                              }
                                            >
                                              <span>Supply List</span>
                                            </DropdownItem>
                                            <DropdownItem divider />
                                          </>
                                        )}
                                      {RoleBasedCRUDAccess(
                                        "property",
                                        "edit"
                                      ) && (
                                          <>
                                            <DropdownItem
                                              onClick={() =>
                                                this.redirectPurchaseHistory(
                                                  property.property_id,
                                                  "appliance_history"
                                                )
                                              }
                                            >
                                              <span>Appliance History</span>
                                            </DropdownItem>
                                            <DropdownItem divider />
                                            <DropdownItem
                                              onClick={() =>
                                                this.redirectPurchaseHistory(
                                                  property.property_id,
                                                  "furniture_history"
                                                )
                                              }
                                            >
                                              <span>Furniture History</span>
                                            </DropdownItem>
                                            <DropdownItem divider />
                                            <DropdownItem
                                              onClick={() =>
                                                this.redirectPurchaseHistory(
                                                  property.property_id,
                                                  "purchase_history"
                                                )
                                              }
                                            >
                                              <span>Purchase History</span>
                                            </DropdownItem>
                                            {property.rental_type ===
                                              "entire_home" ||
                                              RoleBasedCRUDAccess(
                                                "maintenance",
                                                "view"
                                              ) ? (
                                              <DropdownItem divider />
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        )}
                                      {RoleBasedCRUDAccess(
                                        "maintenance",
                                        "view"
                                      ) && (
                                          <>
                                            {" "}
                                            <DropdownItem
                                              onClick={() =>
                                                this.redirectMaintenanceHistory(
                                                  property.property_id
                                                )
                                              }
                                            >
                                              <span>Maintenance Item</span>
                                            </DropdownItem>
                                            <DropdownItem divider />
                                          </>
                                        )}
                                    </div>
                                  )}
                                {
                                  <>
                                    {roleType()}
                                    <DropdownItem
                                      onClick={() =>
                                        this.openHistorySideBar(
                                          property.property_id
                                        )
                                      }
                                    >
                                      <span>Tenant History</span>
                                    </DropdownItem>
                                    <DropdownItem divider />
                                  </>
                                }
                                {(property.manager && property.manager.id) && (
                                  <>
                                    {" "}
                                    <DropdownItem
                                      onClick={() =>
                                        this.redirectDocument(
                                          property.property_id
                                        )
                                      }
                                    >
                                      <span>Documents</span>
                                    </DropdownItem>
                                    <DropdownItem divider />
                                  </>
                                )}
                                {roleType() && (
                                  <div>
                                    <DropdownItem
                                      onClick={() =>
                                        this.openDeleteModal(
                                          property.property_id,
                                          property.name
                                        )
                                      }
                                    >
                                      <span>Delete</span>
                                    </DropdownItem>
                                  </div>
                                )}

                              </DropdownMenu>
                            </UncontrolledDropdown>

                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="no-data" style={{ width: "150px" }}>No Properties Available</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        {/* <td></td>
                        <td></td> */}
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card>
              <SendInvite
                toggleModal={() => this.toggleModal()}
                modalState={modalOpen}
                closeModal={() => this.toggleCloseModal()}
                updatePropertyList={() => this.getPropertyList()}
                onSubmit={() => this.handleInvite()}
                onInputChange={this.handleInputChange}
                onChangeDate={this.handleOnNoticeChange}
                actual_start={this.state.actual_start}
                check={check}
                email={email}
                status={inviteStatus}
                onCheckChange={this.handleOnCheckChange}
                description={des}
                invite_id={invite_id}
                link={link}
                url={url}
                copyLink={() => this.copyLink(url)}
                data={inviteData}
                property_id={property_id}
                phoneNumber={phoneNumber}
                handleOnPhoneNumberChange={this.handleOnPhoneNumberChange}
                screeninglist={screeninglist}
                selectedScreening={selectedScreening}
                change={this.handleState}
              />
            </div>
          </Row>
          <Row className="align-items-center jus-space new-margin-page paginator_box">
            <div className="paginate-flex-box ">
              <div
                className="paginate-box"
                onClick={() => this.handleDecrementPage()}
              >
                <p
                  className={page !== 1 ? "page_available" : "page_unavailable"}
                >
                  Previous
                </p>
              </div>
              <div
                className="paginate-box"
                onClick={() => this.handleIncrementPage()}
              >
                <p
                  className={
                    propertyList.length < 10
                      ? "page_unavailable"
                      : "page_available"
                  }
                >
                  Next
                </p>
              </div>
            </div>
          </Row>
        </CustomListingContainer>
        <Modal
          isOpen={deleteModal}
          className="w450 modal-dialog-notice"
          toggle={() => this.closeDeleteModal()}
          centered
        >
          <DeleteModal
            close={this.closeDeleteModal}
            delete={this.deleteProperty}
            propId={property_id}
            propName={property_name}
          />
        </Modal>
        <Card className={exportModal ? "exportSideBar" : "toggle-sidebar"}>
          <ExportPropertySideBar
            close={this.closeExportSideBar}
            open={exportModal}
            toast={this.toastDisplay}
          />
        </Card>
        <Card className={historyModal ? "exportSideBar" : "toggle-sidebar"}>
          <PropertyHistory
            close={this.closeHistoryModal}
            open={historyModal}
            toast={this.toastDisplay}
            id={property_id}
          />
        </Card>
        {/* </Container> */}
      </div >
    );
  }
}

export default Properties;
