//Constants
import Constants from "./Env";
import { redirectLoginPage, getAuthToken } from "./utils";

class APIService {
  signInUrl = Constants.BASE_URL + "host/img_sign";
  cloudinary_base_url = "https://api.cloudinary.com/v1_1/";
  response_url = "https://res.cloudinary.com/parknstays/image/upload";

  constructor() {
    let token = getAuthToken();
    this.headersAuthorization = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      // "x-consumer-username": token
    };
  }
  triggerResponse = async (response) => {
    if (response.status === 401)
      redirectLoginPage();
    return await response.json();
  };
  createInstance(method, data) {
    return {
      method,
      headers: this.headersAuthorization,
      body: JSON.stringify(data),
    };
  }

  uploadInstance(method, data) {
    return {
      method,
      headers: { Authorization: "Bearer " + getAuthToken() },
      body: data,
    };
  }

  uploadDoc = async (url, data) => {
    const response = await fetch(
      Constants.BASE_URL + url,
      this.uploadInstance("POST", data)
    );
    return await response.json();
  };

  getAPI = async (url) => {
    const response = await fetch(
      Constants.BASE_URL + url,
      this.createInstance("GET")
    );
    return this.triggerResponse(response);
  };

  dowloadImage = async (url) => {
    await fetch(Constants.BASE_URL + url, this.createInstance("GET"));
  };

  postAPI = async (url, data) => {
    const response = await fetch(
      Constants.BASE_URL + url,
      this.createInstance("POST", data)
    );
    return this.triggerResponse(response);
  };

  patchAPI = async (url, data) => {
    const response = await fetch(
      Constants.BASE_URL + url,
      this.createInstance("PATCH", data)
    );
    return this.triggerResponse(response);
  };

  deleteAPI = async (url) => {
    const response = await fetch(
      Constants.BASE_URL + url,
      this.createInstance("DELETE")
    );
    return this.triggerResponse(response);
  };
}

export default new APIService();
