import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ApiService from "constants/ApiService";
let opposite_val = false;
export const getNotifyCount = createAsyncThunk("getNotifyCount", async () => {
  let response = await ApiService.getAPI("host/notification/count");
  let data = response?.data?.notifications;

  if (response.success) {
    return data;
  } else {
    return 0;
  }
});

export const getNotifyList = createAsyncThunk("getNotifyList", async () => {
  let response = await ApiService.getAPI(
    `host/notification?page=1&limit=5&read=0`
  );
  let data = response?.data?.notifications;
  if (response.success) {
    return data;
  } else {
    return [];
  }
});

export const counterSlice = createSlice({
  name: "counter",
  initialState: {
    notify_count: 0,
    notify_list: [],
    request_type: "",
    property_update: 1,
  },
  reducers: {
    updatePropertyList(state, action) {
      state.property_update = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getNotifyCount.pending, (state) => {
      state.notify_count = 0;
    });
    builder.addCase(getNotifyCount.fulfilled, (state, action) => {
      state.notify_count = action.payload;
    });

    builder.addCase(getNotifyCount.rejected, (state) => {
      state.notify_count = 0;
    });

    builder.addCase(getNotifyList.pending, (state) => {
      state.notify_list = [];
    });
    builder.addCase(getNotifyList.fulfilled, (state, action) => {
      state.notify_list = action.payload;
    });

    builder.addCase(getNotifyList.rejected, (state) => {
      state.notify_list = [];
    });
  },
});

// Action creators are generated for each case reducer function
// export const { getNotifyCount } = counterSlice.actions;
export const { updatePropertyList } = counterSlice.actions;
export default counterSlice.reducer;
