import React, { Component } from "react";
import {
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  InputGroup,
  Dropdown,
  Input,
  Spinner,
  Modal,
  CardBody,
  FormGroup,
  InputGroupText,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  ModalHeader,
  ModalBody,
} from "reactstrap";
// core components
import ApiService from "constants/ApiService";
import { returnFirstDegreeObjValue } from "constants/utils";
import returnSecondDegreeObjValue, {
  removeCookie,
  returnDownloadGet,
  returnSortKey,
  setCookie,
} from "../../constants/utils";
import "react-datepicker/dist/react-datepicker.css";
import { displayPrice } from "constants/utils";
import { getCookie } from "constants/utils";
import { getIsoString } from "constants/utils";
import ToastList from "components/Toast/ToastList";
import error from "../../assets/img/brand/error.svg";
import Lottie from "react-lottie";
import VerifySuccess from "../../assets/Lotties/successLottie.json";
import ProcessingLottie from "../../assets/Lotties/processingLottie.json";
import maintenanceInvoice from "../../assets/img/brand/maintenanceInvoice.svg";
import requestInvoice from "../../assets/img/brand/requestInvoice.svg";
import rentInvoice from "../../assets/img/brand/rentInvoice.svg";
import sourceRentInvoice from "../../assets/img/brand/sourceRentInvoice.svg";
import PayMethod from "./payMethod";
import PaymentDetails from "./paymentDetails";
import ReactTooltip from "react-tooltip";
import {
  buildQueryString,
  calculatePercentage,
  getDefaultProperty,
  pushToFilteredPage,
  redirectToBookings,
  returnSearchObject,
  showError,
  showLocaldate,
  getAuthToken,
} from "../../constants/utils";
import timelineIcon from "../../assets/img/brand/invoiceTimeline.svg";
import PaymentTimeline from "./paymentTimeline";
import { RoleBasedCRUDAccess } from "views/Roles/RolesUtils";
import TotalInvoice from "../../../src/assets/img/brand/headerTotalInvoice.svg";
import CancelledInvoice from "../../../src/assets/img/brand/cancelledInvoice.svg";
import UnpaidInvoice from "../../../src/assets/img/brand/unpaidInvoice.svg";
import PaidInvoice from "../../../src/assets/img/brand/paidInvoice.svg";
import CountUp from "react-countup";
import showMore from "../../../src/assets/img/brand/showMore.svg";
import Env from "../../constants/Env";
import { returnCustomerFirsLastName, returnRole } from "constants/utils";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { CustomDatePicker } from "components/FilterFields/Filterfields";
import { CustomSearch } from "components/FilterFields/Filterfields";
import { CustomDropDown } from "components/FilterFields/Filterfields";
import { AsscendingDecending } from "components/FilterFields/Filterfields";
import PaymentIframe from "./paymentIframe";

let role;
class Invoices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceList: [],
      total: 0,
      from: "",
      to: "",
      page: 1,
      paymentPageLoading: false,
      inputSearch: "",
      isPayOverAll: false,
      pendingAmount: 0,
      filterOption: "host_payment",
      statusSearch: "",
      typeSearch: "",
      isLoading: true,
      lateFee: 0,
      toast: false,
      response: "",
      toastType: "",
      openPaymentModal: false,
      payment: "success",
      invoice_amount: "",
      transitionId: "",
      popupLoading: true,
      invoiceDetail: "",
      invoiceDetailsModal: false,
      invoiceDetailsData: "",
      payMethodModal: false,
      paymentIframeModal: false,
      invoiceData: "",
      invoiceId: "",
      invoiceStatusView: "",
      timelineModal: false,
      timelineData: "",
      query: {},
      statsData: {},
      sortingList: "",
      timeoutId: null,
      timeout: null,
      input_value: false,
      keyValue: ""
    };
  }

  reloadPage = () => {
    this.props.history.listen((location, search) => {
      if (location.search === "") {
        this.setState({ query: {} }, () => {
          let query = this.props.location.search;
          query = returnSearchObject(query);
          if (Object.keys(query).length === 0) {
            this.handleReset();
          }
        });
      } else {
        this.state.input_value = true
      }
    });
  };

  componentDidMount = async () => {
    role = returnRole();
    this.reloadPage();
    this.getDetails();
    this.setQueryStatus();

    // this.getInvoiceList();
  };

  // componentDidUpdate(prevProps) {
  //   let search = this.props.location.search;
  //   if (prevProps.location.search !== search) {
  //     this.setQueryStatus();
  //   }
  // }

  getDetails = async () => {
    let queryStatus = this.props.location.search;
    let queryObj = returnSearchObject(queryStatus);
    if (queryStatus) {
      if (queryObj.pay_status === "success") {
        this.setState({ openPaymentModal: true }, () => {
          setTimeout(() => this.getData(), 10000);
        });
      } else if (queryObj.pay_status === "failure") {
        this.setState({ payment: "failure", popupLoading: false }, () => {
          this.openModal();
        });
      }
    }
  };

  setQueryStatus = () => {
    let queryStatus = this.props.location.search;
    let default_property = getDefaultProperty();
    if (queryStatus) {
      let queryObj = returnSearchObject(queryStatus);
      this.setState(
        {
          typeSearch: (queryObj.type || ""),
          inputSearch: (queryObj.host_payment || queryObj.propname || queryObj.invoice_id) ?? "",
          filterOption: queryObj.host_payment ? "host_payment"
            : queryObj.propname ? "propname"
              : queryObj.invoice_id && "invoice_id",
          from: queryObj.from ? new Date(queryObj.from) : "",
          to: queryObj.to ? new Date(queryObj.to) : "",
          statusSearch: (queryObj.status || ""),
          page: parseInt(queryObj.page) ?? "",
          query: queryObj,
        },
        () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getInvoiceList();
          this.getRequestStats();
        }
      );
      if (
        queryObj.pay_status === "success" ||
        queryObj.pay_status === "failure"
      )
        delete queryObj["pay_status"];
    } else {
      this.setState(
        {
          query: {
            ...this.state.query,
            page: 1,
            limit: 10,
            from: getIsoString(this.state.from),
            to: getIsoString(this.state.to),
            property_id: default_property,
          },
          // inputSearch: default_property ? default_property : "",
          // filterOption: default_property ? "property_id" : "host_payment",
        },
        () => {
          pushToFilteredPage(this.props, this.state.query);
          this.getInvoiceList();
          this.getRequestStats();
        }
      );
    }
  };

  getData = async () => {
    let id = getCookie("invoice_id");
    let response = await this.getTransactionDetails(id)
    this.handleTransactionDetails(response)
  };

  getTransactionDetails = id => ApiService.getAPI(`host/transactions/${id}`)

  handleTransactionDetails = (response) => {
    if (response.success) {
      if (response.data.transaction.status === "paid") {
        this.setState(
          {
            payment: "success",
            invoiceDetail: response.data.transaction,
            popupLoading: false,
          },
          () => {
            setTimeout(() => this.closePaymentModal(), 10000);
          }
        );
      } else if (response.data.transaction.status === "processing") {
        this.setState(
          {
            payment: "processing",
            popupLoading: false,
          },
          () => {
            setTimeout(() => this.closePaymentModal(), 10000);
          }
        );
      } else {
        this.setState({
          payment: "failure",
          popupLoading: false,
        });
      }
    }
    removeCookie('invoice_id')
  }

  onChangeHandler = (e) => {
    e.target.value === "" && this.handleReset();
    this.setState({ [e.target.name]: e.target.value, input_value: true });
    clearTimeout(this.state.timeoutId);
    const timeoutId = setTimeout(() => {
      this.state.filterOption && this.handleSearch();
    }, 1000);
    this.setState({ timeoutId });
  };
  onChangeSelectHandler = (status) => {
    this.setState({ filterOption: status, input_value: true }, () => {
      this.handleFilterStatus();
    });
  }
  handleSearch = () => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
  };
  getInvoiceList = async () => {
    this.setState({ paymentPageLoading: true });
    let { from, to, } = this.state;
    if (from > to) {
      showError(this, "Please provide valid From and To date");
    } else {
      let response = await ApiService.getAPI(
        `host/invoice/payment${buildQueryString(this.state.query)}`
      );
      this.setState({ paymentPageLoading: false });
      if (response.success) {
        let { invoices } = response.data;
        let pending = response.data?.pending;
        if (pending?.amount) {
          this.setState({ pendingAmount: pending.amount, isPayOverAll: true });
        } else { this.setState({ isPayOverAll: false }) }
        this.setState({ invoiceList: invoices, isLoading: false });
      } else {
        this.setState({ isLoading: false });
        showError(this, response.message);
      }
    }
  };

  getRequestStats = async () => {
    let default_property = getDefaultProperty();
    let { from, to } = this.state;
    let queryParams = {};
    if (from !== "") queryParams.from = getIsoString(from);
    if (to !== "") queryParams.to = getIsoString(to);
    if (default_property !== "") queryParams.property_id = default_property;
    let response = await ApiService.getAPI(
      `host/invoice/pay_stats${buildQueryString(queryParams)}`
    );
    if (response.success) {
      this.setState({ statsData: response.data });
    } else {
      this.setState({ isLoading: false });
      showError(this, response.message);
    }
  };

  updateStateValues = (key, value) => {
    const updatedValue = value == null ? "" : value;
    this.setState({ [key]: updatedValue, input_value: true }, () => {
      if (this.state.from && this.state.to) {
        this.handleFilterStatus();
      }
    });
  };

  handleOnToChange = (e) => {
    this.updateStateValues("to", e);
  };

  handleOnFromChange = (e) => {
    this.updateStateValues("from", e);
  };

  showDetailModal = (id) => {
    this.setState({ invoiceDetailsModal: true, invoiceId: id });
  };

  openPayMethodModal = (data) => {
    this.setState({ payMethodModal: true, invoiceData: data });
  };

  closeInvoiceDetailModal = (res) => {
    this.handleTransactionResponse(res)
    this.setState({ payMethodModal: false });
  };

  openModal = () => {
    this.setState({ openPaymentModal: true }, () => {
      setTimeout(() => this.closePaymentModal(), 10000);
    });
  };

  closePaymentModal = () => {
    this.setState({ openPaymentModal: false }, () => {
      this.handleFilterStatus();
    });
  };

  handleIncrementPage = () => {
    if (this.state.invoiceList.length >= 10) {
      this.setState({ page: this.state.page + 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };

  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };
  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };


  handleFilterStatus = (card) => {
    let staticQuery = returnSearchObject(this.props.location.search);
    if (this.state.inputSearch !== "" && this.state.filterOption !== "") {
      for (let key in staticQuery) {
        if (
          key === "host_payment" ||
          key === "propname" ||
          key === "invoice_id"
        ) {
          delete staticQuery[key];
        }
      }
      staticQuery[this.state.filterOption] = this.state.inputSearch;
    }
    staticQuery.from = getIsoString(this.state.from) ?? "";
    staticQuery.to = getIsoString(this.state.to) ?? "";
    staticQuery.status = this.state.statusSearch ?? "";
    staticQuery.type = this.state.typeSearch ?? "";
    staticQuery.page = this.state.page ?? "";
    staticQuery.sort = this.state.sortingList ?? "";
    this.setState({ query: staticQuery }, () => {
      pushToFilteredPage(this.props, { ...this.state.query });
      this.getInvoiceList();
      this.getRequestStats();
    });
  };
  handleReset = () => {
    let default_property = getDefaultProperty();

    let self = this;
    self.setState(
      {
        from: "",
        to: "",
        statusSearch: "",
        filterOption: "host_payment",
        inputSearch: "",
        invoiceStatusView: "",
        page: 1,
        sortingList: "",
        typeSearch: '',
        query: {
          page: 1,
          limit: 10,
          property_id: default_property,
        },
        input_value: false,
        keyValue: ""
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        // this.handleFilterStatus();
        this.getInvoiceList()
      }
    );
  };
  search = () => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  onChangeValue(event) { }

  setStatus = (status, card) => {
    if (card === "card") {
      this.setState({ statusSearch: status, page: 1, typeSearch: '' }, () => {
        this.handleFilterStatus(card);
      });
    } else {
      this.setState({ statusSearch: status, page: 1, input_value: true }, () => {
        this.handleFilterStatus();
      });
    }
  };

  setType = (status) => {
    this.setState({ typeSearch: status, page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  setInvoiceStatusView = (status) => {
    let from;
    let to;
    this.setState({ invoiceStatusView: status });
    if (status === "tm") {
      from = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      to = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
    } else if (status === "lm") {
      from = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
      to = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
    } else if (status === "ty") {
      from = new Date(new Date().getFullYear(), 0, 1);
      to = new Date(new Date().getFullYear(), 11, 31);
    } else {
      from = "";
      to = "";
    }
    this.setState({ from, to, page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  displayTimeline = (data) => {
    this.setState({ timelineModal: true, timelineData: data });
  };

  closeTimeline = () => {
    this.setState({ timelineModal: false, timelineData: "" });
  };
  setSortingList = (status) => {
    this.setState({ sortingList: status, page: 1 }, () => {
      this.handleFilterStatus();
    });
  };
  downloadInvoicePdf = async (id) => {
    fetch(Env.BASE_URL + `host/invoice/pdf/${id}`, returnDownloadGet())
      .then((response) => {
        return response.blob();
      })
      .then((data) => {
        if (data.type === "application/json") {
          showError(this, "No records found to download");
        } else {
          const downloadLink = document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(data);
          downloadLink.setAttribute("download", `${id}.pdf`);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }
      });
  };
  action = (key) => {
    let skey = returnSortKey(this, key);
    this.setState({ keyValue: skey, sortingList: skey, page: 1, input_value: true }, () => { this.handleFilterStatus() })
  }
  handleTransactionResponse(res) {
    if (res.success) {
      setCookie("invoice_id", res.data.transaction_id);
      if (res.data.newTab) {
        this.setState({paymentIframeModal: true, iframeData:{
          heading: "Centz Payment Gateway",
          link: res.data.link
        }})

        let maxSeconds = 120000  // How many seconds should it try
        const apiInterval = 5000 // interval
        maxSeconds -= apiInterval
        const timeoutFunction = async () => {
          maxSeconds -= apiInterval
          const response = await this.getTransactionDetails(res.data.transaction_id)
          const status = response.data?.transaction?.status
          if (maxSeconds < 0 ||  (status && status!== 'pending')) {
            this.setState({openPaymentModal: true})
            this.handleTransactionDetails(response)
            return this.closePaymentIframe({success: true})
            // return this.dialogRef.close({success: true, data: response.data.data.transaction})
          }
          this.setState({timeout: setTimeout(timeoutFunction, apiInterval)})
        }
        this.setState({timeout: setTimeout(timeoutFunction , apiInterval)})

      } else window.open(res.data.link, "_self");
    }
  }

  closePaymentIframe = ({success}) => {
    if (success) return this.setState({paymentIframeModal: false})
    clearTimeout(this.state.timeout)
    this.setState({ paymentIframeModal: false, openPaymentModal: true})
    setTimeout(async () => {
      const id = getCookie('invoice_id')
      if (!id) return
      const response = await this.getTransactionDetails(id)
      this.handleTransactionDetails(response)
    }, 5000)
  }

  render() {
    let {
      invoiceList,
      inputSearch,
      filterOption,
      typeSearch,
      statusSearch,
      isLoading,
      page,
      toast,
      response,
      toastType,
      openPaymentModal,
      payment,
      invoice_amount,
      transitionId,
      popupLoading,
      invoiceDetail,
      directPay,
      cardPay,
      payMethodModal,
      paymentIframeModal,
      iframeData,
      invoiceDetailsModal,
      invoiceId,
      invoiceStatusView,
      timelineModal,
      timelineData,
      statsData,
      sortingList,
      paymentPageLoading,
      from,
      to,
      input_value
    } = this.state;

    const SuccessVerifyOptions = {
      loop: true,
      autoplay: true,
      animationData: VerifySuccess,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    const ProcessingOptions = {
      loop: true,
      autoplay: true,
      animationData: ProcessingLottie,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#012A4D" />
      </div>
    ) : (
      <>
        {toast && <ToastList message={response} type={toastType} />}
        <div style={{ margin: "24px 24px -24px 24px" }}>
          <div className="header-body">
            <Row className="row-center">
              <Col lg="6" xl="3" className="max-dash mb_mt_28 mb_mt_28 ">
                <Card
                  style={{ cursor: 'pointer' }}
                  className="card-stats mb-4 mb-xl-0 dash-new"
                  onClick={() => this.setStatus("", "card")}
                >
                  <CardBody className="pad-dash">
                    <div className="dash_content dash_content">
                      <div className="dash_left">
                        <div className="heading_with_badge">
                          <h4>OVERALL</h4>
                        </div>
                        <label className="number-con">
                          <CountUp
                            start={0}
                            end={returnSecondDegreeObjValue(
                              statsData,
                              "overall",
                              "total"
                            )}
                            separator=","
                            prefix="$"
                          />
                        </label>
                      </div>
                      <div className="col dash-col dash_right">
                        <img
                          src={TotalInvoice}
                          className="img-dash"
                          alt="total sales"
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="3" className="max-dash mb_mt_28">
                <Card
                  className="card-stats mb-4 mb-xl-0 dash-new"
                  onClick={() => this.setStatus("paid", "card")}
                  style={{ cursor: 'pointer' }}

                >
                  <CardBody className="pad-dash">
                    <div className="dash_content dash_content">
                      <div className="dash_left">
                        <div className="heading_with_badge">
                          <h4>PAID </h4>
                        </div>
                        <label className="number-con">
                          <CountUp
                            start={0}
                            end={returnSecondDegreeObjValue(
                              statsData,
                              "transferred",
                              "total"
                            )}
                            // end={statsData?.transferred?.total ?? "-"}
                            separator=","
                            prefix="$"
                          />
                        </label>
                      </div>
                      <div className="col dash-col dash_right">
                        <img
                          src={PaidInvoice}
                          className="img-dash"
                          alt="total sales"
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="3" className="max-dash mb_mt_28 ">
                <Card
                  className="card-stats mb-4 mb-xl-0 dash-new"
                  onClick={() => this.setStatus("created", "card")}
                  style={{ cursor: 'pointer' }}

                >
                  <CardBody className="pad-dash">
                    <div className="dash_content dash_content">
                      <div className="dash_left">
                        <div className="heading_with_badge">
                          <h4>PENDING </h4>
                        </div>
                        <label className="number-con">
                          <CountUp
                            start={0}
                            end={returnSecondDegreeObjValue(
                              statsData,
                              "pending",
                              "total"
                            )}
                            separator=","
                            prefix="$"
                          />{" "}
                        </label>
                      </div>
                      <div className="col dash-col dash_right">
                        <img
                          src={UnpaidInvoice}
                          className="img-dash"
                          alt="total sales"
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="3" className="max-  dash mb_mt_28 ">

                <Card
                  className="card-stats mb-4 mb-xl-0 dash-new"
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.setStatus("cancelled", "card")}
                >
                  <CardBody className="pad-dash">
                    <div className="dash_content dash_content">
                      <div className="dash_left">
                        <div className="heading_with_badge">
                          <h4>CANCELLED </h4>
                        </div>
                        <label className="number-con">
                          <CountUp
                            start={0}
                            end={returnSecondDegreeObjValue(
                              statsData,
                              "cancelled",
                              "total"
                            )}
                            separator=","
                            prefix="$"
                          />{" "}
                        </label>
                      </div>
                      <div className="col dash-col dash_right">
                        <img
                          src={CancelledInvoice}
                          className="img-dash"
                          alt="total sales"
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <CustomListingContainer>
          <ListingHeaderLayout label={"Payments"}>
            <div className="justend">
              <div
                className="flexEnd invoiceStatusView"
                style={{ width: "170px", marginLeft: "15px" }}
              >
                <UncontrolledDropdown className="status_select_div ">
                  <DropdownToggle className="pr-0 drop_down_container">
                    <Media className="capitalize">
                      {invoiceStatusView === "tm"
                        ? "This Month"
                        : invoiceStatusView === "lm"
                          ? "Last Month"
                          : invoiceStatusView === "ty"
                            ? "This Year"
                            : "All"}
                      <i className="fas fa-angle-down"></i>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" bottom>
                    {" "}
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.setInvoiceStatusView("")}
                    >
                      All
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.setInvoiceStatusView("tm")}
                    >
                      This Month
                    </DropdownItem>{" "}
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.setInvoiceStatusView("lm")}
                    >
                      Last Month
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.setInvoiceStatusView("ty")}
                    >
                      This Year
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          </ListingHeaderLayout>
          <div style={{ margin: "20px" }}>
            <InputGroup className="flex-content align-left">
              <div className="filters_container">
                <div className="left_filter_container">
                  <div className="w30">
                    <CustomDatePicker
                      from={from}
                      to={to}
                      fromChange={this.handleOnFromChange}
                      toChange={this.handleOnToChange}
                    ></CustomDatePicker>
                  </div>
                  {/* <div
                    style={{ width: "27%" }}
                    className="select_with_input ml-10"
                  >
                    <Dropdown variant="input-group" className="select_input_drop">
                      <select
                        id="inputGroupSelect01"
                        className="form_select fixed_select point"
                        name="filterOption"
                        value={filterOption}
                        onChange={this.onChangeHandler}
                      >
                        <option value="host_payment">Host</option>
                        <option value="propname">Property</option>
                        <option value="invoice_id">Invoice Id</option>
                      </select>
                    </Dropdown>
                    <Input
                      aria-label="Text input with dropdown button"
                      className="input-filter-toggle"
                      value={inputSearch}
                      name="inputSearch"
                      onChange={this.onChangeHandler}
                      placeholder="Search by"
                    />
                  </div> */}
                  <div className="select_with_input status_select_input ml-10 mr-10">
                    <UncontrolledDropdown className="status_select_div ">
                      <DropdownToggle className="pr-0 drop_down_container">
                        <Media className="capitalize">
                          {filterOption === "host_payment" ? "Host"
                            : filterOption === "propname" ? "Property"
                              : filterOption === "invoice_id" ? "Invoice Id" : "Host"
                          }
                          <i className="fas fa-angle-down" style={{ marginLeft: "20px" }}></i>
                        </Media>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" bottom style={{ minWidth: "130px" }}>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.onChangeSelectHandler("host_payment")}
                        >
                          Host
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.onChangeSelectHandler("propname")}
                        >
                          Property
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.onChangeSelectHandler("invoice_id")}
                        >
                          Invoice Id
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <Input
                      aria-label="Text input with dropdown button"
                      value={inputSearch}
                      name="inputSearch"
                      placeholder="search by"
                      onChange={this.onChangeHandler}
                    />
                  </div>
                  <CustomDropDown
                    maxwidth={"130px"}
                    statusList={["all", "created", "paid", "cancelled"]}
                    status={statusSearch}
                    setStatus={this.setStatus}
                  />
                  <div className="status_select">
                    <UncontrolledDropdown
                      // style={{ width: "75%" }}
                      className="status_select_div"
                    >
                      <DropdownToggle className="pr-0 drop_down_container">
                        <Media className="capitalize">
                          {typeSearch === "" ? "All" : typeSearch === "source_rent" ? "Source Rent" : typeSearch}{" "}
                          <i className="fas fa-angle-down"></i>
                        </Media>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" bottom>
                        {" "}
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setType("")}
                        >
                          All
                        </DropdownItem>{" "}
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setType("request")}
                        >
                          Request
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setType("maintenance")}
                        >
                          Maintenance
                        </DropdownItem>
                        {role === "manager" && (
                          <DropdownItem
                            className="dropdown_item"
                            onClick={() => this.setType("source_rent")}
                          >
                            Source Rent
                          </DropdownItem>
                        )}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>
                {input_value && (inputSearch || statusSearch || typeSearch || (from || to) || sortingList || invoiceStatusView) &&
                  <div className="right_filter_container">
                    <Button
                      color="primary"
                      onClick={this.handleReset}
                      size="sm"
                      className="button-wrapping mr-4 button-secondary reset-btn"
                      style={{ fontSize: "15px" }}
                    >
                      Clear filter
                    </Button>
                  </div>
                }
              </div>
            </InputGroup>
          </div>

          <Row>
            <div className="col new-table">
              <Card className="shadow card_with_paginator">
                {!paymentPageLoading ? (
                  <>
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          {invoiceList.length > 0 ? (
                            <th scope="col" className="pad1015"></th>
                          ) : (
                            ""
                          )}
                          <th scope="col">Invoice Id</th>
                          <th scope="col" className="bnone" style={{ padding: "0px", display: "flex" }}>
                            <th
                              scope="col"
                              className="pl_40 justLeft items-center bnone point tdColumn_20"
                              style={{ display: "flex" }}
                              onClick={() => this.action("pname")}
                            >
                              <AsscendingDecending keyValue={this.state.keyValue} value="pname" name="Property" />
                            </th>
                            <th
                              scope="col"
                              className="pl_40 justLeft items-center bnone point tdColumn_20"
                              style={{ display: "flex" }}
                              onClick={() => this.action("hname")}
                            >
                              <AsscendingDecending keyValue={this.state.keyValue} value="hname" name="Host" />
                            </th>
                          </th>
                          <th scope="col">Contractor</th>
                          <th scope="col">Created On</th>
                          <th
                            scope="col"
                            className="pl_40 justLeft items-center bnone point"
                            style={{ display: "flex" }}
                            onClick={() => this.action("amount")}
                          >
                            <AsscendingDecending keyValue={this.state.keyValue} value="amount" name="Amount" />
                          </th>
                          <th scope="col">Status</th>
                          {invoiceList.length > 0 ? (
                            <>
                              <th scope="col" style={{ width: "10%" }}></th>
                            </>
                          ) : (
                            ""
                          )}
                          <th scope="col">
                            {this.state.isPayOverAll && (
                              <div
                                onClick={() =>
                                  this.openPayMethodModal({
                                    amount: this.state.pendingAmount,
                                  })
                                }
                                style={{ cursor: "pointer" }}
                              >
                                Pay Overall
                              </div>
                            )}
                          </th>
                        </tr>
                      </thead>
                      {invoiceList.length !== 0 ? (
                        <tbody>
                          {invoiceList.map((invoice, i) => (
                            <tr key={i}>
                              <td className="pad1015 ">
                                <div
                                  data-tip={
                                    invoice?.type === "source_rent"
                                      ? "Source Rent"
                                      : this.capitalizeFirstLetter(
                                        invoice?.type
                                      )
                                  }
                                  data-iscapture="true"
                                  data-for={String(i)}
                                  data-place="top"
                                  className="point flexmid"
                                >
                                  {invoice?.type ? (
                                    invoice?.type === "rental" ? (
                                      <img
                                        src={rentInvoice}
                                        id={invoice?.type}
                                      />
                                    ) : invoice?.type === "source_rent" ? (
                                      <img
                                        src={sourceRentInvoice}
                                        id={invoice?.type}
                                      />
                                    ) : invoice?.type === "request" ? (
                                      <img
                                        src={requestInvoice}
                                        id={invoice?.type}
                                      />
                                    ) : (
                                      invoice?.type === "maintenance" && (
                                        <img
                                          src={maintenanceInvoice}
                                          id={invoice?.type}
                                        />
                                      )
                                    )
                                  ) : (
                                    ""
                                  )}
                                  <ReactTooltip
                                    place="right"
                                    type="info"
                                    effect="solid"
                                    id={String(i)}
                                    html={true}
                                  />
                                </div>
                              </td>
                              <td
                                className="text-capitalize cursor-point"
                                onClick={() =>
                                  this.showDetailModal(invoice.invoice_id)
                                }
                              >
                                {returnFirstDegreeObjValue(
                                  invoice,
                                  "invoice_id"
                                )}
                              </td>
                              <td style={{ padding: "0px" }}>
                                <td className="cursor-point btopNone tdColumn tdColumn_20"
                                >
                                  {returnSecondDegreeObjValue(
                                    invoice,
                                    "property",
                                    "name"
                                  )}
                                </td>
                                <td className="btopNone tdColumn tdColumn_20"
                                >
                                  {invoice.contractor
                                    ? "-"
                                    : returnSecondDegreeObjValue(
                                      invoice,
                                      "host",
                                      "name"
                                    )}
                                </td>
                              </td>
                              <td className="text-capitalize cursor-point">
                                {returnSecondDegreeObjValue(
                                  invoice,
                                  "contractor",
                                  "name"
                                )}{" "}
                              </td>
                              <td>
                                {" "}
                                {showLocaldate(
                                  returnFirstDegreeObjValue(
                                    invoice,
                                    "createdAt"
                                  )
                                )}
                              </td>
                              <td className="tdColumn tdColumn_18">
                                {displayPrice(
                                  returnFirstDegreeObjValue(invoice, "amount")
                                )}
                              </td>
                              <td
                                className={
                                  invoice.status === "paid"
                                    ? "text-capitalize green general_status_style"
                                    : invoice.status === "created"
                                      ? "text-capitalize yellow general_status_style"
                                      : invoice.status === "cancelled"
                                        ? "text-capitalize red general_status_style"
                                        : "text-capitalize"
                                }
                              >
                                {returnFirstDegreeObjValue(invoice, "status")}
                              </td>
                              <td>
                                {invoice.status === "created" &&
                                  RoleBasedCRUDAccess("payment", "pay") && (
                                    <Button
                                      color="primary"
                                      className="button-wrapping paynow-btn"
                                      size="sm"
                                      onClick={() =>
                                        this.openPayMethodModal(invoice)
                                      }
                                    >
                                      Pay Now
                                    </Button>
                                  )}
                              </td>

                              < td >
                                <div className="flexStart" style={{ marginLeft: "25px" }}>
                                  <img
                                    src={timelineIcon}
                                    onClick={() =>
                                      this.displayTimeline(invoice?.timeline)
                                    }
                                    className="point"
                                    style={{ marginRight: "30px" }}
                                  />
                                  {invoice?.status === "created" ||
                                    invoice?.status === "paid" ? (
                                    <UncontrolledDropdown
                                      nav
                                      className="flexy-content "
                                    >
                                      <DropdownToggle
                                        className="pr-0 nav-notice"
                                        nav
                                      >
                                        <Media className="align-items-center">
                                          <img src={showMore} className="point" />
                                        </Media>
                                      </DropdownToggle>
                                      <DropdownMenu
                                        className="dropdown-menu-arrow min-width-drop"
                                        right
                                      >
                                        <DropdownItem
                                          onClick={() =>
                                            this.downloadInvoicePdf(
                                              invoice.invoice_id
                                            )
                                          }
                                        >
                                          <span>Download Invoice</span>
                                        </DropdownItem>
                                        {/* {invoice?.status === "created" || invoice?.status === "pending" ? (
                                    <DropdownItem
                                      onClick={() =>
                                        ""
                                      }
                                    >
                                      <span>Mark Payment</span>
                                    </DropdownItem>) : ("")} */}
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  ) : (
                                    ""
                                  )}</div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td className="no-data">No Payments Available</td>
                            <td style={{ width: "200px" }}></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      )}
                    </Table>
                  </>
                ) : (
                  <div className="spin-loading">
                    <Spinner type="border" color="#012A4D" />
                  </div>
                )}
              </Card>
            </div>
          </Row >
          <Row className="align-items-center jus-space new-margin-page paginator_box">
            <div className="paginate-flex-box ">
              <div
                className="paginate-box"
                onClick={() => this.handleDecrementPage()}
              >
                <p
                  className={page !== 1 ? "page_available" : "page_unavailable"}
                >
                  Previous
                </p>
              </div>
              <div
                className="paginate-box"
                onClick={() => this.handleIncrementPage()}
              >
                <p
                  className={
                    invoiceList.length < 10
                      ? "page_unavailable"
                      : "page_available"
                  }
                >
                  Next
                </p>
              </div>
            </div>
          </Row>
        </CustomListingContainer >
        <Modal
          isOpen={invoiceDetailsModal}
          toggle={() => this.setState({ invoiceDetailsModal: false })}
          className="modal-dialog-centered modal-dialog-invoice"
        >
          <PaymentDetails invoiceId={invoiceId} />
        </Modal>
        <Modal
          isOpen={payMethodModal}
          toggle={this.closeInvoiceDetailModal}
          className="modal-dialog-centered modal-dialog-paymentMethod"
        >
          {/* <ModalBody className="pad0p"> */}
          <PayMethod
            props={this.props}
            invoice_id={this.state.invoiceData?.invoice_id}
            amount={this.state.invoiceData?.amount}
            close={this.closeInvoiceDetailModal}
          />
          {/* </ModalBody> */}
        </Modal>
        <Modal className="max-w-80" isOpen={paymentIframeModal} toggle={() => this.closePaymentIframeModal()}>
          <PaymentIframe close={this.closePaymentIframe} data={iframeData} />
        </Modal>
        <Modal
          isOpen={openPaymentModal}
          className={
            payment === "success"
              ? "modal-dialog-payment-success modal-dialog-centered"
              : "modal-dialog-payment-failure modal-dialog-centered"
          }
          toggle={this.closePaymentModal}
        >
          {popupLoading ? (
            // <div className="spin-loading1">
            // </div>
            <div className="spin-loading1">
              <div className="flex-column d_flex_align">
                <Spinner type="border" color="#012A4D" className="mb-30" />
                <div className="f14 fw-400">
                  Please wait while we are processing your payment
                </div>
              </div>
            </div>
          ) : (
            <div>
              {payment === "success" && (
                <div className="paymentSuccess">
                  <Lottie
                    options={SuccessVerifyOptions}
                    height={170}
                    width={170}
                  />
                  <div className="paymentTitle align_midd">
                    Payment Successful
                  </div>
                  <div className="align_midd amount paymentTitle">
                    ${invoiceDetail.amount}
                  </div>
                  <div className="align_midd mb-2r">
                    Your Payment has been successfully processed
                  </div>
                  <div className="flex">
                    <div className="fw-500">Transaction id</div>
                    <div>{invoiceDetail.transaction_id}</div>
                  </div>
                </div>
              )}{" "}
              {payment === "processing" && (
                <div className="paymentSuccess">
                  <Lottie
                    options={ProcessingOptions}
                    height={170}
                    width={170}
                  />
                  <div className="paymentTitle align_midd mt-20">
                    Payment Processing
                  </div>

                  <div className="align_midd mb-2r">
                    Your Payment is being processed.Will update your payment
                    automatically.
                  </div>
                </div>
              )}
              {payment === "failure" && (
                <div className="paymentFailed">
                  <img src={error} alt="error" className="paymentError" />
                  <div className="paymentTitle">Payment Failed</div>
                  <div className="align_midd">
                    Your Payment was not successfully processed. Please try
                    again after sometime.
                  </div>
                </div>
              )}
            </div>
          )}
        </Modal>

        <Card className={timelineModal ? "exportSideBar" : "toggle-sidebar"}>
          <PaymentTimeline
            close={this.closeTimeline}
            isOpen={timelineModal}
            data={timelineData}
          />
        </Card>
      </>
    );
  }
}

export default Invoices;
