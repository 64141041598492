import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Card,
  Table,
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  InputGroup,
  Dropdown,
  Input,
  Spinner,
  CustomInput,
  Media,
  Modal,
} from "reactstrap";
// core components
import ApiService from "constants/ApiService";
import { returnFirstDegreeObjValue } from "constants/utils";
import { filterQuery } from "constants/utils";
import { isInvalidEmail } from "constants/utils";
import { getStartOfDay } from "constants/utils";
import { returnThirdDegreeObjValue } from "constants/utils";
import { displayPrice } from "constants/utils";
import { roleType } from "constants/utils";
import SendInvite from "components/Modal/SendInvite";

import ToastList from "components/Toast/ToastList";
import showMore from "../../assets/img/brand/showMore.svg";
import returnSecondDegreeObjValue, {
  buildQueryString,
  checkManageBy,
  dropDownToggle,
  getDefaultProperty,
  getIsoString,
  pushToFilteredPage,
  returnSearchObject,
  returnSortKey,
  showError,
  showLocaldate,
  showSuccess,
  showUtcDate,
  userTypeQuery,
} from "../../constants/utils";
import moment from "moment";
import query from "devextreme/data/query";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ExpenseSideDragger from "./AddEditExpenseSideBar";
import maintenanceInvoice from "../../assets/img/brand/maintenanceInvoice.svg";
import requestInvoice from "../../assets/img/brand/requestInvoice.svg";
import rentInvoice from "../../assets/img/brand/rentInvoice.svg";
import sourceRentInvoice from "../../assets/img/brand/sourceRentInvoice.svg";
import ReactTooltip from "react-tooltip";
import managerIcon from "../../assets/img/brand/ManagerIcon.svg";
import { RoleBasedCRUDAccess } from "views/Roles/RolesUtils";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { CustomDatePicker } from "components/FilterFields/Filterfields";
import { AsscendingDecending } from "components/FilterFields/Filterfields";

class Expenses extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.state = {
      expenseList: [],
      page: 1,
      limit: 10,
      response: "",
      isLoading: true,
      query: {},
      from: "",
      to: "",
      expenseModal: false,
      expenseId: "",
      expense: "",
      detailView: false,
      invoiceStatusView: "",
      inputSearch: "",
      filterOption: "prop_name",
      propertyList: [],
      deleteExpenseModal: false,
      btnload: false,
      sortingList: "",
      timeoutId: null,
      input_value: false,
      keyValue: ""
    };
  }

  reloadPage = () => {
    this.props.history.listen((location, search) => {
      if (location.search === "") {
        this.setState({ query: {} }, () => {
          let query = this.props.location.search;
          query = returnSearchObject(query);
          if (Object.keys(query).length === 0) {
            this.handleReset();
          }
        });
      } else {
        this.state.input_value = true
      }
    });
  };

  componentDidMount() {
    this.setQueryStatus();
    this.reloadPage();
  }

  // componentDidUpdate(prevProps) {
  //   let search = this.props.location.search;
  //   if (prevProps.location.search !== search) {
  //     this.setQueryStatus();
  //   }
  // }

  setQueryStatus = () => {
    let queryStatus = this.props.location.search;
    let default_property = getDefaultProperty();

    if (queryStatus) {
      let queryObj = returnSearchObject(queryStatus);

      this.setState(
        {
          inputSearch: (queryObj.prop_name) ?? "",
          filterOption: queryObj.prop_name ? "prop_name" : "prop_name",
          statusSearch: queryObj.is_occupied ?? "",
          managedBy: queryObj.managed_by ?? "",
          page: parseInt(queryObj.page) ?? "",
          query: returnSearchObject(queryStatus),
        },
        () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getExpenseList();
          // this.getPropertyLists();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...this.state.query,
            page: 1,
            limit: 10,
            from: getIsoString(this.state.from),
            to: getIsoString(this.state.to),
          },
          inputSearch: "",
          filterOption: "prop_name",
        },
        () => {
          pushToFilteredPage(this.props, this.state.query);
          this.getExpenseList();
          // this.getPropertyLists();
        }
      );
    }
  };

  getExpenseList = async () => {
    let response = await ApiService.getAPI(
      `host/expenses${buildQueryString(this.state.query)}`
    );
    if (response.success) {
      let { expenses, total } = response.data;
      this.setState({
        expenseList: expenses,
        isLoading: false,
      });
    } else {
      this.setState({ isLoading: false });
      showError(this, response.message);
    }
  };

  // Toast Handler

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value, input_value: true });
    clearTimeout(this.state.timeoutId);
    const timeoutId = setTimeout(() => {
      this.state.filterOption && this.handleSubmit();
    }, 1000);
    this.setState({ timeoutId });
  };

  onChangeSelectHandler = (status) => {
    this.setState({ filterOption: status, input_value: true }, () => {
      this.handleFilterStatus();
    });
  }
  handleOnFromChange = (e) => {
    this.updateStateValues("from", e);
  };

  handleOnToChange = (e) => {
    this.updateStateValues("to", e);
  };
  updateStateValues = (key, value) => {
    const updatedValue = value == null ? "" : value;
    this.setState({ [key]: updatedValue, input_value: true }, () => {
      if (this.state.from && this.state.to) {
        this.handleFilterStatus();
      }
    });
  };

  openViewMode = (id) => {
    this.setState({ detailView: true, expenseId: id }, () => {
      this.toggleExpenseModal(true);
    });
  };

  handleIncrementPage = () => {
    if (this.state.expenseList.length >= 10) {
      this.setState(
        {
          page: this.state.page + 1,
        },
        () => {
          this.handleFilterStatus();
        }
      );
    }
  };
  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 }, () => {
        this.handleFilterStatus();
      });
    }
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    for (let key in staticQuery) {
      if (key === "prop_name") {
        delete staticQuery[key];
      }
    }
    if (this.state.inputSearch !== "" && this.state.filterOption !== "") {
      staticQuery[this.state.filterOption] = this.state.inputSearch;
    }
    staticQuery.from = getIsoString(this.state.from) ?? "";
    staticQuery.to = getIsoString(this.state.to) ?? "";
    staticQuery.page = this.state.page ?? "";
    staticQuery.sort = this.state.sortingList ?? "";

    this.setState({ query: staticQuery }, () => {
      pushToFilteredPage(this.props, { ...this.state.query });
      this.getExpenseList();
    });
  };

  handleSubmit = () => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  handleReset = () => {
    let default_property = getDefaultProperty();

    let self = this;
    self.setState(
      {
        from: "",
        to: "",
        invoiceStatusView: "",
        page: 1,
        sortingList: "",
        query: {
          page: 1,
          limit: 10,
        },
        filterOption: "prop_name",
        inputSearch: "",
        input_value: false,
        keyValue: "",
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getExpenseList();
      }
    );
  };

  displayErrorMessage = (msg) => {
    showError(this, msg);
  };

  editExpense = (data) => {
    this.setState({ expenseId: data }, () => {
      this.toggleExpenseModal(true);
    });
  };

  toggleExpenseModal = (status, msg, update) => {
    this.setState({ expenseModal: status });
    if (msg) {
      showSuccess(this, msg);
      this.handleFilterStatus();
    }
    if (!status) {
      this.setState({ expenseId: "", detailView: false });
    }
  };
  toggleExpenseDeleteModal = () => {
    const { deleteExpenseModal } = this.state;
    this.setState({ deleteExpenseModal: !deleteExpenseModal });
  };
  openDeleteExpenseModal = (expense) => {
    this.setState({ expense: expense, deleteExpenseModal: true });
  };
  closeDeleteExpenseModal = () => {
    this.setState({ expense: "", deleteExpenseModal: false });
  };
  deleteExpense = async () => {
    const { expense } = this.state;
    this.setState({ btnload: true });
    let response = await ApiService.deleteAPI(
      `host/expenses/${expense.expense_id}`
    );
    if (response.success) {
      this.setState({ btnload: false });
      this.closeDeleteExpenseModal();
      showSuccess(this, response.message);
      this.handleFilterStatus();
    } else {
      this.setState({ btnload: false });
      showError(this, response.message);
    }
  };

  setInvoiceStatusView = (status) => {
    let from;
    let to;
    this.setState({ invoiceStatusView: status, input_value: true });
    if (status === "tm") {
      from = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      to = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
    } else if (status === "lm") {
      from = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
      to = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
    } else if (status === "ty") {
      from = new Date(new Date().getFullYear(), 0, 1);
      to = new Date(new Date().getFullYear(), 11, 31);
    } else {
      from = "";
      to = "";
    }
    this.setState({ from, to, page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  setSortingList = (status) => {
    this.setState({ sortingList: status, page: 1, input_value: true }, () => {
      this.handleFilterStatus();
    });
  };
  action = (key) => {
    let skey = returnSortKey(this, key);
    this.setState({ keyValue: skey, sortingList: skey, page: 1, input_value: true }, () => { this.handleFilterStatus(); })
  }
  render() {
    let {
      expenseList,
      page,
      isLoading,
      toast,
      response,
      toastType,
      expenseModal,
      expenseId,
      detailView,
      invoiceStatusView,
      filterOption,
      inputSearch,
      deleteExpenseModal,
      btnload,
      sortingList,
      from,
      to,
      input_value
      // propertyList,
    } = this.state;
    let self = this;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#012A4D" />
      </div>
    ) : (
      <>
        {toast && <ToastList message={response} type={toastType} />}
        <CustomListingContainer>
          <ListingHeaderLayout label={"Expenses"}>
            <div className="justend">
              <div
                className="invoiceStatusView  mr-10 w40"
                style={{ width: "170px", marginLeft: "15px" }}
              >
                <UncontrolledDropdown className="status_select_div ">
                  <DropdownToggle className="pr-0 drop_down_container">
                    <Media className="capitalize">
                      {invoiceStatusView === "tm"
                        ? "This Month"
                        : invoiceStatusView === "lm"
                          ? "Last Month"
                          : invoiceStatusView === "ty"
                            ? "This Year"
                            : "All"}
                      <i className="fas fa-angle-down"></i>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" bottom>
                    {" "}
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.setInvoiceStatusView("")}
                    >
                      All
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.setInvoiceStatusView("tm")}
                    >
                      This Month
                    </DropdownItem>{" "}
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.setInvoiceStatusView("lm")}
                    >
                      Last Month
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.setInvoiceStatusView("ty")}
                    >
                      This Year
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              <div className="add-filter-wrap ml-0">
                {RoleBasedCRUDAccess("expense", "create") && (
                  <Button
                    color="primary"
                    size="sm"
                    className="button-wrapping notify"
                    onClick={() => this.toggleExpenseModal(true)}
                  >
                    Add
                  </Button>
                )}
              </div>
            </div>
          </ListingHeaderLayout>
          <div style={{ margin: "20px" }}>
            <InputGroup className="flex-content align-left">
              <div className="filters_container">
                <div className="left_filter_container">
                  <div className="w30">
                    <CustomDatePicker
                      from={from}
                      to={to}
                      fromChange={this.handleOnFromChange}
                      toChange={this.handleOnToChange}
                    ></CustomDatePicker>
                  </div>
                  <div className="select_with_input status_select_input ml-10">
                    <UncontrolledDropdown className="status_select_div ">
                      <DropdownToggle className="pr-0 drop_down_container">
                        <Media className="capitalize">
                          {filterOption === "prop_name" && "Property"}
                          <i className="fas fa-angle-down" style={{ marginLeft: "20px" }}></i>
                        </Media>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" bottom style={{ minWidth: "130px" }}>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.onChangeSelectHandler("prop_name")}
                        >
                          Property
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <Input
                      aria-label="Text input with dropdown button"
                      value={inputSearch}
                      name="inputSearch"
                      placeholder="search by"
                      onChange={this.onChangeHandler}
                    />
                  </div>
                </div>
                {input_value && (inputSearch || (from || to) || sortingList || invoiceStatusView) &&
                  <div className="right_filter_container">
                    <Button
                      color="primary"
                      onClick={this.handleReset}
                      size="sm"
                      className="button-wrapping mr-4 button-secondary reset-btn"
                      style={{ fontSize: "15px" }}>
                      Clear filter
                    </Button>
                  </div>}
              </div>
            </InputGroup>
          </div>
          <Row>
            <div className="col new-table">
              <Card className="shadow card_with_paginator">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      {expenseList.length > 0 && (
                        <th scope="col" className="pad1015"></th>
                      )}

                      <th
                        scope="col"
                        className={expenseList.length > 0 ? "padl1r" : "padLeft30"}
                      >
                        Expense Date
                      </th>
                      <th
                        scope="col"
                        className={expenseList.length > 0 ? "pl_40 justLeft point" : "justLeft items-center point "}
                        onClick={() => this.action("pname")}
                      >
                        <AsscendingDecending keyValue={this.state.keyValue} value="pname" name="Property" />
                      </th>
                      <th scope="col">Category</th>
                      <th scope="col">Created On</th>
                      <th scope="col">Reference</th>
                      <th scope="col">Amount</th>
                      {RoleBasedCRUDAccess("expense", "edit") == true ||
                        RoleBasedCRUDAccess("expense", "delete") == true ? (
                        <th scope="col"></th>
                      ) : (
                        ""
                      )}
                    </tr>
                  </thead>
                  {expenseList.length !== 0 ? (
                    <tbody>
                      {expenseList.map((expense, i) => (
                        <tr key={i} className="point">
                          <td className="pad1015">
                            <div
                              data-tip={
                                expense.mode === "manual"
                                  ? "Manual"
                                  : expense.mode === "auto"
                                    ? expense?.type?.ref === "source_rent"
                                      ? "Source Rent"
                                      : this.capitalizeFirstLetter(
                                        expense?.type?.ref
                                      )
                                    : ""
                              }
                              data-iscapture="true"
                              data-for={String(i)}
                              data-place="top"
                              className="point flexmid"
                            >
                              {expense.mode === "manual" ? (
                                <img
                                  src={managerIcon}
                                  id={expense?.type?.ref}
                                />
                              ) : expense.mode === "auto" ? (
                                expense?.type?.ref === "rent" ? (
                                  <img
                                    src={rentInvoice}
                                    id={expense?.type?.ref}
                                  />
                                ) : expense?.type?.ref === "source_rent" ? (
                                  <img
                                    src={sourceRentInvoice}
                                    id={expense?.type?.ref}
                                  />
                                ) : expense?.type?.ref === "request" ? (
                                  <img
                                    src={requestInvoice}
                                    id={expense?.type?.ref}
                                  />
                                ) : (
                                  expense?.type?.ref === "maintenance" && (
                                    <img
                                      src={maintenanceInvoice}
                                      id={expense?.type?.ref}
                                    />
                                  )
                                )
                              ) : (
                                ""
                              )}
                              <ReactTooltip
                                place="right"
                                type="info"
                                effect="solid"
                                id={String(i)}
                                html={true}
                              />
                            </div>
                          </td>
                          <td
                            className="text-capitalize cursor-point padl1r"
                            onClick={() =>
                              this.openViewMode(expense.expense_id)
                            }
                          >
                            {showLocaldate(
                              returnFirstDegreeObjValue(expense, "date")
                            )}
                          </td>
                          <td
                            onClick={() =>
                              this.openViewMode(expense.expense_id)
                            }
                            className="tdColumn tdColumn_30"
                          >
                            {returnSecondDegreeObjValue(
                              expense,
                              "property",
                              "name"
                            )}
                          </td>
                          <td
                            onClick={() =>
                              this.openViewMode(expense.expense_id)
                            }
                          >
                            {returnFirstDegreeObjValue(expense, "category")}
                          </td>
                          <td
                            className="text-capitalize cursor-point"
                            onClick={() =>
                              this.openViewMode(expense.expense_id)
                            }
                          >
                            {showLocaldate(
                              returnFirstDegreeObjValue(expense, "createdAt")
                            )}
                          </td>
                          <td
                            onClick={() =>
                              this.openViewMode(expense.expense_id)
                            }
                          >
                            {returnSecondDegreeObjValue(expense, "type", "id")}
                          </td>

                          <td
                            className="text-capitalize"
                            onClick={() =>
                              this.openViewMode(expense.expense_id)
                            }
                          >
                            {displayPrice(
                              returnFirstDegreeObjValue(expense, "amount")
                            )}
                          </td>
                          {RoleBasedCRUDAccess("expense", "edit") === true ||
                            RoleBasedCRUDAccess("expense", "delete") === true ? (
                            <td>
                              {expense.mode === "manual" && (
                                <UncontrolledDropdown
                                  nav
                                  className="position_u"
                                >
                                  <DropdownToggle
                                    className="pr-0 nav-notice"
                                    nav
                                  >
                                    <Media className="align-items-center">
                                      <img src={showMore} />
                                    </Media>
                                  </DropdownToggle>

                                  <DropdownMenu
                                    className="dropdown-menu-arrow min-width-drop"
                                    right
                                  >
                                    <>
                                      {RoleBasedCRUDAccess(
                                        "expense",
                                        "edit"
                                      ) && (
                                          <DropdownItem
                                            onClick={() =>
                                              this.editExpense(expense.expense_id)
                                            }
                                          >
                                            <span>Edit</span>
                                          </DropdownItem>
                                        )}
                                    </>

                                    {/* <DropdownItem divider /> */}
                                    {RoleBasedCRUDAccess(
                                      "expense",
                                      "delete"
                                    ) && (
                                        <DropdownItem
                                          onClick={() =>
                                            this.openDeleteExpenseModal(expense)
                                          }
                                        >
                                          <span>Delete</span>
                                        </DropdownItem>
                                      )}
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              )}
                            </td>
                          ) : (
                            ""
                          )}
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td className="no-data">No Expenses Available</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                        <td></td>
                        {RoleBasedCRUDAccess("expense", "edit") == true ||
                          RoleBasedCRUDAccess("expense", "delete") == true ? (
                          <td></td>
                        ) : (
                          ""
                        )}
                      </tr>
                    </tbody>
                  )}
                </Table>
              </Card>
            </div>
          </Row>
          <Row className="align-items-center jus-space new-margin-page paginator_box">
            <div className="paginate-flex-box ">
              <div
                className="paginate-box"
                onClick={() => this.handleDecrementPage()}
              >
                <p
                  className={page !== 1 ? "page_available" : "page_unavailable"}
                >
                  Previous
                </p>
              </div>
              <div
                className="paginate-box"
                onClick={() => this.handleIncrementPage()}
              >
                <p
                  className={
                    expenseList.length < 10
                      ? "page_unavailable"
                      : "page_available"
                  }
                >
                  Next
                </p>
              </div>
            </div>
          </Row>

          <Card className={expenseModal ? "exportSideBar" : "toggle-sidebar"}>
            <ExpenseSideDragger
              close={this.toggleExpenseModal}
              isOpen={expenseModal}
              toast={this.displayErrorMessage}
              id={expenseId}
              viewMode={detailView}
            />
          </Card>

          <Modal
            className="modal-dialog-centered"
            isOpen={deleteExpenseModal}
            toggle={() => this.toggleExpenseDeleteModal()}
          >
            <>
              <div className="modal-header modal-invite">
                <h5 className="modal-title" id="exampleModalLabel">
                  Block Manager
                </h5>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.closeDeleteExpenseModal(false)}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <span className="pad-invite">
                Are you sure you want to delete this expense ?
              </span>
              <div className="modal-footer">
                <div className="add-filter-wrap ">
                  <div
                    className="filter-wrap mr-3 edit-prop change-profile-pass "
                    onClick={() => this.closeDeleteExpenseModal(false)}
                  >
                    <label className="profile-cancel">Cancel</label>
                  </div>
                </div>
                <Button
                  color="primary"
                  type="button"
                  onClick={() => this.deleteExpense()}
                  className="button-wrapping change-profile-button"
                >
                  {btnload ? (
                    <div className="spin-loading-save w100 h100">
                      <Spinner type="border" color="#012A4D" />
                    </div>
                  ) : (
                    <div>Delete</div>
                  )}
                </Button>
              </div>
            </>
          </Modal>
        </CustomListingContainer>
      </>
    );
  }
}

export default Expenses;
