import ToastList from "components/Toast/ToastList";
import ApiService from "constants/ApiService";
import { setCookie, showError, isChangePassword } from "constants/utils";
import React, { Component } from "react";
import {
  Button,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Spinner,
} from "reactstrap";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      curPassword: "",
      password: "",
      newPassword: "",
      type: "password",
      pType: "password",
      nType: "password",
      passLoad: false,
      toast: "",
      response: "",
      toastType: "",
    };
  }

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  toggleEyeIcon = (key) => {
    if (key === "curPass") {
      this.setState({
        type: this.state.type === "password" ? "text" : "password",
      });
    } else if (key === "pass") {
      this.setState({
        pType: this.state.pType === "password" ? "text" : "password",
      });
    } else if (key === "newPass") {
      this.setState({
        nType: this.state.nType === "password" ? "text" : "password",
      });
    }
  };

  handleChangePassword = async () => {
    let { curPassword, password, newPassword } = this.state;
    if (curPassword === "") {
      showError(this, "Please enter current password");
    } else if (password === "") {
      showError(this, "Please enter new password");
    } else if (password.length <= 6) {
      showError(
        this,
        "New password length should be greater than six characters"
      );
    } else if (newPassword === "") {
      showError(this, "Please enter confirm password");
    } else if (password !== newPassword) {
      showError(this, "New password and confirm password does not match");
    } else {
      this.setState({ passLoad: true });
      let data = {
        current_password: curPassword,
        new_password: password,
      };
      let response = await ApiService.patchAPI("host/change_password", data);
      if (response.success) {
        setCookie("ischangepassword", false);
        this.setState({ passLoad: false });
        // showSuccess(this, response.message);
        this.props.close(response.message);
      } else {
        this.setState({ passLoad: false });
        showError(this, response.message);
      }
    }
  };

  closeModal = () => {
    this.setState({ password: "", newPassword: "", curPassword: "" });
    this.props.close();
  };

  render() {
    let {
      type,
      nType,
      pType,
      password,
      curPassword,
      newPassword,
      passLoad,
      toast,
      response,
      toastType,
    } = this.state;
    return (
      <div>
        {toast && <ToastList message={response} type={toastType} />}
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Change Password
          </h5>
          {isChangePassword() ? (
            ""
          ) : (
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.closeModal()}
            >
              <span aria-hidden={true}>×</span>
            </button>
          )}
        </div>
        <div className="modal-body profile-body">
          {" "}
          <Row>
            <Col lg="12">
              <FormGroup>
                <label
                  className="form-control-label label-in mb-3"
                  htmlFor="input-username"
                >
                  Current Password*
                </label>
                <InputGroup className="input-group-alternative new-input-login">
                  <Input
                    className="form-control-alternative email-new-in"
                    value={curPassword}
                    name="curPassword"
                    placeholder="Current Password"
                    type={type}
                    onChange={this.handleInputChange}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText
                      onClick={() => this.toggleEyeIcon("curPass")}
                      className="new-border"
                    >
                      <i
                        className={
                          type === "password" ? "fa fa-eye-slash" : "fa fa-eye"
                        }
                      ></i>
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup>
                <label
                  className="form-control-label label-in mb-3"
                  htmlFor="input-username"
                >
                  New Password*
                </label>
                <InputGroup className="input-group-alternative new-input-login">
                  <Input
                    className="form-control-alternative email-new-in"
                    value={password}
                    name="password"
                    placeholder="New Password"
                    type={pType}
                    onChange={this.handleInputChange}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText
                      onClick={() => this.toggleEyeIcon("pass")}
                      className="new-border"
                    >
                      <i
                        className={
                          pType === "password" ? "fa fa-eye-slash" : "fa fa-eye"
                        }
                      ></i>
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup>
                <label
                  className="form-control-label label-in mb-3"
                  htmlFor="input-username"
                >
                  Confirm Password*
                </label>
                <InputGroup className="input-group-alternative new-input-login">
                  <Input
                    className="form-control-alternative email-new-in"
                    value={newPassword}
                    name="newPassword"
                    placeholder="Confirm Password"
                    type={nType}
                    onChange={this.handleInputChange}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText
                      onClick={() => this.toggleEyeIcon("newPass")}
                      className="new-border"
                    >
                      <i
                        className={
                          nType === "password" ? "fa fa-eye-slash" : "fa fa-eye"
                        }
                      ></i>
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
        </div>
        {/* </div> */}
        <div className="modal-footer">
          <div className="add-filter-wrap ">
            {isChangePassword() ? (
              ""
            ) : (
              <div
                className="filter-wrap mr-3 edit-prop change-profile-pass"
                onClick={() => this.closeModal()}
              >
                <label className="profile-cancel">Cancel</label>
              </div>
            )}
          </div>
          <Button
            color="primary"
            type="button"
            onClick={() => this.handleChangePassword()}
            className="button-wrapping change-profile-button"
          >
            {passLoad ? (
              <div className="spin-loading-save w100 h100">
                <Spinner type="border" color="#012A4D" />
              </div>
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </div>
    );
  }
}
export default ChangePassword;
