import ApiService from "constants/ApiService";
import moment from "moment";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import { connect } from "react-redux";
import { getNotifyCount, getNotifyList } from "feature/counter/counterSlice";

class Notify extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // this.getNoticationList();
  }

  getNoticationList = async () => {
    let response = await ApiService.getAPI(
      `host/notification?page=1&limit=5&read=0``host/notification?page=1&limit=5&read=0`
    );
    if (response.success) {
      this.setState({ notifyMessage: response.data.notifications });
    }
  };

  redirectPage = async (notify) => {
    let idArr = [];
    idArr.push(notify.notification_id);
    let idData = {
      ids: idArr,
    };
    let response = await ApiService.patchAPI(`host/notification`, idData);
    if (response.success) {
      this.props.updateNotifyCount();
      this.props.updateNotifyList();
    }
  };

  clearNotification = async () => {
    let data = {};
    let response = await ApiService.patchAPI(`host/notification`, data);
    if (response.success) {
      this.props.updateNotifyCount();
      this.props.updateNotifyList();
    }
  };

  render() {
    let { notifyData } = this.props;
    return (
      <div>
        <Card className="bnone">
          <CardHeader className="notifyHeader pad1420">
            <div className="flex">
              <div className="fw-500 f15 cl22212F">Notifications</div>
              {notifyData?.length > 0 && (
                <div
                  className="fw-500 f13  clpaid point"
                  onClick={() => this.clearNotification()}
                >
                  Mark as read
                </div>
              )}
            </div>
          </CardHeader>
          <CardBody className="pad1420">
            {notifyData?.length > 0 ? (
              notifyData?.map((notify) => (
                <Link
                  to={
                    notify.type === "request" && notify.ref
                      ? `/admin/requests/detail/${notify.ref}`
                      : notify.type === "maintenance" && notify.ref
                        ? `/admin/maintenanceList/maintenance_historys/detail/${notify.ref}`
                        : notify.type === "invoice"
                          ? `/admin/invoices`
                          : notify.type === "payout"
                            ? `/admin/income`
                            : notify.type === "vault_doc"
                              ? `/admin/vault`
                              : notify.type === "payment"
                                ? `/admin/payments`
                                : `/admin/notifications`
                  }
                  className="notify_message"
                >
                  <div onClick={() => this.redirectPage(notify)}>
                    <div className="flex mb-7">
                      <div className="fw-400 f13 cl22212F w70">
                        {notify.title}
                      </div>
                      <div
                        className="fw-400 f10 clBDBDBD  {
"
                      >
                        {moment(notify.updatedAt).format("MMM DD")}
                      </div>
                    </div>
                    <div className="fw-400 f11 mb-14 cl757575">
                      {notify.message}
                    </div>
                    <div className="notify-line mb-14"></div>
                  </div>
                </Link>
              ))
            ) : (
              <div className="flexmid cl757575 f14 ">
                No Unread Notification
              </div>
            )}
          </CardBody>
          <Link
            to={{
              pathname: "/admin/notifications",
              merge: "TransactionProp",
            }}
          >
            <CardFooter className="flexmid fw-500 pad1420 f13 point">
              View All
            </CardFooter>
          </Link>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    notify_count: state.counter.notify_count,
    notify_list: state.counter.notify_list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateNotifyCount: () => dispatch(getNotifyCount()),
    updateNotifyList: () => dispatch(getNotifyList()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Notify);