import React, { Component } from "react";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Card,
  Table,
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  InputGroup,
  Dropdown,
  Input,
  Spinner,
  CustomInput,
  Media,
  Modal,
  CardBody,
} from "reactstrap";
import ReactTooltip from "react-tooltip";
// core components
import ApiService from "constants/ApiService";
import { returnFirstDegreeObjValue } from "constants/utils";
import { displayPrice } from "constants/utils";
import ToastList from "components/Toast/ToastList";
import showMore from "../../assets/img/brand/showMore.svg";
import timelineIcon from "../../assets/img/brand/invoiceTimeline.svg";
import TotalInvoice from "../../../src/assets/img/brand/headerTotalInvoice.svg";
import PaidInvoice from "../../../src/assets/img/brand/paidInvoice.svg";
import UnpaidInvoice from "../../../src/assets/img/brand/unpaidInvoice.svg";
import downloadIcon from "../../assets/img/brand/downloadIcon.svg";
import { RoleBasedCRUDAccess } from "views/Roles/RolesUtils";
import RemoveLateFee from "./RemoveLateFee";
import returnSecondDegreeObjValue, {
  buildQueryString,
  getDefaultProperty,
  getIsoString,
  pushToFilteredPage,
  returnCustomerFirsLastName,
  returnSearchObject,
  showError,
  showLocaldate,
  showSuccess,
  returnDownloadGet,
  removeKeys,
  returnSortKey,
  returnCustomerName,
} from "../../constants/utils";
import "react-datepicker/dist/react-datepicker.css";
import DepositDetail from "./DepositDetail";
import EditDeposit from "./EditDeposit";
import CancelDeposit from "./cancelDeposit";
import DepositTimeline from "./DepositTimeline";
import MarkTransferSideBar from "./markTransferSideDragger";
import DepositStatsIcon from "../../assets/img/brand/depositStatsIcon.svg";
import CountUp from "react-countup";
import Constants from "../../constants/Env";
import DepositSendReminder from "./DepositSendReminder";
import DepositMarkPayment from "./DepositMarkPayment";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { CustomDatePicker } from "components/FilterFields/Filterfields";
import { CustomSearch } from "components/FilterFields/Filterfields";
import { AsscendingDecending } from "components/FilterFields/Filterfields";
class Deposits extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.state = {
      depositList: [],
      page: 1,
      limit: 10,
      response: "",
      isLoading: true,
      query: {},
      from: "",
      to: "",
      expenseModal: false,
      expenseId: "",
      detailView: false,
      invoiceStatusView: "",
      inputSearch: "",
      filterOption: "username",
      depositId: "",
      depositData: "",
      statusSearch: "",
      editModal: false,
      confirmModal: false,
      timelineData: "",
      displayTimeline: false,
      statsData: "",
      markTransferModal: false,
      depositId: "",
      latefeeModal: false,
      sortingList: "",
      markPaymentModal: false,
      timeoutId: null,
      input_value: false,
      keyValue: ""
    };
  }

  reloadPage = () => {
    this.props.history.listen((location, search) => {
      if (location.search === "") {
        this.setState({ query: {} }, () => {
          let query = this.props.location.search;
          query = returnSearchObject(query);
          if (Object.keys(query).length === 0) {
            this.handleReset();
          }
        });
      } else {
        this.state.input_value = true
      }
    });
  };

  componentDidMount() {
    this.setQueryStatus();
    this.reloadPage();
  }

  // componentDidUpdate(prevProps) {
  //   let search = this.props.location.search;
  //   if (prevProps.location.search !== search) {
  //     this.setQueryStatus();
  //   }
  // }

  setQueryStatus = () => {
    let queryStatus = this.props.location.search;
    let default_property = getDefaultProperty();
    if (queryStatus) {
      let queryObj = returnSearchObject(queryStatus);
      this.setState(
        {
          inputSearch:
            (queryObj.prop_name || queryObj.property_id || queryObj.username) ??
            "",
          filterOption: queryObj.prop_name
            ? "prop_name"
            : queryObj.property_id
              ? "property_id"
              : queryObj.username && "username",
          // statusSearch: queryObj.status === ?? "",
          statusSearch: queryObj.status === "created,pending" ? "pending" : "",
          page: parseInt(queryObj.page) ?? 1,
          query: queryObj,
          limit: 10,
        },
        () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getdepositList();
          this.getDepositStats();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...this.state.query,
            page: 1,
            limit: 10,
            property_id: default_property,
          },
          inputSearch: default_property ? default_property : "",
          filterOption: default_property ? "property_id" : "username",
        },
        () => {
          pushToFilteredPage(this.props, this.state.query);
          this.getdepositList();
          this.getDepositStats();
        }
      );
    }
  };
  downloadDeposit = async () => {
    let apival = removeKeys(this.state);
    fetch(Constants.BASE_URL + `host/deposits/download${apival}`, returnDownloadGet()).then((response) => {
      return response.blob();
    }).then((data) => {
      if (data.type === "application/json") {
        showError(this, "No records found to download");
      } else {
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(data);
        downloadLink.setAttribute("download", `Deposits.xlsx`);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.remove();
      }
    });
  };

  getdepositList = async () => {
    let response = await ApiService.getAPI(
      `host/deposits${buildQueryString(this.state.query)}`
    );
    if (response.success) {
      let { deposit, total } = response.data;
      this.setState({
        depositList: deposit,
        isLoading: false,
      });
    } else {
      this.setState({ isLoading: false });
      showError(this, response.message);
    }
  };

  getDepositStats = async () => {
    let response = await ApiService.getAPI(`host/deposits/stats`);
    if (response.success) {
      this.setState({ statsData: response.data });
    }
  };

  // Toast Handler
  openMarkTransferSideDragger = (id, data) => {
    this.setState({
      markTransferModal: true,
      depositId: id,
      depositData: data,
    });
  };

  toggleCancelReminderModal = (msg) => {
    this.setState({ reminderModal: false, depositId: "" });
    if (msg) {
      showSuccess(this, msg);
    }
  };

  closeMarkTranferSideDraggger = (msg) => {
    if (msg) {
      showSuccess(this, msg);
      this.getdepositList();
      this.getDepositStats();
    }
    this.setState({
      markTransferModal: false,
      depositId: "",
      depositData: "",
    });
  };
  closeLateFeeModal = (msg) => {
    if (msg) {
      showSuccess(this, msg);
      this.getdepositList();
      this.getDepositStats();
    }
    this.setState({
      latefeeModal: false,
      depositId: "",
    });
  };

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value, input_value: true });
    clearTimeout(this.state.timeoutId);
    const timeoutId = setTimeout(() => {
      this.state.filterOption && this.handleSubmit();
    }, 1000);
    this.setState({ timeoutId });
  };

  onChangeSelectHandler = (status) => {
    this.setState({ filterOption: status, input_value: true }, () => {
      this.handleFilterStatus();
    });
  }
  handleOnFromChange = (e) => {
    this.updateStateValues("from", e);
  };

  handleOnToChange = (e) => {
    this.updateStateValues("to", e);
  };
  updateStateValues = (key, value) => {
    const updatedValue = value == null ? "" : value;
    this.setState({ [key]: updatedValue, input_value: true }, () => {
      if (this.state.from && this.state.to) {
        this.handleFilterStatus();
      }
    });
  };

  openViewMode = (id) => {
    this.setState({ detailView: true, depositId: id });
  };

  closeView = () => {
    this.setState({ detailView: false, depositId: "" });
  };

  handleIncrementPage = () => {
    if (this.state.depositList.length >= 10) {
      this.setState(
        {
          page: this.state.page + 1,
        },
        () => {
          this.handleFilterStatus();
        }
      );
    }
  };
  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 }, () => {
        this.handleFilterStatus();
      });
    }
  };

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    for (let key in staticQuery) {
      if (key === "prop_name" || key === "property_id" || key === "username") {
        delete staticQuery[key];
      }
    }
    if (this.state.inputSearch !== "" && this.state.filterOption !== "") {
      staticQuery[this.state.filterOption] = this.state.inputSearch;
    }
    staticQuery.from = getIsoString(this.state.from) ?? "";
    staticQuery.to = getIsoString(this.state.to) ?? "";
    staticQuery.status = this.state.statusSearch ?? "";
    staticQuery.page = this.state.page ?? "";
    staticQuery.sort = this.state.sortingList ?? "";

    this.setState({ query: staticQuery }, () => {
      pushToFilteredPage(this.props, { ...this.state.query });
      this.getdepositList();
    });
  };

  handleSubmit = () => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  handleReset = () => {
    let default_property = getDefaultProperty();
    let self = this;
    self.setState(
      {
        from: "",
        to: "",
        invoiceStatusView: "",
        statusSearch: "",
        page: 1,
        sortingList: "",
        query: {
          page: 1,
          limit: 10,
          property_id: default_property,
        },
        filterOption: default_property ? "property_id" : "username",
        inputSearch: default_property ? default_property : "",
        input_value: false,
        keyValue: "",
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getdepositList();
      }
    );
  };

  setStatus = (status) => {
    this.setState({ statusSearch: status, page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  displayErrorMessage = (msg) => {
    showError(this, msg);
  };

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  returnStatus = (deposit) => {
    let { amount, paid_amount } = deposit;
    let status = "";
    if (amount === paid_amount) {
      status = "paid";
    } else {
      status = "pending";
    }
    return status;
  };

  editDeposit = (id, data) => {
    this.setState({ editModal: true, depositId: id, depositData: data });
  };

  closeEditDeposit = (msg) => {
    this.setState({ editModal: false, depositData: "" });
    if (msg) {
      showSuccess(this, msg);
      this.getdepositList();
    }
  };

  openConfirmModal = (id) => {
    this.setState({ confirmModal: true, depositId: id });
  };
  overAllAmount = (amount, late_fee_amount) => {
    let dp_amount = amount ?? 0;
    let lf_amount = late_fee_amount ?? 0;

    return dp_amount + lf_amount;
  };
  closeConfirmModal = (msg) => {
    this.setState({ confirmModal: false });
    if (msg) {
      showSuccess(this, msg);
      this.getdepositList();
    }
  };

  showTimeline = (timeline) => {
    this.setState({ timelineData: timeline, displayTimeline: true });
  };

  closeTimeLine = () => {
    this.setState({ timelineData: "", displayTimeline: false });
  };
  openLateFeeModal = (id) => {
    this.setState({
      latefeeModal: true,
      depositId: id,
    });
  };
  setSortingList = (status) => {
    this.setState({ sortingList: status, page: 1, input_value: true }, () => {
      this.handleFilterStatus();
    });
  };
  openMarkPaymentSideDragger = (id) => {
    this.setState({ markPaymentModal: true, depositId: id });
  };
  closeMarkPaymentSideDraggger = (msg) => {
    if (msg) {
      showSuccess(this, msg);
      this.getdepositList();
      this.getDepositStats();
    }
    this.setState({ markPaymentModal: false, depositId: "" });
  };
  action = (key) => {
    let skey = returnSortKey(this, key);
    this.setState({ keyValue: skey, sortingList: skey, page: 1, input_value: true }, () => { this.handleFilterStatus() })
  }
  render() {
    let {
      depositList,
      page,
      isLoading,
      toast,
      response,
      toastType,
      expenseModal,
      expenseId,
      detailView,
      invoiceStatusView,
      filterOption,
      inputSearch,
      depositId,
      depositData,
      statusSearch,
      editModal,
      confirmModal,
      timelineData,
      displayTimeline,
      statsData,
      markTransferModal,
      latefeeModal,
      sortingList,
      markPaymentModal,
      from,
      to,
      input_value
    } = this.state;
    let self = this;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#012A4D" />
      </div>
    ) : (
      <>

        {toast && <ToastList message={response} type={toastType} />}
        <div style={{ margin: "24px 24px -24px 24px" }}>
          <div className="header-body">
            <Row className="row-center">
              <Col lg="6" xl="3" className="max-dash mb_mt_28 ">
                <Card
                  className="card-stats mb-4 mb-xl-0 dash-new point"
                  onClick={() => this.setStatus("")}
                >
                  <CardBody className="pad-dash invoice_dash point">
                    <div className="dash_content dash_content_invoice point ">
                      <div className="dash_left">
                        <div className="heading_with_badge">
                          <h4>OVERALL</h4>
                        </div>
                        <label className="number-con mb_15 point">
                          <CountUp
                            start={0}
                            end={returnSecondDegreeObjValue(
                              statsData,
                              "overall",
                              "amount"
                            )}
                            separator=","
                            prefix="$"
                            className="point"
                          />
                        </label>
                        <div className="count_with_heading">
                          <div className="count_div">
                            <CountUp
                              start={0}
                              end={returnSecondDegreeObjValue(
                                statsData,
                                "overall",
                                "count"
                              )}
                              separator=","
                              prefix=""
                            />
                          </div>
                          <p>Deposits</p>
                        </div>
                      </div>
                      <div className="dash_right_container">
                        <div className="col dash-col dash_right dash_right_invoice">
                          <img
                            src={TotalInvoice}
                            className="img-dash"
                            alt="total sales"
                          />
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="3" className="max-dash mb_mt_28">
                <Card
                  className="card-stats mb-4 mb-xl-0 dash-new point"
                  onClick={() => this.setStatus("paid")}
                >
                  <CardBody className="pad-dash invoice_dash">
                    <div className="dash_content dash_content_invoice ">
                      <div className="dash_left">
                        <div className="heading_with_badge">
                          <h4>PAID</h4>
                        </div>
                        <label className="number-con mb_15 point">
                          <CountUp
                            start={0}
                            end={returnSecondDegreeObjValue(
                              statsData,
                              "paid",
                              "amount"
                            )}
                            separator=","
                            prefix="$"
                          />
                        </label>
                        <div className="count_with_heading">
                          <div className="count_div">
                            <CountUp
                              start={0}
                              end={returnSecondDegreeObjValue(
                                statsData,
                                "paid",
                                "count"
                              )}
                              separator=","
                              prefix=""
                            />
                          </div>
                          <p>Deposits</p>
                        </div>
                      </div>
                      <div className="dash_right_container">
                        <div className="col dash-col dash_right dash_right_invoice">
                          <img
                            src={PaidInvoice}
                            className="img-dash"
                            alt="total sales"
                          />
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="3" className="max-dash mb_mt_28 ">
                <Card
                  className="card-stats mb-4 mb-xl-0 dash-new point"
                  onClick={() => this.setStatus("created,pending")}
                >
                  <CardBody className="pad-dash invoice_dash">
                    <div className="dash_content dash_content_invoice ">
                      <div className="dash_left">
                        <div className="heading_with_badge">
                          <h4>PENDING</h4>
                        </div>
                        <label className="number-con mb_15 point">
                          <CountUp
                            start={0}
                            end={returnSecondDegreeObjValue(
                              statsData,
                              "pending",
                              "amount"
                            )}
                            separator=","
                            prefix="$"
                          />
                        </label>
                        <div className="count_with_heading">
                          <div className="count_div">
                            <CountUp
                              start={0}
                              end={returnSecondDegreeObjValue(
                                statsData,
                                "pending",
                                "count"
                              )}
                              separator=","
                              prefix=""
                            />
                          </div>
                          <p>Deposits</p>
                        </div>
                      </div>
                      <div className="dash_right_container">
                        <div className="percentage_calulate"></div>
                        <div className="col dash-col dash_right dash_right_invoice">
                          <img
                            src={UnpaidInvoice}
                            className="img-dash"
                            alt="total sales"
                          />
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="3" className="max-dash mb_mt_28 ">

                <Card
                  className="card-stats mb-4 mb-xl-0 dash-new"
                  onClick={() => this.setStatus("paid_out")}
                >
                  <CardBody className="pad-dash invoice_dash">
                    <div className="dash_content dash_content_invoice ">
                      <div className="dash_left">
                        <div className="heading_with_badge">
                          <h4>REFUNDED</h4>
                        </div>
                        <label className="number-con mb_15 point">
                          <CountUp
                            start={0}
                            end={returnSecondDegreeObjValue(
                              statsData,
                              "transferred",
                              "amount"
                            )}
                            separator=","
                            prefix="$"
                          />
                        </label>
                        <div className="count_with_heading">
                          <div className="count_div">
                            <CountUp
                              start={0}
                              end={returnSecondDegreeObjValue(
                                statsData,
                                "transferred",
                                "count"
                              )}
                              separator=","
                              prefix=""
                            />
                          </div>
                          <p>Deposits</p>
                        </div>
                      </div>
                      <div className="dash_right_container">
                        <div className="percentage_calulate"></div>
                        <div className="col dash-col dash_right dash_right_invoice">
                          <img
                            src={DepositStatsIcon}
                            className="img-dash"
                            alt="total sales"
                          />
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <CustomListingContainer>
          <ListingHeaderLayout label={"Deposits"} />
          <div style={{ margin: "20px" }}>
            <InputGroup className="flex-content align-left">
              <div className="filters_container">
                <div className="left_filter_container">
                  <div className="w30">
                    {/* {" "}
                    <div className="flexStart" style={{ width: "100%" }}>
                      <div className="date-merge w20">Date</div>
                      <div className="w40">
                        <DatePicker
                          selected={this.state.from}
                          maxDate={new Date()}
                          dateFormat="MM-dd-yyyy"
                          placeholderText=""
                          className="filter-input-date point "
                          onChange={this.handleOnFromChange}
                          name="from"
                          id="from"
                          value={this.state.value}
                          style={{ fontWeight: "500" }}
                          showYearDropdown
                          showMonthDropdown
                          ariaDescribedBy="basic-addon2"
                        />
                      </div>

                      <div className="todate-merge">to</div>
                      <div className="w40">
                        <DatePicker
                          selected={this.state.to}
                          maxDate={new Date()}
                          dateFormat="MM-dd-yyyy"
                          placeholderText=""
                          className="to_filter-input-date  point"
                          onChange={this.handleOnToChange}
                          name="to"
                          id="to"
                          value={this.state.value}
                          style={{ fontWeight: "500" }}
                          showYearDropdown
                          showMonthDropdown
                          ariaDescribedBy="basic-addon2"
                        />
                      </div>
                    </div> */}
                    <CustomDatePicker
                      from={from}
                      to={to}
                      fromChange={this.handleOnFromChange}
                      toChange={this.handleOnToChange}
                    ></CustomDatePicker>
                  </div>
                  <div className="select_with_input status_select_input ml-10">
                    <UncontrolledDropdown className="status_select_div ">
                      <DropdownToggle className="pr-0 drop_down_container">
                        <Media className="capitalize">
                          {filterOption === "username" ? "Tenant"
                            : filterOption === "prop_name" ? "Property"
                              : filterOption === "property_id" ? "Property Id" : "Tenant"
                          }
                          <i className="fas fa-angle-down" style={{ marginLeft: "20px" }}></i>
                        </Media>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" bottom style={{ minWidth: "130px" }}>
                        {" "}
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.onChangeSelectHandler("username")}
                        >
                          Tenant
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.onChangeSelectHandler("prop_name")}
                        >
                          Property
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.onChangeSelectHandler("property_id")}
                        >
                          Property Id
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <Input
                      aria-label="Text input with dropdown button"
                      value={inputSearch}
                      name="inputSearch"
                      placeholder="search by"
                      onChange={this.onChangeHandler}
                    />
                  </div>
                  <div className="status_select">
                    <UncontrolledDropdown className="status_select_div">
                      <DropdownToggle className="pr-0 drop_down_container">
                        <Media className="capitalize">
                          {statusSearch === ""
                            ? "All"
                            : statusSearch === "created"
                              ? "Created"
                              : statusSearch === "pending"
                                ? "Partially Paid"
                                : statusSearch === "paid_out"
                                  ? "Refunded"
                                  : statusSearch === "past_due"
                                    ? "Past Due"
                                    : statusSearch}
                          <i className="fas fa-angle-down"></i>
                        </Media>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" bottom>
                        {" "}
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("")}
                        >
                          All
                        </DropdownItem>{" "}
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("created")}
                        >
                          Created
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("pending")}
                        >
                          Partially Paid
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("past_due")}
                        >
                          Past Due
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("paid")}
                        >
                          Paid
                        </DropdownItem>

                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("paid_out")}
                        >
                          Refunded
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("cancelled")}
                        >
                          Cancelled
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>
                <div className="right_filter_container">
                  {input_value && (inputSearch || statusSearch || (from || to) || sortingList) &&
                    <Button
                      color="primary"
                      onClick={this.handleReset}
                      size="sm"
                      className="button-wrapping mr-4 button-secondary reset-btn"
                      style={{ fontSize: "15px" }}                  >
                      Clear filter
                    </Button>
                  }
                  <>
                    <div
                      onClick={() => this.downloadDeposit()}
                      className="download-btn point"
                      id="downloadDeposit"
                      data-tip="Download"
                      data-iscapture="true"
                      data-for="downloadDeposit"
                      data-place="bottom"
                    >
                      <img src={downloadIcon} style={{ width: "17px" }} />
                    </div>
                    <ReactTooltip
                      place="bottom"
                      type="info"
                      effect="solid"
                      id="downloadDeposit"
                      html={true}
                    />
                  </>
                </div>
              </div>
            </InputGroup>
          </div>
          <Row>
            <div className="col new-table">
              <Card className="shadow card_with_paginator">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" className="bnone" style={{ padding: "0px", display: "flex" }}>
                        <th
                          scope="col"
                          className="pl_40 justLeft items-center bnone point tdColumn_18"
                          style={{ display: "flex" }}
                          onClick={() => this.action("user")}
                        >
                          <AsscendingDecending keyValue={this.state.keyValue} value="user" name="Tenant" />
                        </th>
                        {/* <th scope="col">Property</th> */}
                        <th
                          scope="col"
                          className="pl_40 justLeft items-center bnone point tdColumn_18"
                          style={{ display: "flex" }}
                          onClick={() => this.action("pname")}
                        >
                          <AsscendingDecending keyValue={this.state.keyValue} value="pname" name="Property" />
                        </th>
                      </th>
                      <th scope="col">Created At</th>
                      <th
                        scope="col"
                        className="pl_40 justLeft items-center bnone point"
                        style={{ display: "flex" }}
                        onClick={() => this.action("amount")}
                      >
                        <AsscendingDecending keyValue={this.state.keyValue} value="amount" name="Overall" />
                      </th>
                      <th scope="col">Paid</th>
                      <th scope="col">In Process</th>
                      <th scope="col">Refunded</th>
                      <th scope="col">Due On</th>
                      <th scope="col">Status</th>
                      <th scope="col" style={{ width: "6%" }}></th>
                    </tr>
                  </thead>
                  {depositList.length !== 0 ? (
                    <tbody>
                      {depositList.map((deposit, i) => (
                        <tr
                          key={i}
                          className="point"
                          onClick={() =>
                            this.props.history.push(
                              `/admin/bookings/detail/${deposit.booking_id}`
                            )
                          }
                        >
                          <td style={{ padding: "0px" }}>
                            <td className="cursor-point btopNone tdColumn tdColumn_18"   >
                              {returnCustomerName(deposit)}
                            </td>
                            <td className="btopNone tdColumn tdColumn_18"  >
                              {returnSecondDegreeObjValue(
                                deposit,
                                "property",
                                "name"
                              )}
                            </td>
                          </td>
                          <td>
                            {showLocaldate(
                              returnFirstDegreeObjValue(deposit, "createdAt")
                            )}
                          </td>
                          <td className="tdColumn tdColumn_18">
                            {displayPrice(
                              this.overAllAmount(
                                deposit?.amount,
                                deposit?.late_fee_amount
                              )
                            )}
                          </td>

                          <td>
                            {displayPrice(
                              returnFirstDegreeObjValue(deposit, "paid_amount")
                            )}
                          </td>
                          <td>
                            {displayPrice(
                              returnFirstDegreeObjValue(
                                deposit,
                                "processing_amount"
                              )
                            )}
                          </td>
                          <td>
                            {displayPrice(
                              returnFirstDegreeObjValue(
                                deposit,
                                "transferred_amount"
                              )
                            )}
                          </td>
                          <td>
                            {showLocaldate(
                              returnFirstDegreeObjValue(deposit, "installment_due_on")
                            )}
                          </td>
                          <td
                            className={
                              deposit?.status === "pending" ||
                                deposit?.status === "created"
                                ? "capitalize yellow general_status_style"
                                : deposit?.status === "paid"
                                  ? "capitalize green general_status_style"
                                  : deposit?.status === "paid_out"
                                    ? "capitalize clpaid general_status_style"
                                    : "capitalize red general_status_style"
                            }
                          >
                            {returnFirstDegreeObjValue(deposit, "status") ===
                              "paid_out"
                              ? "Refunded"
                              : returnFirstDegreeObjValue(deposit, "status") ===
                                "pending" &&
                                returnFirstDegreeObjValue(
                                  deposit,
                                  "paid_amount"
                                )
                                ? "Partially Paid"
                                : returnFirstDegreeObjValue(deposit, "status")}
                          </td>

                          <td onClick={(e) => e.stopPropagation()}>
                            <div className="flexStart">
                              <img
                                src={timelineIcon}
                                className="mr-20 point"
                                onClick={() =>
                                  this.showTimeline(deposit?.timeline)
                                }
                              />
                              <UncontrolledDropdown nav className="position_u">
                                <DropdownToggle className="pr-0 nav-notice" nav>
                                  <Media className="align-items-center">
                                    <img src={showMore} />
                                  </Media>
                                </DropdownToggle>
                                <DropdownMenu
                                  className="dropdown-menu-arrow min-width-drop"
                                  right
                                >
                                  {(deposit?.status === "created" ||
                                    deposit?.status === "pending") &&
                                    RoleBasedCRUDAccess("deposit", "edit") && (
                                      <DropdownItem
                                        onClick={() =>
                                          this.editDeposit(
                                            deposit.deposit_id,
                                            deposit
                                          )
                                        }
                                      >
                                        <span>Edit</span>
                                      </DropdownItem>
                                    )}

                                  {deposit?.status === "created" &&
                                    RoleBasedCRUDAccess("deposit", "edit") && (
                                      <DropdownItem
                                        onClick={() =>
                                          this.openConfirmModal(
                                            deposit.deposit_id
                                          )
                                        }
                                      >
                                        <span>Cancel</span>
                                      </DropdownItem>
                                    )}

                                  <DropdownItem
                                    onClick={() =>
                                      this.openViewMode(deposit.deposit_id)
                                    }
                                  >
                                    <span>History</span>
                                  </DropdownItem>
                                  {(deposit?.status === "paid" ||
                                    deposit?.status === "pending") &&
                                    RoleBasedCRUDAccess("deposit", "edit") && (
                                      <DropdownItem
                                        onClick={() =>
                                          this.openMarkTransferSideDragger(
                                            deposit.deposit_id,
                                            deposit
                                          )
                                        }
                                      >
                                        <span>Mark Transfer</span>
                                      </DropdownItem>
                                    )}

                                  {RoleBasedCRUDAccess("deposit", "edit") &&
                                    deposit?.late_fee?.length > 0 && (
                                      <DropdownItem
                                        onClick={() =>
                                          this.openLateFeeModal(
                                            deposit.deposit_id
                                          )
                                        }
                                      >
                                        <span>Remove Late Fee</span>
                                      </DropdownItem>
                                    )}

                                  {(deposit?.status === "created" ||
                                    deposit?.status === "pending") &&
                                    RoleBasedCRUDAccess("deposit", "edit") && (
                                      <DropdownItem
                                        onClick={() =>
                                          this.setState({
                                            reminderModal: true,
                                            depositId: deposit.deposit_id,
                                          })
                                        }
                                      >
                                        <span>Send Reminder</span>
                                      </DropdownItem>
                                    )}
                                  {deposit?.status === "created" || deposit?.status === "pending" ? (
                                    <DropdownItem
                                      onClick={() =>
                                        this.openMarkPaymentSideDragger(
                                          deposit.deposit_id
                                        )
                                      }
                                    >
                                      <span>Mark Payment</span>
                                    </DropdownItem>) : ("")}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td className="no-data">No Deposits Available</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              </Card>
            </div>
          </Row>
          <Row className="align-items-center jus-space new-margin-page paginator_box">
            <div className="paginate-flex-box ">
              <div
                className="paginate-box"
                onClick={() => this.handleDecrementPage()}
              >
                <p
                  className={page !== 1 ? "page_available" : "page_unavailable"}
                >
                  Previous
                </p>
              </div>
              <div
                className="paginate-box"
                onClick={() => this.handleIncrementPage()}
              >
                <p
                  className={
                    depositList.length < 10
                      ? "page_unavailable"
                      : "page_available"
                  }
                >
                  Next
                </p>
              </div>
            </div>
          </Row>
        </CustomListingContainer>
        <Modal
          isOpen={confirmModal}
          toggle={() => this.closeConfirmModal()}
          centered
          className="w450 modal-dialog-notice scheduleModal"
        >
          <CancelDeposit close={this.closeConfirmModal} id={depositId} />
        </Modal>

        <Modal
          isOpen={this.state.reminderModal}
          toggle={() => this.toggleCancelReminderModal()}
          centered
        >
          <DepositSendReminder
            close={this.toggleCancelReminderModal}
            id={depositId}
          />
        </Modal>

        <Card className={detailView ? "invoice-sidebar" : "toggle-sidebar"}>
          <DepositDetail
            isOpen={detailView}
            id={depositId}
            close={this.closeView}
          />
        </Card>
        <Card className={latefeeModal ? "invoice-sidebar" : "toggle-sidebar"}>
          <RemoveLateFee
            isOpen={latefeeModal}
            id={depositId}
            close={this.closeLateFeeModal}
            toast={this.displayErrorMessage}
          />
        </Card>
        <Card className={editModal ? "exportSideBar" : "toggle-sidebar"}>
          <EditDeposit
            isOpen={editModal}
            id={depositId}
            close={this.closeEditDeposit}
            toast={this.displayErrorMessage}
            data={depositData}
          />
        </Card>
        <Card
          className={displayTimeline ? "exportSideBar" : "toggle-sidebar"}
        >
          <DepositTimeline
            isOpen={displayTimeline}
            close={this.closeTimeLine}
            toast={this.displayErrorMessage}
            data={timelineData}
          />
        </Card>
        <Card
          className={markTransferModal ? "exportSideBar" : "toggle-sidebar"}
        >
          <MarkTransferSideBar
            close={this.closeMarkTranferSideDraggger}
            isOpen={markTransferModal}
            toast={this.displayErrorMessage}
            id={depositId}
            data={depositData}
          />
        </Card>
        <Card
          className={markPaymentModal ? "exportSideBar" : "toggle-sidebar"}
        >
          <DepositMarkPayment
            close={this.closeMarkPaymentSideDraggger}
            isOpen={markPaymentModal}
            toast={this.displayErrorMessage}
            id={depositId}
          />
        </Card>
      </>
    );
  }
}

export default Deposits;
