import React, { Component } from "react";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Card,
  Table,
  Row,
  Button,
  InputGroup,
  Input,
  Spinner,
  Modal,
} from "reactstrap";
// core components
import ApiService from "constants/ApiService";
import { returnFirstDegreeObjValue } from "constants/utils";
import returnSecondDegreeObjValue from "constants/utils";
import showMore from "../../assets/img/brand/showMore.svg";
import "react-datepicker/dist/react-datepicker.css";
import {
  returnDateTxt,
  showCalendarFormat,
  getIsoString,
  showError,
  showSuccess,
  showLocaldate,
  returnCustomerFirsLastName,
  buildQueryString,
  returnSearchObject,
  pushToFilteredPage,
} from "constants/utils";
import AcceptModal from "../../components/Modal/AcceptModal";
import RejectModal from "components/Modal/RejectModal";
import ToastList from "components/Toast/ToastList";
import { getDefaultProperty } from "constants/utils";
import { checkNoticeCancelDate } from "constants/utils";
import NoticeCancelModal from "./noticeCancelModal";
import { RoleBasedCRUDAccess } from "views/Roles/RolesUtils";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { CustomDropDown } from "components/FilterFields/Filterfields";
import { CustomDatePicker } from "components/FilterFields/Filterfields";
import { AsscendingDecending } from "components/FilterFields/Filterfields";
import { returnSortKey } from "constants/utils";
import timelineIcon from "../../assets/img/brand/invoiceTimeline.svg";
import { displayPrice } from "constants/utils";
import { noticeStatus } from "constants/utils";
import TimeLineSidebar from "../Invoices/timeLineSideBar"
import { returnCustomerName } from "constants/utils";

const MemoizedAccept = React.memo(AcceptModal);
const MemoizedReject = React.memo(RejectModal);

class Notice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noticeList: [],
      total: 0,
      filterToggle: false,
      from: "",
      to: "",
      page: 1,
      inputSearch: "",
      filterOption: "username",
      statusSearch: "accepted",
      modalOpen: false,
      rejectModal: false,
      isLoading: true,
      notice_date: "",
      act_date: "",
      notice_id: "",
      key: "",
      about: "",
      reject_reason: "",
      toast: false,
      response: "",
      toastType: "",
      query: {},
      cancelNotice: false,
      noticeId: "",
      sortingList: "",
      timeoutId: null,
      input_value: false,
      keyValue: "",
      timeLineModal: false,
    };
  }

  reloadPage = () => {
    this.props.history.listen((location, search) => {
      if (location.search === "") {
        this.setState({ query: {} }, () => {
          let query = this.props.location.search;
          query = returnSearchObject(query);
          if (Object.keys(query).length === 0) {
            this.handleReset();
          }
        });
      } else {
        this.state.input_value = true
      }
    });
  };

  componentDidMount() {
    this.reloadPage();
    this.setQueryStatus();
  }

  onChangeHandler = (e) => {
    e.target.value === "" && this.handleReset();
    this.setState({ [e.target.name]: e.target.value, input_value: true });
    clearTimeout(this.state.timeoutId);
    const timeoutId = setTimeout(() => {
      this.state.filterOption && this.handleSearch();
    }, 1000);
    this.setState({ timeoutId });
  };
  onChangeSelectHandler = (status) => {
    this.setState({ filterOption: status, input_value: true }, () => {
      this.handleFilterStatus();
    });
  }

  setQueryStatus = () => {
    let default_property = getDefaultProperty();
    let queryStatus = this.props.location.search;
    if (queryStatus) {
      let queryObj = returnSearchObject(queryStatus);
      this.setState(
        {
          inputSearch: (queryObj.propname || queryObj.username) ?? "",
          filterOption: queryObj.propname ? "propname" : queryObj.username && "username",
          from: queryObj.from ? new Date(queryObj.from) : "",
          to: queryObj.to ? new Date(queryObj.to) : "",
          statusSearch: queryObj.status ?? "accepted",
          page: parseInt(queryObj.page) ?? "",
          query: returnSearchObject(queryStatus),
        },
        () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getNoticeList();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...this.state.query,
            page: 1,
            limit: 10,
            status: "accepted",
            from: getIsoString(this.state.from),
            to: getIsoString(this.state.to),
            property_id: default_property,
          },
          // filterOption: default_property ? "property_id" : "",
        },
        () => {
          pushToFilteredPage(this.props, this.state.query);
          this.getNoticeList();
        }
      );
    }
  };

  getNoticeList = async () => {
    let { from, to } = this.state;
    if (from > to) {
      showError(this, "Please provide valid From and To date");
    } else {
      let response = await ApiService.getAPI(`host/notice${buildQueryString(this.state.query)}`);
      if (response.success) {
        let { notices } = response.data;
        this.setState({ noticeList: notices, isLoading: false });
      } else {
        this.setState({ isLoading: false });
        showError(this, response.message);
      }
    }
  };

  toggleFilterMenu = () => {
    this.setState({ filterToggle: !this.state.filterToggle });
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  toggleModal = (date, actDate, id, key) => {
    this.setState({
      modalOpen: !this.state.modalOpen,
      notice_date: returnDateTxt(date),
      act_date: showLocaldate(actDate),
      notice_id: id,
      key,
    });
  };

  handleOnNoticeChange = (e) => {
    this.updateStateValues("notice_date", e);
  };

  handleCloseModal = () => {
    this.setState({
      modalOpen: false,
      notice_date: "",
      act_date: "",
      notice_id: "",
      key: "",
    });
  };

  handleAcceptReject = async (key) => {
    var self = this;
    let { notice_id, notice_date } = self.state;
    if (notice_date === "") {
      showError(this, "Please select checkout date");
    } else {
      var data = {
        notice_date: showCalendarFormat(notice_date),
      };
      let response = await ApiService.patchAPI(
        `host/notice/${key}/${notice_id}`,
        data
      );
      if (response.success) {
        showSuccess(this, response.message);
        self.getNoticeList();
        self.handleCloseModal();
      } else {
        showError(this, response.message);
      }
    }
  };

  handleIncrementPage = () => {
    if (this.state.noticeList) {
      this.setState({ page: this.state.page + 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };
  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };
  toggleRejectModal = (id, key) => {
    this.setState({
      rejectModal: !this.state.rejectModal,
      notice_id: id,
      key,
    });
  };
  handleRejectReason = async () => {
    let { reject_reason, notice_id } = this.state;
    if (reject_reason === "") {
      showError(this, "Please enter reason to reject this notice");
    } else {
      let data = {
        reject_reason,
      };
      let response = await ApiService.patchAPI(`host/notice/reject/${notice_id}`, data);
      if (response.success) {
        showSuccess(this, response.message);
        this.getNoticeList();
        this.toggleCloseRejectModal();
      } else {
        showError(this, response.message);
      }
    }
  };
  toggleCloseRejectModal = () => {
    this.setState({
      rejectModal: false,
      notice_id: "",
      key: "",
      reject_reason: "",
    });
  };

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    staticQuery.from = getIsoString(this.state.from) ?? "";
    staticQuery.to = getIsoString(this.state.to) ?? "";
    if (this.state.inputSearch !== "" && this.state.filterOption !== "") {
      for (let key in staticQuery) {
        if (key === "username" || key === "propname") {
          delete staticQuery[key];
        }
      }
      staticQuery[this.state.filterOption] = this.state.inputSearch;
    }
    staticQuery.status = this.state.statusSearch ?? "accepted";
    staticQuery.page = this.state.page ?? "";
    staticQuery.sort = this.state.sortingList ?? "";
    this.setState({ query: staticQuery }, () => {
      pushToFilteredPage(this.props, { ...this.state.query });
      this.getNoticeList();
    }
    );
  };

  handleSearch = () => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  handleReset = () => {
    let default_property = getDefaultProperty();
    let self = this;
    self.setState(
      {
        statusSearch: "accepted",
        filterOption: "username",
        inputSearch: "",
        from: "",
        to: "",
        page: 1,
        sortingList: "",
        query: {
          page: 1,
          limit: 10,
          status: "accepted",
          property_id: default_property,
        },
        input_value: false,
        keyValue: "",
      }, () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getNoticeList();
      }
    );
  };
  handleOnFromChange = (e) => {
    this.updateStateValues("from", e);
  };
  handleOnToChange = (e) => {
    this.updateStateValues("to", e);
  };
  // Date Handler
  updateStateValues = (key, value) => {
    const updatedValue = value == null ? "" : value;
    this.setState({ [key]: updatedValue, input_value: true }, () => {
      if (this.state.from && this.state.to) {
        this.handleFilterStatus();
      }
    });
  };

  redirectDetailPage = (notice_id) => {
    this.props.history.push(`notices/detail/${notice_id}`);
  };

  setStatus = (status) => {
    this.setState({ statusSearch: status, page: 1, input_value: true }, () => {
      this.handleFilterStatus();
    });
  };

  openNoticeModal = (id) => {
    this.setState({ cancelNotice: true, noticeId: id });
  };

  toggleNoticeModal = (msg) => {
    this.setState({ cancelNotice: false });
    if (msg) {
      showSuccess(this, msg);
      this.handleFilterStatus();
    }
  };
  setSortingList = (status) => {
    this.setState({ sortingList: status, page: 1, input_value: true }, () => {
      this.handleFilterStatus()
    });
  }
  // action = (key) => {
  //   let { keyValue, sortingList } = this.state
  //   if (key && !keyValue) {
  //     this.setState({ keyValue: key + "_asc" }, () => { this.setSortingList(key + "_asc") })
  //   } else if (key && keyValue.includes("_asc")) {
  //     this.setState({ keyValue: key + "_dsc" }, () => { this.setSortingList(key + "_dsc") })
  //   } else if (key && keyValue.includes("_dsc")) {
  //     this.setState({ keyValue: "", sortingList: "" }, () => { this.setSortingList("") })
  //   }
  // }
  action = (key) => {
    let skey = returnSortKey(this, key);
    this.setState({ keyValue: skey, sortingList: skey, page: 1, input_value: true }, () => { this.handleFilterStatus(); })
  }

  openTimeLineModal = (id) => {
    this.setState({ noticeId: id, timeLineModal: true });
  };

  closeTimeLineModal = () => {
    this.setState({ timeLineModal: false, noticeId: "" });
  };


  render() {
    let {
      noticeList,
      inputSearch,
      filterOption,
      statusSearch,
      isLoading,
      modalOpen,
      page,
      reject_reason,
      rejectModal,
      toast,
      response,
      toastType,
      cancelNotice,
      noticeId,
      sortingList,
      from,
      timeLineModal,
      to,
      input_value,
    } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#012A4D" />
      </div>
    ) : (
      <>
        {toast && <ToastList message={response} type={toastType} />}
        <CustomListingContainer>
          <ListingHeaderLayout label={"Notices"} />
          <div style={{ margin: "20px" }}>
            <InputGroup className="flex-content align-left ">
              <div className="filters_container">
                <div className="left_filter_container">
                  <div className="w30">
                    <CustomDatePicker
                      from={from}
                      to={to}
                      fromChange={this.handleOnFromChange}
                      toChange={this.handleOnToChange}
                    ></CustomDatePicker>
                  </div>

                  <div className="select_with_input status_select_input ml-10">
                    <UncontrolledDropdown className="status_select_div ">
                      <DropdownToggle className="pr-0 drop_down_container">
                        <Media className="capitalize">
                          {filterOption === "username"
                            ? "Tenant"
                            : filterOption === "propname"
                              ? "Property" : "Tenant"
                          }
                          <i className="fas fa-angle-down" style={{ marginLeft: "20px" }}></i>
                        </Media>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" bottom style={{ minWidth: "130px" }}>
                        {" "}
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.onChangeSelectHandler("username")}
                        >
                          Tenant
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.onChangeSelectHandler("propname")}
                        >
                          Property
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <Input
                      aria-label="Text input with dropdown button"
                      value={inputSearch}
                      name="inputSearch"
                      placeholder="search by"
                      onChange={this.onChangeHandler}
                    />
                  </div>
                  <CustomDropDown
                    maxwidth={"130px"}
                    statusList={["all", "accepted", "pending", "checked_out", "rejected", "cancelled"]}
                    status={statusSearch}
                    setStatus={this.setStatus}
                  />
                </div>
                {input_value && (inputSearch || statusSearch !== "accepted" || (from || to) || sortingList) &&
                  <div className="right_filter_container">
                    <Button
                      color="primary"
                      onClick={this.handleReset}
                      size="sm"
                      className="button-wrapping mr-4 button-secondary reset-btn"
                      style={{ fontSize: "15px" }}
                    >
                      Clear filter
                    </Button>
                    {/* <Button
                    color="primary"
                    onClick={() => this.handleSearch()}
                    size="sm"
                    className="button-wrapping search-wrapper"
                  >
                    Search
                  </Button> */}
                  </div>}
              </div>
            </InputGroup>
          </div>
          <Row>
            <div className="col new-table">
              <Card className="shadow card_with_paginator">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" className="bnone" style={{ padding: "0px", display: "flex" }}>
                        <th
                          scope="col"
                          className="pl_40 justLeft items-center bnone point"
                          style={{ width: "235px", display: "flex" }}
                          onClick={() => this.action("user")}
                        >
                          <AsscendingDecending keyValue={this.state.keyValue} value="user" name="Tenant" />
                        </th>
                        {/* <th scope="col">Property</th> */}
                        <th
                          scope="col"
                          className="pl_40 justLeft items-center bnone point"
                          style={{ width: "235px", display: "flex" }}
                          onClick={() => this.action("pname")}
                        >
                          <AsscendingDecending keyValue={this.state.keyValue} value="pname" name="Property" />
                        </th>
                      </th>
                      <th scope="col">Created On</th>
                      <th scope="col" className="bnone" style={{ padding: "0px", display: "flex" }}>
                        <th
                          scope="col"
                          className="pl_40 justLeft items-center bnone point"
                          style={{ width: "170px", display: "flex" }}
                          onClick={() => this.action("checkin")}
                        >
                          <AsscendingDecending keyValue={this.state.keyValue} value="checkin" name="Check-In" />
                        </th>
                        <th
                          scope="col"
                          className="pl_40 justLeft items-center bnone point"
                          style={{ width: "170px", display: "flex" }}
                          onClick={() => this.action("checkout")}
                        >
                          <AsscendingDecending keyValue={this.state.keyValue} value="checkout" name="Check-Out" />
                        </th>
                      </th>
                      <th scope="col" style={{ width: "7%" }}>Notice Ends</th>
                      <th scope="col">Notice Amount</th>
                      <th scope="col">Use Deposit</th>
                      <th scope="col">Status</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  {noticeList.length !== 0 ? (
                    <tbody>
                      {noticeList.map((notice, i) => (
                        <tr key={i} className="point" onClick={() =>
                          this.redirectDetailPage(notice.notice_id)
                        }>
                          <td style={{ padding: "0px", width: "470px" }}>
                            <td className="cursor-point btopNone tdColumn tdColumn_23"   >
                              {returnCustomerName(notice)}
                            </td>
                            <td className="btopNone tdColumn tdColumn_23"
                            >
                              {returnSecondDegreeObjValue(
                                notice,
                                "property",
                                "name"
                              )}{" "}
                            </td>
                          </td>
                          <td style={{ width: "170px" }}>{showLocaldate(notice?.createdAt)}</td>
                          <td style={{ padding: "0px", width: "200px" }}>
                            <td className="cursor-point btopNone"
                              style={{ padding: "20px 0px 20px 40px", width: "170px" }}
                            >
                              {showLocaldate(notice?.check_in_date)}
                            </td>
                            <td className="btopNone" style={{ padding: "20px 0px 20px 40px", width: "170px" }}
                            >
                              {showLocaldate(notice?.notice_date)}
                            </td>
                          </td>
                          {/* <td
                            className={
                              notice.status === "accepted"
                                ? "text-capitalize green general_status_style"
                                : notice.status === "cancelled" ||
                                  notice.status === "rejected"
                                  ? "text-capitalize red general_status_style"
                                  : "text-capitalize yellow general_status_style"
                            }
                          >
                            {returnFirstDegreeObjValue(notice, "status")}
                          </td> */}
                          <td>{showLocaldate(notice.act_date)}</td>
                          <td>{displayPrice(notice.balance_amt)}</td>
                          <td>{notice.use_deposit ? "Yes" : "No"}</td>

                          <td className={notice.status === "accepted"
                            ? "text-capitalize green general_status_style"
                            : notice.status === "cancelled" ||
                              notice.status === "rejected" ||
                              notice.status === "checked_out"
                              ? "text-capitalize red general_status_style"
                              : "text-capitalize yellow general_status_style"
                          }>
                            {noticeStatus(notice.status)}
                          </td>
                          <td style={{ textAlign: "left", padding: "0px" }}>
                            <div className="flexStart" style={{ marginLeft: "25px" }} onClick={(e) => e.stopPropagation()} >
                              <img src={timelineIcon} className="mr-20 point" onClick={() => this.openTimeLineModal(notice.notice_id)} />
                              <div className="text-capitalize">
                                {RoleBasedCRUDAccess("notice", "edit") && (
                                  notice.status === "pending" || (notice.status === "accepted" &&
                                    checkNoticeCancelDate(notice.notice_date)) ? (
                                    <UncontrolledDropdown
                                      nav
                                      className="flexy-content "
                                      onClick={(e) => e.stopPropagation()}   >
                                      <DropdownToggle className="pr-0 nav-notice" nav    >
                                        <Media className="align-items-center">
                                          <img src={showMore} alt="" />
                                          <Media className="ml-2 d-none d-lg-block"></Media>
                                        </Media>
                                      </DropdownToggle>
                                      <DropdownMenu className="dropdown-menu-arrow min-width-drop" right>
                                        {notice.status === "pending" && (
                                          <>
                                            <DropdownItem onClick={() => this.toggleModal(notice.notice_date, notice.act_date, notice.notice_id, "accept")}>
                                              <span>Accept</span>
                                            </DropdownItem>

                                            <DropdownItem divider />
                                            <DropdownItem
                                              onClick={() =>
                                                this.toggleRejectModal(
                                                  notice.notice_id,
                                                  "reject"
                                                )
                                              }
                                            >
                                              <span>Reject</span>
                                            </DropdownItem>
                                          </>
                                        )}
                                        {notice.status === "accepted" && (
                                          <>
                                            <DropdownItem onClick={() => this.openNoticeModal(notice.notice_id)}>
                                              Cancel
                                            </DropdownItem>
                                          </>
                                        )}
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  ) : (
                                    ""
                                  )
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td className="no-data">No Notices Available</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        {RoleBasedCRUDAccess("notice", "edit") && <td></td>}
                      </tr>
                    </tbody>
                  )}
                </Table>
              </Card>
            </div>
          </Row>
          <Row className="align-items-center jus-space new-margin-page paginator_box">
            <div className="paginate-flex-box ">
              <div
                className="paginate-box"
                onClick={() => this.handleDecrementPage()}
              >
                <p
                  className={page !== 1 ? "page_available" : "page_unavailable"}
                >
                  Previous
                </p>
              </div>
              <div
                className="paginate-box"
                onClick={() => this.handleIncrementPage()}
              >
                <p
                  className={
                    noticeList.length < 10
                      ? "page_unavailable"
                      : "page_available"
                  }
                >
                  Next
                </p>
              </div>
            </div>
          </Row>
        </CustomListingContainer>
        <MemoizedAccept
          toggleModal={() => this.toggleModal("", "", "", "")}
          closeModal={() => this.handleCloseModal()}
          modalState={modalOpen}
          actDate={this.state.act_date}
          notice_date={this.state.notice_date}
          onChangeDate={this.handleOnNoticeChange}
          onSubmit={() => this.handleAcceptReject("accept")}
        />
        <MemoizedReject
          toggleModal={() => this.toggleRejectModal()}
          closeModal={() => this.toggleCloseRejectModal()}
          modalState={rejectModal}
          reject_reason={reject_reason}
          onInputChange={this.handleInputChange}
          onSubmit={() => this.handleRejectReason()}
        />
        <Card className={timeLineModal ? "exportSideBar" : "toggle-sidebar"}>
          <TimeLineSidebar
            close={this.closeTimeLineModal}
            id={noticeId}
            type="notice"
            isOpen={timeLineModal}
          />
        </Card>
        <Modal
          isOpen={cancelNotice}
          toggle={() => this.toggleNoticeModal()}
          centered
        >
          <NoticeCancelModal id={noticeId} close={this.toggleNoticeModal} />
        </Modal>
        {/* </Container> */}
      </>
    );
  }
}

export default Notice;
