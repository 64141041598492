import React, { Component } from "react";
import {
  Card,
  Table,
  Row,
  Button,
  InputGroup,
  Dropdown,
  Input,
  Spinner,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
} from "reactstrap";
import ApiService from "constants/ApiService";
import returnSecondDegreeObjValue from "constants/utils";
import ToastList from "components/Toast/ToastList";
import "react-datepicker/dist/react-datepicker.css";
import {
  getIsoString,
  showLocaldate,
  redirectToBookings,
  showError,
  returnCustomerFirsLastName,
  returnSearchObject,
  pushToFilteredPage,
  buildQueryString,
  getDefaultProperty,
} from "constants/utils";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { CustomDatePicker } from "components/FilterFields/Filterfields";
import { CustomDropDown } from "components/FilterFields/Filterfields";
import { AsscendingDecending } from "components/FilterFields/Filterfields";
import { returnSortKey } from "constants/utils";
import { returnCustomerName } from "constants/utils";

class Tentants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      page: 1,
      limit: 10,
      from: "",
      to: "",
      inputSearch: "",
      filterOption: "username",
      statusSearch: "",
      isLoading: true,
      tenants: [],
      query: {},
      sortingList: "",
      timeoutId: null,
      input_value: false,
      keyValue: ""
    };
  }

  reloadPage = () => {
    this.props.history.listen((location, search) => {
      if (location.search === "") {
        this.setState({ query: {} }, () => {
          let query = this.props.location.search;
          query = returnSearchObject(query);
          if (Object.keys(query).length === 0) {
            this.handleReset();
          }
        });
      } else {
        this.state.input_value = true
      }
    });
  };

  componentDidMount() {
    this.setQueryStatus();
    this.reloadPage();
  }


  setQueryStatus = () => {
    let queryStatus = this.props.location.search;
    let default_property = getDefaultProperty();

    if (queryStatus) {
      let queryObj = returnSearchObject(queryStatus);
      this.setState(
        {
          inputSearch:
            (queryObj.propname ||
              queryObj.property_id ||
              queryObj.username ||
              queryObj.booking_id) ??
            "",
          filterOption: queryObj.propname
            ? "propname"
            : queryObj.property_id
              ? "property_id"
              : queryObj.username
                ? "username"
                : queryObj.booking_id && "booking_id",
          from: queryObj.from ? new Date(queryObj.from) : "",
          to: queryObj.to ? new Date(queryObj.to) : "",
          statusSearch: queryObj.status ?? "",
          page: parseInt(queryObj.page) ?? "",
          query: returnSearchObject(queryStatus),
        },
        () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getTenants();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...this.state.query,
            page: 1,
            limit: 10,
            from: getIsoString(this.state.from),
            to: getIsoString(this.state.to),
            property_id: default_property,
          },
        },
        () => {
          pushToFilteredPage(this.props, this.state.query);
          this.getTenants();
        }
      );
    }
  };

  getTenants = async () => {
    let { from, to } = this.state;
    if (from > to) {
      showError(this, "Please provide valid From and To date");
    } else {
      let response = await ApiService.getAPI(`host/tenants${buildQueryString(this.state.query)}`);
      this.setState({ isLoading: false });
      if (response.success) {
        this.setState({ tenants: response.data.bookings });
      } else {
        this.setState({ isLoading: false });
        showError(this, response.message);
      }
    }
  };

  onChangeHandler = (e) => {
    e.target.value === "" && this.handleReset();
    this.setState({ [e.target.name]: e.target.value, input_value: true });
    clearTimeout(this.state.timeoutId);
    const timeoutId = setTimeout(() => {
      this.state.filterOption && this.handleSearch();
    }, 1000);
    this.setState({ timeoutId });
  };
  onChangeSelectHandler = (status) => {
    this.setState({ filterOption: status, input_value: true }, () => {
      this.handleFilterStatus();
    });
  }

  handleOnFromChange = (e) => {
    this.updateStateValues("from", e);
  };
  handleOnToChange = (e) => {
    this.updateStateValues("to", e);
  };
  updateStateValues = (key, value) => {
    const updatedValue = value == null ? "" : value;
    this.setState({ [key]: updatedValue, input_value: true }, () => {
      if (this.state.from && this.state.to) {
        this.handleFilterStatus();
      }
    });
  };

  setStatus = (status) => {
    this.setState({ statusSearch: status, page: 1, input_value: true }, () => {
      this.handleFilterStatus();
    });
  };

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    staticQuery.from = getIsoString(this.state.from) ?? "";
    staticQuery.to = getIsoString(this.state.to) ?? "";
    if (this.state.inputSearch !== "" && this.state.filterOption !== "") {
      for (let key in staticQuery) {
        if (
          key === "propname" ||
          key === "booking_id" ||
          key === "username" ||
          key === "property_id"
        ) {
          delete staticQuery[key];
        }
      }
      staticQuery[this.state.filterOption] = this.state.inputSearch;
    }
    staticQuery.status = this.state.statusSearch ?? "";
    staticQuery.page = this.state.page ?? "";
    staticQuery.sort = this.state.sortingList ?? "";

    this.setState({ query: staticQuery }, () => {
      pushToFilteredPage(this.props, { ...this.state.query });
      this.getTenants();
    });
  };

  handleSearch = () => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
  };
  handleReset = () => {
    let default_property = getDefaultProperty();
    let self = this;
    self.setState(
      {
        from: "",
        to: "",
        statusSearch: "",
        filterOption: "username",
        inputSearch: default_property ? default_property : "",
        page: 1,
        sortingList: "",
        query: {
          page: 1,
          limit: 10,
          property_id: default_property,
        },
        input_value: false,
        keyValue: ""
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getTenants();
      }
    );
  };
  handleIncrementPage = () => {
    if (this.state.tenants.length >= 10) {
      this.setState({ page: this.state.page + 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };
  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };
  setSortingList = (status) => {
    this.setState({ sortingList: status, page: 1, input_value: true }, () => {
      this.handleFilterStatus();
    })
  }
  action = (key) => {
    let skey = returnSortKey(this, key);
    this.setState({ keyValue: skey, sortingList: skey, page: 1, input_value: true }, () => { this.handleFilterStatus() })
  }
  render() {
    let {
      page,
      toast,
      response,
      toastType,
      inputSearch,
      filterOption,
      statusSearch,
      tenants,
      isLoading,
      sortingList,
      from,
      to,
      input_value
    } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#0A808" />
      </div>
    ) : (
      <>
        {toast && <ToastList message={response} type={toastType} />}
        <CustomListingContainer>
          <ListingHeaderLayout label={"Tenants"} />
          <div style={{ margin: "20px" }}>
            <InputGroup className="flex-content align-left">
              <div className="filters_container">
                <div className="left_filter_container">
                  <div className="w30">
                    <CustomDatePicker
                      from={from}
                      to={to}
                      fromChange={this.handleOnFromChange}
                      toChange={this.handleOnToChange}
                    ></CustomDatePicker>
                  </div>
                  <div className="select_with_input status_select_input ml-10">
                    <UncontrolledDropdown className="status_select_div ">
                      <DropdownToggle className="pr-0 drop_down_container">
                        <Media className="capitalize">
                          {filterOption === "propname"
                            ? "Property"
                            : filterOption === "username"
                              ? "Name" : "Property"
                          }
                          <i className="fas fa-angle-down" style={{ marginLeft: "20px" }}></i>
                        </Media>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" bottom style={{ minWidth: "130px" }}>
                        {" "}
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.onChangeSelectHandler("username")}
                        >
                          Name
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.onChangeSelectHandler("propname")}
                        >
                          Property
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <Input
                      aria-label="Text input with dropdown button"
                      value={inputSearch}
                      name="inputSearch"
                      placeholder="search by"
                      onChange={this.onChangeHandler}
                    />
                  </div>
                  <CustomDropDown
                    maxwidth={"130px"}
                    statusList={["all", "booked", "in_notice", "pre_booking", "checked_out"]}
                    status={statusSearch}
                    setStatus={this.setStatus}
                  />
                </div>
                {input_value && (inputSearch || statusSearch || (from || to) || sortingList) &&
                  <div className="right_filter_container">
                    {" "}
                    <Button
                      color="primary"
                      onClick={this.handleReset}
                      size="sm"
                      className="button-wrapping mr-4 button-secondary reset-btn"
                      style={{ fontSize: "15px" }}                  >
                      Clear filter
                    </Button>
                  </div>}
              </div>
            </InputGroup>
          </div >
          <Row>
            <div className="col new-table">
              <Card className="shadow card_with_paginator">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" className="bnone" style={{ padding: "0px", display: "flex" }}>
                        <th
                          scope="col"
                          className="pl_40 justLeft items-center bnone point"
                          style={{ width: "300px", display: "flex" }}
                          onClick={() => this.action("user")}
                        >
                          <AsscendingDecending keyValue={this.state.keyValue} value="user" name="Name" />
                        </th>
                        <th
                          scope="col"
                          className="pl_40 justLeft items-center bnone point"
                          style={{ width: "300px", display: "flex" }}
                          onClick={() => this.action("pname")}
                        >
                          <AsscendingDecending keyValue={this.state.keyValue} value="pname" name="Property" />
                        </th>
                      </th>
                      <th scope="col" style={{ width: "300px" }}>Check-In</th>
                      <th scope="col" style={{ width: "300px" }}>Check-Out</th>
                      <th scope="col" style={{ width: "300px" }}>Status</th>
                    </tr>
                  </thead>
                  {this.state.tenants.length > 0 ? (
                    <tbody>
                      {tenants.map((tenant, i) => (
                        <tr key={i}>
                          <td style={{ padding: "0px", width: "300px" }}>
                            <td className="cursor-point btopNone tdColumn tdColumn_30"
                              onClick={() =>
                                redirectToBookings(this, tenant.booking_id)
                              }
                            >
                              {returnCustomerName(tenant)}
                            </td>
                            <td className="btopNone point tdColumn tdColumn_30">
                              {returnSecondDegreeObjValue(
                                tenant,
                                "property",
                                "name"
                              )}
                            </td>
                          </td>
                          <td>{showLocaldate(tenant.check_in)}</td>
                          <td>{showLocaldate(tenant.check_out)}</td>
                          <td
                            className={tenant.status === "in_notice" ? "yellow text-capitalize general_status_style"
                              : (tenant.status === "booked" || tenant.status === "pre_booking") ? "green text-capitalize general_status_style"
                                : tenant.status === "checked_out" ? "red text-capitalize general_status_style" : "f13"
                            }
                          >
                            {tenant.status && tenant.status === "in_notice" ? "In Notice" :
                              tenant.status === "checked_out" ? "Checked Out" :
                                tenant.status === "pre_booking" ? "Pre Booked" :
                                  tenant.status}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td className="no-data">No Tenants Available</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              </Card>
            </div>
          </Row>
          <Row className="align-items-center jus-space new-margin-page paginator_box">
            <div className="paginate-flex-box ">
              <div
                className="paginate-box"
                onClick={() => this.handleDecrementPage()}
              >
                <p
                  className={page !== 1 ? "page_available" : "page_unavailable"}
                >
                  Previous
                </p>
              </div>
              <div
                className="paginate-box"
                onClick={() => this.handleIncrementPage()}
              >
                <p
                  className={
                    tenants.length < 10 ? "page_unavailable" : "page_available"
                  }
                >
                  Next
                </p>
              </div>
            </div>
          </Row>
        </CustomListingContainer >
      </>
    );
  }
}

export default Tentants;
