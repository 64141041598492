import React, { Component } from "react";
import {
  Card,
  Table,
  Container,
  Row,
  Col,
  Button,
  InputGroup,
  Dropdown,
  Input,
  Spinner,
  InputGroupText,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
// core components
import ApiService from "constants/ApiService";
import { returnFirstDegreeObjValue } from "constants/utils";
import { filterQuery } from "constants/utils";
import { displayPrice, showUtcDate, getIsoString } from "constants/utils";
import { returnThirdDegreeObjValue } from "constants/utils";
import returnSecondDegreeObjValue from "constants/utils";
import ToastList from "components/Toast/ToastList";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateSvg from "../../../assets/img/brand/date-pick.svg";
import { showError } from "constants/utils";
import { showLocaldate } from "constants/utils";
import { buildQueryString } from "constants/utils";
import { pushToFilteredPage } from "constants/utils";
import { returnSearchObject } from "constants/utils";
import { RoleBasedCRUDAccess } from "views/Roles/RolesUtils";
import { CustomDatePicker } from "components/FilterFields/Filterfields";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { CustomDropDown } from "components/FilterFields/Filterfields";

let item_id;

class MaintenanceHistory extends Component {
  constructor(props) {
    super(props);
    item_id = this.props.match.params?.item_id;
    this.state = {
      maintenanceList: [],
      total: 0,
      page: 1,
      limit: 10,
      from: "",
      to: "",
      filterToggle: false,
      modalOpen: false,
      inputSearch: "",
      filterOption: "",
      statusSearch: "",
      toast: false,
      response: "",
      toastType: "",
      input_value: "false",
      isLoading: true,
    };
  }
  componentDidMount() {
    this.setQueryStatus();
    // this.getPurchaseList();
  }

  // componentDidUpdate(prevProps) {
  //   let search = this.props.location.search;
  //   if (prevProps.location.search !== search) {
  //     this.setQueryStatus();
  //   }
  // }

  setQueryStatus = () => {
    let queryStatus = this.props.location.search;
    if (queryStatus) {
      let queryObj = returnSearchObject(queryStatus);
      this.setState(
        {
          from: queryObj.from ? new Date(queryObj.from) : "",
          to: queryObj.to ? new Date(queryObj.to) : "",
          statusSearch: queryObj.status ?? "",
          page: parseInt(queryObj.page) ?? "",
          query: returnSearchObject(queryStatus),
        },
        () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getPurchaseList();
        }
      );
    } else {
      this.setState(
        { query: { ...this.state.query, page: 1, limit: 10 } },
        () => {
          pushToFilteredPage(this.props, this.state.query);
          this.getPurchaseList();
        }
      );
    }
  };

  getPurchaseList = async () => {
    let { page, statusSearch, from, to } = this.state;
    let queryParams = "";
    if (statusSearch !== "") {
      queryParams += filterQuery("status", statusSearch);
    }
    if (from !== "") queryParams += filterQuery("from", getIsoString(from));
    if (to !== "") queryParams += filterQuery("to", getIsoString(to));
    if (from > to) {
      showError(this, "Please provide valid From and To date");
    } else {
      let response = await ApiService.getAPI(
        `host/maintenance/history/${item_id}${buildQueryString(
          this.state.query
        )}`
      );
      if (response.success) {
        let { data } = response;
        this.setState({
          maintenanceList: data.list,
          isLoading: false,
        });
      } else {
        this.setState({ isLoading: false });
        showError(this, response.message);
      }
    }
  };

  pageChange = (page) => {
    var self = this;
    self.setState({ page: page }, () => {
      self.getPurchaseList();
    });
  };
  updateStateValues = (key, value) => {
    const updatedValue = value == null ? "" : value;
    this.setState({ [key]: updatedValue, input_value: true }, () => {
      if (this.state.from && this.state.to) {
        this.handleFilterStatus();
      }
    });
  };
  handleOnToChange = (e) => {
    this.updateStateValues("to", e);
  };
  handleOnFromChange = (e) => {
    this.updateStateValues("from", e);
  };

  handleIncrementPage = () => {
    this.setState({ page: this.state.page + 1 }, () =>
      this.getPurchaseList(this.state.page)
    );
  };
  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 }, () =>
        this.getPurchaseList(this.state.page)
      );
    }
  };

  setStatus = (status) => {
    this.setState({ statusSearch: status, page: 1, input_value: true }, () => {
      this.handleFilterStatus();
    });
  };

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    staticQuery.from = getIsoString(this.state.from) ?? "";
    staticQuery.to = getIsoString(this.state.to) ?? "";

    staticQuery.status = this.state.statusSearch ?? "";
    staticQuery.page = this.state.page ?? "";
    this.setState({ query: staticQuery }, () => {
      pushToFilteredPage(this.props, { ...this.state.query });
      this.getPurchaseList();
    });
  };

  handleSearch = () => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  handleReset = () => {
    let self = this;
    self.setState(
      {
        from: "",
        to: "",
        filterOption: "",
        inputSearch: "",
        statusSearch: "",
        query: {
          page: 1,
          limit: 10,
        },
        input_value: false,
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getPurchaseList();
      }
    );
  };
  redirectDetailPage = (id) => {
    this.props.history.push(
      `/admin/maintenanceList/maintenance_historys/detail/${id}`
    );
  };

  render() {
    let {
      maintenanceList,
      page,
      inputSearch,
      filterOption,
      statusSearch,
      isLoading,
      toast,
      response,
      toastType,
      from,
      to,
      input_value
    } = this.state;

    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#012A4D" />
      </div>
    ) : (
      <>
        {toast && <ToastList message={response} type={toastType} />}
        <div className="align-items-center" style={{ margin: "20px 25px 0px 30px" }}>
          <div className="manage-con">
            <Breadcrumb>
              <BreadcrumbItem
                onClick={() => this.props.history.goBack()}
                className="bread-cursor"
              >
                Maintenace
              </BreadcrumbItem>
              <BreadcrumbItem active style={{ color: "#012a4d" }}>History</BreadcrumbItem>
            </Breadcrumb>
          </div>
        </div>
        <CustomListingContainer>
          <div style={{ margin: "20px" }}>

            <InputGroup className="paymentError flex-content align-left">
              <div className="filters_container">
                <div className="left_filter_container">
                  <div className="w30">
                    <CustomDatePicker
                      from={from}
                      to={to}
                      fromChange={this.handleOnFromChange}
                      toChange={this.handleOnToChange}
                    />
                  </div>
                  <CustomDropDown
                    maxwidth={"130px"}
                    statusList={["all", "pending", "completed", "accepted", "assigned"]}
                    status={statusSearch}
                    setStatus={this.setStatus}
                  />
                </div>
                {input_value && (statusSearch || (from || to)) &&
                  <div className="right_filter_container">
                    <Button
                      color="primary"
                      onClick={this.handleReset}
                      size="sm"
                      className="button-wrapping search-wrapper content-flex mr-4 button-secondary ml-4 reset-btn"
                    >
                      Clear Filter
                    </Button>
                  </div>}
              </div>
            </InputGroup>
          </div>

          <Row>
            <div className="col new-table">
              <Card className="shadow card_with_paginator">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">S.No</th>
                      <th scope="col">Property</th>
                      <th scope="col">Contractor</th>
                      <th scope="col">Service Name</th>
                      <th scope="col">Service Date</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  {maintenanceList.length !== 0 ? (
                    <tbody>
                      {maintenanceList &&
                        maintenanceList.map((maintenance, i) => (
                          <tr
                            className="cursor_pointer"
                            key={i}
                            onClick={
                              RoleBasedCRUDAccess("maintenance", "view")
                                ? () =>
                                  this.redirectDetailPage(
                                    maintenance.history_id
                                  )
                                : ""
                            }
                          >
                            <td>{page * 10 - 10 + (i + 1)}</td>
                            <td>
                              {" "}
                              {returnSecondDegreeObjValue(
                                maintenance,
                                "property",
                                "name"
                              )}
                            </td>
                            <td>
                              {returnSecondDegreeObjValue(
                                maintenance,
                                "contractor",
                                "name"
                              )}
                            </td>
                            <td>{maintenance?.service_name}</td>
                            <td className="text-capitalize">
                              {showLocaldate(
                                returnFirstDegreeObjValue(
                                  maintenance,
                                  "service_date"
                                )
                              )}
                            </td>

                            <td
                              className={
                                maintenance &&
                                  (maintenance.status === "pending" ||
                                    maintenance.status === "in_progress")
                                  ? "status_yellow header_data capitalize"
                                  : maintenance.status === "assigned" ||
                                    maintenance.status === "accepted" ||
                                    maintenance.status === "completed"
                                    ? "status_completed header_data capitalize"
                                    : "status_red header_data capitalize"
                              }
                            >
                              {" "}
                              {maintenance?.status === "in_progress"
                                ? "In Progress"
                                : returnFirstDegreeObjValue(
                                  maintenance,
                                  "status"
                                )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td className="no-data">
                          No Maintenance History Available
                        </td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              </Card>
            </div>
          </Row>
          <Row className="align-items-center jus-space new-margin-page paginator_box">
            <div className="paginate-flex-box ">
              <div
                className="paginate-box"
                onClick={() => this.handleDecrementPage()}
              >
                <p
                  className={page !== 1 ? "page_available" : "page_unavailable"}
                >
                  Previous
                </p>
              </div>
              <div
                className="paginate-box"
                onClick={() => this.handleIncrementPage()}
              >
                <p
                  className={
                    maintenanceList.length < 10
                      ? "page_unavailable"
                      : "page_available"
                  }
                >
                  Next
                </p>
              </div>
            </div>
          </Row>
        </CustomListingContainer >
      </>
    );
  }
}

export default MaintenanceHistory;
