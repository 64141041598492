import React, { Component } from "react";
import {
  Card,
  Table,
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  Spinner,
  InputGroup,
  Dropdown,
  Input,
} from "reactstrap";
import ApiService from "constants/ApiService";
import { returnFirstDegreeObjValue } from "constants/utils";
import returnSecondDegreeObjValue from "constants/utils";
import { getUSFormat } from "constants/utils";
import Edit from "../../assets/img/brand/editData.svg";
import { filterQuery } from "constants/utils";
class Managers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      managerList: [],
      filterOption: "",
      statusSearch: "",
      page: 1,
      limit: 10,
      isLoading: true,
    };
  }
  componentDidMount() {
    this.getManagerList();
  }

  getManagerList = async () => {
    let { page, filterOption, inputSearch } = this.state;
    let queryParams = "";
    if (filterOption !== "" && inputSearch !== "")
      queryParams += filterQuery(filterOption, inputSearch);
    let response = await ApiService.getAPI(
      `host/role?page=${page}&limit=10${queryParams}`
    );
    if (response.success) {
      this.setState({ managerList: [], isLoading: false });
    }
  };

  redirectAddPage = () => {
    // this.props.history.push("manager/add");
  };
  redirectEditPage = (manager_id) => {
    // this.props.history.push(`manager/${manager_id}`);
  };
  redirectPropertyPage = (manager_id) => {
    this.props.history.push({
      pathname: `properties/${manager_id}`,
      merge: "ManagerProp",
      manager_id,
    });
  };
  redirectPayoutsPage = (manager_id) => {
    this.props.history.push({
      pathname: `payouts/${manager_id}`,
      merge: "PayoutProp",
      manager_id,
    });
  };
  handleIncrementPage = () => {
    this.setState({ page: this.state.page + 1 }, () =>
      this.getManagerList(this.state.page)
    );
  };
  handleDecrementPage = () => {
    this.setState({ page: this.state.page - 1 }, () =>
      this.getManagerList(this.state.page)
    );
  };

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleReset = () => {
    let self = this;
    self.setState(
      {
        from: "",
        to: "",
        statusSearch: "",
        filterOption: "",
        inputSearch: "",
      },
      () => this.getManagerList()
    );
  };

  render() {
    let { managerList, page, isLoading, filterOption, inputSearch } =
      this.state;

    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" />
      </div>
    ) : (
      <>
        <Container className="mt-8 card-pad-wrapper" fluid>
          <div>
            <Breadcrumb className="pad-to-8"></Breadcrumb>
          </div>
          <Row className="align-items-center header-div">
            <Col xs="8" className="manage-con">
              <Breadcrumb className="custom_breadcrumb">Managers</Breadcrumb>
            </Col>

            <Col className="text-right pad-new-page" xs="4">
              <Button
                color="primary"
                onClick={() => this.redirectAddPage()}
                size="sm"
                className="button-wrapping"
              >
                Add
              </Button>
            </Col>
          </Row>
          <InputGroup className="mb-5 mt-5 mb_mt_28 flex-content align-left">
            <div className="filters_container">
              <div className="left_filter_container">
                <div className="select_with_input">
                  <Dropdown
                    variant="input-group"
                    className="input-group-select select_input_drop"
                  >
                    <select
                      id="inputGroupSelect01"
                      className="form_select fixed_select point"
                      name="filterOption"
                      value={filterOption}
                      onChange={this.onChangeHandler}
                    >
                      <option value="">Select</option>
                      <option value="name">Name</option>{" "}
                      <option value="email">Email</option>
                    </select>
                    {/* <i className="fas fa-angle-down"></i> */}
                  </Dropdown>
                  <Input
                    aria-label="Text input with dropdown button"
                    className="input-filter-toggle select_input"
                    value={inputSearch}
                    name="inputSearch"
                    placeholder="search by"
                    onChange={this.onChangeHandler}
                  />
                </div>
              </div>
              <div className="right_filter_container">
                <Button
                  color="primary"
                  onClick={this.handleReset}
                  size="sm"
                  className="button-wrapping search-wrapper content-flex mr-4 button-secondary ml-4 reset-btn"
                >
                  Reset
                </Button>
                <Button
                  color="primary"
                  onClick={() => this.getManagerList()}
                  size="sm"
                  className="button-wrapping search-wrapper"
                >
                  Search
                </Button>
              </div>
            </div>
          </InputGroup>

          <Row>
            <div className="col new-table">
              <Card className="shadow card_with_paginator">
                {managerList.length !== 0 ? (
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Contact</th>
                        <th scope="col">Properties</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {managerList.map((manager, i) => (
                        <tr key={i}>
                          <td className="text-capitalize">
                            {returnFirstDegreeObjValue(manager, "name")}
                          </td>
                          <td>
                            {" "}
                            {returnFirstDegreeObjValue(manager, "email")}
                          </td>
                          <td>
                            {getUSFormat(
                              returnSecondDegreeObjValue(
                                manager,
                                "phone",
                                "national_number"
                              )
                            )}
                          </td>
                          <td
                            style={{ color: "black", cursor: "pointer" }}
                            onClick={() =>
                              this.redirectPropertyPage(manager.manager_id)
                            }
                            className="text-capitalize"
                          >
                            view
                          </td>
                          {/* <td
                            style={{ color: "black", cursor: "pointer" }}
                            onClick={() =>
                              this.redirectPayoutsPage(manager.manager_id)
                            }
                            className="text-capitalize"
                          >
                            view
                          </td> */}
                          <td
                            className="text-capitalize cursor-point"
                            onClick={() =>
                              this.redirectEditPage(manager.manager_id)
                            }
                          >
                            <img src={Edit} alt="" />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <>
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Contact</th>
                          <th scope="col">Properties</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                    </Table>
                    <Card className="card_with_paginator no_data_card">
                      <div className="no-data">No Managers Available</div>
                    </Card>
                  </>
                )}
              </Card>
            </div>
          </Row>
          <Row className="align-items-center header-div jus-space new-margin-page paginator_box">
            <div className="paginate-flex-box ">
              <div
                className="paginate-box"
                onClick={() => this.handleDecrementPage()}
              >
                <p
                  className={page !== 1 ? "page_available" : "page_unavailable"}
                >
                  Previous
                </p>
              </div>
              <div
                className="paginate-box"
                onClick={() => this.handleIncrementPage()}
              >
                <p
                  className={
                    managerList.length < 10
                      ? "page_unavailable"
                      : "page_available"
                  }
                >
                  Next
                </p>
              </div>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default Managers;
