// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  Container,
  Spinner,
} from "reactstrap";
import ReactTooltip from "react-tooltip";
import React, { Component } from "react";
import Logo from "../../assets/img/new Logo/host_new_template.svg";
import ApiService from "constants/ApiService";
import SocialButton from "../../constants/SocialLogin";
import {
  getUSFormat,
  isInvalidEmail,
  isInvalidName,
  replaceToNormalNumber,
  saveRole,
  showSuccess,
  saveToken,
  showError,
  saveChangePassword,
  isFirstLowercaseOnly,
  saveSecureVault,
} from "../../constants/utils.js";
import ToastList from "components/Toast/ToastList";
const initialState = {
  name: "",
  email: "",
  phone: "",
  password: "",
  conPassword: "",
  toast: false,
  response: "",
  toastType: "",
};
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hostType: "",
      forgotLink: false,
      signUpLink: false,
      signInLink: true,
      check: true,
      pType: "password",
      nType: "password",
      isLoading: false,
      initialState,
      user_type: "",
    };
  }
  resetState = () => {
    this.setState(initialState);
  };

  componentDidMount() {
    this.setHostType();
  }

  setHostType = () => {
    let url = window.location.hostname.split(".")[0];

    if (url) {
      if (url === "host") {
        this.setState({ user_type: "host" });
      } else if (url === "manager") {
        this.setState({ user_type: "manager" });
      }
    }

    this.setState({ hostType: url });
  };

  handleLogin = async () => {
    let { email, password, check, user_type } = this.state;
    if (email === "") {
      showError(this, "Please enter email");
    } else if (isInvalidEmail(email)) {
      showError(this, "Please enter valid email");
    }
    // else if (this.isFirstLowercaseOnly(email)) {
    //   showError(this, "Please enter 1st letter small");
    // }
    else if (password === "" || password === undefined) {
      showError(this, "Please enter password");
    } else if (check === false) {
      showError(this, "Please accept the terms and conditions");
    } else {
      this.setState({ isLoading: true });
      let data = {
        email: email,
        password: password,
        role: user_type,
      };
      let response = await ApiService.postAPI("auth/host/signin", data);

      this.setState({ isLoading: false });
      if (response.success) {
        showSuccess(this, response.message);
        saveChangePassword(response);
        saveToken(response);
        saveRole(response);

        localStorage.setItem("host_details", JSON.stringify(response.data));
        setTimeout(() => window.location.reload(), 500);
      } else {
        if (response.data?.error) {
          setTimeout(
            () =>
              this.props.history.push({
                pathname: "/verify_email",
                email_id: email,
                signUp: "signIn",
              }),
            500
          );
        } else {
          showError(this, response.message);
        }
      }
      // if (PropResponse.success) {

      //   localStorage.setItem(
      //     "host_role_access",
      //     JSON.stringify(PropResponse.data)
      //   );
      // }
    }
  };
  handleReset = async () => {
    let { email } = this.state;

    if (email === "") {
      showError(this, "Please enter email");
    } else if (isInvalidEmail(email)) {
      showError(this, "Please enter valid email");
    } else {
      let data = {
        email: email,
      };
      let response = await ApiService.postAPI(
        "auth/host/forgot_password",
        data
      );
      if (response.success) {
        showSuccess(this, response.message);
        this.setState({ isLoading: true });
        setTimeout(() => this.toggleSignType("login"), 1000);
      } else {
        showError(this, response.message);
      }
    }
  };

  handleKeypress = (e) => {
    let { signInLink, signUpLink } = this.state;
    //it triggers by pressing the enter key
    if (signUpLink && e.key === "Enter") {
      this.handleSignUp();
    } else if (signInLink && e.key === "Enter") {
      this.handleLogin();
    }
  };

  handleSignUp = async () => {
    let { name, email, phone, password, conPassword, check, user_type } =
      this.state;
    if (
      name === "" ||
      isInvalidName(name) ||
      name.length < 3 ||
      name === null ||
      name === undefined
    ) {
      showError(this, "Please enter valid name");
    } else if (
      email === "" ||
      isInvalidEmail(email) ||
      isFirstLowercaseOnly(email)
    ) {
      showError(this, "Please enter valid email");
    } else if (password === "" || password === undefined) {
      showError(this, "Please enter valid  password");
    } else if (conPassword === "" || conPassword === undefined) {
      showError(this, "Please enter confirm password");
    } else if (password !== conPassword) {
      showError(this, "Password field does not match");
    } else if (phone === "" || phone.length < 16) {
      showError(this, "Please enter valid phone number");
    } else if (user_type === "") {
      showError(this, "Please select user type");
    } else if (check === false) {
      showError(this, "Please accept the terms and conditions");
    } else {
      this.setState({ isLoading: true });
      let data = {
        name: name,
        email: email,
        password: password,
        confirm_password: conPassword,
        phone: {
          national_number: replaceToNormalNumber(phone),
          country_code: "1",
        },
        role: user_type,
      };
      let response = await ApiService.postAPI("auth/host/signup", data);
      if (response.success) {
        showSuccess(this, response.message);
        this.setState({ isLoading: false });
        setTimeout(
          () =>
            this.props.history.push({
              pathname: "/verify_email",
              email_id: email,
              signUp: "signUp",
            }),
          2000
        );
      } else {
        this.setState({ isLoading: false });
        showError(this, response.message);
      }
    }
  };

  userTypeChange = (e) => {
    this.setState({ user_type: e.target.value });
  };

  handleToggle = () => {
    let self = this;
    self.setState(
      { forgotLink: true, signInLink: false, signUpLink: false },
      () => this.resetState()
    );
  };
  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleOnPhoneNumberChange = (evt) => {
    this.setState({ phone: getUSFormat(evt.target.value) });
  };
  toggleSignType = (key) => {
    key === "register"
      ? this.setState({
          forgotLink: false,
          signUpLink: true,
          signInLink: false,
          pType: "password",
          nType: "password",
          isLoading: false,
        })
      : this.setState({
          forgotLink: false,
          signUpLink: false,
          signInLink: true,
          pType: "password",
          nType: "password",
          isLoading: false,
        });
    return this.resetState();
  };
  handleOnCheckChange = () => {
    this.setState({ check: !this.state.check });
  };
  toggleEyeIcon = (key) => {
    if (key === "pass") {
      this.setState({
        pType: this.state.pType === "password" ? "text" : "password",
      });
    } else if (key === "newPass") {
      this.setState({
        nType: this.state.nType === "password" ? "text" : "password",
      });
    }
  };
  handleRedirect = (key) => {
    window.open(
      key === "terms"
        ? "https://www.parknstays.com/#/terms_condtions"
        : "https://www.parknstays.com/#/privacy_policy",
      "_blank"
    );
  };
  // Toast Handler
  handleSocialLoginFailure = (err) => {
    console.error(err);
  };
  handleSocialLogin = (user) => {};
  render() {
    let {
      name,
      password,
      conPassword,
      email,
      phone,
      forgotLink,
      signUpLink,
      signInLink,
      check,
      pType,
      nType,
      toast,
      response,
      toastType,
      isLoading,
    } = this.state;

    return (
      <>
        <div className="main_content_login new-pro-design">
          <Container className="new-max-width">
            {toast && <ToastList message={response} type={toastType} />}
            <Row className="justify-content-center">
              <Col
                lg="5"
                md="7"
                className={
                  signInLink
                    ? "login-flexing"
                    : signUpLink
                    ? "login-signup"
                    : "forgot-trans"
                }
              >
                <Card className="bg-secondary  border-0 radius-log shadow_div ">
                  {signUpLink ? (
                    <div className="signup-nav ">
                      <img
                        className="logo-sign-image"
                        src={Logo}
                        alt="Parknstays Logo"
                      />
                      {signUpLink && (
                        <div className="toggle-link">
                          Already have an account ?{" "}
                          <span onClick={() => this.toggleSignType("login")}>
                            Login Here
                          </span>
                        </div>
                      )}
                    </div>
                  ) : (
                    <CardHeader
                      className={
                        signUpLink
                          ? "bg-transparent border-0 jus-center mt-20"
                          : "bg-transparent border-0 pad-card-top jus-center mt-20"
                      }
                    >
                      <img
                        className="logo-image"
                        src={Logo}
                        alt="Parknstays Logo"
                      />
                    </CardHeader>
                  )}

                  <CardBody
                    className={
                      signInLink
                        ? "px-lg-5 py-lg-5 pad-log-bot pad-left-right-2"
                        : "px-lg-5 py-lg-5 pad-log-bot pad-left-right-2"
                    }
                  >
                    <div
                      className={
                        signUpLink
                          ? "text-left text-muted mb-0 flex"
                          : "text-left text-muted mb-0"
                      }
                    >
                      <small className="signin-small mb-20">
                        {signInLink
                          ? "Log In"
                          : signUpLink
                          ? "Sign Up"
                          : "Forgot Password ?"}
                      </small>
                    </div>

                    {forgotLink && (
                      <label className="term-label mt--1-2">
                        Enter your email and we'll send you a link to reset your
                        password.
                      </label>
                    )}
                    <Form role="form">
                      <div
                        className={signUpLink ? "signup-flexy" : "flex-reverse"}
                      >
                        <div className={signUpLink ? "inherit-flexy" : ""}>
                          {signUpLink && (
                            <FormGroup className="">
                              <label
                                className="form-control-label label-in mb-2"
                                htmlFor="input-email"
                              >
                                Name *
                              </label>
                              <InputGroup className="input-group-alternative new-input-login">
                                <Input
                                  type="text"
                                  name="name"
                                  autoComplete="new-name"
                                  value={name}
                                  onChange={this.handleInputChange}
                                  className="email-new-in new-login-in pad-new-in"
                                  onKeyDown={(e) => this.handleKeypress(e)}
                                />
                              </InputGroup>
                            </FormGroup>
                          )}

                          {(signInLink || forgotLink) && (
                            <FormGroup
                              className={
                                forgotLink ? "mt-0-8" : "mt-4-5"
                              }
                            >
                              <label
                                className="form-control-label label-in mb-2"
                                htmlFor="input-email"
                              >
                                Email *
                              </label>
                              <InputGroup className="input-group-alternative new-input-login">
                                <Input
                                  type="email"
                                  name="email"
                                  value={email}
                                  autoComplete="new-email"
                                  onChange={this.handleInputChange}
                                  onKeyDown={(e) => this.handleKeypress(e)}
                                  className="email-new-in"
                                />
                              </InputGroup>
                            </FormGroup>
                          )}
                        </div>
                        <div style={{ width: "49%" }}>
                          {signUpLink && (
                            <FormGroup className="mb-3">
                              <label
                                className="form-control-label label-in mb-2"
                                htmlFor="input-email"
                              >
                                Email *
                              </label>
                              <InputGroup className="input-group-alternative new-input-login">
                                <Input
                                  type="email"
                                  name="email"
                                  value={email}
                                  autoComplete="new-email"
                                  onKeyDown={(e) => this.handleKeypress(e)}
                                  onChange={this.handleInputChange}
                                  className="email-new-in"
                                />
                              </InputGroup>
                            </FormGroup>
                          )}
                        </div>
                      </div>
                      <div className={signUpLink ? "signup-flexy" : ""}>
                        <div className={signUpLink ? "inherit-flexy" : ""}>
                          {!forgotLink && (
                            <FormGroup
                              className={
                                signInLink ? "mb-4" : "mb-3"
                              }
                            >
                              <label
                                className="form-control-label label-in mb-2"
                                htmlFor="input-email"
                              >
                                Password *
                              </label>
                              <InputGroup className="input-group-alternative new-input-login">
                                <Input
                                  type={pType}
                                  name="password"
                                  value={password}
                                  autoComplete="new-password"
                                  onKeyDown={(e) => this.handleKeypress(e)}
                                  onChange={this.handleInputChange}
                                  className="email-new-in pad-new-in"
                                />
                                <InputGroupAddon addonType="append">
                                  <InputGroupText
                                    onClick={() => this.toggleEyeIcon("pass")}
                                    className="new-border"
                                  >
                                    <i
                                      className={
                                        pType === "password"
                                          ? "fa fa-eye-slash"
                                          : "fa fa-eye"
                                      }
                                    ></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                              </InputGroup>
                            </FormGroup>
                          )}
                        </div>
                        <div style={{ width: "49%" }}>
                          {signUpLink && (
                            <FormGroup className="mb-4">
                              <label
                                className="form-control-label label-in mb-2"
                                htmlFor="input-email"
                              >
                                Confirm Password *
                              </label>
                              <InputGroup className="input-group-alternative new-input-login">
                                <Input
                                  type={nType}
                                  name="conPassword"
                                  value={conPassword}
                                  autoComplete="new-confirm-password"
                                  onKeyDown={(e) => this.handleKeypress(e)}
                                  onChange={this.handleInputChange}
                                  className="email-new-in pad-new-in"
                                />
                                <InputGroupAddon addonType="append">
                                  <InputGroupText
                                    onClick={() =>
                                      this.toggleEyeIcon("newPass")
                                    }
                                    className="new-border"
                                  >
                                    <i
                                      className={
                                        nType === "password"
                                          ? "fa fa-eye-slash"
                                          : "fa fa-eye"
                                      }
                                    ></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                              </InputGroup>
                            </FormGroup>
                          )}
                        </div>
                      </div>
                      <div className={signUpLink ? "signup-flexy" : ""}>
                        <div className={signUpLink ? "inherit-flexy" : ""}>
                          {signUpLink && (
                            <FormGroup className="mb-4">
                              <label
                                className="form-control-label label-in mb-2"
                                htmlFor="input-email"
                              >
                                Mobile Number *
                              </label>
                              <InputGroup className="input-group-alternative new-input-login">
                                <Input
                                  type="text"
                                  name="phone"
                                  value={phone}
                                  autoComplete="new-phone"
                                  onChange={this.handleOnPhoneNumberChange}
                                  maxLength="16"
                                  className="email-new-in"
                                  onKeyDown={(e) => this.handleKeypress(e)}
                                />
                              </InputGroup>
                            </FormGroup>
                          )}
                        </div>
                        <div style={{ width: "49%" }}>
                          {signUpLink && (
                            <FormGroup
                              className="mb-4"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <label
                                className="form-control-label label-in mb-2"
                                htmlFor="input-email"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                User Type
                                <InputGroupText className="new-border-request no_border userTypeInfo">
                                  <div
                                    data-tip="Landlord - Primary Home Owners | Property Managers - Managing Properties which are assigned by Home Owners "
                                    data-iscapture="true"
                                    data-for="toolTip1"
                                    data-place="right"
                                  >
                                    <i className="fa fa-info-circle"></i>
                                  </div>
                                  <ReactTooltip
                                    place="right"
                                    type="info"
                                    effect="solid"
                                    id="toolTip1"
                                    html={true}
                                  />

                                  {/* </div> */}
                                </InputGroupText>
                              </label>

                              <select
                                id="select"
                                value={this.state.user_type}
                                onChange={this.userTypeChange}
                                className="userType"
                                onKeyDown={(e) => this.handleKeypress(e)}
                                disabled={
                                  this.state.hostType === "host" ||
                                  this.state.hostType === "manager"
                                    ? true
                                    : ""
                                }
                              >
                                <option value="">select</option>
                                <option value="host" selected>
                                  LandLord
                                </option>
                                <option value="manager">
                                  Property Manager
                                </option>
                              </select>
                            </FormGroup>
                          )}
                        </div>
                      </div>
                      {!forgotLink && signInLink && (
                        <div className="custom-control custom-control-alternative custom-checkbox text-align">
                          <a
                            className="text-light"
                            onClick={(e) => e.preventDefault()}
                          >
                            <small
                              onClick={() => this.handleToggle()}
                              // style={{ color: "#012A4D" }}
                              className="text-color"
                            >
                              Forgot Password ?
                            </small>
                          </a>
                        </div>
                      )}
                      {!forgotLink && (
                        <div className="terms-pad">
                          <FormGroup
                            className={signUpLink ? "mb-term" : "mb-1 mt-4"}
                            check
                          >
                            <Input
                              type="checkbox"
                              name="check"
                              checked={check}
                              autoComplete="new-phone"
                              onChange={this.handleOnCheckChange}
                              maxLength="10"
                              className="email-new-in term-in checking-in mt-13"
                            />
                            <label className="term-label">
                              By clicking{" "}
                              {signInLink ? "Log In" : "Create Account"} , you
                              agree to our{" "}
                              <span
                                className="term-color"
                                onClick={() => this.handleRedirect("terms")}
                              >
                                {" "}
                                Terms{" "}
                              </span>
                              <span> and </span>
                              <span
                                className="term-color"
                                onClick={() => this.handleRedirect("policy")}
                              >
                                {" "}
                                Policy
                              </span>
                            </label>
                          </FormGroup>
                        </div>
                      )}
                      {/* <div className="mb_40 mt_30 social_class">
                        <SocialButton
                          provider="facebook"
                          appId="853738586025001"
                          onLoginSuccess={this.handleSocialLogin()}
                          onLoginFailure={this.handleSocialLoginFailure()}
                        />
                      </div> */}
                      {(forgotLink || signInLink) && (
                        <div
                          className={signUpLink ? "text-left" : "text-center"}
                        >
                          <Button
                            className={
                              isLoading
                                ? "my-4 sign-auth  pad-but loader-btn bg-shadowable"
                                : "my-4 sign-auth button-wrapping pad-but loader-btn"
                            }
                            color="primary"
                            type="button"
                            onClick={() =>
                              forgotLink
                                ? this.handleReset()
                                : signInLink
                                ? this.handleLogin()
                                : ""
                            }
                          >
                            {forgotLink && !isLoading
                              ? "Submit"
                              : signInLink && !isLoading
                              ? "Log In"
                              : ""}
                            {isLoading && (
                              <div className="spinner-btn">
                                <Spinner type="border" />
                              </div>
                            )}
                          </Button>
                        </div>
                      )}
                      {signUpLink && (
                        <Button
                          className={
                            isLoading
                              ? "bg-shadowable mt-3 loader-btn-reverse"
                              : "button-wrapping mt-3"
                          }
                          color="primary"
                          type="button"
                          onClick={() => this.handleSignUp()}
                        >
                          {isLoading ? (
                            <div className="spinner-btn">
                              <Spinner type="border" />
                            </div>
                          ) : (
                            "Create Account"
                          )}
                        </Button>
                      )}
                    </Form>
                    {forgotLink && (
                      <div className="toggle-link">
                        Already have an account ? {"  "}
                        <span onClick={() => this.toggleSignType("login")}>
                          Login Here
                        </span>
                      </div>
                    )}
                    {signInLink && (
                      <div className="toggle-link">
                        Don't have an account ?{" "}
                        <span onClick={() => this.toggleSignType("register")}>
                          Register Here
                        </span>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}
export default Login;
