import { Component } from "react";
import {
  Card,
  CardHeader,
  Spinner,
  Table,
  Dropdown,
  Input,
  InputGroup,
  Button,
  returnCustomerFirsLastName,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import closeIcon from "../../assets/img/brand/close.svg";
import ApiService from "constants/ApiService";
import { buildQueryString } from "constants/utils";
import { showError } from "constants/utils";
import { returnFirstDegreeObjValue } from "constants/utils";
import { showLocaldate } from "constants/utils";
import { createBrowserHistory } from "history";
export default class BookingMiniList extends Component {
  constructor(props) {
    super(props);
    this.queryObject = {};
    this.filterOptions = [
      { value: "propname", name: "Property" },
      { value: "username", name: "Tenant" },
    ];
    this.state = {
      bookings: [],
      isLoading: true,
      filterOption: "username",
      inputSearch: "",
      input_value: false,
    };
  }

  redirectToAddDocumentPage = (id) => {
    this.props.props.history.push(`/admin/document/add/${id}`);
  };

  componentDidMount = () => {
    this.getBookings();
  };

  // onChangeHandler = (e) => {
  //   this.setState({ [e.target.name]: e.target.value });
  // };
  onChangeHandler = (e) => {
    e.target.value === "" && this.handleReset();
    this.setState({ [e.target.name]: e.target.value, input_value: true });
    clearTimeout(this.state.timeoutId);
    const timeoutId = setTimeout(() => {
      this.state.filterOption && this.handleSubmit();
    }, 1000);
    this.setState({ timeoutId });
  };
  onChangeSelectHandler = (status) => {
    this.setState({ filterOption: status, input_value: true }, () => {
      // this.handleFilterStatus();
    });
  }
  handleSubmit = () => {
    if (this.state.filterOption) {
      this.queryObject = {
        [this.state.filterOption]: this.state.inputSearch,
      };
      this.setState({ isLoading: true });
      this.getBookings();
    }
  };

  handleReset = () => {
    this.setState(
      { inputSearch: "", isLoading: true, filterOption: "username" },
      () => {
        this.state.filterOption || (this.queryObject = {});
        this.getBookings();
      }
    );
  };

  getBookings = () => {
    ApiService.getAPI(
      `host/booking/mini${buildQueryString(this.queryObject)}`
    ).then((response) => {
      this.setState({ isLoading: false });
      if (!response.success) return showError(response.message);
      this.setState({ bookings: response.data.bookings });
    });
  };

  closeModal = () => {
    this.props.close();
  };

  render() {
    const { bookings, isLoading, filterOption, inputSearch, input_value } = this.state;
    return (
      <div style={{ overflow: "auto" }}>
        <CardHeader>
          <div className="flex">
            <div className="fw-500 f16 cl000000">Bookings</div>
            <img
              src={closeIcon}
              onClick={() => this.closeModal()}
              className="point"
            />
          </div>
        </CardHeader>
        <div style={{ margin: "20px 10px 0px 15px" }}>
          Please select the booking to proceed further
        </div>

        <InputGroup className="flex-content align-left">
          <div
            style={{ alignItems: "center", margin: "22px 10px" }}
            className="filters_container"
          >
            <div className="left_filter_container">
              <div className="select_with_input status_select_input ml-10">
                <UncontrolledDropdown className="status_select_div ">
                  <DropdownToggle className="pr-0 drop_down_container">
                    <Media className="capitalize">
                      {filterOption === "username"
                        ? "Tenant"
                        : filterOption === "propname"
                          ? "Property" : "Tenant"
                      }
                      <i className="fas fa-angle-down" style={{ marginLeft: "20px" }}></i>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" bottom style={{ minWidth: "130px" }}>
                    {" "}
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.onChangeSelectHandler("username")}
                    >
                      Tenant
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.onChangeSelectHandler("propname")}
                    >
                      Property
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <Input
                  aria-label="Text input with dropdown button"
                  value={inputSearch}
                  name="inputSearch"
                  placeholder="search by"
                  onChange={this.onChangeHandler}
                />
              </div>
            </div>
            {(input_value && inputSearch) &&
              <div className="right_filter_container">
                <Button
                  color="primary"
                  onClick={this.handleReset}
                  size="sm"
                  className="button-wrapping mr-4 button-secondary reset-btn"
                  style={{ fontSize: "15px" }}                  >
                  Clear filter
                </Button>
              </div>
            }
          </div>
        </InputGroup>
        <div className="BookingOverFlow">
          <Card className="shadow card_with_paginator">
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Tenant</th>
                  <th scope="col">Property</th>
                  <th scope="col">Check-In</th>
                  <th scope="col">Check-Out</th>
                </tr>
              </thead>
              {!!bookings.length && !isLoading && (
                <tbody>
                  {bookings?.map((item, i) => (
                    <tr
                      onClick={() =>
                        this.redirectToAddDocumentPage(item.booking_id)
                      }
                      key={i}
                    >
                      <td className="text-capitalize cursor-point">
                        {`${item.user.name.first} ${item.user.name.last ? item.user.name.last : ""
                          }`}
                      </td>
                      <td className="text-capitalize cursor-point">
                        {returnFirstDegreeObjValue(item.property, "name")}
                      </td>
                      <td>
                        {showLocaldate(
                          returnFirstDegreeObjValue(item, "check_in")
                        )}
                      </td>
                      <td>
                        {showLocaldate(
                          returnFirstDegreeObjValue(item, "check_out")
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </Table>
            {isLoading ? (
              <Card
                style={{ height: "100px" }}
                className="basic-book-info spin-loading3 "
              >
                <Spinner type="border" color="#012A4D" />
              </Card>
            ) : (
              <>
                {!bookings.length && (
                  <Card className="basic-book-info">
                    <div className="no-data font-book">
                      {" "}
                      No Bookings Available
                    </div>
                  </Card>
                )}
              </>
            )}
          </Card>
        </div>
      </div>
    );
  }
}
