import React, { useEffect, useState } from "react";
import {
  DropdownMenu,
  DropdownItem,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
  Dropdown,
  Input,
} from "reactstrap";
import DatePicker from "react-datepicker";
import { makeCapitalize } from "constants/utils";
import ReactTooltip from "react-tooltip";
import { Options } from "devextreme-react/color-box";
import { Select } from "antd";
import upArrow from "../../assets/img/brand/upArrow.svg"
import downArrow from "../../assets/img/brand/downArrow.svg"
import upClArrow from "../../assets/img/brand/upClArrow.svg"
import downClArrow from "../../assets/img/brand/downClArrow.svg"
import { capitalizeFirstLetter } from "constants/utils";


export function CustomDatePicker({ fromChange, toChange, from, to }) {
  const [value, setValue] = useState("");
  useEffect(() => { }, [fromChange, toChange]);

  return (
    <div
      className="mr-10"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div className="left_filter_container" style={{ width: "100%" }}>
        <div className="w_9">
          <div className="flexStart" style={{ width: "100%" }}>
            <div className="date-merge w20">Date</div>
            <div className="w40">
              <DatePicker
                selected={from}
                maxDate={new Date()}
                dateFormat="MM-dd-yyyy"
                placeholderText=""
                className="filter-input-date point "
                onChange={fromChange}
                name="from"
                id="from"
                value={from}
                style={{ fontWeight: "500" }}
                showYearDropdown
                showMonthDropdown
                ariaDescribedBy="basic-addon2"
              />
            </div>

            <div className="todate-merge">to</div>
            <div className="w40">
              <DatePicker
                selected={to}
                minDate={from ? from : ""}
                maxDate={new Date()}
                dateFormat="MM-dd-yyyy"
                placeholderText=""
                className="to_filter-input-date  point"
                onChange={toChange}
                name="to"
                id="to"
                value={to}
                style={{ fontWeight: "500" }}
                showYearDropdown
                showMonthDropdown
                ariaDescribedBy="basic-addon2"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function CustomDropDown({ statusList, setStatus, status, width }) {
  useEffect(() => { }, [setStatus]);
  return (
    <div className="status_select mr-10">
      <UncontrolledDropdown style={{ minWidth: '176px' }} className="status_select_div">
        <DropdownToggle className="pr-0 drop_down_container">
          <Media className="capitalize">
            {status === "in_progress"
              ? "In Progress"
              : status === ""
                ? "All"
                : makeCapitalize(status)}
            <i className="fas fa-angle-down"></i>
          </Media>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-arrow" bottom>
          {" "}
          {statusList.map((stats) => (
            <DropdownItem
              className="dropdown_item"
              onClick={() => setStatus(stats === "all" ? "" : stats)}
            >
              {makeCapitalize(stats)}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
}

export function CustomSearch({ options, filterOption, inputSearch, change, placeholder, onClick }) {
  return (
    <>
      <div className="select_with_input mr-10">
        <Dropdown variant="input-group" className="select_input_drop">
          <select
            id="inputGroupSelect01"
            className="form_select fixed_select"
            name="filterOption"
            value={filterOption}
            onChange={change}
          >
            {
              options?.map((opt) => (
                <option value={opt} >{makeCapitalize(opt)}</option>
              ))
            }
          </select>
        </Dropdown>
        {/* <UncontrolledDropdown className="status_select_div ">
          <DropdownToggle className="pr-0 drop_down_container">
            <Media className="capitalize">
              {filterOption === filterOption}
              <i className="fas fa-angle-down" style={{ marginLeft: "20px" }}></i>
            </Media>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" bottom style={{ minWidth: "130px" }}>
            {" "}
            <DropdownItem
              className="dropdown_item"
              onClick={onClick}
            >
              {options?.map((opt) => (opt))}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown> */}
        <Input
          aria-label="Text input with dropdown button"
          className="input-filter-toggle"
          value={inputSearch}
          name="inputSearch"
          onChange={change}
          placeholder={placeholder}
        />
      </div>
    </>
  );
}

// export function CustomSearch({
//   options,
//   filterOption,
//   inputSearch,
//   change,
//   inputWidth,
//   selectWidth,
//   from,
//   style,
// }) {
//   const handleChange = (value) => {
//   };

//   useEffect(() => {
//   }, [change, inputSearch]);
//   return (
//     <>
//       <div style={{ ...style }} className="customSelectContainer">
//         <Select
//           className="customSelect"
//           onChange={(e) => change(e, "filterOption")}
//           options={options?.map((opt) => ({
//             value: opt,
//             label: makeCapitalize(opt),
//           }))}
//           value={filterOption}
//         />
//         <Input
//           style={{ width: inputWidth }}
//           aria-label="Text input with dropdown button"
//           className="input-filter-toggle"
//           value={inputSearch}
//           onChange={(e) => change(e.target.value, "inputSearch")}
//         />
//       </div>
//     </>
//   );
// }
// export function AsscendingDecending({ AscdataTip, DscdataTip, AscOnClick, DscOnClick, AscId, DscId }) {
//   return (
//     <div style={{ display: "flex", flexDirection: "column" }}>
//       <div style={{ lineHeight: "0.8", fontSize: "18px" }}>
//         <i className="fas fa-angle-up pointer"
//           onClick={AscOnClick}
//           id={AscId}
//           data-tip={AscdataTip}
//           data-iscapture="true"
//           data-for={AscId}
//           data-place="top"
//           style={{ lineHeight: "0" }}
//         />
//         <ReactTooltip
//           place="top"
//           type="info"
//           effect="solid"
//           id={AscId}
//           html={true}
//         />
//       </div>
//       <div style={{ lineHeight: "0.5", fontSize: "18px" }}>
//         <i className="fas fa-angle-down pointer"
//           onClick={DscOnClick}
//           id={DscId}
//           data-tip={DscdataTip}
//           data-iscapture="true"
//           data-for={DscId}
//           data-place="top"
//           style={{ lineHeight: "0" }}
//         />

//         <ReactTooltip
//           place="top"
//           type="info"
//           effect="solid"
//           id={DscId}
//           html={true}
//         />
//       </div>
//     </div>
//   )
// }

export function AsscendingDecending({ keyValue, value, name }) {
  return (
    <>
      <span style={{ marginRight: "15px" }}
        id={value}
        data-tip={keyValue.includes("_asc") ? "Click to" + " " + name + " " + "Dsc" :
          keyValue.includes("_dsc") ? "Clear filter" : "Click to " + " " + name + " " + "Asc"}
        data-iscapture="true"
        data-for={value}
        data-place="top">{name}</span>
      <div style={{ display: "flex", flexDirection: " column" }}>
        {keyValue.includes(value + "_asc") ? <img src={upClArrow} style={{ marginBottom: "2px" }} /> : <img src={upArrow} style={{ marginBottom: "2px" }} />}
        {keyValue.includes(value + "_dsc") ? <img src={downClArrow} /> : <img src={downArrow} />}
      </div>
      <ReactTooltip
        place="top"
        type="info"
        effect="solid"
        id={value}
        html={true}
      />
    </>
  )
}
