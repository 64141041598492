import React, { Component } from "react";
import cookie from "react-cookies";
import {
  Card,
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  InputGroup,
  Input,
  Spinner,
  BreadcrumbItem,
  FormGroup,
  InputGroupText,
  CardHeader,
  CardBody,
  Form,
  Modal,
  InputGroupAddon,
} from "reactstrap";
// core components
import { LightgalleryItem } from "react-lightgallery";
import Remove from "../../assets/img/brand/remove.svg";
import ApiService from "constants/ApiService";
import {
  maxLengthCheck,
  getCloudinaryImage,
  uploadCloudinary,
  handleGoBack,
  showCalendarFormat,
  isSecureVault,
  checkVaultSecure,
} from "constants/utils";
import ToastList from "components/Toast/ToastList";
import { showError, showSuccess, saveSecureVault } from "../../constants/utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Home from "../../assets/img/brand/home.svg";
import propTick from "../../assets/img/brand/propTick.svg";
import DateSvg from "../../assets/img/brand/date-pick.svg";
import pdfImage from "../../assets/img/brand/pdf.png";
import VerifySecureVault from "./VerifySecureVault";
import DisableSecureVault from "./DisableSecureVault";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { DropdownCommon } from "components/Dropdown/DropdownCommon";

let doc_id = "";
class AddEditVault extends Component {
  constructor(props) {
    super(props);
    doc_id = this.props.match.params.doc_id;
    this.state = {
      isLoading: false,
      toast: false,
      toastType: "",
      response: "",
      step_1: true,
      step_2: false,
      step_3: false,
      step_4: false,
      isUpdateLoading: false,
      name: "",
      purchased_at: "",
      price: "",
      frequency: "",
      last_done: "",
      next_due: "",
      desc: "",
      imageArray: [],
      imageLoading: false,
      disableVaultSecurity: false,
      pType: "password",
      password: "",
      verifyVaultModal: false,
      verifyUpdate: false,
      disableVaultModal: false,
    };
  }
  componentDidMount = () => {
    this.setState({ verifyUpdate: checkVaultSecure() });
    if (doc_id) {
      this.getVaultDetails();
    }
  };

  onImageClick = (e) => {
    this.setState({ img_value: "" });
  };
  openPdf = (url) => {
    window.open(url);
  };
  onImageChangeHandler = async (e) => {
    let self = this;
    let imageArray = this.state.imageArray;
    let file = e.target.files[0];

    let filemb = file.size / 1048576;
    let splitnum = JSON.stringify(filemb).split(".");
    let getmb = splitnum[0];

    if (imageArray.length < 5) {
      if (
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg" ||
        file.type === "application/pdf"
      ) {
        if (getmb <= 10) {
          this.setState({ imageLoading: true });
          let data = await uploadCloudinary(file, "host");
          if (data.url) {
            let images = [...imageArray];
            images.push({
              format: data.format,
              public_id: data.public_id,
              version: `v${data.version}`,
              type: file.type === "application/pdf" ? "pdf" : "image",
              // url: data.url,
            });
            self.setState({
              imageArray: images,
              imageLoading: false,
            });
          } else {
            showError(this, "Could not upload image");
            this.setState({ imageLoading: false });
          }
        } else {
          showError(this, "File size should not exceeds 10 MB");
          this.setState({ imageLoading: false });
        }
      } else {
        showError(this, "Invalid Format");
        this.setState({ imageLoading: false });
      }
    } else {
      showError(this, "Maximum file exceeds");
    }
  };
  deleteImage = (idx) => {
    let imageArray = this.state.imageArray;
    let images = [...imageArray];
    images.splice(idx, 1);
    this.setState({ imageArray: images });
  };
  toggleVerifyVaultModal = (status) => {
    if (status) {
      this.setState({ verifyUpdate: false }, () => this.handleAddEdit());
    }
    this.setState({ verifyVaultModal: false });
  };
  toggleDisableVaultModal = (status) => {
    if (status) {
      this.getHostDetails();
      this.setState({ verifyUpdate: false });
      setTimeout(() => this.props.history.goBack(), 2000);
    }
    this.setState({ disableVaultModal: false });
  };
  getHostDetails = async () => {
    let response = await ApiService.getAPI("host");
    if (response.success) {
      localStorage.setItem("host_details", JSON.stringify(response.data));
      let vault_status = response?.data?.host?.vault?.is_enabled ?? false;
      if (vault_status) {
        saveSecureVault("secured");
      } else {
        saveSecureVault("notsecure");
      }
      // this.setState({ verifyUpdate: checkVaultSecure() });
    }
  };
  getVaultDetails = async () => {
    let response = await ApiService.getAPI(`host/vault/${doc_id}`);
    if (response.success) {
      let {
        name,
        docs,
        price,
        purchased_at,
        last_done,
        next_due,
        frequency,
        desc,
      } = response.data.doc;
      this.setState({
        name,
        imageArray: docs,
        purchased_at: purchased_at ? new Date(purchased_at) : "",
        last_done: last_done ? new Date(last_done) : "",
        next_due: next_due ? new Date(next_due) : "",
        frequency: frequency ?? "",
        price,
        desc,
      });
    } else {
    }
  };
  handleAddEdit = async () => {
    const {
      step_1,
      step_2,
      name,
      purchased_at,
      frequency,
      next_due,
      last_done,
      price,
      desc,
      imageArray,
      verifyUpdate,
    } = this.state;
    if (step_1) {
      if (!name) {
        showError(this, "Please enter name");
      } else if (name.length < 3) {
        showError(this, "Please enter valid name");
      } else if (!frequency) {
        showError(this, "Please select frequency");
      } else if (!next_due) {
        showError(this, "Please select next due date");
      } else if (desc && desc.length < 3) {
        showError(this, "Please enter valid desc");
      } else {
        this.setState({ step_2: true, step_1: false });
      }
    }
    if (step_2) {
      if (imageArray.length <= 0) {
        showError(this, "Please attach atleast one document");
      } else {
        if (verifyUpdate) {
          this.setState({ verifyVaultModal: true });
        } else {
          this.setState({ isUpdateLoading: true });
          let data = {
            name: name,
            docs: imageArray,
            purchased_at: purchased_at ? showCalendarFormat(purchased_at) : "",
            last_done: last_done ? showCalendarFormat(last_done) : "",
            next_due: next_due ? showCalendarFormat(next_due) : "",
            frequency: frequency,
            price: price,
            desc: desc,
          };
          this.setState({ isUpdateLoading: true });
          let response;
          if (doc_id) {
            response = await ApiService.patchAPI(`host/vault/${doc_id}`, data);
          } else {
            response = await ApiService.postAPI(`host/vault`, data);
          }
          if (response.success) {
            handleGoBack(this.props);
            showSuccess(this, response.message);
          } else {
            this.setState({ isUpdateLoading: false });
            showError(this, response.message);
          }
        }
      }
    }
  };
  updateVaultDetail = async () => {
    const {
      step_1,
      step_2,
      name,
      purchased_at,
      frequency,
      next_due,
      last_done,
      price,
      desc,
      imageArray,
    } = this.state;

    let data = {
      name: name,
      docs: imageArray,
      purchased_at: purchased_at ? showCalendarFormat(purchased_at) : "",
      last_done: last_done ? showCalendarFormat(last_done) : "",
      next_due: next_due ? showCalendarFormat(next_due) : "",
      frequency: frequency,
      price: price,
      desc: desc,
    };
  }
  toggleBack = () => {
    let { step_1, step_2 } = this.state;
    if (step_1) {
      this.props.history.goBack();
    } else if (step_2) {
      this.setState({ step_1: true, step_2: false });
    }
  };
  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleFrequencyType = (e) => {
    this.setState({ frequency: e })
  }
  handleOnDateChange = (e) => {
    this.updateStateValues("purchased_at", e);
  };
  handleToDateChange = (e) => {
    this.updateStateValues("last_done", e);
  };
  handleDueDateChange = (e) => {
    this.updateStateValues("next_due", e);
  };
  updateStateValues = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      function () {
        if (value === null) {
          this.setState({
            [key]: "",
          });
        }
      }
    );
  };
  closeDisableVaultModal = () => {
    this.setState({ disableVaultSecurity: false });
  };
  toggleEyeIcon = (key) => {
    if (key === "pass") {
      this.setState({
        pType: this.state.pType === "password" ? "text" : "password",
      });
    }
  };
  openDisableVaultModal = () => {
    this.setState({ verifyVaultModal: false, disableVaultModal: true });
  };
  render() {
    const {
      isLoading,
      toast,
      toastType,
      response,
      step_1,
      step_2,
      name,
      isUpdateLoading,
      price,
      frequency,
      last_done,
      next_due,
      desc,
      imageLoading,
      verifyVaultModal,
      disableVaultModal,
      disableVaultSecurity,
      pType,
      password,
    } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#012A4D" />
      </div>
    ) : (
      <>
        <Container className="mt-8 card-pad-wrapper" fluid>
          {toast && <ToastList message={response} type={toastType} />}
          <div className="pad-to-8">
            <Breadcrumb></Breadcrumb>
          </div>
          <div className="d_flex_space_align mt-4">
            <Col xs="8" className="manage-con">
              <Breadcrumb>
                <BreadcrumbItem
                  onClick={() => this.props.history.goBack()}
                  className="bread-cursor"
                >
                  My Vault
                </BreadcrumbItem>
                <BreadcrumbItem active style={{ color: "#012a4d" }}>
                  {doc_id ? "Update" : "Add"}
                </BreadcrumbItem>
              </Breadcrumb>
            </Col>
            <div className="right_filter_container">
              <Button
                color="primary"
                onClick={() => this.toggleBack()}
                size="sm"
                className="button-wrapping search-wrapper content-flex mr-4 button-secondary ml-4 reset-btn"
              >
                Previous
              </Button>
              <Button
                color="primary"
                onClick={!isUpdateLoading ? () => this.handleAddEdit() : ""}
                size="sm"
                className={
                  isUpdateLoading
                    ? "button-wrapping up-prop-btn point"
                    : "button-wrapping point"
                }
              >
                {isUpdateLoading ? (
                  <div className="button_spinner">
                    <Spinner type="border" color="#012A4D" />
                  </div>
                ) : step_2 && !doc_id ? (
                  "Add"
                ) : step_2 && doc_id ? (
                  "Update"
                ) : (
                  "Next"
                )}
              </Button>
            </div>
          </div>
          <div className="progress-all">
            <div className="progress-div progress-purchase">
              <div
                className={
                  step_1
                    ? "progress-back-toggle"
                    : step_2
                      ? "progress-back-toggle w_3 p_3"
                      : "progress-back-toggle"
                }
              >
                {step_1 ? (
                  <div className="img-black image_one">
                    <img src={Home} className="img-abs" alt="" />
                  </div>
                ) : (!step_1 && step_2) ||
                  (!step_1 && !step_2) ||
                  (!step_1 && !step_2) ? (
                  <div className="steps-1 back">
                    <img src={propTick} className="img-abs-prop" alt="" />
                  </div>
                ) : (
                  <div className="steps-1"></div>
                )}
                {step_2 ? (
                  <div className="img-black image_two purchase_two">
                    <img src={Home} className="img-abs" alt="" />
                  </div>
                ) : (!step_1 && !step_2) || (!step_1 && !step_2) ? (
                  <div className="steps-2 back ">
                    <img src={propTick} className="img-abs-prop" alt="" />
                  </div>
                ) : (
                  <div className="steps-2 steps_2_pur"></div>
                )}
              </div>
            </div>
          </div>
          <Row>
            <div className="col new-table">
              {step_1 && (
                <Card className="shadow">
                  {/* <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <h3 className="mb-0">Basic Information</h3>
                      </Col>
                    </Row>
                  </CardHeader> */}
                  <ListingHeaderLayout label={"Basic Information"} />
                  <CardBody className="new-card">
                    <Form>
                      <div>
                        <Row>
                          <Col lg="4" className="mb-3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Name*
                              </label>

                              <Input
                                className="form-control-alternative"
                                value={name}
                                name="name"
                                placeholder="Name"
                                type="text"
                                onChange={this.handleInputChange}
                                maxLength={30}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Date of Purchase
                              </label>
                              <InputGroup className="mb-0  flex-content notice-date">
                                <DatePicker
                                  selected={this.state.purchased_at}
                                  dateFormat="MM-dd-yyyy"
                                  placeholderText=""
                                  className="filter-input-date  new-date-in document-add-date point"
                                  onChange={this.handleOnDateChange}
                                  name="purchased_at"
                                  id="purchased_at"
                                  maxDate={new Date()}
                                  style={{ fontWeight: "500" }}
                                  showYearDropdown
                                  showMonthDropdown
                                  ariaDescribedBy="basic-addon2"
                                />
                                <InputGroupText
                                  id="basic-addon2"
                                  className="group-date doc-add-group notice-group"
                                >
                                  <label
                                    className="mb-remove cursor-point"
                                    htmlFor="purchased_at"
                                  >
                                    <img
                                      src={DateSvg}
                                      alt="Date-Picker Icon"
                                      className="date-svg point"
                                    />
                                  </label>
                                </InputGroupText>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup className="form-relative">
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Price
                              </label>
                              <Input
                                className="form-control-alternative"
                                value={price}
                                name="price"
                                id="input-first-name"
                                onChange={this.handleInputChange}
                                placeholder="Price"
                                type="number"
                                maxLength={5}
                                onInput={maxLengthCheck}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Frequency*
                              </label>
                              <DropdownCommon
                                innerStyle={{ marginTop: "13px" }}
                                status={frequency}
                                setStatus={this.handleFrequencyType}
                                statusList={[
                                  { label: "Select", value: "" },
                                  { label: "Entire Home", value: "weekly" },
                                  { label: "Bi-Weely", value: "bi_weekly" },
                                  { label: "Monthly", value: "monthly" },
                                  { label: "Quarterly", value: "quarterly" },
                                  { label: "Half Yearly", value: "halfyearly" },
                                  { label: "Yearly", value: "yearly" },
                                ]}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Last Done
                              </label>
                              <InputGroup className="mb-0  flex-content notice-date">
                                <DatePicker
                                  selected={last_done}
                                  dateFormat="MM-dd-yyyy"
                                  placeholderText=""
                                  className="filter-input-date  new-date-in document-add-date point"
                                  onChange={this.handleToDateChange}
                                  name="last_done"
                                  id="last_done"
                                  style={{ fontWeight: "500" }}
                                  showYearDropdown
                                  showMonthDropdown
                                  ariaDescribedBy="basic-addon2"
                                  maxDate={new Date()}
                                  minDate={new Date().setMonth(
                                    new Date().getMonth() - 3
                                  )}
                                />
                                <InputGroupText
                                  id="basic-addon2"
                                  className="group-date doc-add-group notice-group"
                                >
                                  <label
                                    className="mb-remove cursor-point"
                                    htmlFor="warranty_date"
                                  >
                                    <img
                                      src={DateSvg}
                                      alt="Date-Picker Icon"
                                      className="date-svg point"
                                    />
                                  </label>
                                </InputGroupText>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Next Due*
                              </label>
                              <InputGroup className="mb-0  flex-content notice-date">
                                <DatePicker
                                  selected={next_due}
                                  dateFormat="MM-dd-yyyy"
                                  placeholderText=""
                                  className="filter-input-date  new-date-in document-add-date point"
                                  onChange={this.handleDueDateChange}
                                  name="next_due"
                                  id="next_due"
                                  style={{ fontWeight: "500" }}
                                  showYearDropdown
                                  showMonthDropdown
                                  ariaDescribedBy="basic-addon2"
                                  maxDate={new Date().setMonth(
                                    new Date().getMonth() + 24
                                  )}
                                  minDate={new Date()}
                                />
                                <InputGroupText
                                  id="basic-addon2"
                                  className="group-date doc-add-group notice-group"
                                >
                                  <label
                                    className="mb-remove cursor-point"
                                    htmlFor="warranty_date"
                                  >
                                    <img
                                      src={DateSvg}
                                      alt="Date-Picker Icon"
                                      className="date-svg point"
                                    />
                                  </label>
                                </InputGroupText>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Description
                              </label>
                              <textarea
                                className="form-control-alternative  form-control tex-area-prop"
                                value={desc}
                                name="desc"
                                id="input-first-name"
                                onChange={this.handleInputChange}
                                placeholder="Type here..."
                                type="text"
                                maxLength="500"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              )}

              {step_2 && (
                <>
                  <Card className="shadow mt-4">
                    <ListingHeaderLayout label={"Documents"} maxFile={true}>
                      <div className="text-right" xs="4">
                        <Button
                          color="primary"
                          size="sm"
                          className="image-hide button-wrapping"
                        >
                          <Input
                            className="form-control-alternative form-control opac-hide "
                            type="file"
                            id="imageUpload"
                            accept="image/pdf/*"
                            value={this.state.img_value}
                            onChange={(event) =>
                              this.onImageChangeHandler(event)
                            }
                            onClick={this.onImageClick}
                            placeholder="Enter Name"
                          />
                          Add
                        </Button>
                      </div>
                    </ListingHeaderLayout>
                    <CardBody className="new-card gallery-change-pad pb-4">
                      <Form>
                        <div>
                          <Row>
                            {this.state.imageArray.map((field, idx) => {
                              return (
                                <Col lg="1" key={idx}>
                                  {field.format === "pdf" ? (
                                    <>
                                      <img
                                        src={pdfImage}
                                        onClick={() => this.openPdf(field.url)}
                                        className="pdfImage billImage mt-2 point"
                                        alt=""
                                      />

                                      {/* <a
                                        href={getCloudinaryImage(
                                          330,
                                          330,
                                          field,
                                          "c_fill"
                                        )}
                                        target="_blank"
                                      >
                                          </a> */}
                                      <img
                                        src={Remove}
                                        className="ni ni-fat-remove pdf-remove purchase-remove"
                                        onClick={() => this.deleteImage(idx)}
                                        alt=""
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <LightgalleryItem
                                        src={getCloudinaryImage(
                                          330,
                                          330,
                                          field,
                                          "c_fill"
                                        )}
                                      >
                                        <img
                                          src={getCloudinaryImage(
                                            330,
                                            330,
                                            field,
                                            "c_fill"
                                          )}
                                          className="arrayImg cursor-point mt-2"
                                          alt=""
                                        />
                                      </LightgalleryItem>

                                      <img
                                        src={Remove}
                                        className="ni ni-fat-remove purchase-i-remove"
                                        onClick={() => this.deleteImage(idx)}
                                        alt=""
                                      />
                                    </>
                                  )}
                                </Col>
                              );
                            })}
                            <Col lg="1">
                              {imageLoading && (
                                <div className="image-loading spinner-cut">
                                  <Spinner type="border" color="#012A4D" />
                                </div>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </>
              )}
            </div>
          </Row>
          <Modal
            isOpen={verifyVaultModal}
            className="vaultverifymodal"
            toggle={() => this.toggleVerifyVaultModal()}
          >
            <VerifySecureVault
              isOpen={verifyVaultModal}
              close={(msg) => this.toggleVerifyVaultModal(msg)}
              isOpenDisable={() => this.openDisableVaultModal()}
            />
          </Modal>
          <Modal
            isOpen={disableVaultModal}
            toggle={() => this.toggleDisableVaultModal()}
          >
            <DisableSecureVault
              close={(msg) => this.toggleDisableVaultModal(msg)}
            />
          </Modal>
        </Container>
      </>
    );
  }
}
export default AddEditVault;
