import React, { Component } from "react";

//Layout
import { Logout, setCookie, getAuthToken, resetCookitTime } from "../constants/utils";
import moment from "moment";
import AdminLayout from "../layouts/Admin";
class PrivateRouter extends Component {
  componentDidMount() {
    let act =
      moment().format("DD MMM, YYYY") + " - " + moment().format("hh:mm  a");
    setCookie("lastActivity", act);
  }

  render = () => {
    window.addEventListener("click", (event) => {
      const token = getAuthToken()
      if (token) resetCookitTime()
      else Logout();
    });
    return <AdminLayout />;
  };
}

export default PrivateRouter;
