import React, { Component } from "react";
import {
    returnFirstDegreeObjValue,
    showLocaldate,
    displayPrice,
} from "constants/utils";

import { Card, CardBody, CardHeader, Table, Spinner } from "reactstrap";
import { displayPercentage } from "constants/utils";
import closeIcon from "../../assets/img/brand/close.svg";
import returnSecondDegreeObjValue from "constants/utils";
import { capitalize } from "@mui/material";
import { makeCapitalize } from "constants/utils";
import { getCloudinaryImage } from "constants/utils";
import docFile from "../../assets/img/brand/docfile.svg";



class VaultSidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            invoiceDetail: [],
            page: 1,
            invoiceStatus: "",
            tableLoad: false,
        };
    }

    componentDidUpdate(prevProps) {
        let { data, status } = this.props;
    }

    // getInvoiceDetail = async () => {
    //     let { data } = this.props;
    //     let { page, invoiceStatus } = this.state;
    //     let id = data.property_id;
    // };

    closeModal = () => {
        this.setState({ invoiceDetail: [], tableLoad: false });
        this.props.close();
    };


    handleIncrementPage = () => {
        if (this.state.invoiceDetail.length >= 10) {
            this.setState({ page: this.state.page + 1 }, () => {
                // this.getInvoiceDetail();
            });
        }
    };

    handleDecrementPage = () => {
        if (this.state.page !== 1) {
            this.setState({ page: this.state.page - 1 }, () => {
                // this.getInvoiceDetail();
            });
        }
    };

    render() {
        let { page } = this.state;
        let { data } = this.props;
        return (
            <div>
                {!data ? (
                    <div className="spin-loading3" style={{ marginTop: "200px" }}>
                        <Spinner type="border" color="#012A4D" />
                    </div>
                ) : (
                    <>
                        <Card>
                            <CardHeader>
                                <div className="flex">
                                    <div className="fw-500 f16 cl000000">Details</div>
                                    <img
                                        src={closeIcon}
                                        onClick={() => this.closeModal()}
                                        className="point"
                                    />
                                </div>
                            </CardHeader>
                            <CardBody className="pad30p">
                                <div className="gridThreeColumns grGap30">
                                    <div className="flex-column">
                                        <div className="f14 fw-400 cl757575 mb-15">Name</div>
                                        <div className="fw-500 cl303030 f15">
                                            {data?.name}
                                        </div>
                                    </div>

                                    <div className="flex-column">
                                        <div className="f14 fw-400 cl757575 mb-15">Price</div>
                                        <div className="fw-500 cl303030 f15">
                                            {displayPrice(data?.price)}
                                        </div>
                                    </div>
                                    <div className="flex-column">
                                        <div className="f14 fw-400 cl757575 mb-15">Frequency</div>
                                        <div className="fw-500 cl303030 f15">
                                            {(data?.frequency)}
                                        </div>
                                    </div>
                                    <div className="flex-column">
                                        <div className="f14 fw-400 cl757575 mb-15">Last done</div>
                                        <div className="fw-500 cl303030 f15">
                                            {showLocaldate(data?.last_done)}
                                        </div>
                                    </div>
                                    <div className="flex-column">
                                        <div className="f14 fw-400 cl757575 mb-15">Next Due</div>
                                        <div className="fw-500 cl303030 f15">
                                            {showLocaldate(data?.next_due)}
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                        <Card className="shadow card_with_paginator">
                            <div>
                                <CardHeader>
                                    <div className="flex">
                                        <div className="fw-500 f16 cl000000">Files</div>
                                    </div>
                                </CardHeader>
                                <CardBody className="pad30p">
                                    <div className="gridSixColumns grGap10">
                                        {data &&
                                            data.docs.map((item, i) => (
                                                <div key={i} className="relative" style={{ marginBottom: "10px" }} >
                                                    {!["png", "jpg", "jpeg"].includes(item?.format) ?
                                                        <div className="text-center point sideWithDoc m_0 image_container imageandpdf" style={{width:"80px"}} >
                                                            <a target="_blank" href={getCloudinaryImage(120, 130, item, "c_fill")}>
                                                                <img src={docFile} style={{ width: "40px", marginTop: "20px" }} />
                                                                <p className={`cl72569C uploadFile`} style={{ wordWrap: "break-word" }}> {item?.name}</p>
                                                            </a>
                                                        </div>
                                                        :
                                                        <div className="image_container">
                                                            <a target="_blank" href={getCloudinaryImage(120, 130, item, "c_fill")}>
                                                                <img
                                                                 
                                                                    src={getCloudinaryImage(120, 130, item, "c_fill")}
                                                                    className="preview-img w-[50px] rounded-[5px] object-fill cursor-pointer"
                                                                    style={{  borderRadius: "7px", border: "1px solid #CECECE",height:"80px" }}
                                                                />
                                                            </a>
                                                        </div>
                                                    }
                                                </div>
                                            ))}
                                    </div>
                                </CardBody>
                                <>
                                    {data?.items && (
                                        <div className="flexEnd paginator_box bbNone ">
                                            <div
                                                className="paginate-box"
                                                onClick={() => this.handleDecrementPage()}
                                            >
                                                <p
                                                    className={
                                                        page !== 1 ? "page_available" : "page_unavailable"
                                                    }
                                                >
                                                    Previous
                                                </p>
                                            </div>
                                            <div
                                                className="paginate-box"
                                                onClick={() => this.handleIncrementPage()}
                                            >
                                                <p
                                                    className={
                                                        data?.items?.length < 10
                                                            ? "page_unavailable"
                                                            : "page_available"
                                                    }
                                                >
                                                    Next
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </>
                            </div>
                        </Card>

                    </>
                )}
            </div>
        );
    }
}

export default VaultSidebar;
