import React, { Component } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Spinner,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import ApiService from "constants/ApiService";
import {
  uploadCloudinary,
  isInvalidName,
  handleGoBack,
  getCloudinaryImage,
} from "constants/utils";
import "react-step-progress-bar/styles.css";
import pdfImage from "../../../assets/img/brand/pdf.png";
import Remove from "../../../assets/img/brand/remove.svg";
import Home from "../../../assets/img/brand/home.svg";
import propTick from "../../../assets/img/brand/propTick.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateSvg from "../../../assets/img/brand/date-pick.svg";
import { LightgalleryItem } from "react-lightgallery";
import ToastList from "components/Toast/ToastList";
import { showError } from "constants/utils";
import {
  showSuccess,
  getUSFormat,
  showCalendarFormat,
  replaceToNormalNumber,
} from "constants/utils";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
let property_id;
let history_id;
class AddEditPurchase extends Component {
  constructor(props) {
    super(props);
    property_id = this.props.match.params.property_id;
    history_id = this.props.match.params.history_id;
    this.state = {
      name: "",
      vendor: "",
      price: "",
      purchase_date: "",
      warranty_date: "",
      des: "",
      step_1: true,
      step_2: false,
      imageArray: [],
      billsArray: [],
      vendorList: [],
      isLoading: false,
      toast: false,
      response: "",
      toastType: "",
      imageLoading: false,
      billsLoading: false,
      isUpdateLoading: false,
      p_type: "",
      item: "",
      appliance_list: [
        "Refrigerator",
        "Oven",
        "Stove",
        "Microwave oven",
        "Dishwasher",
        "Washing machine",
        "Dryer",
        "Vacuum cleaner",
        "Air conditioner",
        "Heater",
        "Blender",
        "Toaster",
        "Coffee maker",
        "Electric kettle",
        "Food processor",
        "Slow cooker",
        "Electric grill",
        "Iron",
        "Hairdryer",
        "Electric toothbrush",
        "Electric shaver",
        "Bread maker",
        "Rice cooker",
        "Juicer",
        "Deep Fryer",
        "Electric Mixer",
        "Electric can opener",
        "Electric Fan",
        "Electric Blanket",
        "Water Purifier",
      ],
      furniture_list: [
        "Sofa/Couch",
        "Coffee table",
        "Dining table",
        "Dining chairs",
        "Bed",
        "Nightstand/Bedside table",
        "Dresser",
        "Wardrobe/Armoire",
        "Bookcase",
        "Desk",
        "Office chair",
        "TV stand/Entertainment center",
        "Side table",
        "Ottoman",
        "Chest of drawers",
        "Shelving unit",
        "Accent chair",
        "Console table",
        "Bar stools",
        "Benches",
      ],
      others: "",
      phone: "",
      vendor_data: "",
      img_value: "",
      bills_value: "",
      btn_enable: true,
    };
  }
  componentDidMount() {
    let arr = this.props.location.pathname.split("/");
    arr.forEach((element) => {
      if (element === "add") {
        this.setState({ p_type: arr[3] }, () => this.getAllDetails());
      } else if (element === "edit") {
        this.setState({ p_type: arr[2] }, () => this.getAllDetails());
      }
    });
  }
  getAllDetails = () => {
    if (history_id) {
      this.getPurchaseDetail();
    } else {
      this.setState({ isLoading: false });
    }
    this.setState({
      progressActive: true,
    });
    this.getVendorList();
  };
  getPurchaseDetail = async () => {
    let response = await ApiService.getAPI(`host/history/${history_id}`);
    if (response.success) {
      let res = response.message;
      let {
        price,
        name,
        vendor,
        images,
        bills,
        description,
        purchased_at,
        warranty_end,
        warranty_contact,
        others,
      } = res;
      this.setState({
        name: name ?? "",
        item: name ?? "",
        others: others ?? "",
        vendor: vendor?.id ?? "",
        vendor_data: vendor ?? "",
        price,
        imageArray: images,
        billsArray: bills,
        des: description,
        purchase_date: purchased_at ? new Date(purchased_at) : "",
        warranty_date: warranty_end ? new Date(warranty_end) : "",
        phone: warranty_contact ? getUSFormat(warranty_contact) : "",
      });
    }
  };
  getVendorList = async () => {
    let response = await ApiService.getAPI("host/vendor");
    if (response.success) {
      this.setState({ vendorList: response.data });
    }
  };
  // Event handler
  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  openPdf = (field) => {
    window.open(getCloudinaryImage(330, 330, field, "c_fill"));
  };

  handleOnDateChange = (e) => {
    this.updateStateValues("purchase_date", e);
  };
  handleToDateChange = (e) => {
    this.updateStateValues("warranty_date", e);
  };
  updateStateValues = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      function () {
        if (value === null) {
          this.setState({
            [key]: "",
          });
        }
      }
    );
  };

  onImageChangeHandler = async (e, type, loading) => {
    let self = this;
    let imageArray = this.state[type];
    let file = e.target.files[0];
    if (this.state[type].length < 6) {
      if (
        (type === "imageArray" &&
          (file.type === "image/png" ||
            file.type === "image/jpg" ||
            file.type === "image/jpeg")) ||
        (type === "billsArray" &&
          (file.type === "image/png" ||
            file.type === "image/jpg" ||
            file.type === "image/jpeg" ||
            file.type === "application/pdf"))
      ) {
        if (file.size < 1000000) {
          this.setState({ [loading]: true });
          let data = await uploadCloudinary(file, "purchase");
          if (data.url) {
            let images = [...imageArray];

            images.push({
              format: data.format,
              public_id: data.public_id,
              version: `v${data.version}`,
              type: file.type === "application/pdf" ? "pdf" : "image",
            });
            self.setState({
              [type]: images,
              [loading]: false,
            });
          } else {
            showError(this, "Could not upload image");
            this.setState({ [loading]: false });
          }
        } else {
          showError(this, "File size should not exceeds 1 MB");
          this.setState({ [loading]: false });
        }
      } else {
        showError(this, "Invalid Format");
        this.setState({ [loading]: false });
      }
    } else {
      showError(this, "Maximum file exceeds");
    }
  };
  deleteImage = (idx, type) => {
    let imageArray = this.state[type];
    let images = [...imageArray];
    images.splice(idx, 1);
    this.setState({ [type]: images });
  };
  purchaseType = (type) => {
    let ret_type = "purchase";
    if (type === "appliance_history") {
      ret_type = "appliance";
    } else if (type === "furniture_history") {
      ret_type = "furniture";
    }

    return `p_type=${ret_type}`;
  };
  handleApplianceFurniture = () => {
    const { p_type } = this.state;
    let data = false;
    if (p_type === "furniture_history" || p_type === "appliance_history") {
      data = true;
    }

    return data;
  };
  handleAddEdit = async () => {
    let {
      imageArray,
      billsArray,
      name,
      price,
      purchase_date,
      warranty_date,
      vendor,
      des,
      step_1,
      step_2,
      phone,
      others,
      item,
      vendor_data,
    } = this.state;
    let purchase_type;
    if (this.state.p_type === "furniture_history") {
      purchase_type = "furniture";
    } else if (this.state.p_type === "appliance_history") {
      purchase_type = "appliance";
    } else {
      purchase_type = "purchase";
    }

    if (step_1) {
      if (!this.handleApplianceFurniture() && name === "") {
        showError(this, "Please enter name");
      } else if (
        (isInvalidName(name) && !this.handleApplianceFurniture()) ||
        (name.length < 3 && !this.handleApplianceFurniture())
      ) {
        showError(this, "Please enter a valid name");
      } else if (!item && this.handleApplianceFurniture()) {
        showError(this, "Please select item");
      } else if (
        item &&
        item === "others" &&
        !others &&
        this.handleApplianceFurniture
      ) {
        showError(this, "Please enter name");
      } else if (purchase_date === "") {
        showError(this, "Please select date of purchase");
      }
      // else if (Object.keys(vendor).length === 0) {
      //   showError(this, "Please select vendor");
      // }
      else if (parseInt(price) <= 0 || price === "") {
        showError(this, "Please enter valid price");
      }
      // else if (phone === "" && this.handleApplianceFurniture()) {
      //   showError(this, "Please enter phone number");
      // }
      else if (phone && phone.length < 16 && this.handleApplianceFurniture()) {
        showError(this, "Please enter valid phone number");
      } else {
        this.setState({ step_1: false, step_2: true });
      }
    }
    if (step_2) {
      let data;
      if (imageArray.length === 0) {
        showError(this, "Please attach atleast one image file");
      } else {
        if (!this.handleApplianceFurniture()) {
          data = {
            name,
            price,
            description: des,
            vendor: vendor_data,
            purchased_at: purchase_date
              ? showCalendarFormat(purchase_date)
              : "",
            warranty_end: warranty_date
              ? showCalendarFormat(warranty_date)
              : "",
            images: imageArray,
            bills: billsArray,
            p_type: purchase_type,
          };
        } else {
          data = {
            name: item,
            others: others,
            price,
            description: des,
            vendor: vendor_data,
            purchased_at: purchase_date
              ? showCalendarFormat(purchase_date)
              : "",
            warranty_end: warranty_date
              ? showCalendarFormat(warranty_date)
              : "",
            warranty_contact: replaceToNormalNumber(phone),
            images: imageArray,
            bills: billsArray,
            p_type: purchase_type,
          };
        }

        this.setState({ btn_enable: false, isUpdateLoading: true });
        if (history_id) {
          let response = await ApiService.patchAPI(
            `host/history/${history_id}`,
            data
          );
          if (response.success) {
            showSuccess(this, response.message);
            this.setState({ isUpdateLoading: false });
            handleGoBack(this.props);
          } else {
            this.setState({ btn_enable: true });
            showError(this, response.message);
          }
        } else {
          let response = await ApiService.postAPI(
            `host/history/${property_id}`,
            data
          );
          if (response.success) {
            showSuccess(this, response.message);
            this.setState({ isUpdateLoading: false });
            handleGoBack(this.props);
          } else {
            this.setState({ btn_enable: true });
            showError(this, response.message);
          }
        }
      }
    }
  };
  onImageClick = (e) => {
    this.setState({ [e.target.name]: "" });
  };
  toggleBack = () => {
    let { step_1, step_2, step_3, step_4 } = this.state;
    if (step_1) {
      this.props.history.goBack();
    } else if (step_2) {
      this.setState({ step_1: true, step_2: false });
    } else if (step_3) {
      this.setState({ step_2: true, step_3: false });
    } else if (step_4) {
      this.setState({ step_3: true, step_4: false });
    }
  };

  handleVendorInputChange = (e) => {
    var self = this;
    let vendorList = this.state.vendorList;
    vendorList.forEach((el) => {
      if (el.vendor_id === e.target.value) {
        let vendor_data = {
          id: el.vendor_id,
          name: el.name,
        };
        this.setState({ vendor_data: vendor_data });
      }
    });

    self.setState({ vendor: e.target.value });
  };
  handleOnPhoneNumberChange = (evt) => {
    this.setState({ [evt.target.name]: getUSFormat(evt.target.value) });
  };
  render() {
    let {
      name,
      step_1,
      step_2,
      price,
      vendor,
      des,
      toast,
      response,
      toastType,
      isLoading,
      vendorList,
      imageLoading,
      billsLoading,
      isUpdateLoading,
      p_type,
      item,
      others,
      phone,
      btn_enable,
    } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#012A4D" />
      </div>
    ) : (
      <>
        <Container className="mt-8 card-pad-wrapper" fluid>
          {toast && <ToastList message={response} type={toastType} />}
          <div className="pad-to-8">
            <Breadcrumb></Breadcrumb>
          </div>
          <div className="d_flex_space_align mt-4">
            <Col xs="8" className="manage-con">
              <Breadcrumb>
                <BreadcrumbItem
                  onClick={() => this.props.history.goBack()}
                  className="bread-cursor"
                >
                  {p_type === "appliance_history"
                    ? "Appliance History"
                    : p_type === "furniture_history"
                      ? "Furniture History"
                      : "Purchase History"}
                </BreadcrumbItem>
                <BreadcrumbItem active style={{ color: "#012a4d" }}>
                  {history_id ? "Update" : "Add"}
                </BreadcrumbItem>
              </Breadcrumb>
            </Col>
            <div className="right_filter_container">
              <Button
                color="primary"
                onClick={() => this.toggleBack()}
                size="sm"
                className="button-wrapping search-wrapper content-flex mr-4 button-secondary ml-4 reset-btn"
              >
                Previous
              </Button>
              <Button
                color="primary"
                onClick={btn_enable ? () => this.handleAddEdit() : ""}
                size="sm"
                className={
                  isUpdateLoading && step_2 && history_id
                    ? "button-wrapping up-prop-btn"
                    : "button-wrapping "
                }
              >
                {isUpdateLoading && step_2 && history_id ? (
                  <div className="button_spinner">
                    <Spinner type="border" color="#012A4D" />
                  </div>
                ) : step_2 && !history_id ? (
                  "Create"
                ) : step_2 && history_id ? (
                  "Update"
                ) : (
                  "Next"
                )}
              </Button>
            </div>
          </div>
          <div className="progress-all">
            <div className="progress-div progress-purchase">
              <div
                className={
                  step_1
                    ? "progress-back-toggle"
                    : step_2
                      ? "progress-back-toggle w_3 p_3"
                      : "progress-back-toggle"
                }
              >
                {step_1 ? (
                  <div className="img-black image_one">
                    <img src={Home} className="img-abs" alt="" />
                  </div>
                ) : (!step_1 && step_2) ||
                  (!step_1 && !step_2) ||
                  (!step_1 && !step_2) ? (
                  <div className="steps-1 back">
                    <img src={propTick} className="img-abs-prop" alt="" />
                  </div>
                ) : (
                  <div className="steps-1"></div>
                )}
                {step_2 ? (
                  <div className="img-black image_two purchase_two">
                    <img src={Home} className="img-abs" alt="" />
                  </div>
                ) : (!step_1 && !step_2) || (!step_1 && !step_2) ? (
                  <div className="steps-2 back ">
                    <img src={propTick} className="img-abs-prop" alt="" />
                  </div>
                ) : (
                  <div className="steps-2 steps_2_pur"></div>
                )}
              </div>
            </div>
          </div>
          <Row>
            <div className="col new-table">
              {step_1 && (
                <Card className="shadow">
                  <ListingHeaderLayout label={"Basic Information"} />
                  {/* <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <h3 className="mb-0">Basic Information</h3>
                      </Col>
                    </Row>
                  </CardHeader> */}
                  <CardBody className="new-card">
                    <Form>
                      <div>
                        <Row>
                          {this.handleApplianceFurniture() && (
                            <>
                              <Col lg="4">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-first-name"
                                  >
                                    Item*
                                  </label>
                                  <select
                                    className="form-control-alternative form-control"
                                    id="input-first-name"
                                    name="item"
                                    value={item}
                                    onChange={this.handleInputChange}
                                  >
                                    <option value="">Select</option>
                                    {p_type === "appliance_history" ? (
                                      this.state.appliance_list.map((el) => (
                                        <option value={el}>{el}</option>
                                      ))
                                    ) : (
                                      <>
                                        {this.state.furniture_list.map((el) => (
                                          <option value={el}>{el}</option>
                                        ))}
                                      </>
                                    )}
                                    <option value="others">Other</option>
                                  </select>
                                </FormGroup>
                              </Col>
                            </>
                          )}
                          {this.state.item === "others" && (
                            <Col lg="4" className="mb-3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-username"
                                >
                                  Name*
                                </label>

                                <Input
                                  className="form-control-alternative"
                                  value={others}
                                  name="others"
                                  placeholder="Name"
                                  type="text"
                                  onChange={this.handleInputChange}
                                />
                              </FormGroup>
                            </Col>
                          )}
                          {!this.handleApplianceFurniture() && (
                            <>
                              {" "}
                              <Col lg="4" className="mb-3">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-username"
                                  >
                                    Name*
                                  </label>

                                  <Input
                                    className="form-control-alternative"
                                    value={name}
                                    name="name"
                                    placeholder="Name"
                                    type="text"
                                    onChange={this.handleInputChange}
                                  />
                                </FormGroup>
                              </Col>
                            </>
                          )}

                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Date of Purchase*
                              </label>
                              <InputGroup className="mb-0  flex-content notice-date">
                                <DatePicker
                                  selected={this.state.purchase_date}
                                  dateFormat="MM-dd-yyyy"
                                  placeholderText=""
                                  className="filter-input-date  new-date-in document-add-date point"
                                  onChange={this.handleOnDateChange}
                                  name="purchase_date"
                                  id="purchase_date"
                                  style={{ fontWeight: "500" }}
                                  maxDate={new Date()}
                                  showYearDropdown
                                  showMonthDropdown
                                  ariaDescribedBy="basic-addon2"
                                />
                                <InputGroupText
                                  id="basic-addon2"
                                  className="group-date doc-add-group notice-group"
                                >
                                  <label
                                    className="mb-remove cursor-point"
                                    htmlFor="purchase_date"
                                  >
                                    <img
                                      src={DateSvg}
                                      alt="Date-Picker Icon"
                                      className="date-svg point"
                                    />
                                  </label>
                                </InputGroupText>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Warranty Expiry
                              </label>
                              <InputGroup className="mb-0  flex-content notice-date">
                                <DatePicker
                                  selected={this.state.warranty_date}
                                  dateFormat="MM-dd-yyyy"
                                  placeholderText=""
                                  className="filter-input-date  new-date-in document-add-date point"
                                  onChange={this.handleToDateChange}
                                  name="warranty_date"
                                  id="warranty_date"
                                  style={{ fontWeight: "500" }}
                                  showYearDropdown
                                  showMonthDropdown
                                  ariaDescribedBy="basic-addon2"
                                />
                                <InputGroupText
                                  id="basic-addon2"
                                  className="group-date doc-add-group notice-group"
                                >
                                  <label
                                    className="mb-remove cursor-point"
                                    htmlFor="warranty_date"
                                  >
                                    <img
                                      src={DateSvg}
                                      alt="Date-Picker Icon"
                                      className="date-svg point"
                                    />
                                  </label>
                                </InputGroupText>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          {this.handleApplianceFurniture() && (
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Warranty Contact
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  value={phone}
                                  name="phone"
                                  // id="input-first-name"
                                  onChange={(e) =>
                                    this.handleOnPhoneNumberChange(e)
                                  }
                                  placeholder="Phone Number"
                                  type="text"
                                  maxLength="16"
                                />
                              </FormGroup>
                            </Col>
                          )}
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Vendor
                              </label>
                              <select
                                className="form-control-alternative form-control"
                                id="input-first-name"
                                name="vendor"
                                value={vendor}
                                onChange={this.handleVendorInputChange}
                              >
                                <option value="">Select</option>
                                {vendorList.map((vendor, i) => (
                                  <option value={vendor.vendor_id} key={i}>
                                    {vendor.name}
                                  </option>
                                ))}
                              </select>
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup className="form-relative">
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Price*
                              </label>
                              <Input
                                className="form-control-alternative"
                                value={price}
                                name="price"
                                id="input-first-name"
                                onChange={this.handleInputChange}
                                placeholder="Price"
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Description
                              </label>
                              <textarea
                                className="form-control-alternative  form-control tex-area-prop"
                                value={des}
                                name="des"
                                id="input-first-name"
                                onChange={this.handleInputChange}
                                placeholder="Tell us something about this purchase"
                                type="text"
                                maxLength="500"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              )}

              {step_2 && (
                <>
                  <Card className="shadow mt-4">
                    <ListingHeaderLayout label={"Images *"}>
                      <div className="text-right" xs="4">
                        <Button
                          color="primary"
                          size="sm"
                          className="image-hide button-wrapping"
                        >
                          <Input
                            className="form-control-alternative form-control opac-hide"
                            type="file"
                            id="imageUpload"
                            value={this.state.img_value}
                            accept="image/*"
                            onChange={(event) =>
                              this.onImageChangeHandler(
                                event,
                                "imageArray",
                                "imageLoading"
                              )
                            }
                            onClick={this.onImageClick}
                            placeholder="Enter Name"
                          />
                          Add
                        </Button>
                      </div>
                    </ListingHeaderLayout>
                    <CardBody className="new-card gallery-change-pad pb-4">
                      <Form>
                        <div>
                          <Row>
                            {this.state.imageArray.map((field, idx) => {
                              return (
                                <Col lg="1" key={idx}>
                                  <>
                                    <LightgalleryItem
                                      src={getCloudinaryImage(
                                        330,
                                        330,
                                        field,
                                        "c_fill"
                                      )}
                                    >
                                      <img
                                        src={getCloudinaryImage(
                                          330,
                                          330,
                                          field,
                                          "c_fill"
                                        )}
                                        className="arrayImg cursor-point mt-2"
                                        alt=""
                                      />
                                    </LightgalleryItem>

                                    <img
                                      src={Remove}
                                      className="ni ni-fat-remove purchase-i-remove"
                                      onClick={() =>
                                        this.deleteImage(
                                          idx,
                                          "imageArray",
                                          "imageLoading"
                                        )
                                      }
                                      alt=""
                                    />
                                  </>
                                </Col>
                              );
                            })}
                            <Col lg="1">
                              {imageLoading && (
                                <div className="image-loading spinner-cut">
                                  <Spinner type="border" color="#012A4D" />
                                </div>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                  <Card className="shadow mt-4">
                    <ListingHeaderLayout label={"Bills"}>
                      <div className="text-right" xs="4">
                        <Button
                          color="primary"
                          size="sm"
                          className="image-hide button-wrapping"
                        >
                          <Input
                            className="form-control-alternative form-control opac-hide "
                            type="file"
                            id="imageUpload"
                            accept="image/*"
                            value={this.state.bills_value}
                            onChange={(event) =>
                              this.onImageChangeHandler(
                                event,
                                "billsArray",
                                "billsLoading"
                              )
                            }
                            onClick={this.onImageClick}
                            placeholder="Enter Name"
                          />
                          Add
                        </Button>
                      </div>
                    </ListingHeaderLayout>
                    <CardBody className="new-card gallery-change-pad pb-4">
                      <Form>
                        <div>
                          <Row>
                            {this.state.billsArray.map((field, idx) => {
                              return (
                                <Col lg="1" key={idx}>
                                  {field.format === "pdf" ? (
                                    <>
                                      <img
                                        src={pdfImage}
                                        className="pdfImage billImage mt-2"
                                        alt=""
                                      />
                                      <img
                                        src={Remove}
                                        className="ni ni-fat-remove pdf-remove purchase-remove"
                                        onClick={() =>
                                          this.deleteImage(
                                            idx,
                                            "billsArray",
                                            "billsLoading"
                                          )
                                        }
                                        alt=""
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <LightgalleryItem
                                        src={getCloudinaryImage(
                                          330,
                                          330,
                                          field,
                                          "c_fill"
                                        )}
                                      >
                                        <img
                                          src={getCloudinaryImage(
                                            330,
                                            330,
                                            field,
                                            "c_fill"
                                          )}
                                          className="arrayImg cursor-point mt-2"
                                          alt=""
                                        />
                                      </LightgalleryItem>

                                      <img
                                        src={Remove}
                                        className="ni ni-fat-remove purchase-i-remove"
                                        onClick={() =>
                                          this.deleteImage(
                                            idx,
                                            "billsArray",
                                            "billsLoading"
                                          )
                                        }
                                        alt=""
                                      />
                                    </>
                                  )}
                                </Col>
                              );
                            })}
                            <Col lg="1">
                              {billsLoading && (
                                <div className="image-loading spinner-cut">
                                  <Spinner type="border" color="#012A4D" />
                                </div>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </>
              )}
            </div>
          </Row>
        </Container>
      </>
    );
  }
}
export default AddEditPurchase;
